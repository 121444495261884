// Import required libraries
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { config } from "../config/config";
import { ErrorBoundary } from "react-error-boundary";
import PageNotFound from "../screens/PageNotFound/PageNotFound";

function ProtectedRoute(props) {
    const { path, component: Component, user } = props;
    const myErrorHandler = (error, errorInfo) => {
        console.log("error", error);
    };
    if (!user.isAuth) {
        window.location.replace(config.api.signInUrl);
    }
    return user.isAuth ? (
        <Route
            path={path}
            render={(ownProps) => (
                <ErrorBoundary
                    FallbackComponent={PageNotFound}
                    onError={myErrorHandler}
                    key={path}
                >
                    <Component
                        user={props.user}
                        location={props.location}
                        match={ownProps.match}
                    />
                </ErrorBoundary>
            )}
        />
    ) : (
        <div></div>
    );
}

ProtectedRoute.propTypes = {
    path: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps)(ProtectedRoute);
