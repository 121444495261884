import React, { useState } from "react";
import { Stack } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { connect } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import { StyledAutoComplete, CssTextField, useStyles } from "./Style";
import "./style.scss";
import CustomAutoComponent from "../AutoCompletes/CustomAutoComponents";
import { getFilteredOptions } from "../../utils/utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectComponent = ({
  handleChange,
  data,
  value,
  filterList,
  setFilterList,
  handleSubmit,
  setFilterOptions,
  filterOptions,
  theme,
}) => {
  const { label, options = [], key, value_type } = data;
  const classes = useStyles();
  const [msg, setMsg] = useState("");
  const [isMyInputFocused, setIsMyInputFocused] = useState(false);

  const removeMsg = () => {
    setTimeout(() => {
      setMsg("");
    }, 3000);
  };

  const handleSelection = (k, data, sortedData) => {
    const newData = { ...filterList, [key]: data };
    setFilterList(newData);
    setFilterOptions({ ...filterOptions, [key]: sortedData });
    handleSubmit(newData);
  };

  const handleOptionSelect = (event, selectedValue) => {
    const updateChanges = (data) => {
      let newFilterOptions = filterOptions[key].filter(
        (item) => !data[key].includes(item)
      );
      newFilterOptions = [...data[key], ...newFilterOptions];
      setFilterOptions({ ...filterOptions, [key]: newFilterOptions });
      setFilterList(data);
      handleSubmit(data);
    };
    if (filterList[key] && filterList[key].includes(...selectedValue)) {
      const filteredData = filterList[key].filter(
        (list) => list !== selectedValue[0]
      );
      const newData = { ...filterList, [key]: filteredData };
      updateChanges(newData);
      return;
    }
    if (filterList[key].length > 9) {
      setMsg("Cannot select more than 10");
      removeMsg();
      return;
    }
    const newData = {
      ...filterList,
      [key]: [...filterList[key], ...selectedValue].sort(),
    };
    updateChanges(newData);
  };

  return (
    <Stack
      direction="column"
      sx={{ flex: 1, pr: "10px" }}
      className={
        filterList[key]?.length && !isMyInputFocused
          ? `${theme}-autoCompeteContainer`
          : ""
      }
    >
      <div className={`labelTextforFilters ${theme}`}>{`${label}`}</div>
      {value_type === "multiple" && key !== "geography" ? (
        <>
          {msg && <div className="error">{msg}</div>}
          <StyledAutoComplete
            multiple
            fullWidth
            classes={{ paper: "GA-app-filter" }}
            className={classes.typographyStyle}
            options={filterOptions[key]}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={[]}
            getOptionSelected={(option, value) => option === value}
            renderOption={(option, { selected }) => {
              return (
                <React.Fragment>
                  {/* <div
                  // style={{
                  //   height: "10px",
                  // }}
                  > */}
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    className={classes.typographyStyle}
                    size="small"
                    style={{
                      marginRight: 5,
                      padding: "0px",
                    }}
                    sx={{
                      "&.Mui-checked": {
                        color: "#FC007F",
                      },
                    }}
                    checked={(filterList[key] || []).includes(option)}
                  />
                  <span style={{ fontSize: "11px" }}>{option}</span>
                  {/* </div> */}
                </React.Fragment>
              );
            }}
            onChange={handleOptionSelect}
            renderInput={(params) => (
              <CssTextField
                {...params}
                className={classes.typographyStyle}
                fullWidth
                onBlur={() => setIsMyInputFocused(false)}
                onFocus={() => setIsMyInputFocused(true)}
                placeholder={
                  filterList[key].length && !isMyInputFocused
                    ? `${filterList[key].slice(0, 2).join()} ${
                        filterList[key].length > 2
                          ? ` +${filterList[key].length - 2}`
                          : ""
                      }`
                    : `Select ${label}`
                }
              />
            )}
          />
        </>
      ) : key === "geography" ? (
        <CustomAutoComponent
          data={data}
          selectedData={filterList[key] || []}
          handleSelection={handleSelection}
          filteredOption={getFilteredOptions(filterOptions[key])}
          isFilterComponent={true}
        />
      ) : (
        <StyledAutoComplete
          value={value || ""}
          fullWidth
          options={options}
          classes={{ paper: "GA-app-filter" }}
          getOptionLabel={(option) => option}
          className={classes.typographyStyle}
          disableClearable={!Boolean(value)}
          onChange={(e, newValue) => {
            handleChange(key, newValue, label);
          }}
          renderInput={(params) => (
            <CssTextField
              {...params}
              className={classes.typographyStyle}
              fullWidth
              placeholder={`Select ${label}`}
            />
          )}
        />
      )}
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(SelectComponent);
