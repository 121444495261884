import React, { useEffect } from "react";
import { Box, Dialog, DialogTitle, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { connect } from "react-redux";
import { v4 } from "uuid";
import _ from "lodash";
import ReactCrop from "react-image-crop";
import { makeStyles } from "@material-ui/core";

// Import Styles
import { useStylesforImageSelector } from "../ImageSelector/Styles";
import { getInitialState, getRequiredComponent } from "../Utils/Utils";
import { Grid, useMediaQuery } from "@mui/material";
import { handleHideComponent } from "../../../Register/components/DashboardTemplate/utils";
import sample_image from "../../../../../assets/images/new-theme/report_sample_image.svg";
import { getFilename } from "../../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  cropImageStyle: {
    "& .ReactCrop__image": {
      minWidth: "50px",
      maxHeight: "400px",
      minHeight: "50px",
      maxWidth: "400px",
    },
  },
}));
const ReportImage = (props) => {
  const {
    setSelectedSize,
    selectedSize,
    setFile,
    file,
    reportDetails,
    setReportState,
    reportState,
    template_no,
  } = props;
  const fileTypes = ["JPEG", "PNG"];
  const [imgSrc, setImgSrc] = useState(
    reportDetails?.sub_category_img_data?.img_url || null
  );

  const [cropComponent, setCropComponent] = useState(false);
  const [crop, setCrop] = useState({ aspect: "" });
  const [imgSrcExt, setImgSrcExt] = useState(null);
  const [image, setImage] = useState(null);
  const handleClose = () => setCropComponent(false);
  function extractImageFileExtensionFromBase64(base64Data) {
    return base64Data.substring(
      "data:image/".length,
      base64Data.indexOf(";base64")
    );
  }
  const handleChangeImageSize = (size) => {
    setSelectedSize(size);
  };
  const handleChange = (file) => {
    setFile(file);
    handleImage(file);
  };
  const handleSizeError = (file) => {
    console.log("file", file);
  };

  const handleImage = async (file) => {
    // setErrorMsg("");
    const myFileItemReader = new FileReader();
    myFileItemReader.addEventListener(
      "load",
      () => {
        const url = myFileItemReader.result;
        setFile(file);
        setImgSrc(url);
        const ext = extractImageFileExtensionFromBase64(url);
        setImgSrcExt(ext);
      },
      false
    );
    myFileItemReader.readAsDataURL(file);
  };
  const classes = useStylesforImageSelector();
  const deleteFile = () => {
    setFile(null);
    setImgSrc(null);
    setReportState({
      ...reportState,
      sub_category_img_data: {
        blob_name: "",
        img_url: "",
        selected_size: "fit",
      },
    });
  };
  // console.log(
  //   "file",
  //   file,
  //   reportDetails?.sub_category_img_data?.blob_name.split("/")[3]
  // );

  const handleCrop = () => {
    setCropComponent(true);
  };
  function base64StringtoFile(base64String, filename) {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const getCroppedImg = async () => {
    // setErrorMsg("");
    if (!crop.width) {
      return;
    }
    try {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      setImgSrc(base64Image);
      setCropComponent(false);
      const oldFileName = getFilename(base64Image);
      const myFilename = oldFileName + "cropped." + imgSrcExt;
      // file to be uploaded
      const myNewCroppedFile = base64StringtoFile(base64Image, myFilename);
      setFile(myNewCroppedFile);
    } catch (e) {
      console.log("crop the image");
    }
  };
  const classes1 = useStyles();
  return (
    <div>
      <FileUploader
        multiple={false}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        maxSize={1}
        className={classes.FileUploaderClass}
        onSizeError={handleSizeError}
        // onDrop={handleDropImage}
      />
      <p className={classes.fileName}>
        {file || imgSrc ? (
          <div style={{ display: "flex" }}>
            File name:{" "}
            {file?.name ||
              reportDetails?.sub_category_img_data?.blob_name.split("/")[3] ||
              "No files uploaded yet"}
            {(file?.name ||
              reportDetails?.sub_category_img_data?.blob_name.split("/")[3]) &&
              template_no === 8 && (
                <span
                  class="material-symbols-outlined"
                  onClick={deleteFile}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  delete
                </span>
              )}
          </div>
        ) : (
          // <span></span>
          "No files uploaded yet."
        )}
      </p>
      <div>
        {imgSrc && (
          <img
            src={imgSrc}
            alt="Uploaded"
            className={
              selectedSize === "fit"
                ? classes.uploadedReportImageContain
                : classes.uploadedReportImageCover
            }
          />
        )}
        {!imgSrc && (
          <img
            src={sample_image}
            alt="Sample Report Here"
            className={
              selectedSize === "fit"
                ? classes.uploadedReportImageContain
                : classes.uploadedReportImageCover
            }
          />
        )}
      </div>

      <>
        <Dialog
          open={imgSrc && cropComponent}
          onClose={handleClose}
          maxWidth="md"
        >
          <DialogTitle style={{ borderBottom: "1px dashed lightgray" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div className="heading">Crop your Image</div>
              <IconButton onClick={handleClose}>
                <span class="material-icons">close</span>
              </IconButton>
            </Box>
          </DialogTitle>
          <div className="crop-image">
            <ReactCrop
              src={imgSrc}
              onImageLoaded={setImage}
              crop={crop}
              onChange={setCrop}
              ruleOfThirds
              className={classes1.cropImageStyle}
              style={{
                maxHeight: "400px",
                // minHeight: "200px",
                maxWidth: "400px",
                minWidth: "50px",
              }}
            />
          </div>
          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            mb={1}
            mr={1}
            style={{
              borderTop: "1px dashed lightgray",
              paddingTop: "12px",
            }}
          >
            <button className="apply-button" onClick={getCroppedImg}>
              Apply
            </button>
          </Stack>
        </Dialog>
      </>

      <div>
        {/* <div
          onClick={() => handleChangeImageSize("fit")}
          className={
            selectedSize === "fit"
              ? classes.imageTypeStylesSelected
              : classes.imageTypeStyles
          }
        >
          <img alt="" />
          <div>Fit Content</div>
        </div> */}
        {/* <div
          className={
            selectedSize !== "fit"
              ? classes.imageTypeStylesSelected
              : classes.imageTypeStyles
          }
          onClick={() => handleChangeImageSize("fill")}
        >
          <img alt="" />
          <div>Fill</div>
        </div> */}
        <div>
          {file && file !== sample_image && (
            <button
              onClick={handleCrop}
              style={{ width: "100px", fontSize: "12px" }}
              id="GA-crop-img"
            >
              Crop
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const ReportSelector = (props) => {
  const {
    user,
    reportDetails = {},
    reportState,
    setshowWarning,
    setReportState,
    URLValid,
    setURLValid,
    setFile,
    file,
    selectedSize,
    setSelectedSize,
    registerFields,
    registerTemplateFields,
    handleOpenDrawer,
    template_no,
    appFields, // TODO: I'll get this value from Redux state
    theme,
  } = props;

  const classes = useStylesforImageSelector();
  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("minWidth:1070px"));
  const isSmallScreen = !useMediaQuery("(min-width:1070px)");
  useEffect(() => {
    const initialState = getInitialState(
      //   sub_category_id
      v4(),
      //   sub_category_name
      "Untitled Report",
      //   sub_category_description
      "Your Description here",
      //   sub_category_type
      "report",
      //   sub_category_icon
      "dashboard",
      user.preferred_username,
      appFields
    );
    if (Object.keys(reportDetails).length !== 0) {
      setReportState(reportDetails);
    } else {
      setReportState(initialState);
    }
  }, []);

  // function: appFields.app_details.function,
  //         line_of_business: appFields.app_details.line_of_business,
  //         business_owners: [user.preferred_username],
  //         technical_owners: [user.preferred_username],
  //         geography: appFields.app_details.geography,

  // To update the values for local fields
  const handleChangeFieldVal = (
    key,
    val,
    blob_name,
    app_logo_type,
    action = "add"
  ) => {
    // we are getting val in array from the drop down, where we should get a single val
    let newVal = {};
    if (app_logo_type) {
      newVal = { ...reportState, [key]: val, blob_name, app_logo_type };
    } else if (key === "function") {
      if (reportState[key].includes(val)) {
        const arr = reportState[key].filter((item) => item !== val);
        newVal = { ...reportState, [key]: arr };
      } else if (val === "All") {
        newVal = { ...reportState, [key]: [val] };
      } else {
        if ((reportState[key] || []).includes("All")) {
          const filteredData = reportState[key].filter(
            (item) => item !== "All"
          );
          newVal = { ...reportState, [key]: [...filteredData, val] };
        } else {
          newVal = {
            ...reportState,
            [key]: [...(reportState[key] || []), val],
          };
        }
      }
    } else if (key === "line_of_business") {
      if (!reportState[key]) {
        newVal = { ...reportState, [key]: [val] };
      } else if (reportState[key].includes(val)) {
        const arr = reportState[key].filter((item) => item !== val);
        newVal = { ...reportState, [key]: arr };
      } else if (val === "Global") {
        newVal = { ...reportState, [key]: [val] };
      } else {
        if ((reportState[key] || []).includes("Global")) {
          const filteredData = reportState[key].filter(
            (item) => item !== "Global"
          );
          newVal = { ...reportState, [key]: [...filteredData, val] };
        } else {
          newVal = {
            ...reportState,
            [key]: [...(reportState[key] || []), val],
          };
        }
      }
    } else if (key === "business_owners" || key === "technical_owners") {
      if (!reportState[key]) {
        newVal = { ...reportState, [key]: [val] };
      } else if (reportState[key].includes(val) && action === "delete") {
        const arr = reportState[key].filter((item) => item !== val);
        newVal = { ...reportState, [key]: arr };
      } else if (reportState[key].includes(val) && action !== "delete") {
        return;
      } else {
        newVal = { ...reportState, [key]: [...reportState[key], val] };
      }
    } else {
      newVal = { ...reportState, [key]: val };
    }
    setReportState(newVal);
  };

  const handleChange = (key, val) => {
    const newState = { ...reportState, [key]: val };
    setReportState(newState);
  };

  return (
    <>
      <div
        style={{ display: isSmallScreen ? "block" : "flex" }}
        className={classes.ReportElementContainer}
      >
        {template_no !== 6 && (
          <div
            style={{
              width: isSmallScreen ? "100%" : "40%",
              display: "flex",
              justifyContent: isSmallScreen ? "center" : "start",
            }}
          >
            <ReportImage
              reportState={reportState}
              setReportState={setReportState}
              setFile={setFile}
              selectedSize={selectedSize}
              setSelectedSize={setSelectedSize}
              file={file}
              reportDetails={reportDetails}
              template_no={template_no}
            />
          </div>
        )}
        <div
          className={
            isSmallScreen
              ? classes.reportFieldsContainerSmall
              : template_no === 6
              ? classes.reportFieldsContainerSmall
              : classes.reportFieldsContainerLarge
          }
        >
          <Grid container spacing={1}>
            {reportState &&
              registerTemplateFields.fields[1].fields.map((item) => {
                return (
                  <React.Fragment key={item.key}>
                    {!handleHideComponent(
                      item.key,
                      "report",
                      reportState,
                      "new"
                    ) &&
                      getRequiredComponent(
                        item,
                        reportState,
                        setshowWarning,
                        handleChangeFieldVal,
                        URLValid,
                        setURLValid,
                        handleOpenDrawer,
                        theme,
                        "new",
                        template_no
                      )}
                  </React.Fragment>
                );
              })}
            {reportState &&
              registerFields.fields.map((item) => {
                return (
                  <React.Fragment key={item.key}>
                    {!handleHideComponent(
                      item.key,
                      "report",
                      reportState,
                      "new"
                    ) &&
                      getRequiredComponent(
                        item,
                        reportState,
                        setshowWarning,
                        handleChangeFieldVal,
                        URLValid,
                        setURLValid,
                        handleOpenDrawer,
                        theme,
                        "new",
                        template_no
                      )}
                  </React.Fragment>
                );
              })}
          </Grid>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  appFields: state.registerNewApp.appData.appFields,
  user: state.user,
  template_no: state.appTemplate.appTemplateState.template_no,
  registerTemplateFields:
    state.register.registerTemplate.data.register_app_template.fields[0]
      .options[0].subform_templates[2],
  registerFields:
    state.register.registerTemplate.data.register_app_template.fields[0]
      .options[0].subform_templates[0],
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(ReportSelector);
