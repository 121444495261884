import React from "react";
import { Grid } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import FormLabelComponent from "../../../../components/FormLabelComponent";
import SelectAppName from "./SelectAppName";
import AutoCompleteComponent from "../../../../components/AutoCompletes/AutoCompleteComponent";
import { set_help_center_state } from "../../../../redux/actions";

const ChooseApplication = ({ options = [], data = {}, helpCenterState }) => {
    const { label, required, key, toolTipText } = data;
    const { formData } = helpCenterState;
    const dispatch = useDispatch();

    const handleChange = (k, v) => {
        const reqData = {
            ...formData,
            [key]: { ...(formData[key] || {}), value: v.value || v },
        };
        dispatch(set_help_center_state({ formData: reqData }));
    };

    const getAppValue = (val) => {
        const field = options.find((item) => item.value === val);
        return field?.application_dropdown_required || false;
    };

    return (
        <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
                <FormLabelComponent
                    required={required}
                    label={label}
                    toolTipText={toolTipText}
                />
                <AutoCompleteComponent
                    label={label}
                    handleChange={handleChange}
                    options={options}
                    keyField={key}
                    value={formData[key]?.value || ""}
                />
            </Grid>
            {getAppValue(formData[key]?.value) && (
                <SelectAppName keyField={key} options={options} />
            )}
            {/* {formData[key]?.value?.short_text_box_required && (
                <InputTextBox data={data} handleChange={handleChange} />
            )} */}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    helpCenterState: state.helpCenter.helpCenterState,
});
export default connect(mapStateToProps)(ChooseApplication);
