import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import LayoutImageCard from "../Components/LayoutElements/LayoutImageCard";
import LayoutReportCard from "../Components/LayoutElements/LayoutReportCard";
import LayoutTextCard from "../Components/LayoutElements/LayoutTextCard";
import { connect, useDispatch } from "react-redux";
import AppReport from "../../../components/AppReport/AppReport.js";
import NewAppReport from "../../../components/AppReport/NewAppReport.js";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paper": {
      overflowY: "hidden",
      backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#f6f6f6",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "90vw !important",
      minWidth: "90vw !important",
      maxHeight: "95vh !important",
      minHeight: "95vh !important",
      display: "flex",
      flexDirection: "column",
    },
  },
  container: {
    display: "grid !important",
    "grid-template-columns": "19% 19% 19% 19% 19%",
    "grid-gap": "15px",
    // paddingBottom: "20px",
    flex: 1,
    paddingLeft: "25px",
    paddingRight: "25px",
    position: "relative",
  },
  textImageContainer: {
    display: "flex !important",
    // "grid-template-columns": "auto auto",
    // paddingBottom: "20px",
    margin: "10px 0px",
    flex: 1,
    paddingLeft: "25px",
    paddingRight: "25px",
    // position: "relative",
  },
  content: {
    flex: 1,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "10px",
    marginBottom: "20px",
  },
  discardBtn: {
    backgroundColor: "#E7E9EE !important",
    color: "#282828 !important",
  },
  addReportText: {
    fontSize: "14px",
    color: "#282828 !important",
  },
  addReportContainer: {
    height: "calc(95vh - 110px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyScreenImg: {
    height: "250px",
  },
  iconForScreenshot: {
    "& .googleIcons": {
      color: "#fc007f !important",
      "-webkit-text-fill-color": "#fc007f !important",
      backgroundColor: "transparent",
    },
  },
  normalIcon: {
    "& .googleIcons": {
      background:
        "linear-gradient(45deg, #FC007F 0%,#FC007F 50% ,#FF4D48 100%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    },
  },
}));

const Template6 = (props) => {
  const { appData, appDashboardState, user } = props;
  const classes = useStyles();
  const [breadCrumbsData, setBreadCrumbsData] = useState({
    appName: appDashboardState?.appData?.app_meta?.app_name,
  });
  const breadCrumbsData_length = Object.keys(breadCrumbsData).length;
  return (
    <LayoutTopSideBottom
      breadCrumbsData={breadCrumbsData}
      setBreadCrumbsData={setBreadCrumbsData}
    >
      {breadCrumbsData_length == 1 ? (
        <>
          <div className={classes.textImageContainer}>
            {appData.map((item) => {
              if (item.sub_category_type === "image") {
                return (
                  <LayoutImageCard
                    card_width="45vw"
                    card_height={"100px"}
                    imageDetails={item}
                  />
                );
              } else if (item.sub_category_type === "text") {
                return (
                  <LayoutTextCard
                    card_width="45vw"
                    card_height={"100px"}
                    textDetails={item}
                  />
                );
              }
            })}
          </div>
          <div style={{ height: "calc(100vh - 220px)", overflowY: "auto" }}>
            <div className={classes.container}>
              {appData.map((item, index) => {
                if (item.sub_category_type === "report") {
                  return (
                    <div
                      onClick={() => {
                        setBreadCrumbsData({
                          ...breadCrumbsData,
                          reportName: [item.sub_category_name, index],
                        });
                      }}
                    >
                      <LayoutReportCard
                        card_width="95%"
                        card_height={"200px"}
                        reportDetails={item}
                        editable={false}
                        template_number={6}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </>
      ) : (
        <div>
          {appData.map((item, index) => {
            if (index === breadCrumbsData.reportName[1]) {
              return (
                <NewAppReport
                  app={appDashboardState.appData}
                  activeReport={item}
                  breadCrumbsData={breadCrumbsData}
                  setBreadCrumbsData={setBreadCrumbsData}
                  user={user}
                />
                // <AppReport
                //   activeReport={item}
                //   user={user}
                //   app={appDashboardState.appData}
                // />
              );
            }
          })}
        </div>
      )}
    </LayoutTopSideBottom>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  appDashboardState: state.appDashboard.appDashboardState,
});

export default connect(mapStateToProps)(Template6);
