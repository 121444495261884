import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, IconButton } from "@mui/material";

import Card from "./component/FunctionCard";
import { function_icons } from "./data";
import functionLogo from "../../../assets/images/setup-images/functionLogo.svg";
import noFunction from "../../../assets/images/setup-images/functionsIcons/noFunction.svg";
import Loader from "../../components/Loader/Loader";
import "./index.scss";
import DescModal from "./component/DescModal";
import equal from "deep-equal";
import SearchBar from "material-ui-search-bar";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const FunctionsInfo = ({
  setActiveScreen,
  handleChange,
  configData,
  loading,
  setConfigData,
  setOthersValues,
  othersValues,
  theme,
  template,
  options,
  setOptions,
  functions_Options_List = [],
  handleUpdate,
  userInfo,
  waiting,
  tab,
}) => {
  const eventTracker = useAnalyticsEventTracker("Signup Functions");
  const [open, setOpen] = useState(false);
  const [funcFilteredList, setFuncFilteredList] = useState([]);

  const handleClose = () => setOpen(false);

  const handleDone = () => {
    if (!othersValues.function) return;
    setConfigData({
      ...configData,
      function: [...(configData.function || []), othersValues.function],
    });
    setOpen(false);
    eventTracker("Save other btn clicked", "Close popup");
  };

  const handleNext = () => {
    if (configData.function?.length) {
      setActiveScreen((prev) => prev + 1);
      eventTracker("Next btn clicked", "Navigate");
    }
  };

  const isDisabled = () => {
    if (
      equal(
        userInfo.user_interests?.function?.sort(),
        configData.function?.sort()
      ) ||
      !configData.function?.length
    ) {
      return true;
    } else return false;
  };

  const handleRoleChange = (key, val) => {
    // checking if card selected is other or not
    if (functions_Options_List.includes(val)) {
      handleChange(key, val);
      eventTracker("Function clicked", val);
    } else {
      // since card selected is other now checking if previously selected or not
      if (configData[key] && configData[key].includes(othersValues[key])) {
        const reqData = configData[key].filter(
          (item) => item !== othersValues[key]
        );
        setConfigData({
          ...configData,
          [key]: reqData,
        });
      } else {
        setOpen(true);
        eventTracker("Other card clicked", "open popup");
      }
    }
  };
  const GATrackEventForOther = (val, seleted_type) => {
    if (seleted_type === "new") {
      eventTracker("New role entered", val);
    } else {
      eventTracker("Existing Job selected", val);
    }
  };
  const handleUpdateAndTrack = () => {
    handleUpdate({ function: configData.function }, "function");
    eventTracker("Update btn clicked", "save");
  };
  const handleBack = () => {
    setActiveScreen((prev) => prev - 1);
    eventTracker("Back btn clicked", "navigate");
  };

  const requestSearch = (val) => {
    const filteredData = template.options?.filter((item) =>
      item.toLowerCase().includes((val || "").toLowerCase())
    );
    setFuncFilteredList(filteredData);
    // tracking the function if val not empty string
    if (val) {
      eventTracker("Search function", val);
    }
  };

  const cancelSearch = () => {
    setFuncFilteredList(template.options);
  };

  useEffect(() => {
    if (template.options?.length) {
      setFuncFilteredList(template.options);
    }
  }, [template.options]);
  return (
    <>
      <DescModal
        handleClose={handleClose}
        open={open}
        type="function"
        handleDone={handleDone}
        othersValues={othersValues}
        setOthersValues={setOthersValues}
        keyField="function"
        options={options}
        setOptions={setOptions}
        GATrackEventForOther={GATrackEventForOther}
      />
      <div>
        <img
          src={functionLogo}
          alt="icon"
          className="setup-card-container-logo"
        />
        <div className="setup-heading">{template.Description}</div>
      </div>
      {/* changed from 310px to 330px */}
      <div style={{ height: "330px", width: "100%" }}>
        <Box display="flex" justifyContent="flex-end" mr="28px" mb="14px">
          <SearchBar
            placeholder="Search functions"
            className="setup-search"
            style={{
              height: "30px",
              boxShadow: "none",
              backgroundColor: theme === "dark" ? "#282828" : "transparent",
            }}
            onChange={(val) => requestSearch(val)}
            onCancelSearch={() => cancelSearch()}
          />
        </Box>
        {funcFilteredList.length ? (
          <>
            <div
              className="setup-card-list"
              //   changed from maxHeight: "260px" to height: 360px
              style={{ height: "360px", overflowY: "auto" }}
            >
              {funcFilteredList.map((item) => (
                <Card
                  name={item}
                  key={item}
                  icon={function_icons(theme)[item]}
                  handleChange={handleRoleChange}
                  data={configData.function}
                  keyField={"function"}
                  defaultList={functions_Options_List}
                  value={othersValues.function}
                  theme={theme}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="setup-card-function-container">
            <img
              className="setup-card-function-not-found"
              src={noFunction}
              alt=""
            />
            <div>No function found.</div>
          </div>
        )}
      </div>
      <button
        className="setup-btn"
        onClick={handleNext}
        disabled={!configData.function?.length || waiting}
      >
        Next
      </button>
      <IconButton
        className="setup-back-btn"
        disabled={waiting || loading}
        onClick={handleBack}
      >
        <KeyboardBackspaceIcon />
      </IconButton>
      {tab && (
        <button
          className="setup-btn-update"
          disabled={isDisabled() || waiting || loading}
          onClick={handleUpdateAndTrack}
        >
          {waiting ? <Loader /> : "Update"}
        </button>
      )}
    </>
  );
};

export default FunctionsInfo;

// onClick={() =>
//     handleUpdate(
//         { function: configData.function },
//         "function"
//     )
// }

// onClick={() => setActiveScreen((prev) => prev - 1)}
