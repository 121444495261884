import React, { useState, useEffect } from "react";
import { Box, Dialog, DialogContent, IconButton, Stack } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { sendmailApproveReject } from "../../../apiServices";
import NotificationModal from "../../../components/AlertMsg/NotificationModal";
import { BootstrapInput } from "../style";
import "../index.scss";

function fetchAPI(payload) {
  const url = "/api/adminupdate";
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const ConfirmationDialog = ({
  open,
  handleClose,
  status,
  user,
  app_id,
  app_name,
  trackStatus,
  handleSendMail,
  userInfo,
}) => {
  const history = useHistory();
  const [comment, setComment] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const handleChange = (e) => setComment(e.target.value);

  const text = () => {
    if (trackStatus) {
      return `Kindly provide the justification for rejecting the request, this will be notified to the Application creator.`;
    }
    return `Kindly provide the justification for rejecting the request, this will be notified to the Application creator before they resubmit their Application.`;
  };

  const handleCloseAlert = () => {
    history.push("/myapps");
    setOpenModal(false);
    setAlertMsg("");
  };
  const handleSubmit = async (value) => {
    if (status === "rejected" && !comment) return;
    setWaiting(true);
    const payload = {
      app_id,
      approval_meta: {
        updated_on: moment().valueOf(),
        updated_by: user,
        approval_status: status,
      },
    };
    try {
      const res = await fetchAPI(payload);
      const response = await res.json();
      if (response.status.status === "datareckitt-200") {
        if (trackStatus) {
          handleSendMail(status, comment);
        } else {
          sendmailApproveReject({
            app_id,
            approval_status: status,
            comments: comment,
          });
        }
        handleClose();
        setAlertMsg(response.status.statusMessage);
        setOpenModal(true);
      } else {
        toast.error(
          response.status.statusMessage || "Opps! unable to update status"
        );
      }
      setWaiting(false);
    } catch (error) {
      setWaiting(false);
      console.log("error while updating status", error);
      toast.error("Opps! unable to update status");
    }
  };
  useEffect(() => {
    setComment("");
  }, [open]);
  return (
    <>
      <NotificationModal
        open={openModal}
        message={alertMsg || "successfully updated"}
        handleClose={handleCloseAlert}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundImage: "none",
            boxShadow: "4px 4px 12px 4px #434343",
          },
        }}
      >
        <div
          style={{
            width: status === "rejected" ? "600px" : "450px",
          }}
        >
          <DialogContent sx={{ padding: "10px 8px 20px 24px" }}>
            <Box display="flex" justifyContent="flex-end">
              {status === "rejected" && (
                <IconButton onClick={handleClose}>
                  <span class="material-icons">close</span>
                </IconButton>
              )}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <div
                className={`confirmationText ${userInfo.ui_preferences.theme}`}
              >
                {status === "rejected"
                  ? text()
                  : `Approve the Application Request for ${app_name}?`}
              </div>
              {status !== "rejected" && (
                <IconButton onClick={handleClose}>
                  <span class="material-icons">close</span>
                </IconButton>
              )}
            </Box>
            {status === "rejected" && (
              <>
                <BootstrapInput
                  value={comment}
                  onChange={handleChange}
                  rows={4}
                  multiline
                  fullWidth
                  sx={{ pr: "11px" }}
                />
              </>
            )}
          </DialogContent>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            px={2}
            pb={2}
          >
            <button onClick={handleClose} className="cancel-btn">
              Cancel
            </button>
            <button
              onClick={() => handleSubmit(comment)}
              disabled={waiting}
              className="btn"
            >
              {waiting ? <Loader color="#ffffff" /> : "Continue"}
            </button>
          </Stack>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.preferred_username,
  userInfo: state.user,
});

export default connect(mapStateToProps)(ConfirmationDialog);
