import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useStylesforImageSelector } from "../ImageSelector/Styles";
import { connect, useDispatch } from "react-redux";
import { useStylesforTemplateSelector } from "../AddNewElementPopUp/Styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// Import Utils
import templateData from "./TemplateData";
import AutoComplete from "./AutoComplete";
import { update_statusData } from "../../../../redux/actions";

const TemplateOptions = (props) => {
  const { setselectedTemplate, user, selectedTemplate } = props;

  const handleSelectElement = (el) => {
    setselectedTemplate(el);
  };

  const popup_classes = useStylesforTemplateSelector();
  return (
    <div className={popup_classes.elementsContainer}>
      {templateData.map((el) => (
        <div
          className={popup_classes.templateContainer}
          onClick={() => handleSelectElement(el.template_no)}
        >
          <div className={popup_classes.contentContainer}>
            <p
              style={{ width: "90%", display: "inline-block" }}
              className={
                el.template_no === selectedTemplate
                  ? popup_classes.selectedTemplateText
                  : popup_classes.elementText
              }
            >
              {el.label}
            </p>
            <span
              className={popup_classes.materialSymbolsButton}
              onClick={() => window.open(el.preview_link, "_blank")}
              title="show preview"
            >
              <span
                class="material-symbols-outlined"
                style={{ fontSize: "14px", color: "#fc007f" }}
              >
                preview
              </span>
            </span>
          </div>
          <img
            src={
              user.ui_preferences.theme === "light"
                ? el.template_img.light
                : el.template_img.dark
            }
            className={
              el.template_no === selectedTemplate
                ? popup_classes.selectedTemplateImage
                : popup_classes.elementImage
            }
            alt={el.label}
          />
          {el.template_no > 5 && (
            <div className={popup_classes.newTemplateSign}>New</div>
          )}
        </div>
      ))}
    </div>
  );
};

const SelectedTemplate = (props) => {
  const { setselectedTemplate, user, selectedTemplate } = props;

  const popup_classes = useStylesforTemplateSelector();
  return (
    <div className={popup_classes.templateContainer}>
      <img
        src={
          templateData.find((el) => el.template_no === selectedTemplate)
            .template_img[user.ui_preferences.theme]
        }
        style={{ height: "140%" }}
        className={popup_classes.oneElementImage}
        alt={"Template" + selectedTemplate}
      />
      {selectedTemplate > 5 && (
        <div
          className={popup_classes.newTemplateSign}
          style={{ bottom: "-100px" }}
        >
          New
        </div>
      )}
    </div>
  );
};

const TemplateSelector = (props) => {
  const {
    handleClose = () => {},
    openTemplateSelector = false,
    user,
    handleSave = () => {},
  } = props;

  const [selectedTemplate, setselectedTemplate] = useState(null);
  const [view, setView] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setselectedTemplate(1);
    setView("multiple_view");
  }, []);

  const handleSelectElement = (el) => {
    setselectedTemplate(el);
  };

  const handleSaveTemplate = () => {
    // TODO: call the imageuploadv2 api and save the data to an object
    handleSave(selectedTemplate);
    dispatch(update_statusData({ editLayout: true }));
  };
  const handleCancel = () => {
    handleClose();
  };
  const ChangeLayoutView = (VIEW) => {
    setView(VIEW);
  };
  const classes = useStylesforImageSelector();
  const popup_classes = useStylesforTemplateSelector();
  return (
    <Dialog
      open={openTemplateSelector}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={popup_classes.TemplateSelectorContainer}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          className={popup_classes.templateViewContainer}
          style={{ paddingTop: "20px" }}
        >
          <Box
            className=""
            m="0px 25px"
            // p="15px 0px"
            display="flex"
            // sx={{ alignItems: "center" }}
            alignItems={"center"}
          >
            <span style={{ paddingRight: "10px" }}>Select template </span>
            {view === "single_view" && (
              <AutoComplete
                selectedTemplate={selectedTemplate}
                handleSelectElement={handleSelectElement}
              />
            )}
          </Box>
          {/* icons */}
          <div className={popup_classes.buttonContainer}>
            <Tooltip title="Grid View">
              <button
                id="multiple_view"
                className={
                  view === "multiple_view"
                    ? popup_classes.inActiveButton
                    : popup_classes.activeButton
                }
                onClick={() => {
                  ChangeLayoutView("multiple_view");
                }}
              >
                <span
                  id="multiple_view"
                  class="material-icons-round"
                  style={{ fontSize: "20px" }}
                >
                  grid_view
                </span>
              </button>
            </Tooltip>

            <Tooltip title="Full View">
              <button
                id="single_view"
                className={
                  view === "single_view"
                    ? popup_classes.inActiveButton
                    : popup_classes.activeButton
                }
                onClick={() => {
                  ChangeLayoutView("single_view");
                }}
              >
                <span
                  id="single_view"
                  class="material-icons-outlined"
                  style={{ fontSize: "20px" }}
                >
                  image
                </span>
              </button>
            </Tooltip>
          </div>
        </div>
        <DialogContent className={popup_classes.ContentForElementSelector}>
          {view === "multiple_view" ? (
            <TemplateOptions
              user={user}
              selectedTemplate={selectedTemplate}
              setselectedTemplate={setselectedTemplate}
            />
          ) : (
            <SelectedTemplate
              user={user}
              selectedTemplate={selectedTemplate}
              setselectedTemplate={setselectedTemplate}
            />
          )}
        </DialogContent>
        <DialogActions className={popup_classes.dialog_footer}>
          <div>
            <button
              onClick={handleCancel}
              className={classes.SecondaryButtonStyles}
            >
              Cancel
            </button>
            <button
              onClick={handleSaveTemplate}
              className={classes.PrimaryButtonStyles}
            >
              Create
            </button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TemplateSelector);
