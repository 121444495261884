import React, { useState } from "react";
import Card from "./component/Card";
import DescModal from "./component/DescModal";
import equal from "deep-equal";
import jobHeaderLogoIcon from "../../../assets/images/setup-images/job-header-logo.svg";
import { jobRole_icons } from "./data";
import "./index.scss";
import Loader from "../../components/Loader/Loader";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const JobRole = ({
    setActiveScreen,
    configData,
    setConfigData,
    setOthersValues,
    othersValues,
    theme,
    template,
    options,
    setOptions,
    job_role_list = [],
    handleUpdate,
    userInfo,
    waiting,
    tab,
}) => {
    const eventTracker = useAnalyticsEventTracker("Signup Job role");
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        const job_role =
            job_role_list.includes(...(configData.job_role || [])) ||
            !configData.job_role?.length
                ? ""
                : configData.job_role[0];
        setOthersValues({ ...othersValues, job_role });
        setOpen(false);
    };

    const isDisabled = () => {
        if (
            equal(
                userInfo.user_interests?.job_role?.sort(),
                configData.job_role?.sort()
            ) ||
            !configData.job_role?.length
        ) {
            return true;
        } else return false;
    };
    const handleDone = () => {
        if (!othersValues.job_role) return;
        setConfigData({
            ...configData,
            job_role: [othersValues.job_role],
        });
        setOpen(false);
        eventTracker("Save other btn clicked", "Close popup");
    };

    const handleRoleChange = (key, val) => {
        if (job_role_list.includes(val)) {
            setConfigData({
                ...configData,
                [key]: [val],
            });
            eventTracker("Job role selected", val);
        } else {
            setOpen(true);
            eventTracker("Other card clicked", "Open popup");
        }
    };

    const handleNext = () => {
        if (configData.job_role?.length) {
            setActiveScreen((prev) => prev + 1);
            eventTracker("Next btn clicked", "Navigate");
        }
    };
    const GATrackEventForOther = (val, seleted_type) => {
        if (seleted_type === "new") {
            eventTracker("New role entered", val);
        } else {
            eventTracker("Existing Job selected", val);
        }
    };
    const handleUpdateAndTrack = () => {
        handleUpdate({ job_role: configData.job_role }, "job_role");
        eventTracker("Update btn clicked", "save");
    };

    return (
        <>
            <DescModal
                handleClose={handleClose}
                open={open}
                othersValues={othersValues}
                setOthersValues={setOthersValues}
                type="job role"
                handleDone={handleDone}
                keyField="job_role"
                options={options}
                setOptions={setOptions}
                GATrackEventForOther={GATrackEventForOther}
            />
            <div>
                <img
                    src={jobHeaderLogoIcon}
                    alt=""
                    className="setup-card-container-logo"
                />

                <div className="setup-heading">
                    {template.Description || ""}
                </div>
            </div>
            <div className="setup-card-list-job-container">
                <div
                    className="setup-card-list-job-container-inner"
                    style={{ maxHeight: "280px", overflowY: "auto" }}
                >
                    {template.options.length &&
                        template.options.map((item) => (
                            <Card
                                icon={
                                    jobRole_icons[
                                        item.toLowerCase()?.replaceAll(" ", "_")
                                    ]
                                }
                                key={item.name}
                                handleChange={handleRoleChange}
                                data={configData.job_role}
                                keyField={"job_role"}
                                name={item}
                                defaultList={job_role_list}
                                value={othersValues.job_role}
                                theme={theme}
                            />
                        ))}
                </div>
            </div>
            <button
                className="setup-btn"
                onClick={handleNext}
                disabled={!configData.job_role?.length || waiting}
            >
                Next
            </button>
            {tab && (
                <button
                    className="setup-btn-update"
                    disabled={isDisabled() || waiting}
                    onClick={handleUpdateAndTrack}
                >
                    {waiting ? <Loader /> : "Update"}
                </button>
            )}
        </>
    );
};

export default JobRole;

// onClick={() =>
//     handleUpdate(
// { job_role: configData.job_role },
// "job_role"
//     )
// }
