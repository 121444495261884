import React from "react";
import SwaggerUI from "swagger-ui-react";

import "swagger-ui-react/swagger-ui.css";
function Swagger() {
    return (
        <div style={{ height: "100vh", overflow: "auto" }}>
            <SwaggerUI url="https://data-reckitt-dev.rbone.net/swagger/swagger.json" />
        </div>
    );
}

export default Swagger;
