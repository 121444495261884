import { makeStyles } from "@material-ui/core";

// Define Styles
export const tabListStyle = {
    "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        height: "2.5px",
        marginBottom: "5px",
    },
    "& .MuiTabs-indicatorSpan": {
        maxWidth: 30,
        width: "100%",
        backgroundColor: "#FC007F",
    },
};
export const tabStyle = {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "23px",
    textTransform: "none",
    color: "#FC007F",
    opacity: 0.6,
    "&.Mui-selected": {
        color: "#FC007F",
        opacity: 1,
    },
    "&.Mui-focusVisible": {
        backgroundColor: "transparent",
    },
};

export const viewStyles = makeStyles((theme) => ({
    inActiveButton: {
        borderRadius: "2px",
    },
    activeButton: {
        backgroundColor: theme.palette.mode === "light" ? "#fff" : "#282828",
        color: "#FC007F",
        borderRadius: "2px",
    },
    buttonContainer: {
        backgroundColor: theme.palette.mode === "light" ? "#fff" : "#282828",
        borderRadius: "2px",
        display: "flex",
        padding: "4px",
        width: "75px",
        height: "30px",
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between !important",
    },
}));

export const useStylesForJiraTicket = makeStyles((theme) => ({
    ticketCardContainer: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "8px",
        width: "300px",
        height: "150px",
        backgroundColor: theme.palette.mode === "light" ? "#fff" : "#282828",
        boxShadow: "0px 5px 15px 0px #00000014",
        borderRadius: "8px",
        cursor: "pointer",
        border: "1px solid transparent",
        "&:hover": {
            border: "1px solid #aab4bd",
        },
    },
    ticketCardSummary: {
        fontFamily: "Energy",
        fontSize: "15px",
        fontWeight: "bold",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        overflow: "hidden",
        overflowWrap: "anywhere",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        webkitLineClamp: "1",
        lineClamp: "1",
        webkitBoxOrient: "vertical",
        margin: "5px 0px 0px 0px",
    },
    ticketCardDescription: {
        margin: "5px 0px 0px 0px",
        fontSize: "11px",
        fontFamily: "Energy",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        overflow: "hidden",
        overflowWrap: "anywhere",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        webkitLineClamp: "2",
        lineClamp: "2",
        webkitBoxOrient: "vertical",
    },
    alertContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& .MuiDialog-paper": {
            overflowY: "hidden",
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "900px !important",
            minWidth: "900px !important",
            maxHeight: "750px !important",
            minHeight: "750px !important",
        },
    },
    ticketKey: {
        margin: "0px",
        marginRight: "20px",
        color: "#FC007F",
        fontWeight: "700",
    },
    ticketSummary: { margin: "0px", fontWeight: "700", fontSize: "18px" },
    ticketStatus: { margin: "0px", fontWeight: "700" },
    ticketDescription: {
        margin: "0px",
        fontSize: "14px",
        wordBreak: "break-all",
    },
    ticketDescriptionLess: {
        margin: "0px",
        fontSize: "14px",
        wordBreak: "break-all",
        overflow: "hidden",
        overflowWrap: "anywhere",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        webkitLineClamp: "2",
        lineClamp: "2",
        webkitBoxOrient: "vertical",
    },
    ticketContentContainer: { height: "690px", overflowY: "auto" },
    ticketMetaContainer: {
        padding: "10px",
        borderBottom: "1px solid lightgrey",
        borderRadius: "5px",
    },
    ticketMetaHeading: {
        margin: "0px 10px 0px 0px",
        fontWeight: "700",
        fontSize: "14px",
        textTransform: "capitalize",
    },
    ticketMetaContent: { margin: "0px 10px 0px 0px", fontSize: "14px" },
    contentHeading: { margin: "0px", fontWeight: "700", fontSize: "14px" },
    attachmentContainer: { marginRight: "10px", position: "relative" },
    attachmentImage: { height: "80px" },
    attachmentPDF: {
        display: "flex",
        height: "80px",
        width: "70px",
        background: "#aab4bd",
        borderRadius: "4px",
        alignItems: "center",
        justifyContent: "center",
        margin: "5px 0px",
    },
    attachmentFileName: { margin: "0px 2px" },
    commentContainer: {
        border: "1px solid #aab4bd",
        borderRadius: "5px",
        padding: "5px 10px",
        marginBottom: "5px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    commentBody: { margin: "0px", fontSize: "14px", whiteSpace: "pre-wrap" },
    commentImage: { height: "60px" },
    commentTime: {
        margin: "0px",
        fontSize: "10px",
        color: "#aab4bd",
    },
    commenterName: {
        margin: "0px",
        color: "#FC007F",
        fontWeight: "700",
        fontSize: "12px",
    },
    commentSection: {
        borderRadius: "4px",
        border: "1px solid #aab4bd",
        padding: "5px",
        marginBottom: "10px",
    },
    textAreaCS: {
        resize: "none",
        border: "0px",
        width: "98%",
        fontFamily: "Energy",
        marginBottom: "10px",
        background: "transparent",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
    postButton: {
        // background: "#282828",
        color: "white",
        marginLeft: "10px",
        borderRadius: "50px",
        padding: "0px 20px",
        "&:disabled": {
            opacity: "50%",
        },
    },
    expandButton: {
        color: "#FC007F",
        cursor: "pointer",
        margin: "0px",
        fontSize: "10px",
        fontWeight: "700",
    },
    cancelButton: {
        border: "1px solid #282828",
        background: "white",
        color: "#282828",
        borderRadius: "50px",
        padding: "0px 20px",
        "&:disabled": {
            opacity: "50%",
        },
    },
    closeButton: {
        color: "#aab4bd",
        fontSize: "22px",
        marginRight: "5px",
    },
    ticketOuterContainer: {
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
    displayFlex: {
        display: "flex",
        alignItems: "center",
        margin: "5px 0px",
    },
    displayFJustSBAlignCen: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));
