// Import required libraies
import React, { useEffect, useState } from "react";
import orderBy from "lodash/orderBy";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

// Import Custom Component
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import DataAssetsFilters from "./DataAssetsFilters/DataAssetsFilters";
import DataAssetsTable from "./DataAssetsTable/DataAssetsTable";
import DataAssetsSideNav from "./DataAssetsSideNav/DataAssetsSideNav";

// Import action creators
import { updateUserInfo, updated_total_views } from "../../redux/actions";

// Import utils/data
import { config } from "../../config/config";
import {
  makeDefaultResponseJson,
  addAllInData,
  filterMyConfigs,
} from "../../utils/utils";
import dataCollectionsAssetsData from "../../../assets/data/dataCollectionsAssetsData.json";

// Import styles
import "./AIDEDataAssets.scss";

function AIDEDataAssets(props) {
  let { match, user, updateUserInfo, activeAssetCollection, theme } = props;
  const initialData = {
    loading: true,
    error: false,
    data: null,
  };

  const initialFilters = {
    country: {
      id: "ALL",
      name: "ALL",
    },
    brand: {
      id: "ALL",
      name: "ALL",
    },
    archetype: {
      id: "ALL",
      name: "ALL",
    },
  };

  const [pageReady, setPageReady] = useState(false);
  const [collections, setCollections] = useState(initialData);
  const [countryData, setCountryData] = useState(initialData);
  const [brandData, setBrandData] = useState(initialData);
  const [archetypeData, setArchetypeData] = useState(initialData);
  const [dataSources, setDataSources] = useState(initialData);
  const [filterDataSources, setFilterDataSources] = useState(initialData);
  const [activeCollection, setActiveCollection] = useState(false);
  const [activeFilter, setActiveFilter] = useState(initialFilters);
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();

  //make data catalog Collections data
  useEffect(() => {
    dispatch(
      updated_total_views({
        app_id: "data-assets",
      })
    );
    setCollections({
      ...initialData,
      loading: false,
      data: dataCollectionsAssetsData,
    });
    if (activeAssetCollection.id && activeAssetCollection.name) {
      setActiveCollection(activeAssetCollection);
    } else {
      setActiveCollection(dataCollectionsAssetsData[0]);
    }
    return () => {};
  }, []);

  // Get data Catalog Country data
  useEffect(() => {
    let url = `${config.api.dataCatalogCountryUrl}`;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        // console.log("REPSONSE -> ", response);
        if (response.status === 200) {
          return response.json();
        } else {
          let error = response.statusText;
          let errorCode = response.status;
          console.log("ERROR -> ", error);
          return { ...makeDefaultResponseJson(), error, errorCode };
        }
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (json.errorCode)
          updateUserInfo({
            error: json.error,
            errorCode: json.errorCode,
          });
        else {
          setCountryData({
            ...initialData,
            loading: false,
            data: orderBy(json.data, ["name"], ["asc"]), // Removed All from dropdown
          });
        }
      });
    return () => {};
  }, []);

  // Get data Catalog Brand data
  useEffect(() => {
    let url = config.api.dataCatalogBrandUrl;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        if (response.status === 200) {
          return response.json();
        } else {
          let error = response.statusText;
          let errorCode = response.status;
          console.log("ERROR -> ", error);
          return { ...makeDefaultResponseJson(), error, errorCode };
        }
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (json.errorCode)
          updateUserInfo({
            error: json.error,
            errorCode: json.errorCode,
          });
        else {
          setBrandData({
            ...initialData,
            loading: false,
            data: orderBy(json.data, ["name"], ["asc"]), // Removed All from dropdown
          });
        }
      });
    return () => {};
  }, []);

  // Get data Catalog Archetype data
  useEffect(() => {
    let url = config.api.dataCatalogArchetypeUrl;
    fetch(url)
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        if (response.status === 200) {
          return response.json();
        } else {
          let error = response.statusText;
          let errorCode = response.status;
          console.log("ERROR -> ", error);
          return { ...makeDefaultResponseJson(), error, errorCode };
        }
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (json.errorCode)
          updateUserInfo({
            error: json.error,
            errorCode: json.errorCode,
          });
        else {
          setArchetypeData({
            ...initialData,
            loading: false,
            data: addAllInData(orderBy(json.data, ["name"], ["asc"])),
          });
        }
      });
    return () => {};
  }, []);

  // Get data Catalog Table data
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (pageReady) {
      setDataSources(initialData);
      let body = {
        app_id: "data-assets",
        ...(activeFilter.country?.name !== "ALL" && {
          country:
            activeFilter.country[config.hardCoded.mappingStructure.country],
        }),
        ...(activeFilter.brand?.id !== "ALL" && {
          brand: activeFilter.brand[config.hardCoded.mappingStructure.brand],
        }),
        ...(activeFilter.archetype?.id !== "ALL" && {
          archetype:
            activeFilter.archetype[config.hardCoded.mappingStructure.archetype],
        }),
      };
      if (
        activeCollection.id !== "recent" &&
        activeCollection.id !== "starred"
      ) {
        body.xplore_archetype = activeCollection.id;
      }
      let url = config.api.dataAssetsTableUrl;
      fetch(url, {
        signal,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            return response.json();
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              ...makeDefaultResponseJson(),
              error,
              errorCode,
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (json.errorCode) {
            updateUserInfo({
              error: json.error,
              errorCode: json.errorCode,
            });
          } else {
            setDataSources({
              ...initialData,
              loading: false,
              data: json.data,
            });
          }
        })
        .catch((e) => {
          console.warn(`Fetch 2 error: ${e.message}`);
        });
    }
    return () => {
      controller.abort();
    };
  }, [pageReady, activeCollection, activeFilter]);
  //, activeCollection, activeFilter

  useEffect(() => {
    if (
      activeCollection &&
      countryData &&
      brandData &&
      archetypeData &&
      !pageReady
    ) {
      setPageReady(true);
    }
  }, [activeCollection, countryData, brandData, archetypeData]);

  useEffect(() => {
    let filteredData = filterMyConfigs(
      dataSources?.data,
      "dataset_name",
      {
        searchText: searchText,
      },
      false,
      "data-assets"
    );
    setFilterDataSources({ ...dataSources, data: filteredData });
  }, [dataSources.data, searchText]);

  return (
    <>
      <div className={`${user.theme} page`}>
        {/* <Header match={match} /> */}
        <LayoutTopSideBottom match={match}>
          <section className="body" style={{ paddingTop: "0px" }}>
            <DataAssetsSideNav
              user={user}
              match={match}
              collections={collections}
              setCollections={setCollections}
              activeCollection={activeCollection}
              setActiveCollection={setActiveCollection}
              theme={theme}
            />
            <main
              className={`content-container ${theme} ${
                user.screen.isSidenavBarExpanded
                  ? "main-expanded-container"
                  : "main-collapsed-container"
              }`}
            >
              <DataAssetsFilters
                user={user}
                countryData={countryData}
                setCountryData={setCountryData}
                brandData={brandData}
                setBrandData={setBrandData}
                archetypeData={archetypeData}
                setArchetypeData={setArchetypeData}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                searchText={searchText}
                setSearchText={setSearchText}
              />
              <DataAssetsTable
                user={user}
                dataSources={dataSources}
                setDataSources={setDataSources}
                filterDataSources={filterDataSources}
                setFilterDataSources={setFilterDataSources}
                updateUserInfo={updateUserInfo}
              />
            </main>
          </section>
          {/* <Footer match={match} /> */}
        </LayoutTopSideBottom>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  activeAssetCollection: state.data.activeAssetCollection,
  theme: state.user.ui_preferences?.theme,
});

const mapDispatchToProps = {
  updateUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIDEDataAssets);
