import C from "../constants";

export default function changeFlag(state = {}, payload) {
    let { type, data } = payload;
    switch (type) {
        case C.REFRESH_COMMENTS:
            return { ...state, refreshComments: !state.refreshComments };
        case C.UPDATE_CURRENT_TAB:
            return { ...state, currentTab: data };
        default:
            return state;
    }
}
