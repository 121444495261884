// Import required liraries
import React, { useEffect } from "react";
import { connect } from "react-redux";

// Import styles
import "./UnderMaintenance.scss";

// Import Custom Component
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";
import LayoutTopBottom from "../../layouts/LayoutTopBottom/LayoutTopBottom";

// Import data/utils
import undermaintainimg from "../../../assets/images/undermaintainimg.svg";
import { useHistory } from "react-router";

function UnderMaintenance(props) {
  const { user } = props;
  const history = useHistory();

  useEffect(() => {
    if (user.active_status === "active") {
      history.push("/myapps");
    }
  }, []);

  return (
    <LayoutTopBottom>
      <div className="undermaintain-container">
        <img
          style={{ width: "700px", marginBottom: "50px" }}
          src={undermaintainimg}
          alt="Under Scheduled Maintain"
        ></img>
        <div>
          <span className="UM-pink UM-text">Data@Reckitt </span>
          <span className="UM-charcoal UM-text">
            is under Scheduled Maintenance
          </span>
        </div>

        <span className="UM-charcoal UM-text">
          We will be back by 18.30 UTC
        </span>
      </div>
    </LayoutTopBottom>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

UnderMaintenance.propTypes = {};

export default connect(mapStateToProps)(UnderMaintenance);
