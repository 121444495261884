// Import required libraries
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
// Import custom components
import UserInfo from "../UserInfo/UserInfo";

// Import styles
import "./Header.scss";
import RBHeaderComponent from "../../screens/Explore/RBHeaderComponent";
import DrawerFiltersContainer from "../../screens/Explore/DrawerContainer";
import ConfirmationModal from "../AlertMsg/ConfirmationModal";
import { toast } from "react-toastify";
import {
  get_user_filters,
  updateReportTemplate,
  updateUserInfo,
  update_BIappDetails,
  update_appData,
  update_statusData,
} from "../../redux/actions";
import { config } from "../../config/config";
import { useParams } from "react-router";

import { isEqualObject } from "../../utils/utils";

import EditPreviewToggle from "../../screens/NewRegister/Components/EditPreviewToggle";

import Loader from "../Loader/Loader";
import ReportHeader from "../../screens/AppDashboard/Components/ReportHeader";

function fetchAPI(payload) {
  const url = `/api/smartsearchapp`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const viewStyles = {
  inActiveButton: {
    borderRadius: "4px",
    backgroundColor: "#282828",
    lineHeight: "1.1",
  },
  activeButton: {
    backgroundColor: "#fff",
    color: "#282828",
    borderRadius: "4px",
    lineHeight: "1.1",
  },
  buttonContainer: {
    backgroundColor: "#F5F5F5",
    borderRadius: "5px",
    display: "flex",
    // padding: "4px",
    // width: "102px",
    height: "30px",
    position: "absolute",
    right: "110px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between !important",
  },
};

function fetchThemeAPI(payload) {
  const url = "/api/themeupdate";
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

function Header(props) {
  const history = useHistory();
  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    if (window.location.href.includes("sigview")) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  }, [window.location.href]);

  let {
    user,
    match,
    app_name = "",
    AppsData,
    buttonStatus,
    registerNewApp,
    appDetails,
    template_no,
    old_template_no,
    editable_layout,
    oldbreadCrumbsData,
    breadCrumbsData = {},
    setBreadCrumbsData,
    appTemplateState,
    theme,
    showScreenshot,
    waitingForScreenshot,
    savedTemplateData,
    localstateSetFirstTime,
  } = props;
  const { showSubCatPage, temp_img_data } = appTemplateState;
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState("");
  const { type, value } = useParams();
  const { appInfo, draftId, tab } = useParams();
  // const [appStateDataLocal, setAppStateDataLocal] = useState({});
  // DRAFT === false   OR    register,pending,approved,published === true (CANCEL)
  const [loadingFilter, setLoadingFilter] = useState("pending");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [search_columns, setSearchColumns] = useState([]);
  const [view, setView] = useState("large_grid");
  const [searchResults, setSearchResults] = useState({
    data: [],
    status: "pending",
  });
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);
  const confirmationMessage =
    "Are you sure you want to discard the layout changes ?";

  let app_id = null;
  let draft_id = null;
  let approval_status = null;
  // let appTemplateStateLocal = { ...appTemplateState };
  // taking the approval_status & app_id from the params of the route of apps which r not draft one.
  if (appInfo) {
    const data = appInfo.split("_");
    app_id = data[0];
    approval_status = data[1];
  }
  // taking the approval_status & route from the route of the draft apps
  if (draftId) {
    const data = draftId.split("_");
    draft_id = data[0];
    approval_status = data[1];
  }

  useEffect(() => {
    dispatch(
      updateUserInfo({
        ...user,
        filteredApps: {
          data: searchResults.data,
          status: searchResults.status,
        },
      })
    );
  }, [searchResults]);

  const ChangeLayoutView = async (selectedLayout) => {
    setView(selectedLayout);
    dispatch(
      updateUserInfo({
        ...user,
        ui_preferences: { ...user.ui_preferences, grid_size: selectedLayout },
      })
    );
    const body = {
      user_id: user.preferred_username,
      ui_preferences: {
        ...user.ui_preferences,
        grid_size: selectedLayout,
      },
    };
    try {
      const res = await fetchThemeAPI(body);
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200") {
      } else {
        toast.error("Theme Request Failed.");
      }
    } catch (error) {
      toast.error("Theme Request Failed.");
    }
  };

  useEffect(() => {
    setView(user.ui_preferences.grid_size || "large_grid");
  }, []);

  // set view by state - initialize by App Category
  const [viewByFilter, setViewByFilter] = useState(
    user.viewByFilter || "app_category"
  );
  const page = window.location.pathname.split("/")[1];
  const getHeaderText = () => {
    if (page === "myapps") {
      return "My Apps";
    } else if (page === "explore") {
      if (type === undefined) {
        return "Data@Reckitt";
      } else return "Explore All Apps";
    } else if (page === "home") {
      return "Home";
    } else if (page === "register") {
      if (appDetails.data.app_id) {
        return appDetails.data.app_meta.app_name;
      } else return "Register Application";
    } else if (page === "faqs") {
      return "FAQs";
    } else if (page === "help") {
      return "Help Center";
    } else if (page === "profile-management") {
      return "Manage Profile";
    } else if (app_name.length) {
      return app_name;
    } else {
      return "";
    }
  };

  const handleOpenFilterDrawer = () => setDrawerOpen(true);

  const currentPath = window.location.pathname.split("/")[1];
  const handleSearchApp = useCallback(
    async (payload) => {
      setLoadingFilter("loading");
      if (currentPath === "home" && AppsData.layoutApps.status === "success") {
        try {
          const res = await fetchAPI(payload);
          const response = await res.json();
          if (response.status?.status === "datareckitt-200") {
            const responsedApps = [];
            if (payload.search_string || payload.search_columns.length) {
              response.data.forEach((item) => {
                if (item.hasOwnProperty("count")) {
                  if (item.count > 0) {
                    responsedApps.push(item.app_id);
                  }
                } else if (item.hasOwnProperty("app_meta")) {
                  responsedApps.push(item.app_id);
                }
              });
              const allAppIDsHome = AppsData.layoutApps.data.map(
                (app) => app.app_id
              );
              const filteredList = allAppIDsHome.filter((value) =>
                responsedApps.includes(value)
              );
              setSearchResults({ data: [...filteredList], status: "success" });
            } else {
              // response.data.forEach((item) => {
              //   responsedApps.push(item.app_id);
              // });
              setSearchResults({ data: [], status: "pending" });
            }
          }
          setLoadingFilter("success");
        } catch (error) {
          toast.error("Oops! somthing went wrong. Please try later.");
          setLoadingFilter("error");
        }
      } else if (
        currentPath === "explore" &&
        AppsData.allApps.status === "success" &&
        type === undefined
      ) {
        try {
          const res = await fetchAPI(payload);
          const response = await res.json();
          if (response.status?.status === "datareckitt-200") {
            const responsedApps = [];
            if (payload.search_string || payload.search_columns.length) {
              response.data.forEach((item) => {
                if (item.hasOwnProperty("count")) {
                  if (item.count > 0) {
                    responsedApps.push(item.app_id);
                  }
                } else if (item.hasOwnProperty("app_meta")) {
                  responsedApps.push(item.app_id);
                }
              });
              const allAppIDsExplore = AppsData.allApps.data.map(
                (app) => app.app_id
              );
              const filteredList = allAppIDsExplore.filter((value) =>
                responsedApps.includes(value)
              );
              setSearchResults({ data: [...filteredList], status: "success" });
            } else {
              // response.data.forEach((item) => {
              //   responsedApps.push(item.app_id);
              // });
              setSearchResults({ data: [], status: "pending" });
            }
          }
          setLoadingFilter("success");
        } catch (error) {
          toast.error("Oops! somthing went wrong. Please try later.");
          setLoadingFilter("error");
        }
      }
      setLoadingFilter("pending");
    },
    [AppsData.allApps.data, AppsData.layoutApps.data, currentPath]
  );

  const getSearchColumns = (filterInfo) => {
    let my_columns = [];
    Object.keys(filterInfo).forEach((item) => {
      if (filterInfo[item] && filterInfo[item].length) {
        my_columns = [
          ...my_columns,
          {
            name: item,
            type:
              config.specialFields.includes(item) && item !== "geography"
                ? "multi"
                : item === "geography"
                ? "custom"
                : "single",
            values:
              item === "app_type" &&
              filterInfo[item] === config.hardCoded.embedded_analytics
                ? "bi_app"
                : item === "app_type"
                ? filterInfo[item].toLowerCase().replaceAll(" ", "_")
                : filterInfo[item],
          },
        ];
      }
    });
    return my_columns;
  };

  const handleDraftDiscard = () => {
    dispatch(update_statusData({ editLayout: false }));
    // dispatch(
    //   update_appData({
    //     visited: { ...visited, category: false },
    //   })
    // );
    dispatch(updateReportTemplate({ template_no: null }));
    setConfirmationModalOpen(false);
  };

  // const handleDraftBack = () => {
  //   dispatch(update_activeStepper(2));
  // };

  useEffect(() => {
    if (AppsData.user_filters.status === "pending") {
      dispatch(get_user_filters({ user_id: user.preferred_username }));
    }
  }, [AppsData.user_filters.status, dispatch, user.preferred_username]);

  useEffect(() => {
    if (AppsData.user_filters.status === "success") {
      const searchColumns = getSearchColumns(AppsData.user_filters.data);
      handleSearchApp({
        search_columns: searchColumns,
        search_string: "",
      });
    }
  }, [
    AppsData.user_filters.data,
    AppsData.allApps.data,
    AppsData.layoutApps.data,
  ]);

  const handleDiscard = () => {
    if (editable_layout) {
      dispatch(update_statusData({ editLayout: false, discardChanges: true }));
    } else {
      dispatch(update_statusData({ editLayout: true, discardChanges: true }));
    }

    if (!app_id && !draftId) {
      dispatch(
        update_BIappDetails({
          categoryInfoLocal: [...savedTemplateData?.templateData],
          // categoryInfo: [...appTemplateState.templateData],
        })
      );
      // } else {
      dispatch(
        updateReportTemplate({
          templateData: savedTemplateData?.templateData,
          template_no: savedTemplateData?.template_no,
          temp_img_data: savedTemplateData?.temp_img_data || {},
        })
      );
      // }
    } else {
      // dispatch(update_statusData({ editLayout: false, discardChanges: true }));
      // if (template_no > 5) {
      dispatch(
        update_BIappDetails({
          categoryInfoLocal: [...savedTemplateData?.templateData],
          // categoryInfo: [...appTemplateState.templateData],
        })
      );
      // } else {
      dispatch(
        updateReportTemplate({
          templateData: savedTemplateData?.templateData,
          template_no: savedTemplateData?.template_no,
          temp_img_data: savedTemplateData?.temp_img_data || {},
        })
      );
      // }
    }
  };

  const handleSaveTemplate = async () => {
    if (template_no > 5) {
      dispatch(
        update_appData({
          savedTemplateData: {
            templateData: [...registerNewApp.BIappDetails.categoryInfoLocal],
            template_no: template_no,
            temp_img_data: temp_img_data || {},
          },
        })
      );
    } else {
      dispatch(
        update_appData({
          savedTemplateData: {
            templateData: [...appTemplateState.templateData],
            template_no: template_no,
            temp_img_data: temp_img_data || {},
          },
        })
      );
    }
    // dispatch(update_statusData({ showScreenshot: false }));
    if (localstateSetFirstTime) {
      dispatch(update_statusData({ localstateSetFirstTime: false }));
    }
    dispatch(update_statusData({ editLayout: false, takeScreenshot: true }));
  };

  const checkIsObjectEqual = () => {
    if (template_no > 5) {
      return isEqualObject(
        registerNewApp.BIappDetails.categoryInfoLocal,
        savedTemplateData?.templateData
      );
    }
    return isEqualObject(
      appTemplateState.templateData,
      savedTemplateData?.templateData
    );
  };
  const isSaveTemplateDisabled = () => {
    let templateData = null;
    if (template_no > 5) {
      templateData = registerNewApp.BIappDetails.categoryInfoLocal;
    } else {
      templateData = appTemplateState.templateData;
    }

    if (templateData !== null && Object.keys(templateData).length === 0) {
      return true;
    }

    if (localstateSetFirstTime) {
      return waitingForScreenshot;
    }
    return waitingForScreenshot || checkIsObjectEqual();
  };

  const isDiscardChangesDisabled = () => {
    // if (localstateSetFirstTime) {
    //   return waitingForScreenshot || selectedWorkspace;
    // }
    return waitingForScreenshot;
  };

  return (
    <>
      <header
        className={`${
          page === "account-setup"
            ? `${user.ui_preferences.theme}-account-setup`
            : `${user.ui_preferences.theme}`
        }`}
        style={{
          zIndex: user.report_fullscreen ? -1 : 1,
          width: showBackButton ? "100vw" : "calc(100vw - 60px)",
        }}
      >
        <div
          title={getHeaderText()}
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className={`main-header-text text-${user.ui_preferences.theme}`}>
            {getHeaderText().length > 20
              ? getHeaderText().slice(0, 20) + "..."
              : getHeaderText()}
          </p>
          {appDetails.status === "success" && (
            <div title={appDetails.data.approval_meta.approval_status}>
              {appDetails.data.approval_meta.approval_status === "pending" && (
                <i
                  className="material-icons-round header-icon-app-status-style status-schedule "
                  id={appDetails?.app_id}
                  title="Pending"
                >
                  schedule
                </i>
              )}
              {appDetails.data.approval_meta.approval_status === "draft" && (
                <i
                  className="material-icons-round header-icon-app-status-style status-schedule"
                  id={appDetails?.app_id}
                  title="Draft"
                >
                  drafts
                </i>
              )}
              {appDetails.data.approval_meta.approval_status === "rejected" && (
                <i
                  className="material-icons-round header-icon-app-status-style status-rejected"
                  id={appDetails?.app_id}
                  title="Rejected"
                >
                  error
                </i>
              )}
              {appDetails.data.approval_meta.approval_status === "approved" && (
                <i
                  className="material-icons-round header-icon-app-status-style status-approved"
                  id={appDetails?.app_id}
                  title="Approved"
                >
                  check_circle
                </i>
              )}
              {appDetails.data.approval_meta.approval_status ===
                "published" && (
                <i
                  className="material-icons-round header-icon-app-status-style status-published"
                  id={appDetails?.app_id}
                  title="Published"
                >
                  verified
                </i>
              )}
            </div>
          )}
        </div>
        {Object.keys(breadCrumbsData).length === 2 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              onClick={() => {
                setBreadCrumbsData({ appName: breadCrumbsData.appName });
              }}
              style={{ color: theme === "dark" ? "#fff" : "#000" }}
            >
              {breadCrumbsData.appName}
            </span>{" "}
            <span
              class="material-icons-outlined selected-header"
              style={{ fontSize: "18px" }}
            >
              chevron_right
            </span>
            <span className="selected-header">
              {breadCrumbsData.reportName[0]}
            </span>
          </div>
        ) : Object.keys(breadCrumbsData).length === 1 ? (
          <div
            className="selected-header"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {breadCrumbsData.appName}
          </div>
        ) : null}
        {showBackButton && (
          <button
            style={{
              position: "absolute",
              left: "10px",
              color: theme === "light" ? "#000" : "#fff",
            }}
            className={`btn btn-back btn-back-${theme}`}
            onClick={() => {
              history.push("/data-assets_published");
            }}
          >
            Back
          </button>
        )}
        {old_template_no > 1 && old_template_no < 6 && (
          <ReportHeader oldbreadCrumbsData={oldbreadCrumbsData} />
        )}

        {/* {history.location.pathname === "/under-maintenance" && (
          <div className="rb-logo-container">
            <img
              className="rb-logo"
              src={
                user.ui_preferences.theme === "light" ? logo : reckittLogoWhite
              }
              alt="RB Logo"
            />{" "}
          </div>
        )} */}
        {(page === "home" || (page === "explore" && type === undefined)) && (
          <div style={{ position: "absolute", left: "190px" }}>
            <DrawerFiltersContainer
              open={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              setLoadingFilter={setLoadingFilter}
              handleSearchApp={handleSearchApp}
              searchVal={searchVal}
              setSearchColumns={setSearchColumns}
              loadingFilter={loadingFilter}
              setSearchVal={setSearchVal}
            />
            <RBHeaderComponent
              match={match}
              loadingFilter={loadingFilter}
              viewByFilter={viewByFilter}
              setViewByFilter={setViewByFilter}
              setSearchVal={setSearchVal}
              searchVal={searchVal}
              search_columns={search_columns}
              handleSmartSearch={handleSearchApp}
              handleFilterDrawer={handleOpenFilterDrawer}
            />
          </div>
        )}
        {(page === "home" || page === "explore" || page === "myapps") &&
          tab !== "usage-metrics" && (
            <div class="GA-layout-change" style={viewStyles.buttonContainer}>
              <button
                class="GA-layout-change"
                id="large_grid"
                style={
                  view === "large_grid"
                    ? viewStyles.inActiveButton
                    : viewStyles.activeButton
                }
                onClick={() => {
                  ChangeLayoutView("large_grid");
                }}
              >
                <span
                  style={{
                    lineHeight: "1.25",
                    fontSize: "20px",
                  }}
                  id="large_grid"
                  class="material-icons-round"
                >
                  grid_view
                </span>
              </button>
              <button
                class="GA-layout-change"
                id="small_grid"
                style={
                  view === "small_grid"
                    ? viewStyles.inActiveButton
                    : viewStyles.activeButton
                }
                onClick={() => {
                  ChangeLayoutView("small_grid");
                }}
              >
                <span
                  style={{
                    lineHeight: "1.25",
                    fontSize: "20px",
                  }}
                  id="small_grid"
                  class="material-icons-round GA-layout-change"
                >
                  apps
                </span>
              </button>
              <button
                class="GA-layout-change"
                id="list"
                style={
                  view === "list"
                    ? viewStyles.inActiveButton
                    : viewStyles.activeButton
                }
                onClick={() => {
                  ChangeLayoutView("list");
                }}
              >
                <span
                  style={{
                    lineHeight: "1.25",
                    fontSize: "20px",
                  }}
                  id="list"
                  class="material-icons-round GA-layout-change"
                >
                  format_list_bulleted
                </span>
              </button>
            </div>
          )}

        {
          <ConfirmationModal
            open={confirmationModalOpen}
            handleClose={handleCloseConfirmationModal}
            handleSubmit={handleDraftDiscard}
            message={confirmationMessage}
            width="300px"
          />
        }
        {!showScreenshot && buttonStatus && template_no && (
          // approval_status !== "pending" &&
          // approval_status !== "rejected" &&
          <div style={{ position: "absolute", left: "360px" }}>
            <EditPreviewToggle
              isDetailsDraweOpen={registerNewApp.statusData.showDetailsDrawer}
            />
          </div>
        )}

        {!showScreenshot && buttonStatus && template_no && (
          // approval_status !== "pending" &&
          <button
            style={{
              position: "absolute",
              right: "100px",
              width: "150px",
            }}
            // className="btn btn-next"
            className={
              isDiscardChangesDisabled() ? "disabled-button" : "btn btn-next"
            }
            disabled={isDiscardChangesDisabled()}
            onClick={() => {
              handleDiscard();
              dispatch(
                update_statusData({
                  showScreenshot: true,
                  isStepperHidden: false,
                  editLayout: true,
                })
              );
            }}
          >
            Discard Changes
          </button>
        )}

        {!showScreenshot &&
          buttonStatus &&
          template_no &&
          approval_status !== "pending" && (
            <button
              style={{
                position: "absolute",
                right: "258px",
              }}
              className={
                isSaveTemplateDisabled() ? "disabled-button" : "btn btn-next"
              }
              onClick={() => {
                handleSaveTemplate();
              }}
              disabled={isSaveTemplateDisabled()}
            >
              {/* Save Template */}
              {/* {curbtn === "Save Template" ? <Loader color="#FFF" /> : "Submit"} */}
              {waitingForScreenshot ? (
                <Loader color="#FFF" margin="4px" />
              ) : (
                "Save Template"
              )}
            </button>
          )}

        <section className="header-menu">
          <UserInfo {...props} />
        </section>
      </header>
    </>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  selectedWorkspace: state.registerNewApp.BIappDetails.selectedWorkspace,
  localstateSetFirstTime:
    state.registerNewApp.statusData.localstateSetFirstTime,
  savedTemplateData: state.registerNewApp.appData.savedTemplateData,
  takeScreenshot: state.registerNewApp.statusData.takeScreenshot,
  waitingForScreenshot: state.registerNewApp.statusData.waitingForScreenshot,
  showScreenshot: state.registerNewApp.statusData.showScreenshot,
  navToExplore: state.registerNewApp.statusData.navToExplore,
  // activeStepper: state.registerNewApp.activeStepper,
  theme: state.user.ui_preferences?.theme,
  appTemplateState: state.appTemplate.appTemplateState,
  user: state.user,
  AppsData: state.AppsData,
  templates: state.register.registerTemplate,
  registerNewApp: state.registerNewApp,
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  appFields: state.registerNewApp.appData.appFields,
  appDetails: state.AppsData.appDetails,
  template_no: state.appTemplate.appTemplateState.template_no,
  old_template_no:
    state.appDashboard?.appDashboardState?.appData?.app_details
      ?.chosen_template,
  addAccessList: state.registerNewApp.accessManagement.addAccessList,
  editable_layout: state.registerNewApp.statusData.editLayout,
  selectedWorkspace: state.registerNewApp.BIappDetails.selectedWorkspace,
  statusData: state.registerNewApp.statusData,
  waiting: state.registerNewApp.statusData.waiting,
  isAppUnique: state.registerNewApp.statusData.isAppUnique,
  isChangesSubmitable: state.registerNewApp.statusData.isChangesSubmitable,
  isDetailsSubmitable: state.registerNewApp.statusData.isDetailsSubmitable,
  templateData: state.appTemplate.appTemplateState.templateData,
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  prev_template_no:
    state.registerNewApp?.appData?.appFields?.app_details?.chosen_template,
  visited: state.registerNewApp.appData.visited,
});

const mapDispatchToProps = {
  updateUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
