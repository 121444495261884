// Import required libraies
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Import Custom Component
import Header from "../../components/Header/Header";
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";
// import LocalParameterItem from "../AIDEApp/AddNewConfig/components/LocalParameterItem";

// Import Context

// Import utils/data
import error500 from "../../../assets/images/error500.svg";
import { useHistory } from "react-router";

function ErrorPage(props) {
  const { user } = props;
  const history = useHistory();
  const ReloadPage = () => {
    // history.push("/home");
    window.location.reload();
  };

  useEffect(() => {
    if (user.isAuth) {
      history.push("/home");
    }
  }, []);

  return (
    <LayoutNone>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt="Error-500"
          style={{ width: "calc(100% - 200px)", margin: "50px" }}
          src={error500}
        ></img>
        <button onClick={ReloadPage}>Try Again!</button>
      </div>
    </LayoutNone>
  );
}

ErrorPage.propTypes = {
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ErrorPage);
