import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

export function Loader({
  fullPage,
  message,
  flexDirection,
  color = "#FC007F",
  margin,
  loader_size = "1rem",
  margin_top = "1px",
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: flexDirection || "column",
      marginTop: {margin_top},
      margin: margin || theme.spacing(1),
      "& > * + *": {
        margin: theme.spacing(5),
      },
      justifyContent: "center",
      alignItems: "center",
      ...(fullPage && {
        height: "100vh",
      }),
    },
    text: {
      color: color || "#FC007F",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {message && <div className={classes.text}>{message}</div>}
      <CircularProgress size={loader_size} className={classes.text} />
    </div>
  );
}

Loader.propTypes = {
  fullPage: PropTypes.any,
  message: PropTypes.any,
};
Loader.defaultProps = {
  fullPage: false,
  message: "",
};

export default Loader;
