import React, { useState } from "react";
import SearchBar from "material-ui-search-bar";
import Loader from "../../../components/Loader/Loader";
import ImageUploader from "./ImageUploader";
import iconList from "../../../../assets/data/iconList.json";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { connect } from "react-redux";
import { imageSize } from "../../../utils/utils";
import { toast } from "react-toastify";

function fetchAPI(formData) {
  const url = "/api/imageuploadv2";
  return fetch(url, {
    method: "POST",
    headers: {},
    body: formData,
  });
}
const SelectLogo = ({
  data,
  open,
  setOpen,
  handleClose,
  handleChange,
  theme,
  upload_for,
  OneToOneRatio = false,
}) => {
  const { label, key } = data;
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [searchIcons, setSearchIcons] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [logo_type, setLogo_type] = useState("google_font");
  const [file, setFile] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const isImgValidated = async () => {
    if (!file) return false;
    const img = await imageSize(URL.createObjectURL(file));
    if (img.height && img.width && img.height !== img.width && !OneToOneRatio) {
      setErrorMsg("Height and width should be in 1:1");
      return false;
      // } else if (file.size > 50000) {
    } else if (file.size > 10000000) {
      setErrorMsg("Icon can't be more than 10MB");
      return false;
    } else {
      return true;
    }
  };
  const handleSelect = (icon) => {
    const index = iconList.findIndex((item) => item === icon);
    setSelectedIcon(index);
    setLogo_type("google_font");
    setFile(null);
  };

  const handleSave = async () => {
    setErrorMsg("");
    const val = await isImgValidated();
    if (logo_type === "uploaded_image" && !val) return;
    if (logo_type === "google_font" && !selectedIcon && selectedIcon !== 0)
      return;
    if (logo_type === "uploaded_image") {
      const uploadFile = async () => {
        setWaiting(true);
        const formData = new FormData();
        formData.append("file", file);
        try {
          const req = await fetchAPI(formData);
          const response = await req.json();
          if (response.status.statusCode === "datareckitt-200") {
            handleChange(
              key,
              response.data.url,
              response.data.blob_name,
              logo_type
            );
          } else {
            toast.error(
              response.status.statusMessage || "Oops! something went wrong"
            );
          }
          setWaiting(false);
          setOpen(false);
        } catch (error) {
          console.log("error from image upload", error);
          toast.error(error || "Oops! something went wrong");
          setWaiting(false);
          setOpen(false);
        }
      };
      uploadFile();
    } else if (logo_type === "google_font") {
      handleChange(key, iconList[selectedIcon], "", "google_font");
      setOpen(false);
    }
    setSelectedIcon(null);
    setSearchVal("");
    setFile(null);
  };

  const requestSearch = (val) => {
    if (val.length > 0) {
      const result = iconList.filter((icon) =>
        icon.replaceAll("_", " ").toLowerCase().includes(val.toLowerCase())
      );
      setSearchVal(val);
      setSearchIcons(result);
    } else if (val.length === 0) {
      setSearchVal("");
      setSearchIcons([]);
    }
  };
  const cancelSearch = () => {
    setSearchVal("");
    setSearchIcons([]);
  };

  const handleCloseModified = () => {
    setSelectedIcon(null);
    setSearchVal("");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModified}
      disableEnforceFocus
      maxWidth="md"
      sx={{
        borderRadius: "10px",
        "& .MuiDialog-paper": {
          backgroundImage: "none",
          boxShadow: "4px 4px 12px 4px #434343",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div className="heading">{`Choose ${label}`}</div>
          <IconButton onClick={handleCloseModified}>
            <span class="material-icons">close</span>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ImageUploader
              file={file}
              setFile={setFile}
              setLogo_type={setLogo_type}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              theme={theme}
              upload_for={upload_for}
            />
          </Grid>
          <Grid container item xs={6}>
            <Box
              border="1px solid #00000033"
              borderRadius="5px"
              p={1}
              width={370}
              height={300}
              textAlign="center"
            >
              <Box display="flex" justifyContent="center" mb={1}>
                <SearchBar
                  placeholder="Search Icons"
                  className="search-icons"
                  style={{
                    height: "38px",
                    boxShadow: "none",
                    backgroundColor:
                      theme === "dark" ? "#282828" : "transparent",
                  }}
                  onChange={(val) => requestSearch(val)}
                  onCancelSearch={() => cancelSearch()}
                />
              </Box>
              {searchVal.length > 0 && (
                <div>
                  {searchIcons.length > 0 && (
                    <Grid container textAlign="center">
                      {searchIcons.slice(0, 40).map((item, i) => (
                        <Grid
                          item
                          xs={1.5}
                          key={i}
                          justifyContent="center"
                          display="flex"
                          alignItems="center"
                        >
                          <div
                            title={item.replaceAll("_", " ")}
                            className={`choose-icon ${theme}-choose-icon ${
                              iconList[selectedIcon] === item ? "selected" : ""
                            }`}
                            onClick={() => handleSelect(item)}
                          >
                            <span class="material-icons-outlined">{item}</span>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  {searchIcons.length === 0 && (
                    <p className="applications-title"> No Icons Found !</p>
                  )}
                </div>
              )}
              {searchVal.length === 0 && (
                <Grid container textAlign="center">
                  {iconList.slice(0, 40).map((item, i) => (
                    <Grid
                      item
                      xs={1.5}
                      key={i}
                      justifyContent="center"
                      display="flex"
                      alignItems="center"
                    >
                      <div
                        title={item.replaceAll("_", " ")}
                        className={`choose-icon ${theme}-choose-icon ${
                          iconList[selectedIcon] === item ? "selected" : ""
                        }`}
                        onClick={() => handleSelect(item)}
                      >
                        <span class="material-icons-outlined">{item}</span>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <button
          onClick={handleSave}
          className="btn"
          style={{ marginRight: "5px" }}
          disabled={waiting}
        >
          {waiting ? <Loader color="#FFFFFF" /> : "Save"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(SelectLogo);
