import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { useParams } from "react-router";
import { update_statusData } from "../../../redux/actions";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ownerList } from "../../../utils/utils";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "18px",
    // fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const EditPreviewToggle = ({
  user,
  editable_layout,
  value,
  handleChange,
  width,
  isDetailsDraweOpen,
  isPreviewDisable,
  component,
  editPreviewToggleReport,
  previewDisableReport = false,
  appDetails,
  userInfo,
}) => {
  const dispatch = useDispatch();
  const { appInfo, draftId, tab } = useParams();
  const theme = user.ui_preferences.theme;
  const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    textTransform: "capitalize",
    backgroundColor: "transparent",
    border: theme.palette.mode === "dark" ? "1px solid #fff" : "1px solid #000",
    fontFamily: "Energy !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "18px !important",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "#ffffff",
      backgroundColor: "#0058aa",
      border: "1px solid #0058aa",
    },
  }));

  const isEditableModeDisabled =
    window.location.href.includes("pending") ||
    (appInfo &&
      !(
        (ownerList(appDetails?.data) || []).includes(
          userInfo.preferred_username
        ) || userInfo.role === "super_admin"
      ))
      ? true
      : false;

  const handleButtonGroup1 = (event, newValue) => {
    if (newValue === null) return;
    dispatch(update_statusData({ editLayout: newValue }));
  };

  const handleButtonGroup2 = (event, newValue) => {
    if (newValue === null) return;
    dispatch(update_statusData({ editPreviewToggleReport: newValue }));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        paddingLeft: "5px",
        paddingRight: "5px",
      }}
    >
      <ToggleButtonGroup
        exclusive
        variant="outlined"
        size="sm"
        value={
          component
            ? value || editPreviewToggleReport
            : value || editable_layout
        }
        onChange={component ? handleButtonGroup2 : handleButtonGroup1}
        title={
          isPreviewDisable || previewDisableReport ? "Nothing to preview" : ""
        }
      >
        {/* <HtmlTooltip
          placement="top"
          title="Grid View"
        > */}
        {/* <Tooltip title="Grid View"> sx={{ display: "flex" }}> */}

        {/* </Tooltip> */}

        {/* </HtmlTooltip> */}

        <ToggleButton
          sx={{ width: width || "110px" }}
          value={true}
          disabled={isEditableModeDisabled}
          className={`btn btn-back ${
            isEditableModeDisabled ? "btn-disabled" : ""
          }`}
        >
          Edit Mode
        </ToggleButton>
        <ToggleButton
          sx={{ width: width || "110px" }}
          value={false}
          disabled={
            component
              ? previewDisableReport
              : isDetailsDraweOpen || isPreviewDisable
          }
          className={`btn btn-back ${
            component
              ? previewDisableReport
                ? "btn-disabled"
                : ""
              : isDetailsDraweOpen || isPreviewDisable
              ? "btn-disabled"
              : ""
          }`}
        >
          Preview Mode
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user,
  appDetails: state.AppsData?.appDetails,
  isPreviewDisable: state.registerNewApp.statusData.isPreviewDisable,
  user: state.user,
  editable_layout: state.registerNewApp.statusData.editLayout,
  editPreviewToggleReport:
    state.registerNewApp.statusData.editPreviewToggleReport,
});

export default connect(mapStateToProps)(EditPreviewToggle);
