import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import landingImg from "../../../../../../assets/images/Dashboard-images/project-management.svg";
import "../../../../AppDashboard/styles/template4.scss";
import { config } from "../../../../../config/config";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { specialFeilds } from "../../../../../utils/utils";
// width - 260 height - 96

const useStyles = (props) =>
  makeStyles((theme) => ({
    container: {
      width: "100%",
      border:
        theme.palette.mode === "dark" ? "transparent" : "0.2px solid #e7e9ee",
      background: theme.palette.mode === "dark" ? "#282828" : "#ffffff",
      // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
      "& .deleteLogo": {
        display: "none",
      },
      "&:hover": {
        "& .deleteLogo": {
          display: "block",
        },
      },
    },
    firstColumn: {
      display: "flex",
      alignItems: "center",
      // gap: "10px",
      flex: 1,
      padding: "5px",
      minHeight: "45px",
      "& .googleIcons": {
        background:
          "linear-gradient(45deg, #FC007F 0%,#FC007F 65% ,#FF4D48 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
      },
    },
    uploadedImg: {
      maxHeight: "55px",
      maxWidth: "55px",
    },
    headerText: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      lineHeight: "26px",
      color: "#FC007F",
    },
    descriptionText: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "20px",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    },
    imgContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      color: "#282828",
    },
    card: {
      boxSizing: "border-box",
      marginBottom: "10px",
      // background: "#ffffff",
      // backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
      backgroundColor: props.card_bg_color
        ? props.card_bg_color
        : theme.palette.mode === "dark"
        ? "#282828"
        : "#fff",
      border:
        theme.palette.mode === "dark" ? "transparent" : "0.2px solid #e7e9ee",
      // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "5px",
      // color: "#282828",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      flexDirection: "column",
      fontSize: "14px",
      cursor: "pointer",
      // gap: "10px",
      // width: "105px",
      // height: "100px",
      padding: "5px",
      position: "relative",
      "& .deleteLogo": {
        display: "none",
      },
      "& .editBtn": {
        display: "none",
      },
      "&:hover": {
        "& .deleteLogo": {
          display: "block",
        },
        "& .editBtn": {
          display: "inherit",
        },
      },
      "& .googleIcons": {
        background:
          "linear-gradient(45deg, #FC007F 0%,#FC007F 65% ,#FF4D48 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
      },
    },
    title: {
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "15px",
      lineHeight: "25px",
      textAlign: "center",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
      // color: "#282828",
      marginTop: "4px",
      overflow: "hidden",
      overflowWrap: "anywhere",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      "& p": {
        textAlign: "center !important",
      },
    },
    img: {
      // height: "50px",
      maxHeight: "60%",
      maxWidth: "60%",
    },
    deleteIcon: {
      position: "absolute",
      left: " -6px",
      top: "-6px",
      height: "15px",
    },
    imgCard: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "cover",
      backgroundSize: "cover",
      backgroundPosition: "center",
      // width: "100%",
      // height: "100%",
    },
    desc: {
      // whiteSpace: "pre-wrap",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "12px",
      overflow: "hidden",
      overflowWrap: "anywhere",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      textAlign: "center",
      "& p": {
        textAlign: "center !important",
        margin: 0,
      },
    },
    customCardContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      textAlign: "center",
      padding: "10px",
      height: "300px",
      width: "220px",
      borderRadius: "5px",
      marginBottom: "10px",
      boxShadow: config.cssValues.cardShadow,
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      // backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
      backgroundColor: props.card_bg_color
        ? props.card_bg_color
        : theme.palette.mode === "dark"
        ? "#282828"
        : "#fff",
      "& .custom-title": {
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "35px",
        overflow: "hidden",
        overflowWrap: "anywhere",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        textAlign: "center",
      },
      "& .googleIcons": {
        fontSize: "75px",
        background:
          "linear-gradient(45deg, #FC007F 0%,#FC007F 65% ,#FF4D48 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
      },
      "& img": {
        height: "75px",
      },
      "& p": {
        textAlign: "center !important",
        margin: 0,
      },
    },
    prevHeading: {
      fontSize: "11px",
      fontWeight: "400",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
      textAlign: "start",
      width: "100%",
      textTransform: "UPPERCASE",
    },
  }));

const PreviewComponent = (props) => {
  const { previewData, parent_report_type, editorState } = props;
  const classes = useStyles(previewData)(previewData.sub_category_type);
  const refName = useRef(null);
  const refDesc = useRef(null);

  useEffect(() => {
    if (
      specialFeilds.includes(previewData.sub_category_type) &&
      previewData.sub_category_type !== "textBox"
    ) {
      const reqHtmlForName = draftToHtml(
        convertToRaw(editorState.sub_category_name.getCurrentContent())
      );
      const reqHtmlForDesc = draftToHtml(
        convertToRaw(editorState.sub_category_description.getCurrentContent())
      );
      if (refName && refName.current) {
        refName.current.innerHTML = reqHtmlForName
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&apos;/g, "'");
      }
      if (refDesc && refDesc.current) {
        refDesc.current.innerHTML = reqHtmlForDesc
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&apos;/g, "'")
          .replaceAll("\\n", "\n");
      }
    }
  }, [
    editorState,
    parent_report_type,
    previewData.sub_category_id,
    previewData.sub_category_type,
    previewData.show_description,
  ]);
  return (
    <>
      {previewData.sub_category_type !== "textBox" && (
        <p className={classes.prevHeading}>Preview of the Widget :</p>
      )}
      {previewData.sub_category_type === "customHybrid" && (
        <div className={classes.customCardContainer}>
          <div className="custom-title" ref={refName}>
            {previewData.sub_category_name || "Preview text"}
          </div>
          <div style={{ flex: 1 }}>
            {previewData.app_logo_type === "google_font" ? (
              <span class="material-icons-outlined googleIcons">
                {previewData.sub_category_icon}
              </span>
            ) : (
              <img src={previewData.sub_category_icon || landingImg} alt="" />
            )}
          </div>
          <div
            className="dashboard-temp4-desc"
            style={{ flex: 1.5, paddingTop: "20px" }}
            ref={refDesc}
          >
            {(previewData.sub_category_description || "").replaceAll(
              "\\n",
              "\n"
            )}
          </div>
        </div>
      )}
      {previewData.sub_category_type === "parentReport" && (
        <div
          style={{
            marginLeft: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
          }}
        >
          <div className={classes.title}>
            {previewData.sub_category_name === ""
              ? "Preview comes here"
              : previewData.sub_category_name}
          </div>
          <div className={classes.descriptionText}>
            {previewData.sub_category_description}
          </div>
        </div>
      )}
      {/* {previewData.sub_category_type === "textBox" && (
                <div id="formatedText">
                    {previewData.sub_category_name === "" ? (
                        "Preview comes here"
                    ) : (
                        <p></p>
                    )}
                </div>
            )} */}
      {/* {previewData.sub_category_type === "textBox" && (
                <div className={classes.title}>
                    {previewData.sub_category_name === ""
                        ? "Preview comes here"
                        : previewData.sub_category_name}
                </div>
            )} */}

      {
        //header component
        previewData.sub_category_type === "header" && (
          <div className={classes.container}>
            <div className={classes.firstColumn}>
              {previewData.sub_category_icon && (
                <div className={classes.imgContainer}>
                  {previewData.app_logo_type === "google_font" ? (
                    <span
                      class="material-icons-outlined googleIcons"
                      style={{
                        fontSize: "50px",
                      }}
                    >
                      {previewData.sub_category_icon}
                    </span>
                  ) : (
                    <img
                      src={previewData.sub_category_icon}
                      alt=""
                      className={classes.uploadedImg}
                    />
                  )}
                </div>
              )}
              <div style={{ marginLeft: "15px" }}>
                <div className={classes.headerText}>
                  {previewData.sub_category_name === ""
                    ? "Preview comes here"
                    : previewData.sub_category_name}
                </div>
                {
                  <div className={classes.descriptionText}>
                    {previewData.sub_category_description}
                  </div>
                }
              </div>
            </div>
          </div>
        )
      }
      {previewData.sub_category_type !== "customHybrid" &&
        previewData.sub_category_type !== "parentReport" &&
        previewData.sub_category_type !== "header" &&
        previewData.sub_category_type !== "textBox" && (
          <div
            className={classes.card}
            style={{
              width:
                // previewData.sub_category_type === "report" &&
                parent_report_type === "parentReport" ? "100px" : "200px",
              height:
                // previewData.sub_category_type === "report" &&
                parent_report_type === "parentReport" ? "100px" : "135px",
            }}
            //   title={(previewData.sub_category_description || "").replaceAll("\\n", "\n")}
            //   {...rest}
          >
            {previewData.sub_category_type === "image" ? (
              previewData.app_logo_type === "google_font" ? (
                <span
                  class="material-icons-outlined googleIcons"
                  style={{
                    fontSize: `50px`,
                  }}
                >
                  {previewData.sub_category_icon}
                </span>
              ) : (
                <img
                  src={previewData.sub_category_icon}
                  className={classes.imgCard}
                  alt=""
                />
              )
            ) : (
              <>
                {previewData.app_logo_type === "google_font" ? (
                  <span
                    class="material-icons-outlined googleIcons"
                    style={{
                      fontSize: `50px`,
                    }}
                  >
                    {previewData.sub_category_icon}
                  </span>
                ) : (
                  <img
                    src={previewData.sub_category_icon}
                    alt=""
                    className={classes.img}
                  />
                )}
                <span
                  className={classes.title}
                  style={{ fontSize: `12px` }}
                  id={previewData.sub_category_id}
                  ref={refName}
                >
                  {previewData.sub_category_name === ""
                    ? "Preview comes here"
                    : previewData.sub_category_name}
                </span>
                {previewData.show_description &&
                  parent_report_type !== "parentReport" && (
                    <span className={classes.desc} ref={refDesc}>
                      {(previewData.sub_category_description || "").replaceAll(
                        "\\n",
                        "\n"
                      )}
                    </span>
                  )}
              </>
            )}
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});
export default connect(mapStateToProps)(PreviewComponent);
