import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import "../index.scss";

import { connect } from "react-redux";
import SelectLogo from "../../Register/components/SelectLogo";

import choose_icon from "../../../../assets/images/choose_icon.svg";
import { BorderColor } from "@material-ui/icons";

const UrlBtn = (props) => {
  const {
    data,
    width,
    value,
    handleChange,
    app_logo_type,
    url,
    isFieldsEditable,
    theme,
    upload_for,
  } = props;
  const { label, required, key } = data;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <Grid item xs={width || 6} key={key} sx={{ alignItems: "end" }}>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          //   alignItems="space-between"
          // height="100%"
          // width="70%"
          // maxWidth="180px"
        >
          {app_logo_type === "uploaded_image" ? (
            <div className="appDetailsIcon-container">
              <img
                src={url || value}
                alt="uploaded icon"
                style={{
                  // marginLeft: "20px",
                  width: "120px",
                  borderRadius: "50%"
                }}
              />
            </div>
          ) : (
            <div class="appDetailsIcon-container">
              <span class="material-icons-outlined appTypeIcon-span">
                {value || "format_align_center"}
              </span>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              className={`appDetailsIcon-heading appDetailsIcon-heading-${theme}`}
            >
              {label}
              {/* <span style={{ display: required ? "" : "none" }}>*</span>: */}
            </div>
            <button
              // className="urlBtn"
              className={`btn-chooseIcon ${theme}-btn-chooseIcon GA-choose-icon`}
              onClick={handleOpen}
              disabled={isFieldsEditable}
            >
              <img alt="Choose Icon" src={choose_icon} />
              {/* <span class="material-icons-outlined GA-choose-icon">
                            add_to_photos
                        </span>
                        <span className="inner-content GA-choose-icon">
                            Choose Icon
                        </span> */}
            </button>
          </div>
        </Box>
      </Box>
      <SelectLogo
        data={data}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleChange={handleChange}
        upload_for={upload_for}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(UrlBtn);
