import { makeStyles, withStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const CssTextField = withStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    padding: "3px 10px",
    // border: "1px solid grey",
    // height: "24px",
    borderRadius: "50px",
    boxShadow: "0px 5px 15px 0px #00000014",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

export const StyledAutoComplete = withStyles(() => ({
  tag: {
    background: "#282828",
    color: "#282828 !important",
    fontFamily: "Energy !important",
  },
  paper: {
    fontFamily: "Energy",
    fontSize: 13,
    color: "#282828",
  },
}))(Autocomplete);

// export const useStyles = makeStyles(() => ({
//   errorContainer: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   typographyStyle: {
//     "& .MuiChip-root": {
//       fontFamily: "Energy",
//       fontSize: 12,
//     },
//     "& .MuiInput-underline:after": {
//       borderBottom: 0,
//     },
//     "& .MuiInput-underline:before": {
//       borderBottom: 0,
//       transition: "none",
//     },
//     "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
//       borderBottom: "0px solid #dedede !important",
//     },
//     "& .Mui-focused .MuiInput-underline": {
//       borderBottom: 0,
//     },
//     "& .MuiAutocomplete-option": {
//       fontFamily: "Energy",
//       fontSize: 12,
//     },
//     "& .MuiAutocomplete-paper": {
//       fontFamily: "Energy",
//       fontSize: 6,
//     },
//     "& .MuiInputBase-input": {
//       textTransform: "capitalize",
//       fontFamily: "Energy",
//       fontSize: 14,
//       color: "#282828",
//     },
//     "& .MuiOutlinedInput-root": {
//       paddingTop: "0px !important",
//       paddingBottom: "0px !important",
//     },
//     "& .MuiAutocomplete-popupIndicator": {
//       "& span": {
//         "& svg": {
//           "& path": {
//             d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
//           },
//         },
//       },
//     },
//   },
//   root: {
//     "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
//       transform: "translate(34px, 20px) scale(1);",
//       fontFamily: "Energy",
//       fontStyle: "normal",
//       fontWeight: "400",
//       fontSize: "5px",
//       color: "#282828",
//       opacity: 0.5,
//     },
//   },
//   select: {
//     fontFamily: "Energy !important",
//     fontSize: "16px!important",
//     fontStyle: "normal!important",
//     fontWeight: "400!important",
//   },
//   comboOptions: {
//     fontFamily: "Energy",
//     fontStyle: "normal",
//     fontWeight: "400",
//     fontSize: "16px",
//     color: "#282828",
//   },
//   popupIndicator: {
//     "& span": {
//       "& svg": {
//         "& path": {
//           d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
//         },
//       },
//     },
//   },
//   inputRoot: {
//     color: "#282828",
//     background: "#ededed",
//     '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
//       paddingLeft: 26,
//       fontFamily: "Energy",
//       fontSize: "14px",
//       fontStyle: "normal",
//       fontWeight: "500",
//       lineHeight: "23px",
//       letterSpacing: "0em",
//       textAlign: "left",
//       color: "#282828",
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderColor: "#fafafa",
//     },
//     "&:hover .MuiOutlinedInput-notchedOutline": {
//       borderColor: "#fafafa",
//     },
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: "#fafafa",
//     },
//   },
//   checkbox: {
//     "& .MuiCheckbox-root": {
//       padding: 0,
//     },
//   },
// }));

export const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Energy",
      fontSize: 12,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Energy",
      fontSize: 12,
      paddingLeft: "10px",
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Energy",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Energy",
      fontSize: 13,
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      marginRight: "-7px",
      "& span": {
        "& svg": {
          "& path": {
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
          },
        },
      },
    },
    "& .MuiAutocomplete-clearIndicator": {
      marginRight: "-8px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "14px",
    },
    "& .MuiIconButton-root": {
      fontSize: "14px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: "18px !important",
      paddingLeft: "7px",
    },
  },
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "5px",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      opacity: 0.5,
    },
  },
  select: {
    fontFamily: "Energy !important",
    fontSize: "16px!important",
    fontStyle: "normal!important",
    fontWeight: "400!important",
  },
  comboOptions: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
        },
      },
    },
  },
  inputRoot: {
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    background: "#ededed",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 26,
      fontFamily: "Energy",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "23px",
      letterSpacing: "0em",
      textAlign: "left",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
  },
  checkbox: {
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
  active: {
    "&.MuiFormControl-root": {
      backgroundColor: "#282828",
      // color: "#fff",
      color: "rgba(255,255,255,0.8)",
    },
    "& .MuiInputBase-root": {
      // color: "#fff",
      color: "rgba(255,255,255,0.8)",
    },
    "& .MuiSvgIcon-root": {
      // color: "#fff",
      color: "rgba(255,255,255,0.8)",
    },
  },
  activeAuto: {
    "&.MuiFormControl-root": {
      backgroundColor: "#282828",
      color: "#fff",
      // color: "rgba(255,255,255,0.5)",
    },
    "& .MuiInputBase-root": {
      color: "#fff",
      // color: "rgba(255,255,255,0.5)",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
      // color: "rgba(255,255,255,0.5)",
    },
  },
  unActive: {
    "&.MuiFormControl-root": {
      backgroundColor: "#fff",
      color: "#282828",
    },
    "& .MuiInputBase-root": {
      color: "#282828",
    },
    "& .MuiSvgIcon-root": {
      color: "#282828",
    },
  },
  SecFilUnActive: {
    "&.MuiFormControl-root": {
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.dark.light : "white",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
    "& .MuiInputBase-root": {
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
  },
}));
