import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import { Prompt } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    backgroundColor: "transparent",
    color: "#fc007f",
    border: "1px solid #fc007f",
    fontWeight: "400",
    width: "135px",
  },
}));

const NotificationModal = ({ open, handleClose, handleDone, msg, theme }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          backgroundImage: "none",
          boxShadow: "4px 4px 12px 4px #434343",
        },
      }}
    >
      <div
        style={{
          minWidth: "320px",
          maxWidth: "600px",
          padding: "20px 20px 20px 10px",
        }}
      >
        <DialogContent>
          <Box
            display="flex"
            alignItems="center"
            mb={1}
            mx={1}
            className={`heading ${theme}`}
            sx={{
              textTransform: "unset",
              fontSize: "12px !important",
              margin: "0px !important",
            }}
          >
            {msg || "You have unsaved changes, Are you sure you want to leave?"}
          </Box>
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" px={2} pb={1} gap={2}>
          <button
            onClick={handleClose}
            className={`${classes.cancelBtn} GA-exit-btn`}
            id="Cancel_exit"
          >
            Cancel
          </button>
          <button
            className="btn GA-exit-btn"
            onClick={handleDone}
            id="Exit_without_save"
          >
            Continue
          </button>
        </Box>
      </div>
    </Dialog>
  );
};

const RouteLeavingGuard = ({
  when,
  navigate,
  msg,
  navigateWithoutSaveTrack,
  theme,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
    navigateWithoutSaveTrack("Cancelled");
  };

  // const handleBlockedNavigation = (nextLocation) => {
  //   if (!confirmedNavigation) {
  //     setModalVisible(true);
  //     setLastLocation(nextLocation);
  //     return false;
  //   }
  //   return true;
  // };


  const handleBlockedNavigation = (nextLocation = {}) => {
    if (!confirmedNavigation) {
      if (!nextLocation?.pathname?.startsWith("/register")) {
        // Check if nextLocation is not inside the application(register flow)
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      }
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    navigateWithoutSaveTrack("Continued");
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <NotificationModal
        open={modalVisible}
        msg={msg}
        handleClose={closeModal}
        handleDone={handleConfirmNavigationClick}
        theme={theme}
      />
    </>
  );
};
export default RouteLeavingGuard;
