//Import required libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

//Import Styles

//Import Custom Components
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import AIDESentimentAnalysis from "../AIDESentimentAnalysis/AIDESentimentAnalysis";
import NoAccess from "./NoAccess";

//Import Context

//Import utils/data
import AppReport from "../../components/AppReport/AppReport";
import AppSideNav from "../../components/AppSideNav/AppSideNav";

// Import styles
import "./BIAppComponent.scss";
import Loader from "../../components/Loader/Loader";

const initialFilters = {
  offset: 0,
  limit: 10,
  noMoreRecords: false,
  order_by: "commented_at",
  order_sequence: "desc",
};
// PROPS Comments
// * user: contains user information who clicks on the app
// * match: contains the app_path
// * appAccess: contains the app access status (true/false)
// * allApps: (NOT USED)
// * theme: contains the theme (dark/light)
// * layoutApps: (NOT USED)
// * appDashboardState: contains appData (app_details, app_access etc.) which is used in the component
const BIAppComponent = (props) => {
  const { user, match, theme, appDashboardState, appAccess } = props;
  const { appData } = appDashboardState;

  const location = useLocation();
  const [activeFilter, setActiveFilter] = useState({
    ...initialFilters,
  });
  const [activeReport, setActiveReport] = useState(location.subcat || {});

  // activeReport is set to report-data in the defined_categories
  useEffect(() => {
    if (Object.keys(appData).length) {
      // console.log("MyappData", appData.app_details);
      const data =
        appData.app_details?.defined_categories[0]?.sub_category[0].hasOwnProperty(
          "url"
        )
          ? appData.app_details?.defined_categories[0]?.sub_category[0]
          : appData.app_details?.defined_categories[0]?.sub_category[0]
              ?.sub_category[0];
      if (!activeReport?.sub_category_id) {
        setActiveReport(data);
      }
    }
  }, [appData]);

  return (
    <div className={`${user.ui_preferences.theme} page`}>
      <LayoutTopSideBottom match={match} app_name={appData.app_meta?.app_name}>
        {appAccess ? (
          <section style={{ paddingTop: "0px", display: "flex" }}>
            {/* shows app side bar which contains categories, reports names */}
            <AppSideNav
              show_sidebar={appData.app_details?.show_sidebar}
              match={match}
              activeReport={activeReport}
              setActiveReport={setActiveReport}
              sidenavData={appData.app_details?.defined_categories}
              app_id={appData.app_id}
            />
            {activeReport && activeReport.identifier !== "Twitter" && (
              <main
                className={`content bi-report ${user.ui_preferences.theme}-report`}
              >
                <>
                  {Object.keys(activeReport).length ? (
                    <AppReport
                      activeReport={activeReport}
                      user={user}
                      app={appData}
                    />
                  ) : (
                    <Loader />
                  )}
                </>
              </main>
            )}
            {activeReport && activeReport.identifier === "Twitter" && (
              <main
                className="content power-bi-report"
                style={{
                  backgroundColor: theme === "dark" ? "#000000" : "transparent",
                }}
              >
                <AIDESentimentAnalysis
                  key={activeReport}
                  activeReport={activeReport}
                  user={user}
                />
              </main>
            )}
          </section>
        ) : (
          <NoAccess
            appName={appData.app_meta?.app_name}
            appId={appData.app_id}
            status={appData.approval_meta?.approval_status}
            appDetails={appData}
          />
        )}
      </LayoutTopSideBottom>
    </div>
  );
};

BIAppComponent.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allApps: state.AppsData.allApps,
  theme: state.user.ui_preferences?.theme,
  layoutApps: state.AppsData.layoutApps,
  appDashboardState: state.appDashboard.appDashboardState,
});

export default connect(mapStateToProps)(BIAppComponent);
