import axios from "axios";
import { BASE_URL } from "./constants";
import { toast } from "react-toastify";

export const invokeAPI = async (
    url,
    method = "GET",
    params = {},
    payload = {}
) => {
    method = method.toUpperCase();
    const options = {
        baseURL: `${BASE_URL}`,
        url,
        method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        timeout: 30000,
        params,
    };
    if (["POST", "PUT", "PATCH"].includes(method)) {
        options.data = JSON.stringify(payload);
    }

    let isSuccess = false;
    let errorMSG = null;
    let respData = null;
    let errorCode = null;
    let statusMsg = "";

    try {
        const { data: response } = await axios.request(options);
        const { status: { statusCode, statusMessage, status } = {} } = response;
        if (statusCode === "datareckitt-200") {
            isSuccess = true;
            respData = response.data;
            statusMsg = statusMessage;
        } else if (status === "datareckitt-200") {
            isSuccess = true;
            respData = response.data;
            statusMsg = statusMessage;
        } else if (statusCode === "auth-200") {
            isSuccess = true;
            respData = response.data;
            statusMsg = statusMessage;
        } else {
            isSuccess = false;
            errorMSG = statusMessage;
        }
    } catch (errorObj) {
        if (errorObj.response) {
            if (errorObj.response.status === 401) {
                window.location.href = "auth/sign_in";
                return null;
            }
            // } else if (errorObj.response.status === 500) {
            //   window.location.href = "/error";
            //   return null;
            // }
            // if (errorObj.response.status === 403) {
            //   window.location.href = "/login";
            //   return null;
            // }
            errorMSG =
                errorObj.response?.errorMessage ||
                errorObj.response.data?.status?.statusMessage ||
                "Oops ! Something went wrong , Please try again !";
            // toast.error(errorMSG);
        } else {
            errorMSG = "Server error";
            // toast.error(errorMSG);
        }
        isSuccess = false;
        errorMSG = errorObj.response?.statusText;
        errorCode = errorObj.response?.status;
    }
    return { isSuccess, data: respData, error: errorMSG, errorCode, statusMsg };
};

export default invokeAPI;
