// Import required libraies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
// Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../components/Loader/Loader";
import EditLayout from "./EditLayout";
import SkeletonLoadScreen from "../../components/ImageLoader/SkeletonLoadScreen";

// Import utils
import { UpdatePageView } from "../../utils/utils";
import { config } from "../../config/config";

// Import action creators
import {
  cleanTopUsersState,
  getRegisterAppTemplate,
  updateUserInfo,
} from "../../redux/actions";
import { getLayoutApps, getRefreshedLayoutApps } from "../../redux/actions";

// Import styles
import "./Home.scss";
import "../../../styles/sass/NewThemeVariables.scss";

// Import Assets/Data
import error_smw from "../../../assets/images/error_smw.svg";
import no_apps_found from "../../../assets/images/no_apps_found.svg";
import empty_home from "../../../assets/images/empty_home.svg";
import { useStyles } from "./style";
import NewAppCard from "../../components/AppCard/NewAppCard";
import NewAppTable from "../../components/AppTable/NewAppTable";

let filteredApps = [];
function Home(props) {
  const { match, AppsData = {}, user, templates } = props;
  const dispatch = useDispatch();
  const [editLayoutStatus, setEditLayoutStatus] = useState(false);
  const [searched, setSearched] = useState([]);

  useEffect(() => {
    if (user.filteredApps.status === "success") {
      // const AppIDsList = user.filteredApps.data.map((el) => el.app_id);
      const FLapps = renderAppData.filter((app) =>
        user.filteredApps.data.includes(app.app_id)
      );
      setSearched(FLapps);
    } else {
      setSearched([]);
    }
  }, [user.filteredApps]);

  // new state
  const [renderAppData, setRenderAppData] = useState([]);
  const [tempLayout, setTempLayout] = useState();
  const [loadSaveLayout, setLoadSaveLayout] = useState("pending");
  const classes = useStyles();

  const handleSortApps = (data) => {
    if (user.filteredApps.status === "success") {
      filteredApps = data.filter((app) =>
        user.filteredApps.data.includes(app.app_id)
      );
      setSearched(filteredApps);
    }
    setRenderAppData(data);
    setTempLayout(data);
  };

  const handleRefreshCard = () => {
    dispatch(
      getRefreshedLayoutApps({
        user_id: user.preferred_username,
      })
    );
  };

  const requestSearch = (FLApps, apps) => {
    const AppIDsList = FLApps.map((el) => el.app_id);
    if (FLApps.length) {
      filteredApps = apps.filter((app) => AppIDsList.includes(app.app_id));
      handleSortApps(filteredApps);
    } else {
      handleSortApps(apps);
    }
  };

  const HandleEditLayout = () => {
    // Edit Layout
    if (!editLayoutStatus) {
      setEditLayoutStatus(!editLayoutStatus);
    }
    // Save Layout
    else if (editLayoutStatus) {
      let bodyArray = [...tempLayout];
      handleSortApps(bodyArray);
      const app_id_Array = bodyArray.map((app) => app.app_id);
      setLoadSaveLayout("loading");
      let body = {
        user_id: user.preferred_username,
        sequence_of_app: app_id_Array,
      };

      let url = config.api.add_layout_Url;
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            return response.json();
          } else {
            let error = response.statusText;
            console.log("ERROR -> ", error);
            setLoadSaveLayout("pending");
            // toast.error("ERROR : While updating Favourites .Please try Again");
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (json?.errorCode) {
            console.log("ERROR=>", json.errorCode);
            setLoadSaveLayout("error");
            // setEditLayoutStatus(!editLayoutStatus);
            toast.error("Couldn't save the layout! Please try Again.");
          } else {
            if (json?.status.status === "datareckitt-200") {
              setLoadSaveLayout("success");
              setEditLayoutStatus(!editLayoutStatus);
              // handleRefreshCard();
              handleSortApps(bodyArray);
              // console.log("API temp layout ", tempLayout);
              // toast.success("Layout Saved Successfully !");
              dispatch(
                getLayoutApps({
                  user_id: user.preferred_username,
                })
              );
            } else {
              setLoadSaveLayout("error");
              // setEditLayoutStatus(!editLayoutStatus);
              toast.error("Couldn't save the layout! Please try Again.");
            }
          }
        });
    }
  };

  useEffect(() => {
    UpdatePageView("home");
    dispatch(
      getLayoutApps({
        user_id: user.preferred_username,
      })
    );
    if (templates.status === "pending") {
      dispatch(getRegisterAppTemplate());
    }
    return () => {
      dispatch(cleanTopUsersState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (AppsData?.layoutApps?.status === "success") {
      handleSortApps(AppsData.layoutApps.data);
    }
  }, [AppsData.layoutApps.status, templates.status]);

  useEffect(() => {
    if (AppsData?.refreshedLayoutApps?.status === "success") {
      if (searched.length === 0) {
        handleSortApps(AppsData.refreshedLayoutApps.data);
      } else {
        requestSearch(searched, AppsData.refreshedLayoutApps.data);
      }
    }
  }, [AppsData.refreshedLayoutApps]);

  useEffect(() => {
    if (user.filteredApps.status === "pending") {
      // if (searched === []) {
      const newData = AppsData.refreshedLayoutApps.data.length
        ? AppsData.refreshedLayoutApps.data
        : AppsData.layoutApps.data;
      handleSortApps(newData);
      // } else {
      //   requestSearch(searched, AppsData.refreshedLayoutApps.data);
      // }
    }
  }, [user.filteredApps.status]);

  return (
    <LayoutTopSideBottom match={match} selected="/home">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        {AppsData?.layoutApps?.status === "success" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: "8px",
            }}
          >
            {loadSaveLayout !== "loading" &&
              AppsData.refreshedLayoutApps?.status !== "loading" &&
              AppsData?.layoutApps?.status !== "loading" &&
              AppsData?.allApps?.status !== "loading" && (
                <button
                  class={`${classes.editLayoutStyle} ${
                    editLayoutStatus ? "GA-save-layout" : "GA-edit-layout"
                  }`}
                  onClick={HandleEditLayout}
                >
                  <span
                    class={`material-icons-round ${
                      editLayoutStatus ? "GA-save-layout" : "GA-edit-layout"
                    }`}
                    style={{ fontSize: "16px" }}
                  >
                    {editLayoutStatus ? "done" : "edit"}
                  </span>
                  {editLayoutStatus ? "Save Layout" : "Edit Layout"}
                </button>
              )}
            {(loadSaveLayout === "loading" ||
              AppsData.refreshedLayoutApps?.status === "loading" ||
              AppsData?.layoutApps?.status === "loading" ||
              AppsData?.allApps?.status === "loading") && (
              <button disabled={true} class={classes.loadingButtonStyle}>
                <Loader color="white" />
              </button>
            )}
          </div>
        )}
      </div>

      {AppsData?.layoutApps?.status === "error" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            style={{ marginTop: "150px" }}
            src={error_smw}
            alt="Something went wrong !"
          ></img>
        </div>
      )}
      {(AppsData?.layoutApps?.status === "loading" ||
        templates.status === "loading" ||
        user.filteredApps.status === "loading") && (
        <SkeletonLoadScreen showSearchBar={true} />
      )}
      {editLayoutStatus &&
        AppsData?.layoutApps?.status === "success" &&
        user.filteredApps.status !== "loading" && (
          <EditLayout
            renderAppData={renderAppData}
            setRenderAppData={setRenderAppData}
            tempLayout={tempLayout}
            setTempLayout={setTempLayout}
            setEditLayoutStatus={setEditLayoutStatus}
          />
        )}
      {!editLayoutStatus &&
        AppsData?.layoutApps?.status === "success" &&
        user.filteredApps.status !== "loading" &&
        (AppsData.layoutApps.data.length > 0 ? (
          <div className="main-container">
            <div
              style={{
                overflowY: "auto",
                height: "calc(100vh - 145px)",
                marginTop: "8px",
                padding: "0 1.5% 1.5%",
              }}
              className="applications-container"
            >
              <div style={{ padding: "0px" }} className="aide-root">
                <div className="applications-row">
                  {(renderAppData.length === 0 &&
                    user.filteredApps.status === "success") ||
                  (searched.length === 0 &&
                    user.filteredApps.status === "success") ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          marginTop: "150px",
                          height: "200px",
                        }}
                        src={no_apps_found}
                        alt="No Apps Found"
                      ></img>
                    </div>
                  ) : (
                    <>
                      {renderAppData.length > 0 || searched?.length > 0
                        ? user.ui_preferences.grid_size !== "list" && (
                            <div className="new-theme-applications-row-grid">
                              {searched?.length > 0
                                ? searched?.map((app, index) => {
                                    return (
                                      <NewAppCard
                                        handleRefreshCard={handleRefreshCard}
                                        key={app.app_id}
                                        appIndex={index}
                                        row={app}
                                        pageLoadState={
                                          AppsData?.refreshedLayoutApps?.status
                                        }
                                      />
                                    );
                                  })
                                : renderAppData?.map((app, index) => {
                                    return (
                                      <NewAppCard
                                        handleRefreshCard={handleRefreshCard}
                                        key={app.app_id}
                                        appIndex={index}
                                        row={app}
                                        pageLoadState={
                                          AppsData?.refreshedLayoutApps?.status
                                        }
                                      />
                                    );
                                  })}
                            </div>
                          )
                        : renderAppData.length === 0 && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "80%",
                                }}
                              >
                                <img
                                  style={{ height: "500px" }}
                                  src={empty_home}
                                  alt="No apps found !"
                                ></img>
                              </div>
                              <button
                                class={`${
                                  classes.editLayoutStyle
                                } ${"GA-edit-layout"}`}
                                onClick={HandleEditLayout}
                              >
                                <span
                                  class={`material-icons-round ${"GA-edit-layout"}`}
                                  style={{ fontSize: "16px" }}
                                >
                                  {"edit"}
                                </span>
                                {"Edit Layout"}
                              </button>
                            </div>
                          )}
                      {user.ui_preferences.grid_size === "list" &&
                        (renderAppData.length > 0 || searched.length > 0) && (
                          <NewAppTable
                            tableData={
                              searched?.length ? searched : renderAppData
                            }
                          />
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80%",
              }}
            >
              <img
                style={{ height: "500px" }}
                src={empty_home}
                alt="No apps found !"
              ></img>
            </div>
            <button
              class={`${classes.editLayoutStyle} ${"GA-edit-layout"}`}
              onClick={HandleEditLayout}
            >
              <span
                class={`material-icons-round ${"GA-edit-layout"}`}
                style={{ fontSize: "16px" }}
              >
                {"edit"}
              </span>
              {"Edit Layout"}
            </button>
          </div>
        ))}
    </LayoutTopSideBottom>
  );
}

Home.propTypes = {
  user: PropTypes.object,
  verticals: PropTypes.array,
  match: PropTypes.object,
  AppsData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  verticals: state.data.verticals,
  AppsData: state.AppsData,
  templates: state.register.registerTemplate,
});

const mapDispatchToProps = {
  updateUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
