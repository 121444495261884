import React, { useState } from "react";
import { Box, Dialog, DialogTitle, IconButton, Stack } from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { makeStyles } from "@material-ui/core";

import { getFilename } from "../../../utils/utils";
import { HtmlTooltip } from "../style";
import "../index.scss";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    cropImageStyle: {
        "& .ReactCrop__image": {
            minWidth: "50px",
            maxHeight: "400px",
            minHeight: "50px",
            maxWidth: "400px",
        },
    },
}));

function ImageUploader({
    file,
    setFile,
    setLogo_type,
    errorMsg,
    setErrorMsg,
    theme,
    upload_for = "",
    appDetails,
}) {
    const eventTracker = useAnalyticsEventTracker(
        "upload icon",
        appDetails.data.app_id
    );
    const [srcImg, setSrcImg] = useState(null);
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({ aspect: 1 / 1 });
    const [result, setResult] = useState(null);
    const [imgSrcExt, setImgSrcExt] = useState(null);
    const [cropComponent, setCropComponent] = useState(false);
    const handleClose = () => setCropComponent(false);

    function extractImageFileExtensionFromBase64(base64Data) {
        return base64Data.substring(
            "data:image/".length,
            base64Data.indexOf(";base64")
        );
    }

    const handleImage = async (event) => {
        if (event.target.files && event.target.files[0]) {
            setErrorMsg("");
            const currentFile = event.target.files[0];
            const myFileItemReader = new FileReader();
            myFileItemReader.addEventListener(
                "load",
                () => {
                    const url = myFileItemReader.result;
                    setFile(event.target.files[0]);
                    const ext = extractImageFileExtensionFromBase64(url);
                    setSrcImg(url);
                    setResult(url);
                    setImgSrcExt(ext);
                    setLogo_type("uploaded_image");
                    // const url = URL.createObjectURL(event.target.files[0]);
                },
                false
            );
            myFileItemReader.readAsDataURL(currentFile);
            eventTracker("clicked on upload", "", upload_for);
        }
    };

    const handleCrop = () => {
        setCropComponent(true);
        eventTracker("crop image", "crop btn clicked", upload_for);
    };

    function base64StringtoFile(base64String, filename) {
        var arr = base64String.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const getCroppedImg = async () => {
        setErrorMsg("");
        if (!crop.width) return;
        try {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

            const base64Image = canvas.toDataURL("image/jpeg", 1);
            setResult(base64Image);
            setCropComponent(false);
            const oldFileName = getFilename(base64Image);
            const myFilename = oldFileName + "cropped." + imgSrcExt;
            // file to be uploaded
            const myNewCroppedFile = base64StringtoFile(
                base64Image,
                myFilename
            );
            setFile(myNewCroppedFile);
            eventTracker("Crop image", "Image cropped", upload_for);
        } catch (e) {
            console.log("crop the image");
        }
    };

    const classes = useStyles();

    return (
        <>
            <Box
                border="1px solid #00000033"
                borderRadius="5px"
                p={1}
                textAlign="center"
                height={300}
                width={370}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center" justifyContent="center">
                    <div
                        style={{ color: theme === "dark" ? "#fff" : "#282828" }}
                    >
                        Upload Icon from system
                    </div>
                    <HtmlTooltip
                        placement="top"
                        title={
                            <>
                                <ul>
                                    <li>Icon size should be less than 10MB</li>
                                    <li>
                                        Icon format can be either PNG or JPEG
                                    </li>
                                    <li>Icons can be cropped once uploaded</li>
                                </ul>
                            </>
                        }
                    >
                        <HelpOutlineIcon
                            style={{ fontSize: "15px", marginLeft: "10px" }}
                        />
                    </HtmlTooltip>
                </Box>
                {!srcImg && (
                    <div className="icon-upload-container">
                        <div>
                            <input
                                type="file"
                                name="logo"
                                accept="image/x-png, image/png, image/jpg, image/jpeg"
                                onChange={handleImage}
                                id="app_logo_upload"
                                style={{ display: "none" }}
                            />
                            <label
                                htmlFor="app_logo_upload"
                                style={{ cursor: "pointer" }}
                            >
                                <span
                                    class="material-icons-outlined"
                                    id="GA-upload-icon"
                                    style={{
                                        fontSize: "100px",
                                        color:
                                            theme === "dark"
                                                ? "#8d9ca6"
                                                : "#282828",
                                    }}
                                >
                                    upload_file
                                </span>
                            </label>
                        </div>
                    </div>
                )}
                <>
                    <Dialog
                        open={srcImg && cropComponent}
                        onClose={handleClose}
                        maxWidth="md"
                    >
                        <DialogTitle
                            style={{ borderBottom: "1px dashed lightgray" }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <div className="heading">Crop your Image</div>
                                <IconButton onClick={handleClose}>
                                    <span class="material-icons">close</span>
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <div className="crop-image">
                            <ReactCrop
                                src={srcImg}
                                onImageLoaded={setImage}
                                crop={crop}
                                onChange={setCrop}
                                ruleOfThirds
                                className={classes.cropImageStyle}
                                style={{
                                    maxHeight: "400px",
                                    // minHeight: "200px",
                                    maxWidth: "400px",
                                    minWidth: "50px",
                                }}
                            />
                        </div>
                        <Stack
                            flexDirection="row"
                            justifyContent="flex-end"
                            mb={1}
                            mr={1}
                            style={{
                                borderTop: "1px dashed lightgray",
                                paddingTop: "12px",
                            }}
                        >
                            <button
                                className="apply-button"
                                onClick={getCroppedImg}
                            >
                                Apply
                            </button>
                        </Stack>
                    </Dialog>
                    {result && !cropComponent && (
                        <Box maxWidth="100%" maxHeight="70%">
                            <img
                                src={result}
                                alt="cropped images"
                                className="final-image"
                            />
                            <div style={{ color: "red", fontSize: "12px" }}>
                                {errorMsg}
                            </div>
                        </Box>
                    )}
                </>
                {!cropComponent && srcImg && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <div title="upload image">
                            <input
                                type="file"
                                name="logo"
                                accept="image/x-png, image/png, image/jpg, image/jpeg"
                                onChange={handleImage}
                                id="app_logo_upload_small"
                                style={{ display: "none" }}
                            />
                            <label
                                htmlFor="app_logo_upload_small"
                                style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "flex-end",
                                }}
                            >
                                <span
                                    class="material-icons-outlined"
                                    id="GA-upload-icon"
                                    style={{
                                        fontSize: "34px",
                                        color:
                                            theme === "dark"
                                                ? "#8d9ca6"
                                                : "#282828",

                                        // color: "#282828",
                                        marginRight: "10px",
                                    }}
                                >
                                    upload_file
                                </span>
                            </label>
                        </div>
                        <button onClick={handleCrop} id="GA-crop-img">
                            Crop
                        </button>
                    </Stack>
                )}
            </Box>
        </>
    );
}

const mapStateToProps = (state) => ({
    appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(ImageUploader);
