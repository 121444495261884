import React from "react";
import LayoutNone from "../../../../layouts/LayoutNone/LayoutNone";
import sample_image from "../../../../../assets/images/new-theme/sample_image.svg";
import { connect } from "react-redux";

const LayoutImageCard = (props) => {
  const {
    card_width = "45vw",
    card_height = "100px",
    handleEditSubCat,
    handleDeleteSubCat,
    imageDetails = {},
    editable_layout,
    theme,
  } = props;
  const path_has_register =
    window.location.pathname.split("/")[1] === "register";
  return (
    <img
      onClick={
        // editable_layout
        //   ? () => {
        //       handleEditSubCat(imageDetails);
        //     }
        //   : () => {}
        path_has_register
          ? editable_layout
            ? () => {
                handleEditSubCat(imageDetails);
              }
            : null
          : null
      }
      style={{
        height: card_height,
        width: card_width,
        borderRadius: "6px",
        border: "1px solid #D7DADF",
        background: theme === "light" ? "#FFF" : "#000",
        marginBottom: "10px",
        marginRight: "5px",
        objectFit:
          imageDetails?.sub_category_img_data?.selected_size === "fit"
            ? "contain"
            : "cover",
      }}
      alt=""
      src={imageDetails?.sub_category_img_data?.img_url || sample_image}
    />
  );
};

const mapStateToProps = (state) => ({
  editable_layout: state.registerNewApp.statusData.editLayout,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(LayoutImageCard);
