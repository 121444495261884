import { Box } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import "./index.scss";
import {
  CssTextField,
  HtmlTooltip,
  StyledAutoComplete,
  useStyles,
} from "../../../NewRegister/style";
import AutoWithCheckBox from "../../../NewRegister/Components/AutoCompletesComponents/AutoWithCheckbox";

const FieldComponent = ({
  autoCheck,
  data,
  handleChange,
  dataList,
  isFieldsEditable,
  value,
  width,
  isInputExistInOptions,
  isInputAlreadyExist,
  addTooltips,
  isTimeRange,
  theme,
}) => {
  const { label, options = [], key, required, placeholder } = data;
  const classes = useStyles();
  const getOptions = () => dataList[key] || [];
  // little modification for GA to get action name i.e label in this case.
  const modifiedHandleChange = (v) => {
    handleChange(key, v, label);
  };
  return (
    <Box flex={1} key={key} sx={{ marginLeft: "20px", maxWidth: "300px" }}>
      {key === "usersList" ? (
        <HtmlTooltip
          placement="bottom-start"
          open={addTooltips}
          title={<div>Cannot add more than 5 users</div>}
        >
          <div
            className={`heading ${theme}`}
            style={{
              color: theme === "light" ? "#000" : "#fff",
            }}
          >
            {label} <span style={{ display: required ? "" : "none" }}>*</span>
            {/* : */}
          </div>
        </HtmlTooltip>
      ) : (
        <div
          className={`heading ${theme}`}
          style={{
            color: theme === "light" ? "#000" : "#fff",
          }}
        >
          {label} <span style={{ display: required ? "" : "none" }}>*</span>
          {/* : */}
        </div>
      )}

      {autoCheck ? (
        <AutoWithCheckBox
          fieldkey={key}
          dataList={dataList}
          isFieldsEditable={isFieldsEditable}
          label={placeholder}
          selectChange={modifiedHandleChange}
          selectedOptions={value}
          isInputAlreadyExist={isInputAlreadyExist}
          isInputExistInOptions={isInputExistInOptions}
          options={getOptions()}
        />
      ) : isTimeRange ? null : (
        <StyledAutoComplete
          className={classes.typographyStyle}
          disabled={isFieldsEditable}
          classes={{
            paper: key === "subCategory" ? "GA-usage-report" : "GA-usage-range",
          }}
          value={value.title || value || ""}
          options={key === "subCategory" ? dataList.subCategory || [] : options}
          getOptionLabel={(option) => option.title || option}
          onChange={(e, newValue) => {
            modifiedHandleChange(newValue);
          }}
          disableClearable
          renderInput={(params) => (
            <CssTextField
              {...params}
              className={classes.typographyStyle}
              fullWidth
              placeholder={placeholder}
            />
          )}
          style={{
            // height: "50px",
            width: "100%",
          }}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(FieldComponent);
