import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  CssTextField,
  paperStyle,
  StyledTableRow,
  useStyles1,
} from "../../../../components/UserTable/style";
import {
  getUsersList,
  update_accessManagement,
  update_appData,
  update_MLappDetails,
} from "../../../../redux/actions";
import {
  CssTextField as StyleCssTextField,
  HtmlTooltip,
  StyledAutoComplete,
  useStyles,
} from "../../style";
import "../../index.scss";
import moment from "moment";

const ProvisioningAccess = ({
  openProvModal,
  setOpenProvModal,
  configDetails,
  usersList,
  isChanged,
  isFieldsEditable,
  label,
  appDetails,
  isAppUpdating,
  addAccessList,
  userInfo,
  theme
}) => {
  const classes = useStyles();
  const classesTable = useStyles1();
  const dispatch = useDispatch();
  const autoC = useRef(null);
  const [open, setOpen] = useState(false);
  const [usersOptions, setUserOptions] = useState([]);
  const handleClose = () => setOpenProvModal(false);

  const addUserToAccessManagementList = (val) => {
    let data = {
      user_id: val.user_id,
      name: val.user_name,
      role: "owner",
      access_granted_by: userInfo.preferred_username,
      access_granted_on: moment().valueOf(),
      security_filters: [
        {
          country: "All",
          brands_list: ["All"],
        },
      ],
    };
    // setAddAccessList([...addAccessList, data]);
    dispatch(
      update_accessManagement({ addAccessList: [...addAccessList, data] })
    );
  };
  const handleAddUser = (val) => {
    if (isFieldsEditable) return;
    const isItemExist = (
      configDetails.provisioning_access_list || []
    ).findIndex(
      (item) => item.email.toLowerCase() === val.user_id.toLowerCase()
    );
    if (isItemExist >= 0) return;

    // setCofigDetails({
    //   ...configDetails,
    //   provisioning_access_list: [
    //     ...(configDetails.provisioning_access_list || []),
    //     {
    //       email: val.user_id,
    //       access_level: "read",
    //     },
    //   ],
    // });
    dispatch(
      update_MLappDetails({
        configDetails: {
          ...configDetails,
          provisioning_access_list: [
            ...(configDetails.provisioning_access_list || []),
            {
              email: val.user_id,
              access_level: "read",
            },
          ],
        },
      })
    );

    // setIsChanged({
    //   ...isChanged,
    //   provisioning_details: {
    //     ...(isChanged.provisioning_details || {}),
    //     provisioning_access_list: [
    //       ...(isChanged?.provisioning_details?.provisioning_access_list || []),
    //       {
    //         email: val.user_id,
    //         access_level: "read",
    //       },
    //     ],
    //   },
    // });
    dispatch(
      update_appData({
        isChanged: {
          ...isChanged,
          provisioning_details: {
            ...(isChanged.provisioning_details || {}),
            provisioning_access_list: [
              ...(isChanged?.provisioning_details?.provisioning_access_list ||
                []),
              {
                email: val.user_id,
                access_level: "read",
              },
            ],
          },
        },
      })
    );

    // adding user in accessmangement => checking if user exist in accessmanagement list while updating else adding if registering
    if (isAppUpdating) {
      const index = (
        appDetails.data.access_details?.users_list || []
      ).findIndex(
        (item) => item.user_id.toLowerCase() === val.user_id.toLowerCase()
      );
      if (index < 0) {
        addUserToAccessManagementList(val);
      }
    } else {
      addUserToAccessManagementList(val);
    }
  };
  const handleUpdateRole = (i, val) => {
    if (isFieldsEditable) return;
    const provList = configDetails?.provisioning_access_list;

    const provisioning_access_list = [
      ...provList.slice(0, i),
      {
        ...provList[i],
        access_level: val,
      },
      ...provList.slice(i + 1),
    ];
    // setCofigDetails({
    //   ...configDetails,
    //   provisioning_access_list,
    // });

    dispatch(
      update_MLappDetails({
        configDetails: {
          ...configDetails,
          provisioning_access_list,
        },
      })
    );

    // setIsChanged({
    //   ...isChanged,
    //   provisioning_details: {
    //     ...(isChanged.provisioning_details || {}),
    //     provisioning_access_list,
    //   },
    // });
    dispatch(
      update_appData({
        isChanged: {
          ...isChanged,
          provisioning_details: {
            ...(isChanged.provisioning_details || {}),
            provisioning_access_list,
          },
        },
      })
    );
  };

  const handleDelete = (i, row) => {
    if (isFieldsEditable || i === 0) return;
    const provList = configDetails?.provisioning_access_list;
    const provisioning_access_list = [
      ...provList.slice(0, i),
      ...provList.slice(i + 1),
    ];
    // setCofigDetails({
    //   ...configDetails,
    //   provisioning_access_list,
    // });

    dispatch(
      update_MLappDetails({
        configDetails: {
          ...configDetails,
          provisioning_access_list,
        },
      })
    );

    // setIsChanged({
    //   ...isChanged,
    //   provisioning_details: {
    //     ...(isChanged.provisioning_details || {}),
    //     provisioning_access_list,
    //   },
    // });

    dispatch(
      update_appData({
        isChanged: {
          ...isChanged,
          provisioning_details: {
            ...(isChanged.provisioning_details || {}),
            provisioning_access_list,
          },
        },
      })
    );

    // filtering user from newly added users
    const filterAccessManagementList = addAccessList.filter(
      (item) => item.user_id.toLowerCase() !== row.email.toLowerCase()
    );
    // setAddAccessList(filterAccessManagementList);
    dispatch(
      update_accessManagement({
        addAccessList: filterAccessManagementList,
      })
    );
  };

  const handleSelectUser = (val) => {
    if (isFieldsEditable) return;
    if (!val) return;
    dispatch(getUsersList({ term: val }));
  };

  useEffect(() => {
    if (usersList.status === "success") {
      setUserOptions(usersList.data);
    }
  }, [usersList]);

  return (
    <div>
      <Dialog
        open={openProvModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": { backgroundImage: "none" },
        }}
      >
        <Box className={classes.alertContainer}>
          {/* input label */}
          <Box display="flex" justifyContent="space-between" mt={2} mx={2}>
            <div
              className={`heading ${theme}`}
              style={{ marginTop: "30px", marginLeft: "8px" }}
            >
              {/* Add Business Owners */}
              {label || "GIVE ACCESS TO"}
            </div>
            {/*  Close Icon */}
            <span class="material-icons-outlined" onClick={handleClose}>
              close
            </span>
          </Box>

          {/* input field */}
          <Stack direction="row" alignItems="center" pl="23px">
            <StyledAutoComplete
              id="asynchronous-demo"
              size="small"
              style={{
                fontFamily: "Energy !important",
                width: "450px",
              }}
              className={classes.typographyStyle}
              open={open}
              ref={autoC}
              value={null}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderTags={() => null}
              getOptionSelected={(option, value) =>
                option.user_id === value.user_id
              }
              onChange={(event, newValue) => {
                handleAddUser(newValue);
                const ele = autoC.current.getElementsByClassName(
                  "MuiAutocomplete-clearIndicator"
                )[0];
                if (ele) ele.click();
              }}
              getOptionLabel={(option) => option.user_id}
              options={usersOptions}
              loading={usersList.status === "loading"}
              renderInput={(params) => (
                <StyleCssTextField
                  {...params}
                  variant="standard"
                  size="small"
                  placeholder="Add users"
                  className={classes.typographyStyle}
                  style={{
                    boxShadow: "none",
                    border: "1px solid #B2B1B1",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                  onChange={(ev) => {
                    // dont fire API if the user delete or not entered anything
                    if (ev.target.value !== "" || ev.target.value !== null) {
                      handleSelectUser(ev.target.value);
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {usersList.status === "loading" ? (
                          <CircularProgress color="inherit" size={15} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Stack>

          {/* Table shown */}
          <DialogContent style={{ maxHeight: "200px", minHeight: "200px" }}>
            <Paper sx={paperStyle}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" stickyHeader="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ pt: 0 }}>User Id</TableCell>
                      <TableCell sx={{ pt: 0 }}>Access Level</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(configDetails.provisioning_access_list || []).map(
                      (row, i) => {
                        return (
                          <StyledTableRow
                            key={row.email}
                            hover
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classesTable.tableRowCell}
                            >
                              {row.email}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classesTable.tableRowCell}
                              sx={{
                                minWidth: "100px",
                                maxWidth: "100px",
                              }}
                            >
                              <Stack
                                display="flex"
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                              >
                                <StyledAutoComplete
                                  blurOnSelect
                                  className={classes.typographyStyle}
                                  style={{
                                    width: "80px",
                                  }}
                                  disableClearable
                                  value={row.access_level}
                                  options={["Read", "Write"]}
                                  onChange={(event, newValue) => {
                                    handleUpdateRole(i, newValue);
                                  }}
                                  renderInput={(params) => (
                                    <CssTextField
                                      {...params}
                                      variant="standard"
                                      size="small"
                                      placeholder={`Type role`}
                                      className={classes.typographyStyle}
                                    />
                                  )}
                                />
                                {i !== 0 && (
                                  <>
                                    <span
                                      class="material-symbols-outlined"
                                      onClick={() => handleDelete(i, row)}
                                      style={{
                                        paddingLeft: "18%",
                                        color: "#b0b0b0",
                                        fontWeight: "100",
                                        fontSize: "24px",
                                      }}
                                    >
                                      cancel
                                    </span>
                                  </>
                                )}
                              </Stack>
                            </TableCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </DialogContent>

          {/* buttons */}
          <DialogActions>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              px={2}
              flex={1}
            >
              {/* Read access Details button */}
              <HtmlTooltip
                placement="top"
                title={
                  <>
                    <ul>
                      <li>Contribute to repository</li>
                      <li>Create branches for repository</li>
                      <li>Create tags for repository</li>
                      <li>View firestore collections</li>
                      <li>View pipelines</li>
                    </ul>
                  </>
                }
              >
                <div className="desciption" style={{ fontSize: "12px" }}>
                  Read Access Details
                </div>
              </HtmlTooltip>

              {/* write access details button */}
              <HtmlTooltip
                placement="top"
                title={
                  <>
                    <ul>
                      <li>Contribute to repository</li>
                      <li>Create branches for repository</li>
                      <li>Create tags for repository</li>
                      <li>View and edit firestore collections</li>
                      <li>View and edit pipelines</li>
                      <li>Run pipelines</li>
                    </ul>
                  </>
                }
              >
                <div className="desciption" style={{ fontSize: "12px" }}>
                  Write Access Details
                </div>
              </HtmlTooltip>

              {/* Done button */}
              <button className="btn" onClick={handleClose}>
                Done
              </button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addAccessList: state.registerNewApp.accessManagement.addAccessList,
  configDetails: state.registerNewApp.MLappDetails.configDetails,
  isChanged: state.registerNewApp.appData.isChanged,
  usersList: state.AppsData.usersList,
  appDetails: state.AppsData.appDetails,
  userInfo: state.user,
  theme:state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(ProvisioningAccess);
