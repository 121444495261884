import { makeStyles, styled, TextField, withStyles } from "@material-ui/core";
import { Autocomplete, InputBase } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => {
    return {
        "& .MuiInputBase-input": {
            fontFamily: "Energy",
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : theme.palette.white.main,
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : theme.palette.dark.light,
            border: "1px solid rgba(66, 89, 108, 0.25)",
            boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "23px",
            padding: "8px",
            "&.Mui-disabled": {
                color:
                    theme.palette.mode === "dark"
                        ? theme.palette.white.main
                        : theme.palette.dark.light,
                // "-webkit-text-fill-color": `#282828 !important`,
                "-webkit-text-fill-color": `${
                    theme.palette.mode === "dark"
                        ? theme.palette.white.main
                        : theme.palette.dark.light
                } !important`,
            },
        },
        "&:focus": {},
    };
});
export const StyledAutoComplete = withStyles((theme) => {
    return {
        tag: {
            fontFamily: "Energy !important",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : theme.palette.dark.light,
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : theme.palette.white.main,
        },
        paper: {
            fontFamily: "Energy",
            fontSize: 13,
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : theme.palette.dark.light,
            backgroundColor:
                theme.palette.mode === "dark"
                    ? `${theme.palette.dark.light} !important`
                    : theme.palette.white.main,
            // "#282828"
        },
    };
})(Autocomplete);

export const CssTextField = withStyles((theme) => ({
    root: {
        width: "98%",
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : theme.palette.dark.light,
        backgroundColor:
            theme.palette.mode === "dark"
                ? theme.palette.dark.light
                : theme.palette.white.main,
        padding: "5px 10px",
        border: "1px solid rgba(66, 89, 108, 0.25)",
        boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        "& .MuiInputBase-root": {
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : theme.palette.dark.light,
        },
        "& label.Mui-focused": {},
        "& .MuiInput-underline:after": {
            borderBottomColor: "rgba(66, 89, 108, 0.25)",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "white",
            "& fieldset": {
                borderColor: "rgba(66, 89, 108, 0.25)",
            },
            "&:hover fieldset": {
                borderColor: "rgba(66, 89, 108, 0.25)",
            },
            "&.Mui-focused fieldset": {
                border: "1px solid rgba(66, 89, 108, 0.25)",
            },
        },
    },
}))(TextField);

export const useStyles = makeStyles((theme) => ({
    typographyStyle: {
        "& .MuiChip-root": {
            fontFamily: "Energy",
            fontSize: 12,
        },
        "& .MuiInput-underline:after": {
            borderBottom: 0,
        },
        "& .MuiInput-underline:before": {
            borderBottom: 0,
            transition: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "0px solid #dedede !important",
        },
        "& .Mui-focused .MuiInput-underline": {
            borderBottom: 0,
        },
        "& .MuiAutocomplete-option": {
            fontFamily: "Energy",
            fontSize: 12,
        },
        "& .MuiAutocomplete-paper": {
            fontFamily: "Energy",
            fontSize: 6,
        },
        "& .MuiInputBase-input": {
            textTransform: "capitalize",
            fontFamily: "Energy",
            fontSize: 14,
            // color: "#282828",
        },
        "& .MuiOutlinedInput-root": {
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
        },
        "& .MuiAutocomplete-popupIndicator": {
            "& span": {
                "& svg": {
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
                    },
                },
            },
        },
    },
    dropdownList: {
        "& .MuiAutocomplete-option": {
            textTransform: "capitalize !important",
            fontSize: "13px",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.dark
                    : theme.palette.dark.main,
        },
    },
}));
