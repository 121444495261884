import React from "react";
import { connect } from "react-redux";
import { Box, Dialog, DialogContent } from "@mui/material";
import "../../screens/NewRegister/index.scss";
import Loader from "../Loader/Loader";

const NotificationModal = ({
  open = false,
  handleClose,
  message,
  waiting = false,
  buttonText = "",
  theme,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    sx={{
      "& .MuiDialog-paper": {
        backgroundImage: "none",
        boxShadow: "4px 4px 12px 4px #434343",
      },
    }}
  >
    <div
      style={{
        minWidth: "320px",
        maxWidth: "600px",
      }}
    >
      <DialogContent>
        <Box
          display="flex"
          alignItems="center"
          mb={1}
          mx={1}
          className={`confirmationText ${theme}`}
        >
          {message || "Kindly complete the form before submitting."}
        </Box>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" px={2} pb={1}>
        <button onClick={handleClose} disabled={waiting} className="btn">
          {waiting ? (
            <Loader color="#ffffff" margin="0px 8px" />
          ) : (
            buttonText || "Done"
          )}
        </button>
      </Box>
    </div>
  </Dialog>
);



const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(NotificationModal);
