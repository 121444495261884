import React, { useState } from "react";
// import { EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Grid } from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { makeStyles } from "@material-ui/core";
import { getFieldLabel } from "../utils";

const useStyles1 = makeStyles((theme) => ({
  container: {
    "& .rdw-editor-main": {
      // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
      backgroundColor:
        theme.palette.mode === "dark" ? "#282828" : "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      borderRadius: "3px",
      border: `1px solid ${
        theme.palette.mode === "dark" ? "#757575" : "rgba(66, 89, 108, 0.25)"
      }`,
      // boxShadow: "2px 4px 4px rgba(0,0,0,.1)",
      padding: "6px 10px",
      fontSize: "12px",
      fontWeight: "400",
    },
    "& .rdw-editor-toolbar": {
      backgroundColor: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      // border: `1px solid ${
      //     theme.palette.mode === "dark"
      //         ? "#000000"
      //         : "rgba(66, 89, 108, 0.25)"
      // }`,
      padding: "6px 0px 0px",
      border: "none",
      fontSize: "12px",
      display: "flex",
      // boxShadow: "2px 4px 4px rgba(0,0,0,.1)",
      // "&:hover": {
      //     boxShadow: `1px 1px 0px ${
      //         theme.palette.mode === "dark" ? "transparent" : "#bfbdbd"
      //     }`,
      // },
    },
    "& .rdw-option-wrapper": {
      backgroundColor:
        theme.palette.mode === "dark" ? "#282828" : "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      border: `1px solid ${
        theme.palette.mode === "dark" ? "#757575" : "rgba(66, 89, 108, 0.25)"
      }`,
      "&:hover": {
        boxShadow: `1px 1px 0px ${
          theme.palette.mode === "dark" ? "transparent" : "#bfbdbd"
        }`,
      },
      "& img": {
        filter: theme.palette.mode === "dark" ? "invert(1)" : "",
      },
    },
    "& .rdw-dropdown-wrapper": {
      backgroundColor:
        theme.palette.mode === "dark" ? "#282828" : "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      border: `1px solid ${
        theme.palette.mode === "dark" ? "#757575" : "rgba(66, 89, 108, 0.25)"
      }`,
      "&:hover": {
        boxShadow: `1px 1px 0px ${
          theme.palette.mode === "dark" ? "transparent" : "#bfbdbd"
        }`,
      },
    },
    "& .rdw-dropdown-optionwrapper": {
      backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    },
    "& .rdw-dropdownoption-highlighted": {
      color: "#4e4e4e",
    },
    "& .rdw-dropdownoption-active": {
      color: "#4e4e4e",
    },
    "& .rdw-colorpicker-modal": {
      backgroundColor: theme.palette.mode === "dark" ? "#4e4e4e" : "#fff",
    },
    "& .rdw-colorpicker-modal-style-label": {
      fontSize: "12px",
    },
    "& .public-DraftStyleDefault-block": {
      margin: 0,
    },
    "& .rdw-dropdown-carettoopen": {
      borderTop:
        theme.palette.mode === "dark" ? "6px solid #fff" : "6px solid #4e4e4e",
    },
    "& .rdw-dropdown-carettoclose": {
      borderTop:
        theme.palette.mode === "dark" ? "6px solid #fff" : "6px solid #4e4e4e",
    },
  },
}));

const useStyles2 = makeStyles((theme) => ({
  container: {
    "& .rdw-editor-main": {
      // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
      backgroundColor:
        theme.palette.mode === "dark" ? "#282828" : "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      borderRadius: "3px",
      border: `1px solid ${
        theme.palette.mode === "dark" ? "#757575" : "rgba(66, 89, 108, 0.25)"
      }`,
      // boxShadow: "2px 4px 4px rgba(0,0,0,.1)",
      padding: "6px 10px",
      fontSize: "12px",
      fontWeight: "400",
    },
    "& .rdw-editor-toolbar": {
      backgroundColor: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      // border: `1px solid ${
      //     theme.palette.mode === "dark"
      //         ? "#000000"
      //         : "rgba(66, 89, 108, 0.25)"
      // }`,
      padding: "6px 0px 0px",
      border: "none",
      fontSize: "12px",
      display: "none",
      // boxShadow: "2px 4px 4px rgba(0,0,0,.1)",
      // "&:hover": {
      //     boxShadow: `1px 1px 0px ${
      //         theme.palette.mode === "dark" ? "transparent" : "#bfbdbd"
      //     }`,
      // },
    },
    "& .rdw-option-wrapper": {
      backgroundColor:
        theme.palette.mode === "dark" ? "#282828" : "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      border: `1px solid ${
        theme.palette.mode === "dark" ? "#757575" : "rgba(66, 89, 108, 0.25)"
      }`,
      "&:hover": {
        boxShadow: `1px 1px 0px ${
          theme.palette.mode === "dark" ? "transparent" : "#bfbdbd"
        }`,
      },
      "& img": {
        filter: theme.palette.mode === "dark" ? "invert(1)" : "",
      },
    },
    "& .rdw-dropdown-wrapper": {
      backgroundColor:
        theme.palette.mode === "dark" ? "#282828" : "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      border: `1px solid ${
        theme.palette.mode === "dark" ? "#757575" : "rgba(66, 89, 108, 0.25)"
      }`,
      "&:hover": {
        boxShadow: `1px 1px 0px ${
          theme.palette.mode === "dark" ? "transparent" : "#bfbdbd"
        }`,
      },
    },
    "& .rdw-dropdown-optionwrapper": {
      backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    },
    "& .rdw-dropdownoption-highlighted": {
      color: "#4e4e4e",
    },
    "& .rdw-dropdownoption-active": {
      color: "#4e4e4e",
    },
    "& .rdw-colorpicker-modal": {
      backgroundColor: theme.palette.mode === "dark" ? "#4e4e4e" : "#fff",
    },
    "& .rdw-colorpicker-modal-style-label": {
      fontSize: "12px",
    },
    "& .public-DraftStyleDefault-block": {
      margin: 0,
    },
    "& .rdw-dropdown-carettoopen": {
      borderTop:
        theme.palette.mode === "dark" ? "6px solid #fff" : "6px solid #4e4e4e",
    },
    "& .rdw-dropdown-carettoclose": {
      borderTop:
        theme.palette.mode === "dark" ? "6px solid #fff" : "6px solid #4e4e4e",
    },
  },
}));

const options = [
  "inline",
  "blockType",
  "fontSize",
  "colorPicker",
  "fontFamily",
];
const TextEditor = (props) => {
  const {
    editorState,
    handleEditorState,
    data,
    widget_type,
    customOptions = null,
    theme,
  } = props;
  const [showEditor, setShowEditor] = useState(false);
  const classes1 = useStyles1();
  const classes2 = useStyles2();

  const { label, key, required } = data;

  const handleShowEditor = () => {
    setShowEditor(!showEditor);
  };

  return (
    <Grid
      item
      xs={12}
      className={showEditor ? classes1.container : classes2.container}
      sx={{ color: "#000" }}
    >
      <div style={{ display: "flex" }}>
        <div className={`heading ${theme}`}>
          {key === "sub_category_name" ? getFieldLabel(widget_type) : label}{" "}
          <span style={{ display: required ? "inline" : "none" }}>*</span>:
        </div>
        {/* {!showEditor && (
          <span
            onClick={handleShowEditor}
            class="material-icons-outlined icon-for-text-editor"
          >
            auto_fix_high
          </span>
        )}
        {showEditor && (
          <span
            onClick={handleShowEditor}
            class="material-icons-outlined icon-for-text-editor"
          >
            cancel
          </span>
        )} */}
      </div>
      <Editor
        editorState={editorState[key]}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onFocus={() => setShowEditor(true)}
        onBlur={() => setShowEditor(false)}
        onEditorStateChange={(reqData) => handleEditorState(key, reqData)}
        toolbar={{
          options: customOptions || options,
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic", "underline"],
          },
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana",
              "Energy",
              "Source Sans Pro",
              "Lucida Grande",
              "Sans-serif",
            ],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          colorPicker: {
            icon: undefined,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            colors: [
              "rgb(255, 0, 127)",
              "rgb(90, 40, 130)",
              "rgb(85, 195, 240)",
              "rgb(0, 166, 165)",
              "rgb(231, 233, 238)",
              "rgb(0, 88, 170)",
              "rgb(80, 180, 40)",
              "rgb(240, 205, 40)",
              "rgb(253, 99, 32)",
              "rgb(240, 140, 42)",
              "rgb(66, 89, 108)",
              "rgb(97,189,109)",
              "rgb(26,188,156)",
              "rgb(84,172,210)",
              "rgb(44,130,201)",
              "rgb(147,101,184)",
              "rgb(71,85,119)",
              "rgb(204,204,204)",
              "rgb(65,168,95)",
              "rgb(0,168,133)",
              "rgb(61,142,185)",
              "rgb(41,105,176)",
              "rgb(85,57,130)",
              "rgb(40,50,78)",
              "rgb(0,0,0)",
              "rgb(247,218,100)",
              "rgb(251,160,38)",
              "rgb(235,107,86)",
              "rgb(226,80,65)",
              "rgb(163,143,132)",
              "rgb(239,239,239)",
              "rgb(255,255,255)",
              "rgb(250,197,28)",
              "rgb(243,121,52)",
              "rgb(209,72,65)",
              "rgb(184,49,47)",
              "rgb(124,112,107)",
              "rgb(209,213,216)",
            ],
          },
        }}
      />
    </Grid>
  );
};

export default TextEditor;
