import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  editLayoutStyle: {
    marginRight: "40px",
    width: "100px",
    height: "26px",
    fontSize: "11px",
    borderRadius: "50px",
    background: "#FC007F",
    color: "white",
    boxShadow: "0px 5px 15px 0px #00000014",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  loadingButtonStyle: {
    marginRight: "40px",
    width: "100px",
    height: "26px",
    fontSize: "11px",
    borderRadius: "50px",
    background: "#FC007F",
    color: "white",
    boxShadow: "0px 5px 15px 0px #00000014",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  searchBarStyle: {
    width: "300px",
    margin: "10px 20px 10px 40px",
    height: "35px",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
    borderRadius: "4px",
    "&.MuiPaper-elevation1": {
      //   boxShadow: "none !important",
      width: "94% !important",
      borderRadius: "50px",
      boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.08)",
    },
    "& .MuiInputBase-input": {
      fontFamily: "Energy",
      fontSize: "14px",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
    "& .MuiSvgIcon-root": {
      fill: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
  },
}));
