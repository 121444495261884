const constants = {
  CHANGE_THEME: "CHANGE_THEME",
  UPDATE_MULTI_SELECTIONS: "UPDATE_MULTI_SELECTIONS",
  UPDATE_ALERT_INFO: "UPDATE_ALERT_INFO",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  RESET_FILTERS: "RESET_FILTERS",
  UPDATE_DIALOG_INFO: "UPDATE_DIALOG_INFO",
  TOGGLE_SIDENAV: "TOGGLE_SIDENAV",
  REFRESH_COMMENTS: "REFRESH_COMMENTS",
  SET_ACTIVE_ASSET_COLLECTION: "SET_ACTIVE_ASSET_COLLECTION",
  GET_REGISTER_APP_TEMPLATE: "GET_REGISTER_APP_TEMPLATE",
  REGISTER_APP_TEMPLATE_RECEIVED: "REGISTER_APP_TEMPLATE_RECEIVED",
  GET_UPDATE_VIEW_REPORT_DETAILS: "GET_UPDATE_VIEW_REPORT_DETAILS",
  VIEW_REPORT_DETAILS_RECEIVED: "VIEW_REPORT_DETAILS_RECEIVED",
  GET_ALL_CATEGORY_LIST: "GET_ALL_CATEGORY_LIST",
  CATEGORY_LIST_RECEIVED: "CATEGORY_LIST_RECEIVED",
  GET_NEW_ROUTE: "GET_NEW_ROUTE",
  ALL_ROUTES_RECEIVED: "ALL_ROUTES_RECEIVED",

  GET_ALL_APPS: "GET_ALL_APPS",
  ALL_APPS_RECEIVED: "ALL_APPS_RECEIVED",
  GET_ALL_REF_APPS: "GET_ALL_REF_APPS",
  ALL_REF_APPS_RECEIVED: "ALL_REF_APPS_RECEIVED",
  GET_MANAGE_APPS: "GET_MANAGE_APPS",
  MANAGE_APPS_RECEIVED: "MANAGE_APPS_RECEIVED",
  GET_REF_MANAGE_APPS: "GET_REF_MANAGE_APPS",
  REF_MANAGE_APPS_RECEIVED: "REF_MANAGE_APPS_RECEIVED",
  GET_FAV_APPS: "GET_FAV_APPS",
  FAV_APPS_RECEIVED: "FAV_APPS_RECEIVED",
  GET_REF_FAV_APPS: "GET_REF_FAV_APPS",
  REF_FAV_APPS_RECEIVED: "REF_FAV_APPS_RECEIVED",
  GET_RECENT_APPS: "GET_RECENT_APPS",
  RECENT_APPS_RECEIVED: "RECENT_APPS_RECEIVED",
  GET_REF_RECENT_APPS: "GET_REF_RECENT_APPS",
  REF_RECENT_APPS_RECEIVED: "REF_RECENT_APPS_RECEIVED",
  VALIDATE_APPLICATION_NAME: "VALIDATE_APPLICATION_NAME",
  GET_APP_DETAILS: "GET_APP_DETAILS",
  APP_DETAILS_RECEVIED: "APP_DETAILS_RECEVIED",
  UPDATE_APP_DETAILS: "UPDATE_APP_DETAILS",
  APP_DETAILS_UPDATED: "APP_DETAILS_UPDATED",
  CLEAN_APP_DETAILS: "CLEAN_APP_DETAILS",
  GET_APP_VIEWS_COMMENTS: "GET_APP_VIEWS_COMMENTS",
  APP_VIEWS_COMMENTS_RECEIVED: "APP_VIEWS_COMMENTS_RECEIVED",
  GET_UPDATED_COMMENTS: "GET_UPDATED_COMMENTS",
  UPDATED_COMMENTS_RECEIVED: "UPDATED_COMMENTS_RECEIVED",
  UPDATE_TOTAL_VIEWS: "UPDATE_TOTAL_VIEWS",
  TOTAL_VIEWS_UPDATED: "TOTAL_VIEWS_UPDATED",
  ADD_COMMENTS: "ADD_COMMENTS",
  GET_USERS_LIST: "GET_USERS_LIST",
  USERS_LIST_RECEIVED: "USERS_LIST_RECEIVED",
  GET_COUNTRY_BRANDS: "GET_COUNTRY_BRANDS",
  COUNTRY_BRANDS_RECEIVED: "COUNTRY_BRANDS_RECEIVED",
  GET_UNIQUE_BACKEND_REPO: "GET_UNIQUE_BACKEND_REPO",
  UNIQUE_BACKEND_REPO_RECEIVED: "UNIQUE_BACKEND_REPO_RECEIVED",
  GET_UNIQUE_FRONTEND_REPO: "GET_UNIQUE_FRONTEND_REPO",
  UNIQUE_FRONTEND_REPO_RECEIVED: "UNIQUE_FRONTEND_REPO_RECEIVED",
  UPDATE_REPOS: "UPDATE_REPOS",
  GET_LAYOUT_APPS: "GET_LAYOUT_APPS",
  LAYOUT_APPS_RECEIVED: "LAYOUT_APPS_RECEIVED",
  GET_RECOMMENDED_APPS: "GET_RECOMMENDED_APPS",
  RECOMMENDED_APPS_RECEIVED: "RECOMMENDED_APPS_RECEIVED",
  UPDATE_RECOMMENDED_APPS: "UPDATE_RECOMMENDED_APPS",
  GET_LAYOUT_REF_APPS: "GET_LAYOUT_REF_APPS",
  LAYOUT_REF_APPS_RECEIVED: "LAYOUT_REF_APPS_RECEIVED",
  VALIDATE_UNIQUE_APP_URL: "VALIDATE_UNIQUE_APP_URL",
  UNIQUE_APP_URL_RECEIVED: "UNIQUE_APP_URL_RECEIVED",
  UPDATE_DATASETS_TAGS: "UPDATE_DATASETS_TAGS",
  DATASETS_TAGS_UPDATED: "DATASETS_TAGS_UPDATED",
  GET_UNIQUE_APP_HANDLE: "GET_UNIQUE_APP_HANDLE",
  UNIQUE_APP_HANDLE_RECEIVED: "UNIQUE_APP_HANDLE_RECEIVED",
  UPDATE_APP_HANDLE: "UPDATE_APP_HANDLE",
  GET_USAGE_REPORT_USERS: "GET_USAGE_REPORT_USERS",
  USAGE_REPORT_USERS_RECEIVED: "USAGE_REPORT_USERS_RECEIVED",
  CLEAN_USAGE_REPORT_USERS: "CLEAN_USAGE_REPORT_USERS",
  GET_USER_ACCESS_LIST: "GET_USER_ACCESS_LIST",
  USER_ACCESS_LIST_RECEIVED: "USER_ACCESS_LIST_RECEIVED",
  CLEAN_USER_ACCESS_LIST: "CLEAN_USER_ACCESS_LIST",
  REFRESH_USER_ACCESS_LIST: "REFRESH_USER_ACCESS_LIST",
  USER_ACCESS_LIST_REFRESHED: "USER_ACCESS_LIST_REFRESHED",
  GET_SIGNUP_TEMPLATES: "GET_SIGNUP_TEMPLATES",
  SIGNUP_TEMPLATES_RECEIVED: "SIGNUP_TEMPLATES_RECEIVED",
  UPDATE_JOB_ROLE_OTHER_OPTIONS: "UPDATE_JOB_ROLE_OTHER_OPTIONS",
  UPDATE_FUNCTION_OTHER_OPTIONS: "UPDATE_FUNCTION_OTHER_OPTIONS",
  JOB_ROLE_OTHER_OPTIONS_UPDATED: "JOB_ROLE_OTHER_OPTIONS_UPDATED",
  FUNCTION_OTHER_OPTIONS_UPDATED: "FUNCTION_OTHER_OPTIONS_UPDATED",
  UPDATE_USER: "UPDATE_USER",
  USER_UPDATED: "USER_UPDATED",
  UPDATE_HOME_LAYOUT: "UPDATE_HOME_LAYOUT",
  HOME_LAYOUT_UPDATED: "HOME_LAYOUT_UPDATED",
  UPDATE_LAYOUT_DATA_LIST: "UPDATE_LAYOUT_DATA_LIST",
  LAYOUT_DATA_LIST_UPDATED: "LAYOUT_DATA_LIST_UPDATED",
  UPDATE_DRAFT_STATUS: "UPDATE_DRAFT_STATUS",
  DRAFT_STATUS_UPDATED: "DRAFT_STATUS_UPDATED",
  UPDATE_ML_APP_URL: "UPDATE_ML_APP_URL",
  SYNC_ACCESS_IN_ENV: "SYNC_ACCESS_IN_ENV",
  ACCESS_IN_ENV_SYNCED: "ACCESS_IN_ENV_SYNCED",
  CLEAN_USERS_LIST: "CLEAN_USERS_LIST",
  UPDATE_CURRENT_TAB: "UPDATE_CURRENT_TAB",
  GET_HELP_CENTER_TEMPLATES: "GET_HELP_CENTER_TEMPLATES",
  HELP_CENTER_TEMPLATES_RECEIVED: "HELP_CENTER_TEMPLATES_RECEIVED",
  SET_HELP_CENTER_STATE: "SET_HELP_CENTER_STATE",
  GET_APP_LIST: "GET_APP_LIST",
  APP_LIST_RECEIVED: "APP_LIST_RECEIVED",
  SEND_MAIL_TO_APP_OWNER: "SEND_MAIL_TO_APP_OWNER",
  MAIL_TO_APP_OWNER_SENT: "MAIL_TO_APP_OWNER_SENT",
  SAVE_TICKET_ID: "SAVE_TICKET_ID",
  TICKET_ID_SAVED: "TICKET_ID_SAVED",
  UPDATE_APP_DASHBOARD: "UPDATE_APP_DASHBOARD",
  GET_APP_DASHBOARD_DETAILS: "GET_APP_DASHBOARD_DETAILS",
  APP_DASHBOARD_DETAILS_RECEIVED: "APP_DASHBOARD_DETAILS_RECEIVED",
  UPDATE_POSITION_META: "UPDATE_POSITION_META",
  POSITION_META_UPDATED: "POSITION_META_UPDATED",
  GET_APP_DATA: "GET_APP_DATA",
  APP_DATA_RECEIVED: "APP_DATA_RECEIVED",
  ADD_REPORT_TEMPLATE: "ADD_REPORT_TEMPLATE",
  DELETE_REPORT_TEMPLATE: "DELETE_REPORT_TEMPLATE",
  UPDATE_REPORT_TEMPLATE: "UPDATE_REPORT_TEMPLATE",
  UPDATE_REPORT_DESC: "UPDATE_REPORT_DESC",
  CLEAN_APP_TEMP_STATE: "CLEAN_APP_TEMP_STATE",
  UPDATE_AZURE_GROUP_MEMBERS: "UPDATE_AZURE_GROUP_MEMBERS",
  AZURE_GROUP_MEMBERS_UPDATED: "AZURE_GROUP_MEMBERS_UPDATED",
  UPDATE_WORK_SPACE: "UPDATE_WORK_SPACE",
  UPDATE_POWER_BI_REPORTS: "UPDATE_POWER_BI_REPORTS",
  GET_USER_FILTERS: "GET_USER_FILTERS",
  USER_FILTERS_RECEIVED: "USER_FILTERS_RECEIVED",
  UPDATE_USER_FILTERS: "UPDATE_USER_FILTERS",
  //shashank's change
  GET_TOP_USERS_APP: "GET_TOP_USERS_APP",
  TOP_USERS_RECEIVED: "TOP_USERS_RECEIVED",
  CLEAN_TOP_USER_STATE: "CLEAN_TOP_USER_STATE",
  DUBLICATE_WORKSPACE_LIST: "DUBLICATE_WORKSPACE_LIST",
  CLEAN_REGISTER_STATES: "CLEAN_REGISTER_STATES",
  GET_DEFINED_CATEGORY: "GET_DEFINED_CATEGORY",
  DEFINED_CATEGORY_RECEIVED: "DEFINED_CATEGORY_RECEIVED",
  UPDATE_EXPLORE_APPS: "UPDATE_EXPLORE_APPS",
  CLEAN_EXPLORE_APPS: "CLEAN_EXPLORE_APPS",
  // Changes By Nomaan
  UPDATE_BI_APP_DETAILS: "UPDATE_BI_APP_DETAILS",
  UPDATE_ML_APP_DETAILS: "UPDATE_ML_APP_DETAILS",
  UPDATE_ACCESS_MANAGEMENT: "UPDATE_ACCESS_MANAGEMENT",
  UPDATE_APP_DATA: "UPDATE_APP_DATA",
  UPDATE_STATUS_DATA: "UPDATE_STATUS_DATA",
  UPDATE_ACTIVE_STEPPER: "UPDATE_ACTIVE_STEPPER",
  CLEAN_NEW_APP_REGISTER_STATES: "CLEAN_NEW_APP_REGISTER_STATES",
  UPDATE_TOKEN: "UPDATE_TOKEN",
};

export default constants;
