// Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import SkeletonLoadScreen from "../../components/ImageLoader/SkeletonLoadScreen";

// Import action creators
import {
  cleanAppDetails,
  cleanTopUsersState,
  setActiveAssetCollection,
  updateUserInfo,
  getRegisterAppTemplate,
} from "../../redux/actions";
import {
  getManageApps,
  getRefreshedManageApps,
  getLayoutApps,
} from "../../redux/actions";

// Import utils/data
import { UpdatePageView } from "../../utils/utils";

// Import styles
import "./Manage.scss";

// Import Assets
import Add_new_app from "../../../assets/images/Add_new_app.svg";
import no_app_found from "../../../assets/images/no_app_found.svg";
import error_smw from "../../../assets/images/error_smw.svg";
import { useHistory, useParams } from "react-router";
import NewAppCard from "../../components/AppCard/NewAppCard";
import NewAppTable from "../../components/AppTable/NewAppTable";
import UsageMetricsComponent from "../../components/UsageMetricsComponent/UsageMetricsComponent";

function ManageApps(props) {
  const { match, AppsData = {}, user, registerTemplate } = props;
  const { tab } = useParams();
  const history = useHistory();
  const [tabValue, setTabValue] = useState("My Applications");
  const [renderAppState, setRenderAppState] = useState({});
  const [appDataState, setAppDataState] = useState([]);
  const [monthKeys, setMonthKeys] = useState([]);
  const [monthShort, setMonthShort] = useState([]);
  const filteredAppDataState = appDataState.filter(
    (row) =>
      row.app_meta.app_type === "ml_app" &&
      row.approval_meta.approval_status === "published"
  );
  const getPayload = () => {
    return {
      type: "manage",
      role: user.role,
      email: user.preferred_username,
    };
  };

  const dispatch = useDispatch();
  const handleRefreshCard = () => {
    dispatch(getRefreshedManageApps(getPayload()));
  };

  useEffect(() => {
    if (
      tab !== "requested-apps" &&
      tab !== "my-apps" &&
      tab !== "usage-metrics"
    ) {
      history.push("/myapps/my-apps");
      setTabValue("My Applications");
    } else {
      if (tab === "requested-apps") {
        setTabValue("Requested Apps");
      } else if (tab === "my-apps") {
        setTabValue("My Applications");
      } else if (tab === "usage-metrics") {
        setTabValue("Usage Metrics");
      }
    }
  }, []);

  useEffect(() => {
    UpdatePageView("myapps");
    dispatch(getManageApps(getPayload()));
    if (AppsData?.layoutApps?.status === "pending") {
      dispatch(
        getLayoutApps({
          user_id: user.preferred_username,
        })
      );
    }
    return () => {
      dispatch(cleanTopUsersState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (AppsData.manageApps.status === "success") {
      setRenderAppState(AppsData.manageApps.data);
      setAppDataState(AppsData.manageApps.data.my_apps);
      if (Object.keys(AppsData.appDetails.data).length) {
        dispatch(cleanAppDetails());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppsData.manageApps, dispatch]);

  useEffect(() => {
    if (AppsData.refreshedManageApps.status === "success") {
      setRenderAppState(AppsData.refreshedManageApps.data);
      setAppDataState(AppsData.refreshedManageApps.data.my_apps);
    }
  }, [AppsData.refreshedManageApps]);

  const tabListStyle = {
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      height: "2.5px",
      marginBottom: "5px",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#FC007F",
    },
  };
  const tabStyle = {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "23px",
    textTransform: "none",
    color: "#FC007F",
    opacity: 0.6,
    "&.Mui-selected": {
      color: "#FC007F",
      opacity: 1,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  };

  const contentContainer = {
    "&.MuiTabPanel-root": {
      padding: "0px !important",
    },
  };

  const HandleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === "My Applications") {
      history.push("/myapps/my-apps");
      setAppDataState(renderAppState.my_apps);
    } else if (newValue === "Requested Apps") {
      history.push("/myapps/requested-apps");
      setAppDataState(renderAppState.requested_apps);
    } else {
      history.push("/myapps/usage-metrics");
      // setAppDataState(dummyData);
    }
  };

  const handleClickCreateNew = () => {
    history.push("/register");
  };

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    const currentMonthFormatted = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    })
      .format(currentDate)
      .replace(" ", "_")
      .toLowerCase();
    const currentMonthShort = new Intl.DateTimeFormat("en-US", {
      month: "short",
      year: "numeric",
    }).format(currentDate);

    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(currentDate.getMonth() - 1);
    const lastMonthFormatted = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    })
      .format(lastMonth)
      .replace(" ", "_")
      .toLowerCase();
    const lastMonthShort = new Intl.DateTimeFormat("en-US", {
      month: "short",
      year: "numeric",
    }).format(lastMonth);

    const lastLastMonth = new Date(currentDate);
    lastLastMonth.setMonth(currentDate.getMonth() - 2);
    const lastLastMonthFormatted = new Intl.DateTimeFormat("en-US", {
      month: "long",
      year: "numeric",
    })
      .format(lastLastMonth)
      .replace(" ", "_")
      .toLowerCase();
    const lastLastMonthShort = new Intl.DateTimeFormat("en-US", {
      month: "short",
      year: "numeric",
    }).format(lastLastMonth);

    setMonthKeys([
      currentMonthFormatted,
      lastMonthFormatted,
      lastLastMonthFormatted,
    ]);
    setMonthShort([currentMonthShort, lastMonthShort, lastLastMonthShort]);
  }, []);

  useEffect(() => {
    if (registerTemplate.status === "pending") {
      dispatch(getRegisterAppTemplate());
    }
  }, [dispatch]);

  return (
    <LayoutTopSideBottom match={match} selected="/myapps">
      {AppsData?.manageApps?.status === "error" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img style={{ marginTop: "150px" }} src={error_smw} alt="" />
        </div>
      )}
      {AppsData?.manageApps?.status === "loading" && (
        <SkeletonLoadScreen showSingleRow={true} showNavTab={true} />
      )}
      {AppsData?.manageApps?.status === "success" && (
        <div className="main-container-mng">
          <div className="applications-container-mng">
            <div className="aide-root-mng">
              <TabContext value={tabValue}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TabList
                    onChange={HandleChangeTab}
                    aria-label="lab API tabs example"
                    TabIndicatorProps={{
                      children: <span className="MuiTabs-indicatorSpan" />,
                    }}
                    sx={tabListStyle}
                  >
                    <Tab
                      className="GA-myapps-tabs"
                      sx={tabStyle}
                      label="My Applications"
                      value="My Applications"
                    />
                    {/* {(user.role === "admin" || user.role === "super_admin") && (
                      <Tab
                        className="GA-myapps-tabs"
                        sx={tabStyle}
                        label="Requested Apps"
                        value="Requested Apps"
                      />
                    )} */}
                    {/* {user.role === "super_admin" && (
                      <Tab
                        className="GA-myapps-tabs"
                        sx={tabStyle}
                        label="Usage Metrics"
                        value="Usage Metrics"
                      />
                    )} */}
                  </TabList>
                  {/* {user.ui_preferences.grid_size === "list" &&
                    tabValue !== "Usage Metrics" && (
                      <button
                        onClick={handleClickCreateNew}
                        style={{ marginTop: "10px" }}
                      >
                        Create New App
                      </button>
                    )} */}
                </div>
                <div
                  style={{ marginBottom: "0px" }}
                  className="applications-container-mng"
                >
                  <div
                    style={{ marginBottom: "0px" }}
                    className="applications-row-mng"
                  >
                    <TabPanel
                      style={{
                        height: "calc(100vh - 210px)",
                        overflowY: "auto",
                      }}
                      className={contentContainer}
                      value="My Applications"
                    >
                      {user.ui_preferences.grid_size === "list" &&
                        filteredAppDataState.length === 0 && (
                          <div
                            className="img-container"
                            style={{
                              margin: "auto",
                              marginTop: "100px",
                            }}
                          >
                            <img src={Add_new_app} alt="New App"></img>
                          </div>
                        )}
                      {user.ui_preferences.grid_size !== "list" && (
                        <>
                          {filteredAppDataState.length !== 0 && (
                            <div className="applications-row-grid-mng">
                              {/* <>
                                <NewAppCard type="add"></NewAppCard>
                              </> */}
                              {filteredAppDataState.map((row, index) => (
                                <>
                                  <NewAppCard
                                    // showDeleteIcon={true}
                                    handleRefreshCard={handleRefreshCard}
                                    row={row}
                                    key={row.app_id}
                                    appIndex={index}
                                    pageLoadState={
                                      AppsData?.refreshedManageApps?.status
                                    }
                                  ></NewAppCard>
                                </>
                              ))}
                            </div>
                          )}
                          {filteredAppDataState.length === 0 && (
                            <>
                              {/* <div className="applications-row-grid-mng">
                                <NewAppCard type="add"></NewAppCard>
                              </div> */}
                              <div
                                className="img-container"
                                style={{
                                  margin: "auto",
                                  marginTop: "100px",
                                }}
                              >
                                <img src={Add_new_app} alt="New App"></img>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {user.ui_preferences.grid_size === "list" &&
                        filteredAppDataState.length !== 0 && (
                          <NewAppTable
                            tableData={filteredAppDataState}
                          ></NewAppTable>
                        )}
                    </TabPanel>
                    <TabPanel
                      style={{
                        height: "calc(100vh - 210px)",
                        overflowY: "auto",
                      }}
                      className={contentContainer}
                      value="Requested Apps"
                    >
                      {user.ui_preferences.grid_size !== "list" && (
                        <>
                          {filteredAppDataState.length !== 0 && (
                            <div className="applications-row-grid-mng">
                              {filteredAppDataState.length !== 0 &&
                                filteredAppDataState.map((row, index) => (
                                  <>
                                    <NewAppCard
                                      handleRefreshCard={handleRefreshCard}
                                      row={row}
                                      key={row.app_id}
                                      appIndex={index}
                                      // showDeleteIcon={true}
                                      pageLoadState={
                                        AppsData?.refreshedManageApps?.status
                                      }
                                    ></NewAppCard>
                                  </>
                                ))}
                            </div>
                          )}
                          {filteredAppDataState.length === 0 && (
                            <div
                              className="img-container-mng"
                              style={{
                                margin: "auto",
                                marginTop: "100px",
                              }}
                            >
                              <img
                                className="No-apps-image-mng"
                                src={no_app_found}
                                alt="No App found"
                              ></img>
                            </div>
                          )}
                        </>
                      )}
                      {user.ui_preferences.grid_size === "list" &&
                        filteredAppDataState.length !== 0 && (
                          <NewAppTable
                            tableData={filteredAppDataState}
                          ></NewAppTable>
                        )}
                      {user.ui_preferences.grid_size === "list" &&
                        filteredAppDataState.length === 0 && (
                          <div
                            className="img-container-mng"
                            style={{
                              margin: "auto",
                              marginTop: "100px",
                            }}
                          >
                            <img
                              className="No-apps-image-mng"
                              src={no_app_found}
                              alt="No App found"
                            ></img>
                          </div>
                        )}
                    </TabPanel>
                    <TabPanel
                      style={{
                        height: "calc(100vh - 210px)",
                        overflowY: "auto",
                      }}
                      className={contentContainer}
                      value="Usage Metrics"
                    >
                      <UsageMetricsComponent
                        monthKeys={monthKeys}
                        monthShort={monthShort}
                      />
                    </TabPanel>
                  </div>
                </div>
              </TabContext>
            </div>
          </div>
        </div>
      )}
    </LayoutTopSideBottom>
  );
}

ManageApps.propTypes = {
  user: PropTypes.object,
  verticals: PropTypes.array,
  match: PropTypes.object,
  AppsData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  verticals: state.data.verticals,
  AppsData: state.AppsData,
  registerTemplate: state.register.registerTemplate,
});

const mapDispatchToProps = {
  updateUserInfo,
  setActiveAssetCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageApps);
