import React, { useState } from "react";
import { connect } from "react-redux";
import "./AppReport.scss";
import Tooltip from "@mui/material/Tooltip";

const GatewayWidgitName = ({ data, expanded, setExpanded, theme }) => {
  const get_WidgitName = () => {
    let widgetName = data.sub_category_name;
    if (data.sub_category_name?.startsWith('{"blocks"')) {
      widgetName = JSON.parse(data.sub_category_name).blocks[0]?.text;
    }
    if (widgetName.length <= 25) {
      return widgetName;
    } else {
      return widgetName.slice(0, 25) + "...";
    }
  };
  const get_WidgitNameFull = () => {
    let widgetName = data.sub_category_name;
    if (data.sub_category_name?.startsWith('{"blocks"')) {
      widgetName = JSON.parse(data.sub_category_name).blocks[0]?.text;
    }
    return widgetName;
  };

  return (
    <div className="folder">
      <span
        class="material-symbols-outlined"
        onClick={() => {
          setExpanded(!expanded);
        }}
        style={{
          cursor: "pointer",
          color: theme === "light" ? "#000" : "#fff",
          paddingRight: "10px",
        }}
      >
        {expanded ? "expand_less" : "expand_more"}
      </span>
      <div
        style={{
          display: "flex",
          color: theme === "light" ? "#000" : "#fff",
          alignItems: "center",
        }}
      >
        {data?.app_logo_type === "google_font" ? (
          <span
            class="material-icons-outlined googleIcons"
            style={{
              paddingRight: "10px",
              fontSize: "20px",
              color: theme === "light" ? "#000" : "#fff",
            }}
          >
            {data?.sub_category_icon}
          </span>
        ) : (
          <img
            style={{
              maxWidth: "24px",
              maxHeight: "24px",
              paddingRight: "10px",
            }}
            src={data?.sub_category_icon}
            alt=""
          />
        )}
        {get_WidgitName().length <= 25 ? (
          get_WidgitName()
        ) : (
          <Tooltip title={get_WidgitNameFull()}>{get_WidgitName()}</Tooltip>
        )}
      </div>
    </div>
  );
};

const ReportName = ({ data, loadedReport, setLoadedReport, theme }) => {
  const get_reportName = () => {
    let reportName = data.sub_category_name;
    if (data.sub_category_name?.startsWith('{"blocks"')) {
      reportName = JSON.parse(data.sub_category_name).blocks[0]?.text;
    }

    if (reportName.length <= 25) {
      return reportName;
    } else {
      return reportName.slice(0, 25) + "...";
    }
  };

  const get_reportNameFull = () => {
    let reportName = data.sub_category_name;
    if (data.sub_category_name?.startsWith('{"blocks"')) {
      reportName = JSON.parse(data.sub_category_name).blocks[0]?.text;
    }
    return reportName;
  };

  const change_report = () => {
    if (data?.sub_category_id !== loadedReport?.sub_category_id) {
      setLoadedReport(data);
    }
  };
  return (
    <div
      onClick={change_report}
      className={
        data.sub_category_id === loadedReport?.sub_category_id
          ? `report report-${theme} active-report-${theme}`
          : `report report-${theme}`
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {data?.app_logo_type === "google_font" ? (
          <span
            class="material-icons-outlined googleIcons"
            style={{
              paddingRight: "10px",
              fontSize: "20px",
              color: theme === "light" ? "#000" : "#fff",
            }}
          >
            {data?.sub_category_icon}
          </span>
        ) : (
          <img
            style={{
              maxWidth: "24px",
              maxHeight: "24px",
              paddingRight: "10px",
            }}
            src={data?.sub_category_icon}
            alt=""
          />
        )}

        {get_reportName().length <= 25 ? (
          get_reportName()
        ) : (
          <Tooltip title={get_reportNameFull()}>{get_reportName()}</Tooltip>
        )}
      </div>
    </div>
  );
};

const TreeNode = ({ node, loadedReport, setLoadedReport, theme }) => {
  const [expanded, setExpanded] = useState(true);
  return node.sub_category_type === "hybrid" ||
    node.sub_category_type === "parentReport" ||
    node.sub_category_type === "customHybrid" ? (
    <div className="folder-Container">
      <GatewayWidgitName
        data={node}
        expanded={expanded}
        setExpanded={setExpanded}
        theme={theme}
      />
      <div
        style={{
          display: expanded ? "block" : "none",
          borderLeft: "1px dashed #ccc",
          marginLeft: "10px",
        }}
      >
        <Tree
          data={node.sub_category}
          loadedReport={loadedReport}
          setLoadedReport={setLoadedReport}
          theme={theme}
        />
      </div>
    </div>
  ) : (
    <ReportName
      data={node}
      loadedReport={loadedReport}
      setLoadedReport={setLoadedReport}
      theme={theme}
    />
  );
};

const Tree = ({ data, loadedReport, setLoadedReport, theme }) => {
  return data.map((item, index) => {
    if (
      item?.sub_category_type === "hybrid" ||
      item?.sub_category_type === "parentReport" ||
      item?.sub_category_type === "customHybrid" ||
      item?.sub_category_type === "report"
    ) {
      return (
        <TreeNode
          node={item}
          loadedReport={loadedReport}
          setLoadedReport={setLoadedReport}
          theme={theme}
        />
      );
    }
  });
};

const SideNavigationPanel = ({
  definedCategoriesList,
  loadedReport,
  setLoadedReport,
  theme = "light",
  sidePanelWidth = "282px",
  expandButtonLeftMargin = "331px",
  showSidePanel = true,
}) => {
  const [navExpanded, setNavExpanded] = useState(showSidePanel);
  return (
    <div
      style={{
        display: "flex",
        minWidth: !navExpanded ? "20px" : null,
        borderRight: "1px solid #ccc",
      }}
    >
      {navExpanded && (
        <div
          style={{
            width: sidePanelWidth,
            overflow: "auto",
            height: "calc(100vh - 90px)",
            paddingTop: "15px",
          }}
        >
          <Tree
            data={definedCategoriesList}
            loadedReport={loadedReport}
            setLoadedReport={setLoadedReport}
            theme={theme}
          />
        </div>
      )}

      <div
        style={{ left: navExpanded ? expandButtonLeftMargin : "68px" }}
        className={navExpanded ? "expand-button" : "collapsed-button"}
        onClick={() => {
          setNavExpanded(!navExpanded);
        }}
      >
        <span
          style={{
            color: "#fff",
            fontSize: "20px",
          }}
          class="material-symbols-outlined"
        >
          {navExpanded ? "chevron_left" : "chevron_right"}
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
  editable_layout: state.registerNewApp.statusData.editLayout,
  showSidePanel:
    state.appDashboard.appDashboardState?.appData?.app_details?.show_sidebar,
});
export default connect(mapStateToProps)(SideNavigationPanel);
