import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { v4 } from "uuid";
import {
  updateReportTemplate,
  update_appData,
  update_statusData,
} from "../../../redux/actions";
import AppLayout from "./AppLayout";
import DashboardTemplate from "../Components/DashboardTemplate";
import EditPreviewToggle from "../Components/EditPreviewToggle";
import { ownerList } from "../../../utils/utils";
import { useParams } from "react-router";

const initialState = {
  bi_application: "Power BI",
  show_description: false,
  sub_category_description: "",
  function: ["All"],
  line_of_business: ["Global"],
  business_owners: [],
  technical_owners: [],
  geography: [{ countries: ["All"], region: "All" }],
  sub_category_id: v4(),
  sub_category_name: "Untitled report",
  sub_category_type: "report",
  sub_category_img_data: {
    img_url: "",
    blob_name: "",
    selected_size: "fit",
  },
  url: "",
  view_count: 0,
  additional_images: [],
  app_logo_type: "google_font",
  blob_name: "analytics",
  sub_category_icon: "dashboard",
};
const AppTemplate = (props) => {
  const {
    selectedTemplate,
    user,
    // appFields,
    template_no,
    // setCategoryInfo,
    // categoryInfo,
    // setCategoryInfoLocal,
    isFieldsEditable,
    editable_layout,
    appDetails,
    userInfo,
    app_details,
    visited,
    savedTemplateData,
    setOpen = () => {},
    setAlertMsg = () => {},
    setDraftApp = () => {},
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedTemplate === 1) {
      dispatch(
        updateReportTemplate({
          showSubCatPage: true,
        })
      );
    }
  }, [selectedTemplate]);

  const { appInfo, draftId, tab } = useParams();

  useEffect(() => {
    if (
      appInfo &&
      !(
        (ownerList(appDetails?.data) || []).includes(
          userInfo.preferred_username
        ) || userInfo.role === "super_admin"
      )
    ) {
      dispatch(update_statusData({ editLayout: false }));
    }
  }, [editable_layout]);

  // useEffect(() => {
  //   dispatch(update_statusData({ editLayout: false }));
  // }, []);

  // CHANGES-MADE
  //----------------
  useEffect(() => {
    if (Object.keys(savedTemplateData).length === 0) {
      if (appInfo || draftId) {
        // console.log("appInfoID-details", appInfo, draftId);
        dispatch(
          update_appData({
            savedTemplateData: {
              template_no: app_details?.chosen_template,
              templateData: app_details?.defined_categories,
              temp_img_data: app_details?.temp_img_data || {},
            },
          })
        );
      }
    }
  }, []);

  return (
    <>
      <div
      // style={
      //   editable_layout
      //     ?
      //     {}: {
      //       // marginLeft: "auto",
      //       // marginRight: "auto",
      //       maxWidth: "1200px",
      //     }
      // }
      >
        {selectedTemplate > 1 && selectedTemplate < 6 && (
          // (template_no > 1 && template_no < 6 && (
          <DashboardTemplate
            // appFields={appFields}
            template_no={template_no}
            // setCategoryInfo={setCategoryInfo}
            // categoryInfo={categoryInfo}
            // setCategoryInfoLocal={setCategoryInfoLocal}
            isFieldsEditable={isFieldsEditable}
            setOpen={setOpen}
            setAlertMsg={setAlertMsg}
            setDraftApp={setDraftApp}
          />
        )}
        {selectedTemplate > 5 && selectedTemplate < 10 && (
          <AppLayout
            setOpen={setOpen}
            setAlertMsg={setAlertMsg}
            setDraftApp={setDraftApp}
          />
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
  allApps: state.AppsData.allApps,
  theme: state.user.ui_preferences?.theme,
  appTemplateState: state.appTemplate.appTemplateState.templateData,
  template_no: state.appTemplate.appTemplateState.template_no,
  registerTemplateFields:
    state.register.registerTemplate.data.register_app_template.fields[0]
      .options[0].subform_templates[2],
  appDetails: state.AppsData.appDetails,
  editable_layout: state.registerNewApp.statusData.editLayout,
  userInfo: state.user,
  app_details: state.AppsData?.appDetails?.data?.app_details,
  appInfoID: state.registerNewApp.appData.appInfoID,
  savedTemplateData: state.registerNewApp.appData.savedTemplateData,
});

export default connect(mapStateToProps)(AppTemplate);
