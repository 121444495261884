// Import required libraries
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Import styles
import "./Footer.scss";

// Import config
import { config } from "../../config/config";

function Footer(props) {
  let { user } = props;
  const currentYear = new Date().getFullYear();
  return (
    <footer
      style={{ zIndex: user.report_fullscreen ? -1 : 15 }}
      className="footer-theme-class"
    >
      <p className="footer-text">{`Copyright @${currentYear}. ${config.footerText}`}</p>
    </footer>
  );
}

Footer.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(Footer);