import { put, takeLatest, all } from "redux-saga/effects";
import * as apiService from "../../apiServices";
import C from "../constants";
import { updateUserInfo, updateAlertInfo, refreshComments } from "../actions";
import { toast } from "react-toastify";
import { getAppIds } from "../../utils/utils";
import { config } from "../../config/config";

function* fetchAllApps(payload = {}) {
  const { isSuccess, data = [], error } = yield apiService.getAllApps();
  if (isSuccess) {
    yield put({
      type: C.ALL_APPS_RECEIVED,
      data: { data, message: "", status: "success" },
    });
  } else {
    yield put({
      type: C.ALL_APPS_RECEIVED,
      data: { data, message: error, status: "error" },
    });
    console.log(error); // need to show error message
  }
}
function* fetch_new_routes(payload = {}) {
  const { isSuccess, data = [], error } = yield apiService.getAllApps();
  if (isSuccess) {
    yield put({
      type: C.ALL_ROUTES_RECEIVED,
      data,
    });
  } else {
    yield put({
      type: C.ALL_ROUTES_RECEIVED,
      data,
    });
    console.log(error); // need to show error message
  }
}

function* fetch_all_ref_apps(payload = {}) {
  const { isSuccess, data = [], error } = yield apiService.getAllApps();
  if (isSuccess) {
    yield put({
      type: C.ALL_REF_APPS_RECEIVED,
      data,
    });
  } else {
    yield put({
      type: C.ALL_REF_APPS_RECEIVED,
      data,
    });
    console.log(error); // need to show error message
  }
}

function* fetch_ref_manage_apps(payload = {}) {
  const {
    isSuccess,
    data = [],
    error,
  } = yield apiService.getManageApps(payload.data);
  if (isSuccess) {
    yield put({
      type: C.REF_MANAGE_APPS_RECEIVED,
      data,
    });
  } else {
    yield put({
      type: C.REF_MANAGE_APPS_RECEIVED,
      data,
    });
    console.log(error); // need to show error message
  }
}

function* fetch_ref_recent_apps(payload = {}) {
  const {
    isSuccess,
    data = [],
    error,
  } = yield apiService.getRecentApps(payload.data);
  if (isSuccess) {
    yield put({
      type: C.REF_RECENT_APPS_RECEIVED,
      data,
    });
  } else {
    yield put({
      type: C.REF_RECENT_APPS_RECEIVED,
      data,
    });
    console.log(error); // need to show error message
  }
}

function* fetch_ref_fav_apps(payload = {}) {
  const { isSuccess, data = [], error } = yield apiService.getFavApps();
  if (isSuccess) {
    yield put({
      type: C.REF_FAV_APPS_RECEIVED,
      data,
    });
  } else {
    yield put({
      type: C.REF_FAV_APPS_RECEIVED,
      data,
    });
    console.log(error); // need to show error message
  }
}

function* fetchManageApps(payload = {}) {
  const {
    isSuccess,
    data = [],
    error,
  } = yield apiService.getManageApps(payload.data);
  if (isSuccess) {
    yield put({
      type: C.MANAGE_APPS_RECEIVED,
      data: { data, message: "", status: "success" },
    });
  } else {
    yield put({
      type: C.MANAGE_APPS_RECEIVED,
      data: { data, message: error, status: "error" },
    });
    console.log(error); // need to show error message
  }
}

function* fetchFavApps(payload = {}) {
  const { isSuccess, data = [], error } = yield apiService.getFavApps();
  if (isSuccess) {
    yield put({
      type: C.FAV_APPS_RECEIVED,
      data: { data, message: "", status: "success" },
    });
  } else {
    yield put({
      type: C.FAV_APPS_RECEIVED,
      data: { data, message: error, status: "error" },
    });
    console.log(error); // need to show error message
  }
}

function* fetchRecentApps(payload = {}) {
  const {
    isSuccess,
    data = [],
    error,
  } = yield apiService.getRecentApps(payload.data);
  if (isSuccess) {
    yield put({
      type: C.RECENT_APPS_RECEIVED,
      data: { data, message: "", status: "success" },
    });
  } else {
    yield put({
      type: C.RECENT_APPS_RECEIVED,
      data: { data, message: error, status: "error" },
    });
    console.log(error); // need to show error message
  }
}

function* fetchCountryBrands(payload = {}) {
  const { isSuccess, data = [], error } = yield apiService.getCountryBrands();
  if (isSuccess) {
    yield put({
      type: C.COUNTRY_BRANDS_RECEIVED,
      data: { data, message: "", status: "success" },
    });
  } else {
    yield put({
      type: C.COUNTRY_BRANDS_RECEIVED,
      data: { data, message: error, status: "error" },
    });
    console.log(error); // need to show error message
  }
}

function* fetchRegisterAppTemplate(payload = {}) {
  const {
    isSuccess,
    data = [],
    error,
  } = yield apiService.getRegisterAppTemplates();
  if (isSuccess) {
    yield put({ type: C.REGISTER_APP_TEMPLATE_RECEIVED, data });
  } else {
    console.log(error); // need to show error message
  }
}

function* fetchUpdateReportViewDetails(payload = {}) {
  const { isSuccess, error, errorCode } =
    yield apiService.updateReportViewDetail(payload.data);
  if (isSuccess) {
    yield refreshComments();
  } else if (errorCode) {
    yield updateUserInfo({ error, errorCode });
  } else {
    yield updateAlertInfo({
      open: true,
      message: error,
      severity: "error",
    });
  }
}

function* fetchAllCategoryList() {
  const { isSuccess, data, error, errorCode } =
    yield apiService.getAllCategorys();
  if (isSuccess) {
    yield put({ type: C.CATEGORY_LIST_RECEIVED, data });
  } else if (errorCode) {
    yield updateUserInfo({ error, errorCode });
  } else {
    yield updateAlertInfo({
      open: true,
      message: error,
      severity: "error",
    });
  }
}

function* fetchAppDetails(payload = {}) {
  const { isSuccess, data, errorMSG } = yield apiService.getAppDetail(
    payload.data
  );
  if (isSuccess) {
    yield put({
      type: C.APP_DETAILS_RECEVIED,
      data: {
        status: "success",
        msg: payload.appType === "admin" ? "admin" : "",
        data,
      },
    });
    // yield put({ type: C.APP_DETAILS_RECEVIED, data });
  } else {
    yield put({
      type: C.APP_DETAILS_RECEVIED,
      data: {
        status: "error",
        msg: "Unable to load App Details",
        data: {},
      },
    });
    toast.error(errorMSG || "App with these details does not exist!");
    // toast.error(errorMSG || "Unable to the load App Details !");
  }
}
function* fetch_views_comments(payload = {}) {
  const { isSuccess, data, errorMSG } = yield apiService.getAppViewsAndComments(
    payload.data
  );
  if (isSuccess) {
    yield put({
      type: C.APP_VIEWS_COMMENTS_RECEIVED,
      data: { status: "success", msg: "", data },
    });
    // yield put({ type: C.APP_VIEWS_COMMENTS_RECEIVED, data });
  } else {
    yield put({
      type: C.APP_VIEWS_COMMENTS_RECEIVED,
      data: {
        status: "error",
        msg: "Unable to load views and comments",
        data: {},
      },
    });
    toast.error(errorMSG || "Oops ! Something went wrong , Please try again !");
  }
}

function* fetch_updated_comments(payload = {}) {
  const { isSuccess, data, error, errorCode } =
    yield apiService.getAppViewsAndComments(payload.data);
  if (isSuccess) {
    yield put({ type: C.UPDATED_COMMENTS_RECEIVED, data });
  } else if (errorCode) {
    yield updateUserInfo({ error, errorCode });
  } else {
    yield updateAlertInfo({
      open: true,
      message: error,
      severity: "error",
    });
  }
}

function* fetch_update_total_views(payload = {}) {
  const { isSuccess, errorMSG } = yield apiService.updateTotalViews(
    payload.data
  );
  if (isSuccess) {
    yield put({ type: C.TOTAL_VIEWS_UPDATED });
  } else {
    toast.error(errorMSG || "Oops ! Something went wrong , Please try again !");
  }
}

function* fetch_users_list(payload = {}) {
  const { isSuccess, errorMSG, data } = yield apiService.getUsersList(
    payload.data
  );
  if (isSuccess) {
    yield put({ type: C.USERS_LIST_RECEIVED, data });
  } else {
    toast.error(errorMSG || "Oops ! Unable to fetch users, Please try again !");
  }
}
function* fetch_usage_report_users(payload = {}) {
  const { isSuccess, errorMSG, data } = yield apiService.getUsageReportUsers(
    payload.data
  );
  if (isSuccess) {
    yield put({ type: C.USAGE_REPORT_USERS_RECEIVED, data });
  } else {
    toast.error(errorMSG || "Oops ! Unable to fetch users, Please try again !");
  }
}
function* fetch_upate_azure_group_members(payload = {}) {
  const { isSuccess, errorMSG, data } =
    yield apiService.update_azure_group_members(payload.data);
  if (isSuccess) {
    yield put({
      type: C.AZURE_GROUP_MEMBERS_UPDATED,
      data: { status: "success", msg: errorMSG, data },
    });
  } else {
    yield put({
      type: C.AZURE_GROUP_MEMBERS_UPDATED,
      data: { status: "error", msg: errorMSG, data: {} },
    });
    toast.error(errorMSG || "Oops ! Unable to add users in Azure group.");
  }
}

function* fetch_unique_backend_repo(payload = {}) {
  const { isSuccess, errorMSG, errorCode } = yield apiService.backendRepoUnique(
    payload.data
  );
  if (isSuccess) {
    yield put({
      type: C.UNIQUE_BACKEND_REPO_RECEIVED,
      data: { status: "success", msg: errorMSG },
    });
  } else if (!errorCode) {
    yield put({
      type: C.UNIQUE_BACKEND_REPO_RECEIVED,
      data: { status: "error", msg: errorMSG },
    });
  } else {
    toast.error(errorMSG || "Oops ! Something went wrong , Please try again !");
  }
}

function* fetch_unique_frontend_repo(payload = {}) {
  const { isSuccess, errorMSG, errorCode } = yield apiService.frontRepoUnique(
    payload.data
  );
  if (isSuccess) {
    yield put({
      type: C.UNIQUE_FRONTEND_REPO_RECEIVED,
      data: { status: "success", msg: errorMSG },
    });
  } else if (!errorCode) {
    yield put({
      type: C.UNIQUE_FRONTEND_REPO_RECEIVED,
      data: { status: "error", msg: errorMSG },
    });
  } else {
    toast.error(errorMSG || "Oops ! Something went wrong , Please try again !");
  }
}
function* fetch_unique_app_url(payload = {}) {
  const { isSuccess, error, errorMSG, errorCode } =
    yield apiService.appUrlUnique(payload.data);
  if (isSuccess) {
    yield put({
      type: C.UNIQUE_APP_URL_RECEIVED,
      data: { status: "success", msg: error },
    });
  } else if (!errorCode) {
    yield put({
      type: C.UNIQUE_APP_URL_RECEIVED,
      data: { status: "error", msg: error },
    });
  } else {
    toast.error(errorMSG || "Oops ! Something went wrong , Please try again !");
  }
}
function* fetch_datasets_tags(payload = {}) {
  const { isSuccess, error, errorMSG, errorCode } =
    yield apiService.datasets_tags_update(payload.data);
  if (isSuccess) {
    yield put({
      type: C.DATASETS_TAGS_UPDATED,
      data: { status: "success", msg: error },
    });
  } else if (!errorCode) {
    yield put({
      type: C.DATASETS_TAGS_UPDATED,
      data: { status: "error", msg: error },
    });
  } else {
    toast.error(
      errorMSG || "Oops ! Unable to add tags and dataset in backend !"
    );
  }
}

function* fetch_layout_apps(payload = {}) {
  const {
    isSuccess,
    data = [],
    error,
  } = yield apiService.getLayoutApps(payload.data);
  if (isSuccess) {
    yield put({
      type: C.LAYOUT_APPS_RECEIVED,
      data: { data, message: "", status: "success" },
    });
    const AllLayoutIds = getAppIds(data);
    yield put({
      type: C.UPDATE_LAYOUT_DATA_LIST,
      data: AllLayoutIds,
    });
  } else {
    yield put({
      type: C.LAYOUT_APPS_RECEIVED,
      data: { data, message: error, status: "error" },
    });
    console.log(error); // need to show error message
  }
}

function* fetch_recommended_apps(payload = {}) {
  const {
    isSuccess,
    data = [],
    error,
  } = yield apiService.getRecommendedApps(payload.data);
  if (isSuccess) {
    yield put({
      type: C.RECOMMENDED_APPS_RECEIVED,
      data: { data, message: "", status: "success" },
    });
  } else {
    yield put({
      type: C.RECOMMENDED_APPS_RECEIVED,
      data: { data, message: error, status: "error" },
    });
    console.log(error); // need to show error message
  }
}

function* fetch_layout_ref_apps(payload = {}) {
  const {
    isSuccess,
    data = [],
    error,
  } = yield apiService.getLayoutApps(payload.data);
  if (isSuccess) {
    yield put({
      type: C.LAYOUT_REF_APPS_RECEIVED,
      data: { data, message: "", status: "success" },
    });
    const AllLayoutIds = getAppIds(data);
    yield put({
      type: C.UPDATE_LAYOUT_DATA_LIST,
      data: AllLayoutIds,
    });
  } else {
    yield put({
      type: C.LAYOUT_REF_APPS_RECEIVED,
      data: { data, message: error, status: "error" },
    });
    console.log(error); // need to show error message
  }
}

function* fetch_unique_app_handle(payload = {}) {
  const { isSuccess, error, errorMSG, errorCode } =
    yield apiService.apphandleUnique(payload.data);
  if (isSuccess) {
    yield put({
      type: C.UNIQUE_APP_HANDLE_RECEIVED,
      data: { status: "success", msg: error },
    });
  } else if (!errorCode) {
    yield put({
      type: C.UNIQUE_APP_HANDLE_RECEIVED,
      data: { status: "error", msg: error },
    });
  } else {
    toast.error(errorMSG || "Oops ! Something went wrong , Please try again !");
  }
}
// function* fetch_publish_app(payload = {}) {
//     const { isSuccess, errorMSG, data } = yield apiService.publishapp(
//         payload.data
//     );
//     if (isSuccess) {
//         yield put({ type: C.PUBLISH_APP_RECEIVED, data });
//     } else {
//         toast.error(
//             errorMSG || "Oops ! Unable to fetch users, Please try again !"
//         );
//     }
// }

function* fetch_user_access_list(payload = {}) {
  const {
    isSuccess,
    data = [],
    errorMSG,
  } = yield apiService.getUserAccessList(payload.data);
  if (isSuccess) {
    yield put({ type: C.USER_ACCESS_LIST_RECEIVED, data });
  } else {
    toast.error(errorMSG || "Oops ! Unable to fetch users, Please try again !");
  }
}
function* fetch_refresh_user_access_list(payload = {}) {
  const {
    isSuccess,
    data = [],
    errorMSG,
  } = yield apiService.getUserAccessList(payload.data);
  if (isSuccess) {
    yield put({ type: C.USER_ACCESS_LIST_REFRESHED, data });
  } else {
    toast.error(errorMSG || "Oops ! Unable to fetch users, Please try again !");
  }
}
function* fetch_signup_templates() {
  const {
    isSuccess,
    data = [],
    errorMSG,
  } = yield apiService.getSignupTemplates();
  if (isSuccess) {
    yield put({ type: C.SIGNUP_TEMPLATES_RECEIVED, data });
  } else {
    toast.error(
      errorMSG || "Oops ! Unable to fetch signup templates, Please try again !"
    );
  }
}
function* fetch_udpated_home_layout(payload = {}) {
  const { isSuccess, error, errorMSG, errorCode } =
    yield apiService.update_home_layout(payload.data);
  if (isSuccess) {
    yield put({
      type: C.HOME_LAYOUT_UPDATED,
      data: { status: "success", msg: error },
    });
    yield put({
      type: C.GET_LAYOUT_REF_APPS,
      data: { user_id: payload.data?.user_id },
    });
  } else if (!errorCode) {
    yield put({
      type: C.HOME_LAYOUT_UPDATED,
      data: { status: "error", msg: error },
    });
  } else {
    toast.error(
      errorMSG || "Oops ! Unable to update layout, Please try again !"
    );
  }
}
function* fetch_udpate_job_role_options(payload = {}) {
  const { isSuccess, error, errorMSG, errorCode } =
    yield apiService.update_job_role_others_options(payload.data);
  if (isSuccess) {
    yield put({
      type: C.JOB_ROLE_OTHER_OPTIONS_UPDATED,
      data: { status: "success", msg: error },
    });
  } else if (!errorCode) {
    yield put({
      type: C.JOB_ROLE_OTHER_OPTIONS_UPDATED,
      data: { status: "error", msg: error },
    });
  } else {
    toast.error(errorMSG || "Oops ! something went wrong please try later!");
  }
}
function* fetch_udpate_functions_options(payload = {}) {
  const { isSuccess, error, errorMSG, errorCode } =
    yield apiService.update_functions_others_options(payload.data);
  if (isSuccess) {
    yield put({
      type: C.FUNCTION_OTHER_OPTIONS_UPDATED,
      data: { status: "success", msg: error },
    });
  } else if (!errorCode) {
    yield put({
      type: C.FUNCTION_OTHER_OPTIONS_UPDATED,
      data: { status: "error", msg: error },
    });
  } else {
    toast.error(errorMSG || "Oops ! something went wrong please try later!");
  }
}
function* fetch_udpated_user(payload = {}) {
  const { isSuccess, errorMSG, data } = yield apiService.update_user_data(
    payload.data
  );
  if (isSuccess) {
    yield put({
      type: C.USER_UPDATED,
      data,
    });
  } else {
    toast.error(errorMSG || "Oops ! something went wrong please try later!");
  }
}

function* fetch_sync_access_to_env(payload = {}) {
  const { isSuccess, errorMSG } = yield apiService.sync_access_data_in_env(
    payload.data
  );
  const { action_for_uat, action_for_dev } = payload.data;
  const notificationData =
    action_for_dev && action_for_uat
      ? "Dev & Uat"
      : action_for_uat
      ? "Uat"
      : "Dev";
  if (isSuccess) {
    toast.success(`Access has been synchronised with ${notificationData}.`);
    yield put({
      type: C.ACCESS_IN_ENV_SYNCED,
      data: { status: "success" },
    });
  } else {
    toast.error(
      errorMSG || "Unable to synchronise access list. Please try later!"
    );
    yield put({
      type: C.ACCESS_IN_ENV_SYNCED,
      data: { status: "error" },
    });
  }
}
function* fetch_help_center_templates(payload = {}) {
  const { isSuccess, errorMSG, data, error } =
    yield apiService.fetchHelpCenterTemplates(payload.data);
  if (isSuccess) {
    yield put({
      type: C.HELP_CENTER_TEMPLATES_RECEIVED,
      data: {
        status: "success",
        data: data?.help_center_template || {},
      },
    });
  } else {
    yield put({
      type: C.HELP_CENTER_TEMPLATES_RECEIVED,
      data: { status: "error", message: error || "", data: {} },
    });
    toast.error(errorMSG || "Oops ! something went wrong please try later!");
  }
}
function* fetch_all_app_list() {
  const { isSuccess, errorMSG, data, error } =
    yield apiService.fetchAllAppList();
  if (isSuccess) {
    yield put({
      type: C.APP_LIST_RECEIVED,
      data: {
        status: "success",
        data: data || [],
      },
    });
  } else {
    yield put({
      type: C.APP_LIST_RECEIVED,
      data: { status: "error", message: error || "", data: [] },
    });
    toast.error(errorMSG || "Oops ! something went wrong please try later!");
  }
}
function* send_mail_to_owners(payloadInfo = {}) {
  const { req_category } = payloadInfo.data;
  const url =
    req_category === "raise_incident"
      ? config.api.incidentmailUrl
      : req_category === "request_feature"
      ? config.api.featuremailUrl
      : config.api.feedbackmailUrl;
  const { isSuccess, errorMSG, data, error } =
    yield apiService.sendMailToOwners(payloadInfo.data, url);
  if (isSuccess) {
    yield put({
      type: C.MAIL_TO_APP_OWNER_SENT,
      data: {
        status: "success",
        data: data || [],
      },
    });
  } else {
    yield put({
      type: C.MAIL_TO_APP_OWNER_SENT,
      data: {
        status: "error",
        message: error || "Opps! somthing went wrong.",
        data: [],
      },
    });
    toast.error(errorMSG || "Oops ! something went wrong please try later!");
  }
}
function* fetch_save_ticket_details(payloadInfo = {}) {
  const { isSuccess, errorMSG, data, error } =
    yield apiService.save_ticket_datials(payloadInfo.data);
  if (isSuccess) {
    yield put({
      type: C.TICKET_ID_SAVED,
      data: {
        status: "success",
        data: data || [],
      },
    });
  } else {
    yield put({
      type: C.TICKET_ID_SAVED,
      data: {
        status: "error",
        message: error || "Opps! somthing went wrong.",
        data: [],
      },
    });
    toast.error(errorMSG || "Oops ! something went wrong please try later!");
  }
}

function* fetch_save_position_meta(payloadInfo = {}) {
  const { isSuccess, errorMSG } = yield apiService.save_position_meta(
    payloadInfo.data
  );
  if (isSuccess) {
    yield put({
      type: C.POSITION_META_UPDATED,
      data: { status: "success" },
    });
  } else {
    yield put({
      type: C.POSITION_META_UPDATED,
      data: { status: "error" },
    });
    toast.error(errorMSG || "Oops ! something went wrong please try later!");
  }
}
function* fetch_app_data(payload = {}) {
  const { isSuccess, data, errorMSG } = yield apiService.getAppDetail(
    payload.data
  );
  if (isSuccess) {
    yield put({
      type: C.APP_DASHBOARD_DETAILS_RECEIVED,
      data: {
        status: "success",
        msg: "",
        data,
      },
    });
  } else {
    yield put({
      type: C.APP_DASHBOARD_DETAILS_RECEIVED,
      data: {
        status: "error",
        msg: "Unable to load App Details",
        data: {},
      },
    });
    toast.error(errorMSG || "Oops! something went wrong. Please try later.");
  }
}
function* fetch_users_filters(payload = {}) {
  const { isSuccess, data, errorMSG } = yield apiService.get_users_filters_data(
    payload.data
  );
  if (isSuccess) {
    yield put({
      type: C.USER_FILTERS_RECEIVED,
      data: {
        status: "success",
        msg: "",
        data,
      },
    });
  } else {
    yield put({
      type: C.USER_FILTERS_RECEIVED,
      data: {
        status: "error",
        msg: "Unable to load App Details",
        data: {},
      },
    });
    toast.error(errorMSG || "Oops! something went wrong. Please try later.");
  }
}

// shashank's change
function* fetch_top_users_app(payload = {}) {
  const { isSuccess, data, errorMSG } = yield apiService.getTopUserApp(
    payload.data
  );
  const id = payload.data.search_columns[0].values[0];
  if (isSuccess) {
    yield put({
      type: C.TOP_USERS_RECEIVED,
      data: {
        status: "success",
        message: "",
        data: {
          [id]: {
            status: "success",
            message: "",
            data: data[1],
          },
        },
      },
    });
  } else {
    yield put({
      type: C.TOP_USERS_RECEIVED,
      data: {
        status: "error",
        message: "Unable to load App Details",
        data: {
          [id]: {
            status: "error",
            message: "Unable to load App Details",
            data: {},
          },
        },
      },
    });
    toast.error(
      errorMSG ||
        "Oops! something went wrong in loading Top Users. Please try later."
    );
  }
}

function* fetch_app_defined_category(payload = {}) {
  const { isSuccess, data } = yield apiService.getAppDetail(payload.data);
  const app_id = payload.data.app_id;
  if (isSuccess) {
    yield put({
      type: C.DEFINED_CATEGORY_RECEIVED,
      data: {
        status: "success",
        message: "",
        data: {
          [app_id]: {
            status: "success",
            message: "",
            data: data,
          },
        },
      },
    });
  } else {
    yield put({
      type: C.DEFINED_CATEGORY_RECEIVED,
      data: {
        status: "error",
        message: "Unable to load App Details",
        data: {
          [app_id]: {
            status: "error",
            message: "Unable to load App Details",
            data: {},
          },
        },
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(C.GET_ALL_APPS, fetchAllApps),
    takeLatest(C.GET_MANAGE_APPS, fetchManageApps),
    takeLatest(C.GET_FAV_APPS, fetchFavApps),
    takeLatest(C.GET_RECENT_APPS, fetchRecentApps),
    takeLatest(C.GET_REGISTER_APP_TEMPLATE, fetchRegisterAppTemplate),
    takeLatest(C.GET_UPDATE_VIEW_REPORT_DETAILS, fetchUpdateReportViewDetails),
    takeLatest(C.GET_ALL_CATEGORY_LIST, fetchAllCategoryList),
    takeLatest(C.GET_APP_DETAILS, fetchAppDetails),
    takeLatest(C.UPDATE_APP_DETAILS, fetchAppDetails),
    takeLatest(C.GET_APP_VIEWS_COMMENTS, fetch_views_comments),
    takeLatest(C.GET_UPDATED_COMMENTS, fetch_updated_comments),
    takeLatest(C.UPDATE_TOTAL_VIEWS, fetch_update_total_views),
    takeLatest(C.GET_NEW_ROUTE, fetch_new_routes),
    takeLatest(C.GET_ALL_REF_APPS, fetch_all_ref_apps),
    takeLatest(C.GET_REF_RECENT_APPS, fetch_ref_recent_apps),
    takeLatest(C.GET_REF_MANAGE_APPS, fetch_ref_manage_apps),
    takeLatest(C.GET_REF_FAV_APPS, fetch_ref_fav_apps),
    takeLatest(C.GET_USERS_LIST, fetch_users_list),
    takeLatest(C.GET_COUNTRY_BRANDS, fetchCountryBrands),
    takeLatest(C.GET_UNIQUE_BACKEND_REPO, fetch_unique_backend_repo),
    takeLatest(C.GET_UNIQUE_FRONTEND_REPO, fetch_unique_frontend_repo),
    takeLatest(C.GET_LAYOUT_APPS, fetch_layout_apps),
    takeLatest(C.GET_LAYOUT_REF_APPS, fetch_layout_ref_apps),
    takeLatest(C.GET_RECOMMENDED_APPS, fetch_recommended_apps),
    takeLatest(C.GET_USER_FILTERS, fetch_users_filters),
    takeLatest(C.UPDATE_USER_FILTERS, fetch_users_filters),

    takeLatest(C.VALIDATE_UNIQUE_APP_URL, fetch_unique_app_url),
    takeLatest(C.UPDATE_DATASETS_TAGS, fetch_datasets_tags),
    takeLatest(C.GET_UNIQUE_APP_HANDLE, fetch_unique_app_handle),
    takeLatest(C.GET_USAGE_REPORT_USERS, fetch_usage_report_users),
    takeLatest(C.UPDATE_AZURE_GROUP_MEMBERS, fetch_upate_azure_group_members),

    // UserAccessManagement
    takeLatest(C.GET_USER_ACCESS_LIST, fetch_user_access_list),
    takeLatest(C.REFRESH_USER_ACCESS_LIST, fetch_refresh_user_access_list),

    //signup screen
    takeLatest(C.GET_SIGNUP_TEMPLATES, fetch_signup_templates),
    takeLatest(C.UPDATE_JOB_ROLE_OTHER_OPTIONS, fetch_udpate_job_role_options),
    takeLatest(C.UPDATE_FUNCTION_OTHER_OPTIONS, fetch_udpate_functions_options),
    takeLatest(C.UPDATE_USER, fetch_udpated_user),
    takeLatest(C.UPDATE_HOME_LAYOUT, fetch_udpated_home_layout),
    takeLatest(C.SYNC_ACCESS_IN_ENV, fetch_sync_access_to_env),
    takeLatest(C.GET_HELP_CENTER_TEMPLATES, fetch_help_center_templates),
    takeLatest(C.GET_APP_LIST, fetch_all_app_list),
    takeLatest(C.SEND_MAIL_TO_APP_OWNER, send_mail_to_owners),
    takeLatest(C.SAVE_TICKET_ID, fetch_save_ticket_details),

    // app dashboard
    takeLatest(C.UPDATE_POSITION_META, fetch_save_position_meta),

    // shashank's change
    takeLatest(C.GET_TOP_USERS_APP, fetch_top_users_app),
    takeLatest(C.GET_APP_DASHBOARD_DETAILS, fetch_app_data),
    takeLatest(C.GET_DEFINED_CATEGORY, fetch_app_defined_category),
  ]);
}
