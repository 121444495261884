const user = {
  isAuth: false,
  theme: "light",
  name: "",
  preferred_username: "",
  token: "",
  error: null,
  errorCode: null,
  selections: {
    country: "ALL",
    segment: "ALL",
    brand: "ALL",
    searchText: "",
    tableOrder: "desc",
    tableOrderBy: "modified_on",
    runView: "data",
  },
  alertInfo: {
    message: "",
    open: false,
    severity: "success",
  },
  dialogInfo: {
    message: "",
    open: false,
    handleYes: () => {},
    handleNo: () => {},
  },
  screen: {
    isSidenavBarExpanded: true,
  },
};

const data = {
  country: null,
  brand: null,
  archetype: null,
  activeAssetCollection: { id: "", name: "" },
  categoryList: { status: "pending", message: "", data: [] },
};

const changeFlag = {
  refreshComments: false, //fetch comments once user hits send button
  currentTab: "",
};

const register = {
  registerTemplate: { status: "pending", message: "", data: [] },
  backend_repo_uniqueness: { status: "pending", message: "" },
  frontend_repo_uniqueness: { status: "pending", message: "" },
  app_url_uniqueness: { status: "pending", message: "" },
  update_dataset_tags: { status: "pending", message: "" },
  unique_app_handle: { status: "pending", message: "" },
  usage_report_users: { status: "pending", message: "", data: {} },
  register_draft_app: { status: "pending", message: "" },
  ml_app_url_status: { status: "pending", message: "", unique: false },
  sync_access_with_env: { status: "pending", message: "" },
  azure_group_info: { status: "pending", message: "", data: {} },
  azure_group_members: { status: "pending", message: "", data: {} },
  powerBiWorkspace: { status: "pending", message: "", data: [] },
  powerBiReports: { status: "pending", message: "", data: {} },
  dublicate_workspace_list: { status: "pending", message: "", data: {} },
};

const AppsData = {
  allApps: { status: "pending", message: "", data: [] },
  layoutApps: { status: "pending", message: "", data: [] },
  recommendedApps: { status: "pending", message: "", data: [] },
  refreshedAllApps: { status: "pending", message: "", data: [] },
  refreshedLayoutApps: { status: "pending", message: "", data: [] },
  allRoutes: { status: "pending", message: "", data: [] },
  favApps: { status: "pending", message: "", data: [] },
  refreshedFavApps: { status: "pending", message: "", data: [] },
  recentApps: { status: "pending", message: "", data: [] },
  refreshedRecentApps: { status: "pending", message: "", data: [] },
  manageApps: { status: "pending", message: "", data: [] },
  refreshedManageApps: { status: "pending", message: "", data: [] },
  suggestedApps: { status: "pending", message: "", data: [] },
  refreshedSuggestedApps: { status: "pending", message: "", data: [] },
  appDetails: { status: "pending", message: "", data: {} },
  viewsNComments: { status: "pending", message: "", data: {} },
  updatedViewsNComments: { status: "pending", message: "", data: {} },
  updateViews: { status: "pending", message: "" },
  usersList: { status: "pending", message: "", data: [] },
  countryBrands: { status: "pending", message: "", data: [] },
  layoutStatus: { status: "pending", message: "" },
  layoutDataList: { status: "", message: "", data: [] },
  user_filters: { status: "pending", message: "", data: {} },
  topUsersApp: {},
  defined_categories: {},
  explore_apps: {},
};
const userAccessManagement = {
  user_access_list: { status: "pending", message: "", data: [] },
  refreshed_user_access_list: { status: "pending", message: "", data: [] },
};
const signup = {
  signup_templates: { status: "pending", message: "", data: {} },
};

const helpCenter = {
  helpCenterTemplate: { status: "pending", message: "", data: {} },
  helpCenterState: {
    appList: [],
    formData: {},
  },
  allAppList: { status: "pending", message: "", data: [] },
  sendMailToOnwer: { status: "pending", message: "" },
  saveTicketMeta: { status: "pending", message: "" },
};
const appDashboard = {
  appDashboardState: {
    appData: {},
    filterOptions: {
      tags: [],
      associated_datasets: [],
      geography: [],
      function: [],
      line_of_business: [],
    },
  },
};

const appTemplate = {
  appTemplateState: { templateData: {} },
};

const registerNewApp = {
  BIappDetails: {
    categoryInfo: [],
    categoryInfoLocal: [],
    selectedWorkspace: "",
    prevWorkspace: "",
    selectedTemplate: null,
  },

  MLappDetails: {
    configDetails: {},
    trackStatusState: [],
  },

  accessManagement: {
    accessList: [],
    accessListClone: [],
    addAccessList: [],
  },
  appData: {
    appFields: { app_details: {} },
    dataList: {},
    visited: {},
    isChanged: {
      app_meta: {},
      app_details: {},
      provisioning_details: {},
    },
    validationMsg: "",
    access_type: "public",
    appInfoID: "",
    savedTemplateData: {},
  },

  statusData: {
    waiting: false,
    isAppUnique: false,
    openSubmitError: false,
    open: false,
    isChangesSubmitable: false,
    isDetailsSubmitable: false,
    isAdminRegistering: false,
    draftApp: false,
    dirty: false,
    editLayout: true,
    showDetailsDrawer: false,
    navToExplore: false,
    isPreviewDisable: false,
    discardChanges: false,
    editPreviewToggleReport: true,
    showScreenshot: true,
    takeScreenshot: false,
    waitingForScreenshot: false,
    isStepperHidden: false,
    localstateSetFirstTime: false,
  },
  activeStepper: 0,
};

const handleBiEmbedToken = {
  token: "",
  expTimestamp: 0,
};
export const stateData = {
  user,
  changeFlag,
  data,
  register,
  AppsData,
  userAccessManagement,
  signup,
  helpCenter,
  appDashboard,
  appTemplate,
  registerNewApp,
  handleBiEmbedToken,
};
