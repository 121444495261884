import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";
import React, { useState } from "react";
import CreateRegisterBodyContainer from "../Components/CreateRegisterBodyContainer";
import CreateFooterContainer from "../Components/CreateFooterContainer";
import { isEqualObject } from "../../../utils/utils";
import Loader from "../../../components/Loader/Loader";
import { getTemplateStringInNumber } from "../../../screens/Register/components/DashboardTemplate/utils";
import { toast } from "react-toastify";
import {
  getAppDetails,
  updateAppDetails,
  updateDraftstatus,
  updateReportTemplate,
  updateTagsDatasets,
  update_BIappDetails,
  update_appData,
  update_statusData,
  update_activeStepper,
} from "../../../redux/actions";
import {
  sendmailRequest,
  sendmailToOpsRequest,
  update_draft_apps,
} from "../../../apiServices";

import moment from "moment";
import TemplateImg_1 from "../../../../assets/images/new-theme/register/light-theme/template_1.svg";
import TemplateImg_2 from "../../../../assets/images/new-theme/register/light-theme/template_2.svg";
import TemplateImg_3 from "../../../../assets/images/new-theme/register/light-theme/template_3.svg";
import TemplateImg_4 from "../../../../assets/images/new-theme/register/light-theme/template_4.svg";
import TemplateImg_5 from "../../../../assets/images/register-images/Template_5.svg";
import TemplateImg_6 from "../../../../assets/images/new-theme/register/light-theme/template_6.svg";
import TemplateImg_7 from "../../../../assets/images/new-theme/register/light-theme/template_7.svg";
import TemplateImg_8 from "../../../../assets/images/new-theme/register/light-theme/template_8.svg";
import TemplateImg_9 from "../../../../assets/images/new-theme/register/light-theme/template_9.svg";

import TemplateImg_1_dark from "../../../../assets/images/new-theme/register/dark-theme/template_1.svg";
import TemplateImg_2_dark from "../../../../assets/images/new-theme/register/dark-theme/template_2.svg";
import TemplateImg_3_dark from "../../../../assets/images/new-theme/register/dark-theme/template_3.svg";
import TemplateImg_4_dark from "../../../../assets/images/new-theme/register/dark-theme/template_4.svg";
import TemplateImg_5_dark from "../../../../assets/images/register-images/Template_5_dark.svg";
import TemplateImg_6_dark from "../../../../assets/images/new-theme/register/dark-theme/template_6.svg";
import TemplateImg_7_dark from "../../../../assets/images/new-theme/register/dark-theme/template_7.svg";
import TemplateImg_8_dark from "../../../../assets/images/new-theme/register/dark-theme/template_8.svg";
import TemplateImg_9_dark from "../../../../assets/images/new-theme/register/dark-theme/template_9.svg";

// buttonStatus={!showSubCatPage && activeStepper === 3}

function fetchAPIRegister(payload, app_id) {
  const url = "/api/appregister";
  return fetch(url, {
    method: app_id ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}
const templatesImages = {
  1: TemplateImg_1,
  2: TemplateImg_2,
  3: TemplateImg_3,
  4: TemplateImg_4,
  5: TemplateImg_5,
  6: TemplateImg_6,
  7: TemplateImg_7,
  8: TemplateImg_8,
  9: TemplateImg_9,
};
const templatesImagesDark = {
  1: TemplateImg_1_dark,
  2: TemplateImg_2_dark,
  3: TemplateImg_3_dark,
  4: TemplateImg_4_dark,
  5: TemplateImg_5_dark,
  6: TemplateImg_6_dark,
  7: TemplateImg_7_dark,
  8: TemplateImg_8_dark,
  9: TemplateImg_9_dark,
};

const ScreenshotPage = (props) => {
  let {
    statusData,
    theme,
    visited,
    user,
    registerNewApp,
    isAdminRegistering,
    appFields,
    appDetails,
    template_no,
    addAccessList,
    appTemplateState,
    selectedWorkspace,
    setOpen = () => {},
    setAlertMsg = () => {},
    setDraftApp = () => {},
    isDetailsSubmitable,
    templateData,
    categoryInfoLocal,
    prev_template_no,
    categoryInfo,
  } = props;

  const dispatch = useDispatch();
  const { showSubCatPage, temp_img_data } = appTemplateState;
  const { appInfo, draftId, tab } = useParams();
  let app_id = null;
  let draft_id = null;
  let approval_status = null;
  if (appInfo) {
    const data = appInfo.split("_");
    app_id = data[0];
    approval_status = data[1];
  }
  if (draftId) {
    const data = draftId.split("_");
    draft_id = data[0];
    approval_status = data[1];
  }

  const [curbtn, setCurbtn] = useState("");

  const isDraftButtonHidden = () => {
    return !(
      approval_status == "pending" ||
      approval_status == "rejected" ||
      approval_status == "approved"
    );
  };

  const isChangeTemplateHidden = () => {
    return (
      // showScreenshot &&
      // buttonStatus &&
      // template_no &&
      !(
        approval_status == "pending" ||
        approval_status == "rejected" ||
        approval_status == "approved"
      )
    );
  };

  const isSaveAndExitHidden = () => {
    return (
      // showScreenshot &&
      // buttonStatus &&
      // template_no &&
      !(approval_status == "pending")
    );
  };

  const isDraftButtonDisabled = () => {
    if (template_no > 5) {
      if (
        // registerNewApp.statusData.showDetailsDrawer ||
        (draftId &&
          !isDetailsSubmitable &&
          isEqualObject(categoryInfoLocal, categoryInfo) &&
          prev_template_no === template_no) ||
        curbtn !== ""
      ) {
        return true;
      }
    } else if (template_no <= 5) {
      if (
        // registerNewApp.statusData.showDetailsDrawer ||
        (draftId &&
          !isDetailsSubmitable &&
          isEqualObject(categoryInfoLocal, templateData) &&
          prev_template_no === template_no) ||
        curbtn !== ""
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const getAppRegisteringData = (draft) => {
    const defaultAccess = {
      user_id: user.preferred_username,
      name: user.name,
      role: "owner",
      access_granted_by: user.preferred_username,
      access_granted_on: moment().valueOf(),
    };
    let access_details = {};
    if (draftId) {
      access_details = {
        ...appDetails.data?.access_details,
        users_list: [
          ...appDetails.data?.access_details?.users_list,
          ...addAccessList,
        ],
      };
    } else {
      if (appFields.app_type === "ml_app") {
        access_details = {
          users_list: [
            {
              ...defaultAccess,
              security_filters: [
                {
                  country: "All",
                  brands_list: ["All"],
                },
              ],
            },
            ...addAccessList,
          ],
          access_type: "public",
        };
      } else {
        access_details = {
          users_list: [defaultAccess, ...addAccessList],
          access_type: "public",
        };
      }
    }
    const approval_meta = {
      updated_on: moment().valueOf(),
      updated_by: user.role !== "user" ? user.preferred_username : "",
      approval_status: draft
        ? "draft"
        : (user.role === "admin" || user.role === "super_admin") &&
          appFields.app_type !== "ml_app"
        ? "approved"
        : "pending",
    };

    let app_meta = {
      app_name: appFields.app_name,
      app_path: "",
      app_location_type: "",
      app_type: appFields.app_type,
      app_logo_type: appFields.app_logo_type || "google_font",
      app_logo: appFields.app_logo,
      blob_name: appFields.blob_name || "",
      created_on: moment().valueOf(),
      created_by: user.preferred_username,
      activation_status: "active",
      total_views: 0,
    };
    let app_details = {
      ...appFields.app_details,
    };
    return { app_meta, approval_meta, access_details, app_details };
  };

  const handleDraftSubmit = async () => {
    dispatch(updateDraftstatus("loading"));
    let data = {};
    if (appFields.app_type === "bi_app") {
      let { app_meta, approval_meta, access_details, app_details } =
        getAppRegisteringData("draft");
      app_details = {
        ...app_details,
        // defined_categories: registerNewApp.BIappDetails.categoryInfoLocal,
        defined_categories:
          getTemplateStringInNumber(template_no) > 5
            ? registerNewApp.BIappDetails.categoryInfoLocal
            : appTemplateState.templateData,
        chosen_template: getTemplateStringInNumber(template_no),
        workspace_id: selectedWorkspace,
        temp_img_data: temp_img_data || {},
      };
      data = { app_meta, approval_meta, access_details, app_details };
    } else return;
    if (draftId) {
      data = { ...data, app_id: draft_id };
    }
    const { isSuccess, errorMSG, statusMsg } = await update_draft_apps(
      data,
      draftId ? "PUT" : "POST"
    );
    if (isSuccess) {
      // console.log("statusMsg-head", statusMsg);
      setDraftApp(true);
      setOpen(true);
      setAlertMsg(statusMsg);
      dispatch(updateDraftstatus("success"));
      dispatch(update_statusData({ dirty: false }));
    } else {
      toast.error(
        errorMSG || "Oops ! unable to save changes, please try later!"
      );
    }
  };

  const handleDraft = () => {
    setCurbtn("draft");
    handleDraftSubmit();
  };

  const handleCancel = () => {
    dispatch(
      updateReportTemplate({
        templateData: [],
        template_no: null,
        temp_img_data: {},
      })
    );
    dispatch(
      update_BIappDetails({
        categoryInfoLocal: [],
        categoryInfo: [],
        selectedWorkspace: "",
      })
    );
    dispatch(
      update_appData({
        visited: { ...visited, category: false },
      })
    );
  };

  const getBiRegisteringData = (draft = "") => {
    let { app_meta, approval_meta, access_details, app_details } =
      getAppRegisteringData(draft);
    app_details = {
      ...app_details,
      defined_categories:
        getTemplateStringInNumber(template_no) > 5
          ? registerNewApp.BIappDetails.categoryInfoLocal
          : appTemplateState.templateData,
      chosen_template: getTemplateStringInNumber(template_no),
      temp_img_data: temp_img_data || {},
      workspace_id: selectedWorkspace,
    };
    return { app_meta, approval_meta, access_details, app_details };
  };

  const isSaveAndExitDisabled = () => {
    const template_data_local =
      getTemplateStringInNumber(template_no) > 5
        ? registerNewApp.BIappDetails.categoryInfoLocal
        : appTemplateState.templateData;
    if (template_data_local.length === 0) {
      return true;
    } else return false;
  };

  const handleSaveTagsDatasets = () => {
    const data = {
      associated_datasets: appFields.app_details?.associated_datasets || [],
      tags: appFields.app_details?.tags || [],
    };
    dispatch(updateTagsDatasets(data));
  };

  const handleSubmit = async () => {
    setCurbtn("save");
    dispatch(update_statusData({ waiting: true }));
    let data = {};
    if (app_id || isAdminRegistering) {
      data = {
        access_details: {
          ...appDetails.data.access_details,
          users_list: [
            ...appDetails.data.access_details?.users_list,
            ...addAccessList,
          ],
        },
        approval_meta: appDetails.data.approval_meta,
        app_id: app_id || appDetails.data.app_id,
      };
      let app_meta = {
        ...appDetails.data.app_meta,
        app_name: appFields.app_name,
        app_logo: appFields.app_logo,
        app_logo_type: appFields.app_logo_type || "google_font",
        blob_name: appFields.blob_name || "",
        total_views: appFields.total_views,
      };
      let app_details = {
        ...appDetails.data.appFields,
        ...appFields.app_details,
      };
      if (appFields.app_type === "bi_app") {
        app_details = {
          ...app_details,
          chosen_template: getTemplateStringInNumber(template_no),
          // defined_categories: appTemplateState.templateData,
          // defined_categories: registerNewApp.BIappDetails.categoryInfoLocal,
          defined_categories:
            getTemplateStringInNumber(template_no) > 5
              ? registerNewApp.BIappDetails.categoryInfoLocal
              : appTemplateState.templateData,
          temp_img_data,
        };
      }
      data = { ...data, app_meta, app_details };
    } else {
      const bi_data =
        getTemplateStringInNumber(template_no) > 5
          ? registerNewApp.BIappDetails.categoryInfoLocal
          : appTemplateState.templateData;
      if (appFields.app_type === "bi_app") {
        data = getBiRegisteringData("", bi_data);
      } else return;
      if (draftId) {
        data = { ...data, app_id: draft_id };
      }
    }
    try {
      const res = await fetchAPIRegister(data, app_id || isAdminRegistering);
      const result = await res.json();
      if (
        result.status?.status === "datareckitt-200" ||
        result.status?.statusCode === "datareckitt-200"
      ) {
        if (
          (user.role === "admin" ||
            user.role === "super_admin" ||
            appFields.app_type === "ml_app") &&
          !app_id & !draftId
        ) {
          const reqInfo = {
            type: "app_id",
            app_id: result.status.app_id || appDetails.data.app_id,
            approval_status:
              appFields.app_type === "ml_app" ? "pending" : "approved",
          };
          if (isAdminRegistering) {
            dispatch(updateAppDetails(reqInfo, "admin"));
          } else {
            dispatch(getAppDetails(reqInfo, "admin"));
          }
        }
        // used to update new tags and datasets in backend
        handleSaveTagsDatasets();
        // used to send mail
        if (appFields.app_type === "ml_app") {
          sendmailToOpsRequest({
            app_id: result.status.app_id || appDetails.data.app_id,
            approval_status: "pending",
            operation: appInfo || isAdminRegistering ? "update" : "register",
          });
        } else {
          if (user.role === "user") {
            if (app_id) {
              sendmailRequest({
                app_id,
                operation: "updation_request",
              });
            } else {
              sendmailRequest({
                app_id: result.status.app_id,
                operation: "register_request",
              });
            }
          }
        }
      }

      dispatch(update_statusData({ waiting: false }));
      dispatch(update_statusData({ dirty: false }));

      if (result.status?.app_id !== undefined) {
        dispatch(update_appData({ appInfoID: result.status?.app_id }));
        setOpen(true);
        setAlertMsg(result.status?.statusMessage);
      } else {
        setAlertMsg(
          "Congratulations! Your application is successfully Updated!"
        );
        setOpen(true);
      }

      dispatch(update_statusData({ editLayout: true }));
      // -----------------------------------------------
    } catch (error) {
      console.log("error form api", error);
      toast.error("Oops Something went wrong. Please try later");
      dispatch(update_statusData({ waiting: false }));
      dispatch(update_statusData({ dirty: false }));
      setOpen(true);
      setAlertMsg("Oops Something went wrong. Please try later");
    }
    setCurbtn("");
  };

  return (
    <>
      <CreateRegisterBodyContainer>
        {/* This is a Screenshot Area */}
        {/* <div
          style={{
            height: "450px",
            width: "90%",
            border: "1px solid #ccc",
          }}
        > */}
        <img
          // style={{ height: "400px", position: "absolute", top: "210px" }}
          style={{
            height: "400px",
            minWidth: "400px",
            border: "1px solid #ccc",
          }}
          src={
            temp_img_data?.url
              ? temp_img_data?.url
              : theme === "light"
              ? templatesImages[template_no]
              : templatesImagesDark[template_no]
          }
          alt=""
        />
        {/* </div> */}
      </CreateRegisterBodyContainer>
      <CreateFooterContainer>
        {/* Button required: Back, Save as Draft, Edit/Prev Mode, Submit, Change Template */}

        {isDraftButtonHidden() && (
          <button
            style={{ width: "95px", position: "absolute", left: "0px" }}
            onClick={handleDraft}
            disabled={isDraftButtonDisabled()}
            className={`draft-button-header ${
              isDraftButtonDisabled() ? "btn-disabled" : ""
            }`}
          >
            {curbtn === "draft" ? (
              <Loader color="#FFF" />
            ) : draftId !== undefined && draftId.includes("active_draft") ? (
              "Update draft"
            ) : (
              "Save as draft"
            )}
          </button>
        )}
        {isChangeTemplateHidden() && (
          <button
            style={{
              width: "95px",
              position: "absolute",
              left: "105px",
              background: "transparent",
            }}
            className={theme === "light" ? "btn-back-light" : "btn-back-dark"}
            onClick={() => {
              dispatch(update_activeStepper(2));
            }}
          >
            Back
          </button>
        )}

        {isChangeTemplateHidden() && (
          <button
            className="btn btn-next"
            onClick={handleCancel}
            // disabled={isDiscardButtonDisabled()}
          >
            Change Template
          </button>
        )}

        <button
          className="btn btn-next"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            dispatch(
              update_statusData({
                showScreenshot: false,
                isStepperHidden: true,
              })
            );
          }}
        >
          Edit/Preview
        </button>

        {isSaveAndExitHidden() && (
          <button
            style={{ marginLeft: "10px" }}
            onClick={handleSubmit}
            disabled={
              isSaveAndExitDisabled() ||
              statusData.waiting ||
              // registerNewApp.statusData.showDetailsDrawer ||
              curbtn !== "" ||
              ((isAdminRegistering || appInfo) &&
                !isDetailsSubmitable &&
                isEqualObject(categoryInfoLocal, templateData))
            }
            className={`btn btn-next ${
              isSaveAndExitDisabled() ||
              // registerNewApp.statusData.showDetailsDrawer ||
              curbtn !== "" ||
              ((isAdminRegistering || appInfo) &&
                !isDetailsSubmitable &&
                isEqualObject(categoryInfoLocal, templateData))
                ? "btn-disabled"
                : ""
            }`}
          >
            {curbtn === "save" ? <Loader color="#FFF" /> : "Submit"}
          </button>
        )}
      </CreateFooterContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  showScreenshot: state.registerNewApp.statusData.showScreenshot,
  activeStepper: state.registerNewApp.activeStepper,
  theme: state.user.ui_preferences?.theme,
  appTemplateState: state.appTemplate.appTemplateState,
  user: state.user,
  templates: state.register.registerTemplate,
  registerNewApp: state.registerNewApp,
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  appFields: state.registerNewApp.appData.appFields,
  appDetails: state.AppsData.appDetails,
  template_no: state.appTemplate.appTemplateState.template_no,
  addAccessList: state.registerNewApp.accessManagement.addAccessList,
  selectedWorkspace: state.registerNewApp.BIappDetails.selectedWorkspace,
  statusData: state.registerNewApp.statusData,
  waiting: state.registerNewApp.statusData.waiting,
  isDetailsSubmitable: state.registerNewApp.statusData.isDetailsSubmitable,
  templateData: state.appTemplate.appTemplateState.templateData,
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  prev_template_no:
    state.registerNewApp?.appData?.appFields?.app_details?.chosen_template,
  visited: state.registerNewApp.appData.visited,
});

export default connect(mapStateToProps)(ScreenshotPage);
