import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  FormControlLabel,
  Grid,
  Checkbox,
  FormGroup,
  FormControl,
  FormLabel,
  Box,
} from "@mui/material";
import "../index.scss";
import { BootstrapInput } from "../style";
import { config } from "../../../config/config";

const getList = (k) => {
  if (k === "Production Environment") {
    return [
      {
        label: "Url",
        name: "url",
        prefix: "https://",
        suffix: `-prd${config.hardCoded.appUrl}`,
        // suffix: "-prod.data.reckitt.com",
      },
      {
        name: "frontend_pipeline",
        label: "Frontend Pipeline",
        prefix: "GCP_PROD_",
        suffix: "_frontend_CI_CD",
      },
      {
        name: "backend_pipeline",
        label: "Backend Pipeline",
        prefix: "GCP_PROD_",
        suffix: "_backend_CI_CD",
      },
    ];
  } else if (k === "UAT Environment") {
    return [
      {
        label: "Url",
        name: "url",
        prefix: "https://",
        suffix: `-uat${config.hardCoded.appUrl}`,
        // suffix: "-uat.data.reckitt.com",
      },
      {
        name: "frontend_pipeline",
        label: "Frontend Pipeline",
        prefix: "GCP_UAT_",
        suffix: "_frontend_CI_CD",
      },
      {
        name: "backend_pipeline",
        label: "Backend Pipeline",
        prefix: "GCP_UAT_",
        suffix: "_backend_CI_CD",
      },
    ];
  }
  return [
    {
      label: "Url",
      name: "url",
      prefix: "https://",
      suffix: `-dev${config.hardCoded.appUrl}`,
      // suffix: "-dev.data.reckitt.com",
    },
    {
      name: "frontend_pipeline",
      label: "Frontend Pipeline",
      prefix: "GCP_DEV_",
      suffix: "_frontend_CI_CD",
    },
    {
      name: "backend_pipeline",
      label: "Backend Pipeline",
      prefix: "GCP_DEV_",
      suffix: "_backend_CI_CD",
    },
  ];
};

const CopyToClipBoad = ({ text }) => {
  const [show, setShow] = useState(false);
  const removeItem = () => {
    setTimeout(() => setShow(false), 1000);
  };
  const copyText = () => {
    navigator.clipboard.writeText(text);
    setShow(true);
    removeItem();
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      // gap={1}
      fontSize={12}
      sx={{ color: "#fc007f" }}
    >
      <span
        onClick={copyText}
        title="copy"
        className={`material-icons-outlined`}
        style={{ cursor: "pointer", fontSize: "12px", paddingLeft: "3px" }}
        id={text}
      >
        content_copy
      </span>
      {show && <span style={{ paddingLeft: "5px" }}>Copied</span>}
    </Box>
  );
};

const DetailsComponent = ({ label, prefix, suffix, value, theme }) => {
  return (
    <>
      {/* Heading */}
      <div className={`heading ${theme}`} style={{ paddingBottom: "0px" }}>
        {label}:
      </div>

      {/* content */}
      <Box display="flex" alignItems="center" sx={{ paddingBottom: "10px" }}>
        <span className="url-text">{prefix}</span>
        {/* <BootstrapInput
          value={value}
          placeholder={`Enter ${label}`}
          disabled
          className="register-config-dev-env"
          title={value}
        /> */}
        <span className="url-text">{value}</span>
        <span className="url-text">{suffix}</span>
        {<CopyToClipBoad text={prefix + value + suffix} />}
      </Box>
    </>
  );
};

const CheckBoxComponent = ({
  item,
  value,
  isFieldsEditable,
  handleChange,
  keyField,
  app_url,
  data,
  eventTracker,
  theme,
}) => {
  const [showDetails, setDetails] = useState(false);
  const handleDetails = () => {
    if (!showDetails) {
      eventTracker("Env details btn clicked", "View details");
    } else {
      eventTracker("Env details btn clicked", "Hide details");
    }
    setDetails((prev) => !prev);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth={keyField === "deployment_env" ? "25%" : "auto"}
      mr={2}
      sx={{
        marginRight: "16px",
        marginBottom: "5px",
        // marginTop: "5px",
      }}
    >
      <FormControlLabel
        sx={(theme) => ({
          "& .MuiFormControlLabel-label": {
            fontFamily: "Energy !important",
            fontStyle: "normal !important",
            fontSize: "12px !important",
            lineHeight: "23px !important",
            color: theme.palette.mode === "dark" ? "#fff" : "#282828",
          },
        })}
        control={
          <Checkbox
            checked={((Array.isArray(value) && value) || []).includes(item)}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: "20px",
              },
              "&.Mui-checked": {
                color: "#415A6C",
              },
            }}
            disabled={isFieldsEditable}
            onChange={() => handleChange(keyField, item)}
            inputProps={{ "aria-label": { keyField } }}
          />
        }
        label={item}
      />
      {keyField === "deployment_env" && (
        <>
          <div style={{ display: "flex" }}>
            {(value || []).includes(item) && (
              <button
                className="url-text url-btn GA-show-detials"
                // onClick={() => setDetails((prev) => !prev)}
                onClick={handleDetails}
                id="Deployment_env"
              >
                {showDetails ? "Hide Details" : "View Details"}
              </button>
            )}
          </div>
          {showDetails && (value || []).includes(item) && (
            <>
              {getList(item).map((item, i) => (
                <DetailsComponent
                  key={item.suffix}
                  prefix={item.prefix}
                  suffix={item.suffix}
                  label={item.label}
                  value={data[item.name]}
                  theme={theme}
                />
              ))}
            </>
          )}
        </>
      )}
    </Box>
  );
};

const MultiCheckBox = ({
  data,
  width,
  value = [],
  handleChange,
  isFieldsEditable,
  app_url,
  eventTracker,
  theme,
}) => {
  const { label, key, required, options } = data;
  const [filteredValues, setFilteredValues] = useState([]);

  const getData = (val) => {
    if (key === "deployment_env") {
      const index = (value || []).findIndex((list) => list.env_name === val);
      if (index >= 0) return value[index];
    }
    return {};
  };

  useEffect(() => {
    if (key === "deployment_env") {
      const data = [];
      value.forEach((item) => data.push(item.env_name));
      setFilteredValues(data);
    }
  }, [key, value]);

  return (
    <Grid item xs={width || 12} sx={{ pb: "12px" }}>
      <FormControl component="fieldset" sx={{ width: "100%" }}>
        <FormLabel
          component="legend"
          className={`heading ${theme}`}
          sx={{
            color: theme === "dark" ? "#fff" : "#282828",
          }}
        >
          {label} <span style={{ display: required ? "" : "none" }}>*</span>:
        </FormLabel>
        <FormGroup aria-label="position" row>
          {options.map((item) => (
            <CheckBoxComponent
              key={item}
              keyField={key}
              value={key === "deployment_env" ? filteredValues : value}
              handleChange={handleChange}
              item={item}
              isFieldsEditable={isFieldsEditable}
              app_url={app_url}
              data={getData(item)}
              eventTracker={eventTracker}
              theme={theme}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(MultiCheckBox);
