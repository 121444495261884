import { makeStyles, withStyles } from "@material-ui/core";

export const useStylesfoUM = makeStyles((theme) => ({
  tableContainer: {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.white.main
        : theme.palette.font.color.secondary,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.light : "white",
    borderRadius: "10px",
  },
  tableRowCell: {
    fontFamily: theme.palette.font.fontFamily,
    fontSize: "12px !important",
    cursor: "pointer !important",
    textTransform: "capitalize",
    lineHeight: "0 !important",
    color:
      theme.palette.mode === "light"
        ? theme.palette.font.color.secondary
        : "white",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.light : "white",
  },
  tableHeadCell: {
    fontFamily: theme.palette.font.fontFamily,
    fontSize: "12px !important",
    fontWeight: "700 !important",
    color: theme.palette.font.color.secondary,
    backgroundColor: "#E6E9EE !important",
    textTransform: "uppercase",
    lineHeight: "24px",
    padding: "16px",
    position: "sticky",
    top: "0px",
    zIndex:2
  },
  tableRow: {
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.dark.main : "#FBEDF2",
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : theme.palette.font.color.secondary,
    },
  },
  pagination: {
    "& .MuiTablePagination-selectLabel": {
      fontFamily: theme.palette.font.fontFamily,
      fontSize: "12px",
    },
    "& .MuiTablePagination-select": {
      fontFamily: theme.palette.font.fontFamily,
      fontSize: "12px",
    },
    "& .MuiTablePagination-displayedRows": {
      fontFamily: theme.palette.font.fontFamily,
      fontSize: "12px",
    },
    "& .MuiTablePagination-actions": {
      "& .MuiSvgIcon-root": {
        fontSize: "0.9rem !important",
      },
    },
  },
  tableContentcenter: {
    textAlign: "center !important",
  },
  search: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "4px",
    marginLeft: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  inputField: {
    flex: 1,
    "& .MuiInputBase-input": {
      width: "100% !important",
    },
  },

  stickyCellAppRank: {
    position: "sticky",
    left: "0px",
  },
  stickyCellTotalViews: {
    position: "sticky",
    left: "324px",
  },
  stickyCellAppName: {
    position: "sticky",
    left: "142px",
  },

  stickyCellBody:{
    zIndex: 1,
  },
  stickyCellBody:{
    zIndex: 3,
  }
}));
