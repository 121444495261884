import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
// Import MUI components
import { makeStyles } from "@material-ui/core";
import { getDefaultTempData } from "../../Register/components/DashboardTemplate/utils";
import { toast } from "react-toastify";
import ConfirmationModal from "../../Register/components/DashboardTemplate/Components/ConfirmationModal";
import {
  updateReportTemplate,
  update_BIappDetails,
  update_appData,
  update_statusData,
} from "../../../redux/actions";

import LayoutReportCard from "../Components/LayoutElements/LayoutReportCard";
import AddNewElementPopUp from "../Components/AddNewElementPopUp/AddNewElementPopUp";
import LayoutImageCard from "../Components/LayoutElements/LayoutImageCard";
import LayoutTextCard from "../Components/LayoutElements/LayoutTextCard";
import { v4 } from "uuid";
import { useHistory, useParams } from "react-router";
import PreviewReport from "../Components/PreviewReport/PreviewReport";
import ScreenshotPage from "./ScreenshotPage";
import * as htmlToImage from "html-to-image";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paper": {
      overflowY: "hidden",
      backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#f6f6f6",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "90vw !important",
      minWidth: "90vw !important",
      maxHeight: "95vh !important",
      minHeight: "95vh !important",
      display: "flex",
      flexDirection: "column",
    },
  },
  container: {
    display: "grid !important",
    "grid-template-columns": "19% 19% 19% 19% 19%",
    "grid-gap": "15px",
    // paddingBottom: "20px",
    flex: 1,
    paddingLeft: "25px",
    paddingRight: "25px",
    position: "relative",
  },
  textImageContainer: {
    display: "flex !important",
    // "grid-template-columns": "auto auto",
    // paddingBottom: "20px",
    flex: 1,
    paddingLeft: "25px",
    paddingRight: "25px",
    // position: "relative",
  },
  content: {
    flex: 1,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "10px",
    marginBottom: "20px",
  },
  discardBtn: {
    backgroundColor: "#E7E9EE !important",
    color: "#282828 !important",
  },
  addReportText: {
    fontSize: "14px",
    color: "#282828 !important",
  },
  addReportContainer: {
    height: "calc(95vh - 110px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyScreenImg: {
    height: "250px",
  },
  iconForScreenshot: {
    "& .googleIcons": {
      color: "#fc007f !important",
      "-webkit-text-fill-color": "#fc007f !important",
      backgroundColor: "transparent",
    },
  },
  normalIcon: {
    "& .googleIcons": {
      background:
        "linear-gradient(45deg, #FC007F 0%,#FC007F 50% ,#FF4D48 100%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    },
  },
}));
const useStyles9 = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paper": {
      overflowY: "hidden",
      backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#f6f6f6",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "90vw !important",
      minWidth: "90vw !important",
      maxHeight: "95vh !important",
      minHeight: "95vh !important",
      display: "flex",
      flexDirection: "column",
    },
  },
  container: {
    display: "grid !important",
    "grid-template-columns": "auto",
    "grid-gap": "15px",
    // paddingBottom: "20px",
    flex: 1,
    padding: "10px 15px",
    paddingTop: "0px",
    position: "relative",
  },
  textImageContainer: {
    display: "flex",
    flexDirection: "column",
    // paddingBottom: "20px",
    margin: "0 0 10px 0",
    flex: 1,
    paddingLeft: "15px",
    paddingRight: "15px",
    position: "relative",
    width: "35%",
  },
}));
function fetchImportAPI(obj) {
  const url = "/api/sync-reports";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),
  });
}

const AppLayout = (props) => {
  const {
    open = false,
    setopenCreateTemplatePopUp,
    appTemplateState = {},
    appFields = {},
    categoryInfo,
    template_no,
    user,
    appDetails,
    setSelectedWorkspace,
    prevWorkspace,
    setOpenWorkspaceDrawer,
    selectedTemplate,
    editable_layout,
    registerNewApp,
    categoryInfoLocal,
    theme,
    visited,
    showScreenshot,
    takeScreenshot,
    waitingForScreenshot,
    setOpen,
    setAlertMsg,
    setDraftApp,
    savedTemplateData,
    // app_fields,
  } = props;

  const initialState = {
    bi_application: "Power BI",
    show_description: false,
    sub_category_description: "",
    function: [...appFields.app_details.function],
    line_of_business: [...appFields.app_details.line_of_business],
    business_owners: [user.preferred_username],
    technical_owners: [user.preferred_username],
    geography: [...appFields.app_details.geography],
    sub_category_id: v4(),
    sub_category_name: "Untitled report",
    sub_category_type: "report",
    sub_category_img_data: {
      img_url: "",
      blob_name: "",
      selected_size: "fit",
    },
    url: "",
    view_count: 0,
    additional_images: [],
    app_logo_type: "google_font",
    blob_name: "analytics",
    sub_category_icon: "dashboard",
  };

  const [reportDetails, setReportDetails] = useState(null);
  const [showPreviewReport, setShowPreviewReport] = useState(false);
  const classes = useStyles();
  const classes9 = useStyles9();
  const { appInfo, draftId, tab } = useParams();
  const dispatch = useDispatch();
  //Define required states
  const [openAddReportPopUp, setopenAddReportPopUp] = useState(false);
  const [selectedWidgetType, setSelectedWidgetType] = useState(null);
  // const [renderingData, setRenderingData] = useState([]);
  const [selectedSubcat, setSelectedSubcat] = useState({});
  // const [renderSubReportArr, setRenderSubReportArr] =
  //   useState(appTemplateState);
  const [waiting, setWaiting] = useState(false);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [syncloading, setSyncLoading] = useState("pending");
  const [msg, setMsg] = useState("");
  // const [breadcrumbArr, setBreadCrumbArr] = useState([
  //   { textName: appFields.app_name, textID: appFields.app_id },
  // ]);

  const handleCancelSync = () => {
    setOpenSyncModal(false);
  };

  const handleClose = () => {
    setopenCreateTemplatePopUp(false);
  };

  const handleSyncReports = async () => {
    setMsg("");
    setSyncLoading("loading");
    const requestObject = {
      workspace_id: appDetails.data.app_details.workspace_id,
      // function: appFields.app_details.function,
      function: ["All"],
      // line_of_business: appFields.app_details.line_of_business,
      line_of_business: ["Global"],
      business_owners: [user.preferred_username],
      technical_owners: [user.preferred_username],
      // geography: appFields.app_details.geography,
      geography: [{ countries: ["All"], region: "All" }],
      // app_id: appDetails.data.app_id,
      app_id: "shjdfjhsbdbfjhgdsjfhbdsjh",
    };
    try {
      const response = await fetchImportAPI(requestObject);
      const resJson = await response.json();
      if (resJson?.status.statusCode === "datareckitt-200") {
        dispatch(
          updateReportTemplate({
            templateData: resJson.data,
            template_no: "Template_5",
          })
        );
        // setRenderSubReportArr(resJson.data);
        setSyncLoading("success");
        setOpenSyncModal(false);
      } else if (resJson?.status.statusCode === "datareckitt-401") {
        setOpenWorkspaceDrawer(true);
        setSyncLoading("error");
        handleDiscardChanges();
      } else {
        toast.error("Opps! something went wrong.");
        setSyncLoading("error");
      }
    } catch (error) {
      toast.error("Opps! something went wrong.");
      console.log("error", error);
      setSyncLoading("error");
    }
  };

  // Opens popup to edit/add a new report
  const handleEditSubCat = (report) => {
    setSelectedWidgetType(report.sub_category_type);
    setSelectedSubcat(report);
    setopenAddReportPopUp(true);
  };
  const handleDiscardChanges = () => {
    // Update the template Data state with OLD array (for update) / empty Array for (New)
    setSelectedWorkspace(prevWorkspace);
    dispatch(
      updateReportTemplate({
        templateData: categoryInfo.length
          ? categoryInfo
          : getDefaultTempData(template_no, appFields, user.preferred_username),
      })
    );
    handleClose();
  };

  const handleDeleteReport = (subCat, data) => {
    const ind = data.findIndex(
      (item) => item.sub_category_id === subCat.sub_category_id
    );
    if (ind >= 0) {
      // update incase ID exists
      const leftItems = data.slice(0, ind);
      const rightItems = data.slice(ind + 1);
      const newData = [...leftItems, ...rightItems];
      // setRenderingData(newData);
      dispatch(update_BIappDetails({ categoryInfoLocal: newData }));
      return;
    }
  };

  // Delete a subcategory
  const handleDeleteSubCat = (subCat) => {
    // let data =
    //   renderingData && renderingData?.length > 0
    //     ? JSON.parse(JSON.stringify(renderingData))
    //     : JSON.parse(JSON.stringify(appTemplateState));
    let data =
      categoryInfoLocal && categoryInfoLocal?.length > 0
        ? JSON.parse(JSON.stringify(categoryInfoLocal))
        : JSON.parse(JSON.stringify(appTemplateState));
    handleDeleteReport(subCat, data);
  };

  useEffect(() => {
    if (!visited.category) {
      dispatch(
        update_BIappDetails({ categoryInfoLocal: [...appTemplateState] })
      );
      dispatch(
        update_appData({
          visited: { ...visited, category: true },
        })
      );
    }
  }, [selectedTemplate, appTemplateState]);

  // const handleSaveElement = (newObj, oldState = [...renderingData]) => {
  const handleSaveElement = (newObj, oldState = [...categoryInfoLocal]) => {
    // debugger;
    const reportIndex = oldState.findIndex(
      (el) => el.sub_category_id === newObj.sub_category_id
    );
    if (reportIndex >= 0) {
      const leftData = [...oldState].slice(0, reportIndex);
      const rightData = [...oldState].slice(reportIndex + 1);
      const newArr = [...leftData, newObj, ...rightData];
      // setRenderingData(newArr);
      dispatch(update_BIappDetails({ categoryInfoLocal: newArr }));
      setSelectedSubcat({});
      setopenAddReportPopUp(false);
    } else {
      // setRenderingData([...oldState, newObj]);
      dispatch(
        update_BIappDetails({ categoryInfoLocal: [...oldState, newObj] })
      );
      setSelectedSubcat({});
      setopenAddReportPopUp(false);
    }
  };

  useEffect(() => {
    if (editable_layout && showPreviewReport) {
      setShowPreviewReport(false);
      setReportDetails(null);
    }
  }, [editable_layout]);

  const handlePreviewReport = (report) => {
    if (!editable_layout && report?.url !== "") {
      setReportDetails(report);
      setShowPreviewReport(true);
    }
  };

  // CHANGES
  // ---------------------------------------------
  function fetchAPI(formData) {
    const url = "/api/imageuploadv2";
    return fetch(url, {
      method: "POST",
      headers: {},
      body: formData,
    });
  }
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const req = await fetchAPI(formData);
      const response = await req.json();
      if (response.status.statusCode === "datareckitt-200") {
        dispatch(
          updateReportTemplate({
            temp_img_data: response.data,
          })
        );
        dispatch(
          update_appData({
            savedTemplateData: {
              ...savedTemplateData,
              temp_img_data: response.data,
            },
          })
        );
      } else {
        toast.error("Oops! something went wrong");
      }
      // setReqStates();
    } catch (error) {
      console.log("error from image upload", error);
      toast.error(error || "Oops! something went wrong");
      // setReqStates();
    }
    dispatch(update_statusData({ waitingForScreenshot: false }));
    dispatch(
      update_statusData({
        showScreenshot: true,
        isStepperHidden: false,
        editLayout: true,
      })
    );
  };

  const urlToObject = async (image) => {
    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });
    return file;
  };
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const getScreenShot = async () => {
    // setWaiting(true);
    // setPrevWorkspace(selectedWorkspace);
    // dispatch(update_BIappDetails({ prevWorkspace: selectedWorkspace }));
    // setBreadCrumbArr([
    //   { textName: appFields.app_name, textID: appFields.app_id },
    // ]);
    // setRenderSubReportArr(appTemplateState);
    setShowPreviewReport(false);

    dispatch(update_statusData({ waitingForScreenshot: true }));
    await timeout(800);
    const node = document.getElementById("screenshot");
    htmlToImage
      .toPng(node)
      .then(function (img) {
        return urlToObject(img);
      })
      .then((file) => {
        uploadFile(file);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
        // setReqStates();
      });
  };

  const handleSaveChanges = async () => {
    dispatch(update_statusData({ editLayout: false }));
    await getScreenShot();
  };

  useEffect(() => {
    if (takeScreenshot) {
      handleSaveChanges();
      dispatch(update_statusData({ takeScreenshot: false }));
    }
  }, [takeScreenshot]);

  // ---------------------------------------------

  return (
    <>
      <ConfirmationModal
        open={openSyncModal}
        msg={msg}
        // msg={
        //     "You have unsaved changes! Are you sure you want to discard them and sync reports ?"
        // }
        handleClose={handleCancelSync}
        handleDone={handleSyncReports}
        loadStatus={syncloading === "loading"}
        theme={theme}
      />
      {showPreviewReport && (
        <>
          <button
            style={{
              marginLeft: "25px",
              width: "100px",
              display: "block",
              marginTop: "10px",
              marginBottom: "10px",
              borderRadius: "3px",
            }}
            onClick={() => {
              setShowPreviewReport(false);
              setReportDetails(null);
            }}
          >
            BACK
          </button>
          <div
            style={{
              marginLeft: "25px",
              marginRight: "25px",
            }}
          >
            <PreviewReport activeReport={reportDetails} />
          </div>
        </>
      )}
      {!showPreviewReport && Object.keys(selectedSubcat).length !== 0 && (
        <AddNewElementPopUp
          openImageSelector={openAddReportPopUp}
          setopenAddReportPopUp={setopenAddReportPopUp}
          selectedEl={selectedSubcat.sub_category_type}
          reportDetails={selectedSubcat}
          handleSaveElement={handleSaveElement}
        />
      )}

      {showScreenshot && (
        <ScreenshotPage
          setOpen={setOpen}
          setAlertMsg={setAlertMsg}
          setDraftApp={setDraftApp}
        />
      )}
      {/* For Template 6,7 and 8 */}
      {!showScreenshot &&
        !showPreviewReport &&
        template_no > 5 &&
        template_no < 9 && (
          <div id="screenshot">
            {/* App Text AND App Image */}
            <div className={classes.textImageContainer}>
              {categoryInfoLocal.map((item) => {
                if (item.sub_category_type === "image") {
                  return (
                    <LayoutImageCard
                      card_width="95%"
                      card_height={"100px"}
                      imageDetails={item}
                      template_no={selectedTemplate}
                      handleEditSubCat={handleEditSubCat}
                      handleDeleteSubCat={handleDeleteSubCat}
                    />
                  );
                } else if (item.sub_category_type === "text") {
                  return (
                    <LayoutTextCard
                      card_width="95%"
                      card_height={"100px"}
                      textDetails={item}
                      template_no={selectedTemplate}
                      handleEditSubCat={handleEditSubCat}
                      handleDeleteSubCat={handleDeleteSubCat}
                    />
                  );
                }
              })}
            </div>

            {/* Report AND ADD REPORT CARD */}
            <div
              style={{
                height:
                  !editable_layout && appInfo
                    ? "calc(100vh - 230px)"
                    : "calc(100vh - 230px)",
                overflowY: "auto",
              }}
            >
              {/* REPORTS */}
              <div className={classes.container}>
                {categoryInfoLocal.map((item) => {
                  if (item.sub_category_type === "report") {
                    return (
                      <LayoutReportCard
                        card_width="95%"
                        card_height={selectedTemplate === 7 ? "90%" : "200px"}
                        reportDetails={item}
                        handleEditSubCat={handleEditSubCat}
                        handleDeleteSubCat={handleDeleteSubCat}
                        handlePreviewReport={handlePreviewReport}
                      />
                    );
                  }
                })}

                {/* Add Report */}
                {editable_layout && (
                  <div
                    style={{
                      color: "white",
                      height: "200px",
                      background: "#0058aa",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "3px",
                      width: "95%",
                    }}
                    onClick={() => handleEditSubCat(initialState)}
                  >
                    Add Report
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      {/* For Template 9 */}
      {!showScreenshot && !showPreviewReport && template_no === 9 && (
        <div
          style={{ display: "flex", flexDirection: "row-reverse" }}
          id="screenshot"
        >
          {/* App Image AND App Text */}
          <div className={classes9.textImageContainer}>
            {categoryInfoLocal.map((item) => {
              if (item.sub_category_type === "image") {
                return (
                  <LayoutImageCard
                    card_width="100%"
                    card_height={"40vh"}
                    handleEditSubCat={handleEditSubCat}
                    imageDetails={item}
                  />
                );
              } else if (item.sub_category_type === "text") {
                return (
                  <LayoutTextCard
                    card_width="100%"
                    //     height:
                    // !editable_layout && appInfo
                    //   ? "calc(100vh - 320px)"
                    //   : "calc(100vh - 230px)",

                    // card_height={"40vh"}
                    card_height={!editable_layout && appInfo ? "34vh" : "40vh"}
                    handleEditSubCat={handleEditSubCat}
                    textDetails={item}
                  />
                );
              }
            })}
          </div>
          <div
            style={{
              height:
                !editable_layout && appInfo
                  ? "calc(100vh - 106px)"
                  : "calc(100vh - 106px)",
              overflowY: "auto",
              width: "65%",
            }}
          >
            {/* ADD REPORT  AND Reports */}
            <div className={classes9.container}>
              {editable_layout && (
                <div
                  style={{
                    color: "white",
                    height: "100px",
                    background: "#0058aa",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "3px",
                    width: "100%",
                  }}
                  onClick={() => handleEditSubCat(initialState)}
                >
                  Add Report
                </div>
              )}
              {categoryInfoLocal.map((item) => {
                if (item.sub_category_type === "report") {
                  return (
                    <LayoutReportCard
                      card_height={"90%"}
                      reportDetails={item}
                      // editable={true}
                      handleEditSubCat={handleEditSubCat}
                      handleDeleteSubCat={handleDeleteSubCat}
                      template_number={9}
                      handlePreviewReport={handlePreviewReport}
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  savedTemplateData: state.registerNewApp.appData.savedTemplateData,
  takeScreenshot: state.registerNewApp.statusData.takeScreenshot,
  waitingForScreenshot: state.registerNewApp.statusData.waitingForScreenshot,
  showScreenshot: state.registerNewApp.statusData.showScreenshot,
  user: state.user,
  allApps: state.AppsData.allApps,
  theme: state.user.ui_preferences?.theme,
  appTemplateState: state.appTemplate.appTemplateState.templateData,
  template_no: state.appTemplate.appTemplateState.template_no,
  registerTemplateFields:
    state.register.registerTemplate.data.register_app_template.fields[0]
      .options[0].subform_templates[2],
  appDetails: state.AppsData.appDetails,
  appFields: state.registerNewApp.appData.appFields,
  editable_layout: state.registerNewApp.statusData.editLayout,
  registerNewApp: state.registerNewApp,
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  visited: state.registerNewApp.appData.visited,
});

export default connect(mapStateToProps)(AppLayout);
