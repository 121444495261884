// Import required libraries
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// Import MUI components
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// Import styles
import { tabListStyle, tabStyle, viewStyles } from "./HelpStyles";

// Import actions

// Import custom components
import { SkeletonForHelpCenterPage } from "../../components/ImageLoader/SkeletonLoadScreen";
import TicketCard from "./Components/TicketCard";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import RequestedIssues from "./Components/RequestedIssues";

// Import Hooks

// Import assets/images
import error_smw from "../../../assets/images/error_smw.svg";
import { useHistory, useParams } from "react-router";

export function fetchAPI(url, request_meta) {
    return fetch(url, request_meta);
}

function HelpCenter(props) {
    const { match, user } = props;
    const { tab } = useParams();
    const history = useHistory();

    // Define states
    const [tabValue, setTabValue] = useState("My Issues");
    const [loadTicketList, setLoadTicketList] = useState("pending");
    const [ticketsDataList, setTicketsDataList] = useState([]);

    useEffect(() => {
        if (tab !== "requested-issues" && tab !== "my-issues") {
            history.push("/help/my-issues");
            setTabValue("My Issues");
        } else {
            if (tab === "requested-issues") {
                setTabValue("Requested Issues");
            } else if (tab === "my-issues") {
                setTabValue("My Issues");
            }
        }
    }, []);

  // Function for Fetching User Tickets API call
  const handleFetchTickets = async () => {
    setLoadTicketList("loading");
    var url = "/api/jira/search";
    var payload = {
      jql: `labels="${user.preferred_username}"&&reporter="${
        window.location?.origin?.includes("dev") ||
        window.location?.origin?.includes("localhost")
          ? "Bhavya.Verma@reckitt.com"
          : window.location?.origin?.includes("uat")
          ? "Debamitra.Mukherjee@reckitt.com"
          : "Shubh.Ambaly@reckitt.com"
      }"`,
      maxResults: 1000,
    };
    const request_meta = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetchAPI(url, request_meta);
      const response = await res.json();
      if (response.issues) {
        setLoadTicketList("success");
        setTicketsDataList(response.issues);
      } else {
        setLoadTicketList("error");
      }
    } catch (error) {
      setLoadTicketList("error");
      toast.error("Couldn't Fetch Tickets");
    }
  };

    // Fetch Tickets on Mount
    useEffect(() => {
        handleFetchTickets();
    }, []);

    const HandleChangeTab = async () => {
        if (tabValue === "My Issues") {
            history.push("/help/requested-issues");
            setTabValue("Requested Issues");
        } else {
            history.push("/help/my-issues");
            setTabValue("My Issues");
        }
    };

  return (
    <LayoutTopSideBottom match={match} selected="/help">
      <div style={{ padding: "20px 30px 0px 30px" }}>
        {loadTicketList === "loading" && (
          <SkeletonForHelpCenterPage showNavTab={true} />
        )}
        {loadTicketList === "error" && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img style={{ marginTop: "150px" }} src={error_smw} alt="" />
          </div>
        )}
        {loadTicketList === "success" && (
          <TabContext value={tabValue}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className={viewStyles.headerContainer}
            >
              <TabList
                onChange={HandleChangeTab}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  children: <span className="MuiTabs-indicatorSpan" />,
                }}
                sx={tabListStyle}
              >
                <Tab
                  className="GA-help-center-tabs"
                  sx={tabStyle}
                  label="My Issues"
                  value="My Issues"
                />
                {(user.role === "admin" || user.role === "super_admin") && (
                  <Tab
                    className="GA-help-center-tabs"
                    sx={tabStyle}
                    label="Requested Issues"
                    value="Requested Issues"
                  />
                )}
              </TabList>
            </div>
            <div
              style={{ marginBottom: "0px" }}
              className="Help-center-tickets-container"
            >
              <TabPanel value="My Issues">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat( auto-fill, minmax(300px, 0.1fr) )",
                    gridGap: "15px",
                  }}
                >
                  <TicketCard type="raise-ticket" />
                  {ticketsDataList?.length > 0 &&
                    ticketsDataList.map((ticket) => (
                      <TicketCard ticket_data={ticket} />
                    ))}
                </div>
              </TabPanel>
              <TabPanel value="Requested Issues">
                <RequestedIssues />
              </TabPanel>
            </div>
          </TabContext>
        )}
      </div>
    </LayoutTopSideBottom>
  );
}

HelpCenter.propTypes = {
    user: PropTypes.object,
    match: PropTypes.object,
};

const mapStateToProps = (state) => ({
    user: state.user,
});
export default connect(mapStateToProps)(HelpCenter);
