import React from "react";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

const BreadCrumb = (props) => {
  const { handleClickBreadCrumb, breadcrumbArr = [], user } = props;
  const getElipsesName = (name, arrayLength) => {
    if (arrayLength <= 2) {
      return name;
    } else if (arrayLength <= 5) {
      return name.length > 15 ? name.slice(0, 15) + ".." : name;
    } else if (arrayLength <= 15) {
      return name.length > 5 ? name.slice(0, 5) + ".." : name;
    } else {
      return name.length > 2 ? name.slice(0, 2) + ".." : name;
    }
  };
  return (
    <div style={{ display: "flex" }}>
      {breadcrumbArr.map((item, ind) => {
        // First element
        if (ind === 0) {
          return (
            <div
              key={item.textID}
              onClick={
                breadcrumbArr.length > 1
                  ? () => {
                      handleClickBreadCrumb(item, ind);
                    }
                  : () => {}
              }
              style={{
                color: user.ui_preferences.theme === "dark" ? "#fff" : "#000",
                cursor: breadcrumbArr.length > 1 ? "pointer" : "default",
                fontWeight: "400",
                fontSize: "15px",
              }}
            >
              {item.textName + " "}
            </div>
          );
        }
        // Last element
        else if (ind === breadcrumbArr.length - 1) {
          return (
            <div
              style={{
                color:
                  user.ui_preferences.theme === "dark" ? "#aab4bd" : "#FC007F",
                cursor: "default",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
              }}
              key={item.textID}
            >
              <span
                style={{ fontSize: "18px" }}
                class="material-icons-outlined"
              >
                chevron_right
              </span>
              {item.textName}
            </div>
          );
        }
        // elements in between first and last
        else {
          return (
            <div
              key={item.textID}
              style={{
                color: user.ui_preferences.theme === "dark" ? "#fff" : "#000",
                cursor: "pointer",
                fontWeight: "400",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                handleClickBreadCrumb(item);
              }}
            >
              <span
                style={{ fontSize: "18px" }}
                class="material-icons-outlined"
              >
                chevron_right
              </span>
              <Tooltip title={item.textName}>
                {getElipsesName(item.textName, breadcrumbArr.length)}
              </Tooltip>
            </div>
          );
        }
      })}
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(BreadCrumb);
