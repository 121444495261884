import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import TableBodyComponent from "./TableBodyComponent";
//actions
import {
  clean_user_access_list,
  get_user_access_list,
} from "../../../redux/actions";
//styles
import "./style.scss";
import { useStyles1 } from "../../../components/UserTable/style";
import { paperStyle, useStyles, StyledInputBase } from "../style";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const headerData = [
  { id: "Application", label: "Application" },
  { id: "request_by", label: "Requested By" },
  { id: "requester_comment", label: "Requester's comment" },
  { id: "access_raised_on", label: "Access Raised On" },
  { id: "access_granted_on", label: "Access Granted On" },
  { id: "access_level", label: "Access level" },
  { id: "action", label: "Action" },
];

const TableHeaderCell = (props) => {
  const { column, filtervalue, setFilterValue } = props;
  const [showFilter, setShowFilter] = useState(false);
  const classesTable = useStyles();

  const handleChange = (e) => setFilterValue(e.target.value);
  const cancelSearch = () => setFilterValue("");

  const handleFilter = (e, type) => {
    setShowFilter(!showFilter);
    setFilterValue("");
  };
  return (
    <>
      <TableCell
        className={`${classesTable.tableHeadCell} ${
          column.id === "user_id" ? classesTable.fixedWidth : ""
        }`}
        key={column.id}
        align={column.align}
        style={{
          textAlign: "center",
          maxWidth: column.id === "requester_comment" ? "100px" : "inherit",
        }}
      >
        <div style={{ display: "flex" }}>
          <Box display="flex" alignItems="center" justifyContent="center">
            {!showFilter && (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {column.label}
              </div>
            )}
            {column.id === "Application" && (
              <span
                class="material-icons-outlined"
                style={{
                  fontSize: "16px",
                  marginLeft: "10px",
                  color: filtervalue ? "#ea3592" : "#ffffff",
                }}
                onClick={(e) => handleFilter(e, column.id)}
              >
                {showFilter ? "close" : "filter_alt"}
              </span>
            )}
          </Box>
          {showFilter && (
            <>
              <div className={classesTable.search}>
                <StyledInputBase
                  placeholder="Search Apps"
                  inputProps={{
                    "aria-label": "search",
                  }}
                  onChange={handleChange}
                  value={filtervalue}
                  className={classesTable.inputField}
                />
                {filtervalue ? (
                  <IconButton onClick={(e) => cancelSearch(e)}>
                    <ClearIcon
                      sx={{
                        color: "#282828",
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton>
                    <SearchIcon
                      sx={{
                        color: "#282828",
                      }}
                    />
                  </IconButton>
                )}
              </div>
            </>
          )}
        </div>
      </TableCell>
    </>
  );
};

const UserAccessMangement = ({
  updatePendingRequest,
  pendingCount,
  setPendingCount,
  userInfo,
  user_access_list,
  theme,
  usersList = [],
  setUsersList,
}) => {
  const dispatch = useDispatch();
  const classesTable = useStyles1();
  const [modifiedList, setModifiedList] = useState([]);
  const [page, setPage] = useState(0);
  const [filtervalue, setFilterValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [pendingCount, setPendingCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (filtervalue) {
      const filteredARR = usersList.filter((item) =>
        item.requester_meta.app_name
          .toUpperCase()
          .includes(filtervalue.toUpperCase())
      );
      setModifiedList(filteredARR);
      handleChangePage(null, 0);
    } else {
      setModifiedList(usersList);
    }
  }, [filtervalue, usersList]);

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <div className="profile-subtitle">
          Manage the access requested by users for your applications here.
        </div>
        <div className="profile-subtitle">
          Pending requests: <span>{pendingCount}</span>
        </div>
      </Box>
      <Paper sx={paperStyle}>
        <TableContainer sx={{ maxHeight: "calc(100vh - 347px)" }}>
          <Table stickyHeader="sticky table">
            <TableHead>
              <TableRow>
                {headerData.map((column, i) => (
                  <TableHeaderCell
                    setFilterValue={setFilterValue}
                    filtervalue={filtervalue}
                    key={i}
                    column={column}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {modifiedList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableBodyComponent
                    key={item.request_id}
                    data={item}
                    userInfo={userInfo}
                    theme={theme}
                    setUsersList={setUsersList}
                    updatePendingRequest={updatePendingRequest}
                    setModifiedList={setModifiedList}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!usersList.length && user_access_list.status !== "loading" && (
          <Box textAlign="center" mt={1}>
            No Data found.
          </Box>
        )}
        {user_access_list.status === "loading" && (
          <Box textAlign="center">
            <Loader />
          </Box>
        )}
        <TablePagination
          className={classesTable.pagination}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={modifiedList.length}
          labelRowsPerPage="Requests per page :"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
const mapStateTopProps = (state) => ({
  userInfo: state.user,
  user_access_list: state.userAccessManagement.user_access_list,
  theme: state.user.ui_preferences?.theme,
});
export default connect(mapStateTopProps)(UserAccessMangement);
