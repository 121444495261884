import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import { v4 } from "uuid";
import Button from "@material-ui/core/Button";

import Loader from "../../components/Loader/Loader";

import { formatDate, getInitialsFromEmail, getNormalText, } from "../../utils/utils";
import { config } from "../../config/config";

import { updateDialogInfo, get_updated_comments } from "../../redux/actions";

import { addComments, deleteComments, addLinks } from "../../apiServices";

// Import styles
import "./Comment.scss";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 500,
    paddingBottom: "30px",
  },
  fullList: {
    width: "auto",
  },
  outlined: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.white.main,
    color: "white",
    marginRight: 10,
    fontSize: 14,
    padding: 10,
    height: 35,
    minWidth: 15,
    width: "30%",
    textTransform: "capitalize",
    float: "right",
    fontFamily: "Energy",
    borderRadius: "20px",
    border: "1px solid #282828",
    "&:hover": {
      backgroundColor: "#e9f7fc",
      color: "#282828",
      boxShadow: "none",
    },
  },
}));

const CommentDrawer = (props) => {
  const {
    anchor,
    open,
    activeReport,
    user,
    showLoadMoreLoaderFlag,
    showLoadMoreButton,
    comment,
    setComment,
    all_comments,
    handleClose,
    app,
    updatedViewsNComments,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [likeStatus, setLikeStatus] = useState({
    comment_id: "",
    loading: false,
  });
  const [deleteStatus, setDeleteStatus] = useState({
    comment_id: "",
    loading: false,
  });

  const handleDelete = async (id) => {
    dispatch(
      updateDialogInfo({
        ...user.dialogInfo,
        isDisabled: true,
        open: true,
        message: config.messages.confirmDelete,
      })
    );
    const { isSuccess, errorMSG } = await deleteComments({
      app_id: app.app_id,
      comment_id: id,
    });
    if (isSuccess) {
      dispatch(
        updateDialogInfo({ ...user.dialogInfo, open: false, isDisabled: false })
      );
      dispatch(
        get_updated_comments({
          app_id: app.app_id,
          sub_category_id: activeReport.sub_category_id,
        })
      );
    } else {
      dispatch(
        updateDialogInfo({ ...user.dialogInfo, open: false, isDisabled: false })
      );
      toast.error(errorMSG || "Oops! something went wrong. please try later");
    }
  };

  const handleLike = async (comment_id, operation) => {
    setLikeStatus({ comment_id, loading: true });
    const { isSuccess, errorMSG } = await addLinks({
      app_id: app.app_id,
      operation,
      comment_id,
    });
    setLikeStatus({ comment_id: "", loading: false });
    if (isSuccess) {
      dispatch(
        get_updated_comments({
          app_id: app.app_id,
          sub_category_id: activeReport.sub_category_id,
        })
      );
    } else {
      toast.error(errorMSG || "Oops! something went wrong. please try later");
    }
  };

  const makeComment = async (value) => {
    const payload = {
      app_id: app.app_id,
      sub_category_id: activeReport.sub_category_id,
      comment: value,
    };

    const { isSuccess, errorMSG } = await addComments(payload);
    if (isSuccess) {
      dispatch(
        get_updated_comments({
          app_id: app.app_id,
          sub_category_id: activeReport.sub_category_id,
        })
      );
    } else {
      toast.error(errorMSG || "Oops! something went wrong. please try later");
    }
  };

  const handleNo = () => {
    dispatch(updateDialogInfo({ ...user.dialogInfo, open: false }));
  };
  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
      >
        <div className="list">
          <div
            className={`comment-text-cross-container ${user.ui_preferences.theme}-comment-section`}
          >
            <p className="comment-header-text">Comments</p>
            <i
              className="material-icons-outlined drawer-cancel-icon"
              onClick={handleClose}
            >
              cancel
            </i>
          </div>
          <p className="activereport-comment-label">
            {getNormalText(activeReport?.sub_category_name)}
          </p>
          <div className="comments-container">
            <>
              {all_comments && all_comments.length ? (
                <>
                  {all_comments?.map((text) => (
                    <div
                      key={v4()}
                      className={`comment-list-item ${user.ui_preferences.theme}-list`}
                    >
                      <p
                        data-letters={getInitialsFromEmail(
                          text.commented_by,
                          "@"
                        )}
                        className="commented_by_text"
                      >
                        {" "}
                        {text.user_name}{" "}
                      </p>
                      <div className="comments-info">
                        <div className="msg-info">
                          <p className="comment-value-text">{text.comment}</p>
                          <div className="sub-msg-info">
                            <div className="comment-text">
                              <p className="commented_at-text">
                                {formatDate(
                                  text.commented_on,
                                  "MMM DD, YYYY H:mm a"
                                )}
                              </p>
                              <p className="number_of_likes-text">
                                {text.total_likes > 1
                                  ? text.total_likes + " Likes"
                                  : text.total_likes + " Like"}
                              </p>
                            </div>
                            <div className="comment-action">
                              {user.preferred_username ===
                                text.commented_by && (
                                <i
                                  className={`material-icons-outlined delete-icon GA-delete-comment ${
                                    deleteStatus.loading &&
                                    deleteStatus.comment_id === text.comment_id
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    dispatch(
                                      updateDialogInfo({
                                        message: config.messages.confirmDelete,
                                        open: true,
                                        handleYes: () =>
                                          handleDelete(text.comment_id),
                                        handleNo: handleNo,
                                      })
                                    );
                                  }}
                                >
                                  delete
                                </i>
                              )}
                              {
                                <i
                                  className={`material-icons-outlined fav-icon GA-like-comment
                                                                         ${
                                                                           (likeStatus.loading &&
                                                                             likeStatus.comment_id ===
                                                                               text.comment_id) ||
                                                                           updatedViewsNComments.status ===
                                                                             "loading"
                                                                             ? "disabled"
                                                                             : ""
                                                                         }`}
                                  onClick={() => {
                                    const operation = text.liked_by.includes(
                                      user.preferred_username
                                    )
                                      ? "remove"
                                      : "add";
                                    handleLike(text.comment_id, operation);
                                  }}
                                >
                                  {text.liked_by.includes(
                                    user.preferred_username
                                  )
                                    ? "favorite"
                                    : "favorite_border"}
                                </i>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {showLoadMoreLoaderFlag && (
                    <div className="loader">
                      <Loader />
                    </div>
                  )}
                  {showLoadMoreButton && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        className={classes.outlined}
                        // onClick={(e) => loadMoreComments(e)}
                      >
                        Load More
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <p
                    className={`no-comments ${user.ui_preferences.theme}-no-comment-section`}
                  >
                    No comments
                  </p>
                </>
              )}
            </>
            {/* {covidCommentsData.status === "error" && (
                            <p className="no-comments">{covidCommentsData.message}</p>
                        )} */}
          </div>

          <div
            className={`send-container ${user.ui_preferences.theme}-send-container GA-post-comment`}
          >
            <p
              className="commented_by_user"
              data-letters={getInitialsFromEmail(user.preferred_username, "@")}
            ></p>
            <input
              type="text"
              id="covid-comment"
              name="covid-comment"
              value={comment}
              autoComplete="off"
              className={`comment-input ${user.ui_preferences.theme}-comment-input GA-post-comment`}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  makeComment(comment);
                  setComment("");
                }
              }}
              placeholder="Write a comment..."
            />
            <p
              className={`${comment ? "send-button" : "disabled"} ${
                user.ui_preferences.theme
              }-send GA-post-app-comment`}
              onClick={() => {
                makeComment(comment);
                setComment("");
              }}
            >
              Send
            </p>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  updatedViewsNComments: state.AppsData.updatedViewsNComments,
});

export default connect(mapStateToProps)(CommentDrawer);
// try {
//     setDeleteStatus({ comment_id: id, loading: true });
//     // const res = await fetchAPI3({ app_id: app.app_id, comment_id: id });
//     // const data = await res.json();
//     const res = await deleteComments({ app_id: app.app_id, comment_id: id });
//     setDeleteStatus({ comment_id: "", loading: false });
//     if (data.status.status === "datareckitt-200") {
//         dispatch(updateDialogInfo({ ...user.dialogInfo, open: false }));
//         dispatch(get_updated_comments({ app_id: app.app_id, sub_category_id: activeReport.sub_category_id }));
//     }
// } catch (error) {
//     setDeleteStatus({ comment_id: "", loading: false });
//     console.log("error form delete comment", error);
//     toast.error('Oops Something went wrong. Please try later');
// }

// try {
//     const req = await fetchAPI(payload);
//     const data = await req.json();
//     if (data.status.status === "datareckitt-200") {
//         dispatch(get_updated_comments({ app_id: app.app_id, sub_category_id: activeReport.sub_category_id }));
//     };
// } catch (error) {
//     console.log("error form add comment", error);
//     toast.error('Oops Something went wrong. Please try later');
// }

// try {
//     setLikeStatus({ comment_id, loading: true });
//     const res = await fetchAPI2({ app_id: app.app_id, operation, comment_id });
//     const data = await res.json();
//     setLikeStatus({ comment_id: "", loading: false });
//     if (data.status.status === "datareckitt-200") {
//         dispatch(get_updated_comments({ app_id: app.app_id, sub_category_id: activeReport.sub_category_id }));
//     }
// } catch (error) {
//     setLikeStatus({ comment_id: "", loading: false });
//     console.log("error form like comment", error);
//     toast.error('Oops Something went wrong. Please try later');
// }

/*
function fetchAPI3(payload) {
const url = `/api/addcomments?app_id=${payload.app_id}&comment_id=${payload.comment_id}`
return fetch(url, {
method: "DELETE",
headers: {
    "Content-Type": "application/json",
},
});
};
function fetchAPI2(payload) {
const url = `/api/likes`
return fetch(url, {
method: "POST",
headers: {
    "Content-Type": "application/json",
},
body: JSON.stringify(payload),
});
};
function fetchAPI(payload) {
const url = "/api/addcomments"
return fetch(url, {
method: "POST",
headers: {
    "Content-Type": "application/json",
},
body: JSON.stringify(payload),
});
};

*/
