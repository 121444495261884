import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import BreadcrumbComponent from "../../../components/Breadcrumbs";
import Loader from "../../../components/Loader/Loader";
import SearchFilters from "./SearchFilters";

import { addAppToHome } from "../../../utils/utils";
import {
  updateLoyoutDataList,
  update_home_layout,
} from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "88%",
    paddingTop: "7px",
    paddingBottom: "7px",
    // paddingLeft: "2%",
    paddingRight: "2%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
  },
  btn: {
    background: "transparent",
    color: "#fc007f",
    fontWeight: 400,
    // marginBottom: "5px",
    minWidth: "100px",
    gap: "8px",
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    // justifyContent: "flex-end",
    // flex: 1,
    "& .material-icons-round": {
      fontSize: "18px",
    },
    "& .material-icons-outlined": {
      fontSize: "18px",
    },
  },
}));

const ReportHeader = ({
  oldbreadCrumbsData,
  appDashboardState,
  user,
  AppsData,
}) => {
  const { appData, search_columns = [], searchVal = "" } = appDashboardState;
  const { layoutDataList, layoutStatus } = AppsData;
  const { data: appIdList } = layoutDataList;
  const { status } = layoutStatus;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const HandleAddAppToHome = async () => {
    setLoading(true);
    const data = addAppToHome(
      user.preferred_username,
      appIdList,
      appData.app_id
    );
    dispatch(updateLoyoutDataList(data.sequence_of_app));
    dispatch(update_home_layout(data));
    setLoading(false);
  };

  return (
    <Box className={classes.container}>
      <BreadcrumbComponent data={oldbreadCrumbsData} />

      {oldbreadCrumbsData?.length === 1 && <SearchFilters />}

      {/* <div className={classes.btnContainer}> */}
      <button
        className={classes.btn}
        onClick={HandleAddAppToHome}
        disabled={loading || status === "loading"}
      >
        {loading || status === "loading" ? (
          <Loader />
        ) : appIdList.includes(appData.app_id) ? (
          <>
            <i className="material-icons-round" title="Remove from home">
              home
            </i>
            <div>Remove from Home</div>
          </>
        ) : (
          <>
            <i className="material-icons-outlined" title="Add to home">
              add_home
            </i>
            <div>Add to Home</div>
          </>
        )}
      </button>
      {/* </div> */}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
  appDashboardState: state.appDashboard.appDashboardState,
  allApps: state.AppsData.allApps,
  user: state.user,
  AppsData: state.AppsData,
});

export default connect(mapStateToProps)(ReportHeader);
