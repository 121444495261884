import { Container, Stack } from "@mui/material";
import React from "react";

const CreateFooterContainer = ({ children, justifyContent }) => {
    return (
        <Stack
            position="sticky"
            bottom="0px"
            sx={{
                minWidth:"834px",
                maxWidth:"1144px",
                width:"79%",
                margin:"50px auto 0px"
            }}
        >
            <Container

                sx={{
                    display: "flex",
                    direction: "row",
                    justifyContent: justifyContent || "flex-end",
                    "&.MuiContainer-maxWidthLg": {
                        padding:"0px"
                      },
                }}
            >
                {children}
            </Container>
        </Stack>
    );
};

export default CreateFooterContainer;
