import React, { useEffect } from "react";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
// import sample_image from "../../../../../assets/images/new-theme/report_sample_image.svg";
import sample_image from "../../../../../assets/images/new-theme/report_sample_image.svg";

// Import Styles
import { useStylesforImageSelector } from "./Styles";

const ImageSelector = (props) => {
  const { file, setFile, selectedSize, setSelectedSize, imageDetails } = props;
  const fileTypes = ["JPEG", "PNG"];

  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    if (imageDetails?.sub_category_img_data?.img_url !== "") {
      setImgSrc(imageDetails?.sub_category_img_data?.img_url);
      setSelectedSize(imageDetails?.sub_category_img_data?.selected_size);
    }
  }, [imageDetails]);

  const handleChangeImageSize = (size) => {
    setSelectedSize(size);
  };
  const handleChange = (file) => {
    setFile(file);
    handleImage(file);
  };
  const handleSizeError = (file) => {
    console.log("file", file);
  };

  const handleImage = async (file) => {
    const myFileItemReader = new FileReader();
    myFileItemReader.addEventListener(
      "load",
      () => {
        const url = myFileItemReader.result;
        setFile(file);
        setImgSrc(url);
      },
      false
    );
    myFileItemReader.readAsDataURL(file);
  };
  const classes = useStylesforImageSelector();

  return (
    <>
      <FileUploader
        multiple={false}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        maxSize={1}
        className={classes.FileUploaderClass}
        onSizeError={handleSizeError}
        // onDrop={handleDropImage}
      />
      <p className={classes.fileName}>
        {file
          ? `File name: ${file.name}`
          : imgSrc
          ? imageDetails?.sub_category_img_data?.blob_name.split("/")[3]
          : "No files uploaded yet"}
      </p>
      <div>
        {imgSrc && (
          <img
            src={imgSrc}
            alt="Uploaded"
            className={
              selectedSize === "fit"
                ? classes.uploadedImageContain
                : classes.uploadedImageCover
            }
          />
        )}
        {!imgSrc && (
          <img
            src={sample_image}
            alt="Uploaded"
            className={
              selectedSize === "fit"
                ? classes.uploadedImageContain
                : classes.uploadedImageCover
            }
          />
        )}
      </div>
      <div className={classes.imageTypeStyleContainer}>
        <div
          onClick={() => handleChangeImageSize("fit")}
          className={
            selectedSize === "fit"
              ? classes.imageTypeStylesSelected
              : classes.imageTypeStyles
          }
        >
          <img alt="" />
          <div>Fit Content</div>
        </div>
        <div
          className={
            selectedSize !== "fit"
              ? classes.imageTypeStylesSelected
              : classes.imageTypeStyles
          }
          onClick={() => handleChangeImageSize("fill")}
        >
          <img alt="" />
          <div>Fill</div>
        </div>
      </div>
    </>
  );
};

ImageSelector.propTypes = {};

export default ImageSelector;
