// Import Required Libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

// import Styles
import "../HelpCenter.scss";
import { useStylesForJiraTicket } from "../HelpStyles";

// Import Images
import raise_new_ticket from "../../../../assets/images/RaiseTicket.svg";
import TicketPopUp from "./TicketPopUp";

function TicketCard(props) {
  const { ticket_data, type = "", user } = props;
  const [openJiraTicket, setOpenJiraTicket] = useState(false);
  const classes = useStylesForJiraTicket();
  const history = useHistory();

  const handleOpenTicket = () => {
    if (type === "raise-ticket") {
      history.push("/help/newTicket");
    } else {
      setOpenJiraTicket(true);
    }
  };

  return (
    <>
      {openJiraTicket && (
        <TicketPopUp
          ticket_key={ticket_data.key}
          openJiraTicket={openJiraTicket}
          setOpenJiraTicket={setOpenJiraTicket}
        />
      )}
      <div onClick={handleOpenTicket} className={`${classes.ticketCardContainer} GA-open-ticket`}>
        {type === "raise-ticket" ? (
          <div
            className="GA-new-ticket"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              className="GA-new-ticket"
              src={raise_new_ticket}
              alt="Raise New Ticket"
            />
            <p
              className="GA-new-ticket"
              style={{
                margin: "5px 0px 0px 0px",
                fontSize: "16px",
                fontFamily: "Energy",
                fontWeight: "bold",
                color: "#FC007F",
              }}
            >
              New Ticket
            </p>
          </div>
        ) : (
          <>
            <div id={ticket_data.key} className="GA-open-ticket">
              <p
                id={ticket_data.key}
                className="GA-open-ticket"
                style={{
                  margin: "5px 0px 0px 0px",
                  fontSize: "16px",
                  fontFamily: "Energy",
                  fontWeight: "bold",
                  color:
                    user.ui_preferences.theme === "dark"
                      ? "#aab4bd"
                      : "#FC007F",
                }}
              >
                {ticket_data.key}
              </p>
              <p
                id={ticket_data.key}
                style={{
                  color:
                    user.ui_preferences.theme === "dark" ? "#fff" : "#282828",
                }}
                class="ticket-card-summary GA-open-ticket"
              >
                {ticket_data.fields.summary}
              </p>
              <p
                id={ticket_data.key}
                style={{
                  color:
                    user.ui_preferences.theme === "dark" ? "#fff" : "#282828",
                }}
                class="ticket-card-description GA-open-ticket"
              >
                {ticket_data.fields.description.split("||")[0]}
              </p>
            </div>
            <p
              id={ticket_data.key}
              class="GA-open-ticket"
              style={{
                margin: "5px 0px 0px 0px",
                fontSize: "14px",
                fontFamily: "Energy",
                color:
                  ticket_data.fields.status.name === "Closed"
                    ? "#aab4bd"
                    : user.ui_preferences.theme === "dark"
                    ? "#fff"
                    : "#FC007F",
                fontWeight: "bold",
              }}
            >
              {ticket_data.fields.status.name}
            </p>
          </>
        )}
      </div>
    </>
  );
}

TicketCard.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TicketCard);
