// Import required libraies
import React, { useEffect, useRef, useState } from "react";
// Import Custom Component
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";

// Import styles
import "./Home.scss";
import "../../components/AppRow/AppRow.scss";

// Import Assets/Data
import no_apps_found from "../../../assets/images/no_apps_found.svg";

// import reducers
import { getAllApps } from "../../redux/actions";
import SkeletonLoadScreen from "../../components/ImageLoader/SkeletonLoadScreen";
import EditCard from "../../components/AppCard/EditCard";
import { v4 } from "uuid";
import { getElementContentWidth } from "../../utils/utils";
import {
  StyledAutoComplete,
  CssTextField,
  useStyles,
} from "../../components/AppRow/style";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function EditLayout(props) {
  const {
    AppsData = {},
    renderAppData,
    setRenderAppData,
    tempLayout,
    setTempLayout,
    AllApps,
    setEditLayoutStatus,
    user,
  } = props;
  const dispatch = useDispatch();
  // Defining required states
  const [addApp, setAddApp] = useState([]);
  const [tempArr, setTempArr] = useState([]);
  const containerId = useRef`appRow_${v4()}`;
  const [sliceCount, setSliceCount] = useState();
  const [addAppArr, setAddAppArr] = useState([]);
  const [items, setItems] = useState({
    left: renderAppData,
  });
  const [allExpand, setAllExpand] = useState({
    expanded: false,
    value: "Show All",
  });
  const classes1 = useStyles();
  const [inputValue, setInputValue] = useState("");

  // get all the apps
  useEffect(() => {
    dispatch(getAllApps());
  }, [dispatch]);

  useEffect(() => {
    if (AllApps.status === "error") {
      setEditLayoutStatus(false);
      toast.error(
        AllApps.message || "Error in loading layout , try again later."
      );
    }
  }, [AllApps.status]);

  const handleCreateInitialOptions = (data) => {
    const selectedAppsNames = data.map((app) => {
      return app.app_meta.app_name;
    });
    const AllAppsNames = AllApps.data.map((app) => {
      return app.app_meta.app_name;
    });
    const nonSelectedApps = AllAppsNames.filter(
      (app_name) => !selectedAppsNames.includes(app_name)
    );
    // const appOptionsNames = nonSelectedApps.length
    //   ? nonSelectedApps.map((app) => {
    //       return app.app_meta.app_name;
    //     })
    //   : [];
    // setAddAppArr(appOptionsNames)
    return nonSelectedApps;
  };

  useEffect(() => {
    if (AllApps.status === "success") {
      const optArr = handleCreateInitialOptions(renderAppData);
      setAddAppArr(optArr);
    }
  }, [AllApps.status]);

  // Defining required side effects
  useEffect(() => {
    // Defining required function
    const handleWindowSize = () => {
      // Should come from config
      const appCardWidth = 227;
      const appRowGridGap = 10;

      // Getting slice count based on width
      const appRowEl = document.getElementById(containerId.current);
      if (appRowEl) {
        const appRowContentWidth = getElementContentWidth(appRowEl);
        const newSliceCount = Math.floor(
          appRowContentWidth / (appCardWidth + appRowGridGap)
        );

        // Setting slice count
        setSliceCount(newSliceCount);
      }
    };

    // Do this only when singleRowFlag is true
    if (!allExpand.expanded) {
      // Call the function when component mounts
      handleWindowSize();

      // Add event listener to window resize
      window.addEventListener("resize", handleWindowSize);
    }

    return () => {
      // Remove event listener to window resize
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);

  function OnLayoutChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (targetId) {
      const result = move(
        items[sourceId],
        items[targetId],
        sourceIndex,
        targetIndex
      );
      return setItems({
        ...items,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }
    if (items[sourceId] === undefined) {
      return;
    }
    const result = swap(items[sourceId], sourceIndex, targetIndex);
    return setItems({
      ...items,
      [sourceId]: result,
    });
    // }
  }

  // Update the temporary layout
  useEffect(() => {
    if (renderAppData !== undefined) {
      let newRenderAppData = [...items.left];
      setTempLayout(newRenderAppData);
    }
  }, [items.left]);

  const HandleAddApp = (appList) => {
    // remove this APP-name from the options list
    const newAddAppArr = addAppArr.filter(
      (app_name) => !appList.includes(app_name)
    );
    setAddAppArr(newAddAppArr);

    // add this app object in the ITEMS list
    const NewlyAddedApps = AllApps.data.filter((app) =>
      appList.includes(app.app_meta.app_name)
    );
    setItems({ left: [...NewlyAddedApps, ...items.left] });
    setAddApp([]);
    setTempArr([]);
  };

  const dropZoneHeight =
    items.left.length > sliceCount ? items.left.length / sliceCount : 1;
  const cardHeight = user.ui_preferences.grid_size === "large_grid" ? 250 : 140;
  const dropZoneHeightRounded = Math.ceil(dropZoneHeight) * cardHeight;
  const textHeight = dropZoneHeightRounded.toString();
  const textHeightinPX = textHeight + "px";

  const HandleAddTempApp = (app_NAME) => {
    let newTempArr = [];
    if (tempArr.includes(app_NAME[0])) {
      newTempArr = tempArr.filter((app) => app !== app_NAME[0]);
    } else {
      newTempArr = [...tempArr, app_NAME[0]];
    }
    setTempArr(newTempArr);
  };


  return (
    <div className="main-container">
      {AllApps.status === "loading" ? (
        <SkeletonLoadScreen />
      ) : (
        <div
          style={{
            overflowY: "auto",
            height: "calc(100vh - 129px)",
            marginTop: "12px",
          }}
          className="applications-container"
        >
          <div
            style={{
              position: "absolute",
              top: "70px",
              width: "300px",
              marginLeft: "20px",
            }}
          >
            <StyledAutoComplete
              multiple
              disableCloseOnSelect
              value={addApp || []}
              options={addAppArr || []}
              getOptionLabel={(option) => option}
              noOptionsText={
                addAppArr.length === 0
                  ? "You have added all applications"
                  : "No Options"
              }
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onClose={() => {
                HandleAddApp(tempArr);
              }}
              onChange={(e, newValue) => {
                HandleAddTempApp(newValue);
              }}
              renderOption={(option, { selected }) => {
                return (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      size="small"
                      style={{
                        marginRight: 5,
                        padding: "0px",
                      }}
                      sx={{
                        "&.Mui-checked": {
                          color: "#FC007F",
                        },
                      }}
                      checked={tempArr.includes(option)}
                    />
                    <div
                      style={{
                        color:
                          user.ui_preferences.theme === "dark"
                            ? "#fff"
                            : "#282828",
                      }}
                    >
                      {option}
                    </div>
                  </React.Fragment>
                );
              }}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  className={`${classes1.typographyStyle} ${classes1.SecFilUnActive}`}
                  fullWidth
                  placeholder={`Search Apps`}
                />
              )}
              style={{
                margin: "10px 0px",
                width: "250px",
              }}
            />
          </div>
          <div className="aide-root">
            <div className="applications-container">
              {/* {renderAppData?.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      marginTop: "150px",
                      height: "300px",
                    }}
                    src={no_apps_found}
                    alt="No Apps Found"
                  ></img>
                </div>
              ) : ( */}
              <>
                <div id={containerId.current}>
                  <GridContextProvider onChange={OnLayoutChange}>
                    <GridDropZone
                      className="dropzone left"
                      id="left"
                      boxesPerRow={sliceCount}
                      rowHeight={170}
                      style={{ height: textHeightinPX }}
                    >
                      {tempLayout?.length !== 0 &&
                        items.left.map((row, index) => (
                          <GridItem key={row.app_id}>
                            <EditCard
                              disabled={true}
                              key={row.app_id}
                              appIndex={index}
                              row={row}
                              setItems={setItems}
                              items={items}
                              setAddAppArr={setAddAppArr}
                              gridList={items.left}
                              optionsList={addAppArr}
                              tempLayout={tempLayout}
                            />
                          </GridItem>
                        ))}
                    </GridDropZone>
                  </GridContextProvider>
                </div>
              </>
              {/* )} */}
            </div>
          </div>{" "}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  AllApps: state.AppsData.allApps,
  user: state.user,
});

export default connect(mapStateToProps)(EditLayout);
