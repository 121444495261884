import React, { useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Box, IconButton } from "@mui/material";
import landingImg from "../../../../../../assets/images/Dashboard-images/project-management.svg";
import delete_icon from "../../../../../../assets/images/delete_icon.svg";
import EditIcon from "@mui/icons-material/Edit";
import { config } from "../../../../../config/config";
import { getEditorState, specialFeilds } from "../../../../../utils/utils";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

const useStyles = (customColor) =>
    makeStyles((theme) => ({
        cardContainer: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "3%",
            padding: ".5%",
            height: "calc(80vh - 100px)",
            width: "100%",
            overflow: "auto",
        },
        temp4Card: {
            flex: "0 0 31.33%",
            boxSizing: "border-box",
            // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
            boxShadow: config.cssValues.cardShadow,
            borderRadius: "5px",
            textAlign: "center",
            // background: theme.palette.mode === "dark" ? "#282828" : "#ffffff",
            backgroundColor: customColor
                ? customColor
                : theme.palette.mode === "dark"
                ? "#282828"
                : "#fff",
            color: theme.palette.mode === "dark" ? "#fff" : "#282828",
            // border:
            //     theme.palette.mode === "dark"
            //         ? "transparent"
            //         : "0.2px solid #e7e9ee",
            position: "relative",
            // height: "420px",
            height: "calc(100% - 100px)",
            padding: "30px 15px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
            "& .deleteLogo": {
                display: "none",
            },
            "& .editBtn": {
                display: "none",
            },
            "&:hover": {
                "& .deleteLogo": {
                    display: "block",
                },
                "& .editBtn": {
                    display: "inherit",
                },
            },
            "& .normalIcons": {
                color: "#FC007F",
            },
            "& p": {
                textAlign: "center !important",
                margin: 0,
            },
        },
        temp4Title: {
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: "35px",
        },
        temp4Img: {
            height: "100px",
            width: "100px",
        },
        temp4Desc: {
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "23px",
            // whiteSpace: "pre-wrap",
            overflow: "hidden",
            overflowWrap: "anywhere",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 6,
            "-webkit-box-orient": "vertical",
            textAlign: "center",
            "& p": {
                whiteSpace: "pre-wrap",
            },
        },
        additionalImagesContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "10px",
            "& span": {
                height: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            "& img": {
                maxHeight: "40px",
                maxWidth: "50px",
            },
        },
        deleteIcon: {
            position: "absolute",
            left: " -6px",
            top: "-6px",
            height: "15px",
        },
    }));

const CardComponent = ({
    handleNavigation,
    item,
    isFieldsEditable,
    handleCustomDelete,
    handleCustomEdit,
}) => {
    const classes = useStyles(item.card_bg_color)();
    const refName = useRef();
    const refDesc = useRef();

    useEffect(() => {
        if (specialFeilds.includes(item.sub_category_type)) {
            const name = getEditorState(item.sub_category_name);
            const desc = getEditorState(item.sub_category_description);

            const reqHtmlName = draftToHtml(
                convertToRaw(name.getCurrentContent())
            );
            const reqHtmlDesc = draftToHtml(
                convertToRaw(desc.getCurrentContent())
            );
            if (refName?.current) {
                refName.current.innerHTML = reqHtmlName
                    .replace(/&amp;/g, "&")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">")
                    .replace(/&quot;/g, '"')
                    .replace(/&apos;/g, "'");
            }
            if (refDesc?.current) {
                refDesc.current.innerHTML = reqHtmlDesc
                    .replace(/&amp;/g, "&")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">")
                    .replace(/&quot;/g, '"')
                    .replace(/&apos;/g, "'")
                    .replaceAll("\\n", "\n");
            }
        }
    }, [
        item.sub_category_description,
        item.sub_category_id,
        item.sub_category_name,
        item.sub_category_type,
    ]);
    return (
        <div
            className={classes.temp4Card}
            onClick={() => handleNavigation(item)}
        >
            {!isFieldsEditable && (
                <>
                    <img
                        src={delete_icon}
                        alt="delete"
                        className={`${classes.deleteIcon} deleteLogo`}
                        onClick={(e) => handleCustomDelete(e, item)}
                    />
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                        }}
                        onClick={(e) => handleCustomEdit(e, item)}
                        title="Edit"
                        className="editBtn"
                    >
                        <EditIcon sx={{ fontSize: "14px" }} />
                    </IconButton>
                </>
            )}
            <div>
                <div className={classes.temp4Title} ref={refName}>
                    {/* {item.sub_category_name} */}
                </div>
                {item.app_logo_type === "google_font" ? (
                    <span
                        class="material-icons-outlined normalIcons"
                        style={{
                            fontSize: "130px",
                        }}
                    >
                        {item.sub_category_icon}
                    </span>
                ) : (
                    <img
                        src={item.sub_category_icon || landingImg}
                        alt=""
                        className={classes.temp4Img}
                    />
                )}
            </div>
            <div className={classes.additionalImagesContainer}>
                {(item.additional_images || []).map((icon) => {
                    return (
                        <React.Fragment key={icon.blob_name}>
                            {icon.app_logo_type === "google_font" ? (
                                <span class="material-icons-outlined normalIcons">
                                    {icon.sub_category_icon}
                                </span>
                            ) : (
                                <img
                                    src={icon.sub_category_icon || landingImg}
                                    alt=""
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
            <div className={classes.temp4Desc} ref={refDesc}>
                {/* {(item.sub_category_description || "").replaceAll("\\n", "\n")} */}
            </div>
        </div>
    );
};
const LandingTemplate4 = ({
    data = [],
    handleNavigation,
    theme,
    handleEdit,
    handleDelete,
    isFieldsEditable,
}) => {
    const classes = useStyles()();

    const handleCustomEdit = (e, card) => {
        e.stopPropagation();
        handleEdit(card);
    };
    const handleCustomDelete = (e, card) => {
        e.stopPropagation();
        handleDelete(card);
    };

    const getHeaderData = useMemo(
        () => data.filter((item) => item.sub_category_type === "header"),
        [data]
    );

    return (
        <Box
            className={classes.cardContainer}
            sx={{
                overflow: "auto",
                height: `calc(80vh - 35px - ${
                    getHeaderData.length > 0 ? getHeaderData.length * 60 : 0
                }px)`,
                px: "10px",
            }}
        >
            {data.map((item) => {
                if (item.sub_category_type === "header") return null;
                return (
                    <CardComponent
                        key={item.sub_category_id}
                        handleNavigation={handleNavigation}
                        item={item}
                        isFieldsEditable={isFieldsEditable}
                        handleCustomDelete={handleCustomDelete}
                        handleCustomEdit={handleCustomEdit}
                    />
                );
            })}
            <div style={{ flex: 1 }}></div>
        </Box>
    );
};
const mapStateToProps = (state) => ({
    theme: state.user.ui_preferences?.theme,
});
export default connect(mapStateToProps)(LandingTemplate4);
