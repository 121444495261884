export const BASE_URL = "/api";
export const V1_URL = "/v1";
export const FAV_APP_URL = "/apps?type=favourites";
export const GET_ALL_APPS = "/apps?type=access";
export const GET_MANAGE_APPS = "/apps";
export const GET_RECENT_APPS = "/apps";
export const GET_COUNTRY_BRANDS = "/countrybrands";

export const REGISTER_APP_TEMPLATES =
    "https://run.mocky.io/v3/890988ec-1781-44c0-b400-a02d74247a5e";
