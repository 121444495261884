import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Drawer } from "@mui/material";
import { makeStyles } from "@material-ui/core";
// Import action creators
import { getRecentApps } from "../../redux/actions";
import { toast } from "react-toastify";
// Import Components
import { SkeletonRecentApp } from "../ImageLoader/SkeletonLoadScreen";
import NewAppCard from "../AppCard/NewAppCard";

const useStyles = makeStyles((theme) => ({
  appsContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gridGap: "10px",
    gap: "10px",
    overflowY: "scroll",
    padding: "10px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    padding: "10px",
    height: "100%",
  },
  heading: {
    color: "#fc007f",
    fontSize: "15px",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 400,
    lineHeight: "23px",
    alignContent: "center",
  },
  messageText: {
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    marginTop: "20px",
    fontSize: "13px",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 400,
    lineHeight: "23px",
    alignContent: "center",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    left: "10px",
    cursor: "pointer",
    "& .material-icons-outlined": {
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      fontSize: "20px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  resetBtn: {
    color: "#282828",
    backgroundColor: "#d3d4d7",
    marginRight: "10px",
    flex: 1,
  },
  saveBtn: {
    flex: 1,
    "&:disabled": {
      opacity: ".5",
    },
  },
}));
const RecentDrawer = ({ open, handleCloseRecent, recentApps, user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getPayload = () => {
    return {
      type: "recentlyused",
      role: user.role,
      email: user.preferred_username,
    };
  };
  // get all the apps
  useEffect(() => {
    if (open && recentApps.status !== "loading") {
      dispatch(getRecentApps(getPayload()));
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (recentApps.status === "error") {
      handleCloseRecent();
      toast.error("Oops ! Couldn't load recently used apps.");
    }
  }, [recentApps.status]);

  return (
    <Drawer
      anchor="left"
      open={open}
      variant="persistent"
      sx={(theme) => ({
        "& .MuiDrawer-paper": {
          width: "272px",
          height: "calc(100vh - 25px)",
          borderLeft: "none",
          left: "70px",
          overflow: "hidden",
          backgroundColor: theme.palette.mode === "dark" ? "#000" : "#f7f7f7",
        },
      })}
    >
      <div className={classes.container}>
        <div>
          <div className={classes.closeIcon}>
            <span
              style={{ fontSize: "14px" }}
              class="material-icons-outlined GA-close-drawer"
              onClick={handleCloseRecent}
            >
              close
            </span>
          </div>
          <div className={classes.heading}>Recently Used</div>
          {recentApps.status === "loading" && <SkeletonRecentApp />}
          {recentApps.status === "success" && recentApps.data.length === 0 && (
            <div className={classes.messageText}>
              {recentApps.status.statusMessage ||
                "No recent applications found."}
            </div>
          )}
        </div>
        {recentApps.status === "success" && recentApps.data.length > 0 && (
          <div className={classes.appsContainer}>
            {recentApps.data.slice(0, 10).map((item, index) => {
              return (
                <NewAppCard
                  row={item}
                  key={item.app_id}
                  appIndex={index}
                  recentApps={true}
                />
              );
            })}
          </div>
        )}
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  AppsData: state.AppsData,
  recentApps: state.AppsData.recentApps,
});

export default connect(mapStateToProps)(RecentDrawer);
