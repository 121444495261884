// Import required libraies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

// Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import CovidFaq from "./CovidFaq";
import faqData from "../../../assets/data/Faq.json";
import FaqAccordion from "./FaqAccordion";
import { config } from "../../config/config";
import docimglight from "../../../assets/images/faq-doc.svg";
import docimgdark from "../../../assets/images/faq-doc-white.svg";

// Import utils/data
import { UpdatePageView } from "../../utils/utils";

// Import styles
import "./Faq.scss";

const useStyles = makeStyles((theme) => ({
  outer_container: {
    minWidth: "834px",
    maxWidth: "1730px",
    alignItems: "center",
    width: "91% !important",
    margin: "0px auto",
  },
}));

function Faq(props) {
  const { match, user } = props;
  const [expanded, setExpanded] = useState([]);

  const classes = useStyles();

  const handleChange = (panel) => (event, isExpanded) => {
    isExpanded
      ? setExpanded((oldArray) => [...oldArray, panel])
      : panel.length === 1
      ? setExpanded((OldArray) => OldArray.filter((item) => item[0] != panel))
      : setExpanded((OldArray) => OldArray.filter((item) => item != panel));
  };

  const handleDocButton = () => {
    window.open("https://rbcom.sharepoint.com/sites/DataAtReckitt");
  };

  useEffect(() => {
    UpdatePageView("faqs");
  }, []);

  return (
    <LayoutTopSideBottom match={match} selected="/faqs">
      <Container
        disableGutters
        maxWidth={false}
        className={classes.outer_container}
      >
        <div className="faq-data-quote">
          <div
            className={`faq-data-quote-container  faq-container-${user.ui_preferences.theme} faq-data-quote-container-${user.ui_preferences.theme}`}
          >
            {config.messages.FaqQuote}
          </div>
        </div>
      </Container>

      <Container
        disableGutters
        maxWidth={false}
        className={classes.outer_container}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <div
          className={`faq-container1  faq-container-${user.ui_preferences.theme}`}
        >
          <div>For more queries reach us at : Data@reckitt.com</div>
          <button
            className={`faq-container1-btn  faq-container-${user.ui_preferences.theme} faq-container1-btn-${user.ui_preferences.theme}` }
            onClick={handleDocButton}
          >
            <div>Documentation</div>
            {user.ui_preferences.theme === "dark" ? (
              <img className="doc-img" src={docimgdark} />
            ) : (
              <img className="doc-img-light" src={docimglight} />
            )}
          </button>
        </div>

        <div
          className="faq-container2"
          style={{ overflowY: "auto", height: "calc(100vh - 300px)" }}
        >
          {faqData?.map((item) => (
            <FaqAccordion
              expanded={expanded}
              setExpanded={setExpanded}
              handleChange={handleChange}
              data={item}
              noq={item.content.length}
              theme={user.ui_preferences.theme}
            />
          ))}
        </div>
      </Container>
    </LayoutTopSideBottom>
  );
}

Faq.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Faq);
