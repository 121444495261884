import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import LayoutNone from "../../../../layouts/LayoutNone/LayoutNone";
import {
  getNormalText,
  getEditorState,
  specialFeilds,
} from "../../../../utils/utils";

const LayoutTextCard = (props) => {
  const {
    card_width = "200px",
    card_height = "100px",
    textContent = "You can create a new album with the content just uploaded. You must create an account or sign in to save this content into your account. You can create a new album with the content just uploaded. You must create an account or sign in to save this content into your account. You can create a new album with the content just uploaded. You must create an account or sign in to save this content into your account. You can create a new album with the content just uploaded. You must create an account or sign in to save this content into your account. You can create a new album with the content just uploaded. You must create an account or sign in to save this content into your account. You can create a new album with the content just uploaded. You must create an account or sign in to save this content into your account. You can create a new album with the content just uploaded. You must create an account or sign in to save this content into your account. You can create a new album with the content just uploaded. You must create an account or sign in to save this content into your account. You can create a new album with the content just uploaded. You must create an account or sign in to save this content into your account.",
    handleEditSubCat,
    textDetails = {},
    editable_layout,
    theme,
    template_no,
  } = props;
  const path_has_register =
    window.location.pathname.split("/")[1] === "register";
  const refDesc = useRef(null);
  useEffect(() => {
    const desc = getEditorState(textDetails.sub_category_description);
    const reqHtmlDesc = draftToHtml(convertToRaw(desc.getCurrentContent()));
    if (refDesc?.current) {
      refDesc.current.innerHTML = reqHtmlDesc
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&apos;/g, "'")
        .replaceAll("\\n", "\n");
    }
  }, [textDetails.sub_category_description]);

  return (
    <div
      onClick={
        path_has_register
          ? editable_layout
            ? () => {
                handleEditSubCat(textDetails);
              }
            : null
          : null
        // editable_layout
        //   ? () => {
        //       handleEditSubCat(textDetails);
        //     }
        //   : () => {}
      }
      style={{
        width: card_width, // TODO: we have to convert the width to % and then use it.
        height: card_height,
        fontSize: "0.94vw",
        borderRadius: "6px",
        border: "1px solid #D7DADF",
        background: theme === "light" ? "#FFF" : "#000",
        marginBottom: "10px",
      }}
    >
      <p
        style={{
          overflow: "auto",
          padding: "0px 20px",
          margin: "0px",
          height: template_no !== 9 ? "100px" : "95%",
          textAlign: "left",
        }}
        ref={refDesc}
      >
        {textDetails.sub_category_description || textContent}
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  editable_layout: state.registerNewApp.statusData.editLayout,
  theme: state.user.ui_preferences?.theme,
  template_no: state.appTemplate.appTemplateState.template_no,
});

export default connect(mapStateToProps)(LayoutTextCard);
