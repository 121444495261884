import React from "react";
import { Badge, IconButton } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { connect } from "react-redux";
import { StyledInputBase } from "./Style";
import "./search.scss";

const SearchBar = ({
  user,
  handleClear,
  handleFilterDrawer,
  searchVal = "",
  search_columns = [],
  handleChange,
  disabled = false,
  inputKey = "",
}) => {
  return (
    <div className={`new-theme-search-container ${user.ui_preferences.theme}`}>
      <StyledInputBase
        id="GA-search-apps"
        placeholder="Search Application"
        inputProps={{ "aria-label": "search" }}
        onChange={handleChange}
        value={searchVal}
        fullWidth
        disabled={disabled}
        key={inputKey}
      />
      {searchVal ? (
        <IconButton onClick={handleClear}>
          <ClearIcon
            sx={{
              color: user.ui_preferences.theme === "light" ? "#1E212F" : "#fff",
            }}
          />
        </IconButton>
      ) : (
        <IconButton>
          <SearchIcon
            sx={{
              color: user.ui_preferences.theme === "light" ? "#1E212F" : "#fff",
            }}
          />
        </IconButton>
      )}
      <IconButton aria-label="Filters" onClick={handleFilterDrawer}>
        {search_columns.length ? (
          <Badge badgeContent={search_columns.length} color="primary">
            <TuneIcon sx={{ color: "#f7007f" }} className="GA-open-filters" />
          </Badge>
        ) : (
          <TuneIcon
            sx={{
              color: user.ui_preferences.theme === "light" ? "#1E212F" : "#fff",
            }}
            className="GA-open-filters"
          />
        )}
      </IconButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  AppsData: state.AppsData,
  templates: state.register.registerTemplate,
});
export default connect(mapStateToProps)(SearchBar);
