import { Container } from "@mui/material";
import React from "react";

const RegisterBodyContainer = ({ children, height, overflow, ...rest }) => {
  return (
    <Container
      sx={(theme) => ({
        textAlign: "start",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // flex: 1,
        zIndex: 10,
        overflowY: overflow || "auto",
        height: height || `calc(100vh - 300px)`,
        ...rest,
        "&.MuiContainer-root": {
          maxWidth: "none",
        },
      })}
    >
      {children}
    </Container>
  );
};

export default RegisterBodyContainer;
