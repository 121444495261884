import React from "react";
import { connect } from "react-redux";

const BreadCrumb = (props) => {
  const { handleClickBreadCrumb, breadcrumbArr = [], user } = props;

  return (
    <div style={{ display: "flex" }}>
      {breadcrumbArr.map((item, ind) => {
        if (ind === 0) {
          return (
            <div
              key={item.textID}
              onClick={
                breadcrumbArr.length > 1
                  ? () => {
                      handleClickBreadCrumb(item, ind);
                    }
                  : () => {}
              }
              style={{
                color:
                  user.ui_preferences.theme === "dark" ? "#fff" : "#282828",
                cursor: breadcrumbArr.length > 1 ? "pointer" : "default",
                fontWeight: "bold",
              }}
            >
              {item.textName + " "}
            </div>
          );
        } else if (ind === breadcrumbArr.length - 1) {
          return (
            <div
              style={{
                color:
                  user.ui_preferences.theme === "dark" ? "#aab4bd" : "#FC007F",
                cursor: "default",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
              key={item.textID}
            >
              <span
                style={{ fontSize: "18px" }}
                class="material-icons-outlined"
              >
                chevron_right
              </span>
              {item.textName}
            </div>
          );
        } else {
          return (
            <div
              key={item.textID}
              style={{
                color:
                  user.ui_preferences.theme === "dark" ? "#fff" : "#282828",
                cursor: "pointer",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                handleClickBreadCrumb(item);
              }}
            >
              <span
                style={{ fontSize: "18px" }}
                class="material-icons-outlined"
              >
                chevron_right
              </span>
              {item.textName}
            </div>
          );
        }
      })}
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(BreadCrumb);
