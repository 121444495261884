import React from "react";
import { Grid } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import InputTextBox from "../InputTextBox";
import FormLabelComponent from "../../../../components/FormLabelComponent";
import AutoCompleteComponent from "../../../../components/AutoCompletes/AutoCompleteComponent";
import { set_help_center_state } from "../../../../redux/actions";

const SelectAppName = ({ helpCenterState, keyField, options = [] }) => {
    const dispatch = useDispatch();
    const { appList, formData } = helpCenterState;
    const value = formData[keyField] || {};
    const { application_name = [], other_application = [] } = options[1] || {};

    const data = {
        key: "other_application",
        required: true,
        label: other_application[0]?.["label"],
        toolTipText: other_application[0]?.["toolTipText"],
    };
    const handleChange = (k, v) => {
        const reqData = {
            ...formData,
            [keyField]: { ...(formData[keyField] || {}), [k]: v },
        };
        dispatch(set_help_center_state({ formData: reqData }));
    };

    return (
        <>
            <Grid item xs={4}>
                <FormLabelComponent
                    required={true}
                    label={application_name[0]?.["label"]}
                    toolTipText={application_name[0]?.["toolTipText"]}
                />
                <AutoCompleteComponent
                    label="Choose Application Name"
                    handleChange={handleChange}
                    options={appList}
                    keyField="application_name"
                    value={value.application_name || ""}
                />
            </Grid>
            {value.application_name === "Other" && (
                <InputTextBox
                    data={data}
                    handleChange={handleChange}
                    value={value.other_application || ""}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    helpCenterState: state.helpCenter.helpCenterState,
});

export default connect(mapStateToProps)(SelectAppName);
