import React, { useState } from "react";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { TextField, withStyles, Box } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@mui/material/Checkbox";

import { StyledAutoComplete, useStyles } from "../../style";
import "../../index.scss";
import { connect } from "react-redux";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" htmlColor="#FF007F" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CssTextField = withStyles((theme) => ({
  root: {
    // width: "98%",
    color:
      theme.palette.mode === "dark" ? theme.palette.white : theme.palette.dark,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.light
        : theme.palette.white.main,
    padding: "6px 8px",
    border:
      theme.palette.mode === "dark" ? "1px solid #757575" : "1px solid #E7E9EE",
    borderRadius: "3px",
    "& .MuiInputBase-root": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : theme.palette.dark.light,
    },
    "& label.Mui-focused": {},
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(66, 89, 108, 0.25)",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(66, 89, 108, 0.25)",
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  },
}))(TextField);

const AutoWithCheckBox = (props) => {
  const {
    fieldkey,
    isFieldsEditable,
    isInputAlreadyExist,
    isInputExistInOptions,
    selectChange,
    selectedOptions = [],
    options,
    label,
    theme,
    hasError,
  } = props;
  const classes = useStyles();
  const filter = createFilterOptions();
  const [isMyInputFocused, setIsMyInputFocused] = useState(false);

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      // pb="12px"
      className={
        selectedOptions.length && !isMyInputFocused
          ? `${theme}-autoCompeteContainer`
          : ""
      }
    >
      <StyledAutoComplete
        id="share-select-user"
        size="small"
        disableCloseOnSelect
        multiple
        style={{
          fontFamily: "Energy !important",
          width: "100%",
          marginRight: "20px",
        }}
        className={classes.typographyStyle}
        freeSolo={fieldkey === "tags" || fieldkey === "associated_datasets"}
        options={options}
        getOptionLabel={(option) => {
          if (option && option.title) {
            return option.title;
          }
          if (option && option.inputValue) {
            return option.inputValue;
          }
          return option || "";
        }}
        value={[]}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (
            (fieldkey === "tags" || fieldkey === "associated_datasets") &&
            !isFieldsEditable
          ) {
            if (
              params.inputValue !== "" &&
              !isInputAlreadyExist(params.inputValue) &&
              fieldkey === "tags"
            ) {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            } else if (
              params.inputValue !== "" &&
              !isInputAlreadyExist(params.inputValue) &&
              !isInputExistInOptions(params.inputValue)
            ) {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }
          }
          return filtered;
        }}
        onChange={(event, newValue) => {
          if (isFieldsEditable || !newValue.length) return;
          if (newValue[0].inputValue) {
            // Create a new value from the user input
            selectChange(newValue[0].inputValue);
          } else {
            selectChange(newValue[0]);
          }
        }}
        noOptionsText="No option"
        renderOption={(option, { selected }) => {
          return (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.typographyStyle}
                size="small"
                style={{
                  marginRight: 5,
                  padding: "0px",
                }}
                sx={{
                  "&.Mui-checked": {
                    color: "#fc007f",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "1.2rem",
                  },
                }}
                checked={(selectedOptions || []).includes(option)}
              />
              {option?.title || option || ""}
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="standard"
            fullWidth
            size="small"
            placeholder={
              (selectedOptions || []).length && !isMyInputFocused
                ? `${selectedOptions.slice(0, 2).join()} ${
                    selectedOptions.length > 2
                      ? ` +${selectedOptions.length - 2}`
                      : ""
                  }`
                : `Please type ${label}`
            }
            className={`${classes.typographyStyle} ${
              fieldkey === "tags" || fieldkey === "associated_datasets"
                ? classes.tagsInput
                : ""
            } ${
              (selectedOptions || []).length && !isMyInputFocused
                ? `custom_placeholder ${theme}`
                : ""
            }
            ${hasError ? "error-border":""}`}
            onBlur={() => setIsMyInputFocused(false)}
            onFocus={() => setIsMyInputFocused(true)}
          />
        )}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(AutoWithCheckBox);
