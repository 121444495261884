import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import PropTypes from "prop-types";
import Plotly from "plotly.js-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import { v4 } from "uuid";

const Plot = createPlotlyComponent(Plotly);

const StackChart = ({ graphData, theme, monthStatus }) => {
  const { range, stackData } = graphData;
  const [data, setData] = useState([]);
  const [xAxisLabels, setXAxisLabels] = useState([]);

  useEffect(() => {
    const reqData = [];
    const width = monthStatus ? 30 * 24 * 50 * 60000 : 24 * 50 * 60000;
    const allLabels = stackData.reduce(
      (uniqueLabels, item) =>
        uniqueLabels.concat(
          item.labels.filter((label) => !uniqueLabels.includes(label))
        ),
      []
    );

    setXAxisLabels(allLabels);
    stackData.forEach((item) => {
      reqData.push({
        x: item.labels,
        y: item.daily_count,
        type: "bar",
        line: { color: "#FC007F" },
        marker: {
          size: 5,
        },
        name: item.name,
        width,
        bargap: 0.9,
        hovertemplate: monthStatus
          ? `<b>Date</b>: %{x|%b %Y}<br><b>Count</b>: %{y}<br><b>User</b>:${item.name}<extra></extra>`
          : `<b>Date</b>: %{x}<br><b>Count</b>: %{y}<br><b>User</b>:${item.name}<extra></extra>`,
      });
    });
    setData(reqData);
  }, [stackData]);

  const layout = {
    barmode: "stack",
    offset: [0, 5],
    hoverlabel: {
      // bgcolor: "rgba(0,0,0,0.87)",
      // color: "#fff",
      font: { size: 11 },
    },
    plot_bgcolor: theme === "dark" ? "#282828" : "#fff",
    paper_bgcolor: theme === "dark" ? "#282828" : "#fff",
    font: 11,
    color: "#282828",
    family: "Energy",
    showlegend: false,
    margin: {
      t: 50,
    },
    yaxis: {
      // range: yRange,
      title: {
        text: "Views",
        font: {
          color: theme === "dark" ? "#DDDDDD" : "#8d9ca6",
          family: "Energy",
        },
      },
      // fixedrange: true,
      gridcolor: "#ddd",
      linecolor: "#DDDDDD",
      linewidth: 2,
      // showticksuffix: "all",
      // ticksuffix: "%",
      showline: true,
      showgrid: false,
      ticks: "outside",
      tickcolor: "#DDDDDD",
      tickwidth: 2,
      ticklen: 8,
      tickfont: {
        family: "Energy",
        size: 11,
        color: theme === "dark" ? "#DDDDDD" : "#282828",
        fontWeight: 500,
      },
    },
    xaxis: {
      range,
      // tickmode: "auto",
      title: {
        text: "Time Range",
        font: {
          color: theme === "dark" ? "#DDDDDD" : "#8d9ca6",
          family: "Energy",
        },
      },
      type: "date",
      // showline: true,
      linecolor: "#DDDDDD",
      linewidth: 2,
      // fixedrange: true,
      showgrid: false,
      // showline: false,
      showline: theme === "dark",
      ticks: "outside",
      tickcolor: "#DDDDDD",
      tickwidth: 2,
      ticklen: 8,
      tickfont: {
        family: "Energy",
        size: 11,
        color: theme === "dark" ? "#DDDDDD" : "#282828",
        fontWeight: 500,
      },
      tickvals: xAxisLabels,
      tickformat: monthStatus ? "%b %Y" : null,
    },
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Plot
        data={data}
        key={v4()}
        layout={layout}
        config={{
          displayModeBar: true,
          responsive: true,
          displaylogo: false,
          modeBarButtonsToRemove: ["lasso2d", "select2d"],
        }}
        style={{
          height: "100%",
          fontFamily: "Energy",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "18px",
          cursor: "default",
        }}
      />
    </div>
  );
};

StackChart.propTypes = {
  graphData: PropTypes.any.isRequired,
};

export default StackChart;
