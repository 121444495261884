import React, { useEffect, useState } from "react";
import { TableCell } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { IconButton, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { StyledInputBase } from "../NewUserTable/style";

import sortIcon from "../../../assets/images/sort-icon.svg";
import selectedSortIcon from "../../../assets/images/selected-sort-icon.svg";

import { useStylesfoUM } from "./style";

function UsageMetricsTableHeader(props) {
  const {
    column,
    showFilter,
    orderBy,
    filter,
    handleFilter,
    handleChange,
    handleSort,
    cancelSearch,
    loading,
    user,
  } = props;

  const classes = useStylesfoUM();
  return (
    <>
      {column.id === "app_name" ? (
        <TableCell
          style={{
            minWidth: column.minWidth,
            zIndex: 3,
          }}
          class={`${classes.tableHeadCell} ${classes.stickyCellAppName}`}
        >
          <Box
            display="flex"
            alignItems="left"
            sx={{ flexDirection: "column" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ whiteSpace: "nowrap" }}>{column.label}</div>
              <span
                class="material-icons-outlined GA-filter-btn"
                id={column.id}
                style={{
                  fontSize: "16px",
                  marginLeft: "10px",
                  color: showFilter ? "#fc007f" : "#000",
                }}
                onClick={(e) => handleFilter(e, column.id)}
              >
                {showFilter && column.id === "app_name"
                  ? "close"
                  : "filter_alt"}
              </span>
              {!showFilter && (
                <img
                  src={column.id === orderBy ? selectedSortIcon : sortIcon}
                  onClick={() => !loading && handleSort(column.id)}
                  style={{
                    cursor: loading ? "not-allowed" : "pointer",
                    opacity: loading ? 0.5 : 1,
                  }}
                />
              )}
            </div>
            {showFilter && (
              <>
                <div className={classes.search} style={{ marginLeft: "0px" }}>
                  <StyledInputBase
                    placeholder="Search App"
                    inputProps={{
                      "aria-label": "search",
                    }}
                    onChange={handleChange}
                    value={filter}
                    className={classes.inputField}
                  />
                  {filter ? (
                    <IconButton onClick={(e) => cancelSearch(e)}>
                      <ClearIcon
                        sx={{
                          color: "#282828",
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <SearchIcon
                        sx={{
                          color: "#282828",
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              </>
            )}
          </Box>
        </TableCell>
      ) : column.id === "total_views_last" ? (
        <TableCell
          style={{
            minWidth: column.minWidth,
          }}
          class={`${classes.tableHeadCell}`}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <div style={{ width: "66px" }}>{column.label}</div>
            <img
              src={column.id === orderBy ? selectedSortIcon : sortIcon}
              style={{
                opacity: loading ? 0.5 : 1,
                cursor: loading ? "not-allowed" : "pointer",
              }}
              onClick={() => !loading && handleSort(column.id)}
            />
          </Box>
        </TableCell>
      ) : column.type === "number" ? (
        <TableCell
          style={{
            minWidth: column.minWidth,
            zIndex:
              column.id === "total_views" || column.id === "app_rank" ? 3 : "",
          }}
          class={`${classes.tableHeadCell} ${
            column.id === "total_views"
              ? classes.stickyCellTotalViews
              : column.id === "app_rank"
              ? classes.stickyCellAppRank
              : ""
          }`}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <div>{column.label}</div>
            <img
              src={column.id === orderBy ? selectedSortIcon : sortIcon}
              style={{
                opacity: loading ? 0.5 : 1,
                cursor: loading ? "not-allowed" : "pointer",
              }}
              onClick={() => !loading && handleSort(column.id)}
            />
          </Box>
        </TableCell>
      ) : (
        <TableCell
          class={classes.tableHeadCell}
          style={{
            minWidth: column.minWidth,
            textAlign: "left",
          }}
        >
          {column.label}
        </TableCell>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UsageMetricsTableHeader);
