//Import required libraies
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";

//Import Custom Component
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Sidenav from "../../components/SideNavHome/Sidenav";

//Import Custom Hooks

//Import utils/data
import DRwatermark from "../../../assets/images/new-theme/watermark.svg";

//Import styles
import "./LayoutTopSideBottom.scss";
import { setCurrentTab } from "../../redux/actions";

function LayoutTopSideBottom(props) {
  let {
    user,
    children,
    match,
    selected = "",
    app_name,
    current_tab = "",
    buttonStatus = false,
    oldbreadCrumbsData,
    breadCrumbsData = {},
    setBreadCrumbsData,
    setSelectedTemplate = () => {},
    setOpen = () => {},
    setAlertMsg = () => {},
    setDraftApp = () => {},
    setAppHandle = () => {},
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentTab(current_tab || selected));
  }, [dispatch, selected, current_tab]);

  return (
    <div className={`new-page-container`}>
      <Sidenav match={match} selected={selected} />
      <section
        className={
          user.ui_preferences.theme === "light"
            ? "body-layout layout-background light"
            : "body-layout layout-background dark"
        }
      >
        <img
          style={{
            height: "400px",
            position: "absolute",
            right: "0",
            bottom: "0",
          }}
          src={DRwatermark}
          alt="watermark"
        />
        <Header
          match={match}
          app_name={app_name}
          buttonStatus={buttonStatus}
          breadCrumbsData={breadCrumbsData}
          oldbreadCrumbsData = {oldbreadCrumbsData}
          setBreadCrumbsData={setBreadCrumbsData}
          setSelectedTemplate={setSelectedTemplate}
          setOpen={setOpen}
          setAlertMsg={setAlertMsg}
          setDraftApp={setDraftApp}
          setAppHandle={setAppHandle}
        />
        <main className={`container-layout ${"main-collapsed"}`}>
          {children}
        </main>
        <Footer />
      </section>
    </div>
  );
}

LayoutTopSideBottom.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(LayoutTopSideBottom);
