import { Box } from "@mui/material";
import React from "react";

const UploadedFile = ({ file = {}, handleDelete, indexValue }) => {
    const src = URL.createObjectURL(file);
    const handleFileOpen = () => {
        window.open(src, "_blank");
    };
    return (
        <Box position="relative" bgcolor="#fff">
            {file.type === "application/pdf" ? (
                <>
                    <embed src={src} width="90px" height="100px" />
                    <div className="help-center-attachment-openfile">
                        <span
                            className="material-icons-outlined"
                            onClick={handleFileOpen}
                        >
                            open_in_new
                        </span>
                    </div>
                    <div className="help-center-attachment-footer">
                        <div className="help-center-attachment-filename">
                            {file.name}
                        </div>
                        <span
                            className="help-center-attachment-delete material-icons-outlined"
                            onClick={() => handleDelete(indexValue)}
                        >
                            delete
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <img
                        src={src}
                        alt={file.name}
                        className="help-center-upload-img"
                    />
                    <div className="help-center-attachment-openfile">
                        <span
                            className="material-icons-outlined"
                            onClick={handleFileOpen}
                        >
                            open_in_new
                        </span>
                    </div>
                    <div className="help-center-attachment-footer">
                        <div className="help-center-attachment-filename">
                            {file.name}
                        </div>
                        <span
                            className="help-center-attachment-delete material-icons-outlined"
                            onClick={() => handleDelete(indexValue)}
                        >
                            delete
                        </span>
                    </div>
                </>
            )}
        </Box>
    );
};

export default UploadedFile;
