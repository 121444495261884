import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { set_help_center_state } from "../../../redux/actions";
import FormLabelComponent from "../../../components/FormLabelComponent";

const Rating = ({ data, value = {}, helpCenterState }) => {
    const { key, label, required, options, toolTipText } = data;
    const { formData } = helpCenterState;
    const dispatch = useDispatch();
    const [extraData, setExtraData] = useState({});

    const handleChange = (k, v) => {
        let reqData = {};
        if (k === "rating") {
            reqData = { ...formData, [key]: { [k]: v } };
        } else {
            if ((formData[key][k] || []).includes(v)) {
                const filteredData = formData[key][k].filter(
                    (item) => item !== v
                );
                reqData = {
                    ...formData,
                    [key]: { ...formData[key], [k]: filteredData },
                };
            } else {
                reqData = {
                    ...formData,
                    [key]: {
                        ...formData[key],
                        [k]: [...(formData[key][k] || []), v],
                    },
                };
            }
        }
        dispatch(set_help_center_state({ formData: reqData }));
    };

    useEffect(() => {
        const reqData =
            options.find((item) => item.value === value.rating) || {};
        setExtraData(reqData);
    }, [options, value.rating]);

    return (
        <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", gap: 3 }}
        >
            <div>
                <FormLabelComponent
                    label={label}
                    required={required}
                    toolTipText={toolTipText}
                />
                <div className="help-center-rating-container">
                    {options.map((item) => {
                        return (
                            <div
                                className={`help-center-rating-btn ${
                                    item.value === value.rating
                                        ? "help-center-rating-btn-selected"
                                        : ""
                                }`}
                                key={item.value}
                                onClick={() =>
                                    handleChange("rating", item.value)
                                }
                            >
                                {item.value}
                            </div>
                        );
                    })}
                </div>
            </div>
            {value.rating && (
                <div>
                    <FormLabelComponent label={extraData.label} />
                    <div className="help-center-rating-container">
                        {extraData.options?.map((item) => {
                            return (
                                <div
                                    key={item}
                                    className={`help-center-rating-options ${
                                        value.desc?.includes(item)
                                            ? "help-center-rating-options-selected"
                                            : ""
                                    }`}
                                    onClick={() => handleChange("desc", item)}
                                >
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    helpCenterState: state.helpCenter.helpCenterState,
});
export default connect(mapStateToProps)(Rating);
