import React, { useEffect, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { connect } from "react-redux";
import { IconButton, Box } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import firstplacebadge from "../../../assets/images/1st-place-badge.svg";
import secondplacebadge from "../../../assets/images/2nd-place-badge.svg";
import thirdplacebadge from "../../../assets/images/3rd-place-badge.svg";
import top10badge from "../../../assets/images/top10-badge.svg";
import top20badge from "../../../assets/images/top20-badge.svg";

import { useStylesfoUM } from "./style";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#e6e9ee",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "11px",
    // fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

function UsageMetricsTableBody(props) {
  const { row, columns, HandleOpenApp, getFormattedDate, monthShort, user } =
    props;

  const getAppNameShort = (appName) => {
    return appName.length > 20 ? appName.slice(0, 20) + "..." : appName;
  };

  const classes = useStylesfoUM();
  return (
    <TableRow
      className={classes.tableRow}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.code}
      onClick={() => {
        HandleOpenApp(row);
      }}
    >
      {columns.map((column) => {
        const value = row[column.id];
        if (column.id === "app_rank") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={`${classes.tableRowCell} ${classes.stickyCellAppRank}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <p>
                {column.format && typeof value === "number"
                  ? column.format(value)
                  : value}
              </p>
              {value <= 20 && (
                <img
                  src={
                    value === 1
                      ? firstplacebadge
                      : value === 2
                      ? secondplacebadge
                      : value === 3
                      ? thirdplacebadge
                      : value <= 10
                      ? top10badge
                      : value <= 20
                      ? top20badge
                      : null
                  }
                  style={{ height: "24px", width: "72px" }}
                />
              )}
            </TableCell>
          );
        } else if (column.id === "app_name") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={`${classes.tableRowCell} ${classes.stickyCellAppName}`}
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <HtmlTooltip
                placement="top-start"
                title={
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {value}
                  </div>
                }
              >
                <p>
                  {column.format && typeof value === "number"
                    ? column.format(value)
                    : getAppNameShort(value)}
                </p>
              </HtmlTooltip>
            </TableCell>
          );
        } else if (column.id === "owners") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={classes.tableRowCell}
            >
              <div>
                {value[0].replace("@reckitt.com", "").replace("@rb.com", "")}
                {value.length > 1 && (
                  <HtmlTooltip
                    placement="top-start"
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {value.slice(1, value.length).map((person, index) => (
                          <p>
                            {person
                              .replace("@reckitt.com", "")
                              .replace("@rb.com", "")}
                            {index < value.length - 2 ? ", " : " "}
                          </p>
                        ))}
                      </div>
                    }
                  >
                    <p>+{value.length - 1} more.</p>
                  </HtmlTooltip>
                )}
              </div>
            </TableCell>
          );
        } else if (column.id === "published") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={`${classes.tableRowCell} ${classes.tableContentcenter}`}
            >
              <p>{getFormattedDate(value)}</p>
            </TableCell>
          );
        } else if (column.id === "total_views_last") {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={`${classes.tableRowCell} ${classes.tableContentcenter}`}
              style={{ padding: "8px" }}
            >
              <p
                style={{
                  marginTop:
                    value.length > 1 ? (value[1] !== 0 ? "0px" : "") : "",
                }}
              >
                {column.format && typeof value[0] === "number"
                  ? column.format(value[0])
                  : value[0]}
              </p>
              <div
                style={{
                  display:
                    value.length > 1
                      ? value[1] !== 0
                        ? "flex"
                        : "none"
                      : "none",
                  backgroundColor:
                    value.length > 1
                      ? value[1] > 0
                        ? user.ui_preferences.theme === "light"
                          ? "#DCF0D4"
                          : "#1F470F"
                        : value[1] < 0
                        ? user.ui_preferences.theme === "light"
                          ? "#FCE8D4"
                          : "#7D4814"
                        : ""
                      : "",
                  alignItems: "center",
                  height: "20px",
                  fontSize: "11px",
                  justifyContent: "center",
                }}
              >
                {value.length > 1 &&
                  (value[1] > 0 ? (
                    <span
                      class="material-symbols-outlined"
                      style={{ color: "#50B428" }}
                    >
                      arrow_drop_up
                    </span>
                  ) : value[1] < 0 ? (
                    <span
                      class="material-symbols-outlined"
                      style={{ color: "#F08C2A" }}
                    >
                      arrow_drop_down
                    </span>
                  ) : null)}
                {value.length > 1 &&
                  (value[1] > 0
                    ? `+${value[1]}% vs ${monthShort[2].slice(0, 3)}`
                    : value[1] < 0
                    ? `${value[1]}% vs ${monthShort[2].slice(0, 3)}`
                    : "")}
              </div>
            </TableCell>
          );
        } else {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={`${classes.tableRowCell} ${
                classes.tableContentcenter
              } ${
                column.id === "total_views" ? classes.stickyCellTotalViews : ""
              }
              `}
              style={{ zIndex: column.id === "total_views" ? 1 : "" }}
            >
              <p>
                {column.format && typeof value === "number"
                  ? column.format(value)
                  : value}
              </p>
            </TableCell>
          );
        }
      })}
    </TableRow>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UsageMetricsTableBody);
