import React, { useState, useEffect } from "react";
import { Box, Dialog } from "@mui/material";
import { v4 } from "uuid";
import { useParams } from "react-router";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { connect, useDispatch } from "react-redux";

import CategoryDetails from "../Components/CategoryDetails";
import Loader from "../../../components/Loader/Loader";
import RegisterBodyContainer from "../Components/RegisterBodyContainer";
import FooterContainer from "../Components/FooterContainer";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import TemplateImg_1_dark from "../../../../assets/images/register-images/Template_1_dark.svg";
import TemplateImg_1 from "../../../../assets/images/register-images/Template_1.svg";

import {
  isEqualObject,
  removeReportField,
  removeSubField,
  updateCatState,
  updateSubState,
} from "../../../utils/utils";
import {
  updateReportTemplate,
  update_BIappDetails,
  update_appData,
  update_statusData,
  update_activeStepper,
} from "../../../redux/actions";
import ConfirmationModal from "../../../components/AlertMsg/ConfirmationModal";

const getListStyle = () => ({
  padding: 4,
});

const AddCategory = ({ handleMoreCategory }) => {
  return (
    <Box my={1}>
      <button className="add-category-btn pink" onClick={handleMoreCategory}>
        <span class="material-icons" style={{ marginRight: "10px" }}>
          library_add
        </span>
        Add Category
      </button>
    </Box>
  );
};

const ApplicationCategory = (props) => {
  const {
    templates,
    handleSubmit,
    categoryInfo,
    visited,
    waiting,
    isFieldsEditable,
    isChangesSubmitable,
    isAdminRegistering,
    appFields,
    theme,
    draft_id,
    draft_app_status,
    handleDraftSubmit,
    isDetailsSubmitable,
    appDetails,
    categoryInfoLocal,
    getSubmitableCategories,
    getSubmitableData,
    setSelectedTemplate,
    showDetailsDrawer,
    isAppUnique,
    templateData,
  } = props;

  const { description, fields } = templates;
  const { appInfo } = useParams();
  const dispatch = useDispatch();
  const eventTracker = useAnalyticsEventTracker(
    "Categories",
    appDetails.data.app_id
  );
  const [initailDefaultValues, setInitailDefaultValues] = useState({});
  const [isFieldContainerVibratable, setIsFieldContainerVibratable] =
    useState(false);
  const [isFieldCardRed, setIsFieldCardRed] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);
  const handleOpenConfirmationModal = () => setConfirmationModalOpen(true);
  const confirmationMessage =
    "Are you sure you want to discard the layout changes ?";
  const id = open ? "simple-popover" : undefined;
  let app_id;
  if (appInfo) {
    const data = appInfo.split("_");
    app_id = data[0];
  }

  const initialValues = {
    sub_category_id: v4(),
    sub_category_name: "",
    isCatEditable: true,
    sub_category: [
      {
        sub_category_id: v4(),
        sub_category_name: "",
        isSubCatEditable: true,
        sub_category: [
          {
            sub_category_id: v4(),
            sub_category_name: "",
            sub_category_icon: "space_dashboard",
            bi_application: "Power BI",
            url: "",
            isSubCatEditable: true,
            blob_name: "",
            app_logo_type: "google_font",
            view_count: 0,
          },
        ],
      },
    ],
  };

  const removeVibration = () => {
    setTimeout(() => {
      setIsFieldContainerVibratable(false);
      setIsFieldCardRed(true);
    }, 1000);
  };

  const isSubmitBtnDisbled = (info = categoryInfoLocal) => {
    if (!info.length) return true;
    if (
      window.location.pathname.split("/")[2] === "draft" &&
      appDetails?.data?.app_details?.defined_categories
    ) {
      let rslt = false;
      info.some((item) => {
        // if category is editable and there is no subcategory/report inside it
        if (item.isCatEditable && !item.sub_category.length) {
          return (rslt = true);
        }
        return item.sub_category.some((sub) => {
          // if report is editable and (URL or name is empty)
          if (sub.hasOwnProperty("url")) {
            if (sub.isSubCatEditable && (!sub.url || !sub.sub_category_name)) {
              return (rslt = true);
            } else return false;
          } else {
            // if subcategory is editable and there is no report inside it
            if (sub.isSubCatEditable && !sub.sub_category.length) {
              return (rslt = true);
            }
            return sub.sub_category.some((rep) => {
              // if report is editable and (URL or name is empty)
              if (
                rep.isSubCatEditable &&
                (!rep.url || !rep.sub_category_name)
              ) {
                return (rslt = true);
              } else return false;
            });
          }
        });
      });
      return rslt;
    }

    if (appFields?.app_details?.defined_categories) {
      if (
        isEqualObject(
          appFields?.app_details?.defined_categories,
          categoryInfoLocal
        )
      )
        return true && !isDetailsSubmitable;
    }

    let rslt = false;
    info.some((item) => {
      // console.log("ITEM-SOME", item);
      // if category is editable and there is no subcategory/report inside it
      // if (item.isCatEditable && !item.sub_category.length)
      if (!item.sub_category.length) {
        return (rslt = true);
      }
      return item.sub_category.some((sub) => {
        // if report is editable and (URL or name is empty)
        if (sub.hasOwnProperty("url")) {
          if (sub.isSubCatEditable && (!sub.url || !sub.sub_category_name)) {
            return (rslt = true);
          } else return false;
        } else {
          // if subcategory is editable and there is no report inside it
          // if (sub.isSubCatEditable && !sub.sub_category.length)
          if (!sub.sub_category.length) {
            return (rslt = true);
          }
          return sub.sub_category.some((rep) => {
            // if report is editable and (URL or name is empty)
            if (rep.isSubCatEditable && (!rep.url || !rep.sub_category_name)) {
              return (rslt = true);
            } else return false;
          });
        }
      });
    });
    return rslt;
  };

  const IsBiAppNotSubmitable = (info = categoryInfo) => {
    if (!info.length) return true;
    let rslt = false;
    info.some((item) => {
      if (item.isCatEditable || !item.sub_category.length) {
        return (rslt = true);
      }
      return item.sub_category.some((sub) => {
        if (sub.isSubCatEditable) {
          return (rslt = true);
        } else if (!sub.hasOwnProperty("url"))
          return sub.sub_category.some((rep) => {
            if (rep.isSubCatEditable) {
              return (rslt = true);
            } else return false;
          });
        else return false;
      });
    });
    return rslt;
  };

  const handleOpenImg = () => {
    setOpen(true);
    eventTracker("Create category info clicked", "open popup");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDiscard = () => {
    setSelectedTemplate(null);
    dispatch(
      updateReportTemplate({
        template_no: null,
        showSubCatPage: false,
      })
    );
    handleCloseConfirmationModal();
  };

  const handleBack = () => {
    dispatch(update_activeStepper(2));
  };

  const updateDropedSubData = (
    oldState,
    data,
    sourceCatIndex,
    destCatIndex,
    SubCat_obj,
    type = "__subcat"
  ) => {
    let existing_cat_index = sourceCatIndex;
    let existing_subcat_index = data.source.index;
    if (data.source.droppableId === data.destination.droppableId) {
      oldState.forEach((el, catind) => {
        if (el.sub_category_id === data.source.droppableId.split("__cat")[0]) {
          existing_cat_index = catind;
        }
        if (existing_cat_index > -1) return;
      });
    }
    oldState[existing_cat_index].sub_category.forEach((el, ind) => {
      if (el.sub_category_id === data.draggableId.split(type)[0]) {
        existing_subcat_index = ind;
      }
      if (existing_subcat_index > -1) return;
    });
    let shortedData = removeSubField(
      oldState,
      existing_cat_index, // SRC cat index
      existing_subcat_index //SRC subcat index
    );
    shortedData = [
      ...shortedData.slice(0, destCatIndex),
      {
        ...shortedData[destCatIndex],
        sub_category: [
          ...shortedData[destCatIndex].sub_category.slice(
            0,
            data.destination.index
          ),
          SubCat_obj,
          ...shortedData[destCatIndex].sub_category.slice(
            data.destination.index
          ),
        ],
      },
      ...shortedData.slice(destCatIndex + 1),
    ];
    return shortedData;
  };

  //For same subcategory
  const updateDropedReport = (
    oldState,
    data,
    subCatIndex, //subcat index
    catIndex, //cat index
    sourceReport //report obj
  ) => {
    let existing_subcat_index;
    let existing_cat_index;
    oldState.forEach((el, catind) =>
      el.sub_category.forEach((item, index) => {
        if (
          item.sub_category_id === data.source.droppableId.split("__subcat")[0]
        ) {
          existing_subcat_index = index;
          existing_cat_index = catind;
        }
      })
    );
    let shortedData = removeReportField(
      oldState,
      data.source.droppableId === data.destination.droppableId
        ? catIndex
        : existing_cat_index,
      data.source.droppableId === data.destination.droppableId
        ? subCatIndex
        : existing_subcat_index,
      data.source.index //report index
    );
    shortedData = [
      ...shortedData.slice(0, catIndex),
      {
        ...shortedData[catIndex],
        sub_category: [
          ...shortedData[catIndex].sub_category.slice(0, subCatIndex),
          {
            ...shortedData[catIndex].sub_category[subCatIndex],
            sub_category: [
              ...shortedData[catIndex].sub_category[
                subCatIndex
              ].sub_category.slice(0, data.destination.index),
              sourceReport,
              ...shortedData[catIndex].sub_category[
                subCatIndex
              ].sub_category.slice(data.destination.index),
            ],
          },
          ...shortedData[catIndex].sub_category.slice(subCatIndex + 1),
        ],
      },
      ...shortedData.slice(catIndex + 1),
    ];
    return shortedData;
  };

  const handleDrop = (data) => {
    if (!data.destination) return;
    if (
      data.source.droppableId === data.destination.droppableId &&
      data.source.index === data.destination.index
    )
      return;
    // As of now updated
    if (data.draggableId.includes("__rep")) {
      if (
        data.source.droppableId.endsWith("__subcat") &&
        data.destination.droppableId.endsWith("__subcat")
      ) {
        const sourceId = data.source.droppableId.split("__subcat")[0];
        const destinationId = data.destination.droppableId.split("__subcat")[0];
        let SRC_cat_index;
        let DST_cat_index;
        let sourceSubCatIndex;
        let destinationSubCatIndex;
        categoryInfo.forEach((cat, catInd) => {
          cat.sub_category.forEach((item, Sindex) => {
            if (item.sub_category_id === sourceId) {
              sourceSubCatIndex = Sindex;
              SRC_cat_index = catInd;
              return;
            }
          });
          if (sourceSubCatIndex > -1) return;
        });
        categoryInfo.forEach((cat, catInd) => {
          cat.sub_category.forEach((item, Sindex) => {
            if (item.sub_category_id === destinationId) {
              destinationSubCatIndex = Sindex;
              DST_cat_index = catInd;
              return;
            }
          });
          if (destinationSubCatIndex > -1) return;
        });
        const sourceReport =
          categoryInfo[SRC_cat_index].sub_category[sourceSubCatIndex]
            .sub_category[data.source.index];
        const reqSubInfoData = updateDropedReport(
          categoryInfo,
          data,
          destinationSubCatIndex, //subcat index
          DST_cat_index, // cat index
          sourceReport // report object
        );
        const reqSubLocalData = updateDropedReport(
          categoryInfoLocal,
          data,
          destinationSubCatIndex,
          DST_cat_index,
          sourceReport
        );

        // setCategoryInfo(reqSubInfoData);
        // setCategoryInfoLocal(reqSubLocalData);
        dispatch(
          update_BIappDetails({
            categoryInfo: reqSubInfoData,
            categoryInfoLocal: reqSubLocalData,
          })
        );
        return;
      } else if (
        //FIXed
        data.source.droppableId.endsWith("__cat") &&
        data.destination.droppableId.endsWith("__cat")
      ) {
        const sourceId = data.source.droppableId.split("__cat")[0];
        const destinationId = data.destination.droppableId.split("__cat")[0];
        const sourceCatIndex = categoryInfo.findIndex(
          (item) => item.sub_category_id === sourceId
        );
        const destCatIndex = categoryInfo.findIndex(
          (item) => item.sub_category_id === destinationId
        );
        const report_obj = categoryInfo[sourceCatIndex].sub_category.find(
          (item) => item.sub_category_id === data.draggableId.split("__rep")[0]
        );
        const reqSubInfoData = updateDropedSubData(
          categoryInfo,
          data,
          sourceCatIndex,
          destCatIndex,
          report_obj,
          "__rep"
        );
        const reqSubLocalData = updateDropedSubData(
          categoryInfoLocal,
          data,
          sourceCatIndex,
          destCatIndex,
          report_obj,
          "__rep"
        );

        // setCategoryInfo(reqSubInfoData);
        // setCategoryInfoLocal(reqSubLocalData);
        dispatch(
          update_BIappDetails({
            categoryInfo: reqSubInfoData,
            categoryInfoLocal: reqSubLocalData,
          })
        );
        return;
      } else {
        return;
      }
    }
    // fixed
    else if (data.draggableId.endsWith("__subcat")) {
      const sourceId = data.source.droppableId.split("__cat")[0];
      const destinationId = data.destination.droppableId.split("__cat")[0];
      const sourceCatIndex = categoryInfo.findIndex(
        (item) => item.sub_category_id === sourceId
      );
      const destCatIndex = categoryInfo.findIndex(
        (item) => item.sub_category_id === destinationId
      );
      const subCat_obj = categoryInfo[sourceCatIndex].sub_category.find(
        (item) => item.sub_category_id === data.draggableId.split("__subcat")[0]
      );
      const reqSubInfoData = updateDropedSubData(
        categoryInfo,
        data,
        sourceCatIndex,
        destCatIndex,
        subCat_obj,
        "__subcat"
      );
      const reqSubLocalData = updateDropedSubData(
        categoryInfoLocal,
        data,
        sourceCatIndex,
        destCatIndex,
        subCat_obj,
        "__subcat"
      );

      // setCategoryInfo(reqSubInfoData);
      // setCategoryInfoLocal(reqSubLocalData);
      dispatch(
        update_BIappDetails({
          categoryInfo: reqSubInfoData,
          categoryInfoLocal: reqSubLocalData,
        })
      );
      return;
    }
    // below is working properly //
    // category dragging
    const draggedCat = categoryInfo[data.source.index];
    // category removed
    let shortedCatInfoData = [
      ...categoryInfo.slice(0, data.source.index),
      ...categoryInfo.slice(data.source.index + 1),
    ];
    let shortedCatLocalData = [
      ...categoryInfoLocal.slice(0, data.source.index),
      ...categoryInfoLocal.slice(data.source.index + 1),
    ];
    // category added
    shortedCatInfoData = [
      ...shortedCatInfoData.slice(0, data.destination.index),
      draggedCat,
      ...shortedCatInfoData.slice(data.destination.index),
    ];
    shortedCatLocalData = [
      ...shortedCatLocalData.slice(0, data.destination.index),
      draggedCat,
      ...shortedCatLocalData.slice(data.destination.index),
    ];
    // setCategoryInfo(shortedCatInfoData);
    // setCategoryInfoLocal(shortedCatLocalData);
    dispatch(
      update_BIappDetails({
        categoryInfo: shortedCatInfoData,
        categoryInfoLocal: shortedCatLocalData,
      })
    );
  };

  const handleMoreCategory = () => {
    if (categoryInfo.length === 10) return;
    if (isFieldCardRed) setIsFieldCardRed(false);
    const { infoState, localState } = getSubmitableData(
      categoryInfo,
      categoryInfoLocal
    );
    const initialData = {
      sub_category_id: v4(),
      sub_category_name: "",
      isCatEditable: true,
      sub_category: [],
    };
    // setCategoryInfo([...infoState, initialData]);
    // setCategoryInfoLocal([...localState, initialData]);
    dispatch(
      update_BIappDetails({
        categoryInfo: [...infoState, initialData],
        categoryInfoLocal: [...localState, initialData],
      })
    );
  };

  const handleSubmitFunction = () => {
    const { infoState } = getSubmitableData(categoryInfo, categoryInfoLocal);
    // setCategoryInfo(infoState);
    dispatch(update_BIappDetails({ categoryInfo: infoState }));
    setIsFieldCardRed(false);
    if (IsBiAppNotSubmitable(infoState)) {
      setIsFieldContainerVibratable(true);
      removeVibration();
    } else {
      // call a function to remove extra fields
      const submitableData = getSubmitableCategories(infoState);
      handleSubmit(submitableData);
      eventTracker(
        appInfo ? "Update btn clicked" : "Submit btn clicked",
        "clicked"
      );
    }
  };

  const handleDraftFunction = () => {
    const { infoState, localState } = getSubmitableData(
      categoryInfo,
      categoryInfoLocal
    );
    // setCategoryInfo(infoState);
    // setCategoryInfoLocal(localState);
    dispatch(
      update_BIappDetails({
        categoryInfo: infoState,
        categoryInfoLocal: localState,
      })
    );
    handleDraftSubmit("Categories", infoState);
  };

  useEffect(() => {
    if (fields && fields.length) {
      let initialData = {};
      const index = fields.findIndex((item) => item.key === "sub_category");
      fields[index].fields.forEach((item) => {
        initialData = { ...initialData, [item.key]: item.value };
      });
      const reqData = [
        {
          ...initialValues,
          sub_category: [
            {
              ...initialValues.sub_category[0],
              sub_category: [
                {
                  ...initialValues.sub_category[0].sub_category[0],
                  ...initialData,
                },
              ],
            },
          ],
        },
      ];
      setInitailDefaultValues(initialData);
      if (
        !visited.category &&
        !(
          app_id ||
          (draft_id && appFields.app_details?.defined_categories?.length)
        )
      ) {
        // setCategoryInfo(reqData);
        // setCategoryInfoLocal(reqData);
        dispatch(
          update_BIappDetails({
            categoryInfo: reqData,
            categoryInfoLocal: reqData,
          })
        );
        // setVisited({ ...visited, category: true });
        dispatch(update_appData({ visited: { ...visited, category: true } }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // justify-content: flex-start;
  // height: calc(100vh - 180px);

  const isNextBtnDisabled = () => {
    const reqVal =
      !(
        appFields.app_details["description"] &&
        appFields.app_name &&
        (appFields.app_details["description"] || "").length <= 500 &&
        appFields.app_details?.function?.length &&
        appFields.app_details?.line_of_business?.length
      ) ||
      waiting ||
      !(
        isAppUnique ||
        // ((appInfo || draft_app || isAdminRegistering) &&
        appDetails.data.app_meta?.app_name === appFields.app_name
      );
    return reqVal;
  };

  return (
    <>
      <RegisterBodyContainer
        height={
          appInfo || isAdminRegistering
            ? "calc(100vh - 270px)"
            : "calc(100vh - 220px)"
        }
      >
        <div>
          <Box display="flex" alignItems="center">
            <div className="sub-title">{description}</div>
            <Box display="flex" alignItems="center" className="GA-info-btn">
              <span
                class="material-icons-outlined GA-info-btn"
                aria-describedby={id}
                onClick={handleOpenImg}
                style={{ fontSize: "15px", marginLeft: "10px" }}
              >
                info
              </span>
              <Dialog
                onClose={handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
                sx={{
                  "& .MuiDialog-paper": {
                    maxWidth: "60vw",
                    minWidth: "60vw",
                  },
                }}
              >
                <img
                  src={theme === "dark" ? TemplateImg_1_dark : TemplateImg_1}
                  alt=""
                  height="80%"
                />
              </Dialog>
            </Box>
          </Box>

          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="droppable" type="droppableItem">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {categoryInfo.map((item, i) => (
                    <CategoryDetails
                      data={item}
                      key={item.sub_category_id}
                      // key={v4()}
                      fields={fields}
                      initailDefaultValues={initailDefaultValues}
                      catIndex={i}
                      isFieldsEditable={isFieldsEditable}
                      theme={theme}
                      isFieldContainerVibratable={isFieldContainerVibratable}
                      isFieldCardRed={isFieldCardRed}
                      setIsFieldCardRed={setIsFieldCardRed}
                      getSubmitableData={getSubmitableData}
                      setCatData={updateCatState}
                      setSubCatData={updateSubState}
                      removeField={removeSubField}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!isFieldsEditable && (
            <AddCategory
              handleMoreCategory={handleMoreCategory}
              categoryInfo={categoryInfo}
            />
          )}
        </div>
      </RegisterBodyContainer>
      <FooterContainer>
        <>
          {!(appInfo || isAdminRegistering) && (
            <>
              <button
                className={`btn-draft ${
                  draft_id &&
                  !isDetailsSubmitable &&
                  isEqualObject(categoryInfoLocal, templateData)
                    ? "btn-disabled"
                    : ""
                }`}
                onClick={handleDraftFunction}
                disabled={
                  draft_id &&
                  !isDetailsSubmitable &&
                  isEqualObject(categoryInfoLocal, templateData)
                }
              >
                {draft_app_status.status === "loading" ? (
                  <Loader color="#ffffff" />
                ) : draft_id !== undefined &&
                  draft_id.includes("active_draft") ? (
                  "Update draft"
                ) : (
                  "Save as draft"
                )}
              </button>

              {/* {userInfo.role !== "super_admin" && (
                                <button
                                    onClick={handleBack}
                                    className="btn-back"
                                    disabled={
                                        draft_app_status.status === "loading"
                                    }
                                >
                                    Back
                                </button>
                            )} */}
            </>
          )}
          {/* <button
            onClick={() => {
              dispatch(
                update_statusData({
                  showDetailsDrawer: !showDetailsDrawer,
                })
              );
            }}
            className={`btn btn-next ${
              isNextBtnDisabled() ? "btn-disabled" : ""
            }`}
            style={{
              position: "relative",
              right: "50px",
            }}
          >
            {showDetailsDrawer ? "Hide Details" : "Show Details"}
          </button> */}
          {
            <ConfirmationModal
              open={confirmationModalOpen}
              handleClose={handleCloseConfirmationModal}
              handleSubmit={handleDiscard}
              message={confirmationMessage}
              width="300px"
            />
          }
          {(appInfo === undefined || appInfo.includes("draft")) && (
            <button
              style={{
                width: "130px",
              }}
              onClick={handleOpenConfirmationModal}
              // className="btn-back"
              className={`btn-back ${showDetailsDrawer ? "btn-disabled" : ""}`}
              disabled={
                draft_app_status.status === "loading" || showDetailsDrawer
              }
            >
              Discard Changes
            </button>
          )}
          {(appInfo === undefined || appInfo.includes("draft")) && (
            <button
              onClick={handleBack}
              // className="btn-back"
              className={`btn-back ${showDetailsDrawer ? "btn-disabled" : ""}`}
              disabled={
                draft_app_status.status === "loading" || showDetailsDrawer
              }
            >
              Back
            </button>
          )}
          {/* )} */}
          {!isFieldsEditable && (
            <button
              onClick={handleSubmitFunction}
              // className={`btn btn-next ${
              //     IsBiAppNotSubmitable() ? "btn-disabled" : ""
              // }`}
              id={(app_id || draft_id) && "GA-update-btn"}
              className={`btn btn-next ${
                isSubmitBtnDisbled() || showDetailsDrawer ? "btn-disabled" : ""
              }`}
              disabled={
                waiting ||
                !appFields.app_details?.function.length ||
                !appFields.app_details?.description ||
                ((appInfo || isAdminRegistering) && !isChangesSubmitable) ||
                // !isDetailsSubmitable ||
                draft_app_status.status === "loading" ||
                isSubmitBtnDisbled() ||
                showDetailsDrawer
              }
            >
              {waiting ? (
                <Loader color="#ffffff" margin="0px 8px" />
              ) : app_id || isAdminRegistering ? (
                "Update Request"
              ) : (
                "Submit"
              )}
            </button>
          )}
        </>
      </FooterContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAppUnique: state.registerNewApp.statusData.isAppUnique,
  showDetailsDrawer: state.registerNewApp.statusData.showDetailsDrawer,
  // BIappDetails
  templateData: state.appTemplate.appTemplateState.templateData,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  // statusData
  isChangesSubmitable: state.registerNewApp.statusData.isChangesSubmitable,
  isDetailsSubmitable: state.registerNewApp.statusData.isDetailsSubmitable,
  waiting: state.registerNewApp.statusData.waiting,
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  // appData
  appFields: state.registerNewApp.appData.appFields,
  visited: state.registerNewApp.appData.visited,
  // userInfo: state.user,
  draft_app_status: state.register.register_draft_app,
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(ApplicationCategory);
