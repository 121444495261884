import React, { useState } from "react";
import { connect } from "react-redux";

const FileUploader = ({ handleChange, keyField, helpCenterState }) => {
    const { formData } = helpCenterState;
    const [showWarning, setShowWarning] = useState(false);
    const [msg, setMsg] = useState("");

    const removeWarning = () => {
        setTimeout(() => {
            setShowWarning(false);
            setMsg("");
        }, 3000);
    };

    const handleUpload = (e) => {
        const file = e.target.files[0];
        if (!file || file?.size > 1000000) {
            setMsg("Please upload file less than 1mb.");
            setShowWarning(true);
            removeWarning();
            return;
        } else if ((formData[keyField] || []).length >= 5) {
            setMsg("You can't select more than 5 attachments.");
            setShowWarning(true);
            removeWarning();
            return;
        }
        if (file) {
            handleChange(keyField, file, "multiple");
        }
    };

    return (
        <div className="help-center-upload-box">
            {showWarning && (
                <div className="help-center-warning-text">{msg}</div>
            )}
            <div>
                <input
                    type="file"
                    name="logo"
                    // accept="image/x-png, image/png, image/jpg, image/jpeg"
                    accept="application/pdf, image/*"
                    onChange={handleUpload}
                    id="upload_file_for_jira"
                    style={{ display: "none" }}
                />
                <label
                    htmlFor="upload_file_for_jira"
                    className="help-center-upload-btn"
                >
                    <span
                        class="material-icons-outlined GA-upload-help-center"
                        style={{
                            color: "#8d9ca6",
                        }}
                    >
                        file_upload
                    </span>
                    <div className="help-center-upload-text GA-upload-help-center">
                        Upload from Device
                    </div>
                </label>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    helpCenterState: state.helpCenter.helpCenterState,
});
export default connect(mapStateToProps)(FileUploader);
