import React from "react";
import { Grid } from "@mui/material";
import ModalWrapper from "../../../components/AlertMsg/ModalWrapper";
import CheckBoxComponent from "../../NewRegister/Components/CheckBoxComponent";

const SyncModal = ({
    app_name = "",
    open,
    handleClose,
    SubmitSyncEnv,
    envData,
    setEnvData,
    theme,
}) => {
    const handleChange = (k, v) => setEnvData({ ...envData, [k]: v });
    const handleSubmit = () => {
        if (envData.action_for_uat || envData.action_for_dev) {
            SubmitSyncEnv(envData);
        }
    };

    return (
        <ModalWrapper
            open={open}
            handleClose={handleClose}
            continueText="Sync"
            handleSubmit={handleSubmit}
        >
            <div className={`sync-${theme}`}>
                <div className="sync-heading">{`Sync Access across environments for ${app_name}.`}</div>
                <div className="sync-sub-heading">
                    Select environment you want to copy the access to
                </div>
                <Grid container sx={{marginLeft:"7px"}}>
                    <CheckBoxComponent
                        data={{
                            label: "UAT Environment",
                            key: "action_for_uat",
                        }}
                        handleChange={handleChange}
                        value={envData["action_for_uat"]}
                        width={6}
                        theme={theme}
                    />
                    <CheckBoxComponent
                        data={{
                            label: "DEV Environment",
                            key: "action_for_dev",
                        }}
                        handleChange={handleChange}
                        value={envData["action_for_dev"]}
                        width={6}
                        theme={theme}
                    />
                </Grid>
                <div className="sync-desc">
                    This action will overwrite the existing access. Are you sure
                    you want to continue?
                </div>
            </div>
        </ModalWrapper>
    );
};

export default SyncModal;
