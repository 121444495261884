import { Box, Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { config } from "../../config/config";
import { BootstrapInput } from "../../screens/Register/style";
import Loader from "../Loader/Loader";

const ConfirmationModal = ({
  open,
  handleClose,
  handleSubmit,
  message,
  waiting,
  showComment,
  width = "320px",
  continueText = "Continue",
}) => {
  const [comment, setComment] = useState("");
  const handleChange = (e) => setComment(e.target.value);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          backgroundImage: "none",
          boxShadow: "4px 4px 12px 4px #434343",
        },
      }}
    >
      <div
        style={{
          minWidth: width,
          maxWidth: "600px",
        }}
      >
        <DialogContent sx={{ padding: "30px 60px" }}>
          <Box
            display="flex"
            alignItems="center"
            className="pop-up-message-style"
          >
            {message || config.messages.confimation}
          </Box>
          {showComment && (
            <>
              <BootstrapInput
                value={comment}
                onChange={handleChange}
                rows={4}
                multiline
                fullWidth
                sx={{ pr: "11px" }}
              />
            </>
          )}
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" px={2} pb={2} gap={1}>
          <button
            style={{ height: "25px", width: "90px", fontSize: "12px" }}
            onClick={handleClose}
            className="cancel-btn"
          >
            Cancel
          </button>
          <button
            onClick={() => handleSubmit(comment)}
            disabled={waiting}
            className="btn"
            style={{ height: "25px", width: "90px", fontSize: "12px" }}
          >
            {waiting ? (
              <Loader color="#ffffff" margin="0px 8px" />
            ) : (
              continueText
            )}
          </button>
        </Box>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
