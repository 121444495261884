import { Box, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import {
    CssTextField,
    StyledAutoComplete,
    useStyles,
} from "../../Register/style";

const DescModal = ({
    open,
    handleClose,
    type,
    handleDone,
    othersValues,
    setOthersValues,
    keyField,
    options = {},
    setOptions,
    GATrackEventForOther,
}) => {
    const classes = useStyles();
    const filter = createFilterOptions();
    const [autoOpen, setAutoOpen] = useState(false);
    const [value, setValue] = useState("");

    const handleChange = (val) => {
        setOthersValues({ ...othersValues, [keyField]: val });
        const isInputExistInOptions = (options[keyField] || []).some(
            (item) => item?.toLowerCase() === val?.toLowerCase()
        );
        if (!isInputExistInOptions) {
            setOptions({ ...options, [keyField]: [...options[keyField], val] });
            // tracking if new value is added
            GATrackEventForOther(val, "new");
        } else {
            //tracking if already existing value is selected
            GATrackEventForOther(val);
        }
    };

    const getOptions = () => options[keyField];

    const handleInputChange = (val) => setValue(val);

    useEffect(() => {
        setValue(othersValues[keyField] || "");
    }, [keyField, othersValues]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiDialog-paper": { backgroundImage: "none" } }}
        >
            <div
                style={{
                    minWidth: "500px",
                    maxWidth: "600px",
                }}
            >
                <DialogContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        mb={2}
                        className="heading"
                    >
                        {`Kindly provide the ${type}.`}
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <StyledAutoComplete
                            id="share-select-user"
                            size="small"
                            disableCloseOnSelect
                            style={{
                                fontFamily: "Energy !important",
                                width: "100%",
                                marginRight: "20px",
                            }}
                            className={`${classes.typographyStyle}`}
                            freeSolo
                            open={autoOpen}
                            onOpen={() => {
                                setAutoOpen(true);
                            }}
                            onClose={() => {
                                setAutoOpen(false);
                            }}
                            // handleHomeEndKeys
                            options={getOptions()}
                            getOptionLabel={(option) => {
                                if (option && option.title) {
                                    return option.title;
                                }
                                if (option && option.inputValue) {
                                    return option.inputValue;
                                }
                                return option || "";
                            }}
                            value={othersValues[keyField] || ""}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                // Suggest the creation of a new value

                                if (params.inputValue !== "") {
                                    filtered.push({
                                        inputValue: params.inputValue,
                                        title: `Add "${params.inputValue}"`,
                                    });
                                }
                                return filtered;
                            }}
                            onInputChange={(e, v) => handleInputChange(v)}
                            onChange={(event, newValue) => {
                                if (!newValue) return;
                                if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    handleChange(newValue.inputValue);
                                } else {
                                    handleChange(newValue);
                                }
                                setAutoOpen(false);
                            }}
                            noOptionsText="No option"
                            renderInput={(params) => (
                                <CssTextField
                                    {...params}
                                    variant="standard"
                                    className="setup-select-field"
                                    fullWidth
                                    size="small"
                                    placeholder={`Enter ${type}`}
                                    style={{
                                        width: "100%",
                                        textTransform: "none",
                                    }}
                                />
                            )}
                        />
                    </Box>
                </DialogContent>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    pl={2}
                    pr="23px"
                    pb={1}
                    gap={2}
                >
                    <button onClick={handleClose} className="cancel-btn">
                        Cancel
                    </button>
                    <button
                        onClick={handleDone}
                        className="btn"
                        disabled={Boolean(!value)}
                    >
                        Done
                    </button>
                </Box>
            </div>
        </Dialog>
    );
};

export default DescModal;
