import React from "react";
import { Box, Tab, Tabs } from "@mui/material";

// Import Hooks
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import { useHistory } from "react-router";

const getSteps = (usersList) => {
  if (usersList.length !== 0) {
    return ["My Profile", "Manage Access"];
  } else {
    return ["My Profile"];
  }
};

const TabComponent = ({ activeTab, setActiveTab, usersList }) => {
  const history = useHistory();
  const HandleChange = (event, newValue) => {
    const gaEventTracker = useAnalyticsEventTracker("UserManagement");
    gaEventTracker(
      "TabChange",
      `tab = ${newValue === 0 ? "My Profile" : "Manage Access"}`
    );
    const val = newValue === 0 ? "my-profile" : "manage-access";
    history.push("/profile-management/" + val);
    setActiveTab(val);
  };
  return (
    <Box my={2}>
      <Tabs
        value={activeTab === "my-profile" ? 0 : 1}
        onChange={HandleChange}
        aria-label="Register Tabs"
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.dark.light
              : theme.palette.white.main,
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.08)",
          color: theme.palette.mode === "dark" ? "#fff" : "#FC007F",
          borderRadius: "5px",
          "& .MuiButtonBase-root": {
            color:
              theme.palette.mode === "dark"
                ? theme.palette.white.main
                : theme.palette.dark.light,
            textTransform: "none",
            fontFamily: "Energy",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "19px",
            // color: "#282828",
            opacity: 0.5,
            paddingLeft: "35px",
            paddingRight: "35px",
            "&.Mui-selected": {
              color: theme.palette.primary.main,
              opacity: 1,
            },
          },
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.main,
          },
        })}
      >
        {getSteps(usersList).map((item, i) => (
          <Tab id="GA-manage-profile-tabs" value={i} label={item} />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabComponent;
