import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import Typewriter from "typewriter-effect/dist/core";
import { v4 } from "uuid";
import ViewAppsBy from "./NewViewAppsBy";
import { getRegisterAppTemplate, updateUserInfo } from "../../redux/actions";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import SearchBar from "../../components/SearchBar";
import Loader from "../../components/Loader/Loader";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const RBHeaderComponent = ({
  user,
  AppsData,
  templates,
  query,
  viewByFilter,
  setViewByFilter,
  searchVal,
  setSearchVal,
  search_columns = [],
  handleSmartSearch,
  updateScreenWithFilteredData,
  handleFilterDrawer,
  match,
  loadingFilter,
}) => {
  const dispatch = useDispatch();
  const eventTracker = useAnalyticsEventTracker("Smart search");
  const [inputKey, setInputKey] = useState("random_key");
  const cancelSearch = () => {
    dispatch(
      updateUserInfo({
        ...user,
        filteredApps: { data: [], status: "pending" },
      })
    );
    setSearchVal("");
    if (search_columns.length) {
      handleSmartSearch({
        search_columns: search_columns,
        search_string: "",
      });
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (!value) {
      cancelSearch();
    } else {
      setSearchVal(value);
    }
  };
  const handleClear = () => {
    cancelSearch();
    eventTracker("Reset filters", "Smart search");
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "current_page",
      current_page: "explore",
    });
  }, []);

  useEffect(() => {
    setInputKey(v4());
  }, []);

  useEffect(() => {
    let isCancelled = false;
    const handleSearch = async (value) => {
      if (!value) {
        return;
      }
      await timeout(1000);
      if (!isCancelled) {
        const payload = {
          search_columns: search_columns,
          search_string: value,
        };
        handleSmartSearch(payload);
        eventTracker("String entered", value);
      }
    };
    handleSearch(searchVal);
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  useEffect(() => {
    if (templates.status === "pending") {
      dispatch(getRegisterAppTemplate());
    }
  }, [dispatch]);

  useEffect(() => {
    if (window.location.pathname.split("/")[1] === "explore") {
      const input = document.getElementById("GA-search-apps");
      if (!input || !AppsData.allApps?.data?.length) return;
      const customNodeCreator = function (character) {
        // Add character to input placeholder
        input.placeholder = input.placeholder + character;

        // Return null to skip internal adding of dom node
        return null;
      };

      const onRemoveNode = function ({ character }) {
        if (input.placeholder) {
          // Remove last character from input placeholder
          input.placeholder = input.placeholder.slice(0, -1);
        }
      };
      const reqDataToBeUpdated = JSON.parse(
        JSON.stringify(AppsData.allApps.data)
      );
      const sortedData = reqDataToBeUpdated.sort(
        (a, b) => b.app_meta.total_views - a.app_meta.total_views
      );
      if (!sortedData.length) return;
      const typewriter = new Typewriter(null, {
        loop: true,
        delay: 75,
        onCreateTextNode: customNodeCreator,
        onRemoveNode: onRemoveNode,
      });
      typewriter
        .pauseFor(300)
        .typeString(` '${sortedData[0].app_meta.app_name}'`)
        .pauseFor(600)
        .deleteChars(sortedData[0].app_meta.app_name.length + 3)
        .typeString(` '${sortedData[1].app_meta.app_name}'`)
        .pauseFor(600)
        .deleteChars(sortedData[1].app_meta.app_name.length + 3)
        .typeString(` '${sortedData[2].app_meta.app_name}'`)
        .pauseFor(600)
        .deleteChars(sortedData[2].app_meta.app_name.length + 3)
        .typeString(` '${sortedData[3].app_meta.app_name}'`)
        .pauseFor(600)
        .deleteChars(sortedData[3].app_meta.app_name.length + 3)
        .typeString(` '${sortedData[4].app_meta.app_name}'`)
        .pauseFor(600)
        .deleteChars(sortedData[4].app_meta.app_name.length + 3)
        .start();
    }
  }, [AppsData.allApps?.data]);

  const currentPage = window.location.pathname.split("/")[1];

  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="space-between"
      py={2}
      width={"600px"}
    >
      {/* <RBImageHeader match={match} /> */}
      <SearchBar
        handleClear={handleClear}
        handleFilterDrawer={handleFilterDrawer}
        searchVal={searchVal}
        search_columns={search_columns}
        handleChange={handleChange}
        disabled={AppsData?.allApps?.status === "loading"}
        inputKey={inputKey}
        searchPage="Explore"
      />
      {loadingFilter === "loading" && <Loader />}
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          marginLeft: "10px",
        }}
      >
        {AppsData?.allApps?.status === "success" &&
          templates?.status === "success" &&
          currentPage === "explore" && (
            <ViewAppsBy
              viewByFilter={viewByFilter}
              setViewByFilter={setViewByFilter}
            />
          )}
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  AppsData: state.AppsData,
  templates: state.register.registerTemplate,
});
export default connect(mapStateToProps)(RBHeaderComponent);
