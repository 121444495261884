import { makeStyles } from "@material-ui/core";
import { styled, TableCell, TableRow,InputBase } from "@mui/material";

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        padding: theme.spacing(1),
        backgroundColor: "#fff",
        width: "80px",
        height: "10px",
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "23px",
        borderRadius: "4px",
        color: "#282828",
    },
    "& .Mui-disabled": {
        cursor: "pointer",
    },
}));

export const paperStyle = {
    width: "100%",
    overflow: "hidden",
    borderRadius: "0px",
    boxShadow: "none",
    mt: "10px",
    maxHeight: "calc(100vh - 300px)",
};
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "& .MuiTableCell-root": {
        padding: "6px 16px",
    },
    "&:nth-of-type(even)": {
        backgroundColor:
            theme.palette.mode === "dark" ? "#282828" : "#F5F6F8",
    },
    "&:nth-of-type(odd)": {
        backgroundColor:
            theme.palette.mode === "dark"
                ? theme.palette.dark.main
                : theme.palette.white.main,
    },
    "&.MuiTableRow-hover": {
        "&:hover": {
            "&:nth-of-type(even)": {
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? "#282828"
                        : "#F5F6F8",
            },
            "&:nth-of-type(odd)": {
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? theme.palette.dark.main
                        : theme.palette.white.main,
            },
        },
    },
}));
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    "&.MuiTableCell-root": {
        fontFamily: "Energy !important",
        fontSize: "13px !important",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828 !important",
        padding: "6px 16px",
        borderBottom: "none",
    },
}));

export const useStyles = makeStyles((theme) => ({
    tableRowCell: {
        fontFamily: "Energy !important",
        fontSize: "13px !important",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828 !important",
        "&.MuiTableCell-root": {
            padding: "6px 16px",
            borderBottom: "none",
        },
    },
    tableHeadCell: {
        fontFamily: "Energy !important",
        fontSize: "13px !important",
        fontWeight: "700 !important",
        color: "#ffffff !important",
        backgroundColor: " #282828 !important",
        "&.MuiTableCell-root": {
            padding: "6px 16px",
            borderBottom: "none",
        },
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#F5F6F8!important",
            color: "#D8477E !important",
        },
    },
    pagination: {
        backgroundColor:
            theme.palette.mode === "dark"
                ? theme.palette.dark.main
                : theme.palette.white.main,
        // },
        "& .MuiTablePagination-selectLabel": {
            fontFamily: "Energy",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
        "& .MuiTablePagination-select": {
            fontFamily: "Energy",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
        "& .MuiTablePagination-displayedRows": {
            fontFamily: "Energy",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
    },
    search: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: "4px",
        marginLeft: "10px",
        "& .MuiSvgIcon-root": {
            fontSize: "18px",
        },
    },
    menu: {
        boxShadow: "none",
        "& .MuiMenuItem-root": {
            fontFamily: "Energy",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "23px",
            color: theme.palette.mode === "dark" ? "#fff" : "#282828",
            boxShadow: "none",
            padding: "2px 20px",
        },
        "& .MuiPaper-root": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : theme.palette.white.main,
        },
    },
    fixedWidth: {
        maxWidth: "100px",
    },
    rolePadding: {
        padding: "6px 12px",
        textTransform: "capitalize",
    },
    provisingAccess: {
        "& .MuiTableCell-root": {
            fontFamily: "Energy",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "23px",
            padding: "8px",
            color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        },
    },
    userId: {
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "19px",
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#282828",
    },
    menuStyle: {
        "& .MuiMenuItem-root": {
            backgroundColor: "#FBEDF2",
            color: theme.palette.dark.main,
            "&:hover": {
                backgroundColor: "#FBEDF2",
            },
        },
    },
}));
