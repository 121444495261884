// Import required libraries
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Import custom components

// Import styles
import "./Sidenav.scss";

// Import action creators
import { updateDialogInfo, update_statusData } from "../../redux/actions";

// Import data & utils
import { SideNavDataTop, SideNavDataBottom } from "./SideNavData";

//Import images
import explore from "../../../assets/images/new-theme/explore_pink.svg";
import home from "../../../assets/images/new-theme/home_pink.svg";
import person from "../../../assets/images/new-theme/myapps_pink.svg";
import faq from "../../../assets/images/new-theme/faq_pink.svg";
import faqwhite from "../../../assets/images/new-theme/faq_white.svg";
import help from "../../../assets/images/new-theme/help_pink.svg";
import exploreWhite from "../../../assets/images/new-theme/explore_white.svg";
import homewhite from "../../../assets/images/new-theme/home_white.svg";
import helpwhite from "../../../assets/images/new-theme/help_white.svg";
import personwhite from "../../../assets/images/new-theme/myapps_white.svg";
import RecentDrawer from "../RecentDrawer";
import DR_logo_white from "../../../assets/images/new-theme/reckitt_logo_white.svg";

function Sidenav(props) {
  let { selected = "", user, dirty, isDetailsSubmitable } = props;
  const [openRecent, setopenRecent] = useState(false);

  const handleCloseRecent = () => {
    setopenRecent(false);
  };

  return (
    <>
      <nav className={`new-theme-nav`}>
        <img className="logo-image" alt="d@r logo" src={DR_logo_white}></img>
        <div className="sidenav-elements-container">
          <div>
            {SideNavDataTop.map((el) => (
              <Link
                // onClick={() => handleChangeRoute(el.path)}
                key={el.path}
                to={`${el.path}`}
                className={
                  el.path === selected
                    ? `nav-row-selected `
                    : `nav-row  GA-sidenav`
                }
                id={el.title}
              >
                <img
                  class="nav-icon GA-sidenav"
                  alt="Home"
                  id={el.title}
                  src={
                    el.icon === "explore"
                      ? el.path === selected
                        ? explore
                        : exploreWhite
                      : el.icon === "home"
                      ? el.path === selected
                        ? home
                        : homewhite
                      : el.icon === "person"
                      ? el.path === selected
                        ? person
                        : personwhite
                      : personwhite
                  }
                />
                <p id={el.title} className="row-name GA-sidenav">
                  {el.title}
                </p>
              </Link>
            ))}
            {/* <div
              key="recentlyused"
              style={{
                margin: "4px",
                borderRadius: "5px",
              }}
              className={
                "recentlyused" === selected
                  ? `nav-row-selected `
                  : `nav-row  GA-sidenav`
              }
              id="recentlyused"
              onClick={() => {
                setopenRecent(true);
              }}
            >
              <span class="material-icons-round">history</span>
              <p id="recentlyused" className="row-name GA-sidenav">
                Recents
              </p>
            </div> */}
          </div>
          <div>
            {SideNavDataBottom.map((el) => (
              <Link
                key={el.path}
                // onClick={() => handleChangeRoute(el.path)}
                to={`${el.path}`}
                id={el.title}
                className={
                  el.path === selected
                    ? `nav-row-selected `
                    : `nav-row  GA-sidenav`
                }
              >
                <img
                  alt="FAQs"
                  id={el.title}
                  class="nav-icon GA-sidenav"
                  src={
                    el.icon === "faq"
                      ? el.path === selected
                        ? faq
                        : faqwhite
                      : el.path === selected
                      ? help
                      : helpwhite
                  }
                />
                {/* <span class="material-icons-round nav-icon">{el.icon}</span> */}
                <p id={el.title} className="row-name GA-sidenav">
                  {el.title}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </nav>
      {<RecentDrawer open={openRecent} handleCloseRecent={handleCloseRecent} />}
      {/* )} */}
    </>
  );
}

Sidenav.propTypes = {
  allData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  isDetailsSubmitable: state.registerNewApp.statusData.isDetailsSubmitable,
  allData: state.data,
  dirty: state.registerNewApp.statusData.dirty,
});

const mapDispatchToProps = { updateDialogInfo };

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
