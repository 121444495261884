import React, { useEffect, useState } from "react";
import { Box, Stack, Drawer } from "@mui/material";
import { useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
// import { toast } from "react-toastify";
// import { useMediaQuery } from "@mui/material";
// import GiveAccessComponent from "../../Components/GiveAccessComponent";
// import InputFiled from "../Components/InputField";
import runError from "../../../../../assets/images/register-images/run-error.svg";
import runSuccess from "../../../../../assets/images/register-images/run-success.svg";
import { BootstrapInput, statusIconStyle } from "../../../Register/style";

import SelectComponent from "../../Components/SelectComponents";
import Loader from "../../../../components/Loader/Loader";

import "../../index.scss";
import {
  // updateAppDetails,
  update_statusData,
  update_appData,
} from "../../../../redux/actions";
//hooks
// import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
//styles
import { theme } from "antd";

// import drafticon from "../../../../assets/images/save_as_draft.svg";

// function fetchAPI(payload) {
//   const url = "/api/detailsupdate";
//   return fetch(url, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(payload),
//   });
// }

const InputFiled = (props) => {
  const {
    handleChange,
    value,
    data,
    width,
    isFieldsEditable,
    waiting,
    isAppUnique,
    validationMsg,
    hasError,
    isFieldVibrating,
    isFieldRed,
    URLValid,
    localState = {},
    theme,
  } = props;
  const { label, key, required } = data;
  // console.log("DATA", data);

  return (
    <>
      <Box
        position={"relative"}
        sx={{
          marginBottom: "20px",
        }}
      >
        {key == "description" ? (
          <div
            className={`appDetailsLabel-heading ${theme}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              APP DESCRIPTION
              <span
                style={{
                  display: required || key === "url" ? "" : "none",
                  color: "red",
                }}
              >
                *
              </span>
              :
            </div>
            <span
              style={{
                color:
                  (value && value.length > 500) || !value ? "red" : "inherit",
              }}
            >
              {value ? value.length : 0} / 500
            </span>{" "}
          </div>
        ) : (
          <div className={`appDetailsLabel-heading ${theme}`}>
            APP NAME
            <span
              style={{
                display: required || key === "url" ? "" : "none",
                color: "red",
              }}
            >
              *
            </span>
            :
          </div>
        )}
        <BootstrapInput
          onChange={(e) => handleChange(key, e.target.value.trimStart())}
          value={value}
          multiline={
            key === "description" || key === "sub_category_description"
          }
          placeholder={
            key === "sub_category_name" ? "Enter text here" : `Enter ${label}`
          }
          disabled={isFieldsEditable}
          className={`${isFieldVibrating && "shake-category-field"} ${
            hasError || isFieldRed ? "error" : ""
          }`}
          fullWidth
          rows={
            key === "description" || key === "sub_category_description" ? 2 : 1
          }
        />
        {hasError && (
          <div className="validation_msg" style={{ color: "red" }}>
            This field is required*
          </div>
        )}
        {key === "app_name" && (
          <>
            <Box position={"absolute"} right={0} top="23px" zIndex={10}>
              {waiting && <Loader />}
              {!waiting && isAppUnique ? (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              ) : null}
              {!waiting && !isAppUnique && validationMsg !== "" && (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Already exists"
                  alt="Failed"
                />
              )}
            </Box>
            {validationMsg && !isAppUnique && !waiting && (
              <div
                className="validation_msg"
                style={{ color: isAppUnique ? "green" : "red" }}
              >
                {validationMsg}
              </div>
            )}
          </>
        )}
        {key === "url" && (
          <>
            <Box position={"absolute"} right={0} top="21px" zIndex={10}>
              {URLValid.status === "Valid" && (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              )}
              {URLValid.status === "Invalid" && (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Invalid URL"
                  alt="Failed"
                />
              )}
            </Box>
            {URLValid.status === "Invalid" && (
              <div className="validation_msg" style={{ color: "red" }}>
                {URLValid.msg}
              </div>
            )}
          </>
        )}
      </Box>
    </>
  );
};

const DetailsDrawer = ({
  templates,
  appFields,
  isFieldsEditable,
  isChanged,
  isAdminRegistering,
  waiting,
  isAppUnique,
  validationMsg,
  // appDetails,
  // handleSaveTagsDatasets,
  visited,
  theme,
  draft_app,
  dirty,
  showDetailsDrawer,
  // handleDraftSubmit,
}) => {
  const { appInfo } = useParams();
  const dispatch = useDispatch();
  // const eventTracker = useAnalyticsEventTracker(
  //   "Details",
  //   appDetails.data.app_id
  // );

  // const [loading, setLoading] = useState(false);
  // const [alert, setAlert] = useState(false);
  // const [message, setMessage] = useState(false);
  const [missingValues, setMissingValues] = useState({});
  // const isSmallScreen = useMediaQuery((theme) =>
  //   theme.breakpoints.down("400px")
  // );
  let app_id;
  if (appInfo) {
    const data = appInfo.split("_");
    app_id = data[0];
  }

  const handleChange = (key, value, blob_name, app_logo_type) => {
    if (missingValues[key]) {
      setMissingValues({ ...missingValues, [key]: false });
    }
    //run for app_logo and app_name to update app meta else update app_details
    if (key === "app_logo" || key === "app_name") {
      //run for app_logo
      if (app_id || isAdminRegistering || draft_app) {
        // setting values for comparison if app is in updating state
        // setIsChanged({
        //   ...isChanged,
        //   app_meta: { ...isChanged.app_meta, [key]: value },
        // });
        dispatch(
          update_appData({
            isChanged: {
              ...isChanged,
              app_meta: { ...isChanged.app_meta, [key]: value },
            },
          })
        );
      }
      if (blob_name || app_logo_type) {
        // setAppFields({
        //   ...appFields,
        //   [key]: value,
        //   blob_name,
        //   app_logo_type,
        // });
        dispatch(
          update_appData({
            appFields: {
              ...appFields,
              [key]: value,
              blob_name,
              app_logo_type,
            },
          })
        );
        if (app_id || isAdminRegistering || draft_app) {
          // setting values for comparison if app is in updating state
          // setIsChanged({
          //   ...isChanged,
          //   app_meta: {
          //     ...isChanged.app_meta,
          //     [key]: value,
          //     blob_name,
          //     app_logo_type,
          //   },
          // });
          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                app_meta: {
                  ...isChanged.app_meta,
                  [key]: value,
                  blob_name,
                  app_logo_type,
                },
              },
            })
          );
        }
      } else {
        //run for app_name and also update app_handle if registering
        if (key === "app_name" && visited.configurations) {
          // setVisited({ ...visited, configurations: false });
          dispatch(
            update_appData({ visited: { ...visited, configurations: false } })
          );
        }
        // updating the app_handle value everytime when app name changes while registering but not updating
        let app_details = appFields.app_details;
        if (!(isAdminRegistering || appInfo) && key === "app_name") {
          const app_handle = value?.trim()?.toLowerCase()?.replaceAll(" ", "_");
          app_details = { ...app_details, app_handle };
        }
        // setAppFields({
        //   ...appFields,
        //   [key]: value,
        //   app_details,
        // });
        dispatch(
          update_appData({
            appFields: {
              ...appFields,
              [key]: value,
              app_details,
            },
          })
        );

        if (app_id || isAdminRegistering || draft_app) {
          // setting values for comparison if app is in updating state
          // setIsChanged({
          //   ...isChanged,
          //   app_meta: { ...isChanged.app_meta, [key]: value },
          // });
          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                app_meta: { ...isChanged.app_meta, [key]: value },
              },
            })
          );
        }
      }
    } else {
      if (app_id || isAdminRegistering || draft_app) {
        // setting values for comparison if app is in updating state
        // setIsChanged({
        //   ...isChanged,
        //   app_details: { ...isChanged.app_details, [key]: value },
        // });
        dispatch(
          update_appData({
            isChanged: {
              ...isChanged,
              app_details: { ...isChanged.app_details, [key]: value },
            },
          })
        );
      }
      // setAppFields({
      //   ...appFields,
      //   app_details: { ...appFields.app_details, [key]: value },
      // });
      dispatch(
        update_appData({
          appFields: {
            ...appFields,
            app_details: { ...appFields.app_details, [key]: value },
          },
        })
      );
    }
    // checking if user is registering but didn't save changes and trying to navigate to other page.
    if (!appInfo && !isAdminRegistering && !dirty) {
      // setDirty(true);
      dispatch(update_statusData({ dirty: true }));
    }
  };

  const getRequiredComponentDetails = (data) => {
    const { type, key } = data;
    switch (type) {
      // Input field
      case "input":
        return (
          <InputFiled
            data={data}
            handleChange={handleChange}
            value={
              key === "app_name"
                ? appFields?.app_name
                : appFields?.app_details[key] || ""
            }
            width={6}
            isFieldsEditable={isFieldsEditable}
            waiting={waiting}
            isAppUnique={isAppUnique}
            validationMsg={validationMsg}
            hasError={missingValues[key] || false}
            theme={theme}
          />
        );
      // Applogo
      case "url":
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "43px",
              border: "1px solid",
              borderRadius: "40px",
              width: "43px",
            }}
          >
            {appFields.app_logo_type === "uploaded_image" ? (
              <img
                src={appFields?.app_logo_url || appFields[key]}
                alt="uploaded icon"
                style={{
                  width: "25px",
                }}
              />
            ) : (
              <span
                class="material-icons-outlined"
                style={{ fontSize: "25px" }}
              >
                {appFields[key] || "format_align_center"}
              </span>
            )}
          </Box>
        );

      case "dropdown":
        return (
          <SelectComponent
            data={data}
            handleChange={handleChange}
            value={appFields?.app_details[key]}
            width={5}
            isFieldsEditable={isFieldsEditable}
            appInfo={appInfo}
            isAdminRegistering={isAdminRegistering}
            hasError={missingValues[key] || false}
            missingValues={missingValues}
            setMissingValues={setMissingValues}
          />
        );
      default:
        return "";
    }
  };

  // const handleSaveDetails = async () => {
  //   let data = {};
  //   const { app_meta, approval_meta, app_details } = appDetails.data;
  //   eventTracker("Save details clicked", "save");
  //   data = {
  //     app_meta: { ...app_meta, ...(isChanged.app_meta || {}) },
  //     app_details: { ...app_details, ...(isChanged.app_details || {}) },
  //     app_id: app_id || appDetails.data.app_id,
  //     approval_meta: approval_meta,
  //   };

  //   try {
  //     setLoading(true);
  //     const res = await fetchAPI(data);
  //     const reqres = await res.json();
  //     if (reqres.status.statusCode === "datareckitt-200") {
  //       handleSaveTagsDatasets();
  //       setMessage(reqres.status.statusMessage);
  //       setAlert(true);
  //       // setIsChanged({
  //       //   app_meta: {},
  //       //   app_details: {},
  //       //   provisioning_details: {},
  //       // });
  //       dispatch(
  //         update_appData({
  //           isChanged: {
  //             app_meta: {},
  //             app_details: {},
  //             provisioning_details: {},
  //           },
  //         })
  //       );
  //       dispatch(
  //         updateAppDetails(
  //           {
  //             type: "app_id",
  //             app_id: app_id || appDetails.data.app_id,
  //             approval_status: appDetails.data.approval_meta?.approval_status,
  //           },
  //           isAdminRegistering ? "admin" : ""
  //         )
  //       );
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     toast.error("Oops! unable to save changes");
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <Drawer
        anchor="left"
        open={showDetailsDrawer}
        variant="persistent"
        sx={(theme) => ({
          "& .MuiDrawer-paper": {
            left: "60px",
            top: "70px",
            height: "calc(100vh - 95px)",
            width: "360px",
            borderLeft: "none",
            boxShadow: "0px 4px 12px 4px #434343",
            backgroundColor: theme.palette.mode === "dark" ? "#000" : "#f7f7f7",
          },
        })}
      >
        <Box
          sx={{
            backgroundColor: theme === "light" ? "#fff" : "#1E1E1E",
          }}
          className="drawer-box"
        >
          <div>
            <div
              style={{
                display: "flex",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              {templates &&
                templates?.fields?.map((item) => {
                  if (item.key === "app_logo") {
                    return (
                      <div
                        style={{
                          paddingRight: "10px",
                          marginLeft: "0px",
                        }}
                      >
                        {getRequiredComponentDetails(item)}
                      </div>
                    );
                  }
                })}
              {templates &&
                templates?.fields?.map((item) => {
                  if (item.key === "app_name") {
                    return (
                      <div style={{ width: "100%" }}>
                        {getRequiredComponentDetails(item)}
                      </div>
                    );
                  }
                })}
            </div>
            <Stack
              direction={"column"}
              spacing={0}
              sx={{ width: "100%", alignItems: "left" }}
            >
              {templates &&
                templates?.fields?.map((item) => {
                  if (item.key === "description") {
                    return <>{getRequiredComponentDetails(item)}</>;
                  }
                })}
            </Stack>
            <Stack
              direction={"column"}
              spacing={3}
              sx={{ width: "100%", alignItems: "left" }}
            >
              {templates &&
                templates?.fields?.map((item) => {
                  if (
                    item.key === "app_category" ||
                    item.key === "line_of_business" ||
                    item.key === "geography" ||
                    item.key === "associated_datasets" ||
                    item.key === "function" ||
                    item.key === "tags"
                  ) {
                    return <>{getRequiredComponentDetails(item)}</>;
                  }
                })}
            </Stack>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  dirty: state.registerNewApp.statusData.dirty,
  waiting: state.registerNewApp.statusData.waiting,
  isAppUnique: state.registerNewApp.statusData.isAppUnique,
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  showDetailsDrawer: state.registerNewApp.statusData.showDetailsDrawer,
  isChanged: state.registerNewApp.appData.isChanged,
  appFields: state.registerNewApp.appData.appFields,
  visited: state.registerNewApp.appData.visited,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(DetailsDrawer);
