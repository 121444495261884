// Import required libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Import styles
import "./AppCard.scss";
import "./LargeCard.scss";

// Import assets/images
import delete_icon from "../../../assets/images/delete_icon.svg";

function EditCard(props) {
  let {
    row = {},
    disabled = false,
    gridList,
    optionsList,
    setItems,
    setAddAppArr,
    tempLayout,
    user,
  } = props;
  const [hover, setHover] = useState(false);

  const HandleDelete = () => {
    // Remove this app object from the ITEMS list
    const newDeletedAppArr = gridList.filter(
      (app) => row.app_id !== app.app_id
    );
    setItems({ left: [...newDeletedAppArr] });

    // ADD this APP-name to the options list
    const NewOptionsList = optionsList.concat(row.app_meta.app_name);
    setAddAppArr(NewOptionsList);
  };
  return (
    <>
      <div style={{ userSelect: "none" }}>
        <div
          disabled={disabled}
          onMouseOver={() => {
            setHover(true);
          }}
          onMouseOut={() => {
            setHover(false);
          }}
          class={
            hover
              ? `small-card-container ${user.ui_preferences.theme}-card-background shakable-app-card`
              : `small-card-container ${user.ui_preferences.theme}-card-background`
          }
        >
          {tempLayout.length > 1 && (
            <img
              style={{
                position: "absolute",
                left: " -6px",
                top: "-6px",
                height: "15px",
                display: hover ? "block" : "none",
              }}
              src={delete_icon}
              alt="delete"
              id={row.app_id}
              className="GA-delete-app-layout"
              onClick={HandleDelete}
            ></img>
          )}
          <div class="application-upper-container">
            <div class="application-category-name">
              {row.app_details.app_category}
            </div>
            <div style={{ display: "none" }} class="application-access-type">
              {row.approval_meta.approval_status !== "published"
                ? row.approval_meta.approval_status
                : row.access_details?.access_type || "public"}
            </div>
          </div>
          {row.app_meta?.app_logo_type === "google_font" ? (
            <span
              class={`material-icons-outlined app-icon ${user.ui_preferences.theme}-icon`}
            >
              analytics
            </span>
          ) : (
            <img
              src={row.app_meta?.app_logo}
              className="app-icon GA-app-card-clicked"
              id={row?.app_id}
              style={{
                width: "24px",
                padding: "6px",
              }}
              alt="icon"
            />
          )}
          <div
            class={`text-content-container text-${user.ui_preferences.theme}`}
          >
            <p
              className={`app-name-text ${
                hover ? "new-theme-hover-class" : ""
              }`}
            >
              {row.app_meta.app_name}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

EditCard.propTypes = {
  name: PropTypes.string,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(EditCard);
