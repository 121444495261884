import C from "../constants";

export default function helpCenter(state = {}, payload) {
    let { type, data } = payload;
    switch (type) {
        case C.GET_HELP_CENTER_TEMPLATES:
            return {
                ...state,
                helpCenterTemplate: {
                    status: "loading",
                    message: "",
                    data: {},
                },
            };
        case C.HELP_CENTER_TEMPLATES_RECEIVED:
            return {
                ...state,
                helpCenterTemplate: {
                    status: data.status,
                    message: data.msg || "",
                    data: data.data || {},
                },
            };
        case C.SET_HELP_CENTER_STATE:
            return {
                ...state,
                helpCenterState: {
                    ...state.helpCenterState,
                    ...data,
                },
            };
        case C.GET_APP_LIST:
            return {
                ...state,
                allAppList: {
                    status: "loading",
                    message: "",
                    data: [],
                },
            };
        case C.APP_LIST_RECEIVED:
            return {
                ...state,
                allAppList: {
                    status: data.status,
                    message: data.msg || "",
                    data: data.data || {},
                },
            };
        case C.SEND_MAIL_TO_APP_OWNER:
            return {
                ...state,
                sendMailToOnwer: {
                    status: "loading",
                    message: "",
                },
            };
        case C.MAIL_TO_APP_OWNER_SENT:
            return {
                ...state,
                sendMailToOnwer: {
                    status: data.status,
                    message: data.msg || "",
                },
            };
        case C.SAVE_TICKET_ID:
            return {
                ...state,
                saveTicketMeta: {
                    status: "loading",
                    message: "",
                },
            };
        case C.TICKET_ID_SAVED:
            return {
                ...state,
                saveTicketMeta: {
                    status: data.status,
                    message: data.msg || "",
                },
            };
        default:
            return state;
    }
}
