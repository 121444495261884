import C from "../constants";

export default function signup(state = {}, payload) {
    let { type, data } = payload;
    switch (type) {
        case C.GET_SIGNUP_TEMPLATES:
            return {
                ...state,
                signup_templates: {
                    status: "loading",
                    message: "",
                    data: [],
                },
            };
        case C.SIGNUP_TEMPLATES_RECEIVED:
            return {
                ...state,
                signup_templates: {
                    status: "success",
                    message: "",
                    data,
                },
            };
        case C.UPDATE_JOB_ROLE_OTHER_OPTIONS:
            return {
                ...state,
                job_role_others_data: {
                    status: "loading",
                    message: "",
                },
            };
        case C.JOB_ROLE_OTHER_OPTIONS_UPDATED:
            return {
                ...state,
                job_role_others_data: {
                    status: data.status,
                    message: data.msg,
                },
            };
        case C.UPDATE_FUNCTION_OTHER_OPTIONS:
            return {
                ...state,
                functions_others_data: {
                    status: "loading",
                    message: "",
                },
            };
        case C.FUNCTION_OTHER_OPTIONS_UPDATED:
            return {
                ...state,
                functions_others_data: {
                    status: data.status,
                    message: data.msg,
                },
            };
        default:
            return state;
    }
}
