import { connect } from "react-redux";
import React from "react";
import "../../index.scss";
const ProvisioningAccessList = ({ data, theme }) => {
  return (
    <>
      <div>
        <div className="access-list-table-div">
          <div className="access-list-row-left">Email</div>
          <div className="access-list-row-right">Access Level</div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {data?.provisioning_access_list?.map((row) => (
            <div
              style={{
                height: "32px",
                flexDirection: "row",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                className="access-list-row"
                style={{
                  background: theme === "dark" ? "#282828" : "#FFF",
                  marginRight: "12px",
                }}
              >
                {row.email}
              </div>
              <div
                className="access-list-row"
                style={{
                  background: theme === "dark" ? "#282828" : "#FFF",
                  marginLeft: "12px",
                  textTransform: "capitalize",
                }}
              >
                {row.access_level}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(ProvisioningAccessList);
