import { Container } from "@mui/material";
import React from "react";

const CreateRegisterBodyContainer = ({ children, height, ...rest }) => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "25px",
        zIndex: 10,
        overflowY: "auto",
        margin: "0px auto",
        position: "relative",
        minWidth: "834px",
        // maxWidth: "1730px",
        // width: "91%",
        alignItems: "center",
        "@media (min-height: 1080px)": {
          gap: "50px",
        },
        ...rest,
        "&.MuiContainer-root": {
          maxWidth: "none",
        },
      })}
    >
      {children}
    </Container>
  );
};

export default CreateRegisterBodyContainer;
