import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { debounce } from "throttle-debounce";
// import AutoWithAsyncLoad from "../../../../components/AutoCompletes/AutoWithAsyncLoad";
import AutoWithAsyncLoad from "../../../Register/components/AutoCompletesComponents/AutoWithAsyncLoad";
import Chips from "../../../Register/components/Chips";
import { getUsersList } from "../../../../redux/actions";

const SelectEmailComponent = ({
    values = [],
    handleChange,
    fieldkey,
    usersList,
    handleDelete,
    userInfo,
    helpCenterState,
}) => {
    const { formData } = helpCenterState;
    const dispatch = useDispatch();
    const [userListOptions, setUserListOptions] = useState([]);
    const [showWarning, setShowWarning] = useState(false);

    const removeWarning = () => {
        setTimeout(() => {
            setShowWarning(false);
        }, 3000);
    };

    // const handleGetUsers = (val) => {
    //     if (!val) return;
    //     dispatch(getUsersList({ term: val }));
    // };

    const debounceFunc = debounce(800, (val) => {
        dispatch(getUsersList({ term: val }));
    });

    const handleGetUsers = (val) => {
        if (!val) return;
        debounceFunc(val);
    };

    const handleSelect = (val) => {
        if ((formData[fieldkey] || []).length >= 5) {
            setShowWarning(true);
            removeWarning();
            return;
        }
        handleChange(fieldkey, val.user_id, "multiple");
    };

    const handleDeleteChip = (chip) => {
        handleDelete(fieldkey, chip);
    };
    useEffect(() => {
        if (usersList.status === "success") {
            setUserListOptions(usersList.data);
        }
    }, [usersList]);

    return (
        <div className="help-center-multiselect">
            {showWarning && (
                <div className="help-center-warning-email">
                    You can't select more than 5 emails.
                </div>
            )}
            {values.map((item) => (
                <Chips
                    key={item}
                    handleDelete={handleDeleteChip}
                    chip={item}
                    isFieldsEditable={userInfo.preferred_username === item}
                />
            ))}
            <Box width="300px">
                <AutoWithAsyncLoad
                    handleSelect={handleSelect}
                    list={usersList}
                    options={userListOptions}
                    handleChange={handleGetUsers}
                    my="5px"
                    placeholder="Add email"
                />
            </Box>
        </div>
    );
};
const mapStateToProps = (state) => ({
    userInfo: state.user,
    usersList: state.AppsData.usersList,
    helpCenterState: state.helpCenter.helpCenterState,
});
export default connect(mapStateToProps)(SelectEmailComponent);
