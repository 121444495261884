import React, { useMemo } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import ReactGridTemplate from "../../../components/ReactGridTemplate";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    // height: "calc(80vh - 100px)",
    // overflow: "auto",
  },
  headerContainer: {
    // "& .react-grid-layout": {
    //     height: "142px !important",
    //     overflow: "auto",
    // },
    "& .react-resizable-handle": {
      display: "none !important",
    },
  },
  biggerHeight: {
    height: "calc(80vh - 198px)",
    overflow: "auto",
    "& .react-resizable-handle": {
      display: "none !important",
    },
  },
  smallHeight: {
    height: "calc(80vh - 90px)",
    overflow: "auto",
    "& .react-resizable-handle": {
      display: "none !important",
    },
  },
  commonReportContainer: {
    "& .react-resizable-handle": {
      display: "none !important",
    },
  },
}));

const CommonReport = ({ handleNavigation, data = [] }) => {
  const classes = useStyles();

  const getHeaderData = useMemo(
    () => data.filter((item) => item.sub_category_type === "header"),
    [data]
  );

  const getSubHeaderData = useMemo(
    () => data.filter((item) => item.sub_category_type === "sub_header"),
    [data]
  );

  const getReports = useMemo(
    () =>
      data.filter(
        (item) =>
          item.sub_category_type !== "header" &&
          item.sub_category_type !== "sub_header"
      ),
    [data]
  );

  const handleSubHeaderLayout = (layout) => {};

  const handleReportLayout = (layout) => {};

  const getMaxSubValue = (k = "y") => {
    if (!getSubHeaderData.length) return 0;
    const data = getSubHeaderData?.reduce(function (prev, current) {
      if (+current.position_meta[k] > +prev.position_meta[k]) {
        return current;
      } else {
        return prev;
      }
    });
    return data.position_meta?.y;
  };

  // console.log("getMaxSubValue()",getMaxSubValue())
  // console.log("getHeaderdata",getHeaderData.length)

  return (
    <>
      {getSubHeaderData.length ? (
        <div className={classes.headerContainer}>
          <ReactGridTemplate
            data={getSubHeaderData}
            handleNavigation={handleNavigation}
            onLayoutChange={handleSubHeaderLayout}
            customData={{ minH: 2, maxH: 2 }}
            isBounded={true}
            isDraggable={false}
            isResizable={false}
            notEditable
          />
        </div>
      ) : null}
      <Box
        sx={{
          overflow: "auto",
          height: getSubHeaderData.length
            ? `calc(100vh - 155px - ${
                getHeaderData.length > 0 ? getHeaderData.length * 60 : 0
              }px - ${getMaxSubValue() * 65}px - 120px)`
            : `calc(100vh - 115px - ${
                getHeaderData.length > 0 ? getHeaderData.length * 60 : 0
              }px - ${getMaxSubValue() * 60}px)`,
        }}
        className={classes.commonReportContainer}
      >
        <ReactGridTemplate
          data={getReports}
          handleNavigation={handleNavigation}
          onLayoutChange={handleReportLayout}
          isDraggable={false}
          isResizable={false}
          notEditable
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
  appDashboardState: state.appDashboard.appDashboardState,
  appTemplateState: state.appTemplate.appTemplateState.templateData,
});

export default connect(mapStateToProps)(CommonReport);
