// Import required libraies
import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { v4 } from "uuid";

// Import Custom Component
import Loader from "../../../components/Loader/Loader";
import noDataImage from "../../../../assets/images/undraw_empty.svg";

// Import action creators

// Import utils/data
import { config } from "../../../config/config";
import { makeDefaultResponseJson } from "../../../utils/utils";

// Import styles
import "./DataAssetsTable.scss";

const columns = [
    {
        id: "dataset_name",
        label: "Dataset Name",
        type: "text",
        colspan: "20%",
        align: "left",
    },
    {
        id: "last_updated_date",
        label: "Last Updated",
        type: "text",
        colspan: "1",
        align: "left",
    },
    {
        id: "data_quality",
        label: "Data Quality",
        type: "text",
        colspan: "1",
        align: "left",
    },
    {
        id: "lowest_time_grain",
        label: "Time Grain",
        type: "text",
        colspan: "1",
        align: "left",
    },
    {
        id: "lowest_geo_grain",
        label: "Geo Grain",
        type: "text",
        colspan: "1",
        align: "left",
    },
];

const StyledTableCell = withStyles((theme) => ({
    body: {
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#282828",
        fontFamily: "Energy",
        width: "10%",
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: "4px",
        maxHeight: "calc(100vh - 260px)",
        width: "100%",
        paddingLeft: "1px",
        paddingBottom: "1px",
    },
    tableRow: {
        padding: "9px !important",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "13px !important",
        lineHeight: "23px",
        "&:last-child th, &:last-child td": {
            borderBottom: 0,
        },
        "&:nth-of-type(odd)": {
            // backgroundColor: "white !important",
            backgroundColor:
                theme.palette.mode === "dark"
                    ? "#282828 !important"
                    : "white !important",
            "&:hover": {
                // backgroundColor: "#4eafb342 !important",
            },
        },
        "&:nth-of-type(even)": {
            backgroundColor: "rgba(70, 89, 106, 0.07) !important",
            "&:hover": {
                // backgroundColor: "#4eafb342 !important",
            },
        },
    },
    table: {
        borderCollapse: "collapse",
        "& .MuiTableCell-stickyHeader": {
            backgroundColor: "#E6E8ED",
            color: "rgba(70, 89, 106, 1)",
        },
        "& tbody td:first-child": {
            borderTopLeftRadius: "6px",
            borderBottomLeftRadius: "6px",
        },
        "& tbody td:last-child": {
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
        },
        "& thead th": {
            fontWeight: "600",
            color: "#282828",
            fontSize: "13px",
            borderBottom: "none",
            fontFamily: "Energy",
            "&:first-child": {
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
            },
            "&:last-child": {
                borderTopRightRadius: "6px",
                borderBottomRightRadius: "6px",
            },
        },
        "& tbody td": {
            fontWeight: "300",
            fontSize: "12px",
            borderBottom: 0,
        },
        "& tbody > tr > td.dataset_name": {
            verticalAlign: "top",
            textAlign: "left",
        },
        "& tbody tr:hover": {
            // cursor: "pointer",
        },
        "& thead": { borderBottom: "none" },
        "& tbody tr:first-child td:first-child": {
            borderTopLeftRadius: "4px",
        },
        "& tbody tr:first-child td:last-child": {
            borderTopRightRadius: "4px",
        },
        "& tbody tr:last-child td:first-child": {
            borderBottomLeftRadius: "4px",
        },
        "& tbody tr:last-child td:last-child": {
            borderBottomRightRadius: "4px",
        },
        "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
            color: "#282828",
        },
        "& .MuiTypography-colorInherit": {
            color: "#282828 !important",
        },
        "& .MuiTypography-body2": {
            fontFamily: "Energy !important",
        },
    },
    noDataFound: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "6%",
        fontSize: "13px",
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : theme.palette.dark.main,
        "& img": {
            height: "15vh",
            width: "100%",
        },
    },
}));

function DataAssetsTable(props) {
    let { filterDataSources, updateUserInfo } = props;
    const classes = useStyles();
    const history = useHistory();
    const [favIcon, setFavIcon] = useState([]);

    const changeFavIcon = (e, datasetid) => {
        let arr = [...favIcon];
        if (arr.includes(datasetid)) {
            arr = arr.filter((data) => data !== datasetid);
        } else {
            arr.push(datasetid);
        }
        setFavIcon([...arr]);
    };

    return (
        <>
            {filterDataSources.error && (
                <p className="no-data">{config.messages.tryAgainMsg}</p>
            )}
            {filterDataSources.loading && <Loader />}
            {!filterDataSources.error &&
            !filterDataSources.loading &&
            filterDataSources.data ? (
                filterDataSources?.data?.length ? (
                    <TableContainer className={classes.container}>
                        <Table stickyHeader className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={v4()}
                                            align={column.align}
                                            // width={column.colspan}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        align="right"
                                        width="20%"
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filterDataSources.data.map((row) => (
                                    <TableRow
                                        key={v4()}
                                        row={row}
                                        columns={columns}
                                        className={classes.tableRow}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <StyledTableCell
                                                    key={v4()}
                                                    // width={column.colspan}
                                                    align={column.align}
                                                    className={column.id}
                                                >
                                                    {value ? value : "-"}
                                                </StyledTableCell>
                                            );
                                        })}
                                        <StyledTableCell
                                            padding="checkbox"
                                            align="right"
                                            width="20%"
                                        >
                                            <div className="view-link">
                                                <button
                                                    className="view-link-button"
                                                    onClick={() => {
                                                        history.push(
                                                            `/data-assets_published/sigview/${row?.dataset_name?.trim()}`
                                                        );
                                                    }}
                                                >
                                                    Explore
                                                </button>
                                                <i
                                                    className="material-icons-outlined favourite-icon"
                                                    onClick={(e) => {
                                                        changeFavIcon(
                                                            e,
                                                            row.dataset_id
                                                        );
                                                    }}
                                                >
                                                    {favIcon?.includes(
                                                        row.dataset_id
                                                    )
                                                        ? "star_border"
                                                        : "star"}
                                                </i>{" "}
                                            </div>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <div className={classes.noDataFound}>
                        <img src={noDataImage} alt="" />
                        <p className="no-data">
                            <b>{config.messages.noDatasources}</b>
                        </p>
                    </div>
                )
            ) : null}
        </>
    );
}

export default DataAssetsTable;
