import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Dialog, DialogContent } from "@mui/material";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { connect } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import TableBodyComponent from "../../UserMangement/UserAccessMangement/TableBodyComponent";

//styles
import "../../UserMangement/UserAccessMangement/style.scss";
import { useStyles1 } from "../../../components/UserTable/style";
import { paperStyle, useStyles } from "../../UserMangement/style";

// assets
import no_pending from "../../../../assets/images/no_pending.svg";

const headerData = [
  { id: "request_by", label: "Requested By" },
  { id: "requester_comment", label: "Comment" },
  { id: "access_raised_on", label: "Access Raised On" },
  { id: "access_granted_on", label: "Access Granted On" },
  { id: "access_level", label: "Access level" },
  { id: "action", label: "Action" },
];

const TableHeaderCell = (props) => {
  const { column } = props;
  const classesTable = useStyles();

  return (
    <>
      <TableCell
        className={`${classesTable.tableHeadCell} ${
          column.id === "user_id" ? classesTable.fixedWidth : ""
        }`}
        key={column.id}
        align={column.align}
        style={{
          textAlign: "center",
          maxWidth: column.id === "requester_comment" ? "100px" : "inherit",
        }}
      >
        <div style={{ display: "flex" }}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {column.label}
            </div>
          </Box>
        </div>
      </TableCell>
    </>
  );
};

const useStyles2 = makeStyles((theme) => ({
  heading: {
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    fontSize: "21px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "23px",
    marginBottom: "18px",
  },
  points: {
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "23px",
    marginBottom: "5px",
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
  },
}));

export const WarningPopUp = ({ handleClose, open }) => {
  const classes = useStyles2();

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          backgroundImage: "none",
          boxShadow: "4px 4px 12px 4px #434343",
        },
      }}
    >
      <DialogContent>
        <Paper sx={paperStyle}>
          <Box className={classes.points} textAlign="center">
            Please save the unsaved changes before approving
          </Box>
          <Box className={classes.points} mt={1}>
            or denying the pending requests
          </Box>
        </Paper>
        <div className={classes.closeIcon}>
          <button onClick={handleClose}>close</button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
const PendingRequestsPopUp = ({
  handleClose,
  open,
  user_access_list,
  userInfo,
  theme,
  setUsersList,
  app_id,
  requestList,
  setReloadList,
  refreshed_user_access_list,
  accessList,
}) => {
  const classes = useStyles2();
  const classesTable = useStyles1();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [wait, setWait] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper": {
          backgroundImage: "none",
          boxShadow: "4px 4px 12px 4px #434343",
        },
      }}
    >
      <DialogContent>
        <div className={classes.closeIcon}>
          {!wait && (
            <span class="material-icons-outlined" onClick={handleClose}>
              close
            </span>
          )}
          {wait && <Loader />}
        </div>
        {requestList.length === 0 ? (
          <Paper sx={paperStyle}>
            <img src={no_pending} alt="No pending requests" />
          </Paper>
        ) : (
          <Paper sx={paperStyle}>
            <TableContainer sx={{ maxHeight: "calc(100vh - 338px)" }}>
              <Table stickyHeader="sticky table">
                <TableHead>
                  <TableRow>
                    {headerData.map((column, i) => (
                      <TableHeaderCell key={i} column={column} />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item) => (
                      <TableBodyComponent
                        key={item.request_id}
                        data={item}
                        userInfo={userInfo}
                        theme={theme}
                        setUsersList={setUsersList}
                        tab="access_management"
                        app_id={app_id}
                        setReloadList={setReloadList}
                        setWait={setWait}
                        refreshed_user_access_list={refreshed_user_access_list}
                        accessList={accessList}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!requestList.length && user_access_list.status !== "loading" && (
              <Box textAlign="center" mt={1}>
                No Data found.
              </Box>
            )}
            {user_access_list.status === "loading" && (
              <Box textAlign="center">
                <Loader />
              </Box>
            )}
            <TablePagination
              className={classesTable.pagination}
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={requestList.length}
              labelRowsPerPage="Requests per page :"
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateTopProps = (state) => ({
  userInfo: state.user,
  user_access_list: state.userAccessManagement.user_access_list,
  theme: state.user.ui_preferences?.theme,
  refreshed_user_access_list:
    state.userAccessManagement.refreshed_user_access_list,
});
export default connect(mapStateTopProps)(PendingRequestsPopUp);
