import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import RegisterBodyContainer from "./RegisterBodyContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSkeleton-root": {
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.dark.light : "#e5e5e5",
    },
  },
  marginRoot: {
    marginTop: "35px !important",
  },
}));

const style = { maxWidth: "314px", height: "125px", flex: 1 };
export const RegisterNewAppSkeletons = () => {
  const classes = useStyles();
  return (
    <RegisterBodyContainer>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
          gap={1}
          mt="25px"
          className={classes.root}
        >
          <Skeleton animation="wave" variant="rectangular" sx={style} />
          <Skeleton animation="wave" variant="rectangular" sx={style} />
          <Skeleton animation="wave" variant="rectangular" sx={style} />
        </Box>
        <Box padding={"45px"}>
          <Skeleton animation="wave" variant="text" height={30} width="30%" />
          <Skeleton animation="wave" variant="text" height={60} width="80%" />
        </Box>
      </Box>
    </RegisterBodyContainer>
  );
};
export const UpdateAppSkeleton = ({ draft }) => {
  const classes = useStyles();
  return (
    <RegisterBodyContainer>
      <div style={{ display: "flex" }}>
        <div style={{ padding: "10% 15%" }}>
          <Skeleton
            animation="wave"
            variant="circular"
            height={"120px"}
            width={"120px"}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Box>
            {new Array(3).fill(0).map((_, i) => (
              <div style={{ display: "flex" }} key={i}>
                <div style={{ width: "40%", marginRight: "25px" }}>
                  <Skeleton animation="wave" variant="text" width="60%" />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={60}
                    width="100%"
                  />
                </div>
                <div style={{ width: "40%" }}>
                  <Skeleton animation="wave" variant="text" width="60%" />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={60}
                    width="100%"
                  />
                </div>
              </div>
            ))}
          </Box>

          <Grid item xs={12}>
            <Skeleton animation="wave" variant="text" width="25%" />
            <Skeleton
              animation="wave"
              variant="text"
              height={140}
              width="52%"
            />
          </Grid>
        </div>
      </div>
    </RegisterBodyContainer>
  );
};

export const UsageMaticsSkeletons = () => {
  const classes = useStyles();
  return (
    <RegisterBodyContainer>
      <Grid container spacing={2} className={classes.root}>
        {new Array(2).fill(0).map((_, i) => (
          <Grid item xs={6} key={i}>
            <Skeleton animation="wave" variant="text" width="50px" />
            <Skeleton
              animation="wave"
              variant="text"
              height={60}
              width="100%"
            />
          </Grid>
        ))}
      </Grid>
    </RegisterBodyContainer>
  );
};

export const AccessManagementSkeletons = () => {
  const classes = useStyles();
  return (
    <RegisterBodyContainer>
      <div className={classes.root}>
        <Box display="flex" alignItems="center" gap={2}>
          <Skeleton animation="wave" variant="text" width="80px" />
          <Skeleton animation="wave" variant="text" width="150px" />
        </Box>
        <Skeleton animation="wave" variant="text" height={60} width="100%" />
        <Box display="flex" flexDirection="column">
          <Skeleton
            animation="wave"
            variant="text"
            height={60}
            width="100%"
            sx={{
              "&.MuiSkeleton-root": {
                backgroundColor: "#e5e5e5 !important",
              },
            }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            height={60}
            width="100%"
            sx={{ marginTop: "-20px" }}
          />
        </Box>
      </div>
    </RegisterBodyContainer>
  );
};
