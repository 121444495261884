import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  stepperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    zIndex: 10,
    width: "100%",
  },
}));

const getSteps = (app_type) => {
  if (app_type === "ml_app") {
    return [
      "Application Type",
      "Details",
      "Configurations",
      // "Track Status",
      // "AccessManagement",
    ];
  } else if (app_type === "standalone_app") {
    return [
      "Application Type",
      "Details",
      "Configurations",
      // "AccessManagement",
    ];
  }
  return [
    "Application Type",
    "Details",
    "Layout",
    //  "AccessManagement"
  ];
};

const StepperComponent = ({ activeStepper, app_type, theme }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.stepperContainer}>
        <Box textAlign="center" width={"100%"}>
          <Stepper activeStep={activeStepper - 1}>
            {getSteps(app_type).map((label, i) => (
              <Step
                key={label}
                sx={{
                  "&.MuiStep-root": {
                    px: "2px",
                  },
                }}
              >
                <StepLabel
                  className={`stepper ${theme}-stepper`}
                  sx={{
                    "MuiStepLabel-label.Mui-active": {
                      color: "#000 !important",
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
    </>
  );
};

export default StepperComponent;
