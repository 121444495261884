import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import LandingTemplate from "../Components/LandingTemp4";
import ParentReportType from "../Components/ParentReportType";
import CommonReport from "../Components/CommonReport";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "calc(80vh - 100px)",
        overflow: "auto",
    },
    cardContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        flexWrap: "wrap",
        margin: "10px 0",
        px: "10px",
    },
}));

const Template4 = ({
    data,
    handleNavigation,
    handleEdit,
    handleDelete,
    breadcrumbArr,
    setRenderSubReportArr,
    setSelectedSubcat,
    setopenAddReportPopUp,
    setSelectedWidgetType,
    setParentID,
    updateLayout,
    isFieldsEditable,
    setParentReportType,
}) => {
    const classes = useStyles();

    const getReports = useMemo(
        () => data.filter((item) => item.sub_category_type !== "header"),
        [data]
    );

    const typeOfReport = useMemo(() => {
        const reportType = [];
        data.forEach((item) => reportType.push(item.sub_category_type));
        return reportType;
    }, [data]);

    return (
        <>
            {typeOfReport.includes("parentReport") ? (
                <ParentReportType
                    data={data}
                    handleNavigation={handleNavigation}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    breadcrumbArr={breadcrumbArr}
                    setRenderSubReportArr={setRenderSubReportArr}
                    setSelectedSubcat={setSelectedSubcat}
                    setopenAddReportPopUp={setopenAddReportPopUp}
                    setSelectedWidgetType={setSelectedWidgetType}
                    setParentID={setParentID}
                    isFieldsEditable={isFieldsEditable}
                    setParentReportType={setParentReportType}
                />
            ) : typeOfReport.includes("customHybrid") ? (
                <div className={classes.cardContainer}>
                    <LandingTemplate
                        data={data}
                        handleNavigation={handleNavigation}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        isFieldsEditable={isFieldsEditable}
                    />
                </div>
            ) : (
                <CommonReport
                    handleNavigation={handleNavigation}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    data={data}
                    updateLayout={updateLayout}
                    isFieldsEditable={isFieldsEditable}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    theme: state.user.ui_preferences?.theme,
    appTemplateState: state.appTemplate.appTemplateState.templateData,
});

export default connect(mapStateToProps)(Template4);
