import C from "../constants";

export default function register(state = {}, payload) {
    let { type, data } = payload;
    switch (type) {
        case C.GET_REGISTER_APP_TEMPLATE:
            return {
                ...state,
                registerTemplate: { status: "loading", message: "", data: [] },
            };
        case C.REGISTER_APP_TEMPLATE_RECEIVED:
            return {
                ...state,
                registerTemplate: { status: "success", message: "", data },
            };
        case C.VALIDATE_APPLICATION_NAME:
            return {
                ...state,
                registerTemplate: { status: "loading", message: "", data },
            };
        case C.VALIDATION_RECEIVED:
            return {
                ...state,
                registerTemplate: { status: "success", message: "", data },
            };
        case C.GET_UNIQUE_BACKEND_REPO:
            return {
                ...state,
                backend_repo_uniqueness: {
                    status: "loading",
                    message: "",
                },
            };
        case C.UNIQUE_BACKEND_REPO_RECEIVED:
            return {
                ...state,
                backend_repo_uniqueness: {
                    status: data.status,
                    message: data.msg,
                },
            };
        case C.GET_UNIQUE_FRONTEND_REPO:
            return {
                ...state,
                frontend_repo_uniqueness: {
                    status: "loading",
                    message: "",
                },
            };
        case C.UNIQUE_FRONTEND_REPO_RECEIVED:
            return {
                ...state,
                frontend_repo_uniqueness: {
                    status: data.status,
                    message: data.msg,
                },
            };
        case C.VALIDATE_UNIQUE_APP_URL:
            return {
                ...state,
                app_url_uniqueness: {
                    status: "loading",
                    message: "",
                },
            };
        case C.UPDATE_REPOS:
            return {
                ...state,
                frontend_repo_uniqueness: {
                    status: "success",
                    message: "",
                },
                backend_repo_uniqueness: {
                    status: "success",
                    message: "",
                },
            };
        case C.UNIQUE_APP_URL_RECEIVED:
            return {
                ...state,
                app_url_uniqueness: {
                    status: data.status,
                    message: data.msg,
                },
            };
        case C.UPDATE_DATASETS_TAGS:
            return {
                ...state,
                update_dataset_tags: {
                    status: "loading",
                    message: data.msg,
                },
            };
        case C.DATASETS_TAGS_UPDATED:
            return {
                ...state,
                update_dataset_tags: {
                    status: data.status,
                    message: data.msg,
                },
            };
        case C.GET_UNIQUE_APP_HANDLE:
            return {
                ...state,
                unique_app_handle: {
                    status: "loading",
                    message: "",
                },
            };
        case C.UNIQUE_APP_HANDLE_RECEIVED:
            return {
                ...state,
                unique_app_handle: {
                    status: data.status,
                    message: data.msg,
                },
            };
        case C.UPDATE_APP_HANDLE:
            return {
                ...state,
                unique_app_handle: {
                    status: "success",
                    message: "",
                },
            };
        case C.GET_USAGE_REPORT_USERS:
            return {
                ...state,
                usage_report_users: {
                    status: "loading",
                    message: data.msg,
                    data: {},
                },
            };
        case C.USAGE_REPORT_USERS_RECEIVED:
            return {
                ...state,
                usage_report_users: {
                    status: "success",
                    message: "",
                    data,
                },
            };
        case C.CLEAN_USAGE_REPORT_USERS:
            return {
                ...state,
                usage_report_users: {
                    status: "pending",
                    message: "",
                    data: {},
                },
            };
        case C.UPDATE_DRAFT_STATUS:
            return {
                ...state,
                register_draft_app: {
                    status: data,
                },
            };
        case C.UPDATE_ML_APP_URL:
            return {
                ...state,
                ml_app_url_status: {
                    ...state.ml_app_url_status,
                    ...data,
                },
            };
        case C.SYNC_ACCESS_IN_ENV:
            return {
                ...state,
                sync_access_with_env: {
                    status: "loading",
                    message: "",
                },
            };
        case C.ACCESS_IN_ENV_SYNCED:
            return {
                ...state,
                sync_access_with_env: {
                    status: data.status,
                    message: data.message || "",
                },
            };
        case C.UPDATE_AZURE_GROUP_MEMBERS:
            return {
                ...state,
                azure_group_members: {
                    status: "loading",
                    message: "",
                    data: {},
                },
            };
        case C.AZURE_GROUP_MEMBERS_UPDATED:
            return {
                ...state,
                azure_group_members: {
                    status: data.status,
                    message: data.message || "",
                    data: data.data,
                },
            };
        case C.UPDATE_WORK_SPACE:
            return {
                ...state,
                powerBiWorkspace: {
                    ...state.powerBiWorkspace,
                    ...data,
                },
            };
        case C.UPDATE_POWER_BI_REPORTS:
            return {
                ...state,
                powerBiReports: {
                    ...state.powerBiReports,
                    ...data,
                },
            };
        case C.DUBLICATE_WORKSPACE_LIST:
            return {
                ...state,
                dublicate_workspace_list: {
                    ...state.dublicate_workspace_list,
                    ...data,
                },
            };
        case C.CLEAN_REGISTER_STATES:
            return {
                ...state,
                dublicate_workspace_list: {
                    status: "pending",
                    message: "",
                    data: {},
                },
            };
        default:
            return state;
    }
}
