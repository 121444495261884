//Import required libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import TweetEmbed from "react-tweet-embed";
import { Tweet } from "react-twitter-widgets";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//Import Custom Components
import Footer from "../../components/Footer/Footer";

// Import action creators
import { updateUserInfo, updateAlertInfo } from "../../redux/actions";

// Import custom hooks
import { useNonInitialEffect } from "../../hooks/useNonInitialEffect";

//Import utils/data
import tweetsDateRange from "../../../assets/data/tweetsdateRange.json";
import tweetSortingOption from "../../../assets/data/tweetSortingOptions.json";
import tweetsSentimentScoreHex from "../../../assets/data/tweetsSentimentScoreHex.json";
import noTweetsAvailable from "../../../assets/images/No_tweets_available.svg";
import couldNotLoadTweet from "../../../assets/images/Could_not_load_tweet.svg";

import {
    makeDefaultResponseJson,
    splitToChunks,
    isEqualObject,
} from "../../utils/utils";
import { config } from "../../config/config";
import Loader from "../../components/Loader/Loader";

import "./AIDESentimentAnalysis.scss";

const useStyles = makeStyles((theme) => ({
    multiSelect: {
        fontSize: "12px",
        fontFamily: "Energy",
        width: "160px",
        height: "26px",
        borderRadius: "3px",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        backgroundColor: theme.palette.mode === "dark" ? "#282828" : "white",
        marginLeft: "20px",
        //paddingLeft: "8px",
        border: "1px solid #dedede",
        "&:before": {
            borderBottom: "0px",
        },
        "&:after": {
            borderBottom: "0px",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "0px",
        },
        "& .MuiOutlinedInput-input": {
            padding: "6px 6px",
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingLeft: "8px",
        },
    },
    singleMenuItem: {
        fontSize: "12px",
        fontFamily: "Energy",
        padding: "0px 12px",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        width: "auto",
    },
    outlined: {
        backgroundColor: "#282828",
        color: "white",
        marginRight: 10,
        fontSize: 14,
        padding: 10,
        height: 35,
        minWidth: 15,
        width: "15%",
        textTransform: "capitalize",
        float: "right",
        fontFamily: "Energy",
        borderRadius: "20px",
        border: "1px solid #282828",
        "&:hover": {
            backgroundColor: "#e9f7fc",
            color: "#282828",
            boxShadow: "none",
        },
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 150,
            width: "auto",
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
    variant: "menu",
    getContentAnchorEl: null,
};

const initialFilters = {
    activeSelection: "sentiment_score_high", //id
    column_name: "sentiment_score", //key
    order_sequence: "desc",
    dayCatId: "last_week",
    start_date: null,
    end_date: null,
    offset: 0,
    limit: 20,
    activeReport: "",
    noMoreRecords: false,
};

const initialTweetsData = {
    status: "loading",
    message: "",
    data: {
        average_sentiment: 0,
        maximum_sentiment: 0,
        minimum_sentiment: 0,
        tweets: [],
    },
};

const AIDESentimentAnalysis = (props) => {
    const {
        user,
        updateUserInfo,
        updateAlertInfo,
        activeReport,
        theme = "light",
    } = props;
    const classes = useStyles();
    const [tweetsData, setTweetsData] = useState(initialTweetsData);
    const [activeFilter, setActiveFilter] = useState({
        ...initialFilters,
        activeReport: activeReport.label,
    });

    // console.log("activeFilter.offset", activeFilter.offset);
    // console.log("tweetsData?.tweets.length", tweetsData?.data.tweets?.length);

    const showLoadMoreLoaderFlag =
        activeFilter.offset >= tweetsData?.data.tweets?.length &&
        !activeFilter.noMoreRecords;
    // console.log("showLoadMoreLoaderFlag", showLoadMoreLoaderFlag);

    const showLoadMoreButton =
        tweetsData.data.tweets.length >= activeFilter.limit &&
        activeFilter.offset !== tweetsData?.data.tweets?.length &&
        !activeFilter.noMoreRecords;
    // console.log("showLoadMoreButton", showLoadMoreButton);

    const renderTwitterColumn = (res) => {
        let ele = res.map((tweet) => {
            return (
                <div className="tweet-comp" key={tweet.tweet_id}>
                    <div
                        style={{
                            minHeight: "150px",
                            background: "#FFF",
                            borderRadius: "11px",
                        }}
                    >
                        <Tweet
                            tweetId={`${tweet.tweet_id}`}
                            key={`${tweet.tweet_id}`}
                            options={{
                                cards: "hidden",
                                conversation: "none",
                                theme: theme || "light",
                            }}
                            renderError={(_err) => (
                                <div
                                    style={{
                                        padding: "10px",
                                        border: "0.5px solid lightgrey",
                                        borderRadius: "15px",
                                        borderBottom: "none",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        fontSize: "13px",
                                        height: "150px",
                                        justifyContent: "center",
                                        // background: "#FFF",
                                        backgroundColor:
                                            theme === "dark" ? "#000" : "#fff",
                                        color:
                                            theme === "dark"
                                                ? "#fff"
                                                : "#000000",
                                    }}
                                >
                                    <img
                                        height="40px"
                                        src={couldNotLoadTweet}
                                    />
                                    <p>Could not load tweet</p>
                                </div>
                            )}
                        />
                    </div>
                    <div
                        className={`score-row`}
                        style={{
                            background: `${
                                tweetsSentimentScoreHex[
                                    Math.round(
                                        tweet.sentiment_score * 10 +
                                            Number.EPSILON
                                    ) / 10
                                ]
                            }`,
                            color: `${
                                Math.round(
                                    tweet.sentiment_score * 10 + Number.EPSILON
                                ) /
                                    10 ==
                                0
                                    ? "#000"
                                    : "#FFF"
                            }`,
                        }}
                    >
                        <span>
                            # Score:{" "}
                            {!isNaN(tweet.sentiment_score) &&
                            tweet.sentiment_score != null
                                ? Math.round(tweet.sentiment_score * 1000) /
                                  1000
                                : "--"}
                        </span>
                        <span>
                            # Magnitude:{" "}
                            {!isNaN(tweet.sentiment_magnitude) &&
                            tweet.sentiment_magnitude != null
                                ? Math.round(tweet.sentiment_magnitude * 1000) /
                                  1000
                                : "--"}
                        </span>
                    </div>
                </div>
            );
        });
        return ele;
    };

    //Load More Data Function
    const loadMoreTweets = () => {
        setActiveFilter({
            ...activeFilter,
            offset: tweetsData.data.tweets.length,
        });
    };

    const onChangeColumnName = (e) => {
        let selectedObj = tweetSortingOption.find(
            (filterObj) => filterObj.id === e.target.value
        );
        setActiveFilter({
            ...activeFilter,
            noMoreRecords: false,
            offset: 0,
            activeSelection: selectedObj.id,
            column_name: selectedObj.key,
            order_sequence: selectedObj.filter_by,
        });
    };

    const onChangeDayCat = (e) => {
        setActiveFilter({
            ...activeFilter,
        });
    };

    // Get tweet data
    useEffect(() => {
        //console.log("activeFilter.offset", activeFilter.offset);
        //cleanup
        //offset is 0
        //setTweetsData(initialTweetsData)
        if (activeFilter.offset === 0) {
            setTweetsData(initialTweetsData);
        }
        const controller = new AbortController();
        const { signal } = controller;
        let params = {
            brand: activeReport.sub_category_name,
            order_by: {
                column_name: activeFilter.column_name,
                order_sequence: activeFilter.order_sequence,
            },
            offset: activeFilter.offset,
            limit: activeFilter.limit,
            start_date: activeFilter.start_date,
            end_date: activeFilter.end_date,
        };

        let url = `${config.api.tweetsUrl}`; // call api to get all the tweets
        fetch(url, {
            signal,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                console.groupCollapsed("requesting", url);
                console.log("REPSONSE -> ", response);
                if (response.status === 200) {
                    return response.json();
                } else {
                    let error = response.statusText;
                    let errorCode = response.status;
                    console.log("ERROR -> ", error);
                    return { ...makeDefaultResponseJson(), error, errorCode };
                }
            })
            .then((json) => {
                console.log("JSON -> ", json);
                console.groupEnd();
                if (json.errorCode) {
                    updateUserInfo({
                        error: json.error,
                        errorCode: json.errorCode,
                    });
                    updateAlertInfo({
                        open: true,
                        message: config.messages.somethingWentWrong,
                        severity: "info",
                    });
                    setTweetsData({
                        ...tweetsData,
                        status: "error",
                        message: config.hardCoded.somethingWentWrong,
                        data: {
                            ...tweetsData.data,
                            tweets: [
                                ...tweetsData.data.tweets,
                                ...json.data.tweets,
                            ],
                        },
                    });
                } else {
                    let newfilterObj = { ...activeFilter, noMoreRecords: true };
                    // checking if newFilterObj and activeFilter is equal or not, to prevent multiple fetch calls.
                    // if both objects are not equal then we are setting our activeFilters
                    // this is make one extra fetch call but can't prevent that
                    if (
                        !json.data.tweets.length &&
                        !isEqualObject(newfilterObj, activeFilter)
                    ) {
                        setActiveFilter(newfilterObj);
                    }
                    if (activeFilter.offset === 0) {
                        setTweetsData({
                            ...tweetsData,
                            status: "success",
                            data: json.data,
                        });
                    } else {
                        setTweetsData({
                            ...tweetsData,
                            status: "success",
                            data: {
                                ...tweetsData.data,
                                tweets: [
                                    ...tweetsData.data.tweets,
                                    ...json.data.tweets,
                                ],
                            },
                        });
                    }
                }
            })
            .catch((e) => {
                console.warn(`Fetch 2 error: ${e.message}`);
            });
        return () => {
            controller.abort();
        };
    }, [activeFilter]);

    // Get tweet data
    useNonInitialEffect(() => {
        let newFilters = {
            ...initialFilters,
            activeReport: activeReport.label,
        };
        setActiveFilter(newFilters);
    }, [activeReport]);

    const result = [[], [], [], []]; //we create it, then we'll fill it
    if (tweetsData && tweetsData.data.tweets && tweetsData.data.tweets.length) {
        const n = 4;
        const wordsPerLine = Math.ceil(tweetsData.data.tweets.length / 4);
        for (let line = 0; line < n; line++) {
            for (let i = 0; i < wordsPerLine; i++) {
                const value = tweetsData.data.tweets[i + line * wordsPerLine];
                if (!value) continue; //avoid adding "undefined" values
                result[line].push(value);
            }
        }
    }

    let displayTweetData = splitToChunks([...tweetsData.data.tweets], 3);

    return (
        <>
            {tweetsData.status === "loading" && <Loader />}
            {tweetsData.status === "success" && (
                <>
                    <div className={`analysis-row ${theme}-theme`}>
                        {tweetsData && tweetsData.data ? (
                            <div className="default-scores">
                                <h4>Sentiment Score</h4>
                                <div
                                    className="circle"
                                    style={{
                                        background: `${
                                            tweetsSentimentScoreHex[
                                                Math.round(
                                                    tweetsData.data
                                                        .maximum_sentiment *
                                                        10 +
                                                        Number.EPSILON
                                                ) / 10
                                            ]
                                        }`,
                                    }}
                                ></div>
                                <span>
                                    Maximum:{" "}
                                    {!isNaN(
                                        tweetsData.data.maximum_sentiment
                                    ) &&
                                    tweetsData.data.maximum_sentiment != null
                                        ? Math.round(
                                              tweetsData.data
                                                  .maximum_sentiment * 1000
                                          ) / 1000
                                        : "--"}
                                </span>
                                <div className="vertical"></div>
                                <div
                                    className="circle"
                                    style={{
                                        background: `${
                                            tweetsSentimentScoreHex[
                                                Math.round(
                                                    tweetsData.data
                                                        .minimum_sentiment *
                                                        10 +
                                                        Number.EPSILON
                                                ) / 10
                                            ]
                                        }`,
                                    }}
                                ></div>
                                <span>
                                    Minimum:{" "}
                                    {!isNaN(
                                        tweetsData.data.minimum_sentiment
                                    ) &&
                                    tweetsData.data.minimum_sentiment != null
                                        ? Math.round(
                                              tweetsData.data
                                                  .minimum_sentiment * 1000
                                          ) / 1000
                                        : "--"}
                                </span>
                                <div className="vertical"></div>
                                <div
                                    className="circle"
                                    style={{
                                        background: `${
                                            tweetsSentimentScoreHex[
                                                Math.round(
                                                    tweetsData.data
                                                        .average_sentiment *
                                                        10 +
                                                        Number.EPSILON
                                                ) / 10
                                            ]
                                        }`,
                                    }}
                                ></div>
                                <span>
                                    Average:{" "}
                                    {!isNaN(
                                        tweetsData.data.average_sentiment
                                    ) &&
                                    tweetsData.data.average_sentiment != null
                                        ? Math.round(
                                              tweetsData.data
                                                  .average_sentiment * 1000
                                          ) / 1000
                                        : "--"}
                                </span>
                            </div>
                        ) : null}
                        <div className="date-filters">
                            <div className="sort-by-div">
                                <p>Sort By</p>
                                <Select
                                    name="Select Filter"
                                    value={activeFilter.activeSelection}
                                    onChange={(e) => onChangeColumnName(e)}
                                    className={classes.multiSelect}
                                    MenuProps={MenuProps}
                                >
                                    {tweetSortingOption.map((obj) => (
                                        <MenuItem
                                            value={obj.id}
                                            key={obj.id}
                                            className={classes.singleMenuItem}
                                        >
                                            {obj.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="sort-by-div">
                                <Select
                                    name="Select day category"
                                    value={activeFilter.dayCatId}
                                    onChange={(e) => onChangeDayCat(e)}
                                    className={classes.multiSelect}
                                    MenuProps={MenuProps}
                                >
                                    {tweetsDateRange.map((obj) => (
                                        <MenuItem
                                            value={obj.id}
                                            key={obj.id}
                                            className={classes.singleMenuItem}
                                        >
                                            {obj.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className={`sentiment-container`}>
                        {tweetsData &&
                        tweetsData.data.tweets &&
                        tweetsData.data.tweets.length ? (
                            <>
                                <div className="tweet-row">
                                    {displayTweetData.map((elem) => (
                                        <div
                                            className="tweets-col"
                                            key={elem.id}
                                        >
                                            {elem &&
                                                renderTwitterColumn(elem.data)}
                                        </div>
                                    ))}
                                </div>
                                {showLoadMoreLoaderFlag && (
                                    <div className="loader">
                                        <Loader />
                                    </div>
                                )}
                                {showLoadMoreButton && (
                                    <div
                                        style={{
                                            paddingBottom: "50px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            className={classes.outlined}
                                            onClick={loadMoreTweets}
                                        >
                                            Load More
                                        </Button>
                                    </div>
                                )}
                                {activeFilter.noMoreRecords && (
                                    <div className="no-tweets-text">
                                        No more tweets available
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="no-tweets">
                                    <img src={noTweetsAvailable} />
                                    <span>
                                        No Tweets Available. Try Another Brand!
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
            {tweetsData.status === "error" && (
                <p className="info-message">{tweetsData.message}</p>
            )}
        </>
    );
};

AIDESentimentAnalysis.propTypes = {
    activeReport: PropTypes.object,
};

const mapStateToProps = (state) => ({
    user: state.user,
    theme: state.user.ui_preferences.theme,
});

const mapDispatchToProps = {
    updateUserInfo,
    updateAlertInfo,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AIDESentimentAnalysis);
