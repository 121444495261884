import React from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import Loader from "../Loader/Loader";

const ModalWrapper = ({
    open,
    handleClose,
    handleSubmit,
    children,
    waiting,
    width = "320px",
    continueText = "Continue",
}) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ "& .MuiDialog-paper": { backgroundImage: "none", boxShadow: "4px 4px 12px 4px #434343" } }}
        >
            <DialogContent>{children}</DialogContent>
            <Box display="flex" justifyContent="flex-end" px={2} pb={1} gap={2}>
                <button onClick={handleClose} className="cancel-btn">
                    Cancel
                </button>
                <button
                    onClick={handleSubmit}
                    disabled={waiting}
                    className="btn"
                >
                    {waiting ? (
                        <Loader color="#ffffff" margin="0px 8px" />
                    ) : (
                        continueText
                    )}
                </button>
            </Box>
        </Dialog>
    );
};

export default ModalWrapper;
