import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import loadingGif from "../../../assets/images/animatedBlocks.gif";

// Import images
import DR_image from "../../../assets/images/DR_img.svg";
import explore_img from "../../../assets/images/explore_img.svg";
import faq_img from "../../../assets/images/faq_img.svg";
import help_img from "../../../assets/images/help_img.svg";
import home_img from "../../../assets/images/home_img.svg";
import myapps_img from "../../../assets/images/myapps_img.svg";
import Loader from "../Loader/Loader";

export function ImageLoader({
  fullPage,
  message,
  flexDirection,
  color = "#FC007F",
  margin,
  path,
  LoadImg = home_img,
  ImgHeight= "100px",
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: "10%",
      display: "flex",
      flexDirection: flexDirection || "column",
      margin: margin || theme.spacing(1),
      "& > * + *": {
        margin: theme.spacing(5),
      },
      justifyContent: "center",
      alignItems: "center",
      ...(fullPage && {
        height: "100vh",
      }),
    },
    text: {
      color: color || "#FC007F",
      fontWeight: 600,
    },
  }));

  const imageMapping = {
    "/explore": explore_img,
    "/home": home_img,
    "/myapps": myapps_img,
    "/faqs": faq_img,
    "/help": help_img,
  };

  const headerImg = imageMapping[path] || LoadImg;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img style={{ height: ImgHeight }} src={headerImg}></img>
      <Loader flexDirection="row-reverse" message={message}></Loader>
    </div>
  );
}

ImageLoader.propTypes = {
  fullPage: PropTypes.any,
  message: PropTypes.any,
};
ImageLoader.defaultProps = {
  fullPage: false,
  message: "",
};

export default ImageLoader;
