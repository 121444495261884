import { makeStyles } from "@material-ui/core";

export const useStylesforElementSelector = makeStyles((theme) => ({
  elementsContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
    gap: "20px",
  },
  ContentForElementSelector: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "50%",
  },
  mainContainer: {
    // width: "29%",
    width: "22%",
    marginRight: "10px",
    height: "150px",
    flexShrink: "0",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "10px",
    border:
      theme.palette.mode === "dark" ? "1px solid #757575" : "1px solid #D7DADF",
    boxShadow: "1px 1px 8px 0px rgba(0, 0, 0, 0.08)",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.05)",
      border: "1px solid",
    },
  },
  elementImage: {
    height: "50px",
    cursor: "pointer",
  },
  elementText: {
    margin: "0",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "bold",
    cursor: "pointer",
  },
  elementDescription: {
    margin: "0",
    fontSize: "12px",
    textAlign: "center",
    cursor: "pointer",
    color: theme.palette.mode === "dark" ? "#b1b1b1" : "4e4e4e",
  },
}));

export const useStylesforTemplateSelector = makeStyles((theme) => ({
  elementsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  inActiveButton: {
    borderRadius: "3px",
    backgroundColor: theme.palette.colors.darkBlue,
  },
  activeButton: {
    backgroundColor: "#fff",
    color: "#282828",
    borderRadius: "3px",
  },
  buttonContainer: {
    backgroundColor: "transparent",
    // borderRadius: "3px",
    display: "flex",
    width: "67px",
    height: "30px",
    // border: "1px solid #B2B1B1",
    // marginRight: "20px",
  },
  TemplateSelectorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "95% !important",
      minWidth: "95% !important",
      height: "80% !important",
      boxShadow: "4px 4px 12px 4px #434343",
    },
  },
  templateViewContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "15px",
    paddingRight: "15px",
  },
  templateContainer: {
    cursor: "pointer",
    display: "flex",
    marginRight: "10px",
    marginTop: "15px",
    flexDirection: "column",
    position: "relative",
  },
  ContentForElementSelector: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "50%",
  },
  newTemplateSign: {
    position: "absolute",
    right: "6px",
    bottom: "24px",
    background: theme.palette.colors.darkBlue,
    color: "white",
    height: "17px",
    textAlign: "center",
    width: "43px",
    borderRadius: "10px 0px 0px 10px",
    fontSize: "12px",
  },
  elementImage: {
    height: "200px",
    cursor: "pointer",
    border: "1px solid transparent",
  },
  oneElementImage: {
    height: "100%",
    cursor: "pointer",
    border: "1px solid transparent",
  },
  selectedTemplateText: {
    margin: "0px 0px 0px 5px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
    color: `${theme.palette.colors.teal}`,
  },
  selectedTemplateImage: {
    height: "200px",
    cursor: "pointer",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.colors.teal}`,
  },
  elementText: {
    margin: "0px 0px 0px 5px",
    fontSize: "14px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  dialog_footer: {
    padding: "8px 15px 15px 8px",
  },
}));
