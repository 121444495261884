import { Box, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  removeSubField,
  updateMiddleSubcatState,
} from "../../../../utils/utils";
import "../../index.scss";
import { update_BIappDetails } from "../../../../redux/actions";
const SubCategoryCard = ({
  categoryInfo,
  // setCategoryInfo,
  isFieldsEditable,
  theme,
  setIsFieldCardRed,
  // setCategoryInfoLocal,
  categoryInfoLocal,
  catIndex,
  subCatIndex,
  data,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event) => {
    if (isFieldsEditable) return;
    setAnchorEl(event.currentTarget);
  };
  const marginLeft = "0px";
  const borderColor = "#465a69";
  const handleDelete = () => {
    // setCategoryInfo(removeSubField(categoryInfo, catIndex, subCatIndex));
    // setCategoryInfoLocal(
    //   removeSubField(categoryInfoLocal, catIndex, subCatIndex)
    // );
    dispatch(
      update_BIappDetails({
        categoryInfo: removeSubField(categoryInfo, catIndex, subCatIndex),
        categoryInfoLocal: removeSubField(
          categoryInfoLocal,
          catIndex,
          subCatIndex
        ),
      })
    );
  };

  const handleEdit = () => {
    setIsFieldCardRed(false);
    const reqData = {
      ...categoryInfo[catIndex].sub_category[subCatIndex],
      isSubCatEditable: true,
    };
    // setCategoryInfo(
    //   updateMiddleSubcatState(categoryInfo, catIndex, subCatIndex, reqData)
    // );
    // setCategoryInfoLocal(
    //   updateMiddleSubcatState(categoryInfoLocal, catIndex, subCatIndex, reqData)
    // );

    dispatch(
      update_BIappDetails({
        categoryInfo: updateMiddleSubcatState(
          categoryInfo,
          catIndex,
          subCatIndex,
          reqData
        ),
        categoryInfoLocal: updateMiddleSubcatState(
          categoryInfoLocal,
          catIndex,
          subCatIndex,
          reqData
        ),
      })
    );
  };

  return (
    <div
      className={`sub-category-card ${theme}-category-card`}
      style={{
        borderLeft: theme === "light" ? "5px solid #282828" : "5px solid #fff",
      }}
      id={data.sub_category_id}
    >
      <div style={{ display: "flex", alignItems: "center", width: "88%" }}>
        <span class="material-icons-outlined">folder</span>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
          }}
        >
          <div
            style={{ marginLeft: "10px" }}
            className={`${theme}-subcategory-name`}
          >
            {data.sub_category_name}
          </div>
          <div
            className={`sub-categroy-card-url ${theme}-sub-categroy-card-url`}
            style={{
              marginTop: "7px",
              width: "100%",
            }}
          >
            {categoryInfoLocal[catIndex].sub_category[subCatIndex]
              ?.SubCatHyperlinks?.isHyperlinkActive ? (
              <a
                href={
                  categoryInfoLocal[catIndex].sub_category[subCatIndex]
                    ?.SubCatHyperlinks?.url
                }
                target="_blank"
                rel="noreferrer"
                style={{
                  whiteSpace: "nowrap",
                  display: "block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {
                  categoryInfoLocal[catIndex].sub_category[subCatIndex]
                    ?.SubCatHyperlinks?.url
                }
              </a>
            ) : null}
          </div>
        </div>
      </div>
      <Box display="flex" alignItems="center">
        <span class="material-icons-outlined drag-icon">reorder</span>
        <span class="material-icons-outlined" onClick={handleOpen}>
          more_vert
        </span>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Box>
    </div>
  );
};
const mapStateToProps = (state) => ({
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  theme: state.user.ui_preferences?.theme,
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(SubCategoryCard);
