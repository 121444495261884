import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
  },
  summary: {
    padding: "0px !important",
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "#415a6c !important",
    },
    "& .Mui-expanded": {
      color: "#FF007F !important",
    },
  },
  typosummary: {
    fontFamily: "Energy !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
  },
  maintyposummary: {
    fontSize: "14px !important",
    color: "#0058AA !important",
  },
  subtyposummary: {
    fontSize: "14px !important",
    color: "#000000",
  },
  details: {
    padding: "0px !important",
  },
  description: {
    fontFamily: "Energy !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "30px !important",
    letterSpacing: "0.10000000149011612px !important",
    color: "#4E4E4E  ",
    paddingBottom: "15px !important",
  },
}));

const FaqAccordion = ({
  expanded,
  setExpanded,
  handleChange,
  data,
  theme,
  noq,
}) => {
  const classes = useStyles(theme);

  return (
    <div>
      <Accordion
        className={classes.root}
        expanded={expanded.includes(data.key)}
        onChange={handleChange(data.key)}
        sx={{
          ...(theme === "dark"
            ? {
                backgroundImage: "none !important",
              }
            : {}),
        }}
      >
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          sx={{
            ...(data.key.length === 1
              ? {
                  borderBottom:
                    theme === "dark"
                      ? "1px solid #8f8f8f !important"
                      : "1px solid #c3c4c5",
                  "&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded":
                    {
                      border: "none !important",
                    },
                }
              : {
                  borderTop:
                    theme === "dark"
                      ? "1px solid #8f8f8f !important"
                      : "1px solid #c3c4c5",

                  ...(data.key.slice(1) == noq
                    ? {
                        borderBottom:
                          theme === "dark"
                            ? "1px solid #8f8f8f !important"
                            : "1px solid #c3c4c5",
                        "&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded":
                          {
                            borderBottom: "none !important",
                          },
                      }
                    : {}),
                }),
          }}
        >
          <Typography
            className={`${classes.typosummary} ${
              data.key.length === 1
                ? classes.maintyposummary
                : classes.subtyposummary
            }`}
            sx={{
              width: "70%",
              flexShrink: 0,
              color:
                data.key.length != 1
                  ? theme === "dark"
                    ? "#fff !important"
                    : {}
                  : {},
              fontWeight:
                data.key.length === 1
                  ? theme === "dark"
                    ? "600 !important"
                    : {}
                  : {},
            }}
          >
            {data.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {data.content ? (
            data.content.map((item) => (
              <FaqAccordion
                expanded={expanded}
                setExpanded={setExpanded}
                handleChange={handleChange}
                data={item}
                theme={theme}
                noq={noq}
              />
            ))
          ) : (
            <Typography
              className={classes.description}
              sx={{
                whiteSpace: "pre-line",
                color: theme === "dark" ? "#b1b1b1 !important" : {},
                borderBottom:
                  data.key.slice(1) == noq
                    ? theme === "dark"
                      ? "1px solid #8f8f8f !important"
                      : "1px solid #c3c4c5"
                    : {},
              }}
            >
              {data.description}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

FaqAccordion.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(FaqAccordion);
