import { Grid } from "@mui/material";
import CustomAutoComponents from "../../../../components/AutoCompletes/CustomAutoComponents";
import DropDownField from "../DropDownField/DropDownField";
import CheckboxDropDown from "../DropDownField/CheckboxDropDown";
import IconField from "../../../NewRegister/Components/DashboardTemplate/Components/IconField";
import InputTextBox from "../InputTextBox/InputTextBox";
import { isUrlMatch } from "../../../../utils/utils";
import { handleGetErrorMessage } from "../../../Register/components/DashboardTemplate/utils";
import MultipleEmailSelect from "../../../NewRegister/Components/DashboardTemplate/Components/MultipleEmailSelect";

const customStyling = {
  "& .MuiTextField-root": { boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)" },
};

export const getRequiredComponentValue = (childState, key) => {
  let val;
  switch (key) {
    case "header_text":
      val = childState.sub_category_name;
      break;
    case "sub_category_name":
      val = childState.sub_category_name;
      break;
    case "sub_category_description":
      val = childState.sub_category_description;
      break;
    case "header_description":
      val = childState.sub_category_description;
      break;
    default:
      val = "";
  }
  return val;
};

export const getRequiredComponent = (
  item,
  state,
  setshowWarning,
  handleChange,
  URLValid,
  setURLValid,
  handleOpenDrawer,
  theme,
  template_type = "old",
  template_no
) => {
  const data = {
    label: item.label,
    key: item.key,
    required: item.required,
    options: item.options,
  };

  switch (item.type) {
    case "input":
      return (
        <>
          <InputTextBox
            data={{
              ...data,
              required: item.key === "sub_category_name" ? true : false,
            }}
            handleChange={handleChange}
            state={state}
            setshowWarning={setshowWarning}
            URLValid={URLValid}
            setURLValid={setURLValid}
            value={state[item.key] || ""}
            // id={state[0].sub_category_id || ""}
            required={true}
            width={
              item.key === "sub_category_description"
                ? 12
                : item.key === "url"
                ? 12
                : 6
            }
            theme={theme}
          />
        </>
      );
    case "dropdown":
      if (
        item.key !== "function" &&
        item.key !== "line_of_business" &&
        item.key !== "geography"
      ) {
        return (
          <DropDownField
            data={data}
            handleChange={handleChange}
            value={state[item.key] || ""}
            // width={6}
            template_type={template_type}
          />
        );
      } else if (item.key === "geography") {
        return (
          <Grid item xs={3}>
            <div className={`heading ${theme}`}>
              {item.label} <span>*</span>:
            </div>
            <CustomAutoComponents
              data={data}
              selectedData={state[item.key] || []}
              handleSelection={handleChange}
              filteredOption={item.options}
              // customStyling={customStyling}
            />
          </Grid>
        );
      } else
        return (
          <CheckboxDropDown
            data={data}
            template_type={template_type}
            handleChange={handleChange}
            value={state[item.key] || ""}
            // width={40%""}
          />
        );
    // case "radio":
    //   return (
    //     <RadioBtns
    //       data={data}
    //       // handleChange={(e) => {
    //       //   handleChangeFieldVal(e, item, state);
    //       // }}
    //       value={getRequiredComponentValue(state, item.key) || ""}
    //       // isFieldsEditable={isFieldsEditable}
    //       // theme={theme}
    //     />
    //   );
    case "url":
      return (
        <IconField
          data={data}
          handleChange={handleChange}
          required={
            state.sub_category_type === "image" || template_no > 5
              ? true
              : false
          }
          sub_category_icon={state.sub_category_icon || ""}
          app_logo_type={state.app_logo_type || ""}
          item={item}
          state={state}
          handleOpenDrawer={handleOpenDrawer}
          widgetType={state.sub_category_type}
        />
      );
    // case "checkbox":
    //   return (
    //     <CheckBoxComponent
    //       data={data}
    //       // handleChange={(e) => {
    //       //   handleChangeFieldVal(e, item, state);
    //       // }}
    //       value={getRequiredComponentValue(state, item.key) || ""}
    //       width={3}
    //       // isFieldsEditable={isFieldsEditable}
    //     />
    //   );
    // case "switch":
    //   return (
    //     <SwitchField
    //       data={data}
    //       handleChange={handleChange}
    //       value={state[item.key] || false}
    //       width={3}
    //       // isFieldsEditable={isFieldsEditable}
    //     />
    //   );
    case "multiple":
      return (
        <MultipleEmailSelect
          handleChange={handleChange}
          values={state[item.key] || []}
          // options={options}
          // handleDelete={handleDelete}
          data={data}
          width={12}
        />
      );
    default:
      return "";
  }
};

export const getInitialState = (
  sub_category_id,
  sub_category_name,
  sub_category_description,
  sub_category_type,
  sub_category_icon = "dashboard",
  user_id,
  appFields,
  position_meta
) => ({
  additional_images: [],
  app_logo_type: "google_font",
  bi_application: "Power BI",
  blob_name: "analytics",
  contains_sub_category: true,
  show_description: false,
  display_subtiles: false,
  embedded_report: false,
  position_meta: position_meta || {
    h: 2,
    i: sub_category_id,
    w: 3,
    x: 0,
    y: 0,
  },

  function: appFields.app_details.function,
  line_of_business: appFields.app_details.line_of_business,
  geography: appFields.app_details.geography,
  business_owners: [user_id],
  technical_owners: [user_id],
  sub_category: [],
  sub_category_description,
  sub_category_icon,
  sub_category_id,
  sub_category_name,
  sub_category_type,
  url: "",
  view_count: 0,
});

export const checkFieldsAreValid = (localState, setURLValid) => {
  if (
    localState.sub_category_name === "" ||
    localState.function.length === 0 ||
    localState.business_owners.length === 0 ||
    localState.technical_owners.length === 0 ||
    localState.line_of_business.length === 0 ||
    localState.geography.length === 0
    // ||
    // localState.sub_category_img_data?.blob_name?.length === 0 ||
    // localState.sub_category_img_data?.img_url.length === 0
  ) {
    return false;
  }
  if (!localState.url.startsWith("https://")) {
    setURLValid({
      status: "Invalid",
      msg: "URL should start with (https://)",
    });
    return false;
  } else if (!localState.url.slice(8).trim().length) {
    setURLValid({
      status: "Invalid",
      msg: "Please Enter valid url",
    });
    return false;
  } else if (!isUrlMatch(localState.bi_application, localState.url)) {
    setURLValid({
      status: "Invalid",
      msg: handleGetErrorMessage(localState.bi_application),
    });
    return false;
  } else {
    setURLValid({
      status: "Valid",
      msg: "",
    });
    return true;
  }
};
