import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";

import firstplaceicon from "../../../../assets/images/1stplace.svg";
import secondplaceicon from "../../../../assets/images/2ndplace.svg";
import thirdplaceicon from "../../../../assets/images/3rdplace.svg";
import top10icon from "../../../../assets/images/top10.svg";
import top10darkicon from "../../../../assets/images/top10dark.svg";
import top20icon from "../../../../assets/images/top20.svg";
import top20darkicon from "../../../../assets/images/top20dark.svg";
import goodperficon from "../../../../assets/images/goodperformance.svg";
import lowperficon from "../../../../assets/images/lowperformance.svg";
import top123meter from "../../../../assets/images/top123-meter.svg";
import top1020meter from "../../../../assets/images/top1020-meter.svg";
import goodmeter from "../../../../assets/images/good-meter.svg";
import lowmeter from "../../../../assets/images/low-meter.svg";
import noDataAvailable from "../../../../assets/images/noDataAvailable.svg";
import noDataAvailableDark from "../../../../assets/images/noDataAvailableDark.svg";
import loadingMap from "../../../../assets/images/register-images/maploading.gif";

import "../index.scss";
import { StyledAutoComplete, CssTextField, useStyles } from "../style";

const RankingComponent = ({
  app_name,
  appDetails,
  apidata,
  theme,
  loading,
}) => {
  const options = [
    "All Apps",
    "App Type",
    "App Category",
    "Global Business Unit",
    "Region",
  ];
  const [rankingbasedon, setRankingbasedon] = useState(options[0]);
  const [subrankingbasedon, setSubRankingbasedon] = useState();
  const [rankMsgState, setRankMsgState] = useState([
    ["loading", "loading", "loading", 0],
  ]);
  const [filterOptions, setFilterOptions] = useState([]);
  const classes = useStyles();

  const appType =
    appDetails?.data?.app_meta?.app_type === "bi_app"
      ? "Embedded Analytics"
      : appDetails?.data?.app_meta?.app_type === "ml_app"
      ? "ML Web Apps"
      : "Standalone App";
  const appCategory = appDetails?.data?.app_details?.app_category;
  const gbu = appDetails?.data?.app_details?.line_of_business;
  let region = [];
  appDetails?.data?.app_details?.geography.forEach((item) => {
    region.push(item.region);
  });

  const handleChange = (newValue) => {
    apidata.forEach((item) => {
      if (item.label === newValue && !item.filter_options) {
        setFilterOptions([]);
        setSubRankingbasedon();
      } else if (
        item.label === newValue &&
        item.filter_options !== filterOptions
      ) {
        setFilterOptions(item.filter_options);
        setSubRankingbasedon(item.filter_options[0].label);
      }
    });
    setRankingbasedon(newValue);
  };

  useEffect(() => {
    setSubRankingbasedon(
      rankingbasedon === "Global Business Unit"
        ? gbu[0]
        : rankingbasedon === "Region"
        ? region[0]
        : ""
    );
  }, [rankingbasedon]);

  const handleSubChange = (newValue) => {
    setSubRankingbasedon(newValue);
  };

  const { label, key, ranking, filter_options, message } = apidata;

  const rankcategory = (data) => {
    outerLoop: for (const item of data) {
      if (item.label === rankingbasedon || item.label === subrankingbasedon) {
        if (!item.filter_options) {
          if (item.ranking === 1) {
            setRankMsgState([
              item.message,
              firstplaceicon,
              top123meter,
              item.ranking,
              item.key,
            ]);
          } else if (item.ranking === 2) {
            setRankMsgState([
              item.message,
              secondplaceicon,
              top123meter,
              item.ranking,
              item.key,
            ]);
          } else if (item.ranking === 3) {
            setRankMsgState([
              item.message,
              thirdplaceicon,
              top123meter,
              item.ranking,
              item.key,
            ]);
          } else if (item.ranking <= 10) {
            setRankMsgState([
              item.message,
              theme === "light" ? top10icon : top10darkicon,
              top1020meter,
              item.ranking,
              item.key,
            ]);
          } else if (item.ranking <= 20) {
            setRankMsgState([
              item.message,
              theme === "light" ? top20icon : top20darkicon,
              top1020meter,
              item.ranking,
              item.key,
            ]);
          } else if (item.performance === "good") {
            setRankMsgState([
              item.message,
              goodperficon,
              goodmeter,
              item.ranking,
              item.key,
            ]);
          } else {
            setRankMsgState([
              item.message,
              lowperficon,
              lowmeter,
              item.ranking,
              item.key,
            ]);
          }
          break outerLoop;
        }
      }
    }
  };

  useEffect(() => {
    filterOptions.length ? rankcategory(filterOptions) : rankcategory(apidata);
  }, [rankingbasedon, subrankingbasedon, apidata, theme]);

  const getRankingHeading = () => {
    const value = subrankingbasedon
      ? rankingbasedon + " - " + subrankingbasedon
      : rankingbasedon === "App Type"
      ? rankingbasedon + " - " + appType
      : rankingbasedon === "App Category"
      ? rankingbasedon + " - " + appCategory
      : rankingbasedon;
    return value;
  };

  const getDataStatus = () => {
    if (rankingbasedon === "All Apps" || rankingbasedon === "App Type") {
      return true;
    } else if (
      rankingbasedon === "App Category" &&
      rankMsgState[4] === appCategory
    ) {
      return true;
    } else if (
      rankingbasedon === "Global Business Unit" ||
      rankingbasedon === "Region"
    ) {
      if (filterOptions.map((obj) => obj.key).includes(subrankingbasedon)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  console.log(rankMsgState);

  return (
    <>
      <Box
        marginTop="16px"
        border={1}
        p={2}
        paddingTop="8px"
        borderColor="#B3BDC4"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          "@media (min-width:1920px)": {
            height: "407px",
            // gap: "20px",
          },
        }}
      >
        <div
          style={{
            fontSize: "14px",
            paddingTop: "10px",
            lineHeight: "24px",
            fontWeight: "600",
          }}
        >
          Application Ranking
        </div>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding="20px"
            sx={{
              backgroundColor: theme === "light" ? "#fff" : "#282828",
              height: "100%",
            }}
          >
            <img src={loadingMap} alt="" style={{ height: "250px" }} />
          </Box>
        ) : apidata.length > 0 ? (
          <div className="main_rankcomp_container">
            <div className="heading_and_rank_filter_container">
              <div style={{ display: "flex", gap: "20px" }}>
                {" "}
                <div
                  style={{
                    width: "30%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "11px",
                      paddingBottom: "1px",
                      lineHeight: "18px",
                    }}
                  >
                    FILTER RAKING BASED ON
                  </div>
                  <div>
                    <StyledAutoComplete
                      className={classes.typographyStyle}
                      value={rankingbasedon}
                      options={options}
                      getOptionLabel={(option) => option}
                      onChange={(e, newValue) => {
                        handleChange(newValue);
                      }}
                      disableClearable
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          className={classes.typographyStyle}
                          fullWidth
                          placeholder={`Choose ${label}`}
                        />
                      )}
                    />
                  </div>
                </div>
                {((rankingbasedon === "Global Business Unit" &&
                  gbu.length > 1) ||
                  (rankingbasedon === "Region" && region.length > 1)) && (
                  <div
                    style={{
                      width: "30%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "11px",
                        paddingBottom: "1px",
                        lineHeight: "18px",
                        textTransform: "uppercase",
                      }}
                    >
                      {rankingbasedon}
                    </div>
                    <StyledAutoComplete
                      className={classes.typographyStyle}
                      value={subrankingbasedon}
                      // options={filterOptions.map((obj) => obj.label)}
                      options={
                        rankingbasedon === "Global Business Unit"
                          ? gbu
                          : rankingbasedon === "Region"
                          ? region
                          : []
                      }
                      getOptionLabel={(option) => option}
                      onChange={(e, newValue) => {
                        handleSubChange(newValue);
                      }}
                      disableClearable
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          className={classes.typographyStyle}
                          fullWidth
                          placeholder={`Choose ${label}`}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              <p
                className="ranking_msg"
                style={{ margin: "0px", fontWeight: "400", lineHeight: "24px" }}
              >
                Ranking among:{" "}
                <span
                  className="ranking_msg"
                  style={{ color: theme === "light" ? "#4e4e4e" : "#fff" }}
                >
                  {getRankingHeading()}
                </span>
              </p>
            </div>
            {getDataStatus() ? (
              <>
                <div className="img_rankmsg_container">
                  <img
                    src={rankMsgState[1]}
                    style={{ height: "80px", width: "160px" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      className="ranking_msg"
                      style={{ color: theme === "light" ? "#4e4e4e" : "#fff" }}
                    >
                      {rankMsgState[0][0]}
                    </p>
                    <p
                      className="ranking_msg"
                      style={{ color: "#fc007f", marginLeft: "0px" }}
                    >
                      {rankMsgState[0][1]}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: "1px",
                    width: "100%",
                    backgroundColor: theme === "light" ? "#cccccc" : "#b3bdc4",
                  }}
                ></div>
                <div className="img_rankmsg_container">
                  <div style={{ position: "relative" }}>
                    <img src={rankMsgState[2]} style={{ height: "80px" }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        top: "28px",
                        left: rankMsgState[3] > 9999 ? "26px" : "28px",
                        alignItems: "center",
                        fontSize: "11px",
                      }}
                    >
                      <div>{rankMsgState[3]}</div>
                      <div
                        style={{
                          color: theme === "light" ? "#4e4e4e" : "#fff",
                          fontSize: "10px",
                        }}
                      >
                        Rank
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {rankMsgState[3] > 20 &&
                    rankMsgState[0][0] == "Good Performance" ? (
                      <p
                        className="ranking_msg"
                        style={{ fontWeight: 400, whiteSpace: "nowrap" }}
                      >
                        {rankMsgState[0][2]}
                        <span className={`spanAppName ${theme}`}>
                          {app_name}
                        </span>{" "}
                      </p>
                    ) : (
                      <p
                        className="ranking_msg"
                        style={{ fontWeight: 400, whiteSpace: "nowrap" }}
                      >
                        <span className={`spanAppName ${theme}`}>
                          {app_name}
                        </span>{" "}
                        {rankMsgState[0][2]}
                      </p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  backgroundColor: theme === "light" ? " #fff" : "#282828",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  height: "100%",
                }}
              >
                <img
                  src={
                    theme === "light" ? noDataAvailableDark : noDataAvailable
                  }
                  alt="No Data"
                  style={{ height: "210px", margin: "auto" }}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: theme === "light" ? " #fff" : "#282828",
              display: "flex",
              alignItems: "center",
              padding: "20px",
              height: "100%",
            }}
          >
            <img
              src={theme === "light" ? noDataAvailableDark : noDataAvailable}
              alt="No Data"
              style={{ height: "250px", margin: "auto" }}
            />
          </div>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user,
  appDetails: state.AppsData.appDetails,
  theme: state.user.ui_preferences?.theme,
  app_name: state?.AppsData?.appDetails?.data?.app_meta?.app_name,
});

export default connect(mapStateToProps)(RankingComponent);
