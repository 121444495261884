import React from "react";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import FileUploader from "./FileUploader";
import UploadedFile from "./UploadedFile";
import FormLabelComponent from "../../../components/FormLabelComponent";
import { set_help_center_state } from "../../../redux/actions";
import "../style/style.scss";

const Attachments = ({
    width,
    data,
    uploadedData = [],
    handleChange,
    helpCenterState,
}) => {
    const { label, key, required, toolTipText } = data;
    const { formData } = helpCenterState;
    const dispatch = useDispatch();

    const handleDelete = (index) => {
        const reqData = {
            ...formData,
            [key]: [
                ...(formData[key] || []).slice(0, index),
                ...(formData[key] || []).slice(index + 1),
            ],
        };
        dispatch(set_help_center_state({ formData: reqData }));
    };
    return (
        <Grid item xs={width || 4}>
            <FormLabelComponent
                label={label}
                required={required}
                toolTipText={toolTipText}
            />
            <FileUploader handleChange={handleChange} keyField={key} />
            <div className="help-center-attachment-container">
                {uploadedData.map((item, i) => (
                    <UploadedFile
                        file={item}
                        handleDelete={handleDelete}
                        key={i}
                        indexValue={i}
                    />
                ))}
            </div>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    helpCenterState: state.helpCenter.helpCenterState,
});
export default connect(mapStateToProps)(Attachments);
