//Import required libraries
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { toast } from "react-toastify";
// Import action creators
import { update_powerBiToken } from "../../redux/actions";
import Loader from "../Loader/Loader";
import loginImg from "../../../assets/images/register-images/login-img.png";
// Import styles
import "./AppReport.scss";

const LoginPowerbiScreen = ({
  setIsLoginRequired,
  get_accessToken,
  setIsLoading,
  theme,
}) => {
  const showToaster = () => {
    setTimeout(() => {
      toast.success(
        "You have successfully signed In. Now you can reopen your workspaces.",
        {
          style: {
            fontSize: "14px",
            color: "#4e4e4e",
          },
        }
      );
    }, 1500);
  };
  const handleAuth = () => {
    window.open(`${window.origin}/powerbi_auth/powerbi_login`, "_blank"); //need to be changed after powerbi access is fully set on backend
    showToaster();
    setIsLoginRequired(false);

    setIsLoading(true);
    setTimeout(() => {
      get_accessToken();
    }, 5000);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img src={loginImg} alt="" />
      <div
        style={{
          color: theme === "dark" ? "#fff" : "#000",
        }}
      >
        Please login to PowerBi to see your reports
      </div>
      <button
        onClick={handleAuth}
        style={{
          width: "150px",
          marginTop: "10px",
        }}
      >
        Login
      </button>
    </div>
  );
};

const PowerbiEmbedding = ({
  reportID,
  frameHeight,
  expTimestamp,
  accessToken,
  showFullScreen,
  theme,
  navContentPaneEnabled,
  appId,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoginRequired, setIsLoginRequired] = useState(false);
  const [isActionBarAndBookmarksNeeded, setIsActionBarAndBookmarksNeeded] = useState(false);
  const currentTime = new Date().getTime();
  const get_accessToken = async () => {
    setIsLoading(true);
    var url = "/powerbi_auth/get_token_from_auth";
    const request_meta = {
      method: "GET",
    };
    try {
      const res = await fetch(url, request_meta);
      const response = await res.json();
      if (response.hasOwnProperty("token") && response.token !== null) {
        dispatch(
          update_powerBiToken({
            token: response.token?.access_token,
            expTimestamp: currentTime + response.token?.expires_in * 1000,
          })
        );
      } else {
        setIsLoginRequired(true);
      }
    } catch (error) {
      toast.error("Couldn't load Token. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  // const getNewAccessToken = () => {
  //   console.log("Get Refresh Token");
  // };

  useEffect(() => {
    if (expTimestamp === 0 || expTimestamp <= currentTime) {
      get_accessToken();
    } else {
      setIsLoading(false);
    }
    if (appId === "9cf7c8a9-34c2-4-active") {
      setIsActionBarAndBookmarksNeeded(true);
    }
  }, []);

  let fullScreen = {
    position: "fixed",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
    border: "none",
    margin: "0",
    padding: "0",
    overflow: "hidden",
    zIndex: "99",
  };

  return (
    <>
      {isLoading === false ? (
        isLoginRequired ? (
          <LoginPowerbiScreen
            setIsLoginRequired={setIsLoginRequired}
            get_accessToken={get_accessToken}
            setIsLoading={setIsLoading}
            theme={theme}
          />
        ) : (
          <div
            style={
              showFullScreen
                ? fullScreen
                : { width: "100%", height: frameHeight || "100%" }
            }
          >
            <PowerBIEmbed
              embedConfig={{
                type: "report",
                id: reportID,
                // embedUrl: embedUrl,
                accessToken: accessToken,
                tokenType: models.TokenType.Aad,
                // pageName: "ReportSection326586e2d042a85eb50a",
                // eventHooks: {
                //   accessTokenProvider: getNewAccessToken,
                // },
                // filters: [loadFilter],
                // bookmark: {
                //   name: "Bookmark5ec2439a996b07303a7c",
                // },
                settings: {
                  navContentPaneEnabled: navContentPaneEnabled,
                  persistentFiltersEnabled: true,
                  personalBookmarksEnabled: true,
                  bars: {
                    actionBar: {
                      visible: isActionBarAndBookmarksNeeded,
                    },
                    statusBar: {
                      visible: true,
                    },
                  },
                  panes: {
                    filters: {
                      expanded: false,
                      visible: true,
                    },
                    bookmarks: {
                      visible: isActionBarAndBookmarksNeeded,
                    },
                  },
                },
              }}
              // eventHandlers={
              //   new Map([
              //     [
              //       "loaded",
              //       function () {
              //         console.log("Report loaded");
              //       },
              //     ],
              //     [
              //       "rendered",
              //       function () {
              //         console.log("Report rendered");
              //       },
              //     ],
              //     [
              //       "error",
              //       function (event) {
              //         console.log(event.detail);
              //       },
              //     ],
              //   ])
              // }
              cssClassName={"Embed-container"}
              // getEmbeddedComponent={(embeddedReport) => {
              //   window.report = embeddedReport;
              // }}
            />
          </div>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Loader message="Loading report..." />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
  appDetails: state.AppsData.appDetails,
  accessToken: state.handleBiEmbedToken.token,
  expTimestamp: state.handleBiEmbedToken.expTimestamp,
  appId: state.appDashboard.appDashboardState.appData.app_id,
});

export default connect(mapStateToProps)(PowerbiEmbedding);
