import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

function GetTopUserSlider({
  anchorEl,
  openAnchor,
  handleCloseAnchor,
  AppsData,
  appId,
  topUserRef,
  detailRef,
  classes,
}) {
  const [topUsers, setTopUsers] = useState([]);
  const [isError, setIsError] = useState(false);
  const [colSpan, setColSpan] = useState(1);

  const distanceVertical =
    detailRef.current?.offsetTop - topUserRef.current?.offsetTop;
  const distanceHorizontal =
    detailRef.current?.offsetWidth -
    (topUserRef.current?.offsetLeft + topUserRef.current?.offsetWidth);

  useEffect(() => {
    if (AppsData?.topUsersApp?.[appId]?.status === "success") {
      const sortDict = (dict) => {
        if (!dict) {
          return [];
        }
        let items = Object.keys(dict).map(function (key) {
          return [key, dict[key]];
        });
        items.sort(function (first, second) {
          return second[1] - first[1];
        });
        return items.slice(0, 10);
      };
      const sortedUser = sortDict(AppsData?.topUsersApp?.[appId]?.data);
      setTopUsers(sortedUser);
      if (window.innerWidth > 1200) setColSpan(2);
    }
    if (AppsData?.topUsersApp?.[appId]?.status === "error") {
      setIsError(true);
    }
  }, [AppsData?.topUsersApp?.[appId]?.status]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="topuser-menu"
        open={openAnchor}
        onClose={handleCloseAnchor}
        onClick={handleCloseAnchor}
        sx={{
          marginLeft: `${distanceHorizontal + 10}px`,
          marginTop: `${distanceVertical}px`,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            boxShadow: "4px 4px 12px 4px #434343",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 130, left: 1040 }}
        className={classes.popupTopUser}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ borderBottom: "none" }}
                align="center"
                colSpan={colSpan}
              >
                <p className={classes.topUserHeading}>Top Users in 30 days</p>
              </TableCell>
            </TableRow>
          </TableHead>
          {/* <MenuItem><p className={classes.topUserHeading}>Monthly Top Users</p></MenuItem> */}
          {AppsData?.topUsersApp?.[appId]?.status === "loading" ? (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton width={120} />
                </TableCell>
              </TableRow>
            </TableHead>
          ) : topUsers.length ? (
            <>
              {window.innerWidth > 1200 && (
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <p className={classes.infoPara}>USER</p>
                    </TableCell>
                    <TableCell align="right">
                      <p className={classes.infoPara}>VISITS</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {topUsers.map((user, index) => (
                  <Tooltip title={user[0]}>
                    <StyledTableRow key={index}>
                      <TableCell align="left">
                        <p className={classes.infoPara}>
                          {user[0].length <= 24
                            ? user[0]
                            : `${user[0].slice(0, 21)}...`}
                        </p>
                      </TableCell>
                      {window.innerWidth > 1200 && (
                        <TableCell align="right">
                          <p className={classes.infoPara}>{user[1]}</p>
                        </TableCell>
                      )}
                    </StyledTableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </>
          ) : isError ? (
            <TableBody>
              <StyledTableRow sx={{ backgroundColor: "rgb(0 0 0 / 5%)" }}>
                <TableCell align="center">
                  <p className={classes.infoPara}>Error in Retrieving</p>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          ) : (
            <TableBody>
              <StyledTableRow sx={{ backgroundColor: "rgb(0 0 0 / 5%)" }}>
                <TableCell align="center">
                  <p className={classes.infoPara}>No recent Users</p>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          )}
        </Table>
      </Menu>
    </>
  );
}

const mapStateToProps = (state) => ({
  AppsData: state.AppsData,
});

export default connect(mapStateToProps)(GetTopUserSlider);
