import React, { useState, useEffect } from "react";
import { Box, IconButton, TextField, Typography, Modal } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment";
import { connect } from "react-redux";
import { ReactComponent as CalendarIcon } from "../../../../assets/images/calendar-icon-v2.svg";

function MonthSelector({ appDetails, year, setYear, month, setMonth ,theme}) {
  const timestamp = appDetails?.data?.app_meta?.published_on
  // const timestamp = 1645922129000;
  const [isMonthSelect, setIsMonthSelect] = useState(false);
  const selectedDate = moment(timestamp);
  //   const [year, setYear] = useState(selectedDate.year());
  //   const [month, setMonth] = useState(selectedDate.month());

  const months = moment.monthsShort();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const disableMonth = (targetYear, targetMonth) => {
    const targetDate = moment({ year: targetYear, month: targetMonth });
    const startOfMonth = selectedDate.startOf("month");
    const endOfMonth = moment();

    return targetDate.isBefore(startOfMonth) || targetDate.isAfter(endOfMonth);
  };

  const monthSelectStyle = {
    zIndex: 1300,
    position: "relative",
    top: {
      xxs: "37%",
      xs: "37%",
      sm: "37%",
      md: "307px",
    },
    left: "52%",
    transform: {
      xs: "translate(-50%, -50%)",
      sm: "translate(-50%,0)",
    },
    maxWidth: 280,
    minHeight: 200,
    bgcolor: "#fff",
    boxShadow: 10,
    borderRadius: "8px",
    pl: 3,
    pr: 3,
    pt: 1,
    pb: 2,
  };
  
  useEffect(() => {
    // Set the default year and month to the current year and month when the component mounts
    setYear(currentYear);
    setMonth(currentMonth);
  }, [setYear, setMonth]);
  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column">
          <FormControl
            variant="outlined"
            fullWidth
            onClick={() => setIsMonthSelect(true)}
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <TextField
              id="outlined-adornment-password"
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={() => setIsMonthSelect(true)}>
                      <CalendarIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <KeyboardArrowDownIcon color={theme === "dark" ? "secondary" : "text"} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& fieldset": {
                  borderRadius: "8px",
                  border: "#000000",
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                  fontFamily: 'Energy',
                },
                borderRadius: "8px",
                width: "195px",
                background: "white",
                color: "black",
                fontFamily: 'Energy',
              }}
              size="small"
              value={`${months[month]} ${selectedDate !== "all" ? year : ""}`}
            />
          </FormControl>
        </Box>
        <Modal
          open={isMonthSelect}
          onClose={() => setIsMonthSelect(false)}
          // Backdrop={true}
        >
          <Box display="flex" flexDirection="column" sx={monthSelectStyle}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              fontFamily= 'Energy'
            >
              <IconButton
                aria-label="share"
                component="span"
                onClick={() => {
                  if (year > selectedDate.year()) {
                    setYear((prev) => prev - 1);
                  }
                }}
              >
                <ChevronLeftIcon fontSize="small" color="primary" />
              </IconButton>{" "}
              <Box display="flex" justifyContent="center" sx={{ mt: 0.8 }}>
                <Typography fontSize={14} fontFamily= 'Energy'>{year}</Typography>
              </Box>
              <IconButton
                aria-label="share"
                component="span"
                onClick={() => {
                  if (year < currentYear) {
                    setYear((prev) => prev + 1);
                  }
                }}
              >
                <ChevronRightIcon fontSize="small" color="primary" />
              </IconButton>{" "}
            </Box>
            <Box>
              <Box
                display="flex"
                flexWrap="wrap"
                gap={2}
                justifyContent="center"
                sx={{ mt: 2 }}
              >
                {months.map((m, idx) => (
                  <Box
                    key={idx}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    fontFamily= 'Energy'
                    sx={{
                      "&:hover": {
                        cursor: disableMonth(year, idx)
                          ? "not-allowed"
                          : "pointer",
                        color: disableMonth(year, idx) ? "" : "white",
                        backgroundColor: disableMonth(year, idx) ? "" : "pink",
                      },
                      pb: 0.5,
                      pt: 0.5,
                      opacity: disableMonth(year, idx) ? 0.5 : 1,
                      backgroundColor:
                        idx === month ? "lightblue" : "transparent",
                    }}
                    onClick={() => {
                      if (!disableMonth(year, idx)) {
                        setMonth(idx);
                        setIsMonthSelect(false);
                      }
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      color={
                        disableMonth(year, idx) ? "tertiary.lightGrey3" : ""
                      }
                      fontFamily= 'Energy'
                      width="45px"
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {m}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}
const mapStateToProps = (state) => ({
  userInfo: state.user,
  appDetails: state.AppsData.appDetails,
  usage_report_users: state.register.usage_report_users,
  theme: state.user.ui_preferences?.theme,
});
export default connect(mapStateToProps)(MonthSelector);
