import React, { useEffect, useMemo, useRef } from "react";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import landingImg from "../../../../assets/images/Dashboard-images/project-management.svg";
import { getEditorState, specialFeilds } from "../../../utils/utils";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import "../styles/template4.scss";

const CardComponent = ({ handleNavigation, item, theme }) => {
    const refName = useRef();
    const refDesc = useRef();

    useEffect(() => {
        if (specialFeilds.includes(item.sub_category_type)) {
            const name = getEditorState(item.sub_category_name);
            const desc = getEditorState(item.sub_category_description);

            const reqHtmlName = draftToHtml(
                convertToRaw(name.getCurrentContent())
            );
            const reqHtmlDesc = draftToHtml(
                convertToRaw(desc.getCurrentContent())
            );
            if (refName?.current) {
                refName.current.innerHTML = reqHtmlName
                    .replace(/&amp;/g, "&")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">")
                    .replace(/&quot;/g, '"')
                    .replace(/&apos;/g, "'");
            }
            if (refDesc?.current) {
                refDesc.current.innerHTML = reqHtmlDesc
                    .replace(/&amp;/g, "&")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">")
                    .replace(/&quot;/g, '"')
                    .replace(/&apos;/g, "'")
                    .replaceAll("\\n", "\n");
            }
        }
    }, [
        item.sub_category_description,
        item.sub_category_id,
        item.sub_category_name,
        item.sub_category_type,
    ]);
    return (
        <Box
            className={`dashboard-temp4-card ${
                theme === "dark"
                    ? "dashboard-temp4-dark"
                    : "dashboard-temp4-light"
            }`}
            sx={(theme) => ({
                backgroundColor: item.card_bg_color
                    ? item.card_bg_color
                    : theme.palette.mode === "dark"
                    ? "#282828"
                    : "#fff",
            })}
            onClick={() => handleNavigation(item)}
        >
            <div className="dashboard-temp4-inner-container">
                <div className="dashboard-temp4-title" ref={refName}>
                    {item.sub_category_name}
                </div>
                {item.app_logo_type === "google_font" ? (
                    <span
                        class="material-icons-outlined googleIcons"
                        style={{
                            fontSize: "130px",
                        }}
                    >
                        {item.sub_category_icon}
                    </span>
                ) : (
                    <img
                        src={item.sub_category_icon || landingImg}
                        alt=""
                        className="dashboard-temp4-img"
                    />
                )}
            </div>
            <div className="dashboard-temp4-inner-container">
                <div className="dashboard-temp4-additional-cont">
                    {(item.additional_images || []).map((icon) => {
                        return (
                            <React.Fragment key={icon.blob_name}>
                                {icon.app_logo_type === "google_font" ? (
                                    <span class="material-icons-outlined googleIcons">
                                        {icon.sub_category_icon}
                                    </span>
                                ) : (
                                    <img
                                        src={
                                            icon.sub_category_icon || landingImg
                                        }
                                        alt=""
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
                <div className="dashboard-temp4-desc" ref={refDesc}>
                    {(item.sub_category_description || "").replaceAll(
                        "\\n",
                        "\n"
                    )}
                </div>
            </div>
        </Box>
    );
};

const LandingTemplate4 = ({ reportData = [], handleNavigation, theme }) => {
    const getHeaderData = useMemo(
        () => reportData.filter((item) => item.sub_category_type === "header"),
        [reportData]
    );
    return (
        <Box
            className="dashboard-temp4-container"
            style={{
                height: `calc(100vh - 170px - ${
                    getHeaderData.length > 0 ? getHeaderData.length * 60 : 0
                }px)`,
            }}
            sx={{
                "& .googleIcons": {
                    background:
                        "linear-gradient(45deg, #FC007F 0%,#FC007F 50% ,#FF4D48 100%)",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                },
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                // justifyContent="space-between"
                flexWrap="wrap"
                // gap={1}
                gap="3%"
                height="98%"
                mt="20px"
                px="10px"
                textAlign="center"
            >
                {reportData.map((item) => {
                    if (item.sub_category_type === "header") return null;
                    return (
                        <CardComponent
                            handleNavigation={handleNavigation}
                            item={item}
                            theme={theme}
                            key={item.sub_category_id}
                        />
                    );
                })}
                <div style={{ flex: 1 }}></div>
            </Box>
        </Box>
    );
};
const mapStateToProps = (state) => ({
    theme: state.user.ui_preferences?.theme,
});
export default connect(mapStateToProps)(LandingTemplate4);
