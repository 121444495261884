import React, { useEffect, useState } from "react";
import AppReport from "./AppReport";
import SideNavigationPanel from "./SideNavigationPanel";
import { useHistory, useParams } from "react-router";
import { set } from "lodash";

const get_ValidName = (value) => {
  if (value === undefined || value === null || value === "") {
    return "";
  }
  if (value?.startsWith('{"blocks"')) {
    return JSON.parse(value).blocks[0]?.text;
  } else {
    return value;
  }
};

const NewAppReport = ({
  app,
  activeReport,
  breadCrumbsData,
  setBreadCrumbsData,
  user,
}) => {
  const { appInfo } = useParams();
  const history = useHistory();
  const [loadedReport, setLoadedReport] = useState(() => {
    return activeReport;
  });
  const [sortData, setSortData] = useState([]);

  const findElementPath = (list, target, path = []) => {
    for (let i = 0; i < list.length; i++) {
      const current = list[i];

      if (current?.sub_category_type === "report") {
        if (current?.sub_category_id === target?.sub_category_id) {
          return {
            reportName: [get_ValidName(current.sub_category_name), i],
          };
        }
      }
    }
    return null; // Return null if the target element is not found
  };

  useEffect(() => {
    const reqIds = appInfo.split("&");
    let myPath = findElementPath(
      app?.app_details?.defined_categories,
      loadedReport
    );
    myPath = {
      appName: app?.app_meta?.app_name,
      ...myPath,
    };

    setBreadCrumbsData(myPath);
  }, [loadedReport]);


  useEffect(() => {
    function subCategoryNameSort(obj1, obj2) {
      if (
        (obj1.sub_category_type === "hybrid" ||
          obj1.sub_category_type === "parentReport" ||
          obj1.sub_category_type === "customHybrid") &&
        obj1.sub_category.length > 0
      ) {
        obj1.sub_category.sort(subCategoryNameSort);
      }

      const subCategoryName1 = get_ValidName(obj1.sub_category_name);
      const subCategoryName2 = get_ValidName(obj2.sub_category_name);

      // Extract numeric parts from names
      const numPart1 = subCategoryName1.match(/\d+/);
      const numPart2 = subCategoryName2.match(/\d+/);

      // Extract and compare the entire non-numeric parts
      const nonNumPart1 = subCategoryName1.replace(numPart1, "");
      const nonNumPart2 = subCategoryName2.replace(numPart2, "");
      const nonNumComparison = nonNumPart1.localeCompare(nonNumPart2);

      // If both have numeric parts, compare based on the numeric values
      if (numPart1 && numPart2 && numPart1.index === numPart2.index) {
        if (nonNumComparison == 0) {
          const numValue1 = parseInt(numPart1[0]);
          const numValue2 = parseInt(numPart2[0]);
          return numValue1 - numValue2;
        }
      }

      return subCategoryName1.localeCompare(subCategoryName2);
    }
    setSortData(app?.app_details?.defined_categories.sort(subCategoryNameSort));
  }, []);

  return (
    <div style={{ height: "calc(100vh - 164px)" }}>
      <div style={{ display: "flex" }}>
        <SideNavigationPanel
          definedCategoriesList={sortData}
          loadedReport={loadedReport}
          setLoadedReport={setLoadedReport}
          sidePanelWidth={"250px"}
          expandButtonLeftMargin={"302px"}
        />
        <AppReport app={app} activeReport={loadedReport} extraHeight={45} />
      </div>
    </div>
  );
};

export default NewAppReport;
