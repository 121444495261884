// Import required libraries
import React from "react";
import { connect } from "react-redux";

// Import images
import DR_image from "../../../assets/images/DR_img.svg";
import explore_img from "../../../assets/images/explore_img.svg";
import faq_img from "../../../assets/images/faq_img.svg";
import help_img from "../../../assets/images/help_img.svg";
import home_img from "../../../assets/images/home_img.svg";
import myapps_img from "../../../assets/images/myapps_img.svg";
import manage_profile from "../../../assets/images/manage_profile.svg";

// Import styles
import "./RBImageHeader.scss";

function RBImageHeader(props) {
  let { match, user } = props;

  const headerMapping = {
    explore: "Explore Apps",
    home: "Data@Reckitt",
    myapps: "My Apps",
    // "/favourites": "Favourites",
    faqs: "FAQs",
    help: "Help",
    "profile-management": "Manage Profile",
  };

  const taglineMapping = {
    explore: "Explore data applications across Reckitt",
    home: "One stop for all your data, anytime, anywhere.",
    myapps: "Manage your applications here.",
    //  "favourites": "View your preferred applications.",
    faqs: "Frequently Asked Questions.",
    help: "Your worries end here.",
    "profile-management": "Customise your experience here.",
  };

  const imageMapping = {
    explore: explore_img,
    home: home_img,
    myapps: myapps_img,
    faqs: faq_img,
    help: help_img,
    "profile-management": manage_profile,
  };

  const headerText = headerMapping[match.path.split("/")[1]] || "Data@Reckitt";
  const TaglineText =
    taglineMapping[match.path.split("/")[1]] ||
    "One stop for all your data, anytime, anywhere.";
  const headerImg = imageMapping[match.path.split("/")[1]] || DR_image;

  return (
    <div className="rb-image-content-container">
      <img className="rb-image-content" src={headerImg} alt="Data@Reckitt" />
      <div className="rb-text-content">
        <div className="rb-text-heading"> {headerText}</div>
        <div className={`rb-text ${user.ui_preferences.theme}`}>
          {TaglineText}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(RBImageHeader);