import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { connect } from "react-redux";

import status_success from "../../../../assets/images/register-images/status_success.svg";
import status_pending from "../../../../assets/images/register-images/pending.svg";
import status_to_be_picked from "../../../../assets/images/register-images/toBePicked.svg";
import status_reject from "../../../../assets/images/register-images/rejected.svg";

// styles
import "../index.scss";
import { CssTextField, StyledAutoComplete, useStyles } from "../style";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { set } from "date-fns";

const options = ["To be picked up", "In progress", "Denied", "Completed"];
const icons = {
  "To be picked up": "info",
  "In progress": "refresh",
  Denied: "do_not_disturb_on",
  Completed: "task_alt",
};
const colors = {
  "To be picked up": "#55C3F0",
  "In progress": "#F0CD28",
  Denied: "#C70039",
  Completed: "#50B428",
};

const CopyToClipBoad = ({ text }) => {
  const classes = useStyles();
  const eventTracker = useAnalyticsEventTracker("Track status");
  const [show, setShow] = useState(false);
  const removeItem = () => {
    setTimeout(() => setShow(false), 1000);
  };
  const copyText = () => {
    navigator.clipboard.writeText(text);
    setShow(true);
    removeItem();
    eventTracker("copy content", text);
  };
  return (
    <Box display="flex" alignItems="center" gap={1} fontSize={12}>
      <span
        onClick={copyText}
        title="copy"
        className={`GA-copy-icon ${classes.copyOnClick} material-icons-outlined`}
        id={text}
      >
        content_copy
      </span>
      {show && <div>Copied</div>}
    </Box>
  );
};

const Stepper = ({
  step,
  value = {},
  handleChange,
  options,
  role,
  data,
  isFieldsEditable,
  app_id,
  theme,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    value.provisoning_status || ""
  );

  const refOne = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (event) => {
    if (refOne.current && !refOne.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const classes = useStyles();
  const eventTracker = useAnalyticsEventTracker("Track status", app_id);
  const [showDetails, setShowDetails] = useState(false);

  const handleShowHideDetails = () => {
    eventTracker("Show&hide details", showDetails ? "Hide" : "Show");
    setShowDetails((prev) => !prev);
  };

  const openURLinNewTab = (url) => () => {
    eventTracker("Open URL in new tab", url);
    window.open(url, "_blank");
  };
  return (
    <>
      <Box display="flex" alignItems="center" my={2}>
        {role === "admin" || role === "super_admin" ? (
          <Box width="145px" mr={2}>
            <div
              onClick={() =>
                !isFieldsEditable
                  ? (setShowDropdown(!showDropdown), setShowDetails(false))
                  : null
              }
              style={{
                color: colors[selectedOption],
                alignItems: "center",
                width: "100%",
                borderRadius: "2rem",
                border: "1px solid",
                height: "32px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                class="material-symbols-outlined"
                style={{ fontSize: "20px" }}
              >
                {icons[selectedOption]}
              </span>
              <div
                className="desciption"
                style={{
                  marginLeft: "6px",
                  fontSize: "12px",
                  letterSpacing: "0.16px",
                }}
              >
                {selectedOption}
              </div>
            </div>

            {showDropdown && (
              <div
                ref={refOne}
                style={{
                  position: "absolute",
                  zIndex: 999,
                  color: "#415a6c",
                  width: "145px",
                  border: "1px solid #ccc",
                  backgroundColor: "#fff",
                  borderRadius: "11px",
                }}
              >
                {options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        paddingBottom: "8px",
                        paddingTop: "8px",
                        paddingLeft: "8px",
                        fontSize: "14px",
                        cursor: "pointer",
                        borderRadius: "9px",
                      }}
                      onClick={() => {
                        handleChange(step.label, option);
                        setSelectedOption(option);
                        setShowDropdown(!showDropdown);
                      }}
                      className="menu"
                    >
                      {option}
                    </div>
                  );
                })}
              </div>
            )}
          </Box>
        ) : (
          <img
            src={
              value.provisoning_status === "Completed"
                ? status_success
                : value.provisoning_status === "Denied"
                ? status_reject
                : value.provisoning_status === "In progress"
                ? status_pending
                : status_to_be_picked
            }
            title={value.provisoning_status}
            alt=""
            style={{
              width: 15,
              height: 15,
              marginRight: 15,
              marginTop: 5,
            }}
          />
        )}

        <div>
          <div
            className={
              theme === "light"
                ? "track-status-label-light"
                : "track-status-label-dark"
            }
            style={{
              marginTop:
                step.key === "step_1" ||
                step.key === "step_1_1" ||
                step.key === "step_8"
                  ? "6px"
                  : "0px",
              fontSize: "14px",
            }}
          >
            {step.label}
          </div>
          {(step.key === "step_5" ||
            step.key === "step_6" ||
            step.key === "step_7") &&
          data?.url ? (
            <button
              onClick={handleShowHideDetails}
              className="track-status-url-text url-btn GA-show-detials"
              id="Track_status"
            >
              {showDetails ? "Hide Details" : "View Details"}
            </button>
          ) : (
            <Box display="flex" alignItems="center">
              <div className="track-status-url-text">{data}</div>
              {data && <CopyToClipBoad text={data} />}
            </Box>
          )}

          {showDetails && (
            <>
              <div
                className="url-text"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Url:{" "}
                <a href={data?.url} target="_blank" rel="noreferrer">
                  <span className={classes.urlLink}>{data?.url}</span>
                </a>
                <CopyToClipBoad text={data?.url} />
                <span
                  class="material-symbols-outlined"
                  style={{
                    cursor: "pointer",
                    paddingLeft: "10px",
                    fontSize: "14px",
                  }}
                  onClick={openURLinNewTab(data?.url)}
                >
                  north_east
                </span>
              </div>
              <div
                className="url-text"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Frontend Pipeline:{" "}
                <span style={{ color: "#0058aa", marginLeft: "5px" }}>
                  {data?.frontend_pipeline}
                </span>
                <CopyToClipBoad text={data?.frontend_pipeline} />
              </div>
              <div
                className="url-text"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Backend Pipeline:{" "}
                <span style={{ color: "#0058aa", marginLeft: "5px" }}>
                  {data?.backend_pipeline}
                </span>
                <CopyToClipBoad text={data?.backend_pipeline} />
              </div>
            </>
          )}
        </div>
      </Box>
    </>
  );
};

const TrackStepper = ({
  fields,
  userInfo,
  provisioning_details,
  handleChange,
  trackStatusState,
  isFieldsEditable,
  appDetails = {},
  theme,
}) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const getData = (k) => {
    if (k === "step_2") return provisioning_details?.frontend_repository_name;
    else if (k === "step_3")
      return provisioning_details?.backend_repository_name;
    else if (k === "step_4") return provisioning_details?.db_name;
    else if (k === "step_5")
      return provisioning_details?.deployment_env?.find(
        (item) => item.env_name === "Development Environment"
      );
    else if (k === "step_6")
      return provisioning_details?.deployment_env?.find(
        (item) => item.env_name === "UAT Environment"
      );
    else if (k === "step_7")
      return provisioning_details?.deployment_env?.find(
        (item) => item.env_name === "Production Environment"
      );
  };

  const getValue = (val) =>
    trackStatusState.find((item) => item.resource_name === val);

  return (
    <Box my={2}>
      {(fields || []).slice(2).map((step) => {
        const data = getData(step.key);
        if (
          (step.key === "step_5" ||
            step.key === "step_6" ||
            step.key === "step_7") &&
          !data?.url
        ) {
          return null;
        } else {
          return (
            <Stepper
              key={step.label}
              step={step}
              options={options}
              handleChange={handleChange}
              value={getValue(step.label)}
              role={userInfo.role}
              data={getData(step.key)}
              isFieldsEditable={isFieldsEditable}
              app_id={appDetails.data.app_id}
              theme={theme}
            />
          );
        }
      })}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
  userInfo: state.user,
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(TrackStepper);
