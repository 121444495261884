import React, { useCallback, useMemo, useState } from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Box, IconButton } from "@mui/material";
import { connect } from "react-redux";
import { CssTextField, StyledAutoComplete, useStyles } from "./Style";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" htmlColor="#FF007F" />;
const greyicon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckBoxComponent = ({ checked, isFilterComponent }) => {
  const classes = useStyles();
  return (
    <Checkbox
      icon={isFilterComponent ? greyicon : icon}
      checkedIcon={checkedIcon}
      className={classes.typographyStyle}
      size="small"
      style={{
        marginRight: 5,
        padding: "0px",
      }}
      sx={{
        "&.Mui-checked": {
          color: "#FC007F",
        },
        "& .MuiSvgIcon-root": {
          fontSize: isFilterComponent ? "11px" : "1.2rem",
        },
      }}
      checked={checked}
    />
  );
};

const CustomAutoComponent = ({
  data: info,
  selectedData = [],
  handleSelection,
  filteredOption,
  theme,
  width,
  customStyling = {},
  isFieldsEditable,
  isFilterComponent,
  registerNewApp,
}) => {
  const classes = useStyles();
  const { label, key } = info;
  const [isMyInputFocused, setIsMyInputFocused] = useState(false);
  // const [filteredOptions, setFilteredOptions] = useState(options);
  const [showList, setShowList] = useState(false);
  const [value, setValue] = useState("");
  const [searchedOptions, setSearchedOptions] = useState([]);
  const selectedCountries = useMemo(
    () => selectedData.map((item) => item.countries).flat(),
    [selectedData]
  );

  const selectedRegions = useMemo(
    () => selectedData.map((item) => item.region),
    [selectedData]
  );

  const isAllSelected = useCallback(
    (region) => {
      const req_region = selectedData.find((item) => item.region === region);
      if (req_region) {
        return req_region.countries.includes("All");
      }
      return false;
    },
    [selectedData]
  );

  const getFirstTwo = () => {
    let reqString = selectedData[0].region;
    if (selectedData.length >= 2) {
      reqString += `, ${selectedData[1].region}`;
    }
    return reqString;
  };

  const handleInputChange = (e) => {
    setValue(e.target.value);
    const value = (e.target.value || "").toLowerCase();
    const newOptionsList = [];
    filteredOption.forEach((item) => {
      const countries = item.countries.filter((country) =>
        country.toLowerCase().includes(value)
      );
      if (countries.length) {
        newOptionsList.push({
          region: item.region,
          countries,
        });
      }
    });
    setSearchedOptions(newOptionsList);
  };

  const handleShowList = (e, region) => {
    e.stopPropagation();
    setShowList((prev) => (prev === region ? null : region));
  };

  const sortRegionOption = (newInfo) => {
    const selectedOptions = [];
    const unSelectedOptions = [];
    const selectedData = newInfo.map((item) => item.region);
    filteredOption.forEach((item) => {
      if (selectedData.includes(item.region)) {
        selectedOptions.push(item);
      } else {
        unSelectedOptions.push(item);
      }
    });
    const sortedData = unSelectedOptions.sort(function (a, b) {
      return a.region - b.region;
    });
    return [...selectedOptions, ...sortedData];
  };

  const handleBlur = () => {
    setIsMyInputFocused(false);
    setValue("");
    setSearchedOptions(filteredOption);
    setShowList(null);
  };
  const sortCountriesOption = (newInfo, region) => {
    const regionIndexInOptions = filteredOption.findIndex(
      (item) => item.region === region
    );
    const regionIndexInData = newInfo.findIndex(
      (item) => item.region === region
    );
    const selectedCountriesData = newInfo[regionIndexInData].countries;
    const UnSelectedCountries = filteredOption[
      regionIndexInOptions
    ].countries.filter((item) => !selectedCountriesData.includes(item));
    return [
      ...filteredOption.slice(0, regionIndexInOptions),
      {
        ...filteredOption[regionIndexInOptions],
        countries: [
          ...selectedCountriesData.sort(),
          ...UnSelectedCountries.sort(),
        ],
      },
      ...filteredOption.slice(regionIndexInOptions + 1),
    ];
  };

  const handleRegion = (e, region) => {
    e.stopPropagation();
    if (isFieldsEditable) return;
    const reqInd = selectedData.findIndex((item) => item.region === region);
    let reqData = [];
    if (reqInd >= 0) {
      reqData = [
        ...selectedData.slice(0, reqInd),
        ...selectedData.slice(reqInd + 1),
      ];
    } else {
      if (
        region === "All" ||
        (selectedData.length && selectedData[0].region === "All")
      ) {
        reqData = [{ region, countries: ["All"] }];
      } else {
        reqData = [...selectedData, { region, countries: ["All"] }];
      }
    }
    const sortedOptions = sortRegionOption(reqData);
    handleSelection(key, reqData, sortedOptions);
  };

  const updateValue = (reqInd, countries, region) => {
    const reqData = [
      ...selectedData.slice(0, reqInd),
      {
        ...selectedData[reqInd],
        countries,
      },
      ...selectedData.slice(reqInd + 1),
    ];
    // sortCountriesOption(reqData, region);
    const sortedOptions = sortCountriesOption(reqData, region);
    handleSelection(key, reqData, sortedOptions);
  };

  const handleCountries = (e, region, country) => {
    e.stopPropagation();
    if (isFieldsEditable) return;
    const reqInd = selectedData.findIndex((item) => item.region === region);
    let reqData = [];
    if (reqInd < 0) {
      const indexOfAll = selectedData.findIndex((reg) => reg.region === "All");
      if (indexOfAll >= 0) {
        reqData = [{ region, countries: [country] }];
      } else {
        reqData = [...selectedData, { region, countries: [country] }];
      }
      // sortRegionOption(reqData);
      const sortedOptions = sortRegionOption(reqData);
      handleSelection(key, reqData, sortedOptions);
    } else {
      if (selectedData[reqInd].countries.includes(country)) {
        if (country === "All") return;
        const filteredCountries = selectedData[reqInd].countries.filter(
          (item) => item !== country
        );
        if (!filteredCountries.length) {
          updateValue(reqInd, ["All"], region);
        } else {
          updateValue(reqInd, filteredCountries, region);
        }
      } else {
        if (
          country === "All" ||
          selectedData[reqInd].countries.includes("All")
        ) {
          updateValue(reqInd, [country], region);
        } else {
          const reqCountries = [...selectedData[reqInd].countries, country];
          updateValue(reqInd, reqCountries, region);
        }
      }
    }
  };

  return (
    <Box
      // width={width || "100%"}
      display="flex"
      alignItems="center"
      className={`${
        selectedData.length && !isMyInputFocused
          ? `${theme}-autoCompeteContainer `
          : ""
      }
          ${width ? "RegionBox-container" : ""}
      `}
      sx={{
        width: registerNewApp.statusData.showDetailsDrawer
          ? "97% !important"
          : isFilterComponent
          ? "101%"
          : width
          ? width
          : "100%",
      }}
      // sx={customStyling}
    >
      <StyledAutoComplete
        multiple
        fullWidth
        classes={{ paper: `"GA-app-filter" ${classes.optionPadding}` }}
        className={
          isFilterComponent
            ? classes.typographyStyleSmall
            : classes.typographyStyle
        }
        options={value.length ? searchedOptions : filteredOption}
        disableCloseOnSelect
        getOptionLabel={(option) => option.region}
        // value={[]}
        filterOptions={(x) => x}
        getOptionSelected={(option, value) => {
          return option === value;
        }}
        renderOption={(option, { selected }) => {
          return (
            <div
              className={classes.raderOptions}
              key={option.region}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className={classes.regionContainer}
                onClick={(e) => handleShowList(e, option.region)}
              >
                <div onClick={(e) => handleRegion(e, option.region)}>
                  <CheckBoxComponent
                    checked={selectedRegions.includes(option.region)}
                    isFilterComponent={isFilterComponent}
                  />

                  {isFilterComponent ? (
                    <span style={{ fontSize: "11px" }}>{option.region}</span>
                  ) : (
                    <span>{option.region}</span>
                  )}
                </div>

                <IconButton
                  className={classes.iconButton}
                  style={{
                    opacity: option.region === "All" ? 0 : 1,
                  }}
                >
                  {showList && showList === option.region ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </div>
              {((showList && showList === option.region) || value) && (
                <div className={classes.countriesList}>
                  {option.countries.map((item) => {
                    return (
                      <div
                        onClick={(e) => handleCountries(e, option.region, item)}
                        key={item}
                      >
                        <CheckBoxComponent
                          checked={
                            item === "All"
                              ? isAllSelected(option.region)
                              : selectedCountries.includes(item)
                          }
                          isFilterComponent={isFilterComponent}
                        />
                        {item}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            className={` ${
              isFilterComponent
                ? classes.typographyStyleSmall
                : classes.typographyStyle
            } ${
              selectedData.length && !isMyInputFocused
                ?`custom_placeholder ${theme}`
                : ""
            }`}
            fullWidth
            value={value}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onFocus={() => setIsMyInputFocused(true)}
            placeholder={
              selectedData.length && !isMyInputFocused
                ? `${getFirstTwo()} ${
                    selectedData.length > 2
                      ? ` +${selectedData.length - 2}`
                      : ""
                  }`
                : `Select ${label}`
            }
          />
        )}
      />
    </Box>
  );
};
const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
  registerNewApp: state.registerNewApp,
});
export default connect(mapStateToProps)(CustomAutoComponent);
