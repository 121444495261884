import { makeStyles } from "@material-ui/core";

export const useStylesforSF = makeStyles((theme) => ({
  flexSB: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px dashed #b4bcc4",
    alignItems: "center",
  },
  displayOnlyFlex: {
    display: "flex",
  },
  topContentContainer: {
    borderBottom: "1px dashed #b4bcc4",
    // color: "#282828",
  },
  platformClass: {
    height: "22px",
    marginLeft: "15px",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  displayContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "8px",
  },
  appMetaContainer: {
    display: "flex",
    justifyContent: "start",
    padding: "10px 0px",
    cursor: "default",
  },
  appMetaContent: {
    display: "flex",
    marginRight: "10px",
    alignItems: "center",
  },
  decriptionContainer: {
    padding: "10px 0px",
  },
  infoHeading: {
    fontWeight: 400,
    fontSize: "12px",
    margin: "0px 10px 0px 0px",
  },
  topUserHeading: {
    fontWeight: 600,
    fontSize: "14px",
    margin: "auto",
  },
  topuserIcon: {
    fontSize: "20px",
  },
  viewButton: {
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "12px",
    margin: "0px 10px 0px 0px",
  },
  infoPara: {
    fontSize: "12px",
    margin: "0px 5px",
    lineHeight: "18px",
  },
  subcatLinks: {
    fontSize: "11px",
    margin: "0px 8px",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#0554A9",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  openAppButton: {
    background: "#FC007F",
    marginLeft: "10px",
    height: "31px",
    width: "150px",
    fontWeight: "600",
    "&:disabled": {
      opacity: "50%",
      cursor: "default",
    },
  },
  ownerListContainer: {
    color: "#fff",
    cursor: "default",
  },
  requestAccessButton: {
    background: "#687988",
    marginLeft: "10px",
    height: "31px",
    width: "150px",
    fontWeight: "600",
    "&:disabled": {
      opacity: "50%",
      cursor: "default",
    },
  },
  bottomContentContainer: {
    padding: "10px 0px ",
    // color: theme.palette.theme.mode === "dark" ? theme.palette.white.main :"#282828",
  },
  dailogContainer: {
    minWidth: "700px !important",
    maxWidth: "700px !important",
    minHeight: "450px !important",
    // maxHeight: "450px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
  },
  alertContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px !important",
      minWidth: "700px !important",
      boxShadow: "4px 4px 12px 4px #434343",
    },
  },
  requestalertContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "500px !important",
      minWidth: "500px !important",
      boxShadow: "4px 4px 12px 4px #434343",
    },
  },
  individualFilterContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "5px 0px",
  },
  headerLeftContainer: {
    display: "flex",
    alignItems: "center",
  },
  headingAppName: {
    marginBlock: "auto",
    marginRight: "15px",
    textTransform: "capitalize",
    // color: "#282828",
  },
  appLogo: {
    fontSize: "32px",
    marginRight: "5px",
  },
  AlertFooterContainerWithOwners: {
    background: "#282828",
    "&.MuiDialogActions-root": {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px 20px",
    },
  },
  AlertFooterContainer: {
    background: "#282828",
    "&.MuiDialogActions-root": {
      display: "flex",
      padding: "10px 20px",
    },
  },
  requestBtns: {
    marginLeft: "10px",
    width: "140px",
    fontWeight: "400",
    "&:disabled": {
      opacity: "50%",
      cursor: "default",
    },
  },
  transparent: {
    backgroundColor: "transparent",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    border: "1px solid #000",
  },
  grey: {
    backgroundColor: "#687988",
  },
  pink: {
    // backgroundColor: "#FD5BB2",
    backgroundColor: "#fc007f",
  },
  textFieldText: {
    "& .MuiOutlinedInput-input": {
      fontSize: "11px",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
      fontFamily: "Energy",
    },
    "& .MuiOutlinedInput-root": {
      padding: "12.5px 14px",
      "& fieldset": {
        border:"1px solid #b1b1b1",
    },
    "&:hover fieldset": {
        borderColor: "#fc007f",
    },
    "&.Mui-focused fieldset": {
        border: "1px solid #fc007f",
    },
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   border:"1px solid #fc007f",
    //   borderRadius:"3px"
    // },

  },
  requestAccessHeading: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "15px",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#000",
    gap: "10px",
    display: "flex",
    // alignItems: "center",
    padding: "20px 24px 2px 24px",
    flexDirection: "column",
  },
  requestAccessAppName: {
    fontSize: "13px",
    fontWeight: "400",
  },
  popupTopUser: {
    fontFamily: "Energy",
  },
}));
