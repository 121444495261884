import { makeStyles, TextField, withStyles } from "@material-ui/core";
import { InputBase, styled, TableRow } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        fontFamily: "Energy",
        backgroundColor: "#f3f3f3",
        // backgroundColor: 'rgba(230 233 238)',
        borderRadius: "4px",
        borderColor: "transparent",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "23px",
        padding: "4px 8px",
        border: "transparent",
    },
    "&:focus": {},
}));

export const CssTextField = withStyles((theme) => ({
    root: {
        width: "90%",
        backgroundColor: "transparent",
        padding: "3px 10px",
        borderRadius: "4px",
        "& label.Mui-focused": {
            color: "green",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#dedede",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "white",
            "& fieldset": {
                borderColor: "#dedede",
            },
            "&:hover fieldset": {
                borderColor: "#dedede",
            },
            "&.Mui-focused fieldset": {
                border: "0.5px solid #dedede",
            },
        },
    },
}))(TextField);
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "& .MuiTableCell-root": {
        padding: "6px 16px",
    },
    "&:nth-of-type(even)": {
        // backgroundColor: "#F5F6F8",
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.dark.main : "#F5F6F8",
    },
    "&:nth-of-type(odd)": {
        backgroundColor:
            theme.palette.mode === "dark"
                ? theme.palette.dark.main
                : theme.palette.white.main,
    },
    "&.MuiTableRow-hover": {
        "& .edit-btn": {
            display: "none",
        },
        "& svg": {
            opacity: 0,
        },
        "&:hover": {
            "&:nth-of-type(even)": {
                // backgroundColor: "#F5F6F8",
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? theme.palette.dark.main
                        : "#F5F6F8",
            },
            "&:nth-of-type(odd)": {
                // backgroundColor: "#fff",
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? theme.palette.dark.main
                        : theme.palette.white.main,
            },
            // backgroundColor: "#FBEDF2",
            "& .edit-btn": {
                display: "inline-flex",
                marginLeft: "30px",
                color: "#ea3592",
                "& .material-icons": {
                    fontSize: "20px",
                },
            },
            "& svg": {
                opacity: 1,
            },
        },
    },
}));

export const useStyles1 = makeStyles((theme) => ({
    tableRowCell: {
        fontFamily: "Energy !important",
        fontSize: "13px !important",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828 !important",
        "&.MuiTableCell-root": {
            padding: "6px 16px",
            borderBottom: "none",
        },
        // cursor: "pointer !important",
    },
    tableHeadCell: {
        fontFamily: "Energy !important",
        fontSize: "13px !important",
        fontWeight: "700 !important",
        color: "#ffffff !important",
        backgroundColor: " #282828 !important",
        "&.MuiTableCell-root": {
            padding: "6px 16px",
            borderBottom: "none",
        },
        // color: "#282828 !important",
        // backgroundColor: "#E6E9EE !important",
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#F5F6F8!important",
            // backgroundColor: "#FCFCFC !important",
            color: "#D8477E !important",
        },
    },
    pagination: {
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.dark.main : "#f6f6f6",
        // },
        "& .MuiTablePagination-selectLabel": {
            fontFamily: "Energy",
            // color: "#282828",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
        "& .MuiTablePagination-select": {
            fontFamily: "Energy",
            // color: "#282828",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
        "& .MuiTablePagination-displayedRows": {
            fontFamily: "Energy",
            // color: "#282828",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
    },
    search: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: "4px",
        marginLeft: "10px",
        "& .MuiSvgIcon-root": {
            fontSize: "18px",
        },
    },
    menu: {
        boxShadow: "none",
        "& .MuiMenuItem-root": {
            fontFamily: "Energy",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "23px",
            color: theme.palette.mode === "dark" ? "#fff" : "#282828",
            boxShadow: "none",
            padding: "2px 20px",
        },
        "& .MuiPaper-root": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : theme.palette.white.main,
        },
    },
    fixedWidth: {
        maxWidth: "100px",
    },
    rolePadding: {
        padding: "6px 12px",
        textTransform: "capitalize",
    },
    provisingAccess: {
        "& .MuiTableCell-root": {
            fontFamily: "Energy",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "23px",
            padding: "8px",
            color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        },
    },
    userId: {
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "700",
        // fontSize: "12px",
        lineHeight: "19px",
        // color: "#282828",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#282828",
    },
    menuStyle: {
        "& .MuiMenuItem-root": {
            backgroundColor: "#FBEDF2",
            color: theme.palette.dark.main,
            "&:hover": {
                backgroundColor: "#FBEDF2",
            },
        },
    },
    inputField: {
        flex: 1,
        "& .MuiInputBase-input": {
            width: "100% !important",
        },
    },
    ellipsis: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        padding: theme.spacing(1),
        backgroundColor: "#fff",
        width: "140px",
        height: "10px",
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "23px",
        borderRadius: "4px",
        color: "#282828",
    },
    "& .Mui-disabled": {
        cursor: "pointer",
    },
}));

export const paperStyle = {
    width: "100%",
    overflow: "hidden",
    borderRadius: "0px",
    boxShadow: "none",
};

export const menuStyle = {
    backgroundColor: "#FBEDF2",
    color: "#000000 !important",
    "&:hover": {
        backgroundColor: "#FBEDF2",
    },
    textTransform: "capitalize",
};
export const defaultStyle = {
    textTransform: "capitalize",
};
