import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";

import { StyledAutoComplete, CssTextField, useStyles } from "../../../style";
import "../../../index.scss";

const DropDownField = ({
  handleChange,
  data,
  value,
  width,
  theme = "light",
  widget_type,
  template_no,
  breadcrumbArr,
  renderSubReportArr,
  parent_report_type,
}) => {
  const { label, options = [], key, required } = data;
  const classes = useStyles();

  const getValtoShow = (x) => {
    const val = options.find((opt) => opt.key === x);
    if (val) {
      return val.value;
    } else return "report";
  };

  const getVal = useCallback(
    (newValue) => {
      const val = options.find(
        (opt) => opt.value.toLowerCase() === newValue.toLowerCase()
      );
      if (val) {
        return val.key;
      } else return null;
    },
    [key]
  );

  const getOptionsforSubcategoryType = () => {
    if (parent_report_type === "parentReport") {
      const CHarr = options.filter(
        (opt) => opt.key === "hybrid" || opt.key === "report"
      );
      const arr = CHarr.map((opt) => opt.value);
      return arr;
    }
    // remove Parent report and custom Hybrid
    const PR_CHremovedARR = options.filter(
      (opt) => opt.key !== "parentReport" && opt.key !== "customHybrid"
    );
    if (
      widget_type === "report" ||
      widget_type === "hybrid" ||
      widget_type === "image" ||
      widget_type === "textBox"
    ) {
      const newArr = PR_CHremovedARR.filter(
        (opt) => opt.key !== "header" && opt.key !== "sub_header"
      );
      const arr = newArr.map((opt) => opt.value);
      return arr;
    }
    // First page on temp 2,3,4
    else if (breadcrumbArr.length === 1 && template_no !== "Template_5") {
      const arr = PR_CHremovedARR.map((opt) => opt.value);
      return arr;
    } else {
      if (renderSubReportArr.length > 0) {
        const report_types = renderSubReportArr.map(
          (rep) => rep.sub_category_type
        );
        if (report_types.includes("customHybrid")) {
          const CHarr = options.filter(
            (opt) => opt.key === "customHybrid" || opt.key === "header"
          );
          const arr = CHarr.map((opt) => opt.value);
          return arr;
        } else if (
          report_types.includes("image") ||
          report_types.includes("textBox") ||
          report_types.includes("sub_header") ||
          report_types.includes("report") ||
          report_types.includes("hybrid")
        ) {
          const optionARR = options.filter(
            (opt) => opt.key !== "parentReport" && opt.key !== "customHybrid"
          );
          const arr = optionARR.map((opt) => opt.value);
          return arr;
        } else {
          const arr = options.map((opt) => opt.value);
          return arr;
        }
      } else {
        const arr = options.map((opt) => opt.value);
        return arr;
      }
      // header - all.
      // subheader - header , image , hybrid, report , textBox , sub-header
      // report - header , image , hybrid, report , textBox , sub-header
      // hybrid - header , image , hybrid, report , textBox , sub-header
      // custom-hybrid - only header & custom hybrid
    }
  };

  const getOptions = () => {
    let optionsArr;
    // If sub_category_type
    if (key === "sub_category_type") {
      optionsArr = getOptionsforSubcategoryType();
    }
    // if not sub_category_type
    else {
      optionsArr = options;
    }
    return optionsArr;
  };

  const isDropdownDisabled = () => {
    if (key === "sub_category_type") {
      if (
        widget_type !== "report" &&
        widget_type !== "hybrid" &&
        widget_type !== "textBox" &&
        widget_type !== "image" &&
        widget_type !== null
      ) {
        return true;
      }
    } else return false;
  };

  return (
    <Grid
      item
      xs={width || 3}
      key={key}
      className={isDropdownDisabled() ? classes.disabledDropdown : null}
    >
      <div className="heading">
        {label}{" "}
        <span
          style={{
            display: required || key === "bi_application" ? "" : "none",
          }}
        >
          *
        </span>
        :
      </div>
      <StyledAutoComplete
        className={classes.typographyStyle}
        disabled={isDropdownDisabled()}
        value={key === "sub_category_type" ? getValtoShow(value) : value || ""}
        options={getOptions()}
        getOptionLabel={(option) => option}
        onChange={(e, newValue) => {
          handleChange(
            key,
            key === "sub_category_type" ? getVal(newValue) : newValue
          );
        }}
        disableClearable
        renderInput={(params) => (
          <CssTextField
            {...params}
            className={classes.typographyStyle}
            fullWidth
            placeholder={`Choose ${label}`}
          />
        )}
        style={{
          height: "50px",
          width: "100%",
        }}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  template_no: state.appTemplate.appTemplateState.template_no,
});

export default connect(mapStateToProps)(DropDownField);
