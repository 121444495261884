import React from "react";
import { FormControlLabel, Grid, Checkbox } from "@mui/material";
import "../index.scss";

const CheckBoxComponent = ({
    data,
    width,
    value = false,
    handleChange,
    isFieldsEditable,
}) => {
    const { label, key } = data;
    return (
        <Grid item xs={width || 12} textAlign="start">
            <FormControlLabel
                sx={{
                    "& .MuiFormControlLabel-label": {
                        fontFamily: "Energy !important",
                        fontStyle: "normal !important",
                        fontWeight: "600 !important",
                        fontSize: "14px !important",
                        lineHeight: "23px !important",
                        // color: "#282828",
                        color: "#8d9ca6",
                    },
                }}
                control={
                    <Checkbox
                        checked={Boolean(value)}
                        sx={{
                            "&.Mui-checked": {
                                color: "#ea3592",
                            },
                        }}
                        disabled={isFieldsEditable}
                        onChange={(e) => handleChange(key, e.target.checked)}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                }
                label={label}
            />
        </Grid>
    );
};

export default CheckBoxComponent;
