import React from "react";
import Plotly from "plotly.js-dist";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);
const generateGradient = (color1, color2, steps) => {
  const result = [];
  const r1 = parseInt(color1.slice(1, 3), 16);
  const g1 = parseInt(color1.slice(3, 5), 16);
  const b1 = parseInt(color1.slice(5, 7), 16);

  const r2 = parseInt(color2.slice(1, 3), 16);
  const g2 = parseInt(color2.slice(3, 5), 16);
  const b2 = parseInt(color2.slice(5, 7), 16);

  for (let i = 0; i < steps; i++) {
    const r = Math.round(r1 + (r2 - r1) * (i / (steps - 1))).toString(16);
    const g = Math.round(g1 + (g2 - g1) * (i / (steps - 1))).toString(16);
    const b = Math.round(b1 + (b2 - b1) * (i / (steps - 1))).toString(16);
    result.push(`#${r.padStart(2, '0')}${g.padStart(2, '0')}${b.padStart(2, '0')}`);
  }

  return result;
};

const HorizontalBarChart = ({ keys, values ,theme}) => {
  const reversedKeys = [...keys].reverse();
  const reversedValues = [...values].reverse();
  const gradientColors = generateGradient("#fc007f", "#FF3B79", 10);
  
  const data = [
    {
      y: reversedKeys,
      x: reversedValues,
      type: "bar",
      orientation: "h", 
      text: reversedValues.map(value => ''),
      line: { color: theme === "dark" ? "#fff" : "#FC007F" },
      marker: {
        color: theme === "dark" ? "#fff" : "#FC007F",
        line: {
          width: 2.5,
          color: theme === "dark" ? "#fff" : "#FC007F",
        },
        size: 3,
      },
      // marker: {
      //   color: gradientColors,
      // },
    },
  ];
  const maxBarValue = Math.max(...data[0].x);
  const layout = {
    autosize: true,
    height: 300,
    xaxis: {
      showticklabels: false,
      showgrid: false,
      zeroline: false,
    },
    plot_bgcolor: theme === "dark" ? "#282828" : "#fff",
    paper_bgcolor: theme === "dark" ? "#282828" : "#fff",
    font: 11,
    color: "#282828",
    family: "Energy",
    yaxis: {
      automargin: true,
      ticklen: 30,
      showgrid: false,
      tickcolor: 'rgba(255, 255, 255, 0)',
      tickfont: {
        family: "Energy",
        size: 12,
        color: theme === "dark" ? "#DDDDDD" : "#282828",
        fontWeight: 500,
      },
    },
    margin: {
      t: 20,
      b: 20,
    },
    bargap: 0.1, 
    bargroupgap: 0.001,
    // annotations: data[0].y.map((value, index) => ({
    //   x: maxBarValue * 1.2, 
    //   y: value,
    //   text: data[0].text[index],
    //   showarrow: false,
    //   xanchor: "left",
    //   xshift: 10, 
    // })),

    annotations: reversedValues.map((value, index) => ({
      x: maxBarValue * 1.2,
      y: reversedKeys[index],
      text: value, 
      showarrow: false,
      xanchor: "left",
      xshift: 10,
      font: {
        color: theme === "dark" ? "#DDDDDD" : "#282828",
        family: "Energy",
        size: 12,
        fontWeight: 500,
      },
    })),
  };

  const config = {
    responsive: true,
    displayModeBar: false,
  };
  return (
    <div style={{ width: "100%", maxWidth: "100%", overflowX: "auto" }}>
      <Plot
        data={data}
        layout={layout}
        config={config}
        useResizeHandler={true}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default HorizontalBarChart;
