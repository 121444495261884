// Import Required Libraries
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

// Import Functions
import { fetchAPI } from "../HelpCenter";

// Import Custom components
import TicketCard from "./TicketCard";
import { SkeletonForHelpCenterPage } from "../../../components/ImageLoader/SkeletonLoadScreen";

// Import images/assets
import error_smw from "../../../../assets/images/error_smw.svg";

const RequestedIssues = (props) => {
  const [ticketDataList, setTicketsDataList] = useState([]);
  const [loadTicketList, setLoadTicketList] = useState("pending");

  // Function to Fetch all the requested Issues
  const handleFetchReqTickets = async () => {
    setLoadTicketList("loading");
    var url = "/api/jira/search";
    var payload = {
      jql: `reporter="${
        window.location?.origin?.includes("dev") ||
        window.location?.origin?.includes("localhost")
          ? "bhavya.verma@reckitt.com"
          : window.location?.origin?.includes("uat")
          ? // ? "harshit.singh@reckitt.com"
            "Debamitra.Mukherjee@reckitt.com"
          : "Shubh.Ambaly@reckitt.com"
      }"`,
      maxResults: 1000,
    };
    const request_meta = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetchAPI(url, request_meta);
      const response = await res.json();
      setLoadTicketList("success");
      setTicketsDataList(response.issues);
    } catch (error) {
      setLoadTicketList("error");
      toast.error("Couldn't Fetch Tickets");
    }
  };

  useEffect(() => {
    handleFetchReqTickets();
  }, []);

  return (
    <>
      {loadTicketList === "loading" && (
        <SkeletonForHelpCenterPage showNavTab={false} />
      )}
      {loadTicketList === "error" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img style={{ marginTop: "150px" }} src={error_smw} alt="" />
        </div>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat( auto-fill, minmax(300px, 0.1fr) )",
          gridGap: "15px",
        }}
      >
        {loadTicketList === "success" &&
          ticketDataList.length > 0 &&
          ticketDataList.map((ticket) => <TicketCard ticket_data={ticket} />)}
      </div>
    </>
  );
};

RequestedIssues.propTypes = {};

export default RequestedIssues;
