import React from "react";
import AutoWithCheckbox from "../AutoCompletesComponents/AutoWithCheckbox";
//styles
import "../../index.scss";
import CustomAutoComponent from "../../../../components/AutoCompletes/CustomAutoComponents";
import { update_appData } from "../../../../redux/actions";
import { connect, useDispatch } from "react-redux";
const customStyling = {
  // "& .MuiTextField-root": { boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)" },
};

const SelectForCheckbox = (props) => {
  const {
    fieldkey,
    dataList,
    appFields,
    isFieldsEditable,
    isChanged,
    appInfo,
    isAdminRegistering,
    hasError,
    missingValues,
    setMissingValues,
    label,
    draft_app,
  } = props;
  const dispatch = useDispatch();
  const getOptions = () => dataList[fieldkey] || [];
  const getChips = () =>
    (Array.isArray(appFields.app_details[fieldkey]) &&
      appFields.app_details[fieldkey]) ||
    [];

  const isInputAlreadyExist = (value) => {
    if (!value) return;
    if (fieldkey === "tags") {
      return (appFields.app_details[fieldkey] || []).some(
        (item) => item === value
      );
    } else {
      return (appFields.app_details[fieldkey] || []).some(
        (item) => item.toLowerCase() === value?.toLowerCase()
      );
    }
  };
  const isInputExistInOptions = (value) => {
    const res2 = (dataList[fieldkey] || []).some(
      (item) => item.toLowerCase() === value?.toLowerCase()
    );
    return res2;
  };

  const updateTrackingState = (changedData) => {
    if (appInfo || isAdminRegistering || draft_app) {
      //   setIsChanged({
      //     ...isChanged,
      //     app_details: {
      //       ...isChanged.app_details,
      //       [fieldkey]: changedData,
      //     },
      //   });
      dispatch(
        update_appData({
          isChanged: {
            ...isChanged,
            app_details: {
              ...isChanged.app_details,
              [fieldkey]: changedData,
            },
          },
        })
      );
    }
  };

  const setFieldsValues = (data) => {
    // setAppFields({
    //   ...appFields,
    //   app_details: { ...appFields.app_details, [fieldkey]: data },
    // });
    dispatch(
      update_appData({
        appFields: {
          ...appFields,
          app_details: { ...appFields.app_details, [fieldkey]: data },
        },
      })
    );
  };
  const dataListValues = (reqData, newList) => {
    // setDataList({
    //   ...dataList,
    //   [fieldkey]: [
    //     ...(reqData || []).sort((a, b) =>
    //       a.localeCompare(b, undefined, { sensitivity: "base" })
    //     ),
    //     ...(newList || []).sort((a, b) =>
    //       a.localeCompare(b, undefined, { sensitivity: "base" })
    //     ),
    //   ],
    // });
    dispatch(
      update_appData({
        dataList: {
          ...dataList,
          [fieldkey]: [
            ...(reqData || []).sort((a, b) =>
              a.localeCompare(b, undefined, { sensitivity: "base" })
            ),
            ...(newList || []).sort((a, b) =>
              a.localeCompare(b, undefined, { sensitivity: "base" })
            ),
          ],
        },
      })
    );
  };
  const updateDataList = (filteredData) => {
    const reqList = dataList[fieldkey].filter(
      (item) => !filteredData.includes(item)
    );
    dataListValues(filteredData, reqList);
  };

  const handleSelection = (k, reqData, sortedData) => {
    setFieldsValues(reqData);
    updateTrackingState(reqData);
    // setDataList({
    //   ...dataList,
    //   [fieldkey]: sortedData,
    // });
    dispatch(
      update_appData({
        dataList: {
          ...dataList,
          [fieldkey]: sortedData,
        },
      })
    );
  };

  const addValue = (val) => {
    const reqData = [...appFields.app_details[fieldkey], val];
    updateDataList(reqData);
    setFieldsValues(reqData);
    updateTrackingState(reqData);
  };

  const removeValue = (val) => {
    const reqData = appFields.app_details[fieldkey].filter(
      (item) => item !== val
    );
    updateDataList(reqData);
    setFieldsValues(reqData);
    updateTrackingState(reqData);
  };

  const updateGeography = (key, val, specialVal) => {
    if (val === specialVal) {
      // checking if fields already exist else replace it with every other values.
      if (appFields.app_details[key]?.includes(specialVal)) {
        setFieldsValues([]);
        updateTrackingState([]);
      } else {
        const newList = dataList[fieldkey].filter(
          (item) => item !== specialVal
        );
        setFieldsValues([specialVal]);
        dataListValues([specialVal], newList);
        updateTrackingState([specialVal]);
      }
    } else {
      if (appFields.app_details[key]?.includes(specialVal)) {
        const newList = dataList[fieldkey].filter((item) => item !== val);
        dataListValues([val], newList);
        setFieldsValues([val]);
        updateTrackingState([val]);
      } else {
        let reqData = [];
        if (appFields.app_details[key]?.includes(val)) {
          reqData = appFields.app_details[key].filter((item) => item !== val);
        } else {
          reqData = [...(appFields.app_details[key] || []), val];
        }
        updateDataList(reqData);
        setFieldsValues(reqData);
        updateTrackingState(reqData);
      }
    }
  };

  const updateAssociatedDataSets = (val) => {
    let reqData = [];
    const FieldIndex = (appFields.app_details[fieldkey] || []).findIndex(
      (item) => item.toLowerCase() === val.toLowerCase()
    );
    // removing if already selected
    if (FieldIndex >= 0) {
      reqData = (appFields.app_details[fieldkey] || []).filter(
        (item) => item.toLowerCase() !== val.toLowerCase()
      );
    } else {
      // adding as it is not selected
      const dataListIndex = (dataList[fieldkey] || []).findIndex(
        (item) => item.toLowerCase() === val.toLowerCase()
      );
      // checking if already exist in options or new value is type which is not present in optionlist.
      if (dataListIndex >= 0) {
        reqData = [
          ...appFields.app_details[fieldkey],
          dataList[fieldkey][dataListIndex],
        ];
      } else {
        //adding new type value in option list and seleced values
        reqData = [...appFields.app_details[fieldkey], val];
      }
    }
    updateDataList(reqData);
    setFieldsValues(reqData);
    updateTrackingState(reqData);
  };

  const selectChange = (val) => {
    if (!val) return;
    if (missingValues[fieldkey]) {
      setMissingValues({ ...missingValues, [fieldkey]: false });
    }
    if (fieldkey === "associated_datasets") {
      updateAssociatedDataSets(val);
    } else if (
      fieldkey === "geography" ||
      fieldkey === "line_of_business" ||
      fieldkey === "function"
    ) {
      if (fieldkey === "line_of_business") {
        updateGeography(fieldkey, val, "Global");
      } else {
        updateGeography(fieldkey, val, "All");
      }
    } else {
      if ((appFields.app_details[fieldkey] || []).includes(val)) {
        removeValue(val);
      } else {
        addValue(val);
      }
    }
  };

  return (
    <>
      {fieldkey === "geography" ? (
        <CustomAutoComponent
          data={{ key: fieldkey, label, options: [] }}
          selectedData={getChips()}
          handleSelection={handleSelection}
          filteredOption={getOptions()}
          width="94%"
          customStyling={customStyling}
          isFieldsEditable={isFieldsEditable}
        />
      ) : (
        <AutoWithCheckbox
          fieldkey={fieldkey}
          isFieldsEditable={isFieldsEditable}
          isInputAlreadyExist={isInputAlreadyExist}
          isInputExistInOptions={isInputExistInOptions}
          selectChange={selectChange}
          selectedOptions={getChips()}
          options={getOptions()}
          label={label}
          hasError={hasError}
        />
      )}
      {hasError && <div className="error error-dropdown">This field is required*</div>}
    </>
  );
};

const mapStateToProps = (state) => ({
  // statusData
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  // appData
  isChanged: state.registerNewApp.appData.isChanged,
  appFields: state.registerNewApp.appData.appFields,
  dataList: state.registerNewApp.appData.dataList,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(SelectForCheckbox);
