import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useStylesforElementSelector } from "../Components/AddNewElementPopUp/Styles";
import { Drawer } from "@mui/material";
import FooterContainer from "../Components/FooterContainer";
// Import Images
import fulleditor_dark from "../../../../assets/images/new-theme/register/light-theme/fulleditor_dark.svg";
import predefined_dark from "../../../../assets/images/new-theme/register/light-theme/predefined_dark.svg";
import fulleditor from "../../../../assets/images/new-theme/register/dark-theme/fulleditor.svg";
import predefined from "../../../../assets/images/new-theme/register/dark-theme/predefined.svg";
import { useStylesforImageSelector } from "../Components/ImageSelector/Styles";
import TemplateSelector from "../Components/TemplateSelector/TemplateSelector";
import { getDefaultTempData } from "../Components/DashboardTemplate/utils";
import {
  updateReportTemplate,
  update_BIappDetails,
  update_activeStepper,
  update_statusData,
  update_appData,
} from "../../../redux/actions";
import DetailsDrawer from "../Components/DetailsDrawer/DetailsDrawer.js";
import WorkspaceDrawer from "../Components/DashboardTemplate/Components/WorkspaceDrawer";
// import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";

const CreateLayout = (props) => {
  const {
    user,
    setSelectedTemplate,
    appFields,
    register_template,
    // setActiveStepper,
    activeStepper,
    visited,
    draft_app,
    appTemplateState,
  } = props;
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [openWorkspaceDrawer, setOpenWorkspaceDrawer] = useState(false);
  const dispatch = useDispatch();
  const handleSelectElement = (el) => {
    setSelectedLayout(el);
  };

  const popup_classes = useStylesforElementSelector(user.ui_preferences.theme);
  const classes = useStylesforImageSelector();

  const { fields } = register_template;

  const handleClose = () => {
    setSelectedLayout(null);
  };

  const handleSave = (template) => {
    setSelectedTemplate(template);
    const defaultValues = getDefaultTempData(
      template,
      appFields,
      user.preferred_username
    );
    if (template === "Template_1" || template === 1) {
      dispatch(
        updateReportTemplate({
          template_no: template,
          templateData: defaultValues,
          temp_img_data: {},
        })
      );
      dispatch(
        update_BIappDetails({
          categoryInfo: defaultValues,
          categoryInfoLocal: defaultValues,
        })
      );
      // setCategoryInfo(defaultValues);
      // setCategoryInfoLocal(defaultValues);

      // [?] Marked for review (setCategoryInfo and setCategoryInfoLocal was commented)
    } else {
      dispatch(
        updateReportTemplate({
          template_no: template,
          templateData: defaultValues,
          temp_img_data: {},
        })
      );

      dispatch(
        update_appData({
          savedTemplateData: {
            template_no: template,
            templateData: defaultValues,
            temp_img_data: {},
          },
        })
      );
      dispatch(update_statusData({ localstateSetFirstTime: true }));
      // setCategoryInfo([]);
      // setCategoryInfoLocal([]);

      // [?] Marked for review (setCategoryInfo and setCategoryInfoLocal was commented)
      if (draft_app && template > 5 && template < 10) {
        dispatch(
          update_BIappDetails({
            // categoryInfo: [...defaultValues],
            categoryInfo: [],
            categoryInfoLocal: [...defaultValues],
          })
        );
      } else {
        dispatch(
          update_BIappDetails({
            categoryInfo: [],
            categoryInfoLocal: [],
          })
        );
      }
    }
  };

  // ================== NEW ==================
  const handleChangeTemplate5 = () => {
    setSelectedTemplate(5);
    dispatch(
      updateReportTemplate({
        template_no: 5,
        templateData: [],
        temp_img_data: {},
      })
    );
    dispatch(
      update_appData({
        savedTemplateData: {
          template_no: 5,
          templateData: [],
          temp_img_data: {},
        },
      })
    );

    dispatch(update_statusData({ editLayout: true }));
  };

  // const handleCreateCustom = () => {
  //   handleChangeTemplate("Template_5");
  //   handleClose();
  // };
  // ================== NEW ==================

  const handleBack = () => {
    // eventTracker("Back btn clicked", "clicked");
    // setActiveStepper((prev) => prev - 1);
    dispatch(update_activeStepper(activeStepper - 1));
  };

  const handleOpenImportWorkspace = () => {
    setOpenWorkspaceDrawer(true);
  };

  const handleCloseImportWorkspace = () => {
    setOpenWorkspaceDrawer(false);
  };

  return (
    <>
      {selectedLayout === "predefined" && (
        <TemplateSelector
          openTemplateSelector={selectedLayout === "predefined"}
          handleSave={handleSave}
          handleClose={handleClose}
        />
      )}
      {openWorkspaceDrawer && (
        <WorkspaceDrawer
          open={openWorkspaceDrawer}
          handleClose={handleCloseImportWorkspace}
          appFields={appFields}
        />
      )}
      <div>
        <button
          onClick={handleOpenImportWorkspace}
          className={classes.SecondaryButtonStyles}
        >
          Import Workspace
        </button>
        <div className={popup_classes.elementsContainer}>
          {/* Full Editor Mode */}
          <div
            className={popup_classes.mainContainer}
            onClick={() => {
              handleSelectElement("fulleditor");
              handleChangeTemplate5();
            }}
          >
            <img
              src={
                user.ui_preferences.theme === "light"
                  ? fulleditor_dark
                  : fulleditor
              }
              className={popup_classes.elementImage}
              alt="Full Editor"
            />
            <p className={popup_classes.elementText}>Full editor mode</p>
            <p className={popup_classes.elementDescription}>
              Start designing from scratch: recommended for users with some
              experience or the ones who need to create basic page containig few
              elements.
            </p>
          </div>
          {/* Pre Defined Template */}
          <div
            className={popup_classes.mainContainer}
            onClick={() => handleSelectElement("predefined")}
          >
            <img
              src={
                user.ui_preferences.theme === "light"
                  ? predefined_dark
                  : predefined
              }
              className={popup_classes.elementImage}
              alt="Predefined layout"
            />
            <p className={popup_classes.elementText}>
              Start with predefined template
            </p>
            <p className={popup_classes.elementDescription}>
              Different predefined layouts adjusted to various complex business
              needs to choose from.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          top: "82%",
          position: "sticky",
        }}
      >
        <FooterContainer>
          <>
            <button
              onClick={handleBack}
              className={`btn-back btn-back-${user.ui_preferences.theme}`}
            >
              Back
            </button>
          </>
        </FooterContainer>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeStepper: state.registerNewApp.activeStepper,
  user: state.user,
  appFields: state.registerNewApp.appData.appFields,
  visited: state.registerNewApp.appData.visited,
  appTemplateState: state.appTemplate.appTemplateState.templateData,
});

export default connect(mapStateToProps)(CreateLayout);
