import React, { useState, useEffect, useRef } from "react";

// Import mui components
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import moment from "moment";
import {
  updateAppDashboardState,
  updated_total_views,
  get_top_users_app,
  getDefinedCategory,
  update_statusData,
} from "../../redux/actions";

// Import config
import { config } from "../../config/config";

//Import Hooks
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

// Import Styles
import { useStylesforSF } from "../AppInfoCard/style";

// Import Assets/Images
import sharepointImg from "../../../assets/images/sharepoint.png";
import powerBIImg from "../../../assets/images/powerbi.svg";
import googleStudioImg from "../../../assets/images/googleStudio.png";
import SigmoidLogo from "../../../assets/images/SigmoidLogo.png";
import powerAppImg from "../../../assets/images/powerapp.svg";
import { Skeleton } from "@mui/material";

// Import Components
import GetTopUserSlider from "./components/GetTopUserSlider";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "11px",
    // fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

// Main component
const AppInfoCard = ({
  open,
  handleClose,
  appDetails,
  user,
  setOpenRA,
  reqState,
  setReqState,
  AppsData,
}) => {
  // runs when app details is clicked
  const gaEventTracker = useAnalyticsEventTracker("AppDetails");
  const classes = useStylesforSF();
  //   const classes1 = useStyles();
  const { topUsersApp = {}, defined_categories } = AppsData;
  const [seeQuickLinks, setSeeQuickLinks] = useState(false);

  const [platform, setPlatform] = useState({
    power_bi: false,
    google_studio: false,
    sharepoint: false,
    power_app: false,
  });

  const appId = appDetails?.app_id;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAnchor = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const detailRef = useRef();
  const topUserRef = useRef();

  const history = useHistory();
  const dispatch = useDispatch();

  function hasEditAccess(person) {
    return (
      user.preferred_username === person.user_id && person.role === "owner"
    );
  }
  function isAppOwner(person) {
    return person.role.toLowerCase().includes("owner");
  }
  function hasViewAccess(person) {
    return user.preferred_username === person.user_id;
  }
  function getSubcategories(category) {
    if (category?.CatHyperlinks && category?.CatHyperlinks?.isHyperlinkActive) {
      if (
        category?.CatHyperlinks?.bi_application === "Power BI" &&
        !platform.power_bi
      ) {
        setPlatform({ ...platform, power_bi: true });
      } else if (
        category?.CatHyperlinks?.bi_application === "Google DataStudio" &&
        !platform.google_studio
      ) {
        setPlatform({ ...platform, google_studio: true });
      } else if (
        category?.CatHyperlinks?.bi_application === "Sharepoint" &&
        !platform.sharepoint
      ) {
        setPlatform({ ...platform, sharepoint: true });
      } else if (
        category?.CatHyperlinks?.bi_application === "Power Apps" &&
        !platform.power_app
      ) {
        setPlatform({ ...platform, power_app: true });
      }
      const CatReport = {
        app_logo_type: "",
        bi_application: category?.CatHyperlinks?.bi_application,
        blob_name: "",
        sub_category_icon: "",
        sub_category_id: category.sub_category_id,
        sub_category_name: category.sub_category_name,
        url: category?.CatHyperlinks?.url,
        view_count: category?.CatHyperlinks?.view_count,
      };
      subCategoryArr.push(CatReport);
    }

    const arr = category.sub_category.map((subcategory) => {
      if (
        subcategory?.SubCatHyperlinks &&
        subcategory?.SubCatHyperlinks?.isHyperlinkActive
      ) {
        if (
          subcategory?.SubCatHyperlinks?.bi_application === "Power BI" &&
          !platform.power_bi
        ) {
          setPlatform({ ...platform, power_bi: true });
        } else if (
          subcategory?.SubCatHyperlinks?.bi_application ===
            "Google DataStudio" &&
          !platform.google_studio
        ) {
          setPlatform({ ...platform, google_studio: true });
        } else if (
          subcategory?.SubCatHyperlinks?.bi_application === "Sharepoint" &&
          !platform.sharepoint
        ) {
          setPlatform({ ...platform, sharepoint: true });
        } else if (
          subcategory?.SubCatHyperlinks?.bi_application === "Power Apps" &&
          !platform.power_app
        ) {
          setPlatform({ ...platform, power_app: true });
        }
        const SubCatReport = {
          app_logo_type: "",
          bi_application: subcategory?.SubCatHyperlinks?.bi_application,
          blob_name: "",
          sub_category_icon: "",
          sub_category_id: subcategory.sub_category_id,
          sub_category_name: subcategory.sub_category_name,
          url: subcategory?.SubCatHyperlinks?.url,
          view_count: subcategory?.SubCatHyperlinks?.view_count,
        };
        subCategoryArr.push(SubCatReport);
      }

      if (subcategory.hasOwnProperty("identifier")) {
      } else if (subcategory.hasOwnProperty("url")) {
        //set type of platforms
        if (subcategory.bi_application === "Power BI" && !platform.power_bi) {
          setPlatform({ ...platform, power_bi: true });
        } else if (
          subcategory.bi_application === "Google DataStudio" &&
          !platform.google_studio
        ) {
          setPlatform({ ...platform, google_studio: true });
        } else if (
          subcategory.bi_application === "Sharepoint" &&
          !platform.sharepoint
        ) {
          setPlatform({ ...platform, sharepoint: true });
        } else if (
          subcategory.bi_application === "Power Apps" &&
          !platform.power_app
        ) {
          setPlatform({ ...platform, power_app: true });
        }
        //set subcategory array
        subCategoryArr.push(subcategory);
      } else {
        subcategory.sub_category.forEach((rep) => {
          if (rep.bi_application === "Power BI" && !platform.power_bi) {
            setPlatform({ ...platform, power_bi: true });
          } else if (
            rep.bi_application === "Google DataStudio" &&
            !platform.google_studio
          ) {
            setPlatform({ ...platform, google_studio: true });
          } else if (
            rep.bi_application === "Sharepoint" &&
            !platform.sharepoint
          ) {
            setPlatform({ ...platform, sharepoint: true });
          } else if (
            rep.bi_application === "Power Apps" &&
            !platform.power_app
          ) {
            setPlatform({ ...platform, power_app: true });
          }
          //set subcategory array
          subCategoryArr.push(rep);
        });
      }
    });
    // IMP --> to sort the subcategories on subcount
    subCategoryArr.sort((a, b) => b.view_count - a.view_count);

    return arr;
  }

  // setSeeQuickLinks(false);
  const appOwnerArr = appDetails.access_details
    ? appDetails.access_details?.users_list?.filter(isAppOwner)
    : [];

  const isUserOwner = appOwnerArr.findIndex(
    (appOwner) => appOwner?.user_id === user.preferred_username
  );

  // const favStatus = appDetails.favorited_by?.includes(user.preferred_username);
  const accessableUser = appDetails.access_details
    ? appDetails.access_details.users_list.filter(hasViewAccess)
    : [];
  const accessStatus =
    accessableUser.length || user.role === "super_admin" ? true : false;
  const editableUser = appDetails.access_details
    ? appDetails.access_details.users_list.filter(hasEditAccess)
    : [];
  const editStatus =
    editableUser.length || user.role === "super_admin" ? true : false;
  const subCategoryArr = [];

  const findSubcategories = () => {
    if (
      (appDetails.app_meta.app_type === "bi_app") &
      (defined_categories[appDetails.app_id]?.status === "success")
    ) {
      (
        defined_categories[appDetails.app_id].data.app_details
          ?.defined_categories || []
      ).forEach((category) => {
        getSubcategories(category);
      });
    }
  };

  findSubcategories();

  const handleNavigate = (path) => {
    dispatch(
      updateAppDashboardState({
        search_columns: [],
        filteredReportsList: [],
        loadingFilter: "pending",
        searchVal: "",
        filterOptions: config.initailOptions,
        filterList: config.initailFilters,
        appData: {},
        appDataStatus: "pending",
      })
    );
    history.push(path);
  };

  const handleOpenInNewTab = (row) => {
    dispatch(
      updated_total_views({
        app_id: row.app_id,
      })
    );
    window.open(row.app_meta.app_url, row.app_meta.app_window_Name);
  };

  const HandleOpenApp = (appDetails) => {
    gaEventTracker("OpenApplication", `app_id = ${appDetails.app_id}`);
    if (
      appDetails.app_meta.app_type === "ml_app" ||
      appDetails.app_meta.app_type === "standalone_app"
    ) {
      handleOpenInNewTab(appDetails);
    } else if (appDetails.app_meta.app_type === "bi_app") {
      handleNavigate(appDetails.app_meta.app_path);
    }
  };

  const HandleRequestAccess = (appDetails) => {
    gaEventTracker("RequestAccess", `app_id = ${appDetails.app_id}`);
    setOpenRA(true);
  };

  const toolTipForOwners = () => {
    let toolTipforAppOwner = "";
    const tooltiparr = appOwnerArr.map((person) => {
      toolTipforAppOwner.concat(
        "(" + person?.name?.replace("(Contractor)", "") + ") "
      );
    });
    return toolTipforAppOwner;
  };

  const isTemplateValid = (details) => {
    if (details.app_details.chosen_template === 1) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const { chosen_template } = appDetails.app_details || {};
    if (
      appDetails.app_meta?.app_type === "bi_app" &&
      (!chosen_template || chosen_template === 1) &&
      (!defined_categories[appDetails.app_id] ||
        defined_categories[appDetails.app_id]?.status === "pending")
    ) {
      const payload = {
        app_id: appDetails.app_id,
        approval_status: appDetails.approval_meta.approval_status,
        type: "app_id",
      };
      dispatch(getDefinedCategory(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDetails, dispatch]);

  useEffect(() => {
    if (!appId) return;

    if (
      !topUsersApp[appId] ||
      (topUsersApp[appId] && topUsersApp[appId]?.status === "error")
    ) {

      const currentDate = new Date();
      const oneDayAgo = new Date(currentDate);
      oneDayAgo.setDate(currentDate.getDate() - 1);
      oneDayAgo.setHours(23, 59, 59, 999);

      const thirtyDaysAgo = new Date(currentDate);
      thirtyDaysAgo.setDate(currentDate.getDate() - 30);
      thirtyDaysAgo.setHours(0, 0, 0, 0);

      const data = {
        search_columns: [
          {
            name: "app_id",
            type: "in",
            values: [appId],
          },
          {
            name: "accessed_by",
            type: "in",
            values: ["All"],
          },
          {
            name: "sub_category_id",
            type: "in",
            values: [null],
          },
          {
            name: "accessed_on",
            type: "greaterthan",
            values: thirtyDaysAgo.getTime(),
          },
          {
            name: "accessed_on",
            type: "lessthan",
            values: oneDayAgo.getTime(),
          },
        ],
        monthly: false,
      };
      dispatch(get_top_users_app(data, appId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        minWidth: "700px !important",
        maxWidth: "800px !important",
      }}
      className={classes.alertContainer}
    >
      <div
        id="detail-popup"
        ref={detailRef}
        className={classes.dailogContainer}
      >
        <div>
          <Box className={classes.flexSB} m="0px 25px" p="10px 0px">
            <div className={classes.headerLeftContainer}>
              {appDetails.app_meta?.app_logo_type === "google_font" ? (
                <i
                  className="material-icons-outlined"
                  style={{
                    borderRadius: "32px",
                    padding: "5px",
                    border: "1px solid #0000002e",
                    fontSize: "22px",
                    marginRight: "16px",
                    cursor: "default",
                    color: `${
                      user.ui_preferences.theme === "light" ? "#FC007F" : "#fff"
                    }`,
                  }}
                >
                  {appDetails.app_meta?.app_logo}
                </i>
              ) : (
                <img
                  src={appDetails.app_meta?.app_logo}
                  style={{
                    color: `${
                      user.ui_preferences.theme === "light" ? "#FC007F" : "#fff"
                    }`,
                    borderRadius: "32px",
                    padding: "5px",
                    border: "1px solid #0000002e",
                    width: "22px",
                    marginRight: "16px",
                  }}
                  alt="icon"
                />
              )}
              <div>
                <h4 className={classes.headingAppName}>
                  {appDetails.app_meta.app_name}
                </h4>
              </div>
              {/* {favStatus && (
                <span
                  class="material-icons-round"
                  style={{
                    fontSize: "28px",
                    marginLeft: "5px",
                    color: "#FFC700",
                    cursor: "default",
                  }}
                >
                  star
                </span>
              )} */}
              {editStatus && (
                <div className={classes.displayFlex}>
                  <div title="Total views" className={classes.displayFlex}>
                    <span
                      class="material-icons-outlined"
                      style={{
                        fontSize: "22px",
                        margin: "0px 7px",
                        color: `${
                          user.ui_preferences.theme === "light"
                            ? "#FC007F"
                            : "#fff"
                        }`,
                      }}
                    >
                      visibility
                    </span>
                    <p style={{ margin: "0px", fontSize: "12px" }}>
                      {appDetails.app_meta.total_views}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      gaEventTracker(
                        "UsageReport",
                        `app_id = ${appDetails.app_id}`
                      );
                      history.push({
                        pathname:
                          "/register" +
                          appDetails.app_meta.app_path +
                          "/usage-report",
                        tab: appDetails.app_meta.app_type === "ml_app" ? 6 : 5,
                      });
                    }}
                    id={appDetails.app_id}
                    className={`${classes.displayFlex} GA-usage-report`}
                  >
                    <span
                      class="material-icons-outlined GA-usage-report"
                      id={appDetails.app_id}
                      style={{
                        fontSize: "22px",
                        marginLeft: "15px",
                        color: `${
                          user.ui_preferences.theme === "light"
                            ? "#FC007F"
                            : "#fff"
                        }`,
                      }}
                    >
                      insights
                    </span>
                    <p
                      style={{ fontSize: "12px" }}
                      className={`${classes.subcatLinks} GA-usage-report`}
                      id={appDetails.app_id}
                    >
                      Usage reports
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className={classes.headerLeftContainer}>
              {editStatus && (
                <span
                  // title="Edit app"
                  class="material-icons-round GA-edit-app"
                  id={appDetails.app_id}
                  style={{
                    color: `${
                      user.ui_preferences.theme === "light" ? "#FC007F" : "#fff"
                    }`,
                    fontSize: "22px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    gaEventTracker("EditApp", `app_id = ${appDetails.app_id}`);
                    dispatch(update_statusData({ navToExplore: true }));
                    history.push(
                      "/register" + appDetails.app_meta.app_path + "/details"
                    );
                  }}
                >
                  app_registration
                </span>
              )}
              {appDetails?.access_details?.access_type === "private" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  <div className={classes.displayFlex}>
                    <span
                      class="material-icons-outlined"
                      style={{
                        fontSize: "20px",
                        marginRight: "7px",
                        color: "green",
                        cursor: "default",
                      }}
                    >
                      lock
                    </span>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "green",
                        fontWeight: "400",
                      }}
                    >
                      Users-Only Access
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "10px",
                      marginRight: "7px",
                      fontWeight: "400",
                    }}
                  >
                    {appDetails.access_details.users_list.length + " Users"}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    class="material-icons-outlined"
                    style={{
                      fontSize: "20px",
                      marginRight: "7px",
                      color: "green",
                    }}
                  >
                    public
                  </span>
                  <div
                    style={{
                      fontSize: "12px",
                      marginRight: "5px",
                      color: "green",
                      fontWeight: "400",
                    }}
                  >
                    Public Application
                  </div>
                </div>
              )}
            </div>
          </Box>
          <DialogContent style={{ maxHeight: "300px", minHeight: "150px" }}>
            <div className={classes.topContentContainer}>
              <div
                className={classes.decriptionContainer}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "25px",
                }}
              >
                {/* <p className={classes.infoHeading}>Description :</p> */}
                <div>
                  <p className={classes.infoPara}>
                    {appDetails.app_details.description}
                  </p>
                </div>
                <div>
                  {(isUserOwner >= 0 ||
                    ["super_admin", "admin"].includes(user.role)) &&
                    AppsData?.topUsersApp && (
                      <Tooltip title="Top Users">
                        <>
                          <p
                            id="topuser-btn"
                            ref={topUserRef}
                            onClick={handleClick}
                            className={`${classes.infoPara} GA-top-users`}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Top Users
                            <span
                              class={`material-symbols-outlined ${classes.topuserIcon}`}
                            >
                              chevron_right
                            </span>{" "}
                          </p>
                          <GetTopUserSlider
                            anchorEl={anchorEl}
                            openAnchor={openAnchor}
                            handleCloseAnchor={handleCloseAnchor}
                            appId={appId}
                            classes={classes}
                            detailRef={detailRef}
                            topUserRef={topUserRef}
                          />
                        </>
                      </Tooltip>
                    )}
                </div>
              </div>
              {appDetails.app_meta.app_type === "bi_app" &&
                isTemplateValid(appDetails) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                    }}
                  >
                    <span
                      class="material-icons-round"
                      style={{
                        color: `${
                          user.ui_preferences.theme === "light"
                            ? "#FC007F"
                            : "#fff"
                        }`,
                        marginRight: "7px",
                        transform: "rotate(-45deg)",
                        fontSize: "22px",
                      }}
                    >
                      link
                    </span>
                    <p className={classes.infoHeading}>Quick Links :</p>
                    <>
                      {defined_categories[appDetails.app_id]?.status ===
                      "loading" ? (
                        <Skeleton width={120} />
                      ) : (
                        <>
                          <div
                            style={{
                              width: "500px",
                            }}
                            className={classes.displayContainer}
                          >
                            {subCategoryArr.length <= 5 || seeQuickLinks
                              ? subCategoryArr.map((subcat) => {
                                  return (
                                    <p
                                      onClick={() => {
                                        gaEventTracker(
                                          "QuickLinks",
                                          `app_id = ${appDetails.app_id}`
                                        );
                                        history.push({
                                          pathname:
                                            appDetails.app_meta.app_path,
                                          subcat,
                                        });
                                      }}
                                      className={classes.subcatLinks}
                                    >
                                      <u>{subcat.sub_category_name}</u>
                                    </p>
                                  );
                                })
                              : subCategoryArr.slice(0, 5).map((subcat) => {
                                  return (
                                    <p
                                      onClick={() => {
                                        history.push({
                                          pathname:
                                            appDetails.app_meta.app_path,
                                          subcat,
                                        });
                                      }}
                                      className={classes.subcatLinks}
                                    >
                                      <u>{subcat.sub_category_name}</u>
                                    </p>
                                  );
                                })}
                          </div>
                        </>
                      )}
                      {subCategoryArr.length > 5 && (
                        <span
                          onClick={() => {
                            setSeeQuickLinks(!seeQuickLinks);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                          class="material-icons-round"
                        >
                          {seeQuickLinks ? "expand_less" : "expand_more"}
                        </span>
                      )}
                    </>
                  </div>
                )}
              <div className={classes.appMetaContainer}>
                <div
                  title="Application Category"
                  className={classes.appMetaContent}
                >
                  <span
                    class="material-icons-outlined"
                    style={{
                      color: `${
                        user.ui_preferences.theme === "light"
                          ? "#FC007F"
                          : "#fff"
                      }`,
                      marginRight: "7px",
                      fontSize: "22px",
                      cursor: "default",
                    }}
                  >
                    category
                  </span>
                  <p className={classes.infoHeading}>
                    {appDetails.app_details.app_category}
                  </p>
                </div>
                <div
                  title="Application Type"
                  className={classes.appMetaContent}
                >
                  <span
                    class="material-icons-outlined"
                    style={{
                      color: `${
                        user.ui_preferences.theme === "light"
                          ? "#FC007F"
                          : "#fff"
                      }`,
                      marginRight: "7px",
                      fontSize: "22px",
                      cursor: "default",
                    }}
                  >
                    format_list_bulleted
                  </span>
                  {appDetails.app_meta.app_type === "bi_app" && (
                    <p className={classes.infoHeading}> Embedded Analytics</p>
                  )}
                  {appDetails.app_meta.app_type === "ml_app" && (
                    <p className={classes.infoHeading}> ML Application</p>
                  )}
                  {appDetails.app_meta.app_type === "standalone_app" && (
                    <p className={classes.infoHeading}>
                      Standalone Application
                    </p>
                  )}
                </div>
                {appDetails.app_meta.app_type === "bi_app" && (
                  <div
                    title="Application platforms"
                    className={classes.appMetaContent}
                  >
                    <span
                      class="material-icons-outlined"
                      style={{
                        color: `${
                          user.ui_preferences.theme === "light"
                            ? "#FC007F"
                            : "#fff"
                        }`,
                        marginRight: "5px",
                        fontSize: "22px",
                        cursor: "default",
                      }}
                    >
                      integration_instructions
                    </span>
                    {appDetails.app_meta.app_name.toLowerCase() ===
                      "xplore" && (
                      <img
                        className={classes.platformClass}
                        src={SigmoidLogo}
                        alt="Sigview"
                      ></img>
                    )}
                    {appDetails.app_meta.app_name.toLowerCase() !== "xplore" &&
                      platform.power_bi && (
                        <img
                          className={classes.platformClass}
                          src={powerBIImg}
                          alt="Power BI"
                        ></img>
                      )}
                    {appDetails.app_meta.app_name.toLowerCase() !== "xplore" &&
                      platform.sharepoint && (
                        <img
                          className={classes.platformClass}
                          src={sharepointImg}
                          alt="Sharepoint"
                        ></img>
                      )}
                    {appDetails.app_meta.app_name.toLowerCase() !== "xplore" &&
                      platform.google_studio && (
                        <img
                          className={classes.platformClass}
                          src={googleStudioImg}
                          alt="Google Studio"
                        ></img>
                      )}
                    {appDetails.app_meta.app_name.toLowerCase() !== "xplore" &&
                      platform.power_app && (
                        <img
                          className={classes.platformClass}
                          src={powerAppImg}
                          alt="Power Apps"
                        ></img>
                      )}
                  </div>
                )}
              </div>
            </div>
            <div className={classes.bottomContentContainer}>
              <div>
                {appDetails.app_meta.app_type === "standalone_app" &&
                  appDetails.app_meta.doc_url !== "" && (
                    <div className={classes.displayContainer}>
                      <p className={classes.infoHeading}>Documentation:</p>
                      <p
                        onClick={() => {
                          window.open(
                            appDetails.app_meta.doc_url,
                            "Documentation"
                          );
                        }}
                        className={classes.subcatLinks}
                      >
                        <u>Link</u>
                      </p>
                    </div>
                  )}
                {appDetails.app_details.function.length > 0 && (
                  <div className={classes.displayContainer}>
                    <p className={classes.infoHeading}>Function:</p>
                    {appDetails.app_details.function.map((fun, index) => (
                      <p className={classes.infoPara}>
                        {fun}
                        {index < appDetails.app_details.function.length - 1
                          ? ", "
                          : " "}
                      </p>
                    ))}
                  </div>
                )}
                {appDetails.app_details.line_of_business.length > 0 && (
                  <div className={classes.displayContainer}>
                    <p className={classes.infoHeading}>Global Business Unit:</p>
                    {appDetails.app_details.line_of_business.map(
                      (lob, index) => (
                        <p className={classes.infoPara}>
                          {lob}
                          {index <
                          appDetails.app_details.line_of_business.length - 1
                            ? ", "
                            : " "}
                        </p>
                      )
                    )}
                  </div>
                )}
                {appDetails.app_details.geography.length > 0 && (
                  <div className={classes.displayContainer}>
                    <p className={classes.infoHeading}>Geography:</p>
                    {appDetails.app_details.geography.map((geo, index) => (
                      <p className={classes.infoPara}>
                        {/* {geo} */}
                        {geo.region}
                        {geo.region !== "All" &&
                          geo.countries.map((country, ind) => (
                            <span className={classes.infoPara}>
                              {ind === 0 && "("}
                              {country}
                              {ind < geo.countries.length - 1 ? ", " : ")"}
                            </span>
                          ))}
                        {index < appDetails.app_details.geography.length - 1
                          ? ", "
                          : " "}
                      </p>
                    ))}
                  </div>
                )}
                {appDetails.app_details.associated_datasets.length > 0 && (
                  <div className={classes.displayContainer}>
                    <p className={classes.infoHeading}>Associated Datasets:</p>
                    {appDetails.app_details.associated_datasets.map(
                      (dataset, index) => (
                        <p className={classes.infoPara}>
                          {dataset}
                          {index <
                          appDetails.app_details.associated_datasets.length - 1
                            ? ", "
                            : " "}
                        </p>
                      )
                    )}
                  </div>
                )}
                {appDetails.app_details.tags.length > 0 && (
                  <div className={classes.displayContainer}>
                    <p className={classes.infoHeading}>Tags:</p>
                    {appDetails.app_details.tags.map((tag, index) => (
                      <p className={classes.infoPara}>
                        {tag}
                        {index < appDetails.app_details.tags.length - 1
                          ? ", "
                          : " "}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
        </div>
        <DialogActions
          className={
            appOwnerArr.length !== 0
              ? classes.AlertFooterContainerWithOwners
              : classes.AlertFooterContainer
          }
        >
          {appOwnerArr.length !== 0 && (
            <div className={classes.ownerListContainer}>
              <div className={classes.displayFlex}>
                <span
                  class="material-icons-round"
                  style={{
                    color: `${
                      user.ui_preferences.theme === "light" ? "#FC007F" : "#fff"
                    }`,
                    marginRight: "7px",
                    fontSize: "22px",
                  }}
                >
                  person
                </span>
                <p className={classes.infoHeading}>App Owners</p>
              </div>
              <div
                className={classes.displayContainer}
                style={{
                  paddingLeft: "24px",
                }}
              >
                {appOwnerArr.slice(0, 5).map((person, index) => (
                  <p className={classes.infoPara}>
                    {person?.name
                      ? person?.name
                          .replace("(Contractor)", "")
                          .replace(",", " ") + " "
                      : " "}
                    {index < appOwnerArr.length - 1 ? ", " : " "}
                  </p>
                ))}

                {appOwnerArr.length > 5 && (
                  <HtmlTooltip
                    placement="top-start"
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {appOwnerArr
                          .slice(5, appOwnerArr.length)
                          .map((person, index) => (
                            <p className={classes.infoPara}>
                              {person?.name
                                ? person?.name
                                    .replace("(Contractor)", "")
                                    .replace(",", " ") + " "
                                : " "}
                              {index < appOwnerArr.length - 1 ? ", " : " "}
                            </p>
                          ))}
                      </div>
                    }
                  >
                    <p className={classes.infoPara}>
                      +{appOwnerArr.length - 5} more.
                    </p>
                  </HtmlTooltip>
                )}
              </div>
            </div>
          )}
          <div>
            {accessStatus ||
            appDetails?.access_details?.access_type === "public" ? (
              <button
                className={`${classes.openAppButton} GA-open-application`}
                id={appDetails.app_id}
                onClick={() => {
                  HandleOpenApp(appDetails);
                }}
              >
                Open Application
              </button>
            ) : reqState === "pending" || reqState === "error" ? (
              <button
                onClick={() => {
                  HandleRequestAccess(appDetails);
                }}
                id={appDetails.app_id}
                className={`${classes.requestAccessButton} GA-request-access`}
              >
                Request Access
              </button>
            ) : (
              <button
                id={appDetails.app_id}
                className={classes.requestAccessButton}
              >
                Access Requested
              </button>
            )}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};
AppInfoCard.propTypes = {
  open: PropTypes.any.isRequired,
  handleClose: PropTypes.any.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
  AppsData: state.AppsData,
  appDashboardState: state.appDashboard.appDashboardState,
});

export default connect(mapStateToProps)(AppInfoCard);
