/** @format */

import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: "5px",
        fontFamily: "Energy !important",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    chipStyling: {
        marginTop: "0px",
        fontFamily: "Energy",
        fontSize: "12px",
        border: "1px solid #FBEDF2",
        // height: "28px",
        height: "28px",
        borderRadius: "3px",
        width: "fit-content",
        margin: "5px 5px 0px 0px",
        background: "#FBEDF2",
        fontStyle: "normal",
        fontWeight: 400,
        color: "#D8477E",
        // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
        "&:focus": {
            backgroundColor: "#FBEDF2",
        },
        "& .MuiChip-deleteIcon": {
            fontSize: 16,
            border: "none",
            color: "#D8477E",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        "&.Mui-disabled": {
            "& .MuiChip-deleteIcon": {
                display: "none",
            },
            color: "#D8477E !important",
            opacity: 1,
        },
    },
    chipContainer: {
        "& .MuiChip-root.Mui-disabled": {
            opacity: 1,
        },
    },
    container: {
        // marginLeft: '20px',
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "10px",
    },
}));

export default function SelectedFilterChip({
    chip,
    handleDelete,
    isFieldsEditable,
}) {
    const classes = useStyles();
    return (
        <Chip
            label={chip}
            onDelete={() => handleDelete(chip)}
            deleteIcon={<i className="material-icons-outlined">clear</i>}
            className={classes.chipStyling}
            disabled={isFieldsEditable}
        />
    );
}
