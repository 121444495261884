import React from "react";
import { Drawer } from "@mui/material";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import ConfirmationModal from "../AlertMsg/ConfirmationModal";
import Loader from "../Loader/Loader";
import SelectComponent from "./SelectComponent";

const message = "This action will reset your filters.";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 5,
    padding: "10px",
    height: "100%",
    paddingTop: "20px",
  },
  containerWithoutPadding: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 5,
    padding: "10px",
    height: "100%",
    paddingTop: "10px",
  },
  heading: {
    color: "#fc007f",
    fontSize: "13px",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 400,
    lineHeight: "23px",
    alignContent: "center",
    marginBottom: "20px",
  },
  closeIcon: {
    position: "absolute",
    top: "22px",
    left: "10px",
    cursor: "pointer",
    "& .material-icons-outlined": {
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      fontSize: "20px",
    },
  },
  closeIconWithoutTopVal: {
    position: "absolute",
    top: "10px",
    left: "10px",
    cursor: "pointer",
    "& .material-icons-outlined": {
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      fontSize: "20px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // flexDirection: "column",
    gap: "10px",
    marginBottom: "25px",
  },
  resetBtn: {
    color: "#282828",
    // backgroundColor: "#E7E9EE",
    backgroundColor: "#d3d4d7",
    marginRight: "10px",
    flex: 1,
    height: "24px",
    fontSize: "12px",
  },
  saveBtn: {
    flex: 1,
    height: "24px",
    fontSize: "12px",
    "&:disabled": {
      opacity: ".5",
    },
  },
}));
const DrawerFilters = ({
  open,
  handleCloseDrawer,
  handleReset,
  loading,
  handleSaveFilters,
  templates,
  handleContinue,
  filterList,
  handleChange,
  filterOptions,
  setFilterOptions,
  handleSubmit,
  setFilterList,
  showSaveBtn = false,
  confirmationModalOpen = false,
  handleCloseConfirmationModal,
  requiredDropdowns = [],
}) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={open}
      variant="persistent"
      sx={(theme) => ({
        "& .MuiDrawer-paper": {
          width: "280px",
          borderLeft: "none",
          boxShadow: "4px 4px 12px 4px #434343",
          backgroundColor: theme.palette.mode === "dark" ? "#000" : "#f7f7f7",
        },
      })}
    >
      <ConfirmationModal
        open={confirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        handleSubmit={handleContinue}
        message={message}
      />
      <div
        className={
          !showSaveBtn ? classes.container : classes.containerWithoutPadding
        }
      >
        <div
          className={
            !showSaveBtn ? classes.closeIcon : classes.closeIconWithoutTopVal
          }
        >
          <span
            class="material-icons-outlined GA-close-drawer"
            onClick={handleCloseDrawer}
          >
            close
          </span>
        </div>
        <div>
          <div className={classes.heading}>Apply Filters</div>
          {templates.status === "loading" && <Loader />}
          {(templates.data.search_template?.fields || []).map((item) => {
            // custom setup to show and hide dropdowns
            if (
              requiredDropdowns.length &&
              !requiredDropdowns.includes(item.key)
            )
              return null;
            return (
              <SelectComponent
                key={item.key}
                data={item}
                value={filterList[item.key]}
                handleChange={handleChange}
                filterList={filterList}
                setFilterList={setFilterList}
                handleSubmit={handleSubmit}
                setFilterOptions={setFilterOptions}
                filterOptions={filterOptions}
              />
            );
          })}
        </div>

        <div className={classes.footer}>
          <button
            className={`${classes.resetBtn} GA-reset-filters-btn`}
            onClick={handleReset}
            disabled={loading}
          >
            Reset Filters
          </button>
          {showSaveBtn && (
            <button
              className={`${classes.saveBtn} GA-save-filters-btn`}
              onClick={handleSaveFilters}
              disabled={loading}
            >
              {loading ? <Loader color="#fff" margin="0px" /> : "Save Filters"}
            </button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  templates: state.register.registerTemplate,
  user: state.user,
  AppsData: state.AppsData,
  user_filters: state.AppsData.user_filters,
});

export default connect(mapStateToProps)(DrawerFilters);
