import React, { useEffect, useMemo, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import { Box, IconButton } from "@mui/material";
import delete_icon from "../../../assets/images/delete_icon.svg";
import { config } from "../../config/config";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { getEditorState, specialFeilds } from "../../utils/utils";

const useStyles = (customColor) =>
  makeStyles((theme) => ({
    card: {
      boxSizing: "border-box",
      backgroundColor: customColor
        ? customColor
        : theme.palette.mode === "dark"
        ? "#4e4e4e"
        : "#fff",
      // border:
      //     theme.palette.mode === "dark"
      //         ? "transparent"
      //         : "0.2px solid #e7e9ee",
      // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
      boxShadow: config.cssValues.cardShadow,
      borderRadius: "5px",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      display: "flex",
      alignItems: "center",
      // justifyContent: "center",
      flexDirection: "column",
      fontSize: "14px",
      // cursor: "pointer",
      width: "100%",
      height: "100%",
      justifyContent: "space-evenly",
      // gap: "10px",
      // width: "105px",
      // height: "100px",
      padding: "0 8px",
      position: "relative",
      "& .deleteLogo": {
        display: "none",
      },
      "& .editBtn": {
        display: "inherit",
        position: "absolute",
        top: "0px",
        right: "17px",
      },
      "&:hover": {
        "& .deleteLogo": {
          display: "block",
        },
      },
      "& .googleIcons": {
        background:
          "linear-gradient(45deg, #FC007F 0%,#FC007F 50% ,#FF4D48 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
      },
      "& .normalIcons": {
        color: "#FC007F",
      },
    },
    textCard: {
      boxSizing: "border-box",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px",
      width: "100%",
      height: "100%",
      padding: "5px",
      position: "relative",
      "& .deleteLogo": {
        display: "none",
      },
      "& .editBtn": {
        display: "none",
      },
      "&:hover": {
        "& .deleteLogo": {
          display: "block",
        },
        "& .editBtn": {
          display: "inherit",
        },
      },
    },
    title: {
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "11px",
      lineHeight: "16px",
      textAlign: "center",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      // color: "#282828",
      marginTop: "4px",
      overflow: "hidden",
      overflowWrap: "anywhere",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      "& p": {
        textAlign: "center !important",
        lineHeight: "20px",
        margin: 0,
        padding: 0,
        whiteSpace: "pre-wrap",
      },
    },
    img: {
      // height: "50px",
      maxHeight: "60%",
      maxWidth: "60%",
    },
    deleteIcon: {
      position: "absolute",
      left: " -6px",
      top: "-6px",
      height: "15px",
      cursor: "pointer",
    },
    imgCard: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "cover",
      backgroundSize: "cover",
      backgroundPosition: "center",
      // width: "100%",
      // height: "100%",
    },
    desc: {
      // whiteSpace: "pre-wrap",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "20px",
      overflow: "hidden",
      overflowWrap: "anywhere",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      // "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      textAlign: "center",
      "& p": {
        textAlign: "center !important",
        lineHeight: "20px",
        margin: 0,
        padding: 0,
      },
    },
  }));

const TemplateCard = ({
  data,
  handleNavigation,
  parentData,
  handleDelete,
  handleEdit,
  notEditable = false,
  normalIcons,
  editable_layout,
  isDragging,
  ...rest
}) => {
  const classes = useStyles(data.card_bg_color)();
  const { h, w } = data.position_meta;
  const refName = useRef(null);
  const refDesc = useRef(null);

  const path_has_register =
    window.location.pathname.split("/")[1] === "register";

  const handleCustomEdit = () => {
    // e.stopPropagation();
    handleEdit(data);
  };
  const handleCustomDelete = (e) => {
    e.stopPropagation();
    handleDelete(data);
  };
  const getHeight = (fontSize = 40, text_type, factor = 2) => {
    let height = null;
    if (h < w) {
      height = (h / factor) * fontSize;
    } else if (w < h) {
      height = (w / (factor * 2)) * fontSize;
    } else if (h === w) {
      height = (h / factor) * fontSize;
    } else height = 50;
    if (height < 10) return 10;
    if (height) if (height > 24 && text_type === "name") return 24;
    return height;
  };

  const getTextForTitle = useMemo(() => {
    if (!data.sub_category_description.startsWith('{"blocks"')) {
      return data.sub_category_description;
    } else {
      let reqText = "";
      // JSON.parse(data.sub_category_description)?.blocks?.forEach(
      //     (item) => (reqText = reqText + item.text + "\n")
      // );
      JSON.parse(data.sub_category_description)?.blocks?.forEach((item) => {
        if (item.text) {
          reqText = reqText + item.text + "\n";
        }
      });
      return reqText;
    }
  }, [data.sub_category_description]);

  useEffect(() => {
    if (specialFeilds.includes(data.sub_category_type)) {
      const name = getEditorState(data.sub_category_name);
      const desc = getEditorState(data.sub_category_description);

      const reqHtmlName = draftToHtml(convertToRaw(name.getCurrentContent()));
      const reqHtmlDesc = draftToHtml(convertToRaw(desc.getCurrentContent()));
      if (refName?.current) {
        refName.current.innerHTML = reqHtmlName
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&apos;/g, "'");
      }
      if (refDesc?.current) {
        refDesc.current.innerHTML = reqHtmlDesc
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&apos;/g, "'")
          .replaceAll("\\n", "\n");
      }
    }
  }, [
    data.sub_category_description,
    data.sub_category_id,
    data.sub_category_name,
    data.sub_category_type,
    data.show_description,
  ]);

  if (data.sub_category_type === "textBox") {
    return (
      <div
        className={classes.textCard}
        key={data.sub_category_id}
        onClick={
          () =>
            !isDragging && editable_layout && path_has_register
              ? handleCustomEdit()
              : null
          // !editable_layout && path_has_register
          //   ? undefined
          //   : handleNavigation(data, parentData)
        }
        title={getTextForTitle}
        {...rest}
      >
        {!notEditable && editable_layout && (
          <>
            <img
              src={delete_icon}
              alt="delete"
              className={`${classes.deleteIcon} deleteLogo`}
              onClick={handleCustomDelete}
            />
            {/* <IconButton
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={handleCustomEdit}
              title="Edit"
              className="editBtn"
            >
              <EditIcon sx={{ fontSize: "14px" }} />
            </IconButton> */}
          </>
        )}
        <span ref={refName}></span>
      </div>
    );
  }

  if (data.sub_category_type === "image") {
    return (
      <div
        className={classes.textCard}
        key={data.sub_category_id}
        onClick={
          () =>
            !isDragging && editable_layout && path_has_register
              ? handleCustomEdit()
              : null
          // !editable_layout && path_has_register
          //   ? undefined
          //   : handleNavigation(data, parentData)
        }
        title={getTextForTitle}
        {...rest}
      >
        {!notEditable && editable_layout && (
          <>
            <img
              src={delete_icon}
              alt="delete"
              className={`${classes.deleteIcon} deleteLogo`}
              onClick={handleCustomDelete}
            />
            {/* <IconButton
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={handleCustomEdit}
              title="Edit"
              className="editBtn"
            >
              <EditIcon sx={{ fontSize: "14px" }} />
            </IconButton> */}
          </>
        )}
        {data.app_logo_type === "google_font" ? (
          <span
            class={`material-icons-outlined ${
              normalIcons ? "normalIcons" : "googleIcons"
            }`}
            style={{
              fontSize: `${getHeight(80)}px`,
            }}
          >
            {data.sub_category_icon}
          </span>
        ) : (
          <img
            src={data.sub_category_icon}
            className={classes.imgCard}
            alt=""
          />
        )}
      </div>
    );
  }

  const handleOnClickCard = () => {
    if (!isDragging) {
      if (path_has_register && editable_layout) {
        handleCustomEdit();
      } else {
        handleNavigation(data, parentData);
      }
    } else {
      return;
    }
  };

  // console.log("TemplateCard_data", data);
  return (
    <div
      className={classes.card}
      key={data.sub_category_id}
      onClick={handleOnClickCard}
      title={getTextForTitle}
      style={{
        cursor:
          data.sub_category_type === "header" ||
          data.sub_category_type === "sub_header" ||
          data.sub_category_type === "textBox" ||
          data.sub_category_type === "image"
            ? "default"
            : "pointer",
      }}
      {...rest}
    >
      {!notEditable && editable_layout && (
        <>
          <img
            src={delete_icon}
            alt="delete"
            className={`${classes.deleteIcon} deleteLogo`}
            onClick={handleCustomDelete}
          />
          {data.sub_category_type === "hybrid" && (
            <IconButton
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                handleNavigation(data, parentData);
              }}
              title="Open"
              className="editBtn"
            >
              <ExitToAppRoundedIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          )}
        </>
      )}
      {data.sub_category_type === "image" ? (
        <>
          {data.app_logo_type === "google_font" ? (
            <span
              class={`material-icons-outlined ${
                normalIcons ? "normalIcons" : "googleIcons"
              }`}
              style={{
                fontSize: `${getHeight(80)}px`,
              }}
            >
              {data.sub_category_icon}
            </span>
          ) : (
            <img
              src={data.sub_category_icon}
              className={classes.imgCard}
              alt=""
            />
          )}
        </>
      ) : (
        <>
          {data.sub_category_icon ? (
            <>
              {data.app_logo_type === "google_font" ? (
                <span
                  class={`material-icons-outlined ${
                    normalIcons ? "normalIcons" : "googleIcons"
                  }`}
                  style={{
                    fontSize: `${getHeight(40)}px`,
                  }}
                >
                  {data.sub_category_icon}
                </span>
              ) : (
                <img
                  src={data.sub_category_icon}
                  alt=""
                  className={classes.img}
                />
              )}
            </>
          ) : null}
          <span
            className={classes.title}
            style={{ fontSize: "12px" }}
            id={data.sub_category_id}
            ref={refName}
          >
            {data.sub_category_name}
          </span>
          {data.show_description && (
            <Box
              className={classes.desc}
              sx={{
                "-webkit-line-clamp": `${
                  data.sub_category_icon ? h : parseInt(h) + 1
                } !important`,
              }}
              ref={refDesc}
            >
              {(data.sub_category_description || "").replaceAll("\\n", "\n")}
            </Box>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  editable_layout: state.registerNewApp.statusData.editLayout,
});

export default connect(mapStateToProps)(TemplateCard);

// title={(data.sub_category_description || "").replaceAll(
//     "\\n",
//     "\n"
// )}
