// Import Required Libraries
import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { v4 } from "uuid";
// Import functions
import { fetchAPI } from "../HelpCenter";

// Import Utils
import { formatDate } from "../../../utils/utils";

// import Styles
import "../HelpCenter.scss";
import { useStylesForJiraTicket } from "../HelpStyles";
import { SkeletonForTicketPopUp } from "../../../components/ImageLoader/SkeletonLoadScreen";

function TicketComment(props) {
  const { commentData = {}, allTicketMedia = [], user } = props;
  const [imgData, setImgData] = useState({});

  const classes = useStylesForJiraTicket();
  // Extract the image from all the attachments and set them to particular comment
  // on the basis of file name
  useEffect(() => {
    const reqAttach = allTicketMedia.find((attachment) =>
      commentData.body.includes(`!${attachment.filename}!`)
    );
    setImgData(reqAttach || {});
  }, [allTicketMedia, commentData.body]);

  const openImage = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <div className={classes.commentContainer}>
      <div>
        <p className={classes.commenterName}>
          {commentData.body.includes("[Data@Reckitt Notes]")
            ? "Data@Reckitt Support Team"
            : user.name}
        </p>
        <p className={classes.commentBody}>
          {commentData.body
            .replace("[Data@Reckitt Notes]", "")
            .replace(`!${imgData.filename}!`, "")}
        </p>
        {imgData.content && (
          <img
            onClick={() => {
              openImage(imgData.content);
            }}
            className={classes.commentImage}
            src={imgData.content}
            alt="img"
          />
        )}
        <p className={classes.commentTime}>{formatDate(commentData.updated)}</p>
      </div>
    </div>
  );
}

function TicketContent(props) {
  const {
    data = {},
    setLoadCommentStatus,
    loadCommentStatus,
    loadTicket,
    user,
    handleClose,
  } = props;
  const [commentValue, setCommentValue] = useState("");
  const [attachmentFile, setAttachmentFile] = useState({});
  const [ExpandVal, setExpandVal] = useState({
    description: { expanded: false, text: "Show More" },
    reference: { expanded: false, text: "Show More" },
  });

  const classes = useStylesForJiraTicket();

  const downloadImage = (url) => {
    window.open(url, "_blank").focus();
  };

  const addAttachment = async () => {
    var url = `/api/attachement/issue/${data.key}/attachments`;
    const form = new FormData();
    form.append("file", attachmentFile);

    const request_meta = {
      method: "POST",
      body: form,
      headers: {},
    };
    try {
      const res = await fetchAPI(url, request_meta);
      const response = await res.json();
      return true;
    } catch (error) {
      toast.error("Couldn't post comment!");
    }
  };

  const addComment = async (filename) => {
    var url = `/api/jira/issue/${data.key}/comment`;
    const payload = {
      public: true,
      body: filename ? commentValue + " !" + filename + "!" : commentValue,
    };
    const request_meta = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await fetchAPI(url, request_meta);
      const response = await res.json();
      return true;
    } catch (error) {
      toast.error("Couldn't post comment!");
    }
  };

  const postComment = async () => {
    const reqFunctionToBeCalled = [addComment(attachmentFile?.name)];
    if (attachmentFile?.name) reqFunctionToBeCalled.push(addAttachment());
    const response = await Promise.allSettled(reqFunctionToBeCalled);
    const isResponseResolved = [];
    response.forEach((item) => isResponseResolved.push(item.value || false));
    setAttachmentFile({});
    setCommentValue("");
    if (isResponseResolved.includes(false)) {
      setLoadCommentStatus("error");
    } else {
      setLoadCommentStatus("success");
    }
  };

  const handleExpandVal = (key = "description") => {
    if (key === "description") {
      ExpandVal.description.expanded
        ? setExpandVal({
            ...ExpandVal,
            description: { expanded: false, text: "Show More" },
          })
        : setExpandVal({
            ...ExpandVal,
            description: { expanded: true, text: "Show Less" },
          });
    } else if (key === "reference") {
      ExpandVal.reference.expanded
        ? setExpandVal({
            ...ExpandVal,
            reference: { expanded: false, text: "Show More" },
          })
        : setExpandVal({
            ...ExpandVal,
            reference: { expanded: true, text: "Show Less" },
          });
    }
  };

  return (
    <>
      <Box className="" m="0px 15px" p="15px 0px">
        <div className="ticket-header-container">
          <div className={classes.displayFJustSBAlignCen}>
            <div className={classes.displayFlex}>
              <p className={classes.ticketKey}>{data.key}</p>
              <p className={classes.ticketStatus}>{data.fields.status.name}</p>
            </div>
            <span
              id={data.key}
              style={{
                color: "#aab4bd",
                fontSize: "22px",
                marginRight: "5px",
              }}
              class="material-icons-outlined GA-close-ticket-popup"
              onClick={handleClose}
            >
              close
            </span>
          </div>
        </div>
        <div className={classes.ticketContentContainer}>
          <p className={classes.ticketSummary}>{data.fields.summary}</p>
          {data.fields.description.split("||").map((key, i) => {
            if (i === 0) {
              return (
                <div className={classes.ticketMetaContainer}>
                  <p className={classes.ticketMetaHeading}>Description</p>
                  <p
                    class={
                      ExpandVal.description.expanded || key.length < 280
                        ? "expanded-ticket-description "
                        : "collapsed-ticket-description "
                    }
                  >
                    {key}
                  </p>
                  {key.length > 280 && (
                    <p
                      id={data.key}
                      className={`${classes.expandButton} GA-expand-ticket-description`}
                      onClick={() => {
                        handleExpandVal("description");
                      }}
                    >
                      {ExpandVal.description.text}
                    </p>
                  )}
                </div>
              );
            } else if (
              key.split("::")[0].toLowerCase() === "optional reference"
            ) {
              return (
                <div className={classes.ticketMetaContainer}>
                  <p className={classes.ticketMetaHeading}>
                    {key.split("::")[0]}
                  </p>
                  <p
                    class={
                      ExpandVal.reference.expanded ||
                      key.split("::")[1].length < 280
                        ? "expanded-ticket-description"
                        : "collapsed-ticket-description"
                    }
                  >
                    {key.split("::")[1]}
                  </p>
                  {key.split("::")[1].length > 280 && (
                    <p
                      id={data.key}
                      className={`${classes.expandButton} GA-expand-ticket-description`}
                      onClick={() => {
                        handleExpandVal("reference");
                      }}
                    >
                      {ExpandVal.reference.text}
                    </p>
                  )}
                </div>
              );
            }
            return (
              <div className={classes.ticketMetaContainer}>
                <p className={classes.ticketMetaHeading}>
                  {key.split("::")[0]}
                </p>
                <p className={classes.ticketDescription}>
                  {key.split("::")[1]}
                </p>
              </div>
            );
          })}
          {data.fields.attachment.length > 0 && (
            <div className="ticket-attachment-container">
              <div className={classes.displayFlex}>
                <p className={classes.contentHeading}>Attachments</p>
                <span
                  style={{
                    color: "#FC007F",
                    fontSize: "20px",
                    marginLeft: "5px",
                  }}
                  class="material-icons-outlined content-styles"
                >
                  attachment
                </span>
              </div>
              <div style={{ flexWrap: "wrap" }} className={classes.displayFlex}>
                {data.fields.attachment.map((attachment) => {
                  if (!attachment.filename.endsWith(".pdf")) {
                    return (
                      <div
                        title={attachment.filename}
                        className={classes.attachmentContainer}
                      >
                        <span
                          id={data.key}
                          onClick={() => {
                            downloadImage(attachment.content);
                          }}
                          style={{
                            position: "absolute",
                            background: "white",
                            color: "#282828",
                            borderRadius: "4px",
                            boxShadow: "0px 5px 15px 0px #00000040",
                          }}
                          class="material-icons-outlined GA-download-ticket-attachment"
                        >
                          file_download
                        </span>
                        <img
                          className={classes.attachmentImage}
                          src={attachment.content}
                          alt=""
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        title={attachment.filename}
                        className={classes.attachmentContainer}
                      >
                        <span
                          id={data.key}
                          onClick={() => {
                            downloadImage(attachment.content);
                          }}
                          style={{
                            position: "absolute",
                            background: "white",
                            color: "#282828",
                            borderRadius: "4px",
                            boxShadow: "0px 5px 15px 0px #00000040",
                          }}
                          class="material-icons-outlined GA-download-ticket-attachment"
                        >
                          file_download
                        </span>
                        <div
                          className={classes.attachmentPDF}
                          src={attachment.content}
                        >
                          <span
                            style={{
                              color: "#282828",
                              fontSize: "44px",
                            }}
                            class="material-icons-outlined"
                          >
                            picture_as_pdf
                          </span>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}
          <div className="ticket-comments-container">
            <div className={classes.displayFlex}>
              <p className={classes.contentHeading}>Comments</p>
              <span
                style={{
                  color: "#FC007F",
                  fontSize: "20px",
                  marginLeft: "5px",
                }}
                class="material-icons-outlined"
              >
                chat
              </span>
            </div>
            <div className={classes.commentSection}>
              <textarea
                onChange={(e) => {
                  setCommentValue(e.currentTarget.value);
                }}
                rows="2"
                value={commentValue}
                maxLength={1000}
                placeholder="Enter a comment..."
                className={classes.textAreaCS}
              />
              <div className={classes.displayFJustSBAlignCen}>
                <div className={classes.displayFlex}>
                  <label class="custom-file-upload">
                    <input
                      id={data.key}
                      class="GA-upload-file-for-ticket"
                      key={v4()}
                      onChange={(e) => {
                        setAttachmentFile(e.target.files[0]);
                      }}
                      disabled={
                        loadCommentStatus === "loading" ||
                        loadTicket === "loading"
                      }
                      accept="image/*"
                      type="file"
                      name="myfile"
                    />
                    {"Upload File"}
                  </label>
                  {attachmentFile?.name && (
                    <p className={classes.attachmentFileName}>
                      {attachmentFile?.name}
                    </p>
                  )}
                </div>

                <div className={classes.displayFlex}>
                  {loadCommentStatus !== "loading" &&
                    loadTicket !== "loading" && (
                      <button
                        id={data.key}
                        onClick={() => {
                          setCommentValue("");
                          setAttachmentFile({});
                        }}
                        disabled={
                          commentValue !== "" ||
                          attachmentFile?.name !== undefined
                            ? false
                            : true
                        }
                        className={`${classes.cancelButton} GA-clear-comment-for-ticket`}
                      >
                        Clear
                      </button>
                    )}
                  <button
                    onClick={() => {
                      setLoadCommentStatus("loading");
                      postComment();
                    }}
                    disabled={
                      (commentValue === "" &&
                        attachmentFile?.name === undefined) ||
                      loadCommentStatus === "loading"
                        ? true
                        : false
                    }
                    className={`${classes.postButton} GA-post-comment`}
                  >
                    {loadCommentStatus === "loading" || loadTicket === "loading"
                      ? "Posting..."
                      : "Post Comment"}
                  </button>
                </div>
              </div>
            </div>
            <div className="ticket-comments-content">
              {data.fields.comment.comments
                .slice(0)
                .reverse()
                .map((comment) => {
                  return (
                    <TicketComment
                      key={comment.id}
                      allTicketMedia={data.fields.attachment}
                      commentData={comment}
                      user={user}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}

function TicketPopUp(props) {
  const {
    ticket_key = "",
    openJiraTicket = false,
    setOpenJiraTicket,
    user,
  } = props;
  const [ticketData, setTicketData] = useState({});
  const [loadTicket, setLoadTicket] = useState("pending");
  const [refreshTicket, setRefreshTicket] = useState("pending");
  const [loadCommentStatus, setLoadCommentStatus] = useState("pending");

  const classes = useStylesForJiraTicket();

  const handleClose = () => {
    setOpenJiraTicket(false);
  };

  const getTicketData = (response) => {
    if (response) {
      setLoadTicket("success");
      setLoadCommentStatus("pending");
      setRefreshTicket("pending");
      setTicketData(response);
    }
  };

  const handleFetchTicketDetails = async (key) => {
    setLoadTicket("loading");
    var url = `/api/jira/issue/${key}`;
    const request_meta = {
      method: "GET",
    };
    try {
      const res = await fetchAPI(url, request_meta);
      const response = await res.json();
      getTicketData(response);
    } catch (error) {
      setLoadTicket("error");
      toast.error("Couldn't load ticket details.");
    }
  };

  useEffect(() => {
    handleFetchTicketDetails(ticket_key);
  }, []);

  useEffect(() => {
    if (loadCommentStatus === "success") {
      setRefreshTicket("loading");
      handleFetchTicketDetails(ticket_key);
    }
  }, [loadCommentStatus]);

  return (
    <>
      <Dialog
        open={openJiraTicket}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          minWidth: "900px !important",
          maxWidth: "900px !important",
        }}
        className={classes.alertContainer}
      >
        <div className={classes.ticketOuterContainer}>
          {ticketData !== {} && ticketData.key && (
            <TicketContent
              setLoadCommentStatus={setLoadCommentStatus}
              data={ticketData}
              loadCommentStatus={loadCommentStatus}
              loadTicket={loadTicket}
              user={user}
              handleClose={handleClose}
            />
          )}
          {loadTicket === "loading" && refreshTicket !== "loading" && (
            <SkeletonForTicketPopUp />
          )}
        </div>
      </Dialog>
    </>
  );
}

TicketPopUp.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TicketPopUp);
