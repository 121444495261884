import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { toast } from "react-toastify";
import { Drawer, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Loader from "../../../../../components/Loader/Loader";
import loginImg from "../../../../../../assets/images/register-images/login-img.png";
import { handleCopyToClipBoard } from "../../../../../utils/utils";
import {
  update_power_bi_reports,
  update_power_bi_workspace,
} from "../../../../../redux/actions";
import { StyledInputBase } from "../../../../../components/UserTable/style";
import { useStylesPowerBi } from "../../../style";

function fetchAPI(payload) {
  const url = "/api/powerbi_reports";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const showToaster = (msg, status = "success") => {
  const options = {
    style: {
      fontSize: "14px",
      color: "#4e4e4e",
    },
  };

  const toastFunction = status === "success" ? toast.success : toast.error;

  setTimeout(() => toastFunction(msg, options), 1500);
};

const LoginPowerbiScreen = ({ handleClose }) => {
  const classes = useStylesPowerBi();

  const handleAuth = () => {
    window.open(`${window.origin}/powerbi_auth/powerbi_login`, "_blank"); //need to be changed after powerbi access is fully set on backend
    handleClose();
    showToaster(
      "You have successfully signed In. Now you can reopen your workspaces.",
      "success"
    );
  };

  return (
    <div className={classes.loginContainer}>
      <img src={loginImg} alt="" />
      <div className={classes.loginText}>
        Please login to PowerBi to see your reports
      </div>
      <button className={classes.loginBtn} onClick={handleAuth}>
        Login
      </button>
    </div>
  );
};
const ReportCard = ({ report = {} }) => {
  const { name, embedUrl, webUrl } = report;
  const classes = useStylesPowerBi();
  const [showCopiedUrl, setShowCopiedUrl] = useState(false);
  const [showCopiedEmd, setShowCopiedEmd] = useState(false);
  const removeCopyText = (type) => {
    setTimeout(() => {
      if (type === "emb") {
        setShowCopiedEmd(false);
      } else if (type === "url") {
        setShowCopiedUrl(false);
      }
    }, 1000);
  };
  const handleCopyText = (e, text, type) => {
    if (type === "emb") {
      setShowCopiedEmd(true);
    } else if (type === "url") {
      setShowCopiedUrl(true);
    }
    handleCopyToClipBoard(text);
    removeCopyText(type);
  };

  return (
    <div className={classes.reportCard}>
      <div className={classes.reportText} title={name}>
        {name}
      </div>
      <div className={classes.reportRow}>
        <div className={classes.workspaceIcon}>
          {showCopiedUrl && <div className={classes.copiedNotify}>Copied</div>}
          <ContentCopyIcon
            className={`${classes.icons} GA-copy-link`}
            onClick={(e) => handleCopyText(e, webUrl, "url")}
            id="web_link"
          />
        </div>
        <div className={classes.urlContainer}>
          <div className={classes.reportUrl}>Report URL:</div>
          <div className={classes.url} title={webUrl}>
            {webUrl}
          </div>
        </div>
      </div>
      <div className={classes.reportRow}>
        <div className={classes.workspaceIcon}>
          {showCopiedEmd && <div className={classes.copiedNotify}>Copied</div>}
          <ContentCopyIcon
            className={`${classes.icons} GA-copy-link`}
            onClick={(e) => handleCopyText(e, embedUrl, "emb")}
            id="embedded_link"
          />
        </div>
        <div className={classes.urlContainer}>
          <div className={classes.reportUrl}>Report Embedded URL:</div>
          <div className={classes.url} title={embedUrl}>
            {embedUrl}
          </div>
        </div>
      </div>
    </div>
  );
};
const WorkspaceCard = ({ workspace, reports, status }) => {
  const classes = useStylesPowerBi();
  const [showReports, setShowReports] = useState(false);
  const handleReports = () => setShowReports((prev) => !prev);

  return (
    <>
      <div className={classes.workspaceCard} onClick={handleReports}>
        <div className={classes.workspaceText}>{workspace.name}</div>
        <div className={classes.workspaceIconArrow}>
          {showReports ? (
            <KeyboardArrowUpIcon sx={{ color: "#FC007F" }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: "#FC007F" }} />
          )}
        </div>
      </div>
      {showReports && (
        <>
          {status === "loading" && <Loader />}
          {(reports?.value || []).map((item) => (
            <ReportCard report={item} key={item.name} />
          ))}
        </>
      )}
    </>
  );
};

const PowerBiDrawer = ({ open, handleClose }) => {
  const classes = useStylesPowerBi();
  const dispatch = useDispatch();
  const [searchedReport, setSearchedReport] = useState([]);
  const [searchedVal, setSearchedVal] = useState("");
  const [searchWorkspace, setSearchWorkspace] = useState([]);
  const { powerBiWorkspace, powerBiReports } = useSelector(
    (state) => state.register
  );

  const allReports = useMemo(() => {
    let reqReports = [];
    (Object.keys(powerBiReports.data) || []).forEach((item) => {
      reqReports = [...reqReports, ...(powerBiReports.data[item]?.value || [])];
    });
    return reqReports;
  }, [powerBiReports.data]);

  const { data, status, message } = powerBiWorkspace;

  const requestSearch = (val) => {
    if (val.length > 0) {
      const reqSearchedReport = allReports.filter((item) =>
        item.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())
      );
      const reqSearchWorkspace = data.filter((item) =>
        item.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())
      );
      setSearchWorkspace(reqSearchWorkspace);
      setSearchedReport(reqSearchedReport);
      setSearchedVal(val);
    } else if (val.length === 0) {
      setSearchedReport([]);
      setSearchedVal(val);
    }
  };

  const cancelSearch = () => {
    setSearchedReport([]);
    setSearchedVal("");
  };

  const setDefaultData = useCallback(
    () =>
      dispatch(
        update_power_bi_workspace({
          status: "error",
          message: "login_required",
        })
      ),
    [dispatch]
  );
  const setDefaultReportData = useCallback(
    () =>
      dispatch(
        update_power_bi_workspace({
          status: "error",
          message: "login_required",
        })
      ),
    [dispatch]
  );

  const fetchReports = async (workspace) => {
    dispatch(
      update_power_bi_reports({
        status: "loading",
        message: "",
      })
    );
    const reqWorkspaceIds = [];
    workspace.forEach((item) => {
      reqWorkspaceIds.push(item.id);
    });
    try {
      const response = await fetchAPI({ group_ids: reqWorkspaceIds });
      const resJson = await response.json();
      if (resJson?.status.statusCode === "datareckitt-200") {
        dispatch(
          update_power_bi_reports({
            status: "success",
            message: "",
            data: resJson?.data,
          })
        );
      } else {
        setDefaultReportData();
        toast.error("Opps! something went wrong.");
      }
    } catch (error) {
      toast.error("Opps! something went wrong.");
      console.log("error", error);
      setDefaultReportData();
    }
  };
  useEffect(() => {
    const fetchWorkSpace = async () => {
      dispatch(update_power_bi_workspace({ status: "loading", message: "" }));
      try {
        const response = await fetch("/api/powerbi_workspace");
        const data = await response.json();
        if (
          data?.status.statusCode === "datareckitt-200" &&
          data?.data?.value
        ) {
          dispatch(
            update_power_bi_workspace({
              status: "success",
              data: data?.data.value,
            })
          );
          fetchReports(data?.data.value);
        } else if (data?.data?.hasOwnProperty("error")) {
          showToaster(data?.data?.error?.message, "error");
          setDefaultData();
        } else {
          setDefaultData();
        }
      } catch (error) {
        toast.error("Opps! something went wrong.");
        console.log("error", error);
        setDefaultData();
      }
    };
    if (status === "loading" || status === "success") return;
    fetchWorkSpace();
  }, []);

  return (
    <Drawer
      open={open}
      anchor="right"
      variant="persistent"
      className={classes.drawer}
      sx={{
        "& .MuiDrawer-paper": {
          padding: "20px 10px 20px 10px !important",
        },
      }}
    >
      <div className={classes.headingContainer}>
        <div className={classes.closeIcon}>
          <span class="material-icons-outlined" onClick={handleClose}>
            close
          </span>
        </div>
        <div className={classes.heading}>My PowerBI workspaces</div>
      </div>

      {status === "loading" ? (
        <Loader />
      ) : message === "login_required" ? (
        <LoginPowerbiScreen handleClose={handleClose} />
      ) : (
        <>
          <div className={classes.search}>
            <StyledInputBase
              placeholder="Search reports"
              inputProps={{
                "aria-label": "search",
              }}
              onChange={(e) => requestSearch(e.target.value)}
              value={searchedVal}
              className={classes.inputField}
            />
            {searchedVal ? (
              <IconButton onClick={(e) => cancelSearch(e)}>
                <ClearIcon
                  sx={{
                    color: "#282828",
                  }}
                />
              </IconButton>
            ) : (
              <IconButton>
                <SearchIcon
                  sx={{
                    color: "#282828",
                  }}
                />
              </IconButton>
            )}
          </div>
          <div className={classes.reportContainer}>
            {!searchedVal &&
              (data || []).map((item) => (
                <WorkspaceCard
                  workspace={item}
                  key={item.id}
                  reports={powerBiReports.data[item.id]}
                  status={powerBiReports.status}
                />
              ))}

            {searchedVal &&
            (searchedReport.length || searchWorkspace.length) ? (
              <>
                {searchedReport.map((item) => (
                  <ReportCard report={item} key={v4()} />
                ))}
                {searchWorkspace.map((item) => (
                  <WorkspaceCard
                    workspace={item}
                    key={item.id}
                    reports={powerBiReports.data[item.id]}
                    status={powerBiReports.status}
                  />
                ))}
              </>
            ) : searchedVal ? (
              <div
                className={classes.workspaceText}
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                }}
              >
                No result found
              </div>
            ) : null}
          </div>
        </>
      )}
    </Drawer>
  );
};

export default PowerBiDrawer;
