import { useState } from "react";
import Select, { components } from "react-select";
import "../../../styles/sass/NewThemeVariables.scss";
import { connect, useDispatch } from "react-redux";
import { updateUserInfo } from "../../redux/actions";

const darkcustomStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  control: (provided, state, theme) => ({
    ...provided,
    boxShadow: "none",
    height: "32px",
    fontSize: "12px",
    width: "200px",
    border: "1px solid #b2b1b1",
    minWidth: "16px",
    minHeight: "0px",
    borderRadius: "5px",
    backgroundColor: "#282828",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "#FFF",
    fontSize: "12px",
    fontWeight: "200",
    fontfamily: "Energy",
    bottom: "6px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#FFF",
    fontSize: "12px",
    fontWeight: "400",
    fontfamily: "Energy",
    bottom: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    color: "#FFF",
    fontSize: "12px",
    fontfamily: "Energy",
    backgroundColor: state.isSelected
      ? "#000000"
      : state.isFocused
      ? "#000000"
      : "#282828",
    "&:active": {
      backgroundColor: "#000000",
    },
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  }),
  menu: (provided) => ({
    ...provided,
    width: "200px", // Set your desired width here
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#282828", // Set your desired width here
  }),
};

const lightcustomStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  control: (provided, state, theme) => ({
    ...provided,
    boxShadow: "none",
    height: "32px",
    width: "200px",
    fontSize: "12px",
    border: "1px solid #b2b1b1",
    minWidth: "16px",
    minHeight: "0px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "#000000",
    fontSize: "12px",
    fontWeight: "200",
    fontfamily: "Energy",
    bottom: "6px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000000",
    fontSize: "12px",
    fontWeight: "400",
    fontfamily: "Energy",
    bottom: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    color: "#000000",
    fontfamily: "Energy",
    fontSize: "12px",
    backgroundColor: state.isSelected
      ? "#E7E9EE"
      : state.isFocused
      ? "#E7E9EE"
      : "#ffffff",
    "&:active": {
      backgroundColor: "#E7E9EE",
    },
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  }),
  menu: (provided) => ({
    ...provided,
    width: "200px", // Set your desired width here
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff", // Set your desired width here
  }),
};

const options = [
  { value: "app_category", label: "App Category" },
  { value: "app_type", label: "App Type" },
  { value: "function", label: "Function" },
  { value: "geography", label: "Geography" },
  { value: "line_of_business", label: "Global Business Unit" },
  { value: "tags", label: "Tags" },
  { value: "associated_datasets", label: "Associated Datasets" },
];

const CustomDropdownIndicator = ({ isMenuOpen, ...props }) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      <svg viewBox="0 0 34 34" width="26" height="26" fill="#b0b0b0">
        <path d={menuIsOpen ? "M7 14l5-5 5 5z" : "M7 10l5 5 5-5z"} />
      </svg>
    </components.DropdownIndicator>
  );
};

const ViewAppsBy = (props) => {
  const { viewByFilter, setViewByFilter, user } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (selectedOption) => {
    const value = selectedOption.value;
    setViewByFilter(value);
    dispatch(
      updateUserInfo({
        ...user,
        viewByFilter: value,
      })
    );
  };

  return (
    <div
      style={{
        width: "auto",
        height: "auto",
      }}
    >
      <Select
        value={options.find((option) => option.value === viewByFilter)}
        onChange={handleChange}
        options={options}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        styles={
          user.ui_preferences.theme === "light"
            ? lightcustomStyles
            : darkcustomStyles
        }
        isSearchable={false}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ViewAppsBy);
