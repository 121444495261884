import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { v4 } from "uuid";
import { useHistory } from "react-router";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import InputTextBox from "./Components/InputTextBox";
import SelectComponent from "./Components/SelectComponents";
import Attachments from "./Components/Attachments";
import DropDownComponent from "./Components/DropDownComponent";
import Rating from "./Components/Rating";
import Loader from "../../components/Loader/Loader";
import { NewTicketSkeletonLoader } from "./Components/SkeletonLoader";
import NotificationModal from "../../components/AlertMsg/NotificationModal";
import FormLabelComponent from "../../components/FormLabelComponent";
import { fetchUpdateHelpCenterFeedback } from "../../apiServices";
import {
  get_app_list,
  get_help_center_templates,
  save_ticket_meta,
  send_mail_to_app_owners,
  set_help_center_state,
} from "../../redux/actions";

function fetchAPI(payload) {
  const url = "/api/jira/issue";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}
function fetchAPI2(formData, key) {
  const url = `/api/attachement/issue/${key}/attachments`;
  return fetch(url, {
    method: "POST",
    headers: {},
    body: formData,
  });
}

const getRequiredComponent = (data, handleChange, formData, handleDelete) => {
  const { type, key, options } = data;
  if (
    !data.show_on_ui ||
    (key === "issue_type" &&
      formData.choose_application?.value === "Data@Reckitt")
  )
    return;
  switch (type) {
    case "text_field":
      return (
        <InputTextBox
          data={data}
          handleChange={handleChange}
          width={12}
          value={formData[key]}
          multiline={
            key === "description" || key === "additional_feedback"
              ? true
              : false
          }
          key={key}
        />
      );
    case "rating_field":
      return <Rating data={data} value={formData[key]} key={key} />;
    case "dropdown":
      return (
        <SelectComponent
          data={data}
          handleChange={handleChange}
          width={
            formData.request_category === "Request Feature" &&
            key !== "email_for_communication"
              ? 6
              : 12
          }
          options={options}
          value={formData[key]}
          handleDelete={handleDelete}
          key={key}
        />
      );
    case "attachment_field":
      return (
        <Attachments
          data={data}
          uploadedData={formData[key] || []}
          handleChange={handleChange}
          width={12}
          key={key}
        />
      );
    default:
      return null;
  }
};
const NewTicket = ({
  match,
  templates,
  helpCenterState,
  user,
  theme,
  allAppList,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formData, loading, openFeedbackModal, successMsg } = helpCenterState;
  const defaultData = {
    request_category: "Raise Incident",
    email_for_communication: [user],
  };

  const handleChange = (k, v, value_type) => {
    //validating the length if it is more then return
    if (
      (k === "additional_feedback" || k === "optional_reference") &&
      (v || "").length > 500
    )
      return;
    let reqData = formData;
    if (value_type === "multiple") {
      if (!(formData[k] || []).includes(v)) {
        reqData = {
          ...formData,
          [k]: [...(formData[k] || []), v],
        };
      }
    } else {
      reqData = { ...formData, [k]: v };
    }
    dispatch(set_help_center_state({ formData: reqData }));
  };
  const handleDelete = (k, v) => {
    const filteredData = formData[k].filter((item) => item !== v);
    const reqData = { ...formData, [k]: filteredData };
    dispatch(set_help_center_state({ formData: reqData }));
  };
  const handleClose = () => {
    dispatch(
      set_help_center_state({
        openFeedbackModal: false,
        successMsg: "",
        formData: defaultData,
      })
    );
    history.push("/help");
  };

  const getJiraDescription = (data) => {
    let reqData = `${data.description}${
      data.choose_application?.value
        ? `\n||Application type:: ${data.choose_application.value}`
        : ""
    }${
      data.choose_application?.value === "Registered Apps"
        ? `\n||Application Name:: ${
            data.choose_application.application_name === "Other"
              ? data.choose_application.other_application
              : data.choose_application.application_name
          }`
        : ""
    }${
      data.choose_application?.value !== "Data@Reckitt" && data.issue_type
        ? `\n||Issue type:: ${data.issue_type}`
        : ""
    }`;
    Object.keys(data).forEach((item) => {
      if (
        item === "choose_application" ||
        item === "description" ||
        item === "request_category" ||
        item === "screenshot" ||
        item === "issue_type"
      )
        return;
      if (Array.isArray(data[item])) {
        reqData += `\n||${item.replaceAll("_", " ")}:: ${data[item].join(
          ", "
        )}`;
      } else {
        reqData += `\n||${item.replaceAll("_", " ")}:: ${data[item]}`;
      }
    });
    return reqData;
  };
  const getJiraRaiseTicketData = (data) => {
    return {
      fields: {
        project: {
          key: "DRB",
        },
        issuetype: {
          id: "10201",
        },
        summary: data.request_category,
        description: getJiraDescription(data),
        reporter: {
          id:
            window.location?.origin?.includes("dev") ||
            window.location?.origin?.includes("localhost")
              ? "61baee7d89e8990071003173" // Bhavya's ID
              : window.location?.origin?.includes("uat")
              ? // ? "61b1ef122278e7006b96e33c" // harshit's ID
                "61b1ef12657a050070637868" // Deba's ID
              : "61b1ef13b0b630006a9826da", // shubh's ID
        },
        priority: {
          id: "3",
          name: "Medium",
          iconUrl:
            "https://rb-digital.atlassian.net/images/icons/priorities/medium.svg",
        },
        labels: [user],
        fixVersions: [],
      },
    };
  };
  const getMailPayload = (data, owners_list = [], ticket_no) => {
    if (data.request_category === "Request Feature") {
      return {
        req_category: "request_feature",
        description: data.description || "",
        emails: data.email_for_communication,
        feature_type: data.feature_type || "",
        gbu: data.gbu || [],
        optional_link: data.optional_reference,
        ticket_no,
      };
    } else {
      let payload = {
        req_category: "raise_incident",
        description: data.description || "",
        emails: data.email_for_communication,
        issue_type: data.issue_type || {},
        ticket_no,
      };
      if (data.choose_application?.value === "Registered Apps") {
        payload = {
          ...payload,
          app_owners: owners_list,
          app_name:
            data?.choose_application?.application_name === "Other"
              ? data?.choose_application?.other_application || ""
              : data?.choose_application?.application_name || "",
        };
      }
      return payload;
    }
  };

  const handleMailToOwners = (data, ticket_no = "") => {
    const reqApp = allAppList.data.find(
      (item) => item.app_name === data.choose_application?.application_name
    );
    const payload = getMailPayload(data, reqApp?.owners_list, ticket_no);
    dispatch(send_mail_to_app_owners(payload));
  };
  // trigger the attachemts api to save attachemts in jira
  const handleAttachments = async (data, key) => {
    const form = new FormData();
    form.append("file", data);
    try {
      const res = await fetchAPI2(form, key);
      const response = await res.json();
      // console.log("response from attachment", response);
    } catch (error) {
      toast.error("Opps! unable to add attachments. Please try later.");
    }
  };
  const getFeedbackPayload = (data) => {
    return {
      user_id: user,
      feedback_meta: {
        rating: data.rating?.rating,
        feedback_id: v4(),
        rating_comments: data.rating?.desc || [],
        rated_on: moment().valueOf(),
        comments: data.additional_feedback,
      },
    };
  };
  // checking if ticket is submitable
  const isRequestSubmitable = (data = formData) => {
    let submitable = true;
    if (data.request_category === "Provide Feedback") {
      if (data.rating?.rating) {
        submitable = false;
      }
    } else if (data.request_category === "Request Feature") {
      if (
        data.feature_type &&
        data.gbu?.length &&
        data.description &&
        data.description.length <= 1000
      ) {
        submitable = false;
      }
    } else if (data.request_category === "Raise Incident") {
      const { value, application_name, other_application } =
        data.choose_application || {};
      if (
        data.description &&
        data.description.length <= 1000 &&
        value &&
        (value !== "Registered Apps" ||
          (value === "Registered Apps" &&
            data.issue_type &&
            application_name &&
            (application_name !== "Other" ||
              (application_name === "Other" && other_application))))
      ) {
        return false;
      }
    }
    return submitable;
  };

  const handleSubmit = async (submitableData) => {
    dispatch(set_help_center_state({ loading: true }));
    if (submitableData.request_category === "Provide Feedback") {
      // for feedback
      try {
        const data = getFeedbackPayload(submitableData);
        const { statusMsg, isSuccess } = await fetchUpdateHelpCenterFeedback(
          data
        );
        if (isSuccess) {
          const payload_for_email = {
            ...data,
            req_category: submitableData.request_category,
          };
          // triggering the feedback email
          dispatch(send_mail_to_app_owners(payload_for_email));
          dispatch(
            set_help_center_state({
              openFeedbackModal: true,
              successMsg: statusMsg,
            })
          );
        } else {
          toast.error("Opps! unable to save feedback. Please try later.");
        }
      } catch (error) {
        toast.error("Opps! unable to save feedback. Please try later.");
      }
      dispatch(set_help_center_state({ loading: false }));
    } else {
      // for jira tickert i.e raise incident & request feature
      const data = getJiraRaiseTicketData(submitableData);
      try {
        const date = moment().valueOf();
        const res = await fetchAPI(data);
        const response = await res.json();
        if (!response.errors) {
          // trigger the email to app owners
          handleMailToOwners(submitableData, response?.key);
          // save the ticket details in backend
          dispatch(
            save_ticket_meta({
              user_id: user,
              ticket_meta: {
                created_on: date,
                ticket_id: response.id,
                ticket_key: response.key,
                modified_by: user,
                modified_on: date,
              },
            })
          );
          // triggering the api for attachments
          submitableData.screenshot?.forEach((item) => {
            handleAttachments(item, response.key);
          });
          dispatch(
            set_help_center_state({
              openFeedbackModal: true,
              successMsg: "Your Ticket has been Raised Successfully.",
            })
          );
        } else {
          toast.error("Opps! unable to create ticket. Please try later.");
        }
      } catch (error) {
        toast.error("Opps! unable to create ticket. Please try later.");
      }
      dispatch(set_help_center_state({ loading: false }));
    }
  };
  // triggering the api to get templates & app list
  useEffect(() => {
    if (templates.status === "pending") {
      dispatch(get_help_center_templates());
    }
    if (allAppList.status === "pending") {
      dispatch(get_app_list());
    }
    dispatch(set_help_center_state({ formData: defaultData }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, templates.status, allAppList.status]);

  useEffect(() => {
    if (allAppList.status === "success") {
      const reqList = ["Other"];
      (allAppList.data || []).forEach((item) => {
        if (!reqList.includes(item.app_name)) {
          reqList.push(item.app_name);
        }
      });
      dispatch(set_help_center_state({ appList: reqList }));
    }
  }, [allAppList, dispatch]);

  return (
    <LayoutTopSideBottom match={match} selected="/help">
      <NotificationModal
        open={openFeedbackModal}
        handleClose={handleClose}
        message={successMsg}
      />
      <Container className="help-center-container">
        <div
          className={`help-center-card ${
            theme === "dark" ? "help-center-card-dark" : ""
          }`}
        >
          <div className="help-center-title">
            Welcome to the Help Centre ! Raise a new ticket here, and we’ll
            solve it for you.
          </div>
          <div className="help-center-inner-container">
            {templates.status === "loading" && <NewTicketSkeletonLoader />}
            {templates.status === "success" && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormLabelComponent
                    label={templates.data.label}
                    required={true}
                    toolTipText={templates.data.toolTipText}
                  />
                  <DropDownComponent
                    options={templates.data?.options || {}}
                    keyField={templates.data.key}
                    defaultData={defaultData}
                  />
                </Grid>
                {(
                  templates.data.options[formData.request_category]?.data || []
                ).map((item) =>
                  getRequiredComponent(
                    item,
                    handleChange,
                    formData,
                    handleDelete
                  )
                )}
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                  <button
                    className="help-center-submit"
                    onClick={() => handleSubmit(formData)}
                    disabled={loading || isRequestSubmitable()}
                  >
                    {loading ? (
                      <Loader color="#fff" margin="0px 8px" />
                    ) : (
                      "Submit request"
                    )}
                  </button>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </Container>
    </LayoutTopSideBottom>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.preferred_username,
  templates: state.helpCenter.helpCenterTemplate,
  allApps: state.AppsData.allApps,
  helpCenterState: state.helpCenter.helpCenterState,
  allAppList: state.helpCenter.allAppList,
  theme: state.user.ui_preferences?.theme,
});
export default connect(mapStateToProps)(NewTicket);
