import React from "react";
import { Box, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SvgIcon from "@mui/material/SvgIcon";
import { set_help_center_state } from "../../../redux/actions";
const icons = {
    "Provide Feedback": <ThumbUpAltIcon />,
    "Raise Incident": <ErrorOutlineIcon />,
    "Request Feature": <SettingsSuggestIcon />,
};

const CustomRow = ({ value, options }) => (
    <Box display="flex" alignItems="center" gap={1.5}>
        <SvgIcon color="primary">{icons[value]}</SvgIcon>
        <div>
            <div className="help-center-drop-label">{value}</div>
            <div className="help-center-drop-desc">
                {options[value]?.description}
            </div>
        </div>
    </Box>
);

const DropDownComponent = ({
    helpCenterState,
    options,
    keyField,
    defaultData,
}) => {
    const { formData } = helpCenterState;
    const dispatch = useDispatch();
    const selectedValue = formData.request_category || "";
    const getReqData = () => {
        const reqData = _.omit(options, selectedValue);
        return reqData;
    };
    const handleChange = (e) => {
        const reqData = { ...defaultData, [keyField]: e.target.value };
        dispatch(set_help_center_state({ formData: reqData }));
    };

    return (
        <Select
            displayEmpty
            fullWidth
            size="small"
            onChange={handleChange}
            className="help-center-select-field"
            IconComponent={ExpandMoreIcon}
            MenuProps={{
                PaperProps: {
                    sx: (theme) => ({
                        bgcolor:
                            theme.palette.mode === "dark"
                                ? "#282828"
                                : "#f7f9fc",
                        backgroundImage: "none",
                        "& .MuiMenuItem-root:hover": {
                            backgroundColor: "transparent",
                        },
                        "& .MuiMenuItem-root:focus": {
                            backgroundColor: "transparent",
                        },
                        "& .help-center-drop-desc": {
                            color:
                                theme.palette.mode === "dark"
                                    ? "#ffffff"
                                    : "#282828",
                        },
                    }),
                },
            }}
            value={selectedValue}
            renderValue={(value) => {
                return (
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <CustomRow value={value} options={options} />
                    </Box>
                );
            }}
        >
            {Object.keys(getReqData())?.map((option) => (
                <MenuItem
                    key={option}
                    value={option}
                    className="help-center-select-dropdown"
                >
                    <CustomRow value={option} options={options} />
                </MenuItem>
            ))}
        </Select>
    );
};
const mapStateToProps = (state) => ({
    helpCenterState: state.helpCenter.helpCenterState,
    templates: state.helpCenter.helpCenterTemplate,
});
export default connect(mapStateToProps)(DropDownComponent);
