import { v4 } from "uuid";
import TemplateInputField from "../InputField";
import RadioBtns from "../RadioBtns";
import CheckBoxComponent from "../CheckBoxComponent";

// New Components
import SwitchField from "./Components/SwitchField";
import IconField from "./Components/IconField";
import DropDownField from "./Components/DropDownField";
import CheckBoxDropDown from "./Components/CheckBoxDropDown";

import iconList from "../../../../../assets/data/iconList.json";
import { config } from "../../../../config/config";
import TextEditor from "./Components/TextEditor";
import { getFilteredOptions, specialFeilds } from "../../../../utils/utils";
import MultipleEmailSelect from "./Components/MultipleEmailSelect";
import CustomAutoComponent from "../../../../components/AutoCompletes/CustomAutoComponents";
import { Grid } from "@mui/material";

import report_sample_image from "../../../../../assets/images/new-theme/report_sample_image.svg";

// import DoubleDropDown from "./Components/DoubleDropDown";

const customStyling = {
  "& .MuiTextField-root": { boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)" },
};

export const getTemplateNumberInString = (template_no) => {
  switch (template_no) {
    case 1:
      return "Template_1";
    case 2:
      return "Template_2";
    case 3:
      return "Template_3";
    case 4:
      return "Template_4";
    case 5:
      return "Template_5";
    default:
      return "Template_1";
  }
};
export const getTemplateStringInNumber = (template_no) => {
  switch (template_no) {
    case "Template_1":
      return 1;
    case "Template_2":
      return 2;
    case "Template_3":
      return 3;
    case "Template_4":
      return 4;
    case "Template_5":
      return 5;
    case "Template_6":
      return 6;
    case "Template_7":
      return 7;
    case "Template_8":
      return 8;
    case "Template_9":
      return 9;
    default:
      return template_no;
  }
};

export const getRequiredComponentValue = (childState, key) => {
  let val;
  switch (key) {
    case "header_text":
      val = childState.sub_category_name;
      break;
    case "sub_category_name":
      val = childState.sub_category_name;
      break;
    case "sub_category_description":
      val = childState.sub_category_description;
      break;
    case "header_description":
      val = childState.sub_category_description;
      break;
    default:
      val = "";
  }
  return val;
};

export const getFieldLabel = (type) => {
  let label_val = "";
  switch (type) {
    case "header":
      label_val = "Header text";
      break;
    case "sub_header":
      label_val = "Sub-header text";
      break;
    case "text":
      label_val = "Your text here";
      break;
    case "parentReport":
      label_val = "Horizontal report name";
      break;
    case "report":
      label_val = "Direct Report name";
      break;
    case "customHybrid":
      label_val = "Compound Widget name";
      break;
    case "hybrid":
      label_val = "Gateway Widget name";
      break;
    default:
      label_val = "Your text here";
      break;
  }
  return label_val;
};
export const getRequiredComponent = (
  item,
  state,
  handleChange,
  URLValid,
  widget_type,
  breadcrumbArr,
  renderSubReportArr,
  parent_report_type,
  editorState,
  handleEditorState,
  handleOpenDrawer,
  theme
) => {
  const data = {
    label: item.label,
    key: item.key,
    required: item.required,
    options: item.options,
  };

  switch (item.type) {
    case "input":
      return (
        <>
          {state.sub_category_type === "textBox" ||
          (specialFeilds.includes(state.sub_category_type) &&
            (item.key === "sub_category_name" ||
              item.key === "sub_category_description")) ? (
            <TextEditor
              editorState={editorState}
              handleEditorState={handleEditorState}
              widget_type={state.sub_category_type}
              data={{
                ...data,
                required: item.key === "sub_category_name" ? true : false,
              }}
              customOptions={
                parent_report_type === "parentReport"
                  ? ["inline", "colorPicker", "fontFamily"]
                  : null
              }
            />
          ) : (
            <TemplateInputField
              data={{
                ...data,
                required: item.key === "sub_category_name" ? true : false,
              }}
              localState={state}
              handleChange={handleChange}
              URLValid={URLValid}
              value={state[item.key] || ""}
              // id={state[0].sub_category_id || ""}
              required={true}
              width={
                item.key === "sub_category_description"
                  ? 12
                  : item.key === "url"
                  ? 9
                  : 6
              }
            />
          )}
        </>
      );
    case "dropdown":
      if (
        item.key !== "function" &&
        item.key !== "line_of_business" &&
        item.key !== "geography"
      ) {
        return (
          <DropDownField
            data={data}
            handleChange={handleChange}
            value={state[item.key] || ""}
            width={3}
            widget_type={widget_type}
            breadcrumbArr={breadcrumbArr}
            renderSubReportArr={renderSubReportArr}
            parent_report_type={parent_report_type}
          />
        );
      } else if (item.key === "geography") {
        return (
          <Grid item xs={3}>
            <div className={`heading ${theme}`}>
              {item.label} <span>*</span>:
            </div>
            <CustomAutoComponent
              data={data}
              selectedData={state[item.key] || []}
              handleSelection={handleChange}
              filteredOption={item.options}
              customStyling={customStyling}
            />
          </Grid>
        );
      } else
        return (
          <CheckBoxDropDown
            data={data}
            handleChange={handleChange}
            value={state[item.key] || ""}
            width={3}
          />
        );
    case "radio":
      return (
        <RadioBtns
          data={data}
          // handleChange={(e) => {
          //   handleChangeFieldVal(e, item, state);
          // }}
          value={getRequiredComponentValue(state, item.key) || ""}
          // isFieldsEditable={isFieldsEditable}
          // theme={theme}
        />
      );
    case "url":
      return (
        <IconField
          data={data}
          handleChange={handleChange}
          required={state.sub_category_type === "image" ? true : false}
          sub_category_icon={state.sub_category_icon || ""}
          app_logo_type={state.app_logo_type || ""}
          item={item}
          state={state}
          widgetType={state.sub_category_type}
          handleOpenDrawer={handleOpenDrawer}
        />
      );
    case "checkbox":
      return (
        <CheckBoxComponent
          data={data}
          // handleChange={(e) => {
          //   handleChangeFieldVal(e, item, state);
          // }}
          value={getRequiredComponentValue(state, item.key) || ""}
          width={3}
          // isFieldsEditable={isFieldsEditable}
        />
      );
    case "switch":
      return (
        <SwitchField
          data={data}
          handleChange={handleChange}
          value={state[item.key] || false}
          width={3}
          // isFieldsEditable={isFieldsEditable}
        />
      );
    case "multiple":
      return (
        <MultipleEmailSelect
          handleChange={handleChange}
          values={state[item.key] || []}
          // options={options}
          // handleDelete={handleDelete}
          data={data}
          width={12}
        />
      );
    default:
      return "";
  }
};

export const getInitialState = (
  sub_category_id,
  sub_category_name,
  sub_category_description,
  position_meta,
  sub_category_type,
  sub_category_icon = "dashboard"
) => ({
  additional_images: [],
  app_logo_type: "google_font",
  bi_application: "",
  blob_name: "analytics",
  contains_sub_category: true,
  show_description: false,
  display_subtiles: false,
  embedded_report: false,
  position_meta: position_meta || {
    h: 2,
    i: sub_category_id,
    w: 3,
    x: 0,
    y: 0,
  },
  sub_category: [],
  sub_category_description,
  sub_category_icon,
  sub_category_id,
  sub_category_name,
  sub_category_type,
  url: "",
  view_count: 0,
});

export const getInitialStateforNewTemplates = (
  sub_category_id,
  sub_category_name,
  sub_category_description,
  sub_category_type = "report",
  sub_category_image = "",
  fields,
  userEmail
) => ({
  bi_application: "Power BI",
  show_description: false,
  sub_category_description,
  function: [...fields.app_details.function],
  line_of_business: [...fields.app_details.line_of_business],
  business_owners: [userEmail],
  technical_owners: [userEmail],
  geography: [...fields.app_details.geography],
  sub_category_id,
  sub_category_name,
  sub_category_type,
  sub_category_img_data: {
    img_url: sub_category_image,
    blob_name: "",
    selected_size: "fit",
  },
  url: "",
  view_count: 0,
  additional_images: [],
  app_logo_type: "google_font",
  blob_name: "analytics",
  // position_meta: position_meta || {
  //   h: 2,
  //   i: sub_category_id,
  //   w: 3,
  //   x: 0,
  //   y: 0,
  // },
  sub_category_icon: "dashboard",
});

const randomIcons = [
  "verified",
  "manage_accounts",
  "shopping_basket",
  "visibility",
  "calendar_today",
  "shopping_cart",
  "analytics",
  "date_range",
  "question_answer",
  "login",
  "hourglass_empty",
  "explore",
  "card_giftcard",
  "article",
  "question_answer",
  "paid",
  "task_alt",
  "lightbulb",
  "shopping_bag",
  "open_in_new",
  "perm_identity",
  "trending_up",
  "history",
  "credit_card",
  "account_balance",
  "delete_outline",
  "report_problem",
];
const defaultTemp3PositionMeta = [
  { h: 2, w: 8, x: 0, y: 0 },
  { h: 2, w: 8, x: 8, y: 0 },
  { h: 2, w: 8, x: 16, y: 0 },
  { h: 2, w: 4, x: 0, y: 0 },
  { h: 2, w: 4, x: 4, y: 0 },
  { h: 2, w: 4, x: 8, y: 0 },
  { h: 2, w: 4, x: 12, y: 0 },
  { h: 2, w: 4, x: 16, y: 0 },
  { h: 2, w: 4, x: 20, y: 0 },
  { h: 2, w: 4, x: 0, y: 2 },
  { h: 2, w: 4, x: 4, y: 2 },
  { h: 2, w: 4, x: 8, y: 2 },
  { h: 2, w: 4, x: 12, y: 2 },
  { h: 2, w: 4, x: 16, y: 2 },
  { h: 2, w: 4, x: 20, y: 2 },
  { h: 2, w: 4, x: 0, y: 4 },
  { h: 2, w: 4, x: 4, y: 4 },
  { h: 2, w: 4, x: 8, y: 4 },
  { h: 2, w: 4, x: 12, y: 4 },
  { h: 2, w: 4, x: 16, y: 4 },
  { h: 2, w: 4, x: 20, y: 4 },
];
const defaultTemp2PositionMeta = [
  { x: 0, y: 0, w: 4, h: 2 },
  { x: 0, y: 2, w: 4, h: 2 },
  { x: 4, y: 0, w: 8, h: 4 },
  { x: 12, y: 0, w: 6, h: 3 },
  { x: 18, y: 0, w: 6, h: 3 },
  { x: 0, y: 4, w: 8, h: 4 },
  { x: 12, y: 3, w: 6, h: 3 },
  { x: 18, y: 3, w: 6, h: 3 },
  { x: 8, y: 4, w: 4, h: 2 },
  { x: 8, y: 6, w: 4, h: 2 },
  { x: 12, y: 6, w: 4, h: 2 },
  { x: 16, y: 6, w: 4, h: 2 },
  { x: 20, y: 6, w: 4, h: 2 },
];
export const getDefaultTemp2Data = () => {
  let reqData = [
    getInitialState(
      v4(),
      config.hardCoded.your_header,
      config.hardCoded.your_discription,
      "",
      "header"
    ),
  ];
  defaultTemp2PositionMeta.forEach((item, i) => {
    const Id = v4();
    const position_meta = {
      ...item,
      i: Id,
    };
    reqData.push(
      getInitialState(
        Id,
        `${config.hardCoded.untitled_card}${i + 1}`,
        config.hardCoded.your_discription,
        position_meta,
        "hybrid",
        randomIcons[i]
      )
    );
  });
  return reqData;
};
export const getDefaultTemp3Data = () => {
  let reqData = [
    getInitialState(
      v4(),
      config.hardCoded.your_header,
      config.hardCoded.your_discription,
      "",
      "header"
    ),
  ];
  defaultTemp3PositionMeta.forEach((item, i) => {
    const Id = v4();
    const position_meta = {
      ...item,
      i: Id,
    };
    if (i < 3) {
      reqData.push(
        getInitialState(
          Id,
          `${config.hardCoded.your_subheader}${i + 1}`,
          config.hardCoded.your_discription,
          position_meta,
          "sub_header",
          iconList[i + 20]
        )
      );
    } else {
      reqData.push(
        getInitialState(
          Id,
          `${config.hardCoded.untitled_card}${i - 2}`,
          config.hardCoded.your_discription,
          position_meta,
          "hybrid",
          iconList[i + 20]
        )
      );
    }
  });
  return reqData;
};

export const getDefaultTemp4Data = () => {
  const reqData = [
    getInitialState(
      v4(),
      config.hardCoded.your_header,
      config.hardCoded.your_discription,
      "",
      "header"
    ),
  ];
  new Array(3).fill(0).forEach((_, i) => {
    const ID = v4();
    let category = getInitialState(
      ID,
      `${config.hardCoded.horizontal_report}${i + 1}`,
      config.hardCoded.your_discription,
      "",
      "parentReport"
    );
    const sub_category = [];
    reqData.push();
    new Array(7).fill(0).forEach((_, ind) => {
      if (i % 2 === 1 && ind === 6) return;
      const id = v4();
      sub_category.push(
        getInitialState(
          id,
          `${config.hardCoded.untitled_card}${ind + 1}`,
          config.hardCoded.your_discription,
          "",
          "hybrid",
          //random icon from list
          iconList[15 * i + ind]
        )
      );
    });
    category = { ...category, sub_category };
    reqData.push(category);
  });
  return reqData;
};

const getDefaultTemp1Data = (fields) => {
  return [
    {
      sub_category_id: v4(),
      sub_category_name: "",
      isCatEditable: true,
      sub_category: [
        {
          sub_category_id: v4(),
          sub_category_name: "",
          isSubCatEditable: true,
          sub_category: [
            {
              app_logo_type: "google_font",
              bi_application: "Power BI",
              blob_name: "",
              isSubCatEditable: true,
              sub_category_icon: "space_dashboard",
              sub_category_id: v4(),
              sub_category_name: "",
              url: "",
              view_count: 0,
            },
          ],
        },
      ],
    },
  ];
};

export const getDefaultTemp6Data = (count = 10, fields, user_id) => {
  let imgData = {
    sub_category_id: v4(),
    sub_category_type: "image",
    sub_category_img_data: {
      img_url: report_sample_image,
      blob_name: "",
      selected_size: "fit",
    },
  };
  let textData = {
    sub_category_id: v4(),
    sub_category_type: "text",
    sub_category_description: "Your sample text content comes here.",
  };
  let reqData = [imgData, textData];
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  arr.slice(0, count).forEach((item) => {
    reqData.push(
      getInitialStateforNewTemplates(
        v4(),
        "Untitled Report - " + item,
        config.hardCoded.your_discription,
        "report",
        count === 10 ? report_sample_image : "",
        fields,
        user_id
      )
    );
  });
  return reqData;
};

export const getDefaultTempData = (temp_no, fields, user_id) => {
  if (temp_no === "Template_1" || temp_no === 1) {
    return getDefaultTemp1Data(fields);
  } else if (temp_no === "Template_4" || temp_no === 4) {
    return getDefaultTemp4Data();
  } else if (temp_no === "Template_3" || temp_no === 3) {
    return getDefaultTemp3Data();
  } else if (temp_no === "Template_2" || temp_no === 2) {
    return getDefaultTemp2Data();
  } else if (temp_no === "Template_5" || temp_no === 5) {
    return [];
  } else if (temp_no === "Template_7" || temp_no === 7) {
    return getDefaultTemp6Data(10, fields, user_id);
  } else if (temp_no === "Template_6" || temp_no === 6) {
    return getDefaultTemp6Data(15, fields, user_id);
  } else if (temp_no === "Template_8" || temp_no === 8) {
    return getDefaultTemp6Data(10, fields, user_id);
  } else if (temp_no === "Template_9" || temp_no === 9) {
    return getDefaultTemp6Data(10, fields, user_id);
  }
};
// To hide fields on the basis of WIDGET TYPE
export const handleHideComponent = (
  key,
  reportType,
  state,
  template_type = "old"
) => {
  let val;
  switch (reportType) {
    case "report":
      if (template_type === "new") {
        val = [
          "sub_category_type",
          "display_subtiles",
          "contains_sub_category",
          "app_name",
          "associated_datasets",
          "tags",
          "app_logo",
          "description",
          "show_sidebar",
          "show_commentsviews",
          "app_category",
        ].includes(key);
      } else {
        val = [
          "display_subtiles",
          "contains_sub_category",
          "app_name",
          "associated_datasets",
          "tags",
          "app_logo",
          "description",
          "show_sidebar",
          "show_commentsviews",
          "app_category",
        ].includes(key);
      }
      break;
    case "header":
      val = [
        "contains_sub_category",
        "bi_application",
        "display_subtiles",
        "url",
        "show_description",
        "business_owners",
        "technical_owners",
      ].includes(key);
      break;
    case "customHybrid":
      val = [
        "contains_sub_category",
        "bi_application",
        "display_subtiles",
        "url",
        "show_description",
        "business_owners",
        "technical_owners",
      ].includes(key);
      break;
    case "sub_header":
      val = [
        "contains_sub_category",
        "bi_application",
        "display_subtiles",
        "url",
        "business_owners",
        "technical_owners",
      ].includes(key);
      break;
    case "hybrid":
      val = [
        "contains_sub_category",
        "bi_application",
        "display_subtiles",
        "url",
        "business_owners",
        "technical_owners",
      ].includes(key);
      break;
    case "parentReport":
      val = [
        "contains_sub_category",
        "bi_application",
        "display_subtiles",
        "url",
        "show_description",
        "sub_category_icon",
        "business_owners",
        "technical_owners",
      ].includes(key);
      break;
    case "textBox":
      val = [
        "contains_sub_category",
        "sub_category_icon",
        "sub_category_description",
        "bi_application",
        "display_subtiles",
        "url",
        "show_description",
        "business_owners",
        "technical_owners",
      ].includes(key);
      break;
    case "image":
      val = [
        "contains_sub_category",
        "sub_category_description",
        "sub_category_name",
        "bi_application",
        "display_subtiles",
        "url",
        "show_description",
        "business_owners",
        "technical_owners",
      ].includes(key);
      break;
    default:
      val = false;
  }
  return val;
};

export const handleGetErrorMessage = (url_type) => {
  if (url_type === "Power Apps") {
    return "Please Enter valid Power Apps url";
  } else if (url_type === "Other Applications") {
    return "Please Enter valid url";
  } else {
    return `Please Enter valid ${url_type} embedded url`;
  }
};
