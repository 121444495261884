import React from "react";
import finishedLogo from "../../../assets/images/setup-images/finish_logo.svg";
import { useHistory } from "react-router";

const FinalScreen = ({ setActiveScreen, tabName }) => {
  const history = useHistory();
  return (
    <>
      <div>
        <div className="setup-heading">You are all set! </div>
      </div>
      <img src={finishedLogo} alt="" />
      <button
        className="setup-btn"
        onClick={() => {
          tabName
            ? history.push("/profile-management")
            : history.push("/myapps");
        }}
      >
        Start exploring
      </button>
    </>
  );
};

export default FinalScreen;
