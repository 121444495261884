import React, { useMemo } from "react";
import { connect } from "react-redux";
import CommonReport from "../Components/CommonReport";
import ParentReportType from "../Components/ParentReportType";
import LandingTemplate from "../Components/LandingTemp4";
import "../styles/template4.scss";

const Template4 = ({ data = [], handleNavigation }) => {
    const typeOfReport = useMemo(() => {
        const reportType = [];
        data.forEach((item) => reportType.push(item.sub_category_type));
        return reportType;
    }, [data]);

    return (
        <>
            {typeOfReport.includes("parentReport") ? (
                <ParentReportType reportData={data} />
            ) : typeOfReport.includes("customHybrid") ? (
                <LandingTemplate
                    reportData={data}
                    handleNavigation={handleNavigation}
                />
            ) : (
                <CommonReport handleNavigation={handleNavigation} data={data} />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    theme: state.user.ui_preferences?.theme,
    appDashboardState: state.appDashboard.appDashboardState,
    appTemplateState: state.appTemplate.appTemplateState.templateData,
});

export default connect(mapStateToProps)(Template4);
