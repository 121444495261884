import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import FieldComponent from "./FieldComponent";
import "./index.scss";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import format from "date-fns/format";
import DateRangeComponent from "./DateRangeComponent";
import { set } from "lodash";

const data2 = [
  {
    // label: "Select Time Range :",
    options: ["Last 7 Days", "Last 14 Days", "This Month"],
    key: "time_range",
  },
];

const UserMatrixFilter = ({
  timestampRange,
  SetTimestampRange,
  filters,
  setFilters,
  app_type,
  fetchUsageReportData,
  theme,
  setSelectedChip,
  published_timestamp,
  open,
  refTow,
  timeStampObject,
}) => {
  useEffect(() => {
    if (filters.usersList.length > 1 && filters.chart_type !== "Stack Chart") {
      setFilters({ ...filters, chart_type: "Stack Chart" });
    } else if (
      filters.usersList.length <= 1 &&
      filters.chart_type === "Stack Chart"
    ) {
      setFilters({ ...filters, chart_type: "Bar Chart" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.usersList]);

  const getFormatedTime = () => {
    const currentDate = new Date();
    const oneDayAgo = new Date(currentDate);
    oneDayAgo.setDate(currentDate.getDate() - 1);

    oneDayAgo.getTime();
    const day = oneDayAgo.getUTCDate();
    const month = oneDayAgo.getMonth();
    const year = oneDayAgo.getFullYear();

    const result = `${day}/${month + 1}/${year} 23:59 (UTC)`;
    return result;
  };
  return (
    <>
      <div>
        <div
          style={{
            position: "relative",
          }}
        >
          <div>
            <div
              ref={refTow}
              style={{
                display: "flex",
                top: "116px",
                right: "15px",
                position: "absolute",
                zIndex: "1000",
              }}
            >
              <div
                style={{
                  width: "70%",
                }}
              >
                <DateRangeComponent
                  timestampRange={timestampRange}
                  SetTimestampRange={SetTimestampRange}
                  filters={filters}
                  fetchUsageReportData={fetchUsageReportData}
                  theme={theme}
                  setSelectedChip={setSelectedChip}
                  published_timestamp={published_timestamp}
                  open={open}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              fontFamily: "Energy",
              fontSize: "14px",
              color: "#0158aa",
              width: "300px",
            }}
          >
            Data Last Updated at: {getFormatedTime()}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserMatrixFilter;
