// Import Images
import template_1 from "../../../../../assets/images/new-theme/register/light-theme/template_1.svg";
import template_2 from "../../../../../assets/images/new-theme/register/light-theme/template_2.svg";
import template_3 from "../../../../../assets/images/new-theme/register/light-theme/template_3.svg";
import template_4 from "../../../../../assets/images/new-theme/register/light-theme/template_4.svg";
import template_6 from "../../../../../assets/images/new-theme/register/light-theme/template_6.svg";
import template_7 from "../../../../../assets/images/new-theme/register/light-theme/template_7.svg";
import template_8 from "../../../../../assets/images/new-theme/register/light-theme/template_8.svg";
import template_9 from "../../../../../assets/images/new-theme/register/light-theme/template_9.svg";
import template_1_dark from "../../../../../assets/images/new-theme/register/dark-theme/template_1.svg";
import template_2_dark from "../../../../../assets/images/new-theme/register/dark-theme/template_2.svg";
import template_3_dark from "../../../../../assets/images/new-theme/register/dark-theme/template_3.svg";
import template_4_dark from "../../../../../assets/images/new-theme/register/dark-theme/template_4.svg";
import template_6_dark from "../../../../../assets/images/new-theme/register/dark-theme/template_6.svg";
import template_7_dark from "../../../../../assets/images/new-theme/register/dark-theme/template_7.svg";
import template_8_dark from "../../../../../assets/images/new-theme/register/dark-theme/template_8.svg";
import template_9_dark from "../../../../../assets/images/new-theme/register/dark-theme/template_9.svg";

const templateData = [
  {
    template_no: 1,
    template_id: "template_1",
    template_img: { light: template_1, dark: template_1_dark },
    template_data: [],
    label: "Left navigation with report on right",
    preview_link: "https://data.reckitt.com/01a9ec65-6d53-4-active_published", //done
  },
  {
    template_no: 2,
    template_id: "template_2",
    template_img: { light: template_2, dark: template_2_dark },
    template_data: [],
    label: "Resizable elements",
    preview_link: "https://data.reckitt.com/df5939cd-4d7e-4-active_published", //done
  },
  {
    template_no: 3,
    template_id: "template_3",
    template_img: { light: template_3, dark: template_3_dark },
    template_data: [],
    label: "Horizontal Sections",
    preview_link: "https://data.reckitt.com/a059fa64-facf-4-active_published", //done
  },
  {
    template_no: 4,
    template_id: "template_4",
    template_img: { light: template_4, dark: template_4_dark },
    template_data: [],
    label: "Vertical Sections",
    preview_link: "https://data.reckitt.com/ff870d7d-2d5b-4-active_published", //done
  },
  {
    template_no: 6,
    template_id: "template_6",
    template_img: { light: template_6, dark: template_6_dark },
    template_data: [],
    label: "Tiles with no images",
    preview_link: "https://data.reckitt.com/1c0812e4-18e8-4-active_published", //done
  },
  {
    template_no: 7,
    template_id: "template_7",
    template_img: { light: template_7, dark: template_7_dark },
    template_data: [],
    label: "Tiles with images",
    preview_link: "https://data.reckitt.com/e6f877da-b1aa-4-active_published", //done
  },
  {
    template_no: 8,
    template_id: "template_8",
    template_img: { light: template_8, dark: template_8_dark },
    template_data: [],
    label: "Hybrid Tiles",
    preview_link: "https://data.reckitt.com/fbd000da-4e0e-4-active_published", //done
  },
  {
    template_no: 9,
    template_id: "template_9",
    template_img: { light: template_9, dark: template_9_dark },
    template_data: [],
    label: "Long list with descriptions",
    preview_link: "https://data.reckitt.com/db31c891-a559-4-active_published", //done
  },
];

export default templateData;
