import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  Tooltip,
  Dialog,
  IconButton,
  DialogContent,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import SelectComponent from "../SelectComponents";
import RadioBtns from "../RadioBtns";
// import UrlBtn from "../UrlBtn";
import SelectLogo from "../../../Register/components/SelectLogo";
import choose_icon from "../../../../../assets/images/choose_icon.svg";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import runError from "../../../../../assets/images/register-images/run-error.svg";
import runSuccess from "../../../../../assets/images/register-images/run-success.svg";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import {
  isUrlMatch,
  removeReportField,
  updateSubState,
} from "../../../../utils/utils";
import "../../index.scss";
import { BootstrapInput } from "../../style";
import { update_BIappDetails } from "../../../../redux/actions";
// Import custom hooks
import { useNonInitialEffect } from "../../../../hooks/useNonInitialEffect";

const list = [
  "Navigate to Sharepoint, and find the file you want to embed. If it's not in Sharepoint, you need to upload the file to Sharepoint.",
  "With the file open, Click on 'File' at top left.",
  'Click on option "Share".',
  'Choose the option "Embed".',
  "Choose the desired Appearance and Interaction options.",
  'Click "Copy Link"',
  "Copy the src URL from the embedded Iframe code.",
];

const statusIconStyle = {
  width: "30px",
  height: "30px",
};

const InputFormField = (props) => {
  const {
    handleChange,
    value,
    data,
    detailsPage,
    width,
    subCategories,
    URLValid,
    setURLValid,
    isFieldVibrating,
    isFieldRed,
    app_id,
    theme
  } = props;
  const { label, key, required } = data;
  const eventTracker = useAnalyticsEventTracker("Categories", app_id);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleInfo = () => {
    setOpen(true);
    eventTracker("Embedded url info clicked", subCategories.bi_application);
  };

  const handleInputChange = (k, v) => {
    if (k === "url") {
      setURLValid({ status: "pending", msg: "" });
      handleChange(k, v);
    } else {
      handleChange(k, v);
    }
  };

  const handleGetErrorMessage = (url_type) => {
    if (url_type === "Power Apps") {
      return "Please Enter valid Power Apps url";
    } else if (url_type === "Other Applications") {
      return "Please Enter valid url";
    } else {
      return `Please Enter valid ${url_type} embedded url`;
    }
  };

  const handleBlur = useCallback(
    (value) => {
      const handleURLValidation = (val) => {
        if (!val.startsWith("https://")) {
          setURLValid({
            status: "Invalid",
            msg: "URL should start with (https://)",
          });
        } else if (!val.slice(8).trim().length) {
          setURLValid({
            status: "Invalid",
            msg: "Please Enter valid url",
          });
        } else if (!isUrlMatch(subCategories.bi_application, val)) {
          setURLValid({
            status: "Invalid",
            msg: handleGetErrorMessage(subCategories.bi_application),
          });
        } else {
          setURLValid({
            status: "Valid",
            msg: "",
          });
        }
      };
      if (key === "url" && value) {
        handleURLValidation(value);
      }
      return;
    },
    [key, setURLValid, subCategories.bi_application]
  );

  useEffect(() => {
    if (!value) return;
    handleBlur(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleBlur, subCategories.bi_application]);

  return (
    <Grid item xs={width || 4} key={key}>
      <div className={`heading ${theme}`}>
        {key === "sub_category_name" ? "Report Name" : label}{" "}
        <span style={{ display: required ? "" : "none" }}>*</span>:
      </div>
      <Box display="flex" alignItems="center">
        <BootstrapInput
          onChange={(e) => handleInputChange(key, e.target.value.trimStart())}
          style={{ width: "95%" }}
          value={value}
          onBlur={(e) => handleBlur(e.target.value)}
          multiline={detailsPage}
          placeholder={
            key === "url"
              ? subCategories.bi_application === "Power Apps" ||
                subCategories.bi_application === "Other Applications"
                ? "Enter the URL of the dashboard/application"
                : "Enter the embedded URL of the dashboard/report"
              : key === "sub_category_name"
              ? "Enter report name"
              : `Enter ${label}`
          }
          fullWidth
          rows={detailsPage ? 4 : 1}
          className={`${isFieldVibrating && "shake-category-field"} ${
            isFieldRed && "error"
          }`}
        />
        {key === "url" && URLValid.status === "Valid" ? (
          <img
            src={runSuccess}
            style={statusIconStyle}
            title="Valid URL"
            alt="Success"
          />
        ) : key === "url" && URLValid.status === "Invalid" ? (
          <img
            src={runError}
            style={statusIconStyle}
            title="Invalid URL"
            alt="Failed"
          />
        ) : null}
        {key === "url" && (
          <Tooltip placement="top" title="Click to know more">
            <HelpOutlineIcon
              style={{ fontSize: "15px", marginLeft: "10px" }}
              onClick={handleInfo}
              className="GA-url-info"
              id={subCategories.bi_application}
            />
          </Tooltip>
        )}
      </Box>
      {URLValid.status === "Invalid" && key === "url" && (
        <div className="validation_msg" style={{ color: "red" }}>
          {URLValid.msg}
        </div>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="md"
      >
        <div className="info-content">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div className="sub-title">
              Steps to get {subCategories.bi_application} embedded url
            </div>
            <IconButton onClick={handleClose}>
              <span class="material-icons">close</span>
            </IconButton>
          </Box>
          {subCategories.bi_application === "Google DataStudio" ? (
            <>
              <p>1. Sign in to Data Studio.</p>
              <p>2. Edit the report you want to embed.</p>
              <p>3. Share the report:</p>
              <p>
                <ul>
                  <li>
                    For a public report, use the link sharing options "Public on
                    the web" or "Anyone with the link can view"
                  </li>
                  <li>
                    For a private report, enter one or more users or Google
                    Groups
                  </li>
                  <li>
                    Google Workspace users: To limit the report to users in your
                    domain, turn link sharing on and select the "Anyone at
                    [domain] with the link can view"
                  </li>
                </ul>
              </p>
              <p>
                4. Select File {">"} Embed report, or in the upper right, click
                Embed.
              </p>
              <p>5. Click Enable embedding. A dialog box appears</p>
              <p>6. Select Embed URL.</p>
              <p>7. Click COPY TO CLIPBOARD.</p>
              <a
                href="https://support.google.com/datastudio/answer/7450249?hl=en#zippy=%2Cin-this-article"
                target="_blank"
                rel="noreferrer"
                className="GA-read-more-link"
                id={subCategories.bi_application}
              >
                Read more
              </a>
            </>
          ) : subCategories.bi_application === "Sharepoint" ? (
            <>
              {list.map((item, i) => (
                <p>{`${i + 1}.  ${item}`}</p>
              ))}
              <p>
                8. Example: {"<"}iframe width="402" height="346" frameborder="0"
                scrolling="no" src=
                <span style={{ fontWeight: "bold" }}>
                  "https://rbcom-my.sharepoint.com/personal/xyz_com/_layouts/15/Doc.aspx?sourcedoc=1234&action=embedview&AllowTyping=True&wdDownloadButton=True&wdInConfigurator=True"
                </span>
                {">"}
                {"<"}/iframe{">"}
              </p>
            </>
          ) : subCategories.bi_application === "Power BI" ? (
            <>
              <p>1. Open a report in the Power BI service.</p>
              <p>
                2. On the File menu, select Embed report {">"} Website or
                portal.
              </p>
              <p>
                3. In the Secure embed code dialog, select the link you can use
                to embed this content.
              </p>
              <a
                href="https://docs.microsoft.com/en-us/power-bi/collaborate-share/service-embed-secure"
                target="_blank"
                rel="noreferrer"
                className="GA-read-more-link"
                id={subCategories.bi_application}
              >
                Read more
              </a>
            </>
          ) : (
            <>
              <p>Place the web link for your Power App application</p>
            </>
          )}
        </div>
      </Dialog>
    </Grid>
  );
};

const UrlBtn = (props) => {
  const {
    data,
    width,
    value,
    handleChange,
    app_logo_type,
    url,
    isFieldsEditable,
    theme,
    upload_for,
  } = props;
  const { label, required, key } = data;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <Grid item xs={width || 6} key={key} sx={{ alignItems: "end" }}>
      <Box display="flex" alignItems="flex-end">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="space-between"
          height="100%"
          width="70%"
          maxWidth="180px"
        >
          <div className={`heading ${theme}`}>
            {label} <span style={{ display: required ? "" : "none" }}>*</span>:
          </div>
          <button
            // className="urlBtn"
            className={`btn-chooseIcon ${theme}-btn-chooseIcon GA-choose-icon`}
            onClick={handleOpen}
            disabled={isFieldsEditable}
            style={{ width: "100%" }}
          >
            <span class="material-icons-outlined GA-choose-icon">
              add_to_photos
            </span>
            <span className="inner-content GA-choose-icon">Choose Icon</span>
          </button>
        </Box>
        {app_logo_type === "uploaded_image" ? (
          <div>
            <img
              src={url || value}
              alt="uploaded icon"
              style={{
                marginLeft: "20px",
                width: "55px",
              }}
            />
          </div>
        ) : (
          <span
            class="material-icons-outlined"
            style={{
              fontSize: "64px",
              marginLeft: "1rem",
              // color: "#282828",
              color: "#8d9ca6",
              marginBottom: "-5px",
            }}
          >
            {value || "format_align_center"}
          </span>
        )}
      </Box>
      <SelectLogo
        data={data}
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleChange={handleChange}
        upload_for={upload_for}
      />
    </Grid>
  );
};

const ReportField = (props) => {
  const {
    templates,
    categoryInfo,
    // setCategoryInfo,
    defaultValue,
    theme,
    isVibrating,
    isFieldCardRed,
    appDetails,
    // setCategoryInfoLocal,
    categoryInfoLocal,
    catIndex,
    subCatIndex = -1,
    reportIndex,
  } = props;
  const dispatch = useDispatch();
  const { label, required, fields } = templates;
  const [URLValid, setURLValid] = useState({ status: "pending", msg: "" });
  const [vibrateField, setVibrateField] = useState(false);
  const [isTextFieldRed, setIsTextFieldRed] = useState(false);
  const subCategories =
    subCatIndex > -1
      ? categoryInfoLocal[catIndex].sub_category[subCatIndex].sub_category[
          reportIndex
        ]
      : categoryInfoLocal[catIndex].sub_category[reportIndex];

  const removeVibration = () => {
    setTimeout(() => {
      setVibrateField(false);
      setIsTextFieldRed(true);
    }, 1000);
  };

  const handleChange = (key, value, blob_name, app_logo_type) => {
    if (key === "sub_category_name" && value && value.length > 50) return;
    if (key === "sub_category_icon") {
      const reqData = {
        [key]: value,
        blob_name,
        app_logo_type,
      };
      // setCategoryInfoLocal(
      //   updateSubState(
      //     categoryInfoLocal,
      //     catIndex,
      //     subCatIndex,
      //     reqData,
      //     reportIndex
      //   )
      // );
      dispatch(
        update_BIappDetails({
          categoryInfoLocal: updateSubState(
            categoryInfoLocal,
            catIndex,
            subCatIndex,
            reqData,
            reportIndex
          ),
        })
      );
    } else {
      const reqData = { [key]: key === "url" ? value.trim() : value };
      // setCategoryInfoLocal(
      //   updateSubState(
      //     categoryInfoLocal,
      //     catIndex,
      //     subCatIndex,
      //     reqData,
      //     reportIndex
      //   )
      // );
      dispatch(
        update_BIappDetails({
          categoryInfoLocal: updateSubState(
            categoryInfoLocal,
            catIndex,
            subCatIndex,
            reqData,
            reportIndex
          ),
        })
      );
    }
  };

  const handleDone = async () => {
    setIsTextFieldRed(false);
    if (
      !subCategories.sub_category_name ||
      !subCategories.bi_application ||
      !subCategories.url ||
      URLValid.status === "Invalid"
    ) {
      setVibrateField(true);
      removeVibration();
      return;
    }
    const reqData =
      subCatIndex > -1
        ? {
            ...categoryInfo[catIndex].sub_category[subCatIndex].sub_category[
              reportIndex
            ],
            ...subCategories,
            isSubCatEditable: false,
          }
        : {
            ...categoryInfo[catIndex].sub_category[reportIndex],
            ...subCategories,
            isSubCatEditable: false,
          };
    // setCategoryInfo(
    //   updateSubState(categoryInfo, catIndex, subCatIndex, reqData, reportIndex)
    // );
    dispatch(
      update_BIappDetails({
        categoryInfo: updateSubState(
          categoryInfo,
          catIndex,
          subCatIndex,
          reqData,
          reportIndex
        ),
      })
    );
  };

  const handleClose = () => {
    // checking if this new or old one => if new than delete it & if old one than didn't update it
    if (!defaultValue.sub_category_name || !defaultValue.url) {
      // setCategoryInfo(
      //   removeReportField(categoryInfo, catIndex, subCatIndex, reportIndex)
      // );
      // setCategoryInfoLocal(
      //   removeReportField(categoryInfoLocal, catIndex, subCatIndex, reportIndex)
      // );
      dispatch(
        update_BIappDetails({
          categoryInfo: removeReportField(
            categoryInfo,
            catIndex,
            subCatIndex,
            reportIndex
          ),
          categoryInfoLocal: removeReportField(
            categoryInfoLocal,
            catIndex,
            subCatIndex,
            reportIndex
          ),
        })
      );
    } else {
      const reqData =
        subCatIndex > -1
          ? {
              ...categoryInfo[catIndex].sub_category[subCatIndex].sub_category[
                reportIndex
              ],
              isSubCatEditable: false,
            }
          : {
              ...categoryInfo[catIndex].sub_category[reportIndex],
              isSubCatEditable: false,
            };
      const updatedData = updateSubState(
        categoryInfo,
        catIndex,
        subCatIndex,
        reqData,
        reportIndex
      );
      // setCategoryInfo(updatedData);
      // setCategoryInfoLocal(updatedData);
      dispatch(
        update_BIappDetails({
          categoryInfo: updatedData,
          categoryInfoLocal: updatedData,
        })
      );
    }
  };

  const getRequiredComponent = (data) => {
    const { type, key } = data;
    let width = null;
    if (key === "url" || key === "sub_category_icon") {
      width = 8;
    }
    switch (type) {
      case "input":
        return (
          <InputFormField
            data={data}
            handleChange={handleChange}
            value={subCategories[key]}
            width={width}
            subCategories={subCategories}
            URLValid={URLValid}
            setURLValid={setURLValid}
            isFieldVibrating={Boolean(vibrateField && !subCategories[key])}
            isFieldRed={isTextFieldRed && !subCategories[key]}
            app_id={appDetails.data.app_id}
            theme={theme}
          />
        );
      case "dropdown":
        return (
          <SelectComponent
            data={data}
            handleChange={handleChange}
            appFields={subCategories[key]}
            width={4}
            value={subCategories[key]}
            theme={theme}
          />
        );
      case "radio":
        return (
          <RadioBtns
            data={data}
            handleChange={handleChange}
            value={subCategories[key]}
            app_logo_type={subCategories.app_logo_type}
            url={subCategories.app_logo_url}
            theme={theme}
          />
        );
      case "url":
        return (
          <UrlBtn
            data={data}
            handleChange={handleChange}
            value={subCategories[key]}
            app_logo_type={subCategories.app_logo_type}
            url={subCategories.app_logo_url}
            theme={theme}
            upload_for="subCategory_logo"
          />
        );
      default:
        return "";
    }
  };
  return (
    <>
      <Grid item xs={12} paddingTop="10px">
        {/* <Box className="heading" mt={2} mb={1}>
          {"Report details"}{" "}
          <span style={{ display: required ? "" : "none" }}>*</span>:
        </Box> */}
        <div
          className={`form-container ${isVibrating && "shake-category-field"} ${
            isFieldCardRed && "form-container-red"
          }`}
        >
          <Grid container spacing={2}>
            {(fields || []).map((item) => getRequiredComponent(item))}
          </Grid>
          <div className="sub-category-btns">
            <button className="btn-cancel" onClick={handleClose}>
              <span class="material-icons-outlined">close</span>
            </button>
            <button className="btn-done" onClick={handleDone}>
              <span class="material-icons-outlined">done</span>
            </button>
          </div>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  theme: state.user.ui_preferences?.theme,
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(ReportField);
