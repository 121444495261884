import React, { useMemo } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import ReactGridTemplate from "../../../../../components/ReactGridTemplate";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    // height: "calc(80vh - 100px)",
    // overflow: "auto",
  },
  headerContainer: {
    // "& .react-grid-layout": {
    //     height: "142px !important",
    //     overflow: "auto",
    // },
  },
  biggerHeight: {
    height: "calc(80vh - 235px)",
    overflow: "auto",
  },
  smallHeight: {
    height: "calc(80vh - 95px)",
    overflow: "auto",
  },
}));

const CommonReport = ({
  handleNavigation,
  handleDelete,
  handleEdit,
  data = [],
  updateLayout,
  isFieldsEditable,
  editable_layout,
}) => {
  const classes = useStyles();

  const getHeaderData = useMemo(
    () => data.filter((item) => item.sub_category_type === "header"),
    [data]
  );
  const getSubHeaderData = useMemo(
    () => data.filter((item) => item.sub_category_type === "sub_header"),
    [data]
  );

  const getReports = useMemo(
    () =>
      data.filter(
        (item) =>
          item.sub_category_type !== "header" &&
          item.sub_category_type !== "sub_header"
      ),
    [data]
  );

  const handleSubHeaderLayout = (layout) => {
    const indx = data.findIndex(
      (item) => item.sub_category_type === "sub_header"
    );
    updateLayout(layout, indx);
  };

  const handleReportLayout = (layout) => {
    const indx = data.findIndex(
      (item) =>
        item.sub_category_type !== "header" &&
        item.sub_category_type !== "sub_header"
    );
    if (indx >= 0) {
      updateLayout(layout, indx);
    }
  };

  const getMaxSubValue = (k = "y") => {
    if (!getSubHeaderData.length) return 0;
    const data = getSubHeaderData?.reduce(function (prev, current) {
      if (+current.position_meta[k] > +prev.position_meta[k]) {
        return current;
      } else {
        return prev;
      }
    });
    return data.position_meta?.y;
  };
  return (
    <>
      {getSubHeaderData.length ? (
        <div className={classes.headerContainer}>
          <ReactGridTemplate
            data={getSubHeaderData}
            handleNavigation={handleNavigation}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            onLayoutChange={handleSubHeaderLayout}
            customData={{ minH: 2, maxH: 2 }}
            isBounded={true}
            notEditable={isFieldsEditable}
            isDraggable={!isFieldsEditable && editable_layout}
            isResizable={!isFieldsEditable && editable_layout}
            normalIcons
          />
        </div>
      ) : null}
      <Box
        sx={{
          overflow: "auto",
          height: getSubHeaderData.length
            ? `calc(80vh - 155px - ${
                getHeaderData.length > 0 ? getHeaderData.length * 60 : 0
              }px - ${getMaxSubValue() * 60}px)`
            : `calc(80vh - 30px - ${
                getHeaderData.length > 0 ? getHeaderData.length * 60 : 0
              }px - ${getMaxSubValue() * 60}px)`,
        }}
      >
        <ReactGridTemplate
          data={getReports}
          handleNavigation={handleNavigation}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          onLayoutChange={handleReportLayout}
          notEditable={isFieldsEditable}
          isDraggable={!isFieldsEditable && editable_layout}
          isResizable={!isFieldsEditable && editable_layout}
          editable_layout={editable_layout}
          normalIcons
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  editable_layout: state.registerNewApp.statusData.editLayout,
  theme: state.user.ui_preferences?.theme,
  appDashboardState: state.appDashboard.appDashboardState,
  appTemplateState: state.appTemplate.appTemplateState.templateData,
});

export default connect(mapStateToProps)(CommonReport);
