export const SideNavDataTop = [
  // {
  //   title: "Home",
  //   path: "/home",
  //   icon: "home",
  //   group: "top",
  // },
  // {
  //   title: "Explore",
  //   path: "/explore",
  //   icon: "explore",
  //   group: "top",
  // },
  // {
  //     title: "Recents",
  //     path: "/recentlyused",
  //     icon: "watch_later",
  //     group: "top",
  // },
  // { title: "Favourites", path: "/favourites", icon: "star", group: "top" },
  {
    title: "My Apps",
    path: "/myapps",
    icon: "person",
    group: "bottom",
  },
];

export const SideNavDataBottom = [
  // { title: "FAQs", path: "/faqs", icon: "faq", group: "bottom" },
  // { title: "Help", path: "/help", icon: "help", group: "bottom" },
];
