import { styled } from "@material-ui/core";
import { InputBase } from "@mui/material";
import { makeStyles, withStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const statusIconStyle = {
  width: "24px",
  height: "24px",
};

export const textFieldLabelStyle = {
  textTransform: "capitalize",
  fontWeight: "400",
};

export const useStyles = makeStyles((theme) => ({
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  hyperlinkText: {
    opacity: "1",
  },
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Energy",
      fontSize: 12,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Energy",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Energy",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      textTransform: "capitalize",
      fontFamily: "Energy",
      fontSize: 12,
      padding: "0px !important",
      fontWeight: 400
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      "& span": {
        "& svg": {
          "& path": {
            fill: "#FF007F",
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
          },
        },
      },
    },
  },
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "5px",
      // color: "#282828",
      opacity: 0.5,
    },
  },
  select: {
    fontFamily: "Energy !important",
    fontSize: "16px!important",
    fontStyle: "normal!important",
    fontWeight: "400!important",
  },
  comboOptions: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    // color: "#282828",
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          fill: "#FF007F",
          d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
        },
      },
    },
  },
  inputRoot: {
    // color: "#282828",
    background: "#ededed",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 26,
      fontFamily: "Energy",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "23px",
      letterSpacing: "0em",
      textAlign: "left",
      // color: "#282828",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
  },
  checkbox: {
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
  tagsInput: {
    "& .MuiInputBase-input": {
      textTransform: "none",
    },
  },
  heading: {
    fontFamily: "Energy !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
    lineHeight: "23px !important",
    color: "#8d9ca6",
    textAlign: "start",
  },
  copyOnClick: {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.white.main
        : theme.palette.dark.light,
    marginLeft: "1rem",
    fontSize: "12px",
  },
  urlLink: {
    color: "#7788fe",
    textDecoration: "underline",
    textDecorationColor: "#7788fe",
    marginLeft: "5px",
  },
  lightText: {
    opacity: "0.5",
  },
  capitalOptions: {
    "& .MuiAutocomplete-option": {
      textTransform: "capitalize !important",
    },
  },
  disabledDropdown: {
    "& svg": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      opacity: ".6",
    },
  },
}));
export const CssTextField = withStyles((theme) => ({
  root: {
    width: "95%",
    color:
      theme.palette.mode === "dark"
        ? theme.palette.white.main
        : "#4e4e4e",
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.light
        : theme.palette.white.main,
    padding: "7px 8px",
    border: theme.palette.mode === "dark"
    ? "1px solid #757575"
    : "1px solid #e7e9ee",
    // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "3px",
    "& .MuiInputBase-root": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : "#4e4e4e",
    },
    "& label.Mui-focused": {},
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(66, 89, 108, 0.25)",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(66, 89, 108, 0.25)",
      },
    },
  },
}))(TextField);

export const StyledAutoComplete = withStyles((theme) => {
  return {
    tag: {
      fontFamily: "Energy !important",
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : theme.palette.dark.light,
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.dark.light
          : theme.palette.white.main,
    },
    paper: {
      fontFamily: "Energy",
      fontWeight: 400,
      fontSize: 12,
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : "#4e4e4e",
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.dark.light
          : theme.palette.white.main,
      // "#282828"
    },
  };
})(Autocomplete);

export const BootstrapInput = styled(InputBase)(({ theme }) => {
  return {
    "& .MuiInputBase-input": {
      fontFamily: "Energy",
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.dark.light
          : theme.palette.white.main,
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : "#4e4e4e",
      border:
        theme.palette.mode === "dark"
          ? "1px solid #757575"
          : "1px solid #e7e9ee",
      borderRadius: "3px",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "23px",
      // padding: "6px 8px",
      padding: "6px 22px 6px 8px",
      "&.Mui-disabled": {
        color:
          theme.palette.mode === "dark"
            ? theme.palette.white.main
            : theme.palette.dark.light,
        // "-webkit-text-fill-color": `#282828 !important`,
        "-webkit-text-fill-color": `${
          theme.palette.mode === "dark"
            ? theme.palette.white.main
            : theme.palette.dark.light
        } !important`,
      },
    },
    "&:focus": {},
  };
});
