import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import { Box } from "@mui/material";
import TemplateCard from "../../../components/TemplateCard/TemplateCard";
import { connect, useDispatch } from "react-redux";
import { updated_total_views } from "../../../redux/actions";

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        flexWrap: "wrap",
        marginTop: "10px",
    },
    parentHeading: {
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "15px",
        // lineHeight: "39px",
        lineHeight: "25px",
        // color: "#282828",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        marginTop: "20px",
        // marginBottom: "10px",
    },
    descText: {
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "26px",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
    parentContainer: {
        //  height: calc(100vh - 220px),
        overflow: "auto",
        paddingBottom: "12px",
        display: "flex",
        flexDirection: "column",
    },
    cardRoot: {
        width: "100px",
        height: "100px",
    },
}));

const ParentReportType = ({ reportData = [], appDashboardState }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { appData } = appDashboardState;

    const getHeaderData = useMemo(
        () => reportData.filter((item) => item.sub_category_type === "header"),
        [reportData]
    );

    const handleNavigation = (childData, parentData) => {
        if (
            (childData.url &&
                (childData.url.includes("embed") ||
                    childData.url.includes("embedview") ||
                    childData.url.includes("reportEmbed"))) ||
            childData.sub_category?.length
        ) {
            history.push(
                `${window.location.pathname}&${parentData.sub_category_id}&${childData.sub_category_id}`
            );
        } else if (childData.url) {
            dispatch(
                updated_total_views({
                    app_id: appData.app_id,
                    sub_category_id: childData.sub_category_id,
                })
            );
            window.open(childData.url, "_blank");
        }
    };

    return (
        <Box
            sx={{
                overflow: "auto",
                height: `calc(100vh - 160px - ${
                    getHeaderData.length > 0 ? getHeaderData.length * 55 : 0
                }px)`,
                px: "10px",
            }}
        >
            {reportData.map((parentList) => {
                if (parentList.sub_category_type === "header") return null;
                return (
                    <div key={parentList.sub_category_id}>
                        <div className={classes.parentHeading}>
                            {parentList.sub_category_name}
                        </div>
                        {parentList.sub_category_description && (
                            <div className={classes.descText}>
                                {parentList.sub_category_description}
                            </div>
                        )}
                        <div className={classes.cardContainer}>
                            {(parentList.sub_category || []).map(
                                (childList) => {
                                    if (
                                        childList.sub_category_type === "header"
                                    )
                                        return null;
                                    return (
                                        <div className={classes.cardRoot}>
                                            <TemplateCard
                                                key={childList.sub_category_id}
                                                data={childList}
                                                handleNavigation={
                                                    handleNavigation
                                                }
                                                parentData={parentList}
                                                notEditable
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                );
            })}
        </Box>
    );
};

const mapStateToProps = (state) => ({
    appDashboardState: state.appDashboard.appDashboardState,
});

export default connect(mapStateToProps)(ParentReportType);
