import { InputBase, styled } from "@material-ui/core";

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: "5px 16px",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.light : "white",
    height: "20px",
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "100",
    fontSize: "12px",
    lineHeight: "23px",
    borderRadius: "5px",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#1E212F",
  },
  "& .Mui-disabled": {
    cursor: "pointer",
  },
}));
