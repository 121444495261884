import React from "react";
import { makeStyles } from "@material-ui/core";
import { Dialog, DialogContent } from "@mui/material";

const data = [
    "1.  Copy the AzureAD group name from Data@Reckitt",
    "2.  Open your MS application like powerBI, Sharepoint, powerApps etc",
    "3.  Go to the Manage Access/Manage permissions section of the application",
    "4.  From there choose Advanced Settings or the setting which allow you to add a azureAD group",
    "5.  Paste the group name in the search bar and press enter",
    "6.  Once the group is selected then save the settings",
    "7.  All the emails mentioned below in the Access Management tab of Data@Reckitt will have access to your MS Application.",
];

const useStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        fontSize: "21px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "23px",
        marginBottom: "18px",
    },
    points: {
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "23px",
        marginBottom: "5px",
    },
    closeIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
}));
const TooltipPopup = ({ handleClose, open }) => {
    const classes = useStyles();
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            maxWidth="md"
            sx={{ "& .MuiDialog-paper": { backgroundImage: "none" } }}
        >
            <DialogContent>
                <div className={classes.closeIcon}>
                    <span class="material-icons-outlined" onClick={handleClose}>
                        close
                    </span>
                </div>
                <div className={classes.heading}>
                    Steps to integrate azureAD with your Microsoft Application
                    for managing access from Data@Reckitt
                </div>
                {data.map((item) => (
                    <div className={classes.points}>{item}</div>
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default TooltipPopup;
