import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { v4 } from "uuid";
import { makeStyles } from "@material-ui/core";
import BreadcrumbComponent from "../../../components/Breadcrumbs";
import LayoutTopSideBottom from "../../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import IframeReport from "./IframeReport";
import TemplateHeader from "../../../components/Header/TemplateHeader";
import NoAccess from "../../BIAppComponent/NoAccess";
import Loader from "../../../components/Loader/Loader";
import SearchFilters from "./SearchFilters";

import { addAppToHome } from "../../../utils/utils";
import {
  updateLoyoutDataList,
  update_home_layout,
} from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "7px",
    paddingBottom: "7px",
    paddingLeft: "2%",
    paddingRight: "2%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
  },
  btn: {
    background: "transparent",
    color: "#fc007f",
    fontWeight: 400,
    // marginBottom: "5px",
    minWidth: "100px",
    gap: "8px",
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    // justifyContent: "flex-end",
    // flex: 1,
    "& .material-icons-round": {
      fontSize: "18px",
    },
    "& .material-icons-outlined": {
      fontSize: "18px",
    },
  },
}));

const TemplateLayout = ({
  setBreadCrumbsData,
  match,
  breadCrumbsData,
  report,
  appDashboardState,
  reqAppDetails,
  appAccess,
  children,
  user,
  AppsData,
}) => {
  const { appData, search_columns = [], searchVal = "" } = appDashboardState;
  const { layoutDataList, layoutStatus } = AppsData;
  const { data: appIdList } = layoutDataList;
  const { status } = layoutStatus;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getHeaderData = useMemo(
    () => reqAppDetails.filter((item) => item.sub_category_type === "header"),
    [reqAppDetails]
  );

  const HandleAddAppToHome = async () => {
    setLoading(true);
    const data = addAppToHome(
      user.preferred_username,
      appIdList,
      appData.app_id
    );
    dispatch(updateLoyoutDataList(data.sequence_of_app));
    dispatch(update_home_layout(data));
    setLoading(false);
  };

  if (!appAccess) {
    return (
      <LayoutTopSideBottom match={match}>
        <NoAccess
          appName={appData?.app_meta.app_name}
          appId={appData?.app_id}
          appDetails={appData}
        />
      </LayoutTopSideBottom>
    );
  }

  return (
    <LayoutTopSideBottom match={match} oldbreadCrumbsData={breadCrumbsData}>
      {/* <Box className={classes.container}>
                <BreadcrumbComponent data={breadCrumbsData} />

                {breadCrumbsData.length === 1 && <SearchFilters />}

                <div className={classes.btnContainer}>
                    <button
                        className={classes.btn}
                        onClick={HandleAddAppToHome}
                        disabled={loading || status === "loading"}
                    >
                        {loading || status === "loading" ? (
                            <Loader />
                        ) : appIdList.includes(appData.app_id) ? (
                            <>
                                <i
                                    className="material-icons-round"
                                    title="Remove from home"
                                >
                                    home
                                </i>
                                <div>Remove from Home</div>
                            </>
                        ) : (
                            <>
                                <i
                                    className="material-icons-outlined"
                                    title="Add to home"
                                >
                                    add_home
                                </i>
                                <div>Add to Home</div>
                            </>
                        )}
                    </button>
                </div>
            </Box> */}
      {report.sub_category?.length || report.defined_categories?.length ? (
        <>
          <Box sx={{ pb: "15px", px: "2%" }}>
            {getHeaderData.length ? (
              <Box display="flex" flexDirection="column" gap={1} px="10px">
                {!search_columns.length && !searchVal
                  ? getHeaderData.map((item) => (
                      <TemplateHeader data={item} key={v4()} notEditable />
                    ))
                  : null}
              </Box>
            ) : null}
            {children}
          </Box>
        </>
      ) : report.url ? (
        <>
          <Box
          // sx={{ px: "2%" }}
          >
            <IframeReport
              app={appData}
              activeReport={report}
              breadCrumbsData={breadCrumbsData}
              setBreadCrumbsData={setBreadCrumbsData}
            />
          </Box>
        </>
      ) : null}
    </LayoutTopSideBottom>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
  appDashboardState: state.appDashboard.appDashboardState,
  allApps: state.AppsData.allApps,
  user: state.user,
  AppsData: state.AppsData,
});

export default connect(mapStateToProps)(TemplateLayout);
