import C from "../constants";

export default function appDashboard(state = {}, payload) {
  let { type, data } = payload;
  switch (type) {
    case C.UPDATE_APP_DASHBOARD:
      return {
        ...state,
        appDashboardState: {
          ...state.appDashboardState,
          ...data,
        },
      };
    case C.UPDATE_POSITION_META:
      return {
        ...state,
        positionMeta: { status: "loading" },
      };
    case C.POSITION_META_UPDATED:
      return {
        ...state,
        positionMeta: { status: data.status },
      };
    case C.GET_APP_DASHBOARD_DETAILS:
      return {
        ...state,
        appDashboardState: {
          ...state.appDashboardState,
          appDataStatus: "loading",
        },
      };
    case C.APP_DASHBOARD_DETAILS_RECEIVED:
      return {
        ...state,
        appDashboardState: {
          ...state.appDashboardState,
          appDataStatus: data.status,
          appData: data.data,
        },
      };
    default:
      return state;
  }
}
