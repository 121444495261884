import { makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { v4 } from "uuid";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TemplateCard from "../../../components/TemplateCard/TemplateCard";
import { updated_total_views } from "../../../redux/actions";
import no_search from "../../../../assets/images/no_search.svg";
import Loader from "../../../components/Loader/Loader";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "10px",
        overflow: "auto",
        height: "80vh",
    },
    nonNextContainer: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        flexWrap: "wrap",
    },
    rowContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& .filter-icon-container:first-of-type": {
            "& .filter-icon": {
                display: "none",
            },
        },
    },
    cardRoot: {
        width: "100px",
        height: "100px",
    },
    noAppContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "70vh",
    },
    cardContainer: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
    icon: {
        "&.MuiSvgIcon-root": {
            fontSize: "14px",
        },
    },
}));

const FilterReports = ({ appDashboardState }) => {
    const {
        filteredReportsList = [],
        appData,
        searchVal = "",
        search_columns = [],
        loadingFilter = "pending",
    } = appDashboardState;
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const nestedReport = useMemo(
        () =>
            filteredReportsList.filter((item) => {
                const firstEl = item[0].sub_category_type;
                return (
                    (item.length > 1 && firstEl !== "parentReport") ||
                    (item.length > 2 && firstEl === "parentReport")
                );
            }),
        [filteredReportsList]
    );
    const nonNestedReport = useMemo(
        () =>
            filteredReportsList.filter((item) => {
                const firstEl = item[0].sub_category_type;
                return (
                    (item.length <= 1 && firstEl !== "parentReport") ||
                    (item.length <= 2 && firstEl === "parentReport")
                );
            }),
        [filteredReportsList]
    );

    const handleNavigation = (data, parentData) => {
        if (
            (data.url &&
                (data.url.includes("embed") ||
                    data.url.includes("embedview") ||
                    data.url.includes("reportEmbed"))) ||
            data.sub_category?.length
        ) {
            let reqRoute = "";
            parentData.some((item, i) => {
                // if (
                //     item.sub_category_type === "report" ||
                //     item.sub_category_type === "hybrid"
                // ) {
                reqRoute += `&${item.sub_category_id}`;
                if (item.sub_category_id === data.sub_category_id) {
                    return true;
                }
                // }
                return false;
            });
            history.push(`${window.location.pathname}${reqRoute}`);
        } else if (data.url) {
            dispatch(
                updated_total_views({
                    app_id: appData.app_id,
                    sub_category_id: data.sub_category_id,
                })
            );
            window.open(data.url, "_blank");
        }
    };
    return (
        <div className={classes.container}>
            {loadingFilter === "loading" && <Loader />}
            {nestedReport.map((row) => (
                <div className={classes.rowContainer} key={v4()}>
                    {row.map((item) => {
                        if (
                            item.sub_category_type !== "parentReport" ||
                            (item.sub_category_type === "parentReport" &&
                                item.sub_category_id !== row[0].sub_category_id)
                        ) {
                            return (
                                <div
                                    className={`${classes.cardContainer} filter-icon-container`}
                                    key={v4()}
                                >
                                    <ArrowForwardIosIcon
                                        className={`${classes.icon} filter-icon`}
                                    />
                                    <div className={classes.cardRoot}>
                                        <TemplateCard
                                            data={item}
                                            handleNavigation={handleNavigation}
                                            notEditable
                                            parentData={row}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            ))}
            <div className={classes.nonNextContainer}>
                {nonNestedReport.map((row) => (
                    <React.Fragment key={v4()}>
                        {row.map((item) => {
                            if (
                                item.sub_category_type !== "parentReport" ||
                                (item.sub_category_type === "parentReport" &&
                                    item.sub_category_id !==
                                        row[0].sub_category_id)
                            ) {
                                return (
                                    <div
                                        className={classes.cardRoot}
                                        key={v4()}
                                    >
                                        <TemplateCard
                                            data={item}
                                            handleNavigation={handleNavigation}
                                            notEditable
                                            parentData={row}
                                        />
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </React.Fragment>
                ))}
            </div>
            {(searchVal !== "" || search_columns.length) &&
                filteredReportsList.length === 0 &&
                loadingFilter === "success" && (
                    <div className={classes.noAppContainer}>
                        <img
                            src={no_search}
                            alt="No Apps found"
                            height="200px !important"
                        />
                    </div>
                )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    templates: state.register.registerTemplate,
    user: state.user,
    appDashboardState: state.appDashboard.appDashboardState,
});

export default connect(mapStateToProps)(FilterReports);
