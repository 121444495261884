import C from "../constants";
import { stateData } from "../stateData";

const initial_state = {
  BIappDetails: {
    categoryInfo: [],
    categoryInfoLocal: [],
    selectedWorkspace: "",
    prevWorkspace: "",
    selectedTemplate: null,
  },

  MLappDetails: {
    configDetails: {},
    trackStatusState: [],
  },

  accessManagement: {
    accessList: [],
    accessListClone: [],
    addAccessList: [],
  },
  appData: {
    appFields: { app_details: {} },
    dataList: {},
    visited: {},
    isChanged: {
      app_meta: {},
      app_details: {},
      provisioning_details: {},
    },
    validationMsg: "",
    access_type: "public",
    appInfoID: "",
    savedTemplateData: {},
  },

  statusData: {
    waiting: false,
    isAppUnique: false,
    openSubmitError: false,
    open: false,
    isChangesSubmitable: false,
    isDetailsSubmitable: false,
    isAdminRegistering: false,
    draftApp: false,
    dirty: false,
    editLayout: true,
    showDetailsDrawer: false,
    navToExplore: false,
    isPreviewDisable: false,
    discardChanges: false,
    editPreviewToggleReport: true,
    showScreenshot: true,
    takeScreenshot: false,
    waitingForScreenshot: false,
    isStepperHidden: false,
    localstateSetFirstTime: false,
  },
  activeStepper: 0,
};

export default function registerNewApp(state = {}, payload) {
  let { type, data } = payload;
  switch (type) {
    case C.UPDATE_BI_APP_DETAILS:
      return {
        ...state,
        BIappDetails: {
          ...state.BIappDetails,
          ...data,
        },
      };

    case C.UPDATE_REPORT_DESC:
      return {
        ...state,
        BIappDetails: {
          ...state.BIappDetails,
          categoryInfoLocal: state.BIappDetails.categoryInfoLocal.map(
            (item) => {
              if (item.sub_category_type === "text") {
                return { ...item, ...data };
              }
              return item;
            }
          ),
        },
      };

    case C.UPDATE_ML_APP_DETAILS:
      return {
        ...state,
        MLappDetails: {
          ...state.MLappDetails,
          ...data,
        },
      };

    case C.UPDATE_ACCESS_MANAGEMENT:
      return {
        ...state,
        accessManagement: {
          ...state.accessManagement,
          ...data,
        },
      };

    case C.UPDATE_APP_DATA:
      return {
        ...state,
        appData: {
          ...state.appData,
          ...data,
        },
      };

    case C.CLEAN_NEW_APP_REGISTER_STATES:
      return initial_state;

    case C.UPDATE_STATUS_DATA:
      return {
        ...state,
        statusData: {
          ...state.statusData,
          ...data,
        },
      };

    case C.UPDATE_ACTIVE_STEPPER:
      return {
        ...state,
        activeStepper: data,
      };

    default:
      return state;
  }
}
