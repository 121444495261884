import React, { useState, useEffect, useMemo } from "react";
import { Box, Tooltip } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import equal from "deep-equal";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import moment from "moment";
import { debounce } from "throttle-debounce";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import add_icon from "../../../../assets/images/add_azure.svg";
import UserTable from "../../../components/NewUserTable/UserTable";
import Loader from "../../../components/Loader/Loader";
import NotificationModal from "../../../components/AlertMsg/NotificationModal";
import RegisterBodyContainer from "../Components/RegisterBodyContainer";
import FooterContainer from "../Components/FooterContainer";
import { AccessManagementSkeletons } from "../../Register/components/Skeletons";
import SyncModal from "../../Register/components/SyncModal";
import AutoWithAsyncLoad from "../Components/AutoCompletesComponents/AutoWithAsyncLoad";
//hooks
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import {
  getUsersList,
  getCountryBrands,
  updateAppDetails,
  syncAccessInEnv,
  cleanUserList,
  updateGroupMembers,
  get_user_access_list,
  clean_user_access_list,
  getAppDetails,
  update_accessManagement,
  update_appData,
} from "../../../redux/actions";
import {
  create_azure_group,
  sendMailToUsersAccess,
  updateAccessRequest,
} from "../../../apiServices";

import { ToggleButton, useStyles } from "../style";
import CopyToClipBoard from "../../Register/components/CopyToClipBoard";
import TooltipPopup from "../../Register/components/TooltipPopup";
import PendingRequestsPopUp, {
  WarningPopUp,
} from "../../Register/components/PendingRequestsPopUp";
import { isEqualObject } from "../../../utils/utils";

const initailValues = {
  action_for_uat: false,
  action_for_dev: false,
};
const AccessManagement = (props) => {
  const {
    accessList,
    usersList,
    userInfo,
    app_id,
    appDetails,
    accessListClone,
    approval_status,
    appType,
    countryBrandsData,
    access_type,
    sync_access_with_env,
    user_access_list,
    theme,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const eventTracker = useAnalyticsEventTracker(
    "Access Management",
    appDetails.data.app_id
  );
  const [usersOptions, setUserOptions] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [filtervalue, setFilterValue] = useState("");
  const [filterRole, setFilterRole] = useState("All");
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState("");
  const [isDataChanged, setDataChanged] = useState(false);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  // access role given i.e admin or owner
  const [accessRole, setAccessRole] = useState("");
  const [envData, setEnvData] = useState(initailValues);
  const [existingUsers, setExistingUsers] = useState([]);
  const [azureData, setAzureData] = useState({});
  const [azureLoading, setAzureLoading] = useState(false);
  const [tooltipPopOpen, setTooltipPopOpen] = useState(false);
  const [requestList, setRequestList] = useState([]);
  const [requestPopOpen, setRequestsPopOpen] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [showWarningPopUp, setShowWarningPopUp] = useState(false);

  const showWarning = useMemo(() => {
    if (isEqualObject(appDetails.data.access_details?.users_list, accessList))
      return false;
    else return true;
  }, [appDetails.data.access_details?.users_list, accessList]);

  const hanldeTooltipPop = () => setTooltipPopOpen(true);
  const handleCloseTooltipPop = () => setTooltipPopOpen(false);
  const handleCloseRequestsPop = () => {
    setRequestsPopOpen(false);
    if (reloadList) {
      dispatch(
        getAppDetails({
          type: "app_id",
          app_id: app_id,
          approval_status: appDetails.data.approval_meta.approval_status,
        })
      );
      setReloadList(false);
    }
  };
  const handleOpenRequestsPop = () => {
    if (showWarning) {
      setShowWarningPopUp(true);
    } else {
      setRequestsPopOpen(true);
    }
  };

  const handleCloseWarningPopUp = () => {
    setShowWarningPopUp(false);
  };

  const closeSyncModal = () => setSyncModalOpen(false);
  const handleSyncModal = () => {
    setSyncModalOpen(true);
    eventTracker("Sync other env btn clicked", "open popup");
  };

  const handleButtonGroup = (event, newValue) => {
    if (newValue != null) {
      if (accessRole === "owner" || userInfo.role === "super_admin") {
        // setAccess_type(newValue);
        dispatch(update_appData({ access_type: newValue }));
      }
    }
  };

  const handleClose = () => {
    dispatch(
      updateAppDetails(
        {
          type: "app_id",
          app_id: app_id || appDetails.data?.app_id,
          approval_status:
            approval_status || appDetails.data?.approval_meta?.approval_status,
        },
        !app_id &&
          (userInfo.role === "admin" || userInfo.role === "super_admin")
          ? "admin"
          : ""
      )
    );
    setShowModal(false);
  };

  // const handleGetUsers = (val) => {
  //     if (!val) return;
  //     dispatch(getUsersList({ term: val }));
  // };

  const debounceFunc = debounce(800, (val) => {
    dispatch(getUsersList({ term: val }));
  });

  const handleGetUsers = (val) => {
    if (!val) return;
    debounceFunc(val);
  };
  // setting options to give access
  const getAccessOptions = (role) => {
    if (role === "owner" || userInfo.role === "super_admin") {
      return ["owner", "admin", "user"];
    } else if (role === "admin") {
      return ["admin", "user"];
    } else return [];
  };

  const handleAzureGroup = async () => {
    setAzureLoading(true);
    const users_list = [];
    appDetails.data.access_details?.users_list.forEach((item) => {
      users_list.push(item.user_id);
    });
    const payload = {
      app_id: appDetails.data.app_id,
      app_name: appDetails.data.app_meta.app_name,
      approval_status: appDetails.data.approval_meta.approval_status,
      users_list,
    };
    // creating the azure group
    const { isSuccess, errorMSG, data } = await create_azure_group(payload);
    if (isSuccess) {
      const reqData = {
        app_id: appDetails.data.app_id,
        approval_meta: appDetails.data.approval_meta,
        access_details: {
          ...appDetails.data.access_details,
          group_details: {
            group_id: data?.id,
            group_name: data?.displayName,
            created_by: userInfo.preferred_username,
            created_on: moment().valueOf(),
          },
        },
      };
      setAzureData(data);
      // after creation then we hv to update that in app access details
      const response = await updateAccessRequest(reqData);
      if (response.isSuccess) {
        setAzureLoading(false);
        // updating the app with backend data or refreshing the page.
        dispatch(
          updateAppDetails(
            {
              type: "app_id",
              app_id: appDetails.data?.app_id,
              approval_status: appDetails.data?.approval_meta?.approval_status,
            },
            !app_id &&
              (userInfo.role === "admin" || userInfo.role === "super_admin")
              ? "admin"
              : ""
          )
        );
      } else {
        setAzureLoading(false);
        toast.error(
          errorMSG || "Oops ! Something went wrong. Please refresh the page!"
        );
      }
    } else {
      setAzureLoading(false);
      toast.error(
        errorMSG || "Oops ! Unable to create Azure group. Please try later!"
      );
    }
  };

  const handleUpdateAzureGroupMembers = (users) => {
    if (!appDetails.data.access_details?.group_details?.group_id) return;
    const added_users = [];
    const newUsers = [];
    const deleted_users = [];
    users.forEach((item) => {
      if (!existingUsers.includes(item.user_id)) {
        added_users.push(item.user_id);
      }
      newUsers.push(item.user_id);
    });
    existingUsers.forEach((item) => {
      if (!newUsers.includes(item)) {
        deleted_users.push(item);
      }
    });
    const data = {
      group_id:
        appDetails.data.access_details.group_details?.group_id ||
        azureData.group_id,
      added_users,
      deleted_users,
    };
    dispatch(updateGroupMembers(data));
  };

  const sendMailToUser = async (reqList) => {
    let previousList = [];
    let owner_list = [];
    let user_list = [];
    let admin_list = [];
    appDetails.data.access_details?.users_list.forEach((item) => {
      previousList.push(item.user_id.toLowerCase());
    });
    reqList.forEach((item) => {
      if (!previousList.includes(item.user_id.toLowerCase())) {
        if (item.role === "owner") {
          owner_list.push(item.user_id);
        } else if (item.role === "admin") {
          admin_list.push(item.user_id);
        } else {
          user_list.push(item.user_id);
        }
      }
    });
    if (owner_list.length || user_list.length || admin_list.length) {
      const data = {
        app_id: appDetails.data.app_id,
        approval_status: appDetails.data.approval_meta?.approval_status,
        owner_list,
        user_list,
        admin_list,
      };
      await sendMailToUsersAccess(data);
    }
  };

  const handleAddUser = (val) => {
    let isAddable = true;
    accessListClone.forEach((item) => {
      if (item.user_id.toLowerCase() === val.user_id.toLowerCase()) {
        isAddable = false;
        return;
      }
    });
    if (!isAddable) return;
    if (appType === "ml_app") {
      const updatedAccessList = [
        {
          access_granted_by: userInfo.preferred_username,
          access_granted_on: moment().valueOf(),
          name: val.user_name,
          role: "user",
          user_id: val.user_id,
          security_filters: [
            {
              country: "All",
              brands_list: ["All"],
            },
          ],
        },
        ...accessListClone,
      ];
      // setAccessList(updatedAccessList);
      // setAccessListClone(updatedAccessList);
      dispatch(
        update_accessManagement({
          accessList: updatedAccessList,
          accessListClone: updatedAccessList,
        })
      );
    } else {
      // setAccessList([
      //   {
      //     access_granted_by: userInfo.preferred_username,
      //     access_granted_on: moment().valueOf(),
      //     name: val.user_name,
      //     role: "user",
      //     user_id: val.user_id,
      //   },
      //   ...accessList,
      // ]);
      // setAccessListClone([
      //   {
      //     access_granted_by: userInfo.preferred_username,
      //     access_granted_on: moment().valueOf(),
      //     name: val.user_name,
      //     role: "user",
      //     user_id: val.user_id,
      //   },
      //   ...accessListClone,
      // ]);
      dispatch(
        update_accessManagement({
          accessList: [
            {
              access_granted_by: userInfo.preferred_username,
              access_granted_on: moment().valueOf(),
              name: val.user_name,
              role: "user",
              user_id: val.user_id,
            },
            ...accessList,
          ],
          accessListClone: [
            {
              access_granted_by: userInfo.preferred_username,
              access_granted_on: moment().valueOf(),
              name: val.user_name,
              role: "user",
              user_id: val.user_id,
            },
            ...accessListClone,
          ],
        })
      );
    }
    dispatch(cleanUserList());
  };

  const SubmitSyncEnv = (envData) => {
    const { approval_meta, app_id, access_details, app_details } =
      appDetails.data;
    const syncData = {
      app_id,
      users_list: access_details.users_list,
      approval_status: approval_meta.approval_status,
      app_handle: app_details.app_handle,
      ...envData,
    };
    dispatch(syncAccessInEnv(syncData));
    closeSyncModal();
    setEnvData(initailValues);
    // setup for GA
    const GA_value =
      envData.action_for_uat && envData.action_for_dev
        ? "Sync into uat&dev"
        : envData.action_for_uat
        ? "Sync into uat"
        : envData.action_for_dev
        ? "Sync into dev"
        : "";
    eventTracker("Sync btn clicked", GA_value);
  };

  const handleSave = async () => {
    setWaiting(true);
    const data = {
      app_id: app_id || appDetails.data.app_id,
      access_details: {
        ...appDetails.data.access_details,
        users_list: accessListClone,
        access_type,
      },
      approval_meta: appDetails.data.approval_meta,
    };
    if (appType === "bi_app") {
      handleUpdateAzureGroupMembers(accessListClone);
    }
    const { isSuccess, statusMsg } = await updateAccessRequest(data);
    setMsg(statusMsg);
    if (isSuccess) {
      sendMailToUser(data.access_details.users_list);
      setShowModal(true);
      setWaiting(false);
    } else {
      setWaiting(false);
      toast.error("Opps! unable to update changes");
    }
  };
  useEffect(() => {
    const defaultData = appDetails.data?.access_details?.users_list;
    const trackingData = (data) => {
      let rslt = false;
      data.some((item, i) => {
        if (!equal(item, defaultData[i])) {
          rslt = true;
          return rslt;
        }
        return false;
      });
      return rslt;
    };
    if (
      defaultData?.length !== accessListClone.length ||
      trackingData(accessListClone) ||
      access_type !== appDetails.data?.access_details?.access_type
    ) {
      setDataChanged(true);
    } else {
      setDataChanged(false);
    }
  }, [
    accessListClone,
    appDetails.data?.access_details?.users_list,
    access_type,
    appDetails.data?.access_details?.access_type,
  ]);
  // setting the access type given to user i.e is he a owner or admin of the app
  useEffect(() => {
    if (appDetails.data.access_details?.users_list?.length) {
      const accessGiven = appDetails.data.access_details.users_list.find(
        (item) => item.user_id === userInfo.preferred_username
      );
      setAccessRole(accessGiven?.role);
      const users_list = [];
      appDetails.data.access_details?.users_list.forEach((item) => {
        users_list.push(item.user_id);
      });
      setExistingUsers(users_list);
    }
  }, [appDetails.data.access_details?.users_list, userInfo.preferred_username]);

  useEffect(() => {
    if (appType === "ml_app" && countryBrandsData.status === "pending") {
      dispatch(getCountryBrands());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      get_user_access_list({
        logged_in_user: userInfo.preferred_username,
        role: userInfo.role,
      })
    );
    return () => {
      dispatch(clean_user_access_list());
      setRequestList([]);
    };
  }, [dispatch, userInfo.preferred_username]);

  function filterLatestPendingRecords(records) {
    const latestPendingRecords = {};

    for (const record of records) {
      const requestedBy = record.requester_meta.requested_by;
      const requestedOn = record.requester_meta.requested_on;
      const approvalStatus = record.approval_meta.approval_status;

      // Check if there is already a record for the requested_by email
      if (!latestPendingRecords[requestedBy]) {
        latestPendingRecords[requestedBy] = record;
      } else {
        // Check if the current record has a later requested_on date and is pending
        if (
          requestedOn >
            latestPendingRecords[requestedBy].requester_meta.requested_on &&
          approvalStatus === "pending"
        ) {
          latestPendingRecords[requestedBy] = record;
        }
      }
    }

    // Filter out records with 'denied' approval status for each user
    const result = Object.values(latestPendingRecords).filter(
      (record) => record.approval_meta.approval_status === "pending"
    );

    return result;
  }

  useEffect(() => {
    if (user_access_list.status === "success") {
      const newData = user_access_list.data.filter(
        (item) => item.requester_meta.app_id === app_id
        // &&
        // item.approval_meta.approval_status === "pending"
      );
      const resultArray = filterLatestPendingRecords(newData);
      setRequestList(resultArray);
    }
  }, [user_access_list.data, user_access_list.status, app_id]);

  useEffect(() => {
    if (usersList.status === "success") {
      setUserOptions(usersList.data);
    }
  }, [usersList]);

  return (
    <>
      <RegisterBodyContainer height="calc(100vh - 370px)" overflow="hidden">
        <SyncModal
          open={syncModalOpen}
          handleClose={closeSyncModal}
          app_name={appDetails.data?.app_meta?.app_name}
          SubmitSyncEnv={SubmitSyncEnv}
          envData={envData}
          setEnvData={setEnvData}
          theme={theme}
        />
        <TooltipPopup
          handleClose={handleCloseTooltipPop}
          open={tooltipPopOpen}
        />
        {requestPopOpen && (
          <PendingRequestsPopUp
            handleClose={handleCloseRequestsPop}
            open={requestPopOpen}
            requestList={requestList}
            setUsersList={setRequestList}
            app_id={app_id}
            accessList={accessList}
            appType={appType}
            setReloadList={setReloadList}
          />
        )}
        {showWarningPopUp && (
          <WarningPopUp
            handleClose={handleCloseWarningPopUp}
            open={showWarningPopUp}
          />
        )}
        {appDetails.status === "loading" ||
        user_access_list.status === "loading" ? (
          <AccessManagementSkeletons />
        ) : (
          <>
            {countryBrandsData.status === "loading" && (
              <AccessManagementSkeletons />
            )}
            {countryBrandsData.status === "error" && appType === "ml_app" && (
              <div className={classes.errorContainer}>
                <h4 style={{ color: "#FC007F" }}>Something Went Wrong !</h4>
                <h5 style={{ color: "#8d9ca6", margin: "auto" }}>
                  Couldn't load user data, try reloading the page.
                </h5>
              </div>
            )}
            {((countryBrandsData.status === "success" &&
              appType === "ml_app") ||
              appType === "bi_app" ||
              appType === "standalone_app") && (
              <div>
                <NotificationModal
                  handleClose={handleClose}
                  message={msg || "Your changes are Added."}
                  open={showModal}
                />
                {/* <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={"30px"}
                >
                  <Box display="flex" alignItems="center" gap={"100px"}>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div className={classes.heading}>Application Access:</div>
                      <ToggleButtonGroup
                        exclusive
                        variant="outlined"
                        size="sm"
                        value={access_type}
                        onChange={handleButtonGroup}
                      >
                        <ToggleButton sx={{ width: "66px" }} value="public">
                          Public
                        </ToggleButton>
                        <ToggleButton sx={{ width: "66px" }} value="private">
                          Private
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={handleOpenRequestsPop}
                    >
                      <div className={classes.heading}>Pending Requests:</div>
                      <div
                        style={{
                          background: "red",
                          borderRadius: "15px",
                          height: "15px",
                          width: "15px",
                          marginLeft: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            color: "white",
                            fontSize: "9px",
                            fontWeight: "bold",
                          }}
                        >
                          {requestList.length}
                        </p>
                      </div>
                    </div>

                    {appType === "bi_app" && (
                      <Box display="flex" alignItems="center" gap={2} ml={2}>
                        {appDetails.data.access_details.group_details
                          ?.group_id || azureData.group_id ? (
                          <>
                            <div className={classes.heading}>
                              Azure AD group :
                            </div>
                            <div
                              className={classes.heading}
                              style={{ color: "#fc007f" }}
                            >
                              {appDetails.data.access_details.group_details
                                ?.group_name || azureData.group_name}
                            </div>
                            <Tooltip
                              placement="top"
                              title="The below emails are synced with this azure AD group. Click to know more."
                            >
                              <InfoIcon
                                sx={{
                                  fontSize: "16px",
                                  ml: "5px",
                                  color: "#4e4e4e",
                                }}
                                onClick={hanldeTooltipPop}
                              />
                            </Tooltip>
                            <CopyToClipBoard
                              text={
                                appDetails.data.access_details.group_details
                                  ?.group_name || azureData.group_name
                              }
                            />
                          </>
                        ) : (
                          <>
                            <div className={classes.heading}>
                              Create an Azure AD group for this application :
                            </div>
                            <Switch
                              style={{
                                backgroundColor: "#FC007F",
                                margin: "10px",
                              }}
                              disabled={
                                azureLoading || appDetails.status === "loading"
                              }
                              onChange={handleAzureGroup}
                              className="switch"
                              label="start"
                              sx={{ mx: 1 }}
                              size="small"
                            />
                            <button
                              className={`btn-createADGroup btn-createADGroup-${theme}`}
                              onClick={handleAzureGroup}
                              disabled={
                                azureLoading || appDetails.status === "loading"
                              }
                            >
                              <img src={add_icon} />
                              <div style={{ marginLeft: "5px" }}>
                                Create an Azure AD Group
                              </div>
                            </button>
                            <Tooltip
                              placement="top"
                              title="Click to know more."
                            >
                              <InfoIcon
                                sx={{
                                  fontSize: "16px",
                                  color:
                                    theme === "dark" ? "#b1b1b1" : "#4e4e4e",
                                }}
                                onClick={hanldeTooltipPop}
                              />
                            </Tooltip>
                            {azureLoading && <Loader />}
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                  {
                    // showing syn btn for ml_app and prod/uat setup only
                    appType === "ml_app" &&
                      window.location?.origin !==
                        "https://data-reckitt-dev.rbone.net" && (
                        <button
                          className="sync-access-btn"
                          onClick={handleSyncModal}
                          disabled={sync_access_with_env.status === "loading"}
                        >
                          <span class="material-icons-outlined">sync</span>
                          <div>Other environments</div>
                        </button>
                      )
                  }
                </Box> */}
                <AutoWithAsyncLoad
                  handleChange={handleGetUsers}
                  handleSelect={handleAddUser}
                  options={usersOptions}
                  list={usersList}
                  mb="10px"
                />
                {accessList && (
                  <UserTable
                    setFilterValue={setFilterValue}
                    filtervalue={filtervalue}
                    filterRole={filterRole}
                    setFilterRole={setFilterRole}
                    appType={appType}
                    accessOptions={getAccessOptions(accessRole)}
                    accessRole={accessRole}
                  />
                )}
              </div>
            )}{" "}
          </>
        )}
      </RegisterBodyContainer>

      <FooterContainer>
        <div style={{ marginTop: "80px" }}>
          <button
            className="btn btn-next"
            id="GA-save-changes"
            onClick={handleSave}
            disabled={
              waiting || !isDataChanged || appDetails.status === "loading"
            }
          >
            {waiting ? (
              <Loader color="#ffffff" margin="0px 8px" />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      </FooterContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  access_type: state.registerNewApp.appData.access_type,
  // accessManagement
  accessListClone: state.registerNewApp.accessManagement.accessListClone,
  accessList: state.registerNewApp.accessManagement.accessList,
  // ========================================
  usersList: state.AppsData.usersList,
  userInfo: state.user,
  appDetails: state.AppsData.appDetails,
  countryBrandsData: state.AppsData.countryBrands,
  sync_access_with_env: state.register.sync_access_with_env,
  azure_group_info: state.register.azure_group_info,
  azure_group_members: state.register.azure_group_members,
  user_access_list: state.userAccessManagement.user_access_list,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(AccessManagement);
