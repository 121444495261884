import { Container, Stack } from "@mui/material";
import React from "react";

const FooterContainer = ({ children, justifyContent}) => {
    return (
        <Stack
            position="sticky"
            bottom="2px"
            left={0}
            p="5px 2px"
            marginTop="10px"
        >
            <Container
                maxWidth = {false}
                sx={{
                    display: "flex",
                    direction: "row",
                    justifyContent: justifyContent || "flex-end",
                    alignItems: "center",
                    gap: 2,
                    position: "relative",
                    paddingRight: "45px !important"
                }}
            >
                {children}
            </Container>
        </Stack>
    );
};

export default FooterContainer;
