import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  //   Tooltip,
  Dialog,
  //   IconButton,
  //   DialogContent,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import SelectComponent from "../SelectComponents";
// import RadioBtns from "../RadioBtns";
// import UrlBtn from "../UrlBtn";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import runError from "../../../../../assets/images/register-images/run-error.svg";
import runSuccess from "../../../../../assets/images/register-images/run-success.svg";
// import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import {
  isUrlMatch,
  //   removeReportField,
  updateSubCategoryReportState,
  updateSubState,
} from "../../../../utils/utils";
import "../../index.scss";
import { BootstrapInput } from "../../style";
import { sub } from "date-fns";
import { update_BIappDetails } from "../../../../redux/actions";

const statusIconStyle = {
  width: "30px",
  height: "30px",
};

const InputFormField = (props) => {
  const {
    handleChange,
    value,
    data,
    width,
    bi_application,
    URLValid,
    setURLValid,
    isFieldVibrating,
    isFieldRed,
    theme
  } = props;
  const { label, key, required } = data;
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleInputChange = (k, v) => {
    if (k === "url") {
      setURLValid({ status: "pending", msg: "" });
      handleChange(k, v);
    } else {
      handleChange(k, v);
    }
  };

  //   use to validate the url
  const handleBlur = useCallback(
    (value) => {
      const handleURLValidation = (val) => {
        if (!val.startsWith("https://")) {
          setURLValid({
            status: "Invalid",
            msg: "URL should start with (https://)",
          });
        } else if (!val.slice(8).trim().length) {
          setURLValid({
            status: "Invalid",
            msg: "Please Enter valid url",
          });
        } else if (!isUrlMatch(bi_application, val)) {
          setURLValid({
            status: "Invalid",
            msg: "Please Enter valid url",
          });
        } else {
          setURLValid({
            status: "Valid",
            msg: "",
          });
        }
      };
      if (key === "url" && value) {
        handleURLValidation(value);
      }
      return;
    },
    [key, setURLValid, bi_application]
  );

  // useEffect for handleBlur to validate the url
  useEffect(() => {
    if (!value) return;
    handleBlur(value);
  }, [handleBlur, bi_application]);

  return (
    <Grid item xs={width || 4} key={key}>
      <div className={`heading ${theme}`}>
        {key === "sub_category_name" ? "Report Name" : label}{" "}
        <span style={{ display: required ? "" : "none" }}>*</span>:
      </div>
      <Box display="flex" alignItems="center">
        <BootstrapInput
          onChange={(e) => handleInputChange(key, e.target.value.trimStart())}
          style={{ width: "95%" }}
          value={value}
          onBlur={(e) => handleBlur(e.target.value)}
          placeholder={
            key === "url"
              ? bi_application === "Power Apps" ||
                bi_application === "Other Applications"
                ? "Enter the URL of the dashboard/application"
                : "Enter the embedded URL of the dashboard/report"
              : key === "sub_category_name"
              ? "Enter report name"
              : `Enter ${label}`
          }
          className={`${isFieldVibrating && "shake-category-field"} ${
            isFieldRed && "error"
          }`}
        />
        {key === "url" && URLValid.status === "Valid" ? (
          <img
            src={runSuccess}
            style={statusIconStyle}
            title="Valid URL"
            alt="Success"
          />
        ) : key === "url" && URLValid.status === "Invalid" ? (
          <img
            src={runError}
            style={statusIconStyle}
            title="Invalid URL"
            alt="Failed"
          />
        ) : null}
      </Box>
      {URLValid.status === "Invalid" && key === "url" && (
        <div className="validation_msg" style={{ color: "red" }}>
          {URLValid.msg}
        </div>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        maxWidth="md"
      ></Dialog>
    </Grid>
  );
};

// ====================== CategoryFieldReport ======================
const SubCategoryFieldReport = (props) => {
  const {
    theme,
    isVibrating,
    isFieldCardRed,
    categoryInfoLocal,
    catIndex,
    subCatIndex,
    SubCatHyperLink,
    URLValid,
    setURLValid,
    isSubCatReportvibrating,
    isSubCatReportTextFieldRed,
  } = props;

  const dispatch = useDispatch();
  const fieldsV2 = [
    {
      key: "bi_application",
      label: "BI Application",
      options: [
        "Power BI",
        "Google DataStudio",
        "Sharepoint",
        "Power Apps",
        "Other Applications",
      ],
      required: false,
      type: "dropdown",
      value: "Power BI",
    },
    {
      key: "url",
      label: "URL",
      required: false,
      type: "input",
      value: "",
    },
  ];
  // const [URLValid, setURLValid] = useState({ status: "pending", msg: "" });

  const handleChange = (key, value) => {
    const reqData = { [key]: key === "url" ? value.trim() : value };
    // setCategoryInfoLocal(
    //   updateSubCategoryReportState(
    //     categoryInfoLocal,
    //     catIndex,
    //     subCatIndex,
    //     reqData
    //   )
    // );
    dispatch(
      update_BIappDetails({
        categoryInfoLocal: updateSubCategoryReportState(
          categoryInfoLocal,
          catIndex,
          subCatIndex,
          reqData
        ),
      })
    );
  };

  // COMMENT: data is the element of fieldsV2
  //   KEY = bi_application || url
  //   TYPE = dropdown || input
  const getRequiredComponent = (data) => {
    const { type, key } = data;

    let width = null;
    if (key === "url") {
      width = 8;
    }

    switch (type) {
      case "input":
        return (() => {
          if (data.label === "URL") {
            return (
              <InputFormField
                data={data}
                handleChange={handleChange}
                value={
                  categoryInfoLocal[catIndex].sub_category[subCatIndex]
                    ?.SubCatHyperlinks?.[key]
                }
                width={width}
                bi_application={
                  categoryInfoLocal[catIndex].sub_category[subCatIndex]
                    ?.SubCatHyperlinks?.bi_application
                }
                URLValid={URLValid}
                setURLValid={setURLValid}
                isFieldVibrating={isSubCatReportvibrating}
                isFieldRed={isSubCatReportTextFieldRed}
                theme={theme}
              />
            );
          } else {
            return null; // or some other component / content
          }
        })();
      case "dropdown":
        return (
          <SelectComponent
            data={data}
            handleChange={handleChange}
            appFields={
              categoryInfoLocal[catIndex].sub_category[subCatIndex]
                ?.SubCatHyperlinks?.[key]
            }
            width={4}
            value={
              categoryInfoLocal[catIndex].sub_category[subCatIndex]
                ?.SubCatHyperlinks?.[key]
            }
            theme={theme}
          />
        );
      default:
        return "";
    }
  };
  return (
    <>
      {SubCatHyperLink ? (
        <Grid item xs={12} paddingTop="10px">
          <div
            className={`form-container ${
              isVibrating && "shake-category-field"
            } ${isFieldCardRed && "form-container-red"}`}
          >
            <Grid container spacing={2}>
              {(fieldsV2 || []).map((item) => getRequiredComponent(item))}
            </Grid>
          </div>
        </Grid>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  theme: state.user.ui_preferences?.theme,
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(SubCategoryFieldReport);
