import React from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router";
import SelectForCheckbox from "../../Components/SelectComponents/SelectForCheckbox";
import { connect } from "react-redux";
import { StyledAutoComplete, CssTextField, useStyles } from "../../style";
import "../../index.scss";

const SelectComponent = ({
  handleChange,
  data,
  value,
  width,
  filter,
  isFieldsEditable,
  appInfo,
  hasError,
  missingValues,
  setMissingValues,
  theme,
}) => {
  const { label, options = [], key, required } = data;
  const classes = useStyles();
  const { draftId } = useParams();

  return (
    <Grid item xs={width || 5} key={key}>
      <div className={`appDetailsLabel-heading ${theme}`}>
        {label} <span style={{ display: required ? "" : "none"}}>*</span>:
      </div>
      {(key === "associated_datasets" ||
        key === "tags" ||
        key === "geography" ||
        key === "function" ||
        key === "line_of_business") &&
      !filter ? (
        <SelectForCheckbox
          fieldkey={key}
          isFieldsEditable={isFieldsEditable}
          appInfo={appInfo}
          hasError={hasError}
          missingValues={missingValues}
          setMissingValues={setMissingValues}
          label={label}
          draft_app={draftId}
        />
      ) : (
        <StyledAutoComplete
          className={classes.typographyStyle}
          disabled={isFieldsEditable}
          value={value || ""}
          options={options}
          getOptionLabel={(option) => option}
          onChange={(e, newValue) => {
            handleChange(key, newValue);
          }}
          disableClearable
          renderInput={(params) => (
            <CssTextField
              {...params}
              className={classes.typographyStyle}
              fullWidth
              placeholder={`Choose ${label}`}
            />
          )}
          style={{
            // height: "auto",
            width: "100%",
          }}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});
export default connect(mapStateToProps)(SelectComponent);
