import React from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles, Box } from "@material-ui/core";
import AlertImg from "../../../assets/images/alertIcon.svg";
import PropTypes from "prop-types";
import Loader from "../Loader/Loader";

const useStyles = makeStyles(() => ({
  contained: {
    borderColor: "#4EAFB3",
    backgroundColor: "#4EAFB3",
    color: "white",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    width: 15,
    // minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Energy",
    "&:hover": {
      borderColor: "#4EAFB3",
      backgroundColor: "#4EAFB3",
      color: "white",
      boxShadow: "none",
    },
  },
  alertContainer: {
    width: "500px",
    // minWidth: "400px",
    // maxWidth: "600px",
    padding: "10px",
  },
}));
const DeleteAlert = ({
  open,
  handleClose,
  handleDelete,
  row,
  loadFav,
  AppsData,
  theme = "light",
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            backgroundImage: "none",
            boxShadow: "4px 4px 12px 4px #434343",
          },
        }}
      >
        {loadFav !== "loading" && (
          <div className={classes.alertContainer}>
            <Box display="flex" justifyContent="flex-end">
              <span class="material-icons-outlined" onClick={handleClose}>
                close
              </span>
            </Box>
            <DialogContent>
              <DialogContentText>
                <Box display="flex" alignItems="center" mb={1}>
                  <span
                    style={{
                      marginLeft: "10px",
                      color: theme === "dark" ? "#fff" : "#282828",
                    }}
                  >
                    Are you sure you want to permanently delete this
                    application?
                  </span>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button onClick={handleClose} className="cancel-btn">
                Cancel
              </button>
              <button
                onClick={(e) => {
                  handleDelete(e, row);
                }}
                className="btn"
              >
                Continue
              </button>
            </DialogActions>
          </div>
        )}
        {loadFav === "loading" && (
          <div style={{ width: "500px" }}>
            <Loader message="Deleting App..."></Loader>
          </div>
        )}
      </Dialog>
    </div>
  );
};
DeleteAlert.propTypes = {
  message: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
  redirect: PropTypes.any.isRequired,
  handleClose: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  AppsData: state.AppsData,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(DeleteAlert);
