import { useState } from "react";
import { Stack, TableCell } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useStyles } from "../../screens/Register/style";
import { formatDate } from "../../utils/utils";
import SecurityFilter from "../../screens/NewRegister/Components/SecurityFilter";
import { CssTextField, StyledTableRow, useStyles1 } from "./style";
import { StyledAutoComplete } from "../../screens/NewRegister/style";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";



const TableBodyComponent = ({
  row,
  columns,
  handleUpdateRole,
  handleDelete,
  obj,
  appDetails,
  user,
  // setAccessList,
  // accessList,
  // accessListClone,
  // setAccessListClone,
  securityFilterData,
  appType,
  accessOptions,
  accessRole,
  theme,
}) => {
  const classesTable = useStyles1();
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);

  const eventTracker = useAnalyticsEventTracker(
    "Access management",
    appDetails.data.app_id || ""
  );

  const notCreater = row.user_id !== appDetails.data.app_meta?.created_by;
  // hidding access lvl field for admin so that he can't update owner's access
  const isAdminAllowToChange =
    accessRole !== "admin" || (accessRole === "admin" && row.role !== "owner");

  const handleCloseSecurityFilter = () => {
    setOpenFilter(false);
  };

  const handleViewFilters = (value) => {
    if (accessRole === "admin" && value === "owner") return;
    setOpenFilter(true);
    eventTracker("View security filters", "open popup");
  };

  const handlehover = (e) => {
    e.target.style.color === "rgb(0, 0, 0)"|| e.target.style.color === "rgb(255, 255, 255)"
      ? (e.target.style.color = "#fc007f")
      :theme==="dark"
      ?(e.target.style.color = "#fff")
      : (e.target.style.color = "#000000");
  };

  // const handleOpenFilters = () => {
  //     setOpenFilter(true);
  // };

  return (
    <>
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={`${classesTable.tableRowCell} ${
                column.id === "access_granted_on" || column.id === "user_id"
                  ? classesTable.fixedWidth
                  : ""
              }`}
            >
              <>
                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  maxWidth="100%"
                >
                  {column.id === "role" &&
                  notCreater &&
                  isAdminAllowToChange ? (
                    <StyledAutoComplete
                      blurOnSelect
                      className={classes.typographyStyle}
                      classes={{
                        paper: classes.capitalOptions,
                      }}
                      style={{
                        width: "80px",
                      }}
                      disableClearable
                      value={value}
                      options={accessOptions}
                      onChange={(event, newValue) => {
                        handleUpdateRole(newValue, row);
                      }}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          variant="standard"
                          size="small"
                          placeholder={`Type role`}
                          className={classes.typographyStyle}
                        />
                      )}
                    />
                  ) : column.id === "access_granted_on" ? (
                    formatDate(value, "MMMM Do YYYY")
                  ) : (
                    <span
                      className={
                        column.id === "role"
                          ? classesTable.rolePadding
                          : column.id === "user_id"
                          ? classesTable.userId
                          : classesTable.ellipsis
                      }
                      title={value}
                    >
                      {value || ""}
                    </span>
                  )}
                  {column.id === "access_granted_on" &&
                    (appType === "bi_app" || appType === "standalone_app") &&
                    notCreater &&
                    isAdminAllowToChange && (
                      <span
                        class="material-icons-outlined edit-btn"
                        onClick={() => handleDelete(row)}
                      >
                        delete
                      </span>
                    )}
                  {column.id === "security_filter" && (
                    <div
                      style={{
                        width: "120px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        onClick={() => {
                          handleViewFilters(row.role);
                        }}
                        id="GA-view-filters"
                        style={{
                          color: theme === "dark" ? "#fff" : "#000",
                          fontSize: "14px",
                          fontWeight: "400",
                          cursor: "pointer",
                          marginRight: "20px",
                          whiteSpace: "nowrap",
                          textDecoration: "underline",
                        }}
                        onMouseOver={handlehover}
                        onMouseOut={handlehover}
                      >
                        View Filters
                      </span>

                      {notCreater && isAdminAllowToChange && (
                        <span
                          class="material-icons-outlined edit-btn"
                          onClick={() => handleDelete(row)}
                        >
                          delete
                        </span>
                      )}
                    </div>
                  )}
                </Stack>
              </>
            </TableCell>
          );
        })}
      </StyledTableRow>
      {openFilter && appType === "ml_app" && (
        <SecurityFilter
          open={openFilter}
          handleClose={handleCloseSecurityFilter}
          row={row}
          securityFilterData={securityFilterData}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  appDetails: state.AppsData.appDetails,
  user: state.user.preferred_username,
});

export default connect(mapStateToProps)(TableBodyComponent);
