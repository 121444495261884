import { makeStyles, withStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const CssTextField = withStyles((theme) => ({
  root: {
    // backgroundColor: "#fff",
    "& .MuiInputBase-root": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : theme.palette.dark.light,
    },
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.light
        : theme.palette.white.main,
    padding: "2px 7px 2px 0px",
    borderRadius: "3px",
    border:
      theme.palette.mode === "dark" ? "1px solid #757575" : "1px solid #E7E9EE",
    marginBottom: "5px",
    // boxShadow: "0px 5px 15px 0px #28282814",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      // backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
}))(TextField);

export const StyledAutoComplete = withStyles((theme) => ({
  tag: {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.white.main
        : theme.palette.dark.light,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.light
        : theme.palette.white.main,
    fontFamily: "Energy !important",
  },
  paper: {
    fontFamily: "Energy",
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.light
        : theme.palette.white.main,
    "& .MuiAutocomplete-option": {
      paddingLeft: "3px",
    },
  },
}))(Autocomplete);

export const useStyles = makeStyles((theme) => ({
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Energy",
      fontSize: 12,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Energy",
      fontSize: 12,
      paddingLeft: "10px",
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Energy",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Energy",
      fontSize: 12,
      fontWeight: 400,
      padding: "0px !important",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
    },
    "& .Mui-disabled": {
      opacity: "0.7",
      cursor: "not-allowed",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      marginRight: "-7px",
      "& span": {
        "& svg": {
          "& path": {
            fill: "#FF007F",
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
          },
        },
      },
    },
    "& .MuiAutocomplete-clearIndicator": {
      marginRight: "-8px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "14px",
    },
    "& .MuiIconButton-root": {
      fontSize: "14px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "24px",
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "6px 8px",
      // paddingRight: "18px !important",
      // paddingLeft: "7px",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "6px",
    },
  },
  typographyStyleSmall: {
    "& .MuiChip-root": {
      fontFamily: "Energy",
      fontSize: 11,
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Energy",
      fontSize: 11,
      paddingLeft: "10px",
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Energy",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Energy",
      fontSize: 11,
      paddingTop: "4px !important",
      paddingBottom: "5px !important",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      marginRight: "-7px",
      "& span": {
        "& svg": {
          "& path": {
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
          },
        },
      },
    },
    "& .MuiAutocomplete-clearIndicator": {
      marginRight: "-8px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "11px",
    },
    "& .MuiIconButton-root": {
      fontSize: "11px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "11px",
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingRight: "18px !important",
      paddingLeft: "7px",
    },
    "& .MuiAutocomplete-endAdornment": {
      right: "3px",
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Energy",
      fontSize: "11px !important",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.dark.light
          : theme.palette.white.main,
      "& .MuiAutocomplete-option": {
        paddingLeft: "10px",
      },
    },
  },
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "5px",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
      opacity: 0.5,
    },
  },
  select: {
    fontFamily: "Energy !important",
    fontSize: "16px!important",
    fontStyle: "normal!important",
    fontWeight: "400!important",
  },
  comboOptions: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          fill: "#FF007F",
          d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
        },
      },
    },
  },
  inputRoot: {
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
    background: "#ededed",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 26,
      fontFamily: "Energy",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "23px",
      letterSpacing: "0em",
      textAlign: "left",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
  },
  checkbox: {
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
  active: {
    "&.MuiFormControl-root": {
      backgroundColor: "#282828",
      // color: "#fff",
      color: "rgba(255,255,255,0.8) !important",
    },
    "& .MuiInputBase-root": {
      // color: "#fff",
      color: "rgba(255,255,255,0.8) !important",
    },
    "& .MuiSvgIcon-root": {
      // color: "#fff",
      color: "rgba(255,255,255,0.8)",
    },
  },
  activeAuto: {
    "&.MuiFormControl-root": {
      backgroundColor: "#282828",
      color: "#fff",
      // color: "rgba(255,255,255,0.5)",
    },
    "& .MuiInputBase-root": {
      color: "#fff",
      // color: "rgba(255,255,255,0.5)",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
      // color: "rgba(255,255,255,0.5)",
    },
  },
  unActive: {
    "&.MuiFormControl-root": {
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.dark.light : "white",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
    },
    "& .MuiInputBase-root": {
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
    },
    "& .MuiSvgIcon-root": {
      color: "#FC007F",
    },
  },
  SecFilUnActive: {
    "&.MuiFormControl-root": {
      backgroundColor: theme.palette.mode === "dark" ? "#4e4e4e" : "#f6f6f6",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    },
    "& .MuiInputBase-root": {
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    },
  },
  raderOptions: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  countriesList: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "10px",
    gap: "5px",
  },
  regionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconButton: {
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  optionPadding: {
    "& .MuiAutocomplete-option": {
      padding: "0px",
      marginLeft: "10px",
    },
  },
}));
