import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CovidFaq(props) {
let {setOpen , open } = props ;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Covid Data FAQs
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>1. What is mobility data?</Typography>
          <p>
            Mobility Data intends to help remediate the impact of Covid-19. The
            report is broken down by location and displays the change in visits
            to places like grocery stores and parks; though location accuracy
            and the understanding of categorised places varies from region to
            region. It also shows how visits and length of stay at different
            places change compared to a baseline.\n What data is included in the
            calculation depends on user settings, connectivity, and whether it
            meets privacy threshold. When the data doesn't meet quality and
            privacy thresholds, you might see empty fields for certain places
            and dates. It includes categories that are useful for social
            distancing efforts as well as access to essential services."
          </p>
          <Typography gutterBottom>
            2. What is the source of vaccination data?
          </Typography>
          <p>
            Vaccination Data represents people vaccinated and population
            vaccination rate regarding various Covid-19 vaccines. It is
            collected from medical ministries of various federal governments
            around the world as well as open, publicly sourced, licensed data
            relating to demographics, economy, epidemiology, geography, health,
            hospitalisations, mobility, government response, weather and more.
            Country wide data is not sum of the states or territories, as in
            some countries there is a portion of vaccinations managed by the
            Federal government that is delivered directly to aged and disability
            care and not counted as part of the states/territories. CDC
            contributes to the vaccination data as well.
          </p>
          <Typography gutterBottom>
            3. What is the source of all datasets?
          </Typography>
          <p>
            "Most of the dataset is obtained from the COVID-19 Open Data
            repository from GIT. This repository attempts to assemble the
            largest Covid-19 epidemiological database in addition to a powerful
            set of expansive covariates. It includes open, publicly sourced,
            licensed data relating to demographics, economy, epidemiology,
            geography, health, hospitalizations, mobility, government response,
            weather, and more. Moreover, the data merges daily time-series,
            +20,000 global sources, at a fine spatial resolution, using a
            consistent set of region keys. All regions are assigned a unique
            location key, which resolves discrepancies between ISO / NUTS / FIPS
            codes, etc. The different aggregation levels are Country, Province,
            state, or local equivalent, Municipality, county, or local
            equivalent, Locality which may not follow strict hierarchical order,
            such as \"city\" or \"nursing homes in X location\". \n There are
            multiple types of data: (\n 1) Outcome data Y(i,t), such as cases,
            tests, hospitalizations, deaths and recoveries, for region i and
            time\n2) Static covariate data X(i), such as population size, health
            statistics, economic indicators, geographic boundaries(n3) Dynamic
            covariate data X(i,t), such as mobility, search trends, weather, and
            government interventions"
          </p>
          <Typography gutterBottom>
            4. When are these reports refreshed with latest data?
          </Typography>
          <p>
            We use various open source datasets that are refreshed each day. The
            last time the reports were refreshed with available data is
            displayed on the bottom of the reports.
          </p>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );}
