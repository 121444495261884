import C from "./constants";

export const changeTheme = (theme) => ({
  type: C.CHANGE_THEME,
  theme,
});

export const updateMultiSelections = (data) => ({
  type: C.UPDATE_MULTI_SELECTIONS,
  data,
});

export const updateAlertInfo = (data) => ({
  type: C.UPDATE_ALERT_INFO,
  data,
});

export const updateUserInfo = (data) => ({
  type: C.UPDATE_USER_INFO,
  data,
});

export const resetFilters = (data) => ({
  type: C.RESET_FILTERS,
});

export const updateDialogInfo = (data) => ({
  type: C.UPDATE_DIALOG_INFO,
  data,
});

export const toggleSidenav = (data) => ({
  type: C.TOGGLE_SIDENAV,
});

export const refreshComments = (data) => ({
  type: C.REFRESH_COMMENTS,
});

export const setActiveAssetCollection = (data) => ({
  type: C.SET_ACTIVE_ASSET_COLLECTION,
  data,
});

export const getRegisterAppTemplate = (data) => ({
  type: C.GET_REGISTER_APP_TEMPLATE,
  data,
});

export const getupdateReportViewDetails = (data) => ({
  type: C.GET_UPDATE_VIEW_REPORT_DETAILS,
  data,
});

export const getAllApps = (data) => ({
  type: C.GET_ALL_APPS,
  data,
});

export const getManageApps = (data) => ({
  type: C.GET_MANAGE_APPS,
  data,
});

export const getFavApps = (data) => ({
  type: C.GET_FAV_APPS,
  data,
});

export const getRecentApps = (data) => ({
  type: C.GET_RECENT_APPS,
  data,
});

export const getCategoryList = (data) => ({
  type: C.GET_ALL_CATEGORY_LIST,
  data,
});

export const validateApplicationName = (data) => ({
  type: C.VALIDATE_APPLICATION_NAME,
  data,
});

export const getAppDetails = (data, appType) => ({
  type: C.GET_APP_DETAILS,
  data,
  appType,
});

export const updateAppDetails = (data, appType) => ({
  type: C.UPDATE_APP_DETAILS,
  data,
  appType,
});

export const get_Views_comments = (data) => ({
  type: C.GET_APP_VIEWS_COMMENTS,
  data,
});

export const app_views_comments_received = (data) => ({
  type: C.APP_VIEWS_COMMENTS_RECEIVED,
  data,
});

export const add_comments = (data) => ({
  type: C.ADD_COMMENTS,
  data,
});

export const get_updated_comments = (data) => ({
  type: C.GET_UPDATED_COMMENTS,
  data,
});

export const updated_comments_received = (data) => ({
  type: C.UPDATED_COMMENTS_RECEIVED,
  data,
});

export const updated_total_views = (data) => ({
  type: C.UPDATE_TOTAL_VIEWS,
  data,
});

export const cleanAppDetails = () => ({
  type: C.CLEAN_APP_DETAILS,
});

export const fetchRouteData = () => ({
  type: C.GET_NEW_ROUTE,
});

export const getRefreshedApps = () => ({
  type: C.GET_ALL_REF_APPS,
});

export const getRefreshedLayoutApps = (data) => ({
  type: C.GET_LAYOUT_REF_APPS,
  data,
});

export const getRefreshedManageApps = (data) => ({
  type: C.GET_REF_MANAGE_APPS,
  data,
});
export const getRefreshedRecentApps = () => ({
  type: C.GET_REF_RECENT_APPS,
});
export const getRefreshedFavApps = () => ({
  type: C.GET_REF_FAV_APPS,
});

export const getUsersList = (data) => ({
  type: C.GET_USERS_LIST,
  data,
});

export const cleanUserList = () => ({
  type: C.CLEAN_USERS_LIST,
});

export const getCountryBrands = (data) => ({
  type: C.GET_COUNTRY_BRANDS,
  data,
});

export const getUniqueBackendRepo = (data) => ({
  type: C.GET_UNIQUE_BACKEND_REPO,
  data,
});
export const getUniqueFrontendRepo = (data) => ({
  type: C.GET_UNIQUE_FRONTEND_REPO,
  data,
});
export const updateUniqueRepos = (data) => ({
  type: C.UPDATE_REPOS,
});
export const validateAppUrl = (data) => ({
  type: C.VALIDATE_UNIQUE_APP_URL,
  data,
});

export const getLayoutApps = (data) => ({
  type: C.GET_LAYOUT_APPS,
  data,
});

export const getRecommendedApps = (data) => ({
  type: C.GET_RECOMMENDED_APPS,
  data,
});

export const updateRecommendedApps = (data) => ({
  type: C.UPDATE_RECOMMENDED_APPS,
  data,
});

export const updateTagsDatasets = (data) => ({
  type: C.UPDATE_DATASETS_TAGS,
  data,
});

export const get_unique_app_handle = (data) => ({
  type: C.GET_UNIQUE_APP_HANDLE,
  data,
});
export const get_usage_report_users = (data) => ({
  type: C.GET_USAGE_REPORT_USERS,
  data,
});
export const clean_usage_report_users = () => ({
  type: C.CLEAN_USAGE_REPORT_USERS,
});
export const get_user_access_list = (data) => ({
  type: C.GET_USER_ACCESS_LIST,
  data,
});
export const refresh_user_access_list = (data, id) => ({
  type: C.REFRESH_USER_ACCESS_LIST,
  data,
});
export const clean_user_access_list = () => ({
  type: C.CLEAN_USER_ACCESS_LIST,
});
export const get_signup_templates = () => ({
  type: C.GET_SIGNUP_TEMPLATES,
});
export const update_job_role_other_options = (data) => ({
  type: C.UPDATE_JOB_ROLE_OTHER_OPTIONS,
  data,
});
export const update_function_other_options = (data) => ({
  type: C.UPDATE_FUNCTION_OTHER_OPTIONS,
  data,
});

export const update_user = () => ({
  type: C.UPDATE_USER,
});

export const update_home_layout = (data) => ({
  type: C.UPDATE_HOME_LAYOUT,
  data,
});

export const updateLoyoutDataList = (data) => ({
  type: C.UPDATE_LAYOUT_DATA_LIST,
  data,
});

export const updateDraftstatus = (data) => ({
  type: C.UPDATE_DRAFT_STATUS,
  data,
});
export const update_ml_app_url = (data) => ({
  type: C.UPDATE_ML_APP_URL,
  data,
});
export const update_app_handle = (data) => ({
  type: C.UPDATE_APP_HANDLE,
  data,
});
// sync app's access list with uat or dev
export const syncAccessInEnv = (data) => ({
  type: C.SYNC_ACCESS_IN_ENV,
  data,
});

export const setCurrentTab = (data) => ({
  type: C.UPDATE_CURRENT_TAB,
  data,
});
// help center api's
export const get_help_center_templates = () => ({
  type: C.GET_HELP_CENTER_TEMPLATES,
});

export const set_help_center_state = (data) => ({
  type: C.SET_HELP_CENTER_STATE,
  data,
});
export const get_app_list = () => ({
  type: C.GET_APP_LIST,
});
export const send_mail_to_app_owners = (data) => ({
  type: C.SEND_MAIL_TO_APP_OWNER,
  data,
});
export const save_ticket_meta = (data) => ({
  type: C.SAVE_TICKET_ID,
  data,
});
export const update_power_bi_workspace = (data) => ({
  type: C.UPDATE_WORK_SPACE,
  data,
});
export const update_power_bi_reports = (data) => ({
  type: C.UPDATE_POWER_BI_REPORTS,
  data,
});
export const update_dublicate_workspace_list = (data) => ({
  type: C.DUBLICATE_WORKSPACE_LIST,
  data,
});
// app-dashboard

export const updateAppDashboardState = (data) => ({
  type: C.UPDATE_APP_DASHBOARD,
  data,
});

export const getAppDashboardDetails = (data) => ({
  type: C.GET_APP_DASHBOARD_DETAILS,
  data,
});

// app-template-creation/updation

export const addNewReportToTemplate = (data) => ({
  type: C.ADD_REPORT_TEMPLATE,
  data,
});

export const deleteReportFromTemplate = (data) => ({
  type: C.DELETE_REPORT_TEMPLATE,
  data,
});

export const updateReportTemplate = (data) => ({
  type: C.UPDATE_REPORT_TEMPLATE,
  data,
});

export const updateReportDesc = (data) => ({
  type: C.UPDATE_REPORT_DESC,
  data,
});

export const updatePositionMeta = (data) => ({
  type: C.UPDATE_POSITION_META,
  data,
});

export const getAppData = (data) => ({
  type: C.UPDATE_POSITION_META,
  data,
});

export const cleanTempState = () => ({
  type: C.CLEAN_APP_TEMP_STATE,
});
export const updateGroupMembers = (data) => ({
  type: C.UPDATE_AZURE_GROUP_MEMBERS,
  data,
});
export const get_user_filters = (data) => ({
  type: C.GET_USER_FILTERS,
  data,
});
export const update_user_filters = (data) => ({
  type: C.UPDATE_USER_FILTERS,
  data,
});
export const get_top_users_app = (data, appId) => ({
  type: C.GET_TOP_USERS_APP,
  data,
  appId,
});
export const cleanTopUsersState = () => ({
  type: C.CLEAN_TOP_USER_STATE,
});
export const clean_register_states = (data) => ({
  type: C.CLEAN_REGISTER_STATES,
  data,
});
export const getDefinedCategory = (data) => ({
  type: C.GET_DEFINED_CATEGORY,
  data,
});

export const update_explore_apps = (data) => ({
  type: C.UPDATE_EXPLORE_APPS,
  data,
});
export const clean_explore_apps = (data) => ({
  type: C.CLEAN_EXPLORE_APPS,
});

// Changes by Nomaan
export const update_BIappDetails = (data) => ({
  type: C.UPDATE_BI_APP_DETAILS,
  data,
});

export const update_MLappDetails = (data) => ({
  type: C.UPDATE_ML_APP_DETAILS,
  data,
});

export const update_accessManagement = (data) => ({
  type: C.UPDATE_ACCESS_MANAGEMENT,
  data,
});

export const update_appData = (data) => ({
  type: C.UPDATE_APP_DATA,
  data,
});

export const update_statusData = (data) => ({
  type: C.UPDATE_STATUS_DATA,
  data,
});

export const update_activeStepper = (data) => ({
  type: C.UPDATE_ACTIVE_STEPPER,
  data,
});

export const clean_registerNewApp_state = () => ({
  type: C.CLEAN_NEW_APP_REGISTER_STATES,
});

export const update_powerBiToken = (data) => ({
  type: C.UPDATE_TOKEN,
  data,
});
