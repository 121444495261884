import React from "react";
import { Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import CreateFooterContainer from "../Components/CreateFooterContainer.jsx";
import CreateRegisterBodyContainer from "../Components/CreateRegisterBodyContainer.jsx";
import { useDispatch, connect } from "react-redux";
import biapp_icon from "../../../../assets/images/surface1.svg";
import mlapp_icon from "../../../../assets/images/Union.svg";
import standaloneapp_icon from "../../../../assets/images/Vector.svg";
import { update_activeStepper } from "../../../redux/actions.js";
const AppTypeComponent = ({
  // setActiveStepper,
  templates,
  appFields,
  handleChange,
  theme,
  activeStepper,
}) => {
  const dispatch = useDispatch();
  const { key, options } = templates?.fields[0];
  let selected_description, selected_label;
  options?.map((item) => {
    if (item.key === appFields.app_type) {
      selected_description = item.description;
      selected_label = item.label;
    }
  });

  return (
    <>
      <CreateRegisterBodyContainer>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={"15px"}
          sx={{
            pb: "25px",
            minWidth: "834px",
            maxWidth: "1144px",
            width: "83%",
            "@media (min-height: 1080px)": {
              pb: "50px",
            },
          }}
        >
          {options?.map((item) => (
            <div
              className={`apptype-card ${theme}-apptype-card ${
                appFields.app_type === item.key ? "apptype-card-selected" : ""
              }`}
              key={item.key}
              onClick={() => handleChange(key, item.key)}
            >
              <div className="apptype-card-icon">
                <img
                  src={
                    item.key === "embedded_analytics"
                      ? biapp_icon
                      : item.key === "ml_app"
                      ? mlapp_icon
                      : standaloneapp_icon
                  }
                  alt="embedded_analytics"
                ></img>
              </div>
              <div className="apptype-card-title">
                {item.label}
                <div className="apptype-card-subtitle">{item.subtitle}</div>
              </div>
            </div>
          ))}
        </Box>
        <div
          className={`appType-description-title appType-description-title-${theme}`}
        >
          {selected_label}
          <div className={`appType-description appType-description-${theme}`}>{selected_description}</div>
        </div>
      </CreateRegisterBodyContainer>
      <CreateFooterContainer>
        <button
          className="btn btn-next"
          onClick={() => {
            // setActiveStepper((prev) => prev + 1);
            dispatch(update_activeStepper(activeStepper + 1));
          }}
        >
          Next
        </button>
      </CreateFooterContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeStepper: state.registerNewApp.activeStepper,
});

export default connect(mapStateToProps)(AppTypeComponent);
