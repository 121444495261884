import React, { useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { CssTextField, StyledAutoComplete, useStyles } from "../../style";

const AutoWithAsyncLoad = ({
  handleSelect,
  list,
  options,
  handleChange,
  my,
  placeholder,
  onFocusField = () => {},
  activeKey,
  elementKey,
  mb
}) => {
  const autoC = useRef(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <Box display="flex" alignItems="center" my={my || 2} mb={mb || ""}>
      <StyledAutoComplete
        id="asynchronous-demo"
        size="small"
        style={{
          fontFamily: "Energy !important",
          // width: "450px",
        }}
        fullWidth
        className={classes.typographyStyle}
        open={open}
        ref={autoC}
        value={null}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onFocus={() => {
          onFocusField(elementKey);
        }}
        onBlur={() => {
          onFocusField("");
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderTags={() => null}
        getOptionSelected={(option, value) => option.user_id === value.user_id}
        onChange={(event, newValue) => {
          handleSelect(newValue);
          const ele = autoC.current.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
          )[0];
          if (ele) ele.click();
        }}
        getOptionLabel={(option) => option.user_id}
        options={options}
        loading={list.status === "loading"}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="standard"
            size="small"
            placeholder={placeholder || "Add users"}
            className={`${classes.typographyStyle} ${classes.tagsInput}`}
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              width: "98%",
            }}
            onChange={(ev) => {
              // dont fire API if the user delete or not entered anything
              if (ev.target.value !== "" || ev.target.value !== null) {
                handleChange(ev.target.value);
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {list.status === "loading" && activeKey === elementKey ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutoWithAsyncLoad;
