import C from "../constants";

export default function AppsData(state = {}, payload) {
    let { type, data, message, appId } = payload;
    switch (type) {
        case C.GET_ALL_APPS:
            return {
                ...state,
                allApps: { status: "loading", message: "", data: [] },
            };

        case C.GET_LAYOUT_APPS:
            return {
                ...state,
                layoutApps: { status: "loading", message: "", data: [] },
            };

        case C.LAYOUT_APPS_RECEIVED:
            return { ...state, layoutApps: data };

        case C.GET_RECOMMENDED_APPS:
            return {
                ...state,
                recommendedApps: { status: "loading", message: "", data: [] },
            };

        case C.RECOMMENDED_APPS_RECEIVED:
            return { ...state, recommendedApps: data };

        case C.UPDATE_RECOMMENDED_APPS:
            return {
                ...state,
                recommendedApps: { status: "success", message: "", data },
            };
        case C.GET_COUNTRY_BRANDS:
            return {
                ...state,
                countryBrands: { status: "loading", message: "", data: [] },
            };
        case C.COUNTRY_BRANDS_RECEIVED:
            return { ...state, countryBrands: data };

        case C.ALL_APPS_RECEIVED:
            return { ...state, allApps: data };
        case C.GET_MANAGE_APPS:
            return {
                ...state,
                manageApps: { status: "loading", message: "", data: [] },
            };
        case C.GET_FAV_APPS:
            return {
                ...state,
                favApps: { status: "loading", message: "", data: [] },
            };
        case C.GET_RECENT_APPS:
            return {
                ...state,
                recentApps: { status: "loading", message: "", data: [] },
            };
        case C.GET_ALL_REF_APPS:
            return {
                ...state,
                refreshedAllApps: { status: "loading", message: "", data: [] },
            };
        case C.ALL_REF_APPS_RECEIVED:
            return {
                ...state,
                refreshedAllApps: { status: "success", message: "", data },
            };
        case C.GET_LAYOUT_REF_APPS:
            return {
                ...state,
                refreshedLayoutApps: {
                    status: "loading",
                    message: "",
                    data: [],
                },
            };
        case C.LAYOUT_REF_APPS_RECEIVED:
            return {
                ...state,
                refreshedLayoutApps: data,
            };
        case C.GET_REF_FAV_APPS:
            return {
                ...state,
                refreshedFavApps: { status: "loading", message: "", data: [] },
            };
        case C.REF_FAV_APPS_RECEIVED:
            return {
                ...state,
                refreshedFavApps: { status: "success", message: "", data },
            };
        case C.GET_REF_MANAGE_APPS:
            return {
                ...state,
                refreshedManageApps: {
                    status: "loading",
                    message: "",
                    data: [],
                },
            };
        case C.REF_MANAGE_APPS_RECEIVED:
            return {
                ...state,
                refreshedManageApps: { status: "success", message: "", data },
            };
        case C.GET_REF_RECENT_APPS:
            return {
                ...state,
                refreshedRecentApps: {
                    status: "loading",
                    message: "",
                    data: [],
                },
            };
        case C.REF_RECENT_APPS_RECEIVED:
            return {
                ...state,
                refreshedRecentApps: { status: "success", message: "", data },
            };
        case C.GET_NEW_ROUTE:
            return {
                ...state,
                allRoutes: { status: "loading", message: "", data: [] },
            };
        case C.ALL_ROUTES_RECEIVED:
            return {
                ...state,
                allRoutes: { status: "success", message: "", data },
            };
        case C.MANAGE_APPS_RECEIVED:
            return { ...state, manageApps: data };

        case C.FAV_APPS_RECEIVED:
            return { ...state, favApps: data };
        case C.RECENT_APPS_RECEIVED:
            return { ...state, recentApps: data };

        case C.GET_APP_DETAILS:
            return {
                ...state,
                appDetails: { status: "loading", message: "", data: {} },
            };
        case C.APP_DETAILS_RECEVIED:
            return {
                ...state,
                appDetails: {
                    status: data.status,
                    message: data.msg,
                    data: data.data,
                },
            };

        case C.CLEAN_APP_DETAILS:
            return {
                ...state,
                appDetails: { status: "pending", message: "", data: {} },
            };
        case C.UPDATE_APP_DETAILS:
            return {
                ...state,
                appDetails: { ...state.appDetails, status: "loading" },
            };
        case C.GET_APP_VIEWS_COMMENTS:
            return {
                ...state,
                viewsNComments: { status: "loading", message: "", data: {} },
            };
        case C.APP_VIEWS_COMMENTS_RECEIVED:
            return {
                ...state,
                viewsNComments: {
                    status: data.status,
                    message: data.msg,
                    data: data.data,
                },
            };

        case C.GET_UPDATED_COMMENTS:
            return {
                ...state,
                updatedViewsNComments: {
                    status: "loading",
                    message: "",
                    data: {},
                },
            };
        case C.UPDATED_COMMENTS_RECEIVED:
            return {
                ...state,
                updatedViewsNComments: { status: "success", message: "", data },
            };

        case C.UPDATE_TOTAL_VIEWS:
            return {
                ...state,
                updateViews: { status: "loading", message: "" },
            };
        case C.TOTAL_VIEWS_UPDATED:
            return {
                ...state,
                updateViews: { status: "success", message: "" },
            };
        case C.GET_USERS_LIST:
            return {
                ...state,
                usersList: { status: "loading", message: "", data: [] },
            };
        case C.USERS_LIST_RECEIVED:
            return {
                ...state,
                usersList: { status: "success", message: "", data },
            };
        case C.CLEAN_USERS_LIST:
            return {
                ...state,
                usersList: { status: "success", message: "", data: [] },
            };
        case C.GET_PUBLISH_APP:
            return {
                ...state,
                usersList: { status: "loading", message: "" },
            };
        case C.PUBLISH_APP_RECEIVED:
            return {
                ...state,
                usersList: { status: "success", message },
            };
        case C.UPDATE_HOME_LAYOUT:
            return {
                ...state,
                layoutStatus: { status: "loading", message },
            };
        case C.HOME_LAYOUT_UPDATED:
            return {
                ...state,
                layoutStatus: {
                    status: data.status,
                    message: data.msg,
                },
            };
        case C.UPDATE_LAYOUT_DATA_LIST:
            return {
                ...state,
                layoutDataList: {
                    status: "",
                    message: "",
                    data,
                },
            };
        case C.GET_USER_FILTERS:
            return {
                ...state,
                user_filters: {
                    status: "loading",
                    message: "",
                    data: {},
                },
            };
        case C.USER_FILTERS_RECEIVED:
            return {
                ...state,
                user_filters: {
                    status: data.status,
                    message: data.msg,
                    data: data.data,
                },
            };
        case C.GET_TOP_USERS_APP:
            return {
                ...state,
                topUsersApp: {
                    ...state.topUsersApp,
                    ...data.data,
                    [appId]: {
                        status: "loading",
                        message: "",
                        data: {},
                    },
                },
            };
        case C.TOP_USERS_RECEIVED:
            return {
                ...state,
                topUsersApp: {
                    ...state.topUsersApp,
                    ...data.data,
                },
            };
        case C.CLEAN_TOP_USER_STATE:
            return {
                ...state,
                topUsersApp: {},
            };
        case C.GET_DEFINED_CATEGORY:
            return {
                ...state,
                defined_categories: {
                    ...state.defined_categories,
                    [payload.data.app_id]: {
                        ...(state.defined_categories[payload.app_id] || {}),
                        status: "loading",
                    },
                },
            };
        case C.DEFINED_CATEGORY_RECEIVED:
            return {
                ...state,
                defined_categories: {
                    ...state.defined_categories,
                    ...data.data,
                },
            };
        case C.UPDATE_EXPLORE_APPS:
            return {
                ...state,
                explore_apps: {
                    ...state.explore_apps,
                    ...data,
                },
            };
        case C.CLEAN_EXPLORE_APPS:
            return {
                ...state,
                explore_apps: {},
            };
        default:
            return state;
    }
}
