import C from "../constants";

export default function user(state = {}, payload) {
    let { type, data } = payload;
    switch (type) {
        case C.UPDATE_MULTI_SELECTIONS:
            return {
                ...state,
                selections: { ...state.selections, ...data },
            };
        case C.UPDATE_ALERT_INFO:
            return {
                ...state,
                alertInfo: { ...data },
            };
        case C.UPDATE_DIALOG_INFO:
            return {
                ...state,
                dialogInfo: { ...data },
            };
        case C.UPDATE_USER_INFO:
            return {
                ...state,
                ...data,
            };
        case C.RESET_FILTERS:
            return {
                ...state,
                selections: {
                    ...state.selections,
                    country: "ALL",
                    segment: "ALL",
                    brand: "ALL",
                    searchText: "",
                },
            };
        case C.TOGGLE_SIDENAV:
            return {
                ...state,
                screen: {
                    ...state.screen,
                    isSidenavBarExpanded: !state.screen.isSidenavBarExpanded,
                },
            };
        case C.USER_UPDATED:
            return {
                ...state,
                ...data,
            };
        default:
            return state;
    }
}
