import React from "react";
import { Box, IconButton } from "@mui/material";
import MapComponent from "./component/MapComponent";
import locationLogo from "../../../assets/images/setup-images/locationLogo.svg";
import {
    LATAMCountries,
    APACCountries,
    middleEastList,
    europeCountriesList,
    northAmericanCountries,
    affricanCountries,
} from "./data";
import equal from "deep-equal";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Loader from "../../components/Loader/Loader";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const LocationScreen = ({
    setActiveScreen,
    configData,
    dummyData,
    setDummyData,
    handleChange,
    theme,
    template,
    handleUpdate,
    userInfo,
    waiting,
    tab,
    loading,
    handleSubmit,
}) => {
    const eventTracker = useAnalyticsEventTracker("Signup Geographies");

    const isDisabled = () => {
        if (
            equal(
                userInfo.user_interests?.geography?.sort(),
                configData.geography?.sort()
            ) ||
            !configData.geography?.length
        ) {
            return true;
        } else return false;
    };

    const handleRemoveDummyValues = (val) => {
        if (val === "Europe") {
            const filterData = dummyData.filter(
                (item) => !europeCountriesList.includes(item)
            );
            // const filterData = dummyData.filter((item) => item !== "Europe");
            setDummyData(filterData);
        } else if (val === "LATAM") {
            const filterData = dummyData.filter(
                (item) => !LATAMCountries.includes(item)
            );
            setDummyData(filterData);
        } else if (val === "APAC") {
            const filterData = dummyData.filter(
                (item) => !APACCountries.includes(item)
            );
            setDummyData(filterData);
        } else if (val === "North America") {
            const filterData = dummyData.filter(
                (item) => !northAmericanCountries.includes(item)
            );
            setDummyData(filterData);
        } else if (val === "AMET") {
            const filterData = dummyData.filter(
                (item) =>
                    !affricanCountries.includes(item) &&
                    !middleEastList.includes(item)
            );
            setDummyData(filterData);
        } else {
            const filterData = dummyData.filter((item) => item !== val);
            setDummyData(filterData);
        }
    };
    const handleAddDummyValues = (val) => {
        if (val === "Europe") {
            setDummyData([...dummyData, ...europeCountriesList]);
            // setDummyData([...dummyData, "Europe"]);
        } else if (val === "LATAM") {
            setDummyData([...dummyData, ...LATAMCountries]);
        } else if (val === "APAC") {
            setDummyData([...dummyData, ...APACCountries]);
        } else if (val === "North America") {
            setDummyData([...dummyData, ...northAmericanCountries]);
            // setDummyData([...dummyData, "Northern America"]);
        } else if (val === "AMET") {
            setDummyData([
                ...dummyData,
                ...affricanCountries,
                ...middleEastList,
            ]);
        } else {
            setDummyData([...dummyData, val]);
        }
    };

    const handleRegion = (val) => {
        if (configData.geography?.includes(val)) {
            handleRemoveDummyValues(val);
        } else {
            handleAddDummyValues(val);
        }
        handleChange("geography", val);
        eventTracker("Region clicked", val);
    };

    const handleBack = () => {
        setActiveScreen((prev) => prev - 1);
        eventTracker("Back btn clicked", "navigate");
    };

    const handleUpdateWithTrack = () => {
        handleUpdate({ geography: configData.geography });
        eventTracker("Update btn clicked", "save");
    };

    const handleFinish = () => {
        handleSubmit();
        eventTracker("Finish btn clicked", "save");
    };

    return (
        <>
            <div>
                <img
                    src={locationLogo}
                    alt=""
                    className="setup-card-container-logo"
                />
                <div className="setup-heading">
                    {template.Description || ""}
                </div>
            </div>
            <div
                style={{
                    width: "400px",
                    height: "245px",
                    overflow: "hidden",
                    marginRight: "40px",
                    marginTop: "-40px",
                }}
            >
                <MapComponent regions={dummyData} />
            </div>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
                width="600px"
                gap="14px"
                zIndex={1000}
            >
                {template.options.length &&
                    template.options.map((item) => (
                        <button
                            className={`setup-geo-btn ${theme}-setup-geo-btn ${
                                configData.geography?.includes(item)
                                    ? "setup-geo-btn-selected"
                                    : "etup-geo-btn-unslected"
                            } `}
                            onClick={() => handleRegion(item)}
                        >
                            {item}
                        </button>
                    ))}
            </Box>
            <button
                className="setup-btn"
                onClick={handleFinish}
                disabled={loading || !configData.geography?.length || waiting}
            >
                {loading ? <Loader color="#fff" /> : "Finish"}
            </button>
            <IconButton
                className="setup-back-btn"
                onClick={handleBack}
                disabled={waiting}
            >
                <KeyboardBackspaceIcon />
            </IconButton>
            {tab && (
                <button
                    className="setup-btn-update"
                    disabled={isDisabled() || waiting}
                    // onClick={() =>
                    //     handleUpdate({ geography: configData.geography })
                    // }
                    onClick={handleUpdateWithTrack}
                >
                    {waiting ? <Loader /> : "Update"}
                </button>
            )}
        </>
    );
};

export default LocationScreen;
