import { makeStyles } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

export const useStylesForDashboardTemplate = makeStyles((theme) => ({
    alertContainer1: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& .MuiDialog-paper": {
            overflowY: "hidden",
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "1350px !important",
            minWidth: "1350px !important",
            maxHeight: "750px !important",
            minHeight: "750px !important",
        },
    },
    discardBtn: {
        backgroundColor: "transparent !important",
        color: theme.palette.mode==="dark"?"#fff !important":"#000 !important",
        marginRight: "10px",
        border: theme.palette.mode==="dark"?"1px solid #fff":"1px solid #000",
    },
    alertContainer2: {
        "& .MuiDialog-paper": {
            overflowY: "hidden",
            padding: "20px",
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "900px !important",
            minWidth: "900px !important",
            maxHeight: "650px !important",
            minHeight: "650px !important",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow: "4px 4px 12px 4px #434343",
        },
    },
    heading: {
        fontFamily: "Energy !important",
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "12px !important",
        lineHeight: "23px !important",
        color: theme.palette.mode==="dark"?"#fff":"#000",
        textAlign: "start",
        margin: "10px 5px 0px 0px",
        textTransform: "uppercase"
    },
    lightText: {
        opacity: "0.5",
    },
    footerContainer: {
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
    },
}));

export const StyleSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor:
                    // theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
                    "#fc007f",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        // backgroundColor:
        //     theme.palette.mode === "dark"
        //         ? "rgba(255,255,255,.35)"
        //         : "rgba(0,0,0,.25)",
        backgroundColor: "#8d9ca6",
        boxSizing: "border-box",
    },
}));
