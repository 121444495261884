import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Grid } from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useStylesforImageSelector } from "../ImageSelector/Styles";
import { useStylesforTextEditor } from "../TextEditor/Styles";

const options = [
  "inline",
  "blockType",
  "fontSize",
  "colorPicker",
  "fontFamily",
];
export const TextEditor = (props) => {
  const {
    editorState,
    handleEditorState,
    customOptions = null,
  } = props;
  const classes = useStylesforTextEditor();

  return (
    <Grid item xs={12} className={classes.container} sx={{ color: "#282828" }}>
      <Editor
        editorState={editorState["sub_category_description"]}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={(reqData) => handleEditorState("sub_category_description", reqData)}
        toolbar={{
          options: customOptions || options,
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ["bold", "italic", "underline"],
          },
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana",
              "Energy",
              "Source Sans Pro",
              "Lucida Grande",
              "Sans-serif",
            ],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          colorPicker: {
            icon: undefined,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            colors: [
              "rgb(255, 0, 127)",
              "rgb(90, 40, 130)",
              "rgb(85, 195, 240)",
              "rgb(0, 166, 165)",
              "rgb(231, 233, 238)",
              "rgb(0, 88, 170)",
              "rgb(80, 180, 40)",
              "rgb(240, 205, 40)",
              "rgb(253, 99, 32)",
              "rgb(240, 140, 42)",
              "rgb(66, 89, 108)",
              "rgb(97,189,109)",
              "rgb(26,188,156)",
              "rgb(84,172,210)",
              "rgb(44,130,201)",
              "rgb(147,101,184)",
              "rgb(71,85,119)",
              "rgb(204,204,204)",
              "rgb(65,168,95)",
              "rgb(0,168,133)",
              "rgb(61,142,185)",
              "rgb(41,105,176)",
              "rgb(85,57,130)",
              "rgb(40,50,78)",
              "rgb(0,0,0)",
              "rgb(247,218,100)",
              "rgb(251,160,38)",
              "rgb(235,107,86)",
              "rgb(226,80,65)",
              "rgb(163,143,132)",
              "rgb(239,239,239)",
              "rgb(255,255,255)",
              "rgb(250,197,28)",
              "rgb(243,121,52)",
              "rgb(209,72,65)",
              "rgb(184,49,47)",
              "rgb(124,112,107)",
              "rgb(209,213,216)",
            ],
          },
        }}
      />
    </Grid>
  );
};

const TextEditorDialog = (props) => {
  const {
    handleClose = () => {},
    openImageSelector = true,
    handleCancel = () => {},
  } = props;

  const handleSaveImage = () => {
    // TODO: call the imageuploadv2 api and save the data to an object
  };
  const classes = useStylesforImageSelector();
  return (
    <Dialog
      open={openImageSelector}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.ImageSelectorContainer}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box className="" m="0px 25px" p="15px 0px">
          Add Element / Text
        </Box>
        <DialogContent className={classes.ContentForImageSelector}>
          <TextEditor />
        </DialogContent>
        <DialogActions className="">
          <div>
            <button
              onClick={handleCancel}
              className={classes.SecondaryButtonStyles}
            >
              Cancel
            </button>
            <button className={classes.PrimaryButtonStyles}>Save</button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default TextEditorDialog;
