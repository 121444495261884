import React, { useCallback } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";

// import { StyledAutoComplete, CssTextField, useStyles } from "../../../style";
// import "../../../index.scss";
import { StyledAutoComplete, CssTextField, useStyles } from "../Styles/Styles";

const DropDownField = ({ handleChange, data, value, width, template_type, theme }) => {
  const { label, options = [], key, required } = data;
  const classes = useStyles();

  const getValtoShow = (x) => {
    const val = options.find((opt) => opt.key === x);
    if (val) {
      return val.value;
    } else return "report";
  };

  const getVal = useCallback(
    (newValue) => {
      const val = options.find(
        (opt) => opt.value.toLowerCase() === newValue.toLowerCase()
      );
      if (val) {
        return val.key;
      } else return null;
    },
    [key]
  );

  return (
    <Grid item width={width || "50%"} key={key}>
      <div className={`appDetailsLabel-heading ${theme}`}>
        {label}{" "}
        <span
          style={{
            display: required || key === "bi_application" ? "" : "none",
          }}
        >
          *
        </span>
        :
      </div>
      <StyledAutoComplete
        className={classes.typographyStyle}
        value={key === "sub_category_type" ? getValtoShow(value) : value || ""}
        options={options}
        getOptionLabel={(option) => option}
        onChange={(e, newValue) => {
          handleChange(
            key,
            key === "sub_category_type" ? getVal(newValue) : newValue
          );
        }}
        disableClearable
        renderInput={(params) => (
          <CssTextField
            {...params}
            className={classes.typographyStyle}
            fullWidth
            placeholder={`Choose ${label}`}
          />
        )}
        style={{
          height: "32px",
          width: "100%",
        }}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  template_no: state.appTemplate.appTemplateState.template_no,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(DropDownField);
