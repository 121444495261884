import logger from "redux-logger";
import rootReducer from "./reducers/index";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { stateData } from "./stateData";
import rootSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();

// const initialState = localStorage[config.localStorageReduxStoreName]
//   ? JSON.parse(localStorage[config.localStorageReduxStoreName])
//   : stateData;

const initialState = stateData;

// const saver = (store) => (next) => (action) => {
//   let result = next(action);
//   localStorage[config.localStorageReduxStoreName] = JSON.stringify(
//     store.getState()
//   );
//   return result;
// };

// const storeFactory = () =>
//   applyMiddleware(logger)(createStore)(rootReducer, initialState);

// const storeFactory = applyMiddleware(sagaMiddleware, logger)(createStore)(rootReducer, initialState);
const storeFactory = applyMiddleware(sagaMiddleware, logger)(createStore)(
    rootReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

sagaMiddleware.run(rootSaga);

export default storeFactory;
