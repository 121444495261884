import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import CommentDrawer from "./CommentDrawer";
import Loader from "../../components/Loader/Loader";
import powerbi_logo_black from "../../../assets/images/powerbi_logo_black.svg";
import powerbi_logo_white from "../../../assets/images/powerbi_logo_white.svg";
import { get_Views_comments } from "../../redux/actions";

import { Skeleton } from "@mui/material";

// Import Hooks
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const CommentsAndViews = ({
  app,
  activeReport,
  viewsNComments,
  updatedViewsNComments,
  updateViews,
  user,
  OpenStandaloneURL,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [comment, setComment] = useState("");
  const [open, setOpen] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [commentViewState, setCommentViewState] = useState({
    total_views: 0,
    total_comments: 0,
    todays_views: 0,
  });
  const HandleGATracks = (buttonName) => {
    const gaEventTracker = useAnalyticsEventTracker("BiApp");
    gaEventTracker("CommentSection", `Button = ${buttonName}`);
  };

  const toggleDrawer = () => {
    HandleGATracks("Comments");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      app.app_id &&
      activeReport.sub_category_id 
      // && updateViews.status === "success"
    ) {
      dispatch(
        get_Views_comments({
          app_id: app.app_id,
          sub_category_id: activeReport.sub_category_id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateViews]);

  useEffect(() => {
    if (viewsNComments.status === "success") {
      setCommentList(viewsNComments.data.all_comments);
      setCommentViewState({
        total_views: viewsNComments.data.total_views,
        total_comments: viewsNComments.data.total_comments,
        todays_views: viewsNComments.data.todays_views,
      });
    }
  }, [viewsNComments]);

  useEffect(() => {
    if (updatedViewsNComments.status === "success") {
      setCommentList(updatedViewsNComments.data.all_comments);
      setCommentViewState({
        total_views: updatedViewsNComments.data.total_views,
        total_comments: updatedViewsNComments.data.total_comments,
        todays_views: updatedViewsNComments.data.todays_views,
      });
    }
  }, [updatedViewsNComments]);

  useEffect(() => {
    if (viewsNComments.status === "success") {
      function hasEditAccess(person) {
        return (
          user.preferred_username === person.user_id && person.role === "owner"
        );
      }
      const editableUser = app.access_details
        ? app.access_details.users_list.filter(hasEditAccess)
        : [];
      const val =
        editableUser.length || user.role === "super_admin" ? true : false;
      setEditStatus(val);
    }
  }, [viewsNComments.status]);

  return (
    <>
      {(viewsNComments.status === "loading" ||
        updateViews.status === "loading") && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            bottom: "22px",
          }}
        >
          <Skeleton
            sx={{ margin: "10px 10px" }}
            variant="text"
            animation="wave"
            width={150}
            height={30}
          />
          <Skeleton
            sx={{ margin: "10px 10px" }}
            variant="text"
            animation="wave"
            width={150}
            height={30}
          />
          <Skeleton
            sx={{ margin: "10px 10px" }}
            variant="text"
            animation="wave"
            width={150}
            height={30}
          />
          <Skeleton
            sx={{ margin: "10px 10px" }}
            variant="text"
            animation="wave"
            width={150}
            height={30}
          />
        </div>
      )}
      {viewsNComments.status === "error" && (
        <p className="nav-text">{viewsNComments.message}</p>
      )}
      {viewsNComments.status === "success" &&
      updateViews.status === "success" &&
      Object.keys(viewsNComments.data).length ? (
        <div id="power-bi-comments-container">
          <div className="comment-faq-div">
            <i
              className={`material-icons-outlined nav-icon ${user.ui_preferences.theme}-icon GA-app-comments`}
              onClick={() => toggleDrawer("right", true)}
            >
              chat_bubble_outline
            </i>
            <p
              className={`nav-text ${user.ui_preferences.theme}-text GA-app-comments`}
              onClick={() => toggleDrawer("right", true)}
            >
              {commentViewState.total_comments > 1
                ? commentViewState.total_comments + " Comments"
                : commentViewState.total_comments + " Comment"}
            </p>
            <div className="visibility total-views">
              <i
                className={`material-icons-outlined nav-icon ${user.ui_preferences.theme}-icon visibility`}
              >
                visibility
              </i>
              <p
                className={`nav-text ${user.ui_preferences.theme}-text visibility`}
              >
                {commentViewState.total_views > 1
                  ? commentViewState.total_views + " Total Views"
                  : commentViewState.total_views + " Total View"}
              </p>
            </div>
            <div className="visibility daily-views">
              <i
                className={`material-icons-outlined nav-icon ${user.ui_preferences.theme}-icon visibility`}
              >
                update
              </i>
              <p
                className={`nav-text ${user.ui_preferences.theme}-text visibility`}
              >
                {commentViewState.todays_views > 1
                  ? commentViewState.todays_views + " Daily Views"
                  : commentViewState.todays_views + " Daily View"}
              </p>
            </div>
            {editStatus && (
              <div
                onClick={() => {
                  HandleGATracks("UsageReports");
                  history.push({
                    pathname:
                      "/register" + app.app_meta.app_path + "/usage-report",
                    tab: 5,
                  });
                }}
                id={app.app_id}
                className="GA-usage-report"
                style={{ display: "flex", alignItems: "center" }}
              >
                <i
                  className={`material-icons-outlined nav-icon ${user.ui_preferences.theme}-icon`}
                >
                  insights
                </i>
                <p className={`nav-text ${user.ui_preferences.theme}-text`}>
                  Usage reports
                </p>
              </div>
            )}

            {activeReport.bi_application === "Power BI" && (
              <div
                className={`trouble-signin ${user.ui_preferences.theme}-text`}
                onClick={() =>
                  OpenStandaloneURL(
                    activeReport.url,
                    activeReport.sub_category_name
                  )
                }
              >
                <img
                  alt="Power BI logo Black"
                  src={`${
                    user.ui_preferences.theme === "dark"
                      ? powerbi_logo_white
                      : powerbi_logo_black
                  }`}
                ></img>
                <div
                  className={`trouble-signin ${user.ui_preferences.theme}-text`}
                >
                  Power BI : Trouble Signing-in?
                  <span>Click here</span>
                </div>
              </div>
            )}
          </div>
          <CommentDrawer
            all_comments={commentList}
            toggleDrawer={toggleDrawer}
            activeReport={activeReport}
            comment={comment}
            setComment={setComment}
            open={open}
            handleClose={handleClose}
            app={app}
          />
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  viewsNComments: state.AppsData.viewsNComments,
  updatedViewsNComments: state.AppsData.updatedViewsNComments,
  updateViews: state.AppsData.updateViews,
});

export default connect(mapStateToProps)(CommentsAndViews);
