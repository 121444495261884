import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import equal from "deep-equal";
import DrawerFilters from "../../components/DrawerFilters";
import { updateUserInfo, update_user_filters } from "../../redux/actions";
import { config } from "../../config/config";

const initailFilters = {
  tags: [],
  associated_datasets: [],
  geography: [],
  function: [],
  line_of_business: [],
  app_category: "",
  app_type: "",
};

function fetchAPI(payload) {
  const url = "/api/filters";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const DrawerContainer = ({
  open,
  handleClose,
  templates,
  user,
  searchVal,
  user_filters,
  handleSearchApp,
  setLoadingFilter,
  AppsData,
  setDrawerOpen,
  setSearchColumns,
  loadingFilter,
  setSearchVal,
}) => {
  const dispatch = useDispatch();
  // const [filterTemplates, setFilterTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState("");
  const [filterList, setFilterList] = useState(initailFilters);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    tags: [],
    associated_datasets: [],
    geography: [],
    function: [],
    line_of_business: [],
  });
  const handleReset = () => {
    if (searchVal) {
      handleSearchApp({
        search_columns: [],
        search_string: searchVal,
      });
    } 
    setSearchColumns([]);
    setFilterList(initailFilters);
    dispatch(
      updateUserInfo({
        ...user,
        filteredApps: { data: [], status: "pending" },
      })
    );
  };

  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  const handleCloseDrawer = () => {
    if (loading || loadingFilter === "loading") return;
    if (equal(user_filters.data, filterList)) {
      setDrawerOpen(false);
    } else {
      setConfirmationModalOpen(true);
      // setMessage("This action will reset your filters.");
    }
  };
  const handleContinue = () => {
    setFilterList(user_filters.data);
    handleSubmit(user_filters.data);
    setConfirmationModalOpen(false);
    setDrawerOpen(false);
    // setMessage("");
  };

  const handleChange = (key, value) => {
    const filterInfo = { ...filterList, [key]: value };
    setFilterList(filterInfo);
    handleSubmit(filterInfo);
  };

  const handleSaveFilters = async () => {
    setLoading(true);
    const payload = {
      user_id: user.preferred_username,
      filters: filterList,
    };
    try {
      const res = await fetchAPI(payload);
      const response = await res.json();
      if (response.status?.status === "datareckitt-200") {
        // setNotificationModal(true);
        // setMessage(response.status?.statusMessage);
        dispatch(update_user_filters({ user_id: user.preferred_username }));
        setSearchVal("")
      } else {
        toast.error("Oops! unable to save filters.");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Oops! unable to save filters.");
    }
  };
  // this function is used to set smart search data for the api
  const handleSubmit = (filterInfo = {}) => {
    let search_columns = [];
    Object.keys(filterInfo).forEach((item) => {
      if (filterInfo[item] && filterInfo[item].length) {
        search_columns = [
          ...search_columns,
          {
            name: item,
            type:
              config.specialFields.includes(item) && item !== "geography"
                ? "multi"
                : item === "geography"
                ? "custom"
                : "single",
            // type: config.specialFields.includes(item)
            //     ? "multi"
            //     : "single",
            values:
              item === "app_type" &&
              filterInfo[item] === config.hardCoded.embedded_analytics
                ? "bi_app"
                : item === "app_type"
                ? filterInfo[item].toLowerCase().replaceAll(" ", "_")
                : filterInfo[item],
          },
        ];
      }
    });
    if (!search_columns.length) {
      setLoadingFilter("pending");
      setSearchColumns([]);
      handleReset();
      return;
    }
    setSearchColumns(search_columns);
    handleSearchApp({
      search_columns,
      search_string: searchVal,
    });
  };

  //used to set the options in filters && initialize the filters with the data stored in backend.
  useEffect(() => {
    if (templates.status === "success" && user_filters.status === "success") {
      let options = {};
      // setFilterTemplates(templates.data.search_template.fields);
      templates.data.search_template.fields.forEach((item) => {
        if (config.specialFields.includes(item.key)) {
          if (user_filters.data[item.key]?.length) {
            const reqOptions = item.options
              .filter((opt) => !user_filters.data[item.key].includes(opt))
              .sort();
            options = {
              ...options,
              [item.key]: [
                ...[...user_filters.data[item.key]].sort(),
                ...reqOptions,
              ],
            };
          } else {
            options = { ...options, [item.key]: item.options };
          }
        }
      });
      setFilterOptions(options);
      setFilterList(
        Object.keys(user_filters.data) ? user_filters.data : initailFilters
      );
      handleSubmit(user_filters.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templates.data.search_template?.fields,
    templates.status,
    user_filters.status,
  ]);

  return (
    <DrawerFilters
      open={open}
      // handleClose={handleClose}
      handleCloseDrawer={handleCloseDrawer}
      handleReset={handleReset}
      loading={loading}
      handleSaveFilters={handleSaveFilters}
      // filterTemplates={filterTemplates}
      handleContinue={handleContinue}
      filterList={filterList}
      handleChange={handleChange}
      filterOptions={filterOptions}
      setFilterOptions={setFilterOptions}
      handleSubmit={handleSubmit}
      setFilterList={setFilterList}
      confirmationModalOpen={confirmationModalOpen}
      handleCloseConfirmationModal={handleCloseConfirmationModal}
      showSaveBtn
    />
  );
};

const mapStateToProps = (state) => ({
  templates: state.register.registerTemplate,
  user: state.user,
  AppsData: state.AppsData,
  user_filters: state.AppsData.user_filters,
});

export default connect(mapStateToProps)(DrawerContainer);
