import React, { useState } from "react";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { TextField, withStyles, Box } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@mui/material/Checkbox";

import { StyledAutoComplete, useStyles } from "../Styles/Styles";
// import "../../../index.scss";
import "../../index.scss";
import { connect } from "react-redux";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" htmlColor="#FF007F" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CssTextField = withStyles((theme) => ({
  root: {
    width: "98%",
    color:
      theme.palette.mode === "dark" ? theme.palette.white : theme.palette.dark,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.light
        : theme.palette.white.main,
    padding: "6px 8px",
    border:
      theme.palette.mode === "dark" ? "1px solid #757575" : "1px solid #E7E9EE",
    // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "3px",
    "& .MuiInputBase-root": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : theme.palette.dark.light,
    },
    "& label.Mui-focused": {},
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(66, 89, 108, 0.25)",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(66, 89, 108, 0.25)",
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: "2px",
      paddingBottom: "2px",
    },
  },
}))(TextField);

const CheckBoxDropDown = (props) => {
  const { handleChange, theme, data, value = [], width } = props;
  const { label, options = [], required, key } = data;
  const classes = useStyles();
  const filter = createFilterOptions();
  const [isMyInputFocused, setIsMyInputFocused] = useState(false);

  return (
    <Box
      width={width || "30%"}
      display="flex"
      flexDirection="column"
      pb="12px"
      pt="8px"
      pl="8px"
      marginRight="25px"
      className={
        value.length && !isMyInputFocused ? `${theme}-autoCompeteContainer` : ""
      }
    >
      <div className={`heading ${theme}`}>
        {label} <span style={{ display: required ? "" : "none" }}>*</span>:
      </div>
      <StyledAutoComplete
        id="share-select-user"
        size="small"
        disableCloseOnSelect
        multiple
        style={{
          fontFamily: "Energy !important",
          width: "100%",
          marginRight: "20px",
        }}
        className={classes.typographyStyle}
        options={options}
        getOptionLabel={(option) => {
          if (option && option.title) {
            return option.title;
          }
          if (option && option.inputValue) {
            return option.inputValue;
          }
          return option || "";
        }}
        value={[]}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
        onChange={(event, newValue) => {
          if (!newValue.length) return;
          if (newValue[0].inputValue) {
            // Create a new value from the user input
            handleChange(key, newValue[0].inputValue);
          } else {
            handleChange(key, newValue[newValue.length - 1]);
          }
        }}
        noOptionsText="No option"
        renderOption={(option, { selected }) => {
          return (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className={classes.typographyStyle}
                size="small"
                style={{
                  marginRight: 5,
                  padding: "0px",
                }}
                sx={{
                  "&.Mui-checked": {
                    color: "#fc007f",
                  },
                }}
                checked={value.includes(option)}
              />
              {option?.title || option || ""}
            </React.Fragment>
          );
        }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="standard"
            fullWidth
            size="small"
            placeholder={
              value.length && !isMyInputFocused
                ? `${value.slice(0, 2).join()} ${
                    value.length > 2 ? ` +${value.length - 2}` : ""
                  }`
                : `Please type ${label}`
            }
            className={`${classes.typographyStyle} ${
              value.length && !isMyInputFocused ? `custom_placeholder ${theme}` : ""
            }`}
            onBlur={() => setIsMyInputFocused(false)}
            onFocus={() => setIsMyInputFocused(true)}
          />
        )}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(CheckBoxDropDown);
