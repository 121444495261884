import { makeStyles } from "@material-ui/core";

export const useStylesforImageSelector = makeStyles((theme) => ({
  flexSB: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px dashed #b4bcc4",
    alignItems: "center",
  },
  displayOnlyFlex: {
    display: "flex",
  },
  topContentContainer: {
    borderBottom: "1px dashed #b4bcc4",
    // color: "#282828",
  },
  platformClass: {
    height: "22px",
    marginLeft: "15px",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  displayContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "8px",
  },
  appMetaContainer: {
    display: "flex",
    justifyContent: "start",
    padding: "10px 0px",
    cursor: "default",
  },
  appMetaContent: {
    display: "flex",
    marginRight: "10px",
    alignItems: "center",
  },
  decriptionContainer: {
    padding: "10px 0px",
  },
  infoHeading: {
    fontWeight: 600,
    fontSize: "14px",
    margin: "0px 10px 0px 0px",
  },
  topUserHeading: {
    fontWeight: 600,
    fontSize: "14px",
    margin: "auto",
  },
  topuserIcon: {
    fontSize: "20px",
  },
  viewButton: {
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "12px",
    margin: "0px 10px 0px 0px",
  },
  infoPara: {
    fontSize: "12px",
    margin: "0px 5px",
  },
  subcatLinks: {
    fontSize: "12px",
    margin: "0px 8px",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#0554A9",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  openAppButton: {
    background: "#FC007F",
    marginLeft: "10px",
    height: "40px",
    width: "150px",
    fontWeight: "600",
    "&:disabled": {
      opacity: "50%",
      cursor: "default",
    },
  },
  ownerListContainer: {
    color: "#fff",
    cursor: "default",
  },
  requestAccessButton: {
    background: "#687988",
    marginLeft: "10px",
    height: "40px",
    width: "150px",
    fontWeight: "600",
    "&:disabled": {
      opacity: "50%",
      cursor: "default",
    },
  },
  bottomContentContainer: {
    padding: "10px 0px ",
    // color: theme.palette.theme.mode === "dark" ? theme.palette.white.main :"#282828",
  },
  ImageSelectorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiDialog-paperWidthSm": {
      width: "100% !important",
      maxWidth: "80% !important",
      height: "630px !important",
      boxShadow: "4px 4px 12px 4px #434343",
    },
  },
  fileName: {
    fontSize: "0.875rem",
  },
  ContentForImageSelector: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "50%",
  },
  //TODO: Fix this class , it's not working
  FileUploaderClass: {
    "& .sc-aXZVg": {
      "& .guZdik": { height: "300px !important" },
    },
  },

  uploadedImageContain: {
    width: "650px",
    height: "100px",
    border: "1px solid",
    objectFit: "contain",
    borderRadius: "3px",
    border: theme.palette.mode === "dark"?"1px solid #757575":"1px solid #e7e9ee"
  },
  uploadedImageCover: {
    width: "650px",
    height: "100px",
    border: "1px solid",
    objectFit: "cover",
    borderRadius: "3px",
  },
  uploadedReportImageCover: {
    width: "350px",
    height: "350px",
    border: "1px solid",
    objectFit: "cover",
    borderRadius: "3px",
  },
  uploadedReportImageContain: {
    width: "350px",
    height: "350px",
    border: "1px solid",
    objectFit: "contain",
    borderRadius: "3px",
    border: theme.palette.mode === "dark"?"1px solid #757575":"1px solid #e7e9ee"
  },
  PrimaryButtonStyles: {
    width: "160px",
    height: "30px",
    fontSize: "12px",
    background: theme.palette.colors.darkBlue,
    color: "#fff",
    marginLeft: "10px",
  },
  PrimaryButtonStylesDisabled: {
    width: "160px",
    height: "30px",
    fontSize: "12px",
    opacity: "0.5",
    background: theme.palette.colors.darkBlue,
    color: "#fff",
    marginLeft: "10px",
  },
  SecondaryButtonStyles: {
    width: "160px",
    height: "30px",
    fontSize: "12px",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#000",
    background: "transparent",
    marginLeft: "10px",
    border: "1px solid ",
    fontWeight: "400",
  },
  reportFieldsContainerLarge: {
    width: "50%",
    marginLeft: "15px",
  },
  reportFieldsContainerSmall: {
    width: "100%",
    marginTop: "15px",
  },
  imageTypeStyleContainer: {
    width: "200px",
    display: "flex",
    border: "1px solid",
    borderRadius: "4px",
    height: "30px",
    alignItems: "center",
    margin: "0px 70px",
  },
  imageTypeStylesSelected: {
    width: "50%",
    fontSize: "13px",
    display: "flex",
    justifyContent: "center",
    background: theme.palette.colors.black,
    color: "#fff",
    height: "100%",
    alignItems: "center",
    cursor: "default",
  },
  imageTypeStyles: {
    fontSize: "13px",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    cursor: "default",
    height: "100%",
    alignItems: "center",
  },
  ReportElementContainer: {
    justifyContent: "space-around",
    width: "100%",
  },
  requestBtns: {
    marginLeft: "10px",
    width: "140px",
    fontWeight: "600",
    "&:disabled": {
      opacity: "50%",
      cursor: "default",
    },
  },
  textFieldText: {
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
      fontFamily: "Energy",
    },
    "& .MuiOutlinedInput-root": {
      padding: "12.5px 14px",
    },
  },
  requestAccessHeading: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "15px",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#282828",
    gap: "5px",
    display: "flex",
    // alignItems: "center",
    padding: "20px 24px 2px 24px",
    flexDirection: "column",
  },
  requestAccessAppName: {
    color: "#8d9ca6",
    fontSize: "12px",
  },
  popupTopUser: {
    fontFamily: "Energy",
  },
}));
