import { DateRange } from "react-date-range";
import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./index.scss";
import { set } from "date-fns";
import { connect } from "react-redux";

let OldRange = null;

const DateRangeComponent = ({
  timestampRange,
  SetTimestampRange,
  filters,
  fetchUsageReportData,
  theme,
  setSelectedChip,
  timestamp_publishedOn,
  open,
}) => {
  // if (!OldRange) {
  //   OldRange = timestampRange;
  // }
  // if (OldRange !== timestampRange) {
  //   fetchUsageReportData(filters);
  //   OldRange = timestampRange;
  // }

  const [range, setRange] = useState([]);
  // const [range, setRange] = useState(() => {

  //   const currentDate = new Date(timestampRange.startDate);
  //   const sevenDaysAgo = new Date(timestampRange.endDate);
  //   return [
  //     {
  //       startDate: currentDate,
  //       endDate: sevenDaysAgo,
  //       key: "selection",
  //     },
  //   ];
  // });

  useEffect(() => {
    if (timestampRange.startDate && timestampRange.endDate) {
      const startDate = new Date(timestampRange.startDate);
      const endDate = new Date(timestampRange.endDate);
      setRange([
        {
          startDate: startDate,
          endDate: endDate,
          key: "selection",
        },
      ]);
    }
  }, [timestampRange]);

  console.log("calendar", timestampRange, range);

  // Handle the date range selection when user selects from calendar
  const handleSelect = (range) => {
    setSelectedChip("Time Range");
    const startDateTimestamp = new Date(range.selection.startDate).getTime();
    const dateObject = new Date(range.selection.endDate);
    const endDateTimestamp = new Date(
      dateObject.setDate(dateObject.getDate() + 1) - 1
    ).getTime();
    SetTimestampRange({
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
    });
    setRange([range.selection]);
  };

  return (
    open && (
      <DateRange
        onChange={handleSelect}
        editableDateInputs={true}
        moveRangeOnFirstSelection={false}
        ranges={range}
        months={1}
        minDate={new Date(timestamp_publishedOn)}
        maxDate={new Date()}
        // rangeColors={["#fc007f"]}
        rangeColors={["#DDF3FC", "#fc007f"]}
        // scroll={{ enabled: true }}
        direction="horizontal"
        showMonthAndYearPickers={true}
        className={`calendarElement-${theme}`}
      />
    )
  );
};

// export default DateRangeComponent;
const mapStateToProps = (state) => ({
  userInfo: state.user,
  timestamp_publishedOn:
    state.AppsData.appDetails?.data?.app_meta?.published_on,
  theme: state.user.ui_preferences?.theme,
});
// appDetails?.data?.app_meta?.published_on
export default connect(mapStateToProps)(DateRangeComponent);
