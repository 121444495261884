import React, { useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { v4 } from "uuid";
import { Box } from "@mui/material";
import Template4 from "./Template4";
import Template2 from "./Template2";
import Template3 from "./Template3";
import { updateReportTemplate } from "../../../../../redux/actions";
import TemplateHeader from "../../../../../components/Header/TemplateHeader";

const DashboardView = ({
    appTemplateState,
    renderSubReportArr,
    handleNavigateInsideReport,
    handleEditSubCat,
    breadcrumbArr,
    handleDeleteSubCat,
    setRenderSubReportArr,
    setSelectedSubcat,
    setSelectedWidgetType,
    setopenAddReportPopUp,
    setParentID,
    isFieldsEditable,
    setParentReportType,
}) => {
    const { template_no, templateData } = appTemplateState;
    const dispatch = useDispatch();

    const getHeaderData = useMemo(
        () =>
            renderSubReportArr.filter(
                (item) => item.sub_category_type === "header"
            ),
        [renderSubReportArr]
    );

    const findNUpdateSubCat = (oldData, id, newData) => {
        let isFound = false;
        oldData.forEach((item) => {
            if (isFound) return;
            if (item.sub_category_id !== id) {
                if (item.sub_category.length > 0) {
                    findNUpdateSubCat(item.sub_category, id, newData);
                }
            } else {
                item.sub_category = newData;
                setRenderSubReportArr(newData);
                isFound = true;
            }
        });
    };
    const updateLayout = (layout, indx) => {
        let reqLayout = JSON.parse(JSON.stringify(renderSubReportArr));
        let reqData = JSON.parse(JSON.stringify(templateData));
        layout.forEach((item, i) => {
            reqLayout = [
                ...reqLayout.slice(0, i + indx),
                {
                    ...reqLayout[i + indx],
                    position_meta: {
                        x: item.x,
                        y: item.y,
                        w: item.w,
                        h: item.h,
                        i: item.i,
                    },
                },
                ...reqLayout.slice(i + 1 + indx),
            ];
        });
        if (breadcrumbArr.length === 1) {
            reqData = reqLayout;
            setRenderSubReportArr(reqLayout);
        } else {
            findNUpdateSubCat(
                reqData,
                breadcrumbArr[breadcrumbArr.length - 1].textID,
                reqLayout
            );
        }
        dispatch(
            updateReportTemplate({
                templateData: reqData,
            })
        );
    };

    const onLayoutChange = (layout) => {
        let reqLayout = JSON.parse(JSON.stringify(renderSubReportArr));
        let indx = 0;
        if (reqLayout[0]?.sub_category_type === "header") {
            indx = 1;
        }
        updateLayout(layout, indx);
    };

    return (
        <>
            {getHeaderData.length ? (
                <Box display="flex" flexDirection="column" gap={1} px="10px">
                    {getHeaderData.map((item) => (
                        <TemplateHeader
                            data={item}
                            key={v4()}
                            handleEdit={handleEditSubCat}
                            handleDelete={handleDeleteSubCat}
                            notEditable={isFieldsEditable}
                            normalIcons
                        />
                    ))}
                </Box>
            ) : null}

            <div style={{ flex: 1 }}>
                {template_no === "Template_2" ? (
                    <Template2
                        data={renderSubReportArr}
                        handleNavigation={handleNavigateInsideReport}
                        handleEdit={handleEditSubCat}
                        handleDelete={handleDeleteSubCat}
                        updateLayout={updateLayout}
                        breadcrumbArr={breadcrumbArr}
                        setRenderSubReportArr={setRenderSubReportArr}
                        setSelectedSubcat={setSelectedSubcat}
                        setopenAddReportPopUp={setopenAddReportPopUp}
                        setSelectedWidgetType={setSelectedWidgetType}
                        setParentID={setParentID}
                        isFieldsEditable={isFieldsEditable}
                        setParentReportType={setParentReportType}
                    />
                ) : template_no === "Template_3" ? (
                    <Template3
                        data={renderSubReportArr}
                        handleNavigation={handleNavigateInsideReport}
                        handleEdit={handleEditSubCat}
                        handleDelete={handleDeleteSubCat}
                        updateLayout={updateLayout}
                        breadcrumbArr={breadcrumbArr}
                        setRenderSubReportArr={setRenderSubReportArr}
                        setSelectedSubcat={setSelectedSubcat}
                        setopenAddReportPopUp={setopenAddReportPopUp}
                        setSelectedWidgetType={setSelectedWidgetType}
                        setParentID={setParentID}
                        isFieldsEditable={isFieldsEditable}
                        setParentReportType={setParentReportType}
                    />
                ) : (
                    <Template4
                        data={renderSubReportArr}
                        handleNavigation={handleNavigateInsideReport}
                        handleEdit={handleEditSubCat}
                        handleDelete={handleDeleteSubCat}
                        breadcrumbArr={breadcrumbArr}
                        onLayoutChange={onLayoutChange}
                        setRenderSubReportArr={setRenderSubReportArr}
                        setSelectedSubcat={setSelectedSubcat}
                        setopenAddReportPopUp={setopenAddReportPopUp}
                        setSelectedWidgetType={setSelectedWidgetType}
                        setParentID={setParentID}
                        updateLayout={updateLayout}
                        isFieldsEditable={isFieldsEditable}
                        setParentReportType={setParentReportType}
                    />
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    theme: state.user.ui_preferences?.theme,
    appTemplateState: state.appTemplate.appTemplateState,
});

export default connect(mapStateToProps)(DashboardView);
