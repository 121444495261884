import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { makeStyles, withStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MuiToggleButton from "@mui/material/ToggleButton";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Switch } from "@mui/material";
import { config } from "../../config/config";

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f6f6f6",
    // backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 270,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const StyleSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          // theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
          "#fc007f",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    // backgroundColor:
    //     theme.palette.mode === "dark"
    //         ? "rgba(255,255,255,.35)"
    //         : "rgba(0,0,0,.25)",
    backgroundColor: "#fc007f",
    boxSizing: "border-box",
  },
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => {
  return {
    "& .MuiInputBase-input": {
      fontFamily: "Energy",
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.dark.light
          : theme.palette.white.main,
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
      border:
        theme.palette.mode === "dark"
          ? "1px solid #757575"
          : "1px solid #E7E9EE",
      // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "3px",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      // padding: "6px 8px",
      padding: "6px 22px 6px 8px",
      "&.Mui-disabled": {
        color:
          theme.palette.mode === "dark"
            ? theme.palette.white.main
            : theme.palette.dark.light,
        // "-webkit-text-fill-color": `#282828 !important`,
        "-webkit-text-fill-color": `${
          theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(0, 0, 0, 0.38)"
        } !important`,
        cursor: "not-allowed",
      },
    },
    "&:focus": {},
  };
});

export const CssTextField1 = withStyles({
  root: {
    width: "90%",
    backgroundColor: "#f3f3f3",
    padding: "3px 10px",
    // height: "24px",
    borderRadius: "4px",
    "& label.Mui-focused": {
      // color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
})(TextField);

export const CssTextField = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    width: "95%",
    "@media (min-width:1400px)": {
      width: "93%",
    },
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.light
        : theme.palette.white.main,
    padding: "7px 8px",
    border:
      theme.palette.mode === "dark" ? "1px solid #757575" : "1px solid #E7E9EE",
    borderRadius: "3px",
    "& .MuiInputBase-root": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : theme.palette.dark.light,
    },
    "& label.Mui-focused": {},
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(66, 89, 108, 0.25)",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(66, 89, 108, 0.25)",
      },
    },
  },
}))(TextField);

export const StyledAutoComplete = withStyles((theme) => {
  return {
    tag: {
      fontFamily: "Energy !important",
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : theme.palette.dark.light,
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.dark.light
          : theme.palette.white.main,
    },
    paper: {
      fontFamily: "Energy",
      fontWeight: 400,
      fontSize: 12,
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.dark.light
          : theme.palette.white.main,
      "& .MuiAutocomplete-option": {
        padding: "3px",
        paddingLeft: "10px",
      },
    },
  };
})(Autocomplete);

export const useStyles = makeStyles((theme) => ({
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  hyperlinkText: {
    opacity: "1",
  },
  typographyStyle: {
    "& .MuiChip-root": {
      fontFamily: "Energy",
      fontSize: 12,
      padding: "0px",
    },
    "& .MuiInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInput-underline:before": {
      borderBottom: 0,
      transition: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid #dedede !important",
    },
    "& .Mui-focused .MuiInput-underline": {
      borderBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      fontFamily: "Energy",
      fontSize: 12,
    },
    "& .MuiAutocomplete-paper": {
      fontFamily: "Energy",
      fontSize: 6,
    },
    "& .MuiInputBase-input": {
      textTransform: "capitalize",
      fontFamily: "Energy",
      fontSize: "12px",
      padding: "0px !important",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
      fontWeight: "400",
    },
    "& .Mui-disabled": {
      cursor: "not-allowed",
    },
    "& .MuiOutlinedInput-root": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    "& .MuiAutocomplete-popupIndicator": {
      "& span": {
        "& svg": {
          "& path": {
            fill: "#FF007F",
            d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
          },
        },
      },
    },
  },
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(34px, 20px) scale(1);",
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "5px",
      // color: "#282828",
      opacity: 0.5,
    },
  },
  select: {
    fontFamily: "Energy !important",
    fontSize: "16px!important",
    fontStyle: "normal!important",
    fontWeight: "400!important",
  },
  comboOptions: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    // color: "#282828",
  },
  popupIndicator: {
    "& span": {
      "& svg": {
        "& path": {
          fill: "#FF007F",
          d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
        },
      },
    },
  },

  inputRoot: {
    // color: "#282828",
    background: "#ededed",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: 26,
      fontFamily: "Energy",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "23px",
      letterSpacing: "0em",
      textAlign: "left",
      // color: "#282828",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fafafa",
    },
  },
  checkbox: {
    "& .MuiCheckbox-root": {
      padding: 0,
    },
  },
  tagsInput: {
    "& .MuiInputBase-input": {
      textTransform: "none",
    },
  },
  heading: {
    fontFamily: "Energy !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "18px !important",
    color: theme.palette.mode == "dark" ? "#b1b1b1" : "#4e4e4e",
    textAlign: "start",
  },
  copyOnClick: {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.white.main
        : theme.palette.dark.light,
    marginLeft: "1rem",
    fontSize: "12px",
  },
  urlLink: {
    color: "#7788fe",
    textDecoration: "underline",
    textDecorationColor: "#7788fe",
    marginLeft: "5px",
  },
  lightText: {
    opacity: "0.5",
  },
  capitalOptions: {
    "& .MuiAutocomplete-option": {
      textTransform: "capitalize !important",
    },
  },
  disabledDropdown: {
    "& svg": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      opacity: ".6",
    },
  },
}));

export const statusUpdateBtn = {
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "26px",
  textTransform: "none",
  fontFamily: "Energy",
};

export const publishappStyle = {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "19px",
  textTransform: "none",
  fontFamily: "Energy",
  width: "130px",
  height: "30px",
  ml: "10px",
  backgroundColor: "transparent",
  border: `1.5px solid #FC007F`,
  marginLeft: "5px",
  color: "#FC007F",
  // position: "absolute",
  // right: "45px",
  // top: "30px",
};

export const statusBtn = (status) => {
  const color =
    status === "pending"
      ? "goldenrod"
      : status === "approved"
      ? "#30AB70"
      : status === "published"
      ? "#FC007F"
      : "#d32f2f";

  return {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "26px",
    textTransform: "capitalize",
    marginLeft: "10px",
    border: `2px solid ${color}`,
    // border: "2px solid #FFDE33",
    boxSizing: "border-box",
    color: color,
    borderRadius: "50px",
    cursor: "default",
    padding: "0px 12px",
    height: "auto",
    backgroundColor: "transparent",
    "&:hover": {
      border: `2px solid ${color}`,
      // border: "2px solid #FFDE33",
      color: color,
      backgroundColor: "transparent",
    },
  };
};

export const steperStyle = {
  whiteSpace: "nowrap",
  "& .MuiStepLabel-alternativeLabel": {
    fontFamily: "Energy",
    fontWeight: "600 !important",
  },
  "& .Mui-completed": {
    color: "#FC007F !important",
    fontWeight: "600 !important",
  },
  "& .Mui-active": {
    color: "#FC007F !important",
    fontWeight: "600 !important",
  },
};

export const defaultActiveSteppers = {
  cursor: "pointer",
  whiteSpace: "nowrap",
  "& .MuiSvgIcon-root": {
    color: "#FC007F !important",
    cursor: "pointer",
  },
  "& .MuiStepLabel-alternativeLabel": {
    fontFamily: "Energy",
    fontWeight: "600 !important",
    color: "#FC007F !important",
    cursor: "pointer",
  },
  "& .Mui-completed": {
    color: "#FC007F !important",
    fontWeight: "600 !important",
    cursor: "pointer",
  },
  "& .Mui-active": {
    color: "#FC007F !important",
    fontWeight: "600 !important",
    cursor: "pointer",
  },
};

export const statusIconStyle = {
  width: "24px",
  height: "24px",
};

export const useStylesforSF = makeStyles((theme) => ({
  flexSB: {
    display: "flex",
    justifyContent: "space-between",
  },
  saveButton: {
    marginLeft: "10px",
    padding: "0px 20px",
    "&:disabled": {
      opacity: "50%",
      cursor: "default",
    },
  },
  contained: {
    borderColor: "#4EAFB3",
    backgroundColor: "#4EAFB3",
    color: "white",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    width: 15,
    textTransform: "capitalize",
    fontFamily: "Energy",
    "&:hover": {
      borderColor: "#4EAFB3",
      backgroundColor: "#4EAFB3",
      color: "white",
      boxShadow: "none",
    },
  },
  dailogContainer: {
    padding: "10px",
    minWidth: "700px !important",
    maxWidth: "700px !important",
    minHeight: "400px !important",
    maxHeight: "400px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
  },
  alertContainer: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px !important",
      minWidth: "700px !important",
      boxShadow: "4px 4px 12px 4px #434343",
    },
  },
  individualFilterContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "5px 0px",
  },
  displayFlex: {
    display: "flex",
  },
  editButton: {
    color: "#d8477e",
    fontSize: "20px",
    marginRight: "8px",
  },
  countryName: {
    fontSize: "12px",
    fontFamily: "Energy",
    paddingRight: "10px",
    borderRight: "1px solid #909090",
    marginLeft: "5px",
    // color: "#282828",
    width: "150px",
  },
  brandsList: {
    marginLeft: "10px",
    display: "flex",
    flexWrap: "wrap",
    width: "440px",
  },
  brandName: {
    fontSize: "12px",
    fontFamily: "Energy",
    marginRight: "10px",
    // color: "#282828",
  },
  pinkColor: { color: "#d8477e" },
  headingSecurityFilters: {
    marginBlock: "auto",
    // color: "#282828",
    marginRight: "15px",
    fontWeight: 400,
    fontSize: "14px",
  },
  personIcon: {
    fontSize: "24px",
    marginRight: "5px",
    color: "#282828",
  },
  personName: {
    marginBlock: "auto",
    fontWeight: 400,
    //  color: "#282828"
  },
  filterHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d4d4d4",
    paddingBottom: "5px",
    margin: "5px 20px",
  },
  countryContainer: { display: "flex", alignItems: "center" },
  countryHeading: {
    fontFamily: "Energy",
    margin: "0px 10px 0px 0px",
    fontWeight: 400,
    fontSize: "12px",
    // color: "#282828",
  },
  AddFilterButton: {
    fontSize: "12px",
    fontFamily: "Energy",
    margin: "0px 10px 0px 20px",
    color: "#d8477e",
    background: "#fff",
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
      background: "#FBEDF2",
    },
    "&:disabled": {
      opacity: "50%",
      cursor: "default",
      background: "#FFF",
    },
  },
  AlertFooterContainer: {
    "&.MuiDialogActions-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
    },
  },
  cloneWarning: {
    fontSize: "10px",
    color: "#d8477e",
  },
}));

// export const QontoConnector = styled(StepConnector)(({ theme }) => ({
//     [`&.${stepConnectorClasses.alternativeLabel}`]: {
//         top: 10,
//         left: "calc(-48% + 16px)",
//         right: "calc(50% + 16px)",
//     },
//     [`&.${stepConnectorClasses.active}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             borderColor: "#ea3592",
//         },
//     },
//     [`&.${stepConnectorClasses.completed}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             borderColor: "#ea3592",
//         },
//     },
//     [`& .${stepConnectorClasses.line}`]: {
//         borderColor: "rgba(0, 0, 0, 0.38)",
//         borderTopWidth: 1,
//         borderRadius: 1,
//     },
// }));

export const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.white.main
      : theme.palette.dark.light,
  // color: "#282828",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active &&
    {
      // color: "#FC007F",
    }),
  "& .QontoStepIcon-completedIcon": {
    // color: "#784af4",
    // zIndex: 1,
    width: 14,
    height: 14,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    border: `4px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.white.main
        : theme.palette.dark.light
    }`,
    // border: "4px solid #282828",
    // backgroundColor: "currentColor",
  },
}));

export function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}
// export const QontoConnector = styled(StepConnector)(({ theme }) => ({
//     [`&.${stepConnectorClasses.alternativeLabel}`]: {
//         top: 10,
//         // left: "calc(-48% + 16px)",
//         // right: "calc(50% + 16px)",
//         left: "calc(-58% + 16px)",
//         right: "calc(42% + 16px)",
//     },
//     [`&.${stepConnectorClasses.active}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             // borderColor: "#ea3592",
//             backgroundImage:
//                 "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
//             // "linear-gradient( to right,#FC007F 0%,#FC007F 50%,#282828 50%,#282828 100%)",
//         },
//     },
//     [`&.${stepConnectorClasses.completed}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             borderColor: "#ea3592",
//         },
//     },
//     [`& .${stepConnectorClasses.line}`]: {
//         borderColor: "#282828",
//         borderTopWidth: 3,
//         borderRadius: 1,
//     },
// }));

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-58% + 18px)",
    right: "calc(42% + 18px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        theme.palette.mode === "dark"
          ? `linear-gradient( to right,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.white.main} 50%,${theme.palette.white.main} 100%)`
          : `linear-gradient( to right,${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%,${theme.palette.dark.light} 50%,${theme.palette.dark.light} 100%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.white.main
        : theme.palette.dark.light,
    borderRadius: 1,
  },
}));

export const DefaultStyleSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          // theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
          "#fc007f",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    // backgroundColor:
    //     theme.palette.mode === "dark"
    //         ? "rgba(255,255,255,.35)"
    //         : "rgba(0,0,0,.25)",
    backgroundColor: "grey",
    boxSizing: "border-box",
  },
}));

export const useStylesPowerBi = makeStyles((theme) => ({
  drawer: {
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: "calc((100vw - 850px)/2)",
      "@media (max-width:1190px)": {
        width: "calc((100vw - 480px)/2)",
      },
      maxWidth: 310,
      backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#F1F1F1",
      padding: "65px 10px 40px 10px",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    gap: 2,
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "23px",
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8%",
  },
  heading: {
    alignContent: "center",
    marginBottom: "5px",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "23px",
    textAlign: "center",
  },
  workspaceCard: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
    padding: "8px",
    borderRadius: "5px",
    marginBottom: "5px",
    cursor: "pointer",
  },
  workspaceText: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#FC007F",
  },
  workspaceTextforImport: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    color: theme.palette.mode === "light" ? "#282828" : "#fff",
  },
  reportText: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    overflow: "hidden",
    overflowWrap: "anywhere",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    lineClamp: "2",
    WebkitBoxOrient: "vertical",
    marginBottom: "5px",
  },
  workspaceIcon: {
    width: "20px",
    height: "20px",
    position: "relative",
  },
  workspaceIconArrow: {
    width: "20px",
    height: "20px",
    marginLeft: "auto",
  },
  reportCard: {
    backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
    padding: "8px",
    borderRadius: "5px",
    margin: "5px 2.5px",
    boxShadow: config.cssValues.cardShadow,
    border: "0.2px solid transparent",
    "&:hover": {
      border: "0.2px solid rgba(252, 0, 127 , 1)",
      "& svg": {
        color: "#FC007F",
      },
      // boxShadow: "0px 0px 2px rgba(252, 0, 127,1)",
    },
  },
  reportCardData: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "23px",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
  },
  reportRow: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  icons: {
    "&.MuiSvgIcon-root": {
      fontSize: "16px",
      color: theme.palette.mode === "dark" ? "#fff" : "#8998A2",
      cursor: "pointer",
    },
  },
  reportUrl: {
    whiteSpace: "nowrap",
  },
  closeIcon: {
    // position: "absolute",
    // top: "40px",
    // left: "10px",
    cursor: "pointer",
    "& .material-icons-outlined": {
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
      fontSize: "20px",
    },
  },
  loginText: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "23px",
    textAlign: "center",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
  },
  loginContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
    height: "100%",
  },
  loginBtn: {
    width: "100%",
  },
  search: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "4px",
    marginBottom: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  inputField: {
    flex: 1,
    "& .MuiInputBase-input": {
      width: "100% !important",
    },
  },
  copiedNotify: {
    position: "absolute",
    fontSize: "10px",
    right: "-6px",
    bottom: "16px",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
  },
  reportContainer: {
    height: "calc(100vh - 90px)",
    overflow: "auto",
    paddingTop: "5px",
  },
  urlContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "23px",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
  },
  url: {
    overflow: "hidden",
    overflowWrap: "anywhere",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    lineClamp: "1",
    WebkitBoxOrient: "vertical",
    fontSize: "12px",
  },
  appLists: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    textAlign: "start",
    padding: "0px 8px 10px 8px",
  },
  existListHeading: {
    fontSize: "12px",
    fontWeight: 600,
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    lineHeight: "16px",
  },
  appLink: {
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: 600,
    color: "#fc007f",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
}));
export const useStylesRegisterStatic = makeStyles((theme) => ({
  base_container: {
    position: "relative",
    minWidth: "834px",
    alignItems: "center",
    margin: "0px auto",
  },
  data_qoute: {
    maxWidth: "1730px",
    width: "91% !important",
    marginBottom: "25px",
  },
  stepper_container: {
    maxWidth: "1144px",
    width: "75.76% !important",
    paddingTop: "25px",
    paddingBottom: "50px",
  },
}));

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#fff" : "#000",
  textTransform: "capitalize",
  backgroundColor: "transparent",
  border: theme.palette.mode === "dark" ? "1px solid #fff" : "1px solid #000",
  fontFamily: "Energy !important",
  fontStyle: "normal !important",
  fontWeight: "400 !important",
  fontSize: "12px !important",
  lineHeight: "18px !important",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "#ffffff",
    backgroundColor: "#0058aa",
    border: "1px solid #0058aa",
  },
}));
