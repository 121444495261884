import { useState } from "react";
import Select, { components } from "react-select";
import "../../../../../styles/sass/NewThemeVariables.scss";
import { connect } from "react-redux";

// Import Template Data
import templateData from "./TemplateData";

const darkcustomStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  control: (provided, state, theme) => ({
    ...provided,
    boxShadow: "none",
    height: "32px",
    width: "345px",
    border: "1px solid #b2b1b1",
    minWidth: "16px",
    minHeight: "0px",
    borderRadius: "5px",
    backgroundColor: "#282828",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "200",
    fontfamily: "Energy",
    bottom: "6px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "400",
    fontfamily: "Energy",
    bottom: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    color: "#FFF",
    fontfamily: "Energy",
    backgroundColor: "#282828",
    "&:active": {
      backgroundColor: "#000000",
    },
    "&:hover": {
      backgroundColor: "#000000",
    },
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  }),
  menu: (provided) => ({
    ...provided,
    width: "345px", // Set your desired width here
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#282828", // Set your desired width here
  }),
};

const lightcustomStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  control: (provided, state, theme) => ({
    ...provided,
    boxShadow: "none",
    height: "32px",
    width: "345px",
    border: "1px solid #b2b1b1",
    minWidth: "16px",
    minHeight: "0px",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "#000000",
    fontSize: "14px",
    fontWeight: "200",
    fontfamily: "Energy",
    bottom: "6px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000000",
    fontSize: "14px",
    fontWeight: "400",
    fontfamily: "Energy",
    bottom: "6px",
  }),
  option: (provided, state, theme) => ({
    ...provided,
    display: "flex",
    color: "#000000",
    fontfamily: "Energy",
    backgroundColor: "#ffffff",
    "&:active": {
      backgroundColor: "#0058AA40",
      color: "#0058AA",
    },
    "&:hover": {
      backgroundColor: "#0058AA40",
      color: "#0058AA",
    },
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  }),
  menu: (provided) => ({
    ...provided,
    width: "345px", // Set your desired width here
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff", // Set your desired width here
  }),
};

const CustomDropdownIndicator = ({ isMenuOpen, ...props }) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      <svg viewBox="0 0 34 34" width="26" height="26" fill="#b0b0b0">
        <path d={menuIsOpen ? "M7 14l5-5 5 5z" : "M7 10l5 5 5-5z"} />
      </svg>
    </components.DropdownIndicator>
  );
};

const ViewAppsBy = (props) => {
  const { user, handleSelectElement, selectedTemplate } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChange = (selectedOption) => {
    const value = selectedOption.template_no;
    handleSelectElement(value);
  };

  return (
    <div
      style={{
        width: "auto",
        height: "auto",
      }}
    >
      <Select
        value={templateData.find(
          (option) => option.template_no === selectedTemplate
        )}
        onChange={handleChange}
        options={templateData}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        styles={
          user.ui_preferences.theme === "light"
            ? lightcustomStyles
            : darkcustomStyles
        }
        isSearchable={false}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ViewAppsBy);
