// Import required libraries
import React from "react";

// Import data
// import WIP from "../../../../src/assets/images/WIP.gif";
import comingSoon from "../../../../src/assets/images/under_progress.svg";

//Import styles
import "./WorkInProgress.scss";

function WorkInProgress() {
  return (
    <div className="work-in-progress-container">
      <div className="wip-image">
        <img src={comingSoon} alt="Work in progress" />
      </div>
      <p className="work-in-progress-text">
        This feature is not yet available. Thank you for your patience.
      </p>
    </div>
  );
}

export default WorkInProgress;
