import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import landingImg from "../../../assets/images/Dashboard-images/project-management.svg";
import delete_icon from "../../../assets/images/delete_icon.svg";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import { IconButton } from "@mui/material";
import { config } from "../../config/config";
import { getNormalText } from "../../utils/utils";

const useStyles = (customColor) =>
  makeStyles((theme) => ({
    card: {
      boxSizing: "border-box",
      backgroundColor: customColor
        ? customColor
        : theme.palette.mode === "dark"
        ? "#282828"
        : "#fff",
      // border:
      //     theme.palette.mode === "dark"
      //         ? "transparent"
      //         : "0.2px solid #e7e9ee",
      // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
      boxShadow: config.cssValues.cardShadow,
      borderRadius: "5px",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      fontSize: "14px",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      padding: "5px",
      position: "relative",
      "& .deleteLogo": {
        display: "none",
      },
      "& .editBtn": {
        display: "inherit",
      },
      "&:hover": {
        "& .deleteLogo": {
          display: "block",
        },
        // "& .editBtn": {
        //   display: "inherit",
        // },
      },
      "& .googleIcons": {
        background:
          "linear-gradient(45deg, #FC007F 0%,#FC007F 50% ,#FF4D48 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
      },
      "& .normalIcons": {
        color: "#FC007F",
      },
    },
    title: {
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "10px",
      lineHeight: "16px",
      textAlign: "center",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      // color: "#282828",
      marginTop: "4px",
      overflow: "hidden",
      overflowWrap: "anywhere",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      "& p": {
        textAlign: "center !important",
        lineHeight: "20px",
        margin: 0,
        padding: 0,
        whiteSpace: "pre-wrap",
      },
    },
    img: {
      height: "40px",
      // maxWidth: "60%",
      // maxHeight: "60%",
    },
    deleteIcon: {
      position: "absolute",
      left: " -6px",
      top: "-6px",
      height: "15px",
    },
    itemContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));

const TemplateCard = ({
  data,
  handleNavigation,
  parentData,
  handleDelete,
  handleEdit,
  notEditable = false,
  normalIcons,
  editable_layout,
  ...rest
}) => {
  const classes = useStyles(data.card_bg_color)();
  const refName = useRef();

  const handleCustomEdit = () => {
    // e.stopPropagation();
    handleEdit(data, parentData);
  };
  const handleCustomDelete = (e) => {
    e.stopPropagation();
    handleDelete(data, [], parentData);
  };
  const getTextForTitle = () => {
    if (!data.sub_category_description?.startsWith('{"blocks"')) {
      return data.sub_category_description;
    } else {
      let reqText = "";
      JSON.parse(data.sub_category_description)?.blocks?.forEach(
        (item) => (reqText = reqText + item.text + "\n")
      );
      return reqText;
    }
  };

  // useEffect(() => {
  //     if (specialFeilds.includes(data.sub_category_type)) {
  //         const name = getEditorState(data.sub_category_name);
  //         const reqHtmlName = draftToHtml(
  //             convertToRaw(name.getCurrentContent())
  //         );
  //         if (refName?.current) {
  //             refName.current.innerHTML = reqHtmlName
  //                 .replace(/&amp;/g, "&")
  //                 .replace(/&lt;/g, "<")
  //                 .replace(/&gt;/g, ">")
  //                 .replace(/&quot;/g, '"')
  //                 .replace(/&apos;/g, "'");
  //         }
  //     }
  // }, [data.sub_category_name, data.sub_category_type]);
  const path_has_register =
    window.location.pathname.split("/")[1] === "register";

  return (
    <div
      className={classes.card}
      key={data.sub_category_id}
      // onClick={() =>
      //   !editable_layout && path_has_register
      //     ? handleNavigation(data, parentData)
      //     : handleNavigation(data, parentData)
      // }
      onClick={
        () =>
          path_has_register
            ? editable_layout
              ? handleCustomEdit()
              : handleNavigation(data, parentData)
            : handleNavigation(data, parentData)

        // !editable_layout && path_has_register
        //   ? handleNavigation(data, parentData)
        //   : handleNavigation(data, parentData)
      }
      title={getTextForTitle()}
      {...rest}
    >
      {!notEditable && editable_layout && (
        <>
          <img
            src={delete_icon}
            alt="delete"
            className={`${classes.deleteIcon} deleteLogo`}
            onClick={handleCustomDelete}
            title="Delete"
          />
          {data.sub_category_type === "hybrid" && (
            <IconButton
              sx={{ position: "absolute", top: 0, right: 0 }}
              // onClick={handleCustomEdit}
              onClick={(e) => {
                e.stopPropagation();
                handleNavigation(data, parentData);
              }}
              title="Open"
              className="editBtn"
            >
              <ExitToAppRoundedIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          )}
        </>
      )}
      <div
        className={classes.itemContainer}
        style={{
          alignItems: "flex-end",
          display: data.sub_category_icon ? "flex" : "none",
        }}
      >
        {data.app_logo_type === "google_font" ? (
          <span
            class={`material-icons-outlined ${
              normalIcons ? "normalIcons" : "googleIcons"
            }`}
            style={{ fontSize: "32px" }}
          >
            {data.sub_category_icon}
          </span>
        ) : (
          <img
            src={data.sub_category_icon || landingImg}
            alt=""
            className={classes.img}
          />
        )}
      </div>
      <span
        className={`${classes.title} ${classes.itemContainer}`}
        ref={refName}
        style={{ fontSize: data.sub_category_icon ? "11px" : "13px" }}
      >
        {getNormalText(data.sub_category_name || "")}
      </span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  editable_layout: state.registerNewApp.statusData.editLayout,
});

export default connect(mapStateToProps)(TemplateCard);
