import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Import config
import { config } from "../../config/config";

// Import Assets
import new_app_img from "../../../assets/images/new-theme/new_icon.svg";
import delete_icon from "../../../assets/images/delete_icon.svg";

// Import custom components
import AppInfoCard from "../AppInfoCard/AppInfoCard";
import RequestAccessModal from "../RequestAccessModal/RequestAccessModal";

// Import Hooks
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

// Import Styles
import "./RecommendedAppCard.scss";

// Import actions
import {
  updated_total_views,
  updateLoyoutDataList,
  update_home_layout,
} from "../../redux/actions";
import Loader from "../Loader/Loader";
import { checkNewApp } from "../../utils/utils";
import { flexbox } from "@material-ui/system";

function fetchAPI(payload) {
  const url = `/api/irrelevantapps`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const RecommendedAppCard = (props) => {
  const { app, user = {}, handleRemove, layoutDataList, AppsData } = props;
  const [hover, setHover] = useState(false);
  const [openInfoCard, setOpenInfoCard] = useState(false);
  const { data: appIdList } = layoutDataList;
  const [loadHomeApp, setLoadHomeApp] = useState("pending");
  const [reqState, setReqState] = useState("pending");
  const [AppAccess, setAppAccess] = useState(false);
  const [openRA, setOpenRA] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  var appName = app.app_meta.app_name;
  if (appName.length > 25) {
    appName = appName.slice(0, 25) + "...";
  }

  function hasViewAccess(person) {
    return user.preferred_username === person.user_id;
  }
  const HandleOpenDetails = (e, app_id) => {
    e.stopPropagation();
    setOpenInfoCard(true);
  };

  const handleNavigate = (path) => {
    history.push(path);
  };

  const HandleAddAppToHome = async (e, id) => {
    e.stopPropagation();
    setLoadHomeApp("loading");
    let data = {
      user_id: user.preferred_username,
    };
    const gaEventTracker = useAnalyticsEventTracker("AddOrRemFromHome");
    if (!appIdList.includes(id)) {
      data = {
        ...data,
        sequence_of_app: [id, ...appIdList],
      };
      gaEventTracker("Add", `app_id = ${id}`);
    } else {
      const reqList = appIdList.filter((item) => item !== id);
      data = { ...data, sequence_of_app: reqList };
      gaEventTracker("Remove", `app_id = ${id}`);
    }
    dispatch(updateLoyoutDataList(data.sequence_of_app));
    dispatch(update_home_layout(data));
  };

  const handleOpenInNewTab = (app) => {
    dispatch(
      updated_total_views({
        app_id: app.app_id,
      })
    );
    if (
      app.app_meta.app_type === "ml_app" &&
      !app.app_meta.app_url.startsWith("https://")
    ) {
      window.open(
        "https://" + app.app_meta.app_url,
        app.app_meta.app_window_Name
      );
    } else {
      window.open(app.app_meta.app_url, app.app_meta.app_window_Name);
    }
  };

  const HandleOpenApp = (app) => {
    if (AppAccess || app?.access_details?.access_type === "public") {
      if (
        app.app_meta.app_type === "ml_app" ||
        app.app_meta.app_type === "standalone_app"
      ) {
        handleOpenInNewTab(app);
      } else if (app.app_meta.app_type === "bi_app") {
        handleNavigate(app.app_meta.app_path);
      }
    } else if (!AppAccess) {
      setOpenRA(true);
    }
  };

  const handleRemoveApp = async (e, app) => {
    e.stopPropagation();
    handleRemove(app);
    // call the delete api here
    let payload = {
      user_id: user.preferred_username,
      irrelevant_apps: [app.app_id],
    };
    try {
      const res = await fetchAPI(payload);
      const response = await res.json();
      // console.log("Response", response);
      // update the recommended apps array here
    } catch (error) {
      console.log("ERROR", error);
      toast.error(
        "Oops! Couldn't remove the app from recommendations. Please try later."
      );
    }
  };

  const handleCloseAppInfoCard = () => {
    setReqState("pending");
    setOpenInfoCard(false);
  };
  const handleCloseRAcard = () => {
    setOpenRA(false);
  };

  const newApp = useMemo(() => checkNewApp(app), [app]);

  useEffect(() => {
    const accessableUser = app.access_details
      ? app.access_details.users_list.filter(hasViewAccess)
      : [];
    const accessStatus = accessableUser.length ? true : false;
    setAppAccess(accessStatus);
  }, []);

  useEffect(() => {
    if (AppsData?.refreshedLayoutApps?.status === "success") {
      setLoadHomeApp("success");
    }
  }, [AppsData.refreshedLayoutApps]);

  return (
    <>
      <div
        title={app.app_meta.app_name}
        className={`app-card-styles-RAC ${user.ui_preferences.theme}-RAC`}
        onClick={() => {
          HandleOpenApp(app);
        }}
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseOut={() => {
          setHover(false);
        }}
      >
        {/* cross to remove app */}
        {
          <img
            title="Not Interested!"
            className="GA-delete-RAC-app"
            id={app.app_id}
            style={{
              position: "absolute",
              left: "-4px",
              top: "-4px",
              height: "17px",
              display: hover ? "block" : "none",
            }}
            onClick={(e) => {
              handleRemoveApp(e, app);
            }}
            src={delete_icon}
            alt="delete"
          ></img>
        }
        <div
          style={{
            width: "100%",
          }}
        >
          <div className="div-box-recommended">
            {/* App Icon */}
            {app.app_meta?.app_logo_type === "google_font" ? (
              <i
                style={{
                  fontSize: "18px",
                  color: `${
                    user.ui_preferences.theme === "light" ? "#f7007f" : "#fff"
                  }`,
                  borderRadius: "32px",
                  padding: "5px",
                  border: "1px solid rgba(0, 0, 0, 0.18)",
                }}
                className={
                  hover
                    ? "material-icons-outlined application-icon-hover-1 GA-RAC-app-card-clicked"
                    : "material-icons-outlined application-icon-1 GA-RAC-app-card-clicked"
                }
                id={app?.app_id}
              >
                {app.app_meta?.app_logo}
              </i>
            ) : (
              <img
                src={app.app_meta?.app_logo}
                className="GA-RAC-app-card-clicked"
                id={app?.app_id}
                style={{
                  borderRadius: "32px",
                  padding: "5px",
                  border: "1px solid rgba(0, 0, 0, 0.18)",
                  width: "18px",
                  borderRadius: "100px",
                }}
                alt="icon"
              />
            )}
            {/* App Name */}
            <p
              id={app?.app_id}
              className={`GA-RAC-app-card-clicked"
                ${hover ? "application-name-RAC-hover" : "application-name-RAC"}
              `}
              style={{
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              {appName}
            </p>
            {loadHomeApp !== "loading" && (
              <i
                style={{
                  display: hover ? "block" : "none",
                }}
                className={`${
                  appIdList.includes(app.app_id)
                    ? "material-icons-round"
                    : "material-icons-outlined"
                } app-status-home-icon-RAC GA-RAC-add-to-home`}
                title={
                  appIdList.includes(app.app_id)
                    ? "Remove from home"
                    : "Add to home"
                }
                id={app.app_id}
                onClick={(e) => HandleAddAppToHome(e, app.app_id)}
              >
                {appIdList.includes(app.app_id) ? "home" : "add_home"}
              </i>
            )}

            {loadHomeApp === "loading" && (
              <div className="fav-icon-visible">
                <Loader />
              </div>
            )}
          </div>
          <div>
            <p
              style={{
                // marginTop: "7px",
                fontSize: "10px",
                marginLeft: "22px",
                lineHeight: "15px",
                color:
                  user.ui_preferences.theme === "light" ? "#4e4e4e" : "#fff",
              }}
              className="application-description-RAC GA-RAC-app-card-clicked"
            >
              {app.app_details.description}
            </p>
          </div>
          <div>
            {app.approval_meta.approval_status === "published" && (
              <button
                style={{
                  left: "44px",
                  bottom: "8px",
                  fontSize: "10px",
                  height: "18px",
                }}
                className={
                  hover ? "details-button-RAC-hover" : "details-button-RAC"
                }
                onClick={(e) => {
                  HandleOpenDetails(e, app.app_id);
                }}
              >
                Go to details
              </button>
            )}
            {newApp && (
              <img
                className="GA-RAC-app-card-clicked"
                id={app.app_id}
                style={{
                  height: "13px",
                  position: "absolute",
                  right: "0px",
                  bottom: "8px",
                }}
                src={new_app_img}
                alt="new-app"
              ></img>
            )}
          </div>
        </div>
      </div>
      {openInfoCard && (
        <AppInfoCard
          open={openInfoCard}
          handleClose={handleCloseAppInfoCard}
          appDetails={app}
          setOpenRA={setOpenRA}
          reqState={reqState}
          setReqState={setReqState}
        />
      )}
      {openRA && (
        <RequestAccessModal
          openRA={openRA}
          handleClose={handleCloseRAcard}
          appDetails={app}
          setOpenRA={setOpenRA}
          reqState={reqState}
          setReqState={setReqState}
        />
      )}
    </>
  );
};

RecommendedAppCard.propTypes = {};
const mapStateToProps = (state) => ({
  user: state.user,
  AppsData: state.AppsData,
  layoutDataList: state.AppsData.layoutDataList,
});

export default connect(mapStateToProps)(RecommendedAppCard);
