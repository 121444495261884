import React from "react";
import { Box, Grid } from "@mui/material";
import FormLabelComponent from "../../../components/FormLabelComponent";
import { BootstrapInput } from "../style/style";
import "../style/style.scss";

const InputTextBox = (props) => {
    const {
        handleChange,
        value = "",
        data,
        width,
        isFieldsEditable,
        hasError,
        isFieldRed,
        multiline,
    } = props;
    const { label, key, required, toolTipText } = data;

    return (
        <Grid item xs={width || 4}>
            <div className="help-center-desc-warning">
                <FormLabelComponent
                    required={required}
                    label={label}
                    toolTipText={toolTipText}
                />
                {key === "description" && (
                    <Box ml="auto" className="help-center-label">
                        <span
                            style={{
                                color:
                                    (value && value.length > 1000) || !value
                                        ? "red"
                                        : "inherit",
                            }}
                        >
                            {value ? value.length : 0}
                        </span>{" "}
                        / 1000
                    </Box>
                )}
            </div>
            <BootstrapInput
                onChange={(e) => handleChange(key, e.target.value.trimStart())}
                value={value}
                multiline={multiline}
                placeholder={`Enter ${label}`}
                disabled={isFieldsEditable}
                className={`${hasError || isFieldRed ? "error" : ""}`}
                fullWidth
                rows={multiline ? 4 : 1}
            />
            {hasError && (
                <div className="validation_msg" style={{ color: "red" }}>
                    This field is required*
                </div>
            )}
        </Grid>
    );
};

export default InputTextBox;
