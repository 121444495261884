import React, { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import TemplateCard from "./TemplateCard";
import "./style.scss";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const ReactGridTemplate = ({
    data = [],
    handleNavigation,
    handleEdit,
    handleDelete,
    onLayoutChange,
    customData = {},
    compactType = "horizontal",
    isBounded = false,
    notEditable,
    editable_layout,
    normalIcons,
    ...rest
}) => {
    const [mounted, setMounted] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setResizing] = useState(false);

    const onDrag = (e) => {
        setIsDragging(true);
    };
    const onResizeStart = (e) => {
        setResizing(true);
    };

    const onItemClick = (item, parentItem) => {
        // idiomatic way to prevent a click when resizing
        if (!isDragging && !isResizing) handleNavigation(item, parentItem);
    };

    const onDragStop = (e) => {
        // HACK: add some delay otherwise a click event is sent
        setTimeout(() => setIsDragging(false), 200);
    };
    const onResizeStop = (e) => {
        // HACK: add some delay otherwise a click event is sent
        setTimeout(() => setResizing(false), 200);
    };

    const resizeHandles = ['se', 'ne']

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <ResponsiveReactGridLayout
            onLayoutChange={onLayoutChange}
            measureBeforeMount={false}
            useCSSTransforms={mounted}
            onDrag={onDrag}
            onDragStop={onDragStop}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeStop}
            compactType={compactType}
            isBounded={isBounded}
            rowHeight={60}
            cols={{ lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }}
            resizeHandles={editable_layout?resizeHandles:[]}
            {...rest}
        >
            {data.map((item) => {
                return (
                    <div
                        key={item.sub_category_id}
                        data-grid={{ ...item.position_meta, ...customData }}
                    >
                        <TemplateCard
                            key={item.sub_category_id}
                            data={item}
                            handleNavigation={onItemClick}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            notEditable={notEditable}
                            normalIcons={normalIcons}
                            isDragging={isDragging}
                        />
                    </div>
                );
            })}
        </ResponsiveReactGridLayout>
    );
};

export default ReactGridTemplate;
