import C from "../constants";

export default function appTemplate(state = {}, payload) {
  let { type, data } = payload;
  switch (type) {
    case C.ADD_REPORT_TEMPLATE:
      return {
        ...state,
        appTemplateState: {
          ...state.appTemplateState,
          ...data,
        },
      };
    case C.DELETE_REPORT_TEMPLATE:
      return {
        ...state,
        appTemplateState: {
          ...state.appTemplateState,
          ...data,
        },
      };
    case C.UPDATE_REPORT_TEMPLATE:
      return {
        ...state,
        appTemplateState: {
          ...state.appTemplateState,
          ...data,
        },
      };
    case C.CLEAN_APP_TEMP_STATE:
      return {
        ...state,
        appTemplateState: { templateData: {} },
      };
    default:
      return state;
  }
}
