import React from "react";
import { Grid, Box } from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import runError from "../../../../assets/images/register-images/run-error.svg";
import runSuccess from "../../../../assets/images/register-images/run-success.svg";
import { BootstrapInput, statusIconStyle } from "../style";
import "../index.scss";
import { getFieldLabel } from "./DashboardTemplate/utils";
import { bottom } from "@popperjs/core";

const InputFiled = (props) => {
  const {
    handleChange,
    value,
    data,
    width,
    isFieldsEditable,
    waiting,
    isAppUnique,
    validationMsg,
    hasError,
    isFieldVibrating,
    isFieldRed,
    URLValid,
    localState = {},
  } = props;
  const { label, key, required } = data;

  return (
    <Grid item xs={width || 4}>
      <Box display="flex" alignItems="center" className="heading">
        <div>
          {label}{" "}
          <span
            style={{
              display: required || key === "url" ? "" : "none",
            }}
          >
            *
          </span>
          :
        </div>
        {key === "description" && (
          <Box ml="auto">
            <span
              style={{
                color:
                  (value && value.length > 500) || !value ? "red" : "inherit",
              }}
            >
              {value ? value.length : 0}
            </span>{" "}
            / 500
          </Box>
        )}
      </Box>
      <Box position={"relative"}>
        <BootstrapInput
          onChange={(e) => handleChange(key, e.target.value.trimStart())}
          value={value}
          multiline={
            key === "description" || key === "sub_category_description"
          }
          placeholder={
            key === "sub_category_name" ? "Enter text here" : `Enter ${label}`
          }
          disabled={isFieldsEditable}
          className={`${isFieldVibrating && "shake-category-field"} ${
            hasError || isFieldRed ? "error" : ""
          }`}
          fullWidth
          rows={
            key === "description" || key === "sub_category_description" ? 4 : 1
          }
        />
        {hasError && (
          <div className="validation_msg" style={{ color: "red" }}>
            This field is required*
          </div>
        )}
        {key === "app_name" && (
          <>
            <Box position={"absolute"} right={0} top="2px" zIndex={10}>
              {waiting && <Loader />}
              {!waiting && isAppUnique ? (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              ) : null}
              {!waiting && !isAppUnique && validationMsg !== "" && (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Already exists"
                  alt="Failed"
                />
              )}
            </Box>
            {validationMsg && !isAppUnique && !waiting && (
              <div
                className="validation_msg"
                style={{ color: isAppUnique ? "green" : "red" }}
              >
                {validationMsg}
              </div>
            )}
          </>
        )}
        {key === "url" && (
          <>
            <Box position={"absolute"} right={0} top="2px" zIndex={10}>
              {URLValid.status === "Valid" && (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              )}
              {URLValid.status === "Invalid" && (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Invalid URL"
                  alt="Failed"
                />
              )}
            </Box>
            {URLValid.status === "Invalid" && (
              <div className="validation_msg" style={{ color: "red" }}>
                {URLValid.msg}
              </div>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default InputFiled;
