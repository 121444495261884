import React from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Graticule,
    Line,
    Sphere,
} from "react-simple-maps";
import geoData from "../../../../assets/data/geoData.json";

// const geoUrl =
//     "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

// https://www.worldatlas.com/articles/top-coffee-producing-countries.html

const MapChart = ({ regions }) => {
    return (
        <ComposableMap projection="geoEqualEarth">
            <Geographies geography={geoData} stroke="#FFF" strokeWidth={0.5}>
                {({ geographies }) =>
                    geographies.map((geo) => {
                        const isHighlighted =
                            //   regions.includes(geo.properties.REGION_UN) ||
                            //   regions.includes(geo.properties.SUBREGION) ||
                            regions.includes(
                                geo.properties["Alpha-2"]?.toLowerCase()
                            );
                        return (
                            <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                stroke="#75808a"
                                // strokeWidth="0.5px"
                                fill={isHighlighted ? "#f7bedd" : "#ffffff"}
                                onClick={() => console.log(geo.properties)}
                            />
                        );
                    })
                }
            </Geographies>
        </ComposableMap>
    );
};

export default MapChart;
