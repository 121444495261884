import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

// Import mui components
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box, withStyles, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { update_accessManagement } from "../../../redux/actions";
// Import Styles
import { StyledAutoComplete, CssTextField, useStylesforSF } from "../style";
import { useStyles } from "../../../screens/Explore/style";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
// import {
//     StyledAutoComplete,
//     CssTextField,
//     useStylesforSF,
// } from "../../screens/Register/style";
// import { useStyles } from "../Explore/style";
// import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" htmlColor="#FF007F" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CssTextField2 = withStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.light
        : theme.palette.white.main,
    padding: "6px 8px",
    border: theme.palette.mode ==="dark"?"1px solid #757575":"1px solid #E7E9EE",
    borderRadius: "3px",
    "& label.Mui-focused": {},
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgba(66, 89, 108, 0.25)",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(66, 89, 108, 0.25)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid rgba(66, 89, 108, 0.25)",
      },
    },
  },
}))(TextField);

function compare(a, b) {
  if (a.country < b.country) {
    return -1;
  }
  if (a.country > b.country) {
    return 1;
  }
  return 0;
}

// Child component
const SelectedFilters = ({
  filter,
  securityFilterArr,
  setSecurityFilterArr,
  setBrandOptions,
  setCountry,
  securityFilterData,
  setBrandOptionsArr,
  setCloningUser,
  countryOptionsInitialValue,
  setCountryOptionsArr,
}) => {
  const classes = useStylesforSF();
  // handler functions
  const handleDeleteFilter = (country) => {
    const updatedArr = securityFilterArr.filter(
      (val) => val.country !== country
    );
    updatedArr.sort(compare);
    setSecurityFilterArr(updatedArr);
    if (updatedArr.length === 0) {
      setCountry("");
      setBrandOptions([]);
      setSecurityFilterArr([{ country: "All", brands_list: ["All"] }]);
      setCountryOptionsArr(countryOptionsInitialValue);
    }
    const selectedCountries = updatedArr.map((value) => value.country);
    const array3 = countryOptionsInitialValue.filter((obj) => {
      return selectedCountries.indexOf(obj) == -1;
    });
    setCountryOptionsArr(array3);
    setCloningUser("");
  };
  const handleEditFilter = (country) => {
    const editableArr = securityFilterArr.filter(
      (val) => val.country === country
    );
    setCountry(editableArr[0].country);
    setBrandOptions(editableArr[0].brands_list);
    setBrandOptionsArr(securityFilterData[country]);
  };
  return (
    <div className={classes.individualFilterContainer}>
      <div className={classes.displayFlex}>
        {filter.country !== "All" && (
          <span
            class="material-icons-outlined GA-edit-security-filter"
            style={{ color: "#d8477e", fontSize: "20px" }}
            onClick={() => handleEditFilter(filter.country)}
          >
            edit
          </span>
        )}

        <div className={classes.countryName}>
          {filter.country !== "All" ? filter.country : "All Countries"}
        </div>
        <div className={classes.brandsList}>
          {filter.brands_list.map((brand) => {
            return (
              <span className={classes.brandName}>
                {brand !== "All" ? brand : "All Brands"}
              </span>
            );
          })}
        </div>
      </div>
      {filter.country !== "All" && (
        <span
          class="material-icons-outlined GA-delete-security-filter"
          style={{ color: "#d8477e", fontSize: "20px" }}
          onClick={() => handleDeleteFilter(filter.country)}
        >
          delete
        </span>
      )}
    </div>
  );
};

// Main component
const SecurityFilter = ({
  open,
  handleClose,
  row,
  appDetails,
  accessList,
  accessListClone,
  securityFilterData,
  theme,
}) => {
  const dispatch = useDispatch();
  const classes = useStylesforSF();
  const classes1 = useStyles();
  const eventTracker = useAnalyticsEventTracker(
    "Access management",
    appDetails.data.app_id
  );

  const countryOptionsInitialValue = Object.keys(securityFilterData);
  // const filteredCountryOptionsArr = countryOptionsInitialValue.filter(
  //   (val) => val.country === country
  // );
  const [securityFilterArr, setSecurityFilterArr] = useState([]);
  const [brandOptionsArr, setBrandOptionsArr] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [countryOptionsArr, setCountryOptionsArr] = useState(
    countryOptionsInitialValue
  );
  const [country, setCountry] = useState("");
  const [cloningUser, setCloningUser] = useState("");
  //  console.log("countryOptionsArr", countryOptionsArr);
  const userNameArr1 = accessListClone.map((user) => {
    return user.user_id;
  });
  const userNameArr = userNameArr1.filter((id) => id !== row.user_id);

  const handleChangeCountry = (country) => {
    const findDuplicateCountry = securityFilterArr.filter(
      (filter) => filter.country === country
    );
    if (findDuplicateCountry.length > 0) {
      setCountry("");
      setBrandOptions([]);
      setBrandOptionsArr([]);
      return;
    }
    setCountry(country);
    setBrandOptions([]);
    setBrandOptionsArr(securityFilterData[country]);
  };

  const handleChangeBrand = (brand) => {
    if (brand[0] === "All") {
      if (brandOptions.includes(brand[0])) {
        setBrandOptions([]);
      } else {
        setBrandOptions(["All"]);
      }
    } else {
      if (brandOptions.includes(brand[0])) {
        const filteredData = brandOptions.filter((val) => val !== brand[0]);
        const filteredData2 = filteredData.filter((val) => val !== "All");
        const newData = [...filteredData2];
        setBrandOptions(newData);
      } else {
        const filteredData = brandOptions.filter((val) => val !== "All");
        const newData = [...filteredData, brand[0]];
        setBrandOptions(newData);
      }
    }
  };

  const handleAddFilter = () => {
    if (brandOptions.length === 0 || country === "") {
      return;
    }
    //remove the default value "ALL" while adding first filter.
    //also remove the identical country filters
    const findnew_Val = securityFilterArr.filter(
      (val) => val.country !== "All" && val.country !== country
    );
    //remove added country
    const countryListArr = countryOptionsArr.filter((val) => val !== country);
    //  console.log("countryListArr", countryListArr);
    const newFilter = { country: country, brands_list: brandOptions };
    setCountry("");
    setBrandOptions([]);
    const updatedSF = [...findnew_Val, newFilter];
    updatedSF.sort(compare);
    setSecurityFilterArr(updatedSF);
    setCloningUser("");
    setCountryOptionsArr(countryListArr);
    setBrandOptionsArr([]);
  };

  const handleClearFilter = () => {
    setCountry("");
    setBrandOptions([]);
    setSecurityFilterArr([{ country: "All", brands_list: ["All"] }]);
    setCloningUser("");
    setBrandOptionsArr([]);
    setCountryOptionsArr(countryOptionsInitialValue);
    eventTracker("Clear security filters", "Clear filters");
  };

  const handleCloneUser = (newUser) => {
    setCloningUser(newUser);
    const clonedUser = accessListClone.filter(
      (user) => user.user_id.toLowerCase() === newUser.toLowerCase()
    );
    const SF_arr = clonedUser[0].security_filters;
    SF_arr.sort(compare);
    setSecurityFilterArr(SF_arr);
    const selectedCountries = SF_arr.map((value) => value.country);
    const array3 = countryOptionsInitialValue.filter((obj) => {
      return selectedCountries.indexOf(obj) == -1;
    });
    setCountryOptionsArr(array3);
    setCountry("");
    setBrandOptions([]);
    setBrandOptionsArr([]);
    eventTracker("Clone security filters", "selected");
  };

  const IsSaveDisabled = () => {
    if (securityFilterArr.length === 0) {
      return true;
    } else {
      return false;
    }
  };
  const IsAddDisabled = () => {
    if (brandOptions.length === 0 || country === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleSaveFilters = () => {
    const index = accessList.findIndex((el) => el.user_id === row.user_id);
    const indexClone = accessListClone.findIndex(
      (el) => el.user_id === row.user_id
    );
    const notUpdatedRecordsLeft = accessList.slice(0, index);
    const notUpdatedRecordsRight = accessList.slice(index + 1);
    const notUpdatedRecordsLeftClone = accessListClone.slice(0, indexClone);
    const notUpdatedRecordsRightClone = accessListClone.slice(indexClone + 1);
    const updatedState = [
      ...notUpdatedRecordsLeft,
      {
        ...row,
        security_filters: securityFilterArr,
      },
      ...notUpdatedRecordsRight,
    ];
    const updatedStateClone = [
      ...notUpdatedRecordsLeftClone,
      {
        ...row,
        security_filters: securityFilterArr,
      },
      ...notUpdatedRecordsRightClone,
    ];
    // setAccessList(updatedState);
    // setAccessListClone(updatedStateClone);
    dispatch(
      update_accessManagement({
        accessList: updatedState,
        accessListClone: updatedStateClone,
      })
    );
    handleClose();
    eventTracker("Save security filters", "Save filters");
  };

  useEffect(() => {
    const filterUser = accessList.filter((val) => val.user_id === row.user_id);
    const SFvalue = filterUser[0].security_filters;
    SFvalue.sort(compare);
    setSecurityFilterArr(SFvalue);
    const selectedCountries = SFvalue.map((value) => value.country);
    const array3 = countryOptionsInitialValue.filter((obj) => {
      return selectedCountries.indexOf(obj) == -1;
    });
    setCountryOptionsArr(array3);
  }, []);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        minWidth: "700px !important",
        maxWidth: "800px !important",
      }}
      className={classes.alertContainer}
    >
      <div className={classes.dailogContainer}>
        <Box className={classes.flexSB} mb="10px" p="5px">
          <div className={classes.displayFlex}>
            <h4 className={classes.headingSecurityFilters}>Security Filters</h4>
            <span
              class="material-icons"
              style={{
                fontSize: "24px",
                marginRight: "5px",
                //  color: "#282828",
              }}
            >
              person
            </span>
            <h6 className={classes.personName}>{row.user_id}</h6>
          </div>
          <span class="material-icons-outlined" onClick={handleClose}>
            close
          </span>
        </Box>
        <Box alignItems="center" mb={0}>
          <div className={classes.filterHeaderContainer}>
            <div className={classes.countryContainer}>
              <h6 className={classes.countryHeading}>Select Country :</h6>
              <StyledAutoComplete
                value={country || ""}
                options={countryOptionsArr}
                getOptionLabel={(option) => option}
                disableClearable
                onChange={(e, newValue) => {
                  handleChangeCountry(newValue);
                }}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    className={`${classes1.typographyStyle} ${
                      country ? classes1.active : classes1.SecFilUnActive
                    }`}
                    fullWidth
                    placeholder="Enter Country"
                  />
                )}
                style={{
                  width: "180px",
                }}
              />
            </div>
            <div className={classes.countryContainer}>
              <h6 className={classes.countryHeading}>Select Brands :</h6>
              <StyledAutoComplete
                multiple
                className={classes1.typographyStyle}
                options={brandOptionsArr}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={[]}
                getOptionSelected={(option, value) => option === value}
                renderOption={(option, { selected }) => {
                  return (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className={classes1.typographyStyle}
                        size="small"
                        style={{
                          marginRight: 5,
                          padding: "0px",
                        }}
                        checked={(brandOptions || []).includes(option)}
                      />
                      {option}
                    </React.Fragment>
                  );
                }}
                style={{ width: "140px" }}
                onChange={(e, newValue) => {
                  handleChangeBrand(newValue);
                }}
                renderInput={(params) => (
                  <CssTextField2
                    {...params}
                    className={`${classes1.typographyStyle}  ${
                      brandOptions.length
                        ? classes1.active
                        : classes1.SecFilUnActive
                    }`}
                    fullWidth
                    placeholder={
                      brandOptions.length
                        ? `${"brands"}  +${brandOptions.length}`
                        : "Enter brands"
                    }
                  />
                )}
              />
            </div>

            <button
              className={`${classes.AddFilterButton} GA-add-security-filter`}
              onClick={handleAddFilter}
              disabled={IsAddDisabled()}
            >
              Add Filter
            </button>
          </div>
        </Box>
        <DialogContent style={{ maxHeight: "300px", minHeight: "150px" }}>
          {securityFilterArr.map((filter) => {
            return (
              <SelectedFilters
                filter={filter}
                key={filter.country}
                securityFilterArr={securityFilterArr}
                setSecurityFilterArr={setSecurityFilterArr}
                setCountry={setCountry}
                setBrandOptions={setBrandOptions}
                securityFilterData={securityFilterData}
                setBrandOptionsArr={setBrandOptionsArr}
                setCloningUser={setCloningUser}
                setCountryOptionsArr={setCountryOptionsArr}
                countryOptionsInitialValue={countryOptionsInitialValue}
              ></SelectedFilters>
            );
          })}
        </DialogContent>
        <DialogActions className={classes.AlertFooterContainer}>
          <div>
            <span className={classes.cloneWarning}>
              * Existing Filters will be removed after cloning.
            </span>
            <StyledAutoComplete
              value={cloningUser}
              classes={{ paper: "GA-clone-security-filter" }}
              options={userNameArr}
              getOptionLabel={(option) => option}
              disableClearable
              onChange={(e, newUser) => {
                handleCloneUser(newUser);
              }}
              style={{ width: "350px" }}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  className={`${classes1.typographyStyle} ${classes1.SecFilUnActive}`}
                  fullWidth
                  placeholder="Clone Filters From"
                />
              )}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <button
              className="GA-clear-security-filter"
              style={{
                background: "transparent",
                color: "#000",
                padding: "0px 20px",
                border: "1px solid #000",
              }}
              onClick={handleClearFilter}
            >
              Clear Filters
            </button>
            <button
              className={`${classes.saveButton} GA-save-security-filter`}
              onClick={handleSaveFilters}
              disabled={IsSaveDisabled()}
            >
              Save Filters
            </button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};
SecurityFilter.propTypes = {
  open: PropTypes.any.isRequired,
  handleClose: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  accessListClone: state.registerNewApp.accessManagement.accessListClone,
  accessList: state.registerNewApp.accessManagement.accessList,
  user: state.user,
  AppsData: state.AppsData,
  theme: state.user.ui_preferences?.theme,
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(SecurityFilter);
