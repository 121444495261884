// Import required libraries
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Popover from "@material-ui/core/Popover";
import Badge from "@material-ui/core/Badge";

// Import custom components
import Loader from "../../../components/Loader/Loader";
import dataCatalogFilterData from "../../../../assets/data/dataCatalogFilterData.json";

// Import styles
import "./DataAssetsFilters.scss";

// Import data & utils
import { populateFiltersData } from "../../../utils/utils";

// Import action creators
import { resetFilters, updateUserInfo } from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
    multiSelect: {
        fontSize: "12px",
        fontFamily: "Energy",
        width: "100%",
        height: "50px",
        borderRadius: "5px",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        backgroundColor: theme.palette.mode === "dark" ? "#282828" : "white",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        border: "1px solid #dedede",
        "&:before": {
            borderBottom: "0px",
        },
        "&:after": {
            borderBottom: "0px",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "0px",
        },
        "& .MuiOutlinedInput-input": {
            padding: "6px 6px",
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingLeft: "8px",
        },
    },
    singleMenuItem: {
        fontSize: "12px",
        fontFamily: "Energy",
        padding: "4px 12px",
        color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        width: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        "&:hover": {
            backgroundColor: "#4EAFB3",
            color: "white",
        },
    },
    paper: {
        position: "absolute",
        width: "50%",
        minHeight: "200px",
        backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#FFFFFF",
        color: theme.palette.mode === "dark" ? "#fff" : "#000000",
        borderRadius: "8px",
        padding: "30px",
    },
    chipStyle: {
        fontFamily: "Energy",
        fontSize: 14,
        border: "1px solid #dedede",
        color: "white !important",
        height: "30px",
        borderRadius: "3px",
        width: "fit-content",
        maxWidth: "90%",
        margin: "12px",
        background: "#4EAFB3",
        "& .MuiChip-deleteIcon": {
            fontSize: 20,
            border: "none",
            color: "white",
        },
    },
    bellIconStyle: {
        "& .MuiBadge-dot": {
            backgroundColor: "#F10000 !important",
        },
        "& .MuiBadge-anchorOriginTopRightRectangle": {
            top: "2px",
            right: "-3px",
        },
    },
    inputSearchMode: {
        backgroundColor:
            theme.palette.mode === "dark"
                ? "#282828 !important"
                : "#fff !important",
    },
    searchIconMode: {
        color:
            theme.palette.mode === "dark"
                ? "#fff !important"
                : "#000000 !important",
    },
    inputLabelMode: {
        color:
            theme.palette.mode === "dark"
                ? "#fff !important"
                : "#000000 !important",
        backgroundColor:
            theme.palette.mode === "dark"
                ? "#282828 !important"
                : "#fff !important",
    },
    filterTitle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "32px",
        color: theme.palette.mode === "dark" ? "#fff" : "#000000",
    },
    resetFilterBtn: {
        marginLeft: "10px",
        background: "white",
        color: "#282828",
        border: "1px solid #282828",
        boxSizing: "border-box",
        borderRadius: "4px",
        height: "30px",
        width: "100px",
        fontSize: "12px",
        "&:hover": {
            background:
                theme.palette.mode === "dark" ? "#fff !important" : "#282828",
            color:
                theme.palette.mode === "dark" ? "#282828 !important" : "#fff",
        },
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 150,
            width: "auto",
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
    variant: "menu",
    getContentAnchorEl: null,
};

function getModalStyle() {
    const top = 35;
    const left = 30;

    return {
        top: `${top}%`,
        left: `${left}%`,
        border: "none !important",
    };
}

function DataAssetsFilters(props) {
    let {
        user,
        countryData,
        brandData,
        archetypeData,
        activeFilter,
        setActiveFilter,
        searchText,
        setSearchText,
    } = props;
    const [data, setData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [filterSelections, setFilterSelections] = useState(activeFilter);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const openPopup = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (countryData.data && brandData.data && archetypeData.data && !data) {
        let popFiltersData = populateFiltersData(dataCatalogFilterData, {
            country: countryData.data,
            brand: brandData.data,
            archetype: archetypeData.data,
        });
        setData(popFiltersData);
    }

    const modalOpen = () => {
        setOpenModal(true);
    };

    const modalClose = () => {
        setOpenModal(false);
    };

    const updatingSelections = (catName, trgtValue) => {
        let filterselection = filterSelections;
        setFilterSelections({ ...filterselection, [catName]: trgtValue });
    };

    const setFilterUpdations = () => {
        setActiveFilter({
            ...filterSelections,
        });
        modalClose();
    };

    const resetFiltersNew = () => {
        setFilterSelections({
            country: {
                id: "ALL",
                name: "ALL",
            },
            brand: {
                id: "ALL",
                name: "ALL",
            },
            archetype: {
                id: "ALL",
                name: "ALL",
            },
        });
        setActiveFilter({
            country: {
                id: "ALL",
                name: "ALL",
            },
            brand: {
                id: "ALL",
                name: "ALL",
            },
            archetype: {
                id: "ALL",
                name: "ALL",
            },
        });
        modalClose();
    };

    return (
        <>
            <div className="filter-new">
                <div className={`input-search ${classes.inputSearchMode}`}>
                    <i
                        className={`material-icons-outlined search-icon ${classes.searchIconMode}`}
                    >
                        search
                    </i>
                    <input
                        type="text"
                        id="search"
                        name="search"
                        value={searchText}
                        className={`input-label ${classes.inputLabelMode}`}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        placeholder="Search a dataset"
                    />
                    <i
                        className={`material-icons-outlined cancel-icon ${
                            searchText ? "visible" : ""
                        } ${classes.searchIconMode}`}
                        onClick={() => {
                            setSearchText("");
                        }}
                    >
                        clear
                    </i>
                </div>
                <div className="right-filter-div">
                    <div className="filter-btn">
                        <Badge
                            variant="dot"
                            invisible={
                                filterSelections.country.name === "ALL" &&
                                filterSelections.brand.name === "ALL" &&
                                filterSelections.archetype.name === "ALL"
                            }
                            className={classes.bellIconStyle}
                        >
                            <button onClick={modalOpen}>
                                Filters
                                <i
                                    onMouseOver={openPopup}
                                    className="material-icons filter-alt-icon"
                                >
                                    filter_alt
                                </i>
                            </button>
                        </Badge>
                        {data ? (
                            <Modal
                                open={openModal}
                                onClose={modalClose}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <div
                                    style={modalStyle}
                                    className={classes.paper}
                                >
                                    <p
                                        id="simple-modal-title"
                                        className={classes.filterTitle}
                                    >
                                        Filters
                                    </p>
                                    <>
                                        {data ? (
                                            <div
                                                className={`filters-container`}
                                            >
                                                <div className="filters">
                                                    {data.map((filterObj) => (
                                                        <div
                                                            className={`filter-item ${filterObj.name}`}
                                                            key={filterObj.key}
                                                        >
                                                            {filterObj.type ===
                                                            "text" ? (
                                                                false
                                                            ) : (
                                                                <>
                                                                    <label
                                                                        htmlFor={
                                                                            filterObj.name
                                                                        }
                                                                    >
                                                                        Select{" "}
                                                                        {
                                                                            filterObj.label
                                                                        }
                                                                    </label>
                                                                    <Select
                                                                        name={
                                                                            filterObj.name
                                                                        }
                                                                        id={
                                                                            filterObj.name
                                                                        }
                                                                        value={
                                                                            filterSelections[
                                                                                `${filterObj.name}`
                                                                            ]
                                                                                .name
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            let selectedObj =
                                                                                filterObj.data.find(
                                                                                    (
                                                                                        filterObj
                                                                                    ) =>
                                                                                        filterObj.name ===
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                );
                                                                            updatingSelections(
                                                                                filterObj.name,
                                                                                selectedObj
                                                                            );
                                                                        }}
                                                                        className={
                                                                            classes.multiSelect
                                                                        }
                                                                        MenuProps={
                                                                            MenuProps
                                                                        }
                                                                    >
                                                                        {filterObj.data.map(
                                                                            (
                                                                                obj
                                                                            ) => (
                                                                                <MenuItem
                                                                                    value={
                                                                                        obj.name
                                                                                    }
                                                                                    key={
                                                                                        obj.id
                                                                                    }
                                                                                    className={
                                                                                        classes.singleMenuItem
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        obj.name
                                                                                    }
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <Loader />
                                        )}
                                    </>
                                    <div className="filters-menu">
                                        <button
                                            className={classes.resetFilterBtn}
                                            onClick={() => {
                                                resetFiltersNew();
                                            }}
                                        >
                                            Reset Filters
                                        </button>
                                        <button
                                            className="apply-filter-btn"
                                            onClick={setFilterUpdations}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                </div>
                            </Modal>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            {
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    PaperProps={{
                        style: { width: "20%" },
                    }}
                >
                    <div className="filter-popover">
                        <div className="row filter-popover-label">Country</div>
                        <div className="row">
                            {filterSelections.country.name !== "ALL" ? (
                                <Chip
                                    label={filterSelections.country.name}
                                    className={classes.chipStyle}
                                    onDelete={() => {
                                        setActiveFilter({
                                            ...filterSelections,
                                            country: {
                                                id: "ALL",
                                                name: "ALL",
                                            },
                                        });
                                        updatingSelections("country", {
                                            id: "ALL",
                                            name: "ALL",
                                        });
                                    }}
                                    deleteIcon={
                                        <i className="material-icons-outlined">
                                            clear
                                        </i>
                                    }
                                />
                            ) : (
                                <div className="no-selections">
                                    No Selections Available
                                </div>
                            )}
                        </div>
                        <div className="row filter-popover-label">Brand</div>
                        <div className="row">
                            {filterSelections.brand.name != "ALL" ? (
                                <Chip
                                    label={filterSelections.brand.name}
                                    className={classes.chipStyle}
                                    onDelete={() => {
                                        setActiveFilter({
                                            ...filterSelections,
                                            brand: {
                                                id: "ALL",
                                                name: "ALL",
                                            },
                                        });
                                        updatingSelections("brand", {
                                            id: "ALL",
                                            name: "ALL",
                                        });
                                    }}
                                    deleteIcon={
                                        <i className="material-icons-outlined">
                                            clear
                                        </i>
                                    }
                                />
                            ) : (
                                <div className="no-selections">
                                    No Selections Available
                                </div>
                            )}
                        </div>
                        <div className="row filter-popover-label">
                            Arche Type
                        </div>
                        <div className="row">
                            {filterSelections.archetype.name != "ALL" ? (
                                <Chip
                                    label={filterSelections.archetype.name}
                                    className={classes.chipStyle}
                                    onDelete={() => {
                                        setActiveFilter({
                                            ...filterSelections,
                                            archetype: {
                                                id: "ALL",
                                                name: "ALL",
                                            },
                                        });
                                        updatingSelections("archetype", {
                                            id: "ALL",
                                            name: "ALL",
                                        });
                                    }}
                                    deleteIcon={
                                        <i className="material-icons-outlined">
                                            clear
                                        </i>
                                    }
                                />
                            ) : (
                                <div className="no-selections">
                                    No Selections Available
                                </div>
                            )}
                        </div>
                    </div>
                </Popover>
            }
        </>
    );
}

DataAssetsFilters.propTypes = {
    match: PropTypes.object,
    user: PropTypes.object,
    country: PropTypes.array,
    brand: PropTypes.array,
    archetype: PropTypes.array,
};

const mapStateToProps = (state) => ({
    user: state.user,
    country: state.data.country,
    brand: state.data.brand,
    archetype: state.data.archetype,
    allData: state.data,
});

const mapDispatchToProps = {
    resetFilters,
    updateUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataAssetsFilters);
