import axios from "./axios";
import {
  FAV_APP_URL,
  GET_ALL_APPS,
  GET_MANAGE_APPS,
  GET_RECENT_APPS,
  GET_COUNTRY_BRANDS,
} from "./constants";

import { config } from "../config/config";

/* Users APIs */

export const getFavApps = () => axios(`${FAV_APP_URL}`, "GET");

export const getAllApps = () => axios(`${GET_ALL_APPS}`, "GET");

export const getManageApps = (data) =>
  axios(`${GET_MANAGE_APPS}`, "GET", data, null);

export const getRecentApps = (data) =>
  axios(`${GET_RECENT_APPS}`, "GET", data, null);

export const getAllCategorys = () => axios("/covidDashboard", "GET");

export const getCountryBrands = () => axios(`${GET_COUNTRY_BRANDS}`, "GET");

export const getRegisterAppTemplates = () =>
  axios(`${config.api.templateUrl}`, "GET");

export const updateReportViewDetail = (data) =>
  axios(`${config.api.reportDetailsUrl}`, "POST", null, data);

export const getAppDetail = (data) =>
  axios(`${config.api.appDetailsUrl}`, "GET", data, null);

export const getAppViewsAndComments = (data) =>
  axios(`${config.api.viewsAndCommentUrl}`, "GET", data, null);

export const updateTotalViews = (data) =>
  axios(`${config.api.accessHistoryUrl}`, "POST", data, null);

export const deleteComments = (data) =>
  axios(`${config.api.addCommentsUrl}`, "DELETE", data, null);

export const addComments = (data) =>
  axios(`${config.api.addCommentsUrl}`, "POST", null, data);

export const addLinks = (data) =>
  axios(`${config.api.addLikesUrl}`, "POST", null, data);

export const uploadImage = (data) =>
  axios(`${config.api.uploadImageUrl}`, "POST", null, data);

export const getUsersList = (data) =>
  axios(`${config.api.userListUrl}`, "GET", data, null);
export const getUsageReportUsers = (data) =>
  axios(`${config.api.usageReportUsersUrl}`, "GET", data, null);

export const publishapp = (data) =>
  axios(`${config.api.publishAppUrl}`, "PUT", null, data);

export const updateAccessRequest = (data) =>
  axios(`${config.api.accessUpdateUrl}`, "PUT", null, data);

export const sendmailApproveReject = (data) =>
  axios(`${config.api.emailForStatus}`, "POST", null, data);

export const sendmailRequest = (data) =>
  axios(`${config.api.emailForRequestApp}`, "POST", null, data);

export const sendmailToOpsRequest = (data) =>
  axios(`${config.api.sendMailToOpsUrl}`, "POST", null, data);

export const sendMailToUsersAccess = (data) =>
  axios(`${config.api.sendMailToUsersAccessUrl}`, "POST", null, data);

export const backendRepoUnique = (data) =>
  axios(`${config.api.backend_unique_url}`, "GET", data, null);

export const frontRepoUnique = (data) =>
  axios(`${config.api.fontend_unique_url}`, "GET", data, null);

export const getLayoutApps = (data) =>
  axios(`${config.api.get_Layout_Url}`, "GET", data, null);
export const getRecommendedApps = (data) =>
  axios(`${config.api.get_recommended_URL}`, "GET", data, null);
export const appUrlUnique = (data) =>
  axios(`${config.api.uniqueAppUrl}`, "GET", data, null);
export const apphandleUnique = (data) =>
  axios(`${config.api.apphandleUniqueUrl}`, "GET", data, null);
export const datasets_tags_update = (data) =>
  axios(`${config.api.updateTagsDatasetsUrl}`, "POST", null, data);
export const getUserAccessList = (data) =>
  axios(`${config.api.userAccessListUrl}`, "GET", data, null);

export const create_azure_group = (data) =>
  axios(`${config.api.createAzureGroupUrl}`, "POST", null, data);

export const update_azure_group_members = (data) =>
  axios(`${config.api.updateAzureGroupMembersUrl}`, "POST", null, data);

export const get_users_filters_data = (data) =>
  axios(`${config.api.userFiltersUrl}`, "GET", data, null);

// shashank's change
export const getTopUserApp = (data) =>
    axios(`${config.api.usageMetricsURL}`, "POST", null, data);

//signup screens
export const getSignupTemplates = () =>
  axios(`${config.api.signupTemplatesUrl}`, "GET", null, null);
export const update_job_role_others_options = (data) =>
  axios(`${config.api.jobRoleUrl}`, "POST", data, null);
export const update_functions_others_options = (data) =>
  axios(`${config.api.functionsUrl}`, "POST", data, null);
//update user info
export const update_user_data = () =>
  axios(`${config.api.authUrl2}`, "GET", null, null);

// updatehomelayout
export const update_home_layout = (data) =>
  axios(`${config.api.updateLayoutUrl}`, "POST", null, data);

// register a draft app
export const update_draft_apps = (data, req) =>
  axios(`${config.api.draft_app_url}`, req || "POST", null, data);

export const sync_access_data_in_env = (data) =>
  axios(`${config.api.sync_access_url}`, "POST", null, data);

// help center api's

export const fetchHelpCenterTemplates = (data) =>
  axios(`${config.api.help_center_temp_url}`, "GET", null, null);

export const fetchUpdateHelpCenterFeedback = (data) =>
  axios(`${config.api.feedbackUrl}`, "POST", null, data);

export const fetchAllAppList = () =>
  axios(`${config.api.allAppListUrl}`, "GET", null, null);

export const sendMailToOwners = (data, url) =>
  axios(`${url}`, "POST", null, data);

export const save_ticket_datials = (data) =>
  axios(`${config.api.saveTicketMetaUrl}`, "POST", null, data);

// app dashboard
export const save_position_meta = (data) =>
  axios(`${config.api.savePositionMetaUrl}`, "PUT", null, data);
