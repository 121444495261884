// Import required liraries
import React from "react";
import { Link } from "react-router-dom";

// Import styles
import "./SessionExpired.scss";

// Import Custom Component
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";

// Import data/utils
import { config } from "../../config/config";
import session_expire from "../../../assets/images/session_expire.svg";

import { updateUserInfo } from "../../redux/actions";

function SessionExpired() {
  const AuthenticateUser = () => {
    updateUserInfo({ isAuth: false });
    window.localStorage.removeItem(config.localStorageReduxStoreName);
    window.location.href = config.api.signInUrl;
  };
  return (
    <LayoutNone>
      <div className="se-container">
        <img
          style={{ width: "600px", marginBottom: "50px" }}
          src={session_expire}
          alt="Something went wrong"
        ></img>
        <h3>Session Expired</h3>
        <button onClick={AuthenticateUser} className="button-se">
          Login
        </button>
      </div>
    </LayoutNone>
  );
}

SessionExpired.propTypes = {};

export default SessionExpired;
