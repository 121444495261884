// Import Required Libraries
import React from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";

// Import Pages
import Login from "../screens/Login/Login";
import ErrorPage from "../screens/Error/ErrorPage";
import PageNotFound from "../screens/PageNotFound/PageNotFound";
import Explore from "../screens/Explore/Explore";
import ManageApps from "../screens/ManageApps/ManageApps";
import Help from "../screens/HelpCenter/HelpCenter";
import Faq from "../screens/Faq/Newfaq";
import Home from "../screens/Home/Home";
import AIDEDataAssets from "../screens/AIDEDataAssets/AIDEDataAssets";
import AIDEDataassetsIframe from "../screens/AIDEDataAssets/AIDEDataassetsIframe";
import RegisterNewApp from "../screens/NewRegister";
import Swagger from "../screens/Swagger";
import SessionExpired from "../screens/SessionExpired/SessionExpired";
import UnderMaintenance from "../screens/UnderMaintenance/UnderMaintenance";
import UserMangement from "../screens/UserMangement";
import AccountSetup from "../screens/AccountSetup";

// Import Custom Components
import ProtectedRoute from "./ProtectedRoute";
import NewTicket from "../screens/HelpCenter/NewTicket";
import AppDashboard from "../screens/AppDashboard";
import AllApps from "../screens/Explore/AllApps";
import { UpdateAppSkeleton } from "../screens/Register/components/Skeletons";

function Routes(props) {
  return (
    <Switch>
      <Redirect from="/" to="/myapps" exact />
      <ProtectedRoute path="/" component={ManageApps} exact />
      {/* <ProtectedRoute path="/docs" component={Swagger} exact /> */}
      <ProtectedRoute path="/register" component={RegisterNewApp} exact />
      {/* <ProtectedRoute
        path="/my-components"
        component={UpdateAppSkeleton}
        exact
      /> */}
      <ProtectedRoute
        path="/register/:appInfo"
        component={RegisterNewApp}
        exact
      />
      {/* <ProtectedRoute
        path="/register/draft/:draftId"
        component={RegisterNewApp}
        exact
      /> */}
      <ProtectedRoute
        path="/register/:appInfo/:tab"
        component={RegisterNewApp}
        exact
      />
      <ProtectedRoute path="/error" component={ErrorPage} exact />
      {/* <ProtectedRoute path="/account-setup" component={AccountSetup} exact /> */}
      {/* <ProtectedRoute
        path="/account-setup/:tab"
        component={AccountSetup}
        exact
      />
      <ProtectedRoute
        path="/data-assets_published"
        component={AIDEDataAssets}
        exact
      />
      <ProtectedRoute
        path="/profile-management"
        component={UserMangement}
        exact
      />
      <ProtectedRoute
        path="/profile-management/:tab"
        component={UserMangement}
        exact
      />
      <ProtectedRoute
        path="/data-assets_published/sigview/:datasetId"
        component={AIDEDataassetsIframe}
      /> */}
      <ProtectedRoute path="/myapps" component={ManageApps} exact />
      <ProtectedRoute path="/myapps/:tab" component={ManageApps} exact />
      {/* <ProtectedRoute path="/home" component={Home} exact />
      <ProtectedRoute path="/explore" component={Explore} exact />
      <ProtectedRoute path="/explore/:type/:value" component={AllApps} exact />
      <ProtectedRoute path="/faqs" component={Faq} exact />
      <ProtectedRoute path="/help/newticket" component={NewTicket} exact />
      <ProtectedRoute path="/help/:tab" component={Help} exact />
      <ProtectedRoute path="/help" component={Help} exact />
      <ProtectedRoute path="/pageNotFound" component={PageNotFound} exact /> */}
      <ProtectedRoute
        path="/under-maintenance"
        component={UnderMaintenance}
        exact
      />
      <Route path="/sessionExpired" component={SessionExpired} exact />
      <Route path="/login" component={Login} exact />
      {/* <ProtectedRoute path="/:appInfo" component={AppDashboard} exact /> */}
      <Redirect from="*" to="/explore" exact />
    </Switch>
  );
}

export default Routes;
