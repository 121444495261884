import React from "react";
import { Grid } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import SelectEmailComponent from "./SelectEmailComponent";
import AutoCompleteComponent from "../../../../components/AutoCompletes/AutoCompleteComponent";
import ChooseApplication from "./ChooseApplication";
import FormLabelComponent from "../../../../components/FormLabelComponent";
import AutoWithCheckbox from "../../../Register/components/AutoCompletesComponents/AutoWithCheckbox";
import { set_help_center_state } from "../../../../redux/actions";
import "../../style/style.scss";

const SelectComponent = ({
    keyField,
    value,
    options,
    handleChange,
    data,
    width,
    handleDelete,
    helpCenterState,
}) => {
    const { label, required, key, toolTipText, value_type } = data;
    const { formData } = helpCenterState;
    const dispatch = useDispatch();

    const handleSelect = (val) => {
        let reqData = [];
        if ((formData[key] || []).includes(val)) {
            const filteredData = formData[key].filter((item) => item !== val);
            reqData = { ...formData, [key]: filteredData };
        } else {
            if (val === "Global") {
                reqData = { ...formData, [key]: [val] };
            } else {
                if ((formData[key] || []).includes("Global")) {
                    const filteredData = formData[key].filter(
                        (item) => item !== "Global"
                    );
                    reqData = { ...formData, [key]: [...filteredData, val] };
                } else {
                    reqData = {
                        ...formData,
                        [key]: [...(formData[key] || []), val],
                    };
                }
            }
        }
        dispatch(set_help_center_state({ formData: reqData }));
    };
    const handleCustomChange = (k, v) => {
        if (k === "issue_type" || k === "choose_application") {
            const val = v.value;
            handleChange(k, val);
        } else {
            handleChange(k, v);
        }
    };

    return (
        <>
            {key === "choose_application" ? (
                <ChooseApplication
                    label={label}
                    handleChange={handleChange}
                    options={options}
                    keyField={keyField}
                    value={value}
                    data={data}
                />
            ) : (
                <Grid item xs={width || 4}>
                    <FormLabelComponent
                        required={required}
                        label={label}
                        toolTipText={toolTipText}
                    />
                    {value_type === "multiple" &&
                    key === "email_for_communication" ? (
                        <SelectEmailComponent
                            handleChange={handleChange}
                            values={value}
                            options={options}
                            handleDelete={handleDelete}
                            fieldkey={key}
                        />
                    ) : value_type === "multiple" ? (
                        <AutoWithCheckbox
                            fieldkey={key}
                            selectChange={handleSelect}
                            selectedOptions={value || []}
                            options={options}
                            label={label}
                        />
                    ) : (
                        <AutoCompleteComponent
                            label={label}
                            handleChange={handleCustomChange}
                            options={options}
                            keyField={key}
                            value={value}
                        />
                    )}
                </Grid>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    helpCenterState: state.helpCenter.helpCenterState,
});
export default connect(mapStateToProps)(SelectComponent);
