import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import {
  updateAppDashboardState,
  update_statusData,
  updated_total_views,
} from "../../redux/actions";
import { config } from "../../config/config";
import RequestAccessModal from "../RequestAccessModal/RequestAccessModal";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    color:
      theme.palette.mode === "dark"
        ? theme.palette.white.main
        : theme.palette.font.color.secondary,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.light : "white",
    borderRadius: "10px",
  },
  tableRowCell: {
    fontFamily: theme.palette.font.fontFamily,
    fontSize: "12px !important",
    cursor: "pointer !important",
    textTransform: "capitalize",
    lineHeight: "0 !important",
    color:
      theme.palette.mode === "light"
        ? theme.palette.font.color.secondary
        : "white",
  },
  tableHeadCell: {
    fontFamily: theme.palette.font.fontFamily,
    fontSize: "13px !important",
    fontWeight: "700 !important",
    color: theme.palette.font.color.secondary,
    backgroundColor: "#E6E9EE !important",
  },
  tableRow: {
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.dark.main : "#FBEDF2",
      color:
        theme.palette.mode === "dark"
          ? theme.palette.white.main
          : theme.palette.font.color.secondary,
    },
  },
  pagination: {
    "& .MuiTablePagination-selectLabel": {
      fontFamily: theme.palette.font.fontFamily,
      fontSize: "12px",
    },
    "& .MuiTablePagination-select": {
      fontFamily: theme.palette.font.fontFamily,
      fontSize: "12px",
    },
    "& .MuiTablePagination-displayedRows": {
      fontFamily: theme.palette.font.fontFamily,
      fontSize: "12px",
    },
    "& .MuiTablePagination-actions": {
      "& .MuiSvgIcon-root": {
        fontSize: "0.9rem !important",
      },
    },
  },
}));

function AppTable(props) {
  const { tableData = [], user } = props;
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [openRA, setOpenRA] = React.useState(false);
  const [reqState, setReqState] = React.useState("pending");
  const [requestedApp, setRequestedApp] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleCloseRAcard = () => {
    setOpenRA(false);
  };
  const dispatch = useDispatch();
  const columns = [
    { id: "app_name", label: "Application Name", minWidth: 100 },
    { id: "app_category", label: "Application Category", minWidth: 100 },
    { id: "app_type", label: "Application Type", minWidth: 100 },
    { id: "created_by", label: "Created By", minWidth: 100 },
    { id: "last_modified_on", label: "Last Modified On", minWidth: 100 },
  ];

  function createData(tableData) {
    const result = tableData.map((app) => {
      const epochTime = app.last_modified_meta.last_modified_on;
      const time = moment(epochTime).format("MMM-DD-YYYY H:mm:ss");
      const appData = {
        app_name: app.app_meta.app_name,
        app_category: app.app_details.app_category,
        created_by: app.app_meta.created_by,
        last_modified_on: time,
        app_type:
          app.app_meta.app_type === "ml_app"
            ? "ML Web App"
            : app.app_meta.app_type === "bi_app"
            ? "Embedded Analytics"
            : "Standalone App",
        path: app.app_meta.app_path,
      };
      return appData;
    });
    return result;
  }
  const rows = createData(tableData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const ChangeRoute = (row) => {
    if (window.location.pathname.split("/")[1] === "myapps") {
      history.push("/register" + row.path + "/details");
    } else {
      history.push(row.path);
    }
  };

  const handleOpenInNewTab = (row) => {
    dispatch(
      updated_total_views({
        app_id: row.app_id,
      })
    );
    if (
      row.app_meta.app_type === "ml_app" &&
      !row.app_meta.app_url.startsWith("https://")
    ) {
      window.open(
        "https://" + row.app_meta.app_url,
        row.app_meta.app_window_Name
      );
    } else {
      window.open(row.app_meta.app_url, row.app_meta.app_window_Name);
    }
  };

  const handleNavigate = (path) => {
    history.push(path);
  };

  function hasViewAccess(person) {
    return user.preferred_username === person.user_id;
  }
  const HandleOpenApp = (row) => {
    dispatch(
      updateAppDashboardState({
        search_columns: [],
        filteredReportsList: [],
        loadingFilter: "pending",
        searchVal: "",
        filterOptions: config.initailOptions,
        filterList: config.initailFilters,
        appData: {},
        appDataStatus: "pending",
      })
    );
    const appObj = tableData.filter(
      (app) => app.app_meta.app_path === row.path
    )[0];
    const accessableUser = appObj.access_details
      ? appObj.access_details?.users_list?.filter(hasViewAccess)
      : [];
    const accessStatus = accessableUser?.length ? true : false;
    if (!(window.location.pathname.split("/")[1] === "myapps")) {
      if (
        accessStatus ||
        appObj.access_details.access_type === "public" ||
        user.role === "super_admin"
      ) {
        if (
          appObj.app_meta.app_type === "ml_app" ||
          appObj.app_meta.app_type === "standalone_app"
        ) {
          handleOpenInNewTab(appObj);
        } else if (appObj.app_meta.app_type === "bi_app") {
          handleNavigate(appObj.app_meta.app_path);
        }
      } else if (!accessStatus) {
        setOpenRA(true);
        setRequestedApp(appObj);
      }
    } else if (window.location.pathname.split("/")[1] === "myapps") {
      if (appObj.approval_meta.approval_status === "draft") {
        handleNavigate("/register/draft" + appObj.app_meta.app_path);
      } else {
        handleNavigate(
          "/register" + appObj.app_meta.app_path + "/access-management"
        );
      }
    }
  };

  const classes = useStyles();

  return (
    <>
      <Paper
        class={classes.tableContainer}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer sx={{ maxHeight: 585 }}>
          <Table stickyHeader="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className={classes.tableHeadCell}
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      onClick={() => {
                        HandleOpenApp(row);
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        if (column.id === "app_name") {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={classes.tableRowCell}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <p>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </p>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={classes.tableRowCell}
                            >
                              <p>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </p>
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{
            fontFamily: "Energy !important ",
          }}
          sx={{
            fontFamily: "Energy !important ",
          }}
          className={classes.pagination}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          labelRowsPerPage="Apps per page :"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {openRA && (
        <RequestAccessModal
          openRA={openRA}
          handleClose={handleCloseRAcard}
          appDetails={requestedApp}
          setOpenRA={setOpenRA}
          reqState={reqState}
          setReqState={setReqState}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AppTable);
