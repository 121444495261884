import C from "../constants";

export default function userAccessManagement(state = {}, payload) {
    let { type, data } = payload;
    switch (type) {
        case C.GET_USER_ACCESS_LIST:
            return {
                ...state,
                user_access_list: {
                    status: "loading",
                    message: "",
                    data: [],
                },
            };
        case C.USER_ACCESS_LIST_RECEIVED:
            return {
                ...state,
                user_access_list: {
                    status: "success",
                    message: "",
                    data,
                },
            };
        case C.REFRESH_USER_ACCESS_LIST:
            return {
                ...state,
                refreshed_user_access_list: {
                    status: "loading",
                    message: "",
                    data: [],
                },
            };
        case C.USER_ACCESS_LIST_REFRESHED: {
            return {
                ...state,
                refreshed_user_access_list: {
                    status: "success",
                    message: "",
                    data,
                },
            };
        }
        case C.CLEAN_USER_ACCESS_LIST:
            return {
                ...state,
                user_access_list: {
                    status: "pending",
                    message: "",
                    data: [],
                },
                refreshed_user_access_list: {
                    status: "pending",
                    message: "",
                    data: [],
                },
            };
        default:
            return state;
    }
}
