// Import required liraries
import React from "react";
import { Link } from "react-router-dom";

// Import styles
import "./PageNotFound.scss";

// Import Custom Component
import LayoutNone from "../../layouts/LayoutNone/LayoutNone";
import LayoutTopBottom from "../../layouts/LayoutTopBottom/LayoutTopBottom";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
// Import data/utils
import { config } from "../../config/config";
import something_went_wrong from "../../../assets/images/something-went-wrong.svg";

function PageNotFound() {
  return (
    <LayoutTopSideBottom>
      <div className="pnf-container">
        <img
          style={{ width: "600px", marginBottom: "50px" }}
          src={something_went_wrong}
          alt="Something went wrong"
        ></img>
        <h3>Could not find what you were looking for!</h3>
        <Link to={config.hardCoded.homeLocation}>
          <button className="button-pnf">Click here to explore Apps</button>
        </Link>
      </div>
    </LayoutTopSideBottom>
  );
}

PageNotFound.propTypes = {};

export default PageNotFound;
