// Import required libraries
import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Import custom components

// Import redux
import { setCurrentTab, updateUserInfo } from "../../redux/actions";

// Import utils
import { UpdatePageView } from "../../utils/utils";

// Import Hooks
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

// Import styles
import "./UserInfo.scss";

// Import config
import { config } from "../../config/config";

function fetchAPI(payload) {
  const url = "/api/themeupdate";
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

function UserInfo(props) {
  let { user, theme } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [newTheme, setNewTheme] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const HandleTheme = async () => {
    const val = user.ui_preferences.theme;
    const newVal = val === "dark" ? "light" : "dark";
    const gaEventTracker = useAnalyticsEventTracker("ChangeTheme");
    gaEventTracker(newVal, "");
    const body = {
      user_id: user.preferred_username,
      ui_preferences: {
        grid_size: user.ui_preferences.grid_size,
        theme: newVal,
      },
    };
    dispatch(
      updateUserInfo({
        ui_preferences: {
          grid_size: user.ui_preferences.grid_size,
          theme: newVal,
        },
      })
    );
    setNewTheme(newVal);
    try {
      const res = await fetchAPI(body);
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200") {
      } else {
        dispatch(
          updateUserInfo({
            ui_preferences: {
              grid_size: user.ui_preferences.grid_size,
              theme: val,
            },
          })
        );
        setNewTheme(val);
        toast.error("Theme Request Failed.");
      }
    } catch (error) {
      dispatch(
        updateUserInfo({
          ui_preferences: {
            grid_size: user.ui_preferences.grid_size,
            theme: val,
          },
        })
      );
      setNewTheme(val);
      toast.error("Theme Request Failed.");
    }
  };

  const open = Boolean(anchorEl);

  const logout = () => {
    window.location.href = config.api.signOutUrl;
    window.localStorage.removeItem(config.localStorageReduxStoreName);
  };

  return (
    <div className="user-info-container" title="User Info">
      {/* <i
                className="material-icons-outlined header-menu-icon"
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                {" "}
                person{" "}
            </i> */}
      <div
        className={`profile-image-container`}
        onClick={handleClick}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
      >
        {user.profile_meta?.url !== "" ? (
          <img
            src={user.profile_meta?.url}
            className={`alphabet-picture`}
            alt=""
            style={{ marginRight: "20px" }}
          />
        ) : (
          <div
            className={`alphabet-picture`}
            style={{
              background: user.profile_meta?.background_color,
              marginRight: "20px",
            }}
          >
            <p className={`name-initials`}>{user.profile_meta?.initials}</p>
          </div>
        )}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: { boxShadow: "4px 4px 12px 4px #434343" },
        }}
      >
        <div className="user-info-name" style={{ cursor: "default" }}>
          <div className="user-info-profile">
            <div
              className={`profile-image-container`}
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
            >
              {user.profile_meta?.url !== "" ? (
                <img
                  src={user.profile_meta?.url}
                  className={`alphabet-picture`}
                  alt=""
                  style={{
                    marginRight: "10px",
                    cursor: "default",
                  }}
                />
              ) : (
                <div
                  className={`alphabet-picture`}
                  style={{
                    background: user.profile_meta?.background_color,
                    marginRight: "10px",
                    cursor: "default",
                  }}
                >
                  <p className={`name-initials`}>
                    {user.profile_meta?.initials}
                  </p>
                </div>
              )}
            </div>
            <div>
              <div className="user-info-text">
                {user.name.replace("(Contractor)", "")}
              </div>
              <div style={{ fontSize: "12px" }}>{user.preferred_username}</div>
            </div>
          </div>
        </div>
        {/* {user.user_type === "EXISTING" && (
          <div
            className={`user-info-name ${theme}-user-info-hover GA-manage-profile`}
            onClick={() => {
              UpdatePageView("profileManagement");
              history.push("/profile-management");
            }}
          >
            <p className="user-info-text GA-manage-profile">Manage Profile</p>
          </div>
        )} */}
        <div
          className={`user-info-name ${theme}-user-info-hover GA-theme-changer`}
          onClick={HandleTheme}
        >
          <p className="user-info-text GA-theme-changer">
            {user.ui_preferences.theme} Mode
          </p>
          <span
            class={`material-icons-outlined GA-theme-changer rotated-toggle-theme`}
          >
            {user.ui_preferences.theme === "dark" ? "dark_mode" : "light_mode"}
          </span>
          {/* <p className="user-info-sub-text">Shraddha.Yadav@rb.com</p> */}
        </div>
        <div className={`user-info-sign-out ${theme}-user-info-hover`}>
          <p
            className="user-info-sign-out-text"
            onClick={() => {
              logout();
            }}
          >
            Sign Out
          </p>
        </div>
      </Popover>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
  theme: state.user.ui_preferences?.theme,
});
const mapDispatchToProps = { updateUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
