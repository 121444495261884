import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";

import InputFiled from "../Components/InputField";
import SelectComponent from "../Components/SelectComponents";
import RadioBtns from "../../Register/components/RadioBtns";
import UrlBtn from "../Components/UrlBtn";
import NotificationModal from "../../../components/AlertMsg/NotificationModal";
import Loader from "../../../components/Loader/Loader";
import CheckBoxComponent from "../Components/CheckBoxComponent";
import CreateFooterContainer from "../Components/CreateFooterContainer.jsx";
import CreateRegisterBodyContainer from "../Components/CreateRegisterBodyContainer.jsx";

import {
  updateAppDetails,
  update_statusData,
  update_appData,
  update_BIappDetails,
  update_activeStepper,
} from "../../../redux/actions";
import { config } from "../../../config/config";
//hooks
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
//styles
import "../index.scss";
import { isEqualObject } from "../../../utils/utils";

function fetchAPI(payload) {
  const url = "/api/detailsupdate";
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const ApplicationDetails = (props) => {
  const {
    templates,
    appFields,
    // setActiveStepper,
    isFieldsEditable,
    isChanged,
    isAdminRegistering,
    waiting,
    isAppUnique,
    validationMsg,
    appDetails,
    register_app_template,
    handleSaveTagsDatasets,
    visited,
    theme,
    draft_app,
    handleDraftSubmit,
    draft_app_status,
    dirty,
    ml_app_url_status,
    app_url_uniqueness,
    frontend_repo_uniqueness,
    backend_repo_uniqueness,
    configDetails,
    isChangesSubmitable,
    isDetailsSubmitable,
    getSubmitableData,
    categoryInfo,
    categoryInfoLocal,
    activeStepper,
    template_val,
    templateData,
    template_no,
  } = props;
  const { appInfo } = useParams();
  const dispatch = useDispatch();
  const eventTracker = useAnalyticsEventTracker(
    "Details",
    appDetails.data.app_id
  );
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState(false);
  const [missingValues, setMissingValues] = useState({});

  let app_id;
  if (appInfo) {
    const data = appInfo.split("_");
    app_id = data[0];
  }
  const handleClose = () => setAlert(false);

  const isSaveBtnDisabled = () => {
    return (
      waiting ||
      loading ||
      !isDetailsSubmitable ||
      !appFields.app_details?.function?.length ||
      !appFields.app_details?.line_of_business.length ||
      !appFields.app_name ||
      !appFields.app_details?.description ||
      appFields.app_details?.description?.length > 500 ||
      !(
        isAppUnique || appDetails.data.app_meta?.app_name === appFields.app_name
      )
    );
  };

  const getAppType = (appType) => {
    if (appType === "standalone_app") {
      return "Standalone Application";
    } else if (appType === "ml_app") {
      return "ML Application";
    } else {
      return "Embedded Analytics";
    }
  };
  const getAppDescription = (appType) => {
    const reqFields = register_app_template.data.register_app_template?.fields;
    if (reqFields.length) {
      const reqData = reqFields[0].options.find((item) => item.key === appType);
      return reqData?.description || "";
    }
  };

  const handleChange = (key, value, blob_name, app_logo_type) => {
    if (missingValues[key]) {
      setMissingValues({ ...missingValues, [key]: false });
    }
    //run for app_logo and app_name to update app meta else update app_details
    if (key === "app_logo" || key === "app_name") {
      //run for app_logo
      if (app_id || isAdminRegistering || draft_app) {
        // setting values for comparison if app is in updating state
        // setIsChanged({
        //   ...isChanged,
        //   app_meta: { ...isChanged.app_meta, [key]: value },
        // });
        dispatch(
          update_appData({
            isChanged: {
              ...isChanged,
              app_meta: { ...isChanged.app_meta, [key]: value },
            },
          })
        );
      }
      if (blob_name || app_logo_type) {
        // setAppFields({
        //   ...appFields,
        //   [key]: value,
        //   blob_name,
        //   app_logo_type,
        // });
        dispatch(
          update_appData({
            appFields: {
              ...appFields,
              [key]: value,
              blob_name,
              app_logo_type,
            },
          })
        );
        if (app_id || isAdminRegistering || draft_app) {
          // setting values for comparison if app is in updating state
          // setIsChanged({
          //   ...isChanged,
          //   app_meta: {
          //     ...isChanged.app_meta,
          //     [key]: value,
          //     blob_name,
          //     app_logo_type,
          //   },
          // });
          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                app_meta: {
                  ...isChanged.app_meta,
                  [key]: value,
                  blob_name,
                  app_logo_type,
                },
              },
            })
          );
        }
      } else {
        //run for app_name and also update app_handle if registering
        if (key === "app_name" && visited.configurations) {
          // setVisited({ ...visited, configurations: false });
          dispatch(
            update_appData({ visited: { ...visited, configurations: false } })
          );
        }
        // updating the app_handle value everytime when app name changes while registering but not updating
        let app_details = appFields.app_details;
        if (!(isAdminRegistering || appInfo) && key === "app_name") {
          const app_handle = value?.trim()?.toLowerCase()?.replaceAll(" ", "_");
          app_details = { ...app_details, app_handle };
        }
        // setAppFields({
        //   ...appFields,
        //   [key]: value,
        //   app_details,
        // });
        dispatch(
          update_appData({
            appFields: {
              ...appFields,
              [key]: value,
              app_details,
            },
          })
        );

        if (app_id || isAdminRegistering || draft_app) {
          // setting values for comparison if app is in updating state
          // setIsChanged({
          //   ...isChanged,
          //   app_meta: { ...isChanged.app_meta, [key]: value },
          // });
          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                app_meta: { ...isChanged.app_meta, [key]: value },
              },
            })
          );
        }
      }
    } else {
      if (app_id || isAdminRegistering || draft_app) {
        // setting values for comparison if app is in updating state
        // setIsChanged({
        //   ...isChanged,
        //   app_details: { ...isChanged.app_details, [key]: value },
        // });
        dispatch(
          update_appData({
            isChanged: {
              ...isChanged,
              app_details: { ...isChanged.app_details, [key]: value },
            },
          })
        );
      }
      // setAppFields({
      //   ...appFields,
      //   app_details: { ...appFields.app_details, [key]: value },
      // });
      dispatch(
        update_appData({
          appFields: {
            ...appFields,
            app_details: { ...appFields.app_details, [key]: value },
          },
        })
      );
    }
    // checking if user is registering but didn't save changes and trying to navigate to other page.
    if (!appInfo && !isAdminRegistering && !dirty) {
      // setDirty(true);
      dispatch(update_statusData({ dirty: true }));
    }
  };

  const isNextBtnDisabled = () => {
    const reqVal =
      !(
        appFields.app_details["description"] &&
        appFields.app_name &&
        (appFields.app_details["description"] || "").length <= 500 &&
        appFields.app_details?.function?.length &&
        appFields.app_details?.line_of_business?.length
      ) ||
      waiting ||
      !(
        isAppUnique ||
        ((appInfo || draft_app || isAdminRegistering) &&
          appDetails.data.app_meta?.app_name === appFields.app_name)
      );
    return reqVal;
  };
  const isStandaloneDraftConfigValid = () => {
    const rslt =
      app_url_uniqueness.status === "success" ||
      appFields.app_url === appDetails.data.app_meta?.app_url;
    return rslt;
  };
  const isMLDraftconfigValid = () => {
    const reqData = appDetails.data.provisioning_details;
    const be_repo_name = reqData?.backend_repository_name?.slice(4, -13);
    const fe_repo_name = reqData?.frontend_repository_name?.slice(4, -13);
    const rslt =
      (ml_app_url_status.unique ||
        (draft_app &&
          `https://${appFields.app_url}-prd${config.hardCoded.appUrl}` ===
            appDetails.data.app_meta?.app_url)) &&
      (backend_repo_uniqueness.status === "success" ||
        (draft_app && configDetails.be_repo_name === be_repo_name)) &&
      (frontend_repo_uniqueness.status === "success" ||
        (draft_app && configDetails?.fe_repo_name === fe_repo_name));

    return rslt;
  };
  const isAllStatesAreValid = () => {
    const rslt =
      !appFields.app_url ||
      (appFields.app_url &&
        ((appFields.app_type === "ml_app" && isMLDraftconfigValid()) ||
          (appFields.app_type === "standalone_app" &&
            isStandaloneDraftConfigValid())));
    return !rslt;
  };

  const handleNext = async () => {
    // const data = {
    //     associated_datasets:
    //         appFields.app_details?.associated_datasets || [],
    //     tags: appFields.app_details?.tags || [],
    // };
    // dispatch(updateTagsDatasets(data));
    if (isNextBtnDisabled()) {
      let missingData = {};
      templates.fields?.forEach((item) => {
        if (
          item.required &&
          item.key !== "app_name" &&
          item.key !== "app_logo" &&
          !appFields.app_details[item.key]?.length
        ) {
          missingData = { ...missingData, [item.key]: true };
        } else if (item.key === "app_name" && !appFields.app_name) {
          missingData = { ...missingData, [item.key]: true };
        }
      });
      setMissingValues(missingData);
    } else {
      // setActiveStepper((prev) => prev + 1);
      dispatch(update_activeStepper(activeStepper + 1));

      // eventTracker("Next btn clicked", "clicked");
    }
  };
  const handleBack = () => {
    // eventTracker("Back btn clicked", "clicked");
    // setActiveStepper((prev) => prev - 1);
    dispatch(update_activeStepper(activeStepper - 1));
  };
  const handleDraftFunction = () => {
    if (appFields.app_type === "bi_app") {
      if (template_no === 1) {
        const { infoState, localState } = getSubmitableData(
          categoryInfo,
          categoryInfoLocal
        );
        // setCategoryInfo(infoState);
        // setCategoryInfoLocal(localState);
        dispatch(update_BIappDetails({ categoryInfo: infoState }));
        dispatch(update_BIappDetails({ categoryInfoLocal: localState }));
        handleDraftSubmit("Categories", infoState);
      } else {
        const draft_data = template_no > 5 ? categoryInfoLocal : templateData;
        handleDraftSubmit("Categories", draft_data);
      }
    } else {
      handleDraftSubmit("Details");
    }
  };

  const getRequiredComponentDetails = (data) => {
    const { type, key } = data;
    switch (type) {
      case "input":
        return (
          <InputFiled
            data={data}
            handleChange={handleChange}
            value={
              key === "app_name"
                ? appFields?.app_name
                : appFields?.app_details[key] || ""
            }
            width={key === "description" ? 11 : 5}
            isFieldsEditable={isFieldsEditable}
            waiting={waiting}
            isAppUnique={isAppUnique}
            validationMsg={validationMsg}
            hasError={missingValues[key] || false}
            theme={theme}
          />
        );
      case "dropdown":
        return (
          <SelectComponent
            data={data}
            handleChange={handleChange}
            value={appFields?.app_details[key]}
            width={key === "geography" ? 11 : 5}
            isFieldsEditable={isFieldsEditable}
            appInfo={appInfo}
            hasError={missingValues[key] || false}
            missingValues={missingValues}
            setMissingValues={setMissingValues}
            theme={theme}
          />
        );
      default:
        return "";
    }
  };
  const getRequiredComponentIcon = (data) => {
    const { type, key } = data;
    switch (type) {
      case "url":
        return (
          <UrlBtn
            data={data}
            handleChange={handleChange}
            value={appFields[key]}
            width={3}
            app_logo_type={appFields.app_logo_type}
            url={appFields?.app_logo_url}
            isFieldsEditable={isFieldsEditable}
            theme={theme}
            upload_for="App_logo"
          />
        );
      default:
        return "";
    }
  };
  const getRequiredComponentCheckbox = (data) => {
    const { type, key } = data;
    switch (type) {
      case "radio":
        return (
          <RadioBtns
            data={data}
            handleChange={handleChange}
            value={appFields?.app_details[key]}
            isFieldsEditable={isFieldsEditable}
            theme={theme}
          />
        );
      case "checkbox":
        return (
          <CheckBoxComponent
            data={data}
            handleChange={handleChange}
            value={appFields?.app_details[key]}
            width={3}
            isFieldsEditable={isFieldsEditable}
            theme={theme}
          />
        );
      default:
        return "";
    }
  };
  const handleSaveDetails = async () => {
    let data = {};
    const { app_meta, approval_meta, app_details } = appDetails.data;
    eventTracker("Save details clicked", "save");
    data = {
      app_meta: { ...app_meta, ...(isChanged.app_meta || {}) },
      app_details: { ...app_details, ...(isChanged.app_details || {}) },
      app_id: app_id || appDetails.data.app_id,
      approval_meta: approval_meta,
    };

    try {
      setLoading(true);
      const res = await fetchAPI(data);
      const reqres = await res.json();
      if (reqres.status.statusCode === "datareckitt-200") {
        handleSaveTagsDatasets();
        setMessage(reqres.status.statusMessage);
        setAlert(true);
        // setIsChanged({
        //   app_meta: {},
        //   app_details: {},
        //   provisioning_details: {},
        // });
        dispatch(
          update_appData({
            isChanged: {
              app_meta: {},
              app_details: {},
              provisioning_details: {},
            },
          })
        );
        dispatch(
          updateAppDetails(
            {
              type: "app_id",
              app_id: app_id || appDetails.data.app_id,
              approval_status: appDetails.data.approval_meta?.approval_status,
            },
            isAdminRegistering ? "admin" : ""
          )
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error("Oops! unable to save changes");
      setLoading(false);
    }
  };

  const isDraftButtonDisabled = () => {
    if (appFields.app_type !== "bi_app") {
      if (
        draft_app_status.status === "loading" ||
        isNextBtnDisabled() ||
        isAllStatesAreValid() ||
        (draft_app && !isDetailsSubmitable)
      ) {
        console.log("ml_app")
        return true;
      }
    } else  {
      if (template_val === undefined) {
        if (
          draft_app_status.status === "loading" ||
          isNextBtnDisabled() ||
          isAllStatesAreValid() ||
          (draft_app && !isDetailsSubmitable)
        ) {
          return true;
        }
      }
      if (template_val > 5) {
        if (
          draft_app_status.status === "loading" ||
          isNextBtnDisabled() ||
          isAllStatesAreValid() ||
          (draft_app &&
            !isDetailsSubmitable &&
            (JSON.stringify(templateData) !== JSON.stringify({})
              ? template_no === template_val &&
                isEqualObject(categoryInfoLocal, categoryInfo)
              : true))
        ) {
          return true;
        }
      } else if (template_val <= 5) {
        if (
          draft_app_status.status === "loading" ||
          isNextBtnDisabled() ||
          isAllStatesAreValid() ||
          (draft_app &&
            !isDetailsSubmitable &&
            (JSON.stringify(templateData) !== JSON.stringify({})
              ? template_no === template_val &&
                isEqualObject(categoryInfoLocal, templateData)
              : true))
        ) {
          return true;
        }
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <NotificationModal
        message={message}
        open={alert}
        handleClose={handleClose}
      />

      <CreateRegisterBodyContainer
        height={appInfo || isAdminRegistering ? "315px" : ""}
      >
        <div className="appDetails-container">
          {/* {(appInfo || isAdminRegistering) &&
            appDetails.status === "success" && (
              <Box width={"70%"}>
                <div className="sub-title">
                  {getAppType(appDetails.data.app_meta?.app_type)}
                </div>
                <div
                  className={`description`}
                  style={{
                    color: theme === "dark" ? "#8d9ca6" : "currentColor",
                  }}
                >
                  {getAppDescription(appDetails.data.app_meta?.app_type) || ""}
                </div>
              </Box>
            )} */}
          <Box className="IconAndCheckbox-container">
            {/* <Grid container spacing={1} sx={{ my: 2 }}> */}
            {templates &&
              templates.fields.map((item) => (
                <React.Fragment key={item.key}>
                  {getRequiredComponentIcon(item)}
                </React.Fragment>
              ))}

            <div className="Checkbox-container">
              {templates &&
                templates.fields.map((item) => (
                  <React.Fragment key={item.key}>
                    {getRequiredComponentCheckbox(item)}
                  </React.Fragment>
                ))}
            </div>
          </Box>
          <div className="seperator-line"></div>
          <Box className="DetailFields-container">
            <Grid className="DetailFields-container-grid" container>
              {templates &&
                templates.fields.map((item) => (
                  <React.Fragment key={item.key}>
                    {getRequiredComponentDetails(item)}
                  </React.Fragment>
                ))}
            </Grid>
          </Box>
        </div>
      </CreateRegisterBodyContainer>
      <CreateFooterContainer sx={{ position: "relative", top: "100px" }}>
        {!isFieldsEditable &&
          (appInfo || isAdminRegistering) &&
          appDetails.status === "success" && (
            <button
              className="btn btn-next"
              onClick={handleSaveDetails}
              disabled={isSaveBtnDisabled()}
              id="GA-save-details"
            >
              {loading ? (
                <Loader color="#ffffff" margin="0px 8px" />
              ) : (
                "Save Details"
              )}
            </button>
          )}
        {(!(appInfo || isAdminRegistering || draft_app) ||
          (draft_app && appDetails.status === "success")) && (
          <>
            <button
              className="btn-draft"
              onClick={handleDraftFunction}
              disabled={isDraftButtonDisabled()}
            >
              {draft_app_status.status === "loading" ? (
                <Loader color="#ffffff" />
              ) : draft_app !== undefined &&
                draft_app.includes("active_draft") ? (
                "Update draft"
              ) : (
                "Save as draft"
              )}
            </button>
            {!draft_app && (
              <button
                className={`btn-back btn-back-${theme}`}
                onClick={handleBack}
                disabled={draft_app}
                style={{
                  color: theme === "light" ? "#000" : "#fff",
                }}
              >
                Previous
              </button>
            )}
            <button
              className={`btn btn-next ${
                isNextBtnDisabled() ? "btn-disabled" : ""
              }`}
              onClick={handleNext}
              disabled={draft_app_status.status === "loading"}
            >
              Next
            </button>
          </>
        )}
      </CreateFooterContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeStepper: state.registerNewApp.activeStepper,
  // MLappDetails
  configDetails: state.registerNewApp.MLappDetails.configDetails,
  // BIAppDetails
  templateData: state.appTemplate.appTemplateState.templateData,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  // statusData
  isChangesSubmitable: state.registerNewApp.statusData.isChangesSubmitable,
  isDetailsSubmitable: state.registerNewApp.statusData.isDetailsSubmitable,
  dirty: state.registerNewApp.statusData.dirty,
  waiting: state.registerNewApp.statusData.waiting,
  isAppUnique: state.registerNewApp.statusData.isAppUnique,
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  // appData
  isChanged: state.registerNewApp.appData.isChanged,
  appFields: state.registerNewApp.appData.appFields,
  visited: state.registerNewApp.appData.visited,

  theme: state.user.ui_preferences?.theme,
  appDetails: state.AppsData.appDetails,
  register_app_template: state.register.registerTemplate,
  draft_app_status: state.register.register_draft_app,
  ml_app_url_status: state.register.ml_app_url_status,
  app_url_uniqueness: state.register.app_url_uniqueness,
  backend_repo_uniqueness: state.register.backend_repo_uniqueness,
  frontend_repo_uniqueness: state.register.frontend_repo_uniqueness,
  template_val:
    state.registerNewApp.appData.appFields.app_details?.chosen_template,
});

export default connect(mapStateToProps)(ApplicationDetails);
