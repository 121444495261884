import { Box, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import { update_BIappDetails } from "../../../../redux/actions";
import "../../index.scss";
const CategoryCard = ({
  sub_category_name = "",
  sub_category_id,
  categoryInfo,
  // setCategoryInfo,
  isFieldsEditable,
  theme,
  appDetails,
  setIsFieldCardRed,
  // setCategoryInfoLocal,
  categoryInfoLocal,
  catIndex,
  setCatData,
}) => {
  const eventTracker = useAnalyticsEventTracker(
    "Categories",
    appDetails.data.app_id
  );
  const dispatch = useDispatch();
  const marginLeft = "0px";
  const borderColor = "#465a69";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event) => {
    if (isFieldsEditable) return;
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    // setCategoryInfo([
    //   ...categoryInfo.slice(0, catIndex),
    //   ...categoryInfo.slice(catIndex + 1),
    // ]);
    // setCategoryInfoLocal([
    //   ...categoryInfoLocal.slice(0, catIndex),
    //   ...categoryInfoLocal.slice(catIndex + 1),
    // ]);

    dispatch(
      update_BIappDetails({
        categoryInfo: [
          ...categoryInfo.slice(0, catIndex),
          ...categoryInfo.slice(catIndex + 1),
        ],
        categoryInfoLocal: [
          ...categoryInfoLocal.slice(0, catIndex),
          ...categoryInfoLocal.slice(catIndex + 1),
        ],
      })
    );

    eventTracker("Delete btn clicked", "category deleted");
  };

  const handleEdit = () => {
    setIsFieldCardRed(false);
    const reqData = {
      isCatEditable: true,
    };
    // setCategoryInfo(setCatData(categoryInfo, catIndex, reqData));
    // setCategoryInfoLocal(setCatData(categoryInfoLocal, catIndex, reqData));
    dispatch(
      update_BIappDetails({
        categoryInfo: setCatData(categoryInfo, catIndex, reqData),
        categoryInfoLocal: setCatData(categoryInfoLocal, catIndex, reqData),
      })
    );

    eventTracker("Edit btn clicked", "edit category");
  };

  return (
    <div
      className={`category-card ${theme}-category-card`}
      id={sub_category_id}
      style={{ borderLeft: "5px solid #fc007f" }}
    >
      <div
        style={{
          width: "93%",
        }}
      >
        <div>{sub_category_name}</div>
        <div
          className={`sub-categroy-card-url ${theme}-sub-categroy-card-url`}
          style={{
            marginTop: "-2px",
            textdecoration: "none",
            fontSize: "10px;",
          }}
        >
          {categoryInfoLocal[catIndex]?.CatHyperlinks?.isHyperlinkActive ? (
            <a
              href={categoryInfoLocal[catIndex]?.CatHyperlinks?.url}
              target="_blank"
              rel="noreferrer"
              style={{
                whiteSpace: "nowrap",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                textdecoration: "none",
                fontSize: "10px;",
              }}
            >
              {categoryInfoLocal[catIndex]?.CatHyperlinks?.url}
            </a>
          ) : null}
        </div>
      </div>

      <Box display="flex" alignItems="center">
        <span class="material-icons-outlined drag-icon">reorder</span>
        <span class="material-icons-outlined" onClick={handleOpen}>
          more_vert
        </span>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Box>
    </div>
  );
};
const mapStateToProps = (state) => ({
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  theme: state.user.ui_preferences?.theme,
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(CategoryCard);
