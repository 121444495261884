// Import required libraries
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 } from "uuid";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";

// Import styles
import "./AppRow.scss";
import { StyledAutoComplete, CssTextField, useStyles } from "../AppRow/style";

// Import custom components
import EditCard from "../AppCard/EditCard";
import Loader from "../Loader/Loader";

// Import Hooks
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

// Import utils & data
import { getElementContentWidth } from "../../utils/utils";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import NewAppCard from "../AppCard/NewAppCard";
import { useHistory } from "react-router";
import AppsData from "../../redux/reducers/AppsData";
import { Explore } from "@material-ui/icons";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AppRowTitle(props) {
  let {
    title,
    editLayoutBool,
    setShowDropDown,
    showDropDown = true,
    addApp,
    addAppArr,
    HandleAddApp,
    tempArr,
    setTempArr,
    AllApps,
    user,
    ExploreApps,
  } = props;
  const classes1 = useStyles();
  const [inputValue, setInputValue] = useState("");

  const HandleAddTempApp = (app_NAME) => {
    let newTempArr = [];
    if (tempArr.includes(app_NAME[0])) {
      newTempArr = tempArr.filter((app) => app !== app_NAME[0]);
    } else {
      newTempArr = [...tempArr, app_NAME[0]];
    }
    setTempArr(newTempArr);
  };
  return (
    <>
      {title && (
        <div className="applications-row-container-style">
          {!showDropDown ? (
            <h1
              className={`applications-row-title-style ${user.ui_preferences.theme}`}
            >
              {title === "ml app"
                ? (title = "ML App")
                : title === "bi app"
                ? (title = "Embedded Analytics")
                : title}

              {ExploreApps?.[title]?.status === "success" &&
                ExploreApps?.[title]?.data?.count > 0 &&
                " (" + ExploreApps?.[title]?.data?.count + ")"}
            </h1>
          ) : (
            <div className={`row-title-input-container`}>
              <h1
                className={`applications-row-title-style ${user.ui_preferences.theme}`}
              >
                {title === "ml app"
                  ? "ML App"
                  : title === "bi app"
                  ? "Embedded Analytics"
                  : title}
              </h1>
              <StyledAutoComplete
                multiple
                disableCloseOnSelect
                value={addApp || []}
                options={addAppArr || []}
                getOptionLabel={(option) => option}
                noOptionsText={
                  addAppArr.length === 0
                    ? "You have added all applications"
                    : "No Options"
                }
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onClose={() => {
                  HandleAddApp(tempArr);
                }}
                onChange={(e, newValue) => {
                  HandleAddTempApp(newValue);
                }}
                renderOption={(option, { selected }) => {
                  return (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        size="small"
                        style={{
                          marginRight: 5,
                          padding: "0px",
                        }}
                        sx={{
                          "&.Mui-checked": {
                            color: "#FC007F",
                          },
                        }}
                        checked={tempArr.includes(option)}
                      />
                      <div
                        style={{
                          color:
                            user.ui_preferences.theme === "dark"
                              ? "#fff"
                              : "#282828",
                        }}
                      >
                        {option}
                      </div>
                    </React.Fragment>
                  );
                }}
                renderInput={(params) => (
                  <CssTextField
                    {...params}
                    className={`${classes1.typographyStyle} ${classes1.SecFilUnActive}`}
                    fullWidth
                    placeholder={`Search "${
                      title === "bi app" ? "Embedded Analytics" : title
                    }" Apps`}
                  />
                )}
                style={{
                  margin: "10px 0px",
                  width: "250px",
                }}
              />
              <span
                onClick={() => {
                  setShowDropDown(!showDropDown);
                }}
                class="material-icons-round applications-row-icon-style"
              >
                cancel
              </span>
            </div>
          )}
          {editLayoutBool && AllApps.status === "loading" ? (
            <Loader />
          ) : editLayoutBool &&
            !showDropDown &&
            AllApps.status !== "loading" ? (
            // <img
            //   onClick={() => {
            //     setShowDropDown(!showDropDown);
            //   }}
            //   style={{ marginRight: "10px", cursor: "pointer" }}
            //   alt="icon"
            //   src={add_new}
            // ></img>
            <button
              id={title}
              className={`add-new-app-button GA-add-new`}
              onClick={() => {
                setShowDropDown(!showDropDown);
              }}
            >
              Add New
            </button>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}

function AppRow(props) {
  let {
    data,
    title = "Random Apps",
    handleRefreshCard,
    pageLoadState,
    editLayoutBool = false,
    AllApps,
    renderAppData,
    setRenderAppData,
    tempLayout,
    setTempLayout,
    app_count = 0,
    user,
    fetchExploreData,
    name,
    status,
    ExploreApps,
  } = props;
  const [allExpand, setAllExpand] = useState({
    expanded: false,
    value: "Show All",
  });
  // Defining required states
  const containerId = useRef(`appRow_${v4()}`);
  const [sliceCount, setSliceCount] = useState();
  const [addAppArr, setAddAppArr] = useState([]);
  const [addApp, setAddApp] = useState([]);
  const [showDropDown, setShowDropDown] = useState();
  const [tempArr, setTempArr] = useState([]);
  const history = useHistory();
  const [items, setItems] = useState({
    left: data,
  });
  const handleCreateInitialOptions = (data) => {
    const selectedApps = data.filter(
      (app) => app.app_details.app_category === title
    );

    const selectedAppsNames = selectedApps.map((app) => {
      return app.app_meta.app_name;
    });
    const CategoryApps = AllApps.data.filter(
      (app) => app.app_details.app_category === title
    );

    const CategoryAppsNames = CategoryApps.map((app) => {
      return app.app_meta.app_name;
    });
    const nonSelectedApps = CategoryAppsNames.filter(
      (app_name) => !selectedAppsNames.includes(app_name)
    );
    // const appOptionsNames = nonSelectedApps.length
    //   ? nonSelectedApps.map((app) => {
    //       return app.app_meta.app_name;
    //     })
    //   : [];
    // setAddAppArr(appOptionsNames)
    return nonSelectedApps;
  };

  // Defining required side effects
  useEffect(() => {
    setShowDropDown(false);
    // Defining required function
    const handleWindowSize = () => {
      // Should come from config
      const appCardWidth = 227;
      const appRowGridGap = 10;

      // Getting slice count based on width
      const appRowEl = document.getElementById(containerId.current);
      const appRowContentWidth = getElementContentWidth(appRowEl);
      const newSliceCount = Math.floor(
        appRowContentWidth / (appCardWidth + appRowGridGap)
      );

      // Setting slice count
      setSliceCount(newSliceCount);
    };

    // Do this only when singleRowFlag is true
    if (!allExpand.expanded) {
      // Call the function when component mounts
      handleWindowSize();

      // Add event listener to window resize
      window.addEventListener("resize", handleWindowSize);
    }

    return () => {
      // Remove event listener to window resize
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);

  useEffect(() => {
    if (AllApps.status === "success") {
      const optArr = handleCreateInitialOptions(data);
      setAddAppArr(optArr);
    }
  }, [AllApps.status]);

  // const ToggleAllExpand = () => {
  //   if (app_count > 0) {
  //     fetchExploreData(name, "All");
  //   }
  //   if (!allExpand.expanded) {
  //     // setAllExpand({ expanded: true, value: "Show Less" });
  //     // const rowName = title.replace(" ", "%20");
  //     const rowName = title.replace(" ", "%20");
  //     const myroute = "/explore/" + user.viewByFilter + "/" + rowName;
  //     console.log("myroute", myroute);
  //     history.push(myroute);
  //   } else {
  //     setAllExpand({ expanded: false, value: "Show All" });
  //   }
  // };

  const ToggleAllExpand = () => {
    const rowName = title.replace(" ", "%20");
    const myroute = "/explore/" + user.viewByFilter + "/" + rowName;
    console.log("myroute", myroute);
    history.push(myroute);
  };

  function OnLayoutChange(sourceId, sourceIndex, targetIndex, targetId) {
    const gaEventTracker = useAnalyticsEventTracker("AppsLayout");
    gaEventTracker("ArrangeApps", `app_row = ${title}`);
    if (targetId) {
      const result = move(
        items[sourceId],
        items[targetId],
        sourceIndex,
        targetIndex
      );
      return setItems({
        ...items,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }
    // This if condition will restrict swap of first element. = = = = = = = = =
    // if (items[sourceId] && sourceIndex && targetIndex) {
    if (items[sourceId] === undefined) {
      return;
    }
    const result = swap(items[sourceId], sourceIndex, targetIndex);
    return setItems({
      ...items,
      [sourceId]: result,
    });
    // }
  }

  // Update the temporary layout
  useEffect(() => {
    if (renderAppData !== undefined) {
      let newRenderAppData = [title, items.left];
      const index = tempLayout.findIndex((el) => el[0] === title);
      const notUpdatedRecordsLeft = tempLayout.slice(0, index);
      const notUpdatedRecordsRight = tempLayout.slice(index + 1);
      const updatedState = [
        ...notUpdatedRecordsLeft,
        newRenderAppData,
        ...notUpdatedRecordsRight,
      ];
      setTempLayout(updatedState);
    }
  }, [items.left]);

  const HandleAddApp = (appList) => {
    const gaEventTracker = useAnalyticsEventTracker("AppsLayout");
    gaEventTracker("AddApps", `app_row = ${title}`);
    // remove this APP-name from the options list
    const newAddAppArr = addAppArr.filter(
      (app_name) => !appList.includes(app_name)
    );
    setAddAppArr(newAddAppArr);

    // add this app object in the ITEMS list
    const NewlyAddedApps = AllApps.data.filter((app) =>
      appList.includes(app.app_meta.app_name)
    );
    const OldArrList = items.left;
    // const newAddedArrList = OldArrList.push(NewAddedAppOBJ);
    setItems({ left: [...NewlyAddedApps, ...items.left] });
    setAddApp([]);
    setTempArr([]);
  };

  const dropZoneHeight =
    items.left.length > sliceCount ? items.left.length / sliceCount : 1;
  const cardHeight = user.ui_preferences.grid_size === "large_grid" ? 250 : 140;
  const dropZoneHeightRounded = Math.ceil(dropZoneHeight) * cardHeight;
  const textHeight = dropZoneHeightRounded.toString();
  const textHeightinPX = textHeight + "px";

  return (
    <div className="app-row-container-style" id={containerId.current}>
      <div className="row-header-container-style">
        <AppRowTitle
          title={title}
          editLayoutBool={editLayoutBool}
          setShowDropDown={setShowDropDown}
          showDropDown={showDropDown}
          addApp={addApp}
          addAppArr={addAppArr}
          HandleAddApp={HandleAddApp}
          tempArr={tempArr}
          setTempArr={setTempArr}
          AllApps={AllApps}
          ExploreApps={ExploreApps}
          user={user}
        />
        {!editLayoutBool &&
          (data.length > sliceCount || app_count > data.length) && (
            <p
              className={`show-app-title-style ${user.ui_preferences.theme} GA-expand-collapse-row`}
              onClick={() => {
                ToggleAllExpand(title);
              }}
            >
              {allExpand.value}
            </p>
          )}
      </div>
      {!editLayoutBool && (
        <div className="applications-row-grid-style">
          {(allExpand.expanded && status !== "loading"
            ? data
            : data.slice(0, sliceCount)
          ).map((row, index) => (
            <NewAppCard
              handleRefreshCard={handleRefreshCard}
              key={row.app_id}
              appIndex={index}
              row={row}
              pageLoadState={pageLoadState}
            />
          ))}
        </div>
      )}
      {status === "loading" && <Loader />}
      {editLayoutBool && (
        <GridContextProvider onChange={OnLayoutChange}>
          <GridDropZone
            className="dropzone left"
            id="left"
            boxesPerRow={sliceCount}
            rowHeight={130}
            style={{ height: textHeightinPX }}
          >
            {items.left.map((row, index) => (
              <GridItem
                // onMouseDown={(e) => e.stopPropagation()}
                key={row.app_meta.app_name}
              >
                <EditCard
                  disabled={true}
                  key={row.app_id}
                  appIndex={index}
                  row={row}
                  setItems={setItems}
                  items={items}
                  setAddAppArr={setAddAppArr}
                  gridList={items.left}
                  optionsList={addAppArr}
                  pageLoadState={pageLoadState}
                  tempLayout={tempLayout}
                />
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      )}
    </div>
  );
}

AppRow.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  // handleRefreshCard: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  AllApps: state.AppsData.allApps,
  ExploreApps: state.AppsData.explore_apps,
});

export default connect(mapStateToProps)(AppRow);
