import C from "../constants";
import { stateData } from "../stateData";

export default function handleBiEmbedToken(state = {}, payload) {
  let { type, data } = payload;
  switch (type) {
    case C.UPDATE_TOKEN:
      return { ...state, token: data?.token, expTimestamp: data?.expTimestamp };
    default:
      return state;
  }
}
