import { Box } from "@mui/material";
import React from "react";
import welcomLogo from "../../../assets/images/setup-images/welcomLogo.svg";
import "./index.scss";

const WelcomeScreen = ({ setActiveScreen, template }) => (
    <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        flexDirection="column"
        flex={1}
        px={10}
        zIndex={1000}
    >
        <img src={welcomLogo} alt="" height="180px" />
        <div>
            <div className="setup-title">{template.title || ""}</div>
            <div className="setup-desc" style={{ marginBottom: "40px" }}>
                {template.Description || ""}
            </div>
        </div>
        <button
            className="setup-btn"
            onClick={() => setActiveScreen((prev) => prev + 1)}
        >
            Next
        </button>
    </Box>
);

export default WelcomeScreen;
