import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import { connect, useDispatch } from "react-redux";

// Import Custom components
import ProfileMangement from "./ProfileManagement";
import UserAccessMangement from "./UserAccessMangement";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import RBImageHeader from "../../components/RBImageHeader/RBImageHeader";
import TabComponent from "./TabComponent";
import { SkeletonforManageProfile } from "../../components/ImageLoader/SkeletonLoadScreen";

//actions
import {
  clean_user_access_list,
  get_user_access_list,
} from "../../redux/actions";

// Import Styles
import "./style.scss";
import { useHistory, useParams } from "react-router";

const UserMangement = ({ match, theme, user, userInfo, user_access_list }) => {
  const { tab } = useParams();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("my-profile");
  const [usersList, setUsersList] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);

  useEffect(() => {
    if (tab !== "my-profile" && tab !== "manage-access") {
      history.push("/profile-management/my-profile");
      setActiveTab("my-profile");
    } else {
      setActiveTab(tab);
      history.push("/profile-management/" + tab);
    }
  }, [tab]);

  const dispatch = useDispatch();
  const updatePendingRequest = (data) => {
    if (!data.length) return;
    const pendingData = data.filter(
      (item) => item.approval_meta?.approval_status === "pending"
    );
    setPendingCount(pendingData.length);
  };

  useEffect(() => {
    dispatch(
      get_user_access_list({
        logged_in_user: userInfo.preferred_username,
        role: userInfo.role,
      })
    );
    return () => {
      dispatch(clean_user_access_list());
      setUsersList([]);
    };
  }, [dispatch, userInfo.preferred_username]);

  useEffect(() => {
    if (user_access_list.status === "success") {
      updatePendingRequest(user_access_list.data);
      setUsersList(user_access_list.data);
    }
  }, [user_access_list.data, user_access_list.status]);

  return (
    <LayoutTopSideBottom match={match}>
      {user_access_list.status === "loading" && <SkeletonforManageProfile />}
      {user_access_list.status === "success" && (
        <div className={`profile-container ${theme}-profile-container`}>
          <Container sx={{ textAlign: "start" }}>
            <TabComponent
              usersList={usersList}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            {activeTab === "manage-access" ? (
              <UserAccessMangement
                updatePendingRequest={updatePendingRequest}
                pendingCount={pendingCount}
                setPendingCount={setPendingCount}
                setUsersList={setUsersList}
                usersList={usersList}
              />
            ) : (
              <ProfileMangement theme={theme} user={user} />
            )}
          </Container>
        </div>
      )}
    </LayoutTopSideBottom>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  theme: state.user.ui_preferences?.theme,
  userInfo: state.user,
  user_access_list: state.userAccessManagement.user_access_list,
});

export default connect(mapStateToProps)(UserMangement);
