import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

// Import Custom Component
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import Loader from "../../components/Loader/Loader";
import AppRow from "../../components/AppRow/AppRow";
import SkeletonLoadScreen from "../../components/ImageLoader/SkeletonLoadScreen";
import RBHeaderComponent from "./RBHeaderComponent";

// Import action creators
import {
  setActiveAssetCollection,
  updateUserInfo,
  getAllApps,
  getRefreshedApps,
  getLayoutApps,
  getRecommendedApps,
  updateRecommendedApps,
  get_user_filters,
  cleanTopUsersState,
  update_explore_apps,
  clean_explore_apps,
} from "../../redux/actions";

// Import utils/data
import { ChangeViewByStructure, UpdatePageView } from "../../utils/utils";

// import { appData } from "./AppData";
import error_smw from "../../../assets/images/error_smw.svg";
import no_search from "../../../assets/images/no_search.svg";

// Import styles
import "./Explore.scss";
import RACRow from "../../components/RecommendedAppCard/RACRow";
import NewAppTable from "../../components/AppTable/NewAppTable";

function fetchExploreApps(payload) {
  let url = "/api/apps?";
  for (const [key, value] of Object.entries(payload)) {
    url += `${key}=${value}&`;
  }
  return fetch(url);
}

function Explore(props) {
  const { match, AppsData = {}, templates, user } = props;
  const { explore_apps } = AppsData;
  const dispatch = useDispatch();
  const [Recommendedapps, setRecommendedapps] = useState([]);
  const [allLabels, setAllLabels] = useState([]);

  // This state will show apps on UI
  const [renderAppData, setRenderAppData] = useState([]);
  const [renderTableData, setRenderTableData] = useState([]);

  // Usually called after an app is favourited
  const handleRefreshCard = () => {
    // if (query) {
    //     setLoadingFilter("loading");
    //     setRefresh(!refresh);
    // }
    dispatch(getRefreshedApps());
  };

  const isPageLoading = useMemo(() => {
    return Object.keys(explore_apps).some(
      (item) => explore_apps[item].status === "success"
    );
  }, [explore_apps]);

  const updateScreenWithFilteredData = useCallback(
    (list) => {
      const fieldValue = templates.data.search_template.fields.filter(
        (field) => field.key === user.viewByFilter
      );
      // const filteredIds = user.filteredApps.data.map((app) => app.app_id);
      let newapps = [];
      if (
        user.filteredApps.data.length &&
        user.filteredApps.status === "success"
      ) {
        newapps = list.filter((app) =>
          user.filteredApps.data.includes(app.app_id)
        );
      }
      const data1 = newapps.length ? newapps : list;
      const newData = ChangeViewByStructure(
        data1,
        user.viewByFilter,
        fieldValue[0].options,
        fieldValue[0].value_type
      );
      setRenderAppData(newData);
      setRenderTableData(data1);
    },
    [
      templates.data.search_template?.fields,
      user.viewByFilter,
      user.filteredApps,
    ]
  );
  const filterRecommendedApps = (data) => {
    // get the app objects for recommended apps
    const RecApps = AppsData.allApps.data.filter((app) =>
      data.recommended_apps.includes(app.app_id)
    );
    // create a copy of all apps arr so that it is not modified by sorting
    const allAppsCopyData = JSON.parse(JSON.stringify(AppsData.allApps.data));
    //get the app objects of most viewed apps in descending order
    const mostviewedApps = allAppsCopyData.sort(
      (a, b) => b.app_meta.total_views - a.app_meta.total_views
    );
    // remove the already viewed & recommended apps & irrelevant apps from most viewed apps
    const removed_ViewednRecc = mostviewedApps.filter(
      (app) =>
        !(
          data.apps_used_by_user.includes(app.app_id) ||
          data.recommended_apps.includes(app.app_id) ||
          data.irrelevant_apps.includes(app.app_id)
        )
    );
    const finalArr = [...RecApps, ...removed_ViewednRecc].slice(0, 10);
    setRecommendedapps(finalArr);
  };

  const handleFetchRecommendedApps = () => {
    if (AppsData.recommendedApps.status !== "loading") {
      dispatch(
        getRecommendedApps({
          user_id: user.preferred_username,
        })
      );
    }
  };

  const removeRACfromLocalState = (app) => {
    const newList = Recommendedapps.filter((el) => el.app_id !== app.app_id);
    setRecommendedapps(newList);
    let recommendedAppData = {
      apps_used_by_user: AppsData.recommendedApps.data.apps_used_by_user,
      recommended_apps: AppsData.recommendedApps.data.recommended_apps.filter(
        (el) => el !== app.app_id
      ),
      irrelevant_apps: [
        ...AppsData.recommendedApps.data.irrelevant_apps,
        app.app_id,
      ],
    };
    filterRecommendedApps(recommendedAppData);
    dispatch(updateRecommendedApps(recommendedAppData));
  };

  useEffect(() => {
    dispatch(clean_explore_apps());
  }, []);

  useEffect(() => {
    if (AppsData.user_filters.status === "pending") {
      dispatch(get_user_filters({ user_id: user.preferred_username }));
    }
  }, [AppsData.user_filters.status, dispatch, user.preferred_username]);

  useEffect(() => {
    if (
      AppsData.recommendedApps.status === "success" &&
      AppsData.allApps.status === "success"
    ) {
      filterRecommendedApps(AppsData.recommendedApps.data);
    }
  }, [AppsData.recommendedApps.status, AppsData.allApps.status]);

  // get all the apps
  useEffect(() => {
    UpdatePageView("explore");
    dispatch(getAllApps());
    if (AppsData?.layoutApps?.status === "pending") {
      dispatch(
        getLayoutApps({
          user_id: user.preferred_username,
        })
      );
    }
    return () => {
      dispatch(cleanTopUsersState());
    };
  }, [dispatch]);

  const getAppType = (app_type) => {
    return app_type === "Standalone App"
      ? "standalone_app"
      : app_type === "ML App"
      ? "ml_app"
      : "bi_app";
  };
  const fetchExploreData = useCallback(
    async (data, count) => {
      const name = data.region || data;
      const payload = {
        type: "by_category",
        cat_name: user.viewByFilter,
        cat_value: user.viewByFilter === "app_type" ? getAppType(name) : name,
        cat_type:
          user.viewByFilter === "app_category"
            ? "single"
            : user.viewByFilter === "geography"
            ? "custom"
            : "multi",
        count: count || 15,
      };
      dispatch(
        update_explore_apps({
          [name]: {
            ...(explore_apps[name] || {}),
            status: "loading",
          },
        })
      );
      const res = await fetchExploreApps(payload);
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200") {
        dispatch(
          update_explore_apps({
            [name]: {
              status: "success",
              data: response.data,
            },
          })
        );
      } else {
        dispatch(
          update_explore_apps({
            [name]: { status: "error", data: {} },
          })
        );
      }
    },
    [dispatch, explore_apps, user.viewByFilter]
  );

  useEffect(() => {
    if (templates.status === "success") {
      const fieldValue = templates.data.search_template.fields.find(
        (field) => field.key === user.viewByFilter
      );
      setAllLabels(fieldValue.options);
      fieldValue.options?.forEach((item) => fetchExploreData(item));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, user.viewByFilter]);

  useEffect(() => {
    if (
      // AppsData?.allApps?.status === "success" &&
      AppsData.recommendedApps.status === "pending"
    ) {
      handleFetchRecommendedApps();
    }
  }, [AppsData?.allApps?.status]);

  // Triggered whenever a view-by value is changed
  // BUG was HERE IN THIS USE EFFECT
  useEffect(() => {
    if (
      AppsData?.allApps?.status === "success" &&
      templates?.status === "success"
    ) {
      updateScreenWithFilteredData(AppsData.allApps.data);
    }
    // initially when template is not loaded
    // else {
    //   // const filteredIds = user.filteredApps.data.map((app) => app.app_id);
    //   let newapps = [];
    //   if (
    //     user.filteredApps.data.length &&
    //     user.filteredApps.status === "success"
    //   ) {
    //     newapps = AppsData?.allApps?.data.filter((app) =>
    //       user.filteredApps.data.includes(app.app_id)
    //     );
    //   }
    //   const data1 = newapps.length ? newapps : AppsData.allApps.data;
    //   const newData = ChangeViewByStructure(
    //     data1,
    //     user.viewByFilter,
    //     [
    //       "Analytical App",
    //       "Data Science Lab",
    //       "Data Assets",
    //       "Social Intelligence",
    //       "General",
    //     ],
    //     "single"
    //   );
    //   setRenderAppData(newData);
    //   setRenderTableData(data1);
    // }
  }, [
    user.viewByFilter,
    templates?.status,
    AppsData?.allApps,
    user.filteredApps,
  ]);

  return (
    <LayoutTopSideBottom selected={match.path} match={match}>
      {AppsData?.allApps?.status === "error" && (
        <div className="error-image-container">
          <img
            style={{ marginTop: "150px !important" }}
            src={error_smw}
            alt="ERROR"
          />
        </div>
      )}

      {(!isPageLoading || user.filteredApps.status === "loading") && (
        <SkeletonLoadScreen showSearchBar={true} />
      )}
      {isPageLoading && user.filteredApps.status !== "loading" && (
        <div
          className={`applications-container explore-apps-container`}
        >
          <div className="aide-root" style={{ padding: "0% 1.5% 1.5% 1.5%" }}>
            <div>
              {AppsData?.recommendedApps?.status !== "pending" &&
                !(
                  AppsData?.recommendedApps?.status === "success" &&
                  Recommendedapps.length === 0
                ) && (
                  <div className="recommended-apps-container">
                    <RACRow
                      data={Recommendedapps}
                      loadStatus={AppsData?.recommendedApps?.status}
                      handleRefresh={handleFetchRecommendedApps}
                      handleRemove={removeRACfromLocalState}
                    />
                  </div>
                )}
              {user.filteredApps.data.length === 0 &&
              user.filteredApps.status === "success" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      marginTop: "150px",
                      height: "200px",
                    }}
                    src={no_search}
                    alt="No Apps Found"
                  ></img>
                </div>
              ) : user.ui_preferences.grid_size !== "list" ? (
                Boolean(user.filteredApps.data.length > 0) ? (
                  renderAppData.map((item) => {
                    if (item[1].length) {
                      return (
                        <div className="applications-container">
                          <div className="applications-row">
                            <AppRow
                              handleRefreshCard={handleRefreshCard}
                              data={item[1]}
                              title={item[0].replaceAll("_", " ")}
                              pageLoadState={AppsData?.refreshedAllApps?.status}
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                ) : (
                  allLabels.map((list) => {
                    const name = list.region || list;
                    if (
                      explore_apps[name] &&
                      explore_apps[name].data?.apps?.length
                    ) {
                      return (
                        <div className="applications-container" key={name}>
                          <div className="applications-row">
                            <AppRow
                              handleRefreshCard={handleRefreshCard}
                              fetchExploreData={fetchExploreData}
                              name={name}
                              data={explore_apps[name].data.apps}
                              app_count={explore_apps[name].data.count}
                              status={explore_apps[name].status}
                              title={name.replaceAll("_", " ")}
                              pageLoadState={AppsData?.refreshedAllApps?.status}
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                )
              ) : (
                <div style={{ marginTop: "20px" }}>
                  <NewAppTable tableData={renderTableData} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </LayoutTopSideBottom>
  );
}

Explore.propTypes = {
  user: PropTypes.object,
  verticals: PropTypes.array,
  match: PropTypes.object,
  AppsData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  verticals: state.data.verticals,
  AppsData: state.AppsData,
  templates: state.register.registerTemplate,
});

const mapDispatchToProps = {
  updateUserInfo,
  setActiveAssetCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Explore);
