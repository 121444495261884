import React from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import delete_icon from "../../../assets/images/delete_icon.svg";
import { config } from "../../config/config";

export const useStyles = makeStyles((theme) => ({
  container: {
    // border:
    //     theme.palette.mode === "dark"
    //         ? "transparent"
    //         : "0.2px solid #e7e9ee",
    // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
    boxShadow: config.cssValues.cardShadow,
    background: theme.palette.mode === "dark" ? "#282828" : "#ffffff",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    marginTop: "6px",
    "& .deleteLogo": {
      display: "none",
    },
    "&:hover": {
      "& .deleteLogo": {
        display: "block",
      },
    },
  },
  deleteIcon: {
    position: "absolute",
    left: " -6px",
    top: "-6px",
    height: "15px",
  },
  firstColumn: {
    display: "flex",
    alignItems: "center",
    // gap: "10px",
    flex: 1,
    padding: "5px",
    minHeight: "45px",
    "& .googleIcons": {
      background:
        "linear-gradient(45deg, #FC007F 0%,#FC007F 50% ,#FF4D48 100%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
    },
    "& .normalIcons": {
      color: "#fc007f",
    },
  },
  uploadedImg: {
    maxHeight: "55px",
    maxWidth: "55px",
  },
  headerText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "26px",
    color: "#FC007F",
  },
  descriptionText: {
    fontStyle: "normal",
    fontWeight: 500,
    color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    // fontSize: "16px",
    // lineHeight: "26px",
    fontSize: "12px",
    lineHeight: "18px",
    overflow: "hidden",
    overflowWrap: "anywhere",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    whiteSpace: "pre-wrap",
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#282828",
  },
}));

const TempHeader = ({
  data = {},
  handleEdit,
  handleDelete,
  notEditable = false,
  normalIcons,
  editable_layout,
}) => {
  const classes = useStyles();

  const {
    sub_category_description,
    sub_category_name,
    sub_category_icon,
    app_logo_type,
  } = data;
  const path_has_register =
    window.location.pathname.split("/")[1] === "register";
  return (
    <div className={classes.container}>
      <div
        className={classes.firstColumn}
        onClick={() =>
          editable_layout && path_has_register ? handleEdit(data) : null
        }
      >
        {sub_category_icon && (
          <div className={classes.imgContainer}>
            {app_logo_type === "google_font" ? (
              <span
                class={`material-icons-outlined ${
                  normalIcons ? "normalIcons" : "googleIcons"
                }`}
                style={{
                  fontSize: "50px",
                  // color: "#8d9ca6",
                }}
              >
                {sub_category_icon}
              </span>
            ) : (
              <img
                src={sub_category_icon}
                alt=""
                className={classes.uploadedImg}
              />
            )}
          </div>
        )}
        <div style={{ marginLeft: "15px" }}>
          <div className={classes.headerText}>{sub_category_name}</div>
          <div
            className={classes.descriptionText}
            title={sub_category_description}
          >
            {sub_category_description}
          </div>
        </div>
        {!notEditable && editable_layout && (
          <>
            <img
              src={delete_icon}
              alt="delete"
              className={`${classes.deleteIcon} deleteLogo`}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(data);
              }}
            />
            {/* <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={() => handleEdit(data)}
                            title="Edit"
                        >
                            <EditIcon sx={{ fontSize: "14px" }} />
                        </IconButton> */}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
  editable_layout: state.registerNewApp.statusData.editLayout,
});
export default connect(mapStateToProps)(TempHeader);
