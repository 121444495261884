import { Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import ProvisioningAccess from "./ProvisioningAccess";
import ProvisioningAccessList from "./ProvisioningAccessList";
import { config } from "../../../../config/config";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { ownerList } from "../../../../utils/utils";

const GiveAccessComponent = ({
  isAppUrlUnique,
  appDetails,
  userInfo,
  isFieldsEditable,
  isAdminRegistering,
  configDetails,
  appFields,
  label,
  eventTracker,
  theme,
}) => {
  const { appInfo } = useParams();
  const [openProvModal, setOpenProvModal] = useState(false);
  const handleProvModal = () => {
    setOpenProvModal(true);
    eventTracker("Add access btn clicked", "open popup");
  };
  return (
    <Grid item xs={12} sx={{ pb: "12px" }}>
      {" "}
      {(isAppUrlUnique ||
        `https://${appFields?.app_url}-prd${config.hardCoded.appUrl}` ===
          appDetails.data.app_meta?.app_url) && (
        <>
          {<ProvisioningAccessList data={configDetails} />}
          {userInfo.role === "admin" &&
          (appInfo || isAdminRegistering) &&
          !ownerList(appDetails.data).includes(userInfo.preferred_username)
            ? null // </> //   <div className="heading">{label || "Give Access to"}:</div> // <>
            : (appInfo == undefined ||
                (!appInfo.includes("pending") &&
                  !appInfo.includes("rejected"))) && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    onClick={handleProvModal}
                    size="small"
                    disabled={isFieldsEditable}
                    className="btn-add-access"
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          lineHeight: "14px",
                          paddingRight: "10px",
                          fontSize: "16px",
                        }}
                      >
                        +
                      </div>
                      <div>Add Access</div>
                    </div>
                  </button>

                  <ProvisioningAccess
                    openProvModal={openProvModal}
                    setOpenProvModal={setOpenProvModal}
                    isFieldsEditable={isFieldsEditable}
                    label={label}
                    isAppUpdating={appInfo || isAdminRegistering}
                  />
                </Stack>
              )}
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  configDetails: state.registerNewApp.MLappDetails.configDetails,
  appFields: state.registerNewApp.appData.appFields,
  appDetails: state.AppsData.appDetails,
  userInfo: state.user,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(GiveAccessComponent);
