// Import required libraries
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

// Import custom components
import Loader from "../../components/Loader/Loader";

// Import styles
import "./AppSideNav.scss";

// Import Hooks
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

// Import action creators
import {
  updateDialogInfo,
  updateLoyoutDataList,
  update_home_layout,
} from "../../redux/actions";
import { addAppToHome } from "../../utils/utils";
import { get, set } from "lodash";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 500,
  },
  fullList: {
    width: "auto",
  },
  typographyStyle: {
    fontFamily: "Energy",
    fontSize: 14,
    color: "#282828",
    width: 400,
  },
  contained: {
    color: theme.palette.mode === "dark" ? "white" : "#282828",
    backgroundColor: theme.palette.mode === "dark" ? "#282828" : "white",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Energy",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#282828",
      color: "white",
      boxShadow: "none",
    },
  },
  outlined: {
    borderColor: "#282828",
    backgroundColor: "transparent",
    color: theme.palette.mode === "dark" ? "white" : "#282828",
    marginRight: 4,
    fontSize: 11,
    padding: 10,
    height: 23,
    minWidth: 15,
    textTransform: "capitalize",
    fontFamily: "Energy",
    "&:hover": {
      backgroundColor: "#282828",
      color: "white",
      boxShadow: "none",
    },
  },
  popupTitle: {
    fontSize: "15px",
    fontFamily: '"Energy"',
    width: "225px",
    margin: "20px",
    color: theme.palette.mode === "dark" ? "white" : "#282828",
  },
  btn: {
    background: "transparent",
    color: "#fc007f",
    fontSize:"13px",
    fontWeight:"400"
  },
  btnInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    "& .material-icons-round": {
      fontSize: "18px",
    },
    "& .material-icons-outlined": {
      fontSize: "18px",
    },
  },
}));

const getSubCatReport = (subCategoryRow, setActiveReport) => {
  if (subCategoryRow?.SubCatHyperlinks?.isHyperlinkActive === true) {
    const subCatReport = {
      bi_application: subCategoryRow?.SubCatHyperlinks?.bi_application,
      sub_category_id: subCategoryRow?.sub_category_id,
      sub_category_name: subCategoryRow?.sub_category_name,
      url: subCategoryRow?.SubCatHyperlinks?.url,
    };
    setActiveReport(subCatReport);
  }
};

const getCatReport = (categoryRow, setActiveReport) => {
  if (categoryRow?.CatHyperlinks?.isHyperlinkActive === true) {
    const subCatReport = {
      bi_application: categoryRow?.CatHyperlinks?.bi_application,
      sub_category_id: categoryRow?.sub_category_id,
      sub_category_name: categoryRow?.sub_category_name,
      url: categoryRow?.CatHyperlinks?.url,
    };
    setActiveReport(subCatReport);
  }
};
//Custom Components
const SidenavReportRow = (props) => {
  const {
    reportRow,
    // expanded,
    // setExpanded,
    navExpanded,
    activeReport,
    setActiveReport,
    // updateDialogInfo,
    user,
  } = props;
  return (
    <div className="sidenav-sub-category">
      <div
        className={
          activeReport.sub_category_id === reportRow.sub_category_id
            ? `active-row ${user.ui_preferences.theme}-bi`
            : `${user.ui_preferences.theme}-bi`
        }
      >
        <div
          className={`sidenav-title-sub-category-container ${user.ui_preferences.theme}-bi`}
          onClick={() => {
            setActiveReport(reportRow);
          }}
        >
          {navExpanded ? (
            <>
              {reportRow.app_logo_type === "google_font" ? (
                <i
                  className={
                    activeReport.sub_category_id === reportRow.sub_category_id
                      ? `material-icons-outlined sidenav-sub-category-icon active`
                      : `material-icons-outlined sidenav-sub-category-icon ${user.ui_preferences.theme}-bi`
                  }
                >
                  {reportRow.sub_category_icon}
                </i>
              ) : (
                <img
                  src={reportRow.sub_category_icon}
                  style={{
                    width: "25px",
                    marginRight: "10px",
                  }}
                  alt=""
                />
              )}
              <p
                className={
                  activeReport.sub_category_id === reportRow.sub_category_id
                    ? "sidenav-title-sub-category active"
                    : `sidenav-title-sub-category ${user.ui_preferences.theme}-bi`
                }
              >
                {/* report name */}
                {reportRow.sub_category_name}
              </p>
            </>
          ) : (
            <>
              {reportRow.app_logo_type === "google_font" ? (
                <i
                  className={
                    activeReport.sub_category_id === reportRow.sub_category_id
                      ? `material-icons-outlined sidenav-sub-category-icon active`
                      : `material-icons-outlined sidenav-sub-category-icon ${user.ui_preferences.theme}-bi`
                  }
                >
                  {reportRow.sub_category_icon}
                </i>
              ) : (
                <img
                  src={reportRow.sub_category_icon}
                  style={{
                    width: "25px",
                    marginRight: "10px",
                  }}
                  alt=""
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const SidenavSubcatRow = (props) => {
  let {
    activeReport,
    setActiveReport,
    navExpanded,
    index,
    user,
    subCategoryRow,
  } = props;
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="sidenav-category-l2">
      <div
        className={`sidenav-title-category-container expanded ${
          subCategoryRow?.SubCatHyperlinks?.isHyperlinkActive &&
          activeReport.sub_category_id === subCategoryRow?.sub_category_id
            ? `active-row ${user.ui_preferences.theme}-bi`
            : `${user.ui_preferences.theme}-bi`
        }
        ${
          subCategoryRow?.SubCatHyperlinks?.isHyperlinkActive
            ? `active-row-hover ${user.ui_preferences.theme}-bi`
            : null
        }`}
      >
        {/* <div class="color-changing-div">Hover</div> */}
        {navExpanded ? (
          <div
            // className={`sidenav-title-sub-category-container ${user.ui_preferences.theme}-bi`}
            style={{ display: "flex", width: "80%" }}
            onClick={() => {
              getSubCatReport(subCategoryRow, setActiveReport);
            }}
          >
            <i
              className={`material-icons-outlined folder-icon ${
                user.ui_preferences.theme
              }-bi ${
                subCategoryRow?.SubCatHyperlinks?.isHyperlinkActive &&
                activeReport.sub_category_id === subCategoryRow?.sub_category_id
                  ? `active-row ${user.ui_preferences.theme}-bi`
                  : `${user.ui_preferences.theme}-bi`
              }`}
            >
              folder
            </i>
            <p
              className={`sidenav-title-category ${
                user.ui_preferences.theme
              }-bi ${
                subCategoryRow?.SubCatHyperlinks?.isHyperlinkActive &&
                activeReport.sub_category_id === subCategoryRow?.sub_category_id
                  ? `active-row ${user.ui_preferences.theme}-bi`
                  : `${user.ui_preferences.theme}-bi`
              }`}
            >
              {subCategoryRow.sub_category_name}
            </p>
          </div>
        ) : (
          <div
            onClick={() => {
              getSubCatReport(subCategoryRow, setActiveReport);
            }}
          >
            <i
              className={`material-icons-outlined folder-icon ${
                user.ui_preferences.theme
              }-bi
            ${
              subCategoryRow?.SubCatHyperlinks?.isHyperlinkActive &&
              activeReport.sub_category_id === subCategoryRow?.sub_category_id
                ? `active-row ${user.ui_preferences.theme}-bi`
                : `${user.ui_preferences.theme}-bi`
            }`}
            >
              folder
            </i>
          </div>
        )}

        <i
          className={`material-icons sidenav-category-icon ${user.ui_preferences.theme}-bi`}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {expanded ? "expand_more" : "chevron_right"}
        </i>
      </div>
      <div className="subcategory-tree">
        {expanded &&
          subCategoryRow.sub_category.map((reportRow) => (
            <SidenavReportRow
              key={reportRow.sub_category_id}
              reportRow={reportRow}
              navExpanded={navExpanded}
              activeReport={activeReport}
              setActiveReport={setActiveReport}
              user={user}
            />
          ))}
      </div>
    </div>
  );
};

const SidenavCategoryRow = (props) => {
  let { categoryRow, activeReport, setActiveReport, navExpanded, index, user } =
    props;
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="sidenav-category">
      <div
        className={`sidenav-title-category-container expanded
      ${
        categoryRow?.CatHyperlinks?.isHyperlinkActive &&
        activeReport.sub_category_id === categoryRow?.sub_category_id
          ? `active-row ${user.ui_preferences.theme}-bi`
          : `${user.ui_preferences.theme}-bi`
      }
      
      ${
        categoryRow?.CatHyperlinks?.isHyperlinkActive
          ? `active-row-hover ${user.ui_preferences.theme}-bi`
          : null
      }`}
      >
        {navExpanded ? (
          <div
            style={{ display: "flex", width: "80%" }}
            onClick={() => {
              getCatReport(categoryRow, setActiveReport);
            }}
          >
            <p
              className={`sidenav-title-category ${user.ui_preferences.theme}-bi`}
            >
              {categoryRow.sub_category_name}
            </p>
          </div>
        ) : (
          <div
            onClick={() => {
              getCatReport(categoryRow, setActiveReport);
            }}
          >
            <p
              className={`sidenav-title-category ${user.ui_preferences.theme}-bi`}
            >
              {index + 1} .
            </p>
          </div>
        )}

        <i
          className={`material-icons sidenav-category-icon ${user.ui_preferences.theme}-bi`}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {expanded ? "expand_more" : "chevron_right"}
        </i>
      </div>
      <div className="category-tree">
        {expanded &&
          categoryRow.sub_category.map((subCategoryRow, index) =>
            subCategoryRow.hasOwnProperty("url") ||
            subCategoryRow?.identifier === "Twitter" ? (
              <SidenavReportRow
                key={subCategoryRow.sub_category_id}
                reportRow={subCategoryRow}
                navExpanded={navExpanded}
                activeReport={activeReport}
                setActiveReport={setActiveReport}
                user={user}
              />
            ) : (
              <SidenavSubcatRow
                key={subCategoryRow.sub_category_id}
                subCategoryRow={subCategoryRow}
                navExpanded={navExpanded}
                activeReport={activeReport}
                setActiveReport={setActiveReport}
                user={user}
                index={index}
              />
            )
          )}
      </div>
    </div>
  );
};

// PROPS Comments
// user: user object
// activeReport: activeReport object
// setActiveReport: function to set activeReport
// sidenavData: complete defined_categories object
// show_sidebar: boolean to show/hide sidebar
// AppsData: complete AppsData object
// app_id: app_id of the current app
function Sidenav(props) {
  let {
    user,
    activeReport,
    setActiveReport,
    sidenavData,
    show_sidebar,
    AppsData,
    app_id,
  } = props;
  const { layoutDataList, layoutStatus } = AppsData;
  const { data: appIdList } = layoutDataList;
  const { status } = layoutStatus;
  const [navExpanded, setNavExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    setNavExpanded(show_sidebar);
  }, [show_sidebar]);

  // use to expand collapse the app-sidenav
  const ToggleMenu = () => {
    const gaEventTracker = useAnalyticsEventTracker("BiApp");
    gaEventTracker(
      "SideNavButton",
      `new_state = ${!navExpanded ? "Expand" : "Collapse"}`
    );
    setNavExpanded(!navExpanded);
  };

  const handleDialogClose = () => {
    updateDialogInfo({ ...user.dialogInfo, open: false });
  };

  const HandleAddAppToHome = async () => {
    setLoading(true);
    const data = addAppToHome(user.preferred_username, appIdList, app_id);
    dispatch(updateLoyoutDataList(data.sequence_of_app));
    dispatch(update_home_layout(data));
    setLoading(false);
  };

  const getBtnComponent = () => (
    <div className={classes.btnInner}>
      {appIdList.includes(app_id) ? (
        <>
          <i
            className="material-icons-round"
            title="Remove from home"
            id={app_id}
          >
            home
          </i>
          {navExpanded ? <div>Remove from Home</div> : null}
        </>
      ) : (
        <>
          <i
            className="material-icons-outlined"
            title="Add to home"
            id={app_id}
          >
            add_home
          </i>
          {navExpanded ? <div>Add to Home</div> : null}
        </>
      )}
    </div>
  );

  return (
    <aside
      className={`${user.ui_preferences.theme}-bi ${
        navExpanded ? "sidenav-power-bi-expanded" : "sidenav-power-bi-collapsed"
      }`}
    >
      <button
        className={
          navExpanded
            ? "jira-button expanded-nav GA-expand-appsidenav"
            : "jira-button collapsed-nav GA-expand-appsidenav"
        }
        onClick={ToggleMenu}
      >
        <i className="material-icons jira-icon GA-expand-appsidenav">
          {navExpanded ? "chevron_left" : "chevron_right"}
        </i>
      </button>

      <nav
        style={{
          background:
            user.ui_preferences.theme === "light" ? "#f6f6f6" : "#000000",
        }}
      >
        <button
          className={classes.btn}
          onClick={HandleAddAppToHome}
          disabled={loading || status === "loading"}
        >
          {loading || status === "loading" ? <Loader /> : getBtnComponent()}
        </button>
        {sidenavData ? (
          // map(element, index_of_element)
          sidenavData.map((categoryRow, index) => (
            <SidenavCategoryRow
              key={categoryRow.sub_category_id}
              categoryRow={categoryRow}
              navExpanded={navExpanded}
              index={index}
              activeReport={activeReport}
              setActiveReport={setActiveReport}
              user={user}
            />
          ))
        ) : (
          <Loader />
        )}
      </nav>
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={user.dialogInfo.open}
        className="dialog-container"
      >
        <p className={classes.popupTitle}>{user.dialogInfo.message}</p>
        <div style={{ padding: "0px 0px 20px 20px" }} className="popup-btn">
          <Button
            variant="outlined"
            className={classes.outlined}
            onClick={() => {
              user.dialogInfo.handleNo();
            }}
          >
            No
          </Button>
          <Button
            variant="contained"
            className={classes.contained}
            disabled={user.dialogInfo.isDisabled}
            onClick={() => {
              user.dialogInfo.handleYes();
            }}
          >
            Yes
          </Button>
        </div>
      </Dialog>
    </aside>
  );
}

Sidenav.propTypes = {
  allData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allData: state.data,
  AppsData: state.AppsData,
});

const mapDispatchToProps = { updateDialogInfo };

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
