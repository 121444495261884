// Import required libraies
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Import Custom Component
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import noDataImage from "../../../assets/images/undraw_empty.svg";

// Import action creators
import { updateAlertInfo, updateUserInfo } from "../../redux/actions";

// Import utils/data
import { config } from "../../config/config";
import { makeDefaultResponseJson } from "../../utils/utils";

// Import styles
import "./AIDEDataAssets.scss";
import Loader from "../../components/Loader/Loader";

function AIDEDataassetsIframe(props) {
  let { match, user, updateUserInfo } = props;

  const [loading, setLoading] = useState(true);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  // Get iframe url for a particular dataset
  useEffect(() => {
    let datasetIdSelected = match?.params?.datasetId;
    if (datasetIdSelected) {
      let url = `${config.api.dataAssetsIframeUrl}?email=${user.preferred_username}&dataset_id=${datasetIdSelected}`;
      setLoading(true);
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            return response.json();
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return { ...makeDefaultResponseJson(), error, errorCode };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (json.errorCode) {
            updateUserInfo({ error: json.error, errorCode: json.errorCode });
            setLoading(false);
            setErrorMsg(json.error);
          } else {
            setIframeSrc(json.data?.iframeUrl);
            setLoading(false);
          }
        });
      return () => {};
    }
  }, []);

  return (
    <>
      <div className={`${user.theme} page`}>
        <Header match={match} />
        <section className="body">
          {loading ? (
            <div className="data-assets-loader-container">
              <Loader />
            </div>
          ) : iframeSrc ? (
            <div className="data-assets-iframe-container">
              <iframe
                src={iframeSrc}
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen={true}
                scrolling="yes"
              ></iframe>
            </div>
          ) : (
            <div className="no-assets-iframe-data">
              <img src={noDataImage} />
              <p className="no-data">
                <b>
                  {errorMsg || "Something went wrong. Please try again later."}
                </b>
              </p>
            </div>
          )}
        </section>
        <Footer match={match} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AIDEDataassetsIframe);
