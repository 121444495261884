import React from "react";
import { CssTextField, StyledAutoComplete, useStyles } from "./style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AutoCompleteComponent = ({
    value = "",
    options,
    label,
    handleChange,
    keyField,
}) => {
    const classes = useStyles();
    return (
        <StyledAutoComplete
            blurOnSelect
            className={classes.typographyStyle}
            classes={{
                paper: classes.dropdownList,
            }}
            disableClearable
            value={value}
            options={options}
            getOptionLabel={(option) => option.value || option}
            onChange={(event, newValue) => {
                handleChange(keyField, newValue);
            }}
            popupIcon={<ExpandMoreIcon />}
            renderInput={(params) => (
                <CssTextField
                    {...params}
                    variant="standard"
                    size="small"
                    placeholder={`${label}`}
                    className={classes.typographyStyle}
                />
            )}
        />
    );
};

export default AutoCompleteComponent;
