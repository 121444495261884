import { Tooltip } from "@mui/material";
import React from "react";
import "../index.scss";

const Card = ({
  icon,
  name,
  handleChange,
  data = [],
  keyField,
  defaultList,
  value,
  theme,
}) => {
  return (
    <Tooltip title={name} placement="top" arrow>
      <div
        className={`GA-function-card setup-card-function ${theme}-setup-card ${
          data.includes(name) ||
          (name === "Others" &&
            value &&
            !defaultList.includes(value) &&
            data.includes(value))
            ? "setup-card-selected"
            : ""
        }`}
        title={name}
        onClick={() => handleChange(keyField, name)}
        id={name}
      >
        <img
          className="setup-card-function-icon GA-function-card"
          src={icon}
          alt=""
          id={name}
        />
        <div className="setup-card-function-heading GA-function-card" id={name}>
          {/* {name.length > 10 ? `${name.slice(0, 10)}...` : name} */}
          {name}
        </div>
        {name === "Others" &&
          value &&
          !defaultList.includes(value) &&
          data.includes(value) && (
            <div
              className="setup-card-function-subheading"
              title={value.length > 18 ? value : ""}
            >
              {value}
            </div>
          )}
      </div>
    </Tooltip>
  );
};

export default Card;
