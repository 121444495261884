import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import PropTypes from "prop-types";
import Plotly from "plotly.js-dist";
import createPlotlyComponent from "react-plotly.js/factory";
// import { v4 } from "uuid";

const Plot = createPlotlyComponent(Plotly);

const BarChart = ({ graphData, colors = "#FC007F", theme, monthStatus }) => {
  const { labels, daily_count, uniqueView, range, yRange } = graphData;
  const dailyWidth = 24 * 50 * 60000 * 0.47;
  const monthlyWidth = 30 * 24 * 50 * 60000 * 0.47;
  const width = monthStatus ? monthlyWidth : dailyWidth;
  
  const data = [
    {
      x: labels,
      y: uniqueView,
      type: "bar",
      name: "Unique Views",
      line: { color: theme === "dark" ? "#fff" : "#D9DEE2" },
      marker: {
        color: theme === "dark" ? "#D9DEE2" : "#D9DEE2",
        line: {
          width: 2.5,
          color: theme === "dark" ? "#D9DEE2" : "#D9DEE2",
        },
        size: 3,
      },
      width,
      hovertemplate: monthStatus
        ? "<b>Date</b>: %{x|%b %Y}<br><b>Unique Monthly Views</b>: %{y}<br><extra></extra>"
        : "<b>Date</b>: %{x}<br><b>Unique Views</b>: %{y}<br><extra></extra>",
    },
    {
      x: labels,
      y: daily_count,
      name: "Total Views",
      type: "bar",
      line: { color: theme === "dark" ? "#fff" : colors },
      marker: {
        color: theme === "dark" ? "#fff" : colors,
        line: {
          width: 2.5,
          color: theme === "dark" ? "#fff" : colors,
        },
        size: 5,
      },
      width,
      bargap: 0.9,
      hovertemplate: monthStatus
        ? "<b>Date</b>: %{x|%b %Y}<br><b>Monthly Views</b>: %{y}<br><extra></extra>"
        : "<b>Date</b>: %{x}<br><b>Daily Views</b>: %{y}<br><extra></extra>",
    },
    {
      x: labels,
      y: labels.map(
        (_, index) => 0.25 * daily_count[index] + 0.75 * uniqueView[index]
      ),
      type: "scatter",
      mode: "lines",
      line: { dash: "dot", color: "#0058AA", width: 2 },
      name: "Growth",
      hoverinfo: "y",
    },
  ];
  const layout = {
    // bargap: 0.5,
    // bargroupgap: 0.05,
    barmode: 'group',
    offset: [0, 5],
    hoverlabel: {
      // bgcolor: "rgba(0,0,0,0.87)",
      // color: "#fff",
      font: { size: 11 },
    },
    plot_bgcolor: theme === "dark" ? "#282828" : "#fff",
    paper_bgcolor: theme === "dark" ? "#282828" : "#fff",
    font: 11,
    color: "#282828",
    family: "Energy",
    margin: {
      t: 50,
    },
    legend: {
      x: 0.75,
      y: 1.1,
      xanchor: "left",
      yanchor: "top",
      orientation: "h", // 'h' for horizontal, 'v' for vertical
      font: {
        family: "Energy",
        size: 11,
        color: theme === "dark" ? "#DDDDDD" : "#282828",
      },
      clickmode: "none",
    },

    yaxis: {
      range: yRange,
      // range: [0, 40],
      // dtick: 25,
      title: {
        text: "Views",
        font: {
          // color: "#DDDDDD",
          color: theme === "dark" ? "#DDDDDD" : "#8d9ca6",
          family: "Energy",
        },
      },
      // fixedrange: true,
      linecolor: "#DDDDDD",
      linewidth: 2,
      // showticksuffix: "all",
      // ticksuffix: "%",
      showline: true,
      showgrid: false,
      ticks: "outside",
      tickcolor: "#DDDDDD",
      tickwidth: 2,
      ticklen: 8,
      tickfont: {
        family: "Energy",
        size: 11,
        color: theme === "dark" ? "#DDDDDD" : "#282828",
        fontWeight: 500,
      },
    },
    xaxis: {
      range,
      // tickmode: "auto",
      title: {
        text: "Time Range",
        font: {
          color: theme === "dark" ? "#DDDDDD" : "#8d9ca6",
          family: "Energy",
        },
      },
      type: "date",
      // showline: true,
      linecolor: "#DDDDDD",
      linewidth: 2,
      // fixedrange: true,
      showgrid: false,
      // showline: false,
      showline: theme === "dark",
      ticks: "outside",
      tickcolor: "#DDDDDD",
      tickwidth: 2,
      ticklen: 8,
      tickfont: {
        family: "Energy",
        size: 11,
        color: theme === "dark" ? "#DDDDDD" : "#282828",
        fontWeight: 500,
      },
      tickvals: labels, // Set the tick values to your desired labels
      tickformat: monthStatus ? "%b %Y" : null,
    },
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Plot
        data={data}
        layout={layout}
        config={{
          displayModeBar: true,
          responsive: true,
          displaylogo: false,
          modeBarButtonsToRemove: ["lasso2d", "select2d"],
        }}
        style={{
          height: "100%",
          fontFamily: "Energy",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "18px",
          cursor: "default",
          // legend: { traceorder: 'reversed' },
        }}
      />
    </div>
  );
};

BarChart.propTypes = {
  graphData: PropTypes.any.isRequired,
  // colors: PropTypes.arrayOf(PropTypes.string),
  // theme: PropTypes.string,
};

export default BarChart;
