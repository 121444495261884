// Import required libraries
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 } from "uuid";

// Import styles
import "./RecommendedAppCard.scss";
// Import styles
import "../AppRow/AppRow.scss";

// Import custom components

// Import utils & data
import { getElementContentWidth } from "../../utils/utils";
import RecommendedAppCard from "./RecommendedAppCard";

// Import Assets
import reward from "../../../assets/images/reward.png";
import RAC_failed from "../../../assets/images/RAC_failed.svg";
import Loader from "../Loader/Loader";

function RACRow(props) {
  let { data, user, loadStatus, handleRefresh, handleRemove } = props;
  const [allExpand, setAllExpand] = useState({
    expanded: false,
    value: "Show All",
  });
  // Defining required states
  const containerId = useRef(`appRow_${v4()}`);
  const [sliceCount, setSliceCount] = useState();

  // Defining required side effects
  useEffect(() => {
    // Defining required function
    const handleWindowSize = () => {
      // Should come from config
      const appCardWidth = 240;
      const appRowGridGap = 8;

      // Getting slice count based on width
      const appRowEl = document.getElementById(containerId.current);
      const appRowContentWidth = getElementContentWidth(appRowEl);
      const newSliceCount = Math.floor(
        appRowContentWidth / (appCardWidth + appRowGridGap)
      );

      // Setting slice count
      setSliceCount(newSliceCount);
    };

    // Do this only when singleRowFlag is true
    if (!allExpand.expanded) {
      // Call the function when component mounts
      handleWindowSize();

      // Add event listener to window resize
      window.addEventListener("resize", handleWindowSize);
    }

    return () => {
      // Remove event listener to window resize
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);

  // width: 120px;
  // grid-gap: 15px;

  const ToggleAllExpand = () => {
    if (!allExpand.expanded) {
      setAllExpand({ expanded: true, value: "Show Less" });
    }
    if (allExpand.expanded) {
      setAllExpand({ expanded: false, value: "Show All" });
    }
  };

  return (
    <div
      style={{ marginBottom: "30px" }}
      className="app-row-container-style"
      id={containerId.current}
    >
      <div className="row-header-container-style">
        <div className="recommended-apps-header">
          <img style={{ height: "30px" }} src={reward} alt="Apps" />
          <p
            style={{
              color: user.ui_preferences.theme === "light" ? "#000" : "#fff",
              fontWeight: 300,
              fontSize: "16px",
              margin: "0px 0px 0px 10px",
              alignItems: "center",
            }}
          >
            Apps you might be interested in
          </p>
        </div>
        {data.length > sliceCount && (
          <p
            style={{ margin: "0px" }}
            className={`show-app-title-style ${user.ui_preferences.theme} GA-expand-collapse-row`}
            onClick={() => {
              ToggleAllExpand();
            }}
          >
            {allExpand.value}
          </p>
        )}
      </div>
      {loadStatus === "success" && (
        <div
          className={`applications-row-grid-style-RAC ${user.ui_preferences.theme}-RAC`}
        >
          {(allExpand.expanded ? data : data.slice(0, sliceCount)).map(
            (row) => (
              <RecommendedAppCard
                key={row.app_id}
                app={row}
                handleRemove={handleRemove}
              />
            )
          )}
        </div>
      )}
      {loadStatus === "error" && (
        <div className={`error-styles-RAC ${user.ui_preferences.theme}-RAC`}>
          <img src={RAC_failed} alt="failed" />
          <div style={{ marginLeft: "20px" }}>
            <p
              style={{
                margin: "0px",
                color:
                  user.ui_preferences.theme === "light" ? "#282828" : "white",
              }}
            >
              Something went wrong !
            </p>
            <button
              style={{
                marginRight: "40px",
                width: "85px",
                height: "25px",
                fontSize: "12px",
                borderRadius: "50px",
                background: "#FC007F",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
              onClick={handleRefresh}
            >
              <span
                class={`material-icons-round`}
                style={{ fontSize: "16px", color: "white" }}
              >
                refresh
              </span>
              Refresh
            </button>
          </div>
        </div>
      )}
      {loadStatus === "loading" && (
        <div className={`error-styles-RAC ${user.ui_preferences.theme}-RAC`}>
          {" "}
          <Loader />
        </div>
      )}
    </div>
  );
}

RACRow.propTypes = {
  data: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  // handleRefreshCard: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  AllApps: state.AppsData.allApps,
});

export default connect(mapStateToProps)(RACRow);
