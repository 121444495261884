import ReactGA from "react-ga";
import storeFactory from "../redux/storeFactory";

function getGACustomDimensions(props) {
    const { currTab = "", app_id = "", upload_for } = props;
    return {
        dimension1: currTab,
        // add other custom dimensions in this object like shown below
        dimension2: upload_for,
        dimension3: app_id,
    };
}

const useAnalyticsEventTracker = (category = "", app_id = "") => {
    const allStates = storeFactory.getState((state) => state);
    const currentTab = allStates.changeFlag.currentTab.split("/");
    const eventTracker = (action = "", label = "", upload_for) => {
        ReactGA.set(
            getGACustomDimensions({
                currTab: currentTab[1],
                app_id,
                upload_for,
            })
        );
        ReactGA.event({ category, action, label });
    };
    return eventTracker;
};
export default useAnalyticsEventTracker;

// import ReactGA from "react-ga";
// import storeFactory from "../redux/storeFactory";

// const useAnalyticsEventTracker = (category = "Test category",app_id) => {
//     const allStates = storeFactory.getState((state) => state);
//     const currentTab = allStates.changeFlag.currentTab;
//     const eventTracker = (action = "test action", label = "test label") => {
//         ReactGA.event({ category, action, label });
//     };
//     return eventTracker;
// };
// export default useAnalyticsEventTracker;
