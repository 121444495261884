//Import required libraries
import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import WorkInProgress from "../WorkInProgress/WorkInProgress";
import ViewsComments from "../../screens/BIAppComponent/ViewsComments";
import powerbi_logo_black from "../../../assets/images/powerbi_logo_black.svg";
import powerbi_logo_white from "../../../assets/images/powerbi_logo_white.svg";

// Import action creators
import {
  refreshComments,
  updateUserInfo,
  updateAlertInfo,
  updateDialogInfo,
  updated_total_views,
} from "../../redux/actions";

// Import Hooks
// import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

// Import styles
import "./AppReport.scss";

// Import Images
import otherApp from "../../../assets/images/OtherApp.svg";
import { getNormalText } from "../../utils/utils";
import PowerbiEmbedding from "./PowerbiEmbedding";
// import { set } from "lodash";

// const useStyles = makeStyles({
//   list: {
//     width: 500,
//   },
//   fullList: {
//     width: "auto",
//   },
//   outlined: {
//     backgroundColor: "#282828",
//     color: "white",
//     marginRight: 10,
//     fontSize: 14,
//     padding: 10,
//     height: 35,
//     minWidth: 15,
//     width: "30%",
//     textTransform: "capitalize",
//     float: "right",
//     fontFamily: "Energy",
//     borderRadius: "20px",
//     border: "1px solid #282828",
//     "&:hover": {
//       backgroundColor: "#e9f7fc",
//       color: "#282828",
//       boxShadow: "none",
//     },
//   },
// });

// PROPS Comments
// * activeReport: contains report-data present in the defined_categories
// * app: contains app data like app_id, access_details, last_modified, etc.
const AppReport = (props) => {
  let { activeReport, app, extraHeight = 0, user } = props;

  const initialIframeDim = {
    width: "100",
    height: "100",
  };
  const dispatch = useDispatch();
  const [iframeDim, setIframeDim] = useState(initialIframeDim);
  const [resize, setResize] = useState(new Date().valueOf());
  const [showIframe, setShowIframe] = useState(true);
  const [showFullScreen, setFullScreen] = useState(false);

  //Refresh the iframe (mount unmount the iframe) so that it refreshes correctly everytime
  useEffect(() => {
    setShowIframe(false);
    setTimeout(() => {
      setShowIframe(true);
    }, 10);
  }, [activeReport]);

  //Window resize event listener
  useEffect(() => {
    const resize = () => {
      setResize(new Date().valueOf());
    };

    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);

  //Resize iframe container based on container
  useEffect(() => {
    if (document.getElementById("iframe-id")) {
      const powerBiContainer = document.getElementById("iframe-id");
      const width = powerBiContainer.offsetWidth;
      const reqHeight = parseInt(powerBiContainer.offsetHeight);
      const height = reqHeight > 0 ? reqHeight : 0;
      setIframeDim({ width, height });
    }
  }, [extraHeight, resize]);

  useEffect(() => {
    if (app.app_id && activeReport.sub_category_id) {
      dispatch(
        updated_total_views({
          app_id: app.app_id,
          sub_category_id: activeReport.sub_category_id,
        })
      );
    }
  }, [app.app_id, activeReport.sub_category_id, dispatch]);

  let fullScreen = {
    position: "fixed",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
    border: "none",
    margin: "0",
    padding: "0",
    overflow: "hidden",
    zIndex: "99",
  };

  let ButtonExpand = {
    position: "absolute",
    float: "right",
    right: "10px",
    bottom: "30px",
    zIndex: "100",
    background: "#FC007F",
  };

  let ButtonCollapse = {
    position: "absolute",
    float: "right",
    right: "10px",
    bottom: "8px",
    zIndex: "100",
  };

  const ToggleFullScreen = () => {
    if (showFullScreen) {
      setFullScreen(false);
      dispatch(
        updateUserInfo({
          ...user,
          report_fullscreen: false,
        })
      );
    } else {
      setFullScreen(true);
      dispatch(
        updateUserInfo({
          ...user,
          report_fullscreen: true,
        })
      );
    }
  };

  const OpenStandaloneURL = (url, name) => {
    try {
      window.open(url, name);
    } catch (error) {
      console.log("ERROR: ", error.message);
    }
  };

  useEffect(() => {
    if (
      activeReport.bi_application === "Other Applications" &&
      activeReport.url
    ) {
      OpenStandaloneURL(activeReport.url, activeReport.sub_category_name);
    }
  }, [activeReport.sub_category_id]);

  const get_reportID = (url) => {
    const parser = document.createElement("a");
    parser.href = url;
    const searchParams = new URLSearchParams(parser.search);
    const reportId = searchParams.get("reportId");
    return reportId;
  };

  const get_statusFlag = (url) => {
    const parser = document.createElement("a");
    parser.href = url;
    const searchParams = new URLSearchParams(parser.search);
    const statusFlag = searchParams.get("navContentPaneEnabled");
    if (
      statusFlag !== null &&
      (statusFlag.toLowerCase() === "true" ||
        statusFlag.toLowerCase() === "false")
    ) {
      return statusFlag.toLowerCase() === "true" ? true : false;
    } else {
      return true;
    }
  };

  return (
    <div id="power-bi-report-iframe-container">
      {showIframe && (
        <>
          <div id="iframe-id" className="iframe-container">
            {activeReport.bi_application === "Other Applications" &&
            activeReport.url ? (
              // this runs for Other_application URL: opens in new tab
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  <img
                    style={{
                      height: "200px",
                      marginBottom: "20px",
                    }}
                    src={otherApp}
                    alt="Other Application"
                  />
                  <button
                    onClick={() => {
                      OpenStandaloneURL(
                        activeReport.url,
                        activeReport.sub_category_name
                      );
                    }}
                  >
                    Open Application in new tab
                  </button>
                </div>
              </>
            ) : activeReport.bi_application !== "Other Applications" &&
              activeReport.url ? (
              // this runs for for all other apps like bi_apps, sharepoint, google studio, etc.

              activeReport.bi_application === "Power BI" ? (
                <>
                  <PowerbiEmbedding
                    reportID={get_reportID(activeReport.url)}
                    frameHeight={iframeDim.height}
                    showFullScreen={showFullScreen}
                    navContentPaneEnabled={get_statusFlag(activeReport.url)}
                  />
                </>
              ) : (
                <>
                  <iframe
                    style={showFullScreen ? fullScreen : {}}
                    width="100%"
                    height={iframeDim.height}
                    src={activeReport.url}
                    frameBorder="0"
                    allowFullScreen={true}
                    title={getNormalText(activeReport.sub_category_name)}
                  ></iframe>
                </>
              )
            ) : (
              <WorkInProgress />
            )}
          </div>
        </>
      )}

      {activeReport.bi_application !== "Other Applications" && (
        <button
          style={!showFullScreen ? ButtonExpand : ButtonCollapse}
          className="GA-fullscreen"
          title={!showFullScreen ? "Expand Screen" : "Collapse Screen"}
          onClick={ToggleFullScreen}
        >
          <span class="material-icons-outlined GA-fullscreen">
            {!showFullScreen ? "fullscreen" : "fullscreen_exit"}
          </span>
        </button>
      )}
      {app.app_details?.show_commentsviews ? (
        <>
          <ViewsComments
            app={app}
            activeReport={activeReport}
            OpenStandaloneURL={OpenStandaloneURL}
          />
        </>
      ) : (
        activeReport.bi_application === "Power BI" && (
          <div
            style={{ paddingLeft: "10px", paddingTop: "10px" }}
            className={`trouble-signin ${user.ui_preferences.theme}-text`}
          >
            <img
              alt="Power BI logo Black"
              src={`${
                user.ui_preferences.theme === "dark"
                  ? powerbi_logo_white
                  : powerbi_logo_black
              }`}
            ></img>
            <div
              className={`trouble-signin ${user.ui_preferences.theme}-text`}
              onClick={() =>
                OpenStandaloneURL(
                  activeReport.url,
                  activeReport.sub_category_name
                )
              }
            >
              Power BI : Trouble Signing-in?
              <span>Click here</span>
            </div>
          </div>
        )
      )}
    </div>
  );
};

AppReport.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateUserInfo,
  refreshComments,
  updateAlertInfo,
  updateDialogInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppReport);
