import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import MessageIcon from "@mui/icons-material/Message";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";

// action & utils
import { refresh_user_access_list } from "../../../redux/actions";
import { formatDate } from "../../../utils/utils";
//styles
import { StyledTableRow, StyledTableCell } from "../style";
import "./style.scss";
import { CssTextField } from "../../../components/UserTable/style";
import {
  HtmlTooltip,
  StyledAutoComplete,
  useStyles as useStylesAuto,
} from "../../Register/style";

function fetchAPI(payload) {
  const url = "/api/requestaccess";
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const TableBodyComponent = ({
  data,
  handleChange,
  userInfo,
  theme,
  refreshed_user_access_list,
  setUsersList,
  tab = "manage_profile",
  app_id = "",
  setReloadList,
  setWait,
  accessList,
}) => {
  const { approval_meta, requester_meta } = data;
  const [waiting, setWaiting] = useState(false);
  const dispatch = useDispatch();
  const classes = useStylesAuto();
  const [access_level, setAccess_level] = useState("");

  const handleUpdateRole = (val) => setAccess_level(val);

  const handleRequest = async (approval_status, data) => {
    if (tab === "access_management") {
      setReloadList(true);
      setWait(true);
    }
    setWaiting(true);
    let payload = {
      ...data,
      requester_meta: {
        ...data.requester_meta,
        access_level,
      },
      approval_meta: {
        approval_status,
        approved_on: moment().valueOf(),
        approved_by: userInfo.preferred_username,
      },
    };
    if (data.requester_meta?.app_type === "ml_app") {
      payload = {
        ...payload,
        requester_meta: {
          ...payload.requester_meta,
          security_filters: [
            {
              brands_list: ["All"],
              country: "All",
            },
          ],
        },
      };
    }
    try {
      const res = await fetchAPI(payload);
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200") {
        dispatch(
          refresh_user_access_list({
            logged_in_user: userInfo.preferred_username,
            role: userInfo.role,
          })
        );
        if (tab === "access_management") {
          setWait(false);
        }
      } else {
        setWaiting(false);
        toast.error("Opps! unable to update the request. Please try later.");
        if (tab === "access_management") {
          setWait(false);
        }
      }
    } catch (error) {
      toast.error("Opps! unable to update the request. Please try later.");
      setWaiting(false);
      if (tab === "access_management") {
        setWait(false);
      }
    }
  };

  useEffect(() => {
    if (requester_meta.access_level) {
      setAccess_level(requester_meta.access_level);
    }
  }, [requester_meta.access_level]);

  function filterLatestPendingRecords(records) {
    const latestPendingRecords = {};

    for (const record of records) {
      const requestedBy = record.requester_meta.requested_by;
      const requestedOn = record.requester_meta.requested_on;
      const approvalStatus = record.approval_meta.approval_status;

      // Check if there is already a record for the requested_by email
      if (!latestPendingRecords[requestedBy]) {
        latestPendingRecords[requestedBy] = record;
      } else {
        // Check if the current record has a later requested_on date and is pending
        if (
          requestedOn >
            latestPendingRecords[requestedBy].requester_meta.requested_on &&
          approvalStatus === "pending"
        ) {
          latestPendingRecords[requestedBy] = record;
        }
      }
    }

    // Filter out records with 'denied' approval status for each user
    const result = Object.values(latestPendingRecords).filter(
      (record) => record.approval_meta.approval_status === "pending"
    );

    return result;
  }

  useEffect(() => {
    if (refreshed_user_access_list.status === "success") {
      if (tab === "access_management") {
        const newData = refreshed_user_access_list.data.filter(
          (item) => item.requester_meta.app_id === app_id
          // &&
          // item.approval_meta.approval_status === "pending"
        );
        const resultArray = filterLatestPendingRecords(newData);
        setUsersList(resultArray);
        setWaiting(false);
      } else {
        setUsersList(refreshed_user_access_list.data);
        setWaiting(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshed_user_access_list.status]);

  return (
    <>
      <StyledTableRow hover tabIndex={-1}>
        {tab === "manage_profile" && (
          <StyledTableCell
            onClick={() => handleChange(requester_meta.app_name)}
            title={requester_meta.app_name}
            sx={{ textAlign: "center" }}
          >
            {requester_meta.app_name?.length > 15
              ? `${requester_meta.app_name?.slice(0, 15)}...`
              : requester_meta.app_name}
          </StyledTableCell>
        )}
        <StyledTableCell
          title={requester_meta.requested_by}
          sx={{ textAlign: "center" }}
        >
          {requester_meta.requested_by?.length > 25
            ? `${requester_meta.requested_by?.slice(0, 25)}...`
            : requester_meta.requested_by}
        </StyledTableCell>
        <StyledTableCell
          sx={{
            textAlign: "center",
            opacity: requester_meta.requestors_comment.length ? 1 : 0.5,
          }}
        >
          {requester_meta.requestors_comment?.length ? (
            <HtmlTooltip
              placement="top"
              title={<div>{requester_meta.requestors_comment}</div>}
            >
              <MessageIcon sx={{ fontSize: "20px" }} />
            </HtmlTooltip>
          ) : (
            <MessageIcon sx={{ fontSize: "20px" }} />
          )}
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: "center" }}>
          {formatDate(requester_meta.requested_on, "MMM DD, YYYY")}
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: "center" }}>
          {approval_meta.approved_on
            ? formatDate(approval_meta.approved_on, "MMM DD, YYYY")
            : "N/A"}
        </StyledTableCell>
        <StyledTableCell
          className={`${theme}-profile-text ${
            approval_meta?.approval_status !== "pending"
              ? "profile-hide-icon"
              : "profile-show-icon"
          }`}
        >
          <StyledAutoComplete
            blurOnSelect
            className={classes.typographyStyle}
            classes={{
              paper: classes.capitalOptions,
            }}
            style={{
              width: "80px",
            }}
            disableClearable
            value={access_level}
            options={["owner", "user", "admin"]}
            disabled={approval_meta?.approval_status !== "pending"}
            onChange={(event, newValue) => {
              handleUpdateRole(newValue);
            }}
            renderInput={(params) => (
              <CssTextField
                {...params}
                variant="standard"
                size="small"
                placeholder={`Type role`}
                className={classes.typographyStyle}
              />
            )}
          />
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: "center" }}>
          {approval_meta?.approval_status === "pending" ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                justifyContent="center"
              >
                {waiting ? (
                  <Loader />
                ) : (
                  <>
                    <button
                      className="profile-btn-approve profile-btn"
                      onClick={() => handleRequest("approved", data)}
                    >
                      <CheckIcon />
                    </button>
                    <button
                      className="profile-btn-cancel profile-btn"
                      onClick={() => handleRequest("denied", data)}
                    >
                      <ClearIcon />
                    </button>
                  </>
                )}
              </Box>
            </>
          ) : approval_meta?.approval_status === "approved" ? (
            <div className="profile-status profile-status-approve">
              Access Granted
            </div>
          ) : approval_meta?.approval_status === "denied" ? (
            <div className="profile-status profile-status-reject">
              Access Denied
            </div>
          ) : null}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

const mapStateTopProps = (state) => ({
  refreshed_user_access_list:
    state.userAccessManagement.refreshed_user_access_list,
});
export default connect(mapStateTopProps)(TableBodyComponent);
