import { makeStyles, TextField, withStyles } from "@material-ui/core";
import { InputBase, styled, TableRow } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontFamily: "Energy",
    backgroundColor: "#f3f3f3",
    // backgroundColor: 'rgba(230 233 238)',
    borderRadius: "4px",
    borderColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "23px",
    padding: "4px 8px",
    border: "transparent",
  },
  "&:focus": {},
}));

export const CssTextField = withStyles((theme) => ({
  root: {
    width: "90%",
    backgroundColor: "transparent",
    padding: "3px 10px",
    borderRadius: "4px",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#dedede",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#dedede",
      },
      "&:hover fieldset": {
        borderColor: "#dedede",
      },
      "&.Mui-focused fieldset": {
        border: "0.5px solid #dedede",
      },
    },
  },
}))(TextField);
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    padding: "6px 16px",
    borderRight: theme.palette.mode==="dark"?"1px solid #757575":"1px solid #e7e9ee",
  },
  "&:nth-of-type(even)": {
    // backgroundColor: "#F5F6F8",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.main : "transparent",
    borderBottom: "1px solid #E7E9EE",
  },
  "&:nth-of-type(odd)": {
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.main : "transparent",
    borderBottom: "1px solid #E7E9EE",
  },
  "&.MuiTableRow-hover": {
    "& .edit-btn": {
      display: "none",
    },
    "& svg": {
      opacity: 0,
    },
    "&:hover": {
      "&:nth-of-type(even)": {
        // backgroundColor: "#F5F6F8",
        backgroundColor:
          theme.palette.mode === "dark" ? theme.palette.dark.main : "#ECEDF1",
      },
      "&:nth-of-type(odd)": {
        // backgroundColor: "#fff",
        backgroundColor:
          theme.palette.mode === "dark" ? theme.palette.dark.main : "#ECEDF1",
      },
      // backgroundColor: "#FBEDF2",
      "& .edit-btn": {
        display: "inline-flex",
        marginLeft: "30px",
        color: "#fc007f",
        fontsize: "18px",
        "& .material-icons": {
          fontSize: "20px",
        },
      },
      "& svg": {
        opacity: 1,
      },
    },
  },
}));

export const useStyles1 = makeStyles((theme) => ({
  tableRowCell: {
    fontFamily: "Energy !important",
    fontSize: "12px !important",
    fontWeight: "400",
    color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e !important",
    backgroundColor:
      theme.palette.mode === "dark" ? "transparent" : "transparent",
    "&.MuiTableCell-root": {
      padding: "6px 16px",
      borderBottom: theme.palette.mode==="dark"?"1px solid #757575":"1px solid #e7e9ee",
    },
    // cursor: "pointer !important",
  },
  tableHeadCell: {
    fontFamily: "Energy !important",
    fontSize: "13px !important",
    fontWeight: "400 !important",
    color:
      theme.palette.mode === "dark"
        ? "#ffffff !important"
        : "#000000 !important",
    backgroundColor:
      theme.palette.mode === "dark"
        ? " #282828 !important"
        : " #ffffff !important",
    "&.MuiTableCell-root": {
      padding: "6px 16px",
      borderBottom: theme.palette.mode==="dark"?"1px solid #e7e9ee":"1px solid #757575",
      borderRight: theme.palette.mode==="dark"?"1px solid #757575":"1px solid #E7E9EE",
    },
    // color: "#282828 !important",
    // backgroundColor: "#E6E9EE !important",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#ECEDF1 !important",
      // backgroundColor: "#FCFCFC !important",
      color: "#4e4e4e !important",
    },
  },
  pagination: {
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.main : "transparent",
    // },
    "& .MuiTablePagination-selectLabel": {
      fontFamily: "Energy",
      fontSize: "12px",
      // color: "#282828",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
    },
    "& .MuiTablePagination-select": {
      fontFamily: "Energy",
      fontSize: "12px",
      // color: "#282828",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
    },
    "& .MuiTablePagination-displayedRows": {
      fontFamily: "Energy",
      fontSize: "12px",
      // color: "#282828",
      color:
        theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
    },
    "& .MuiTablePagination-actions": {
      "& .MuiSvgIcon-root": {
        fontSize: "0.9rem !important",
      },
    },
  },
  search: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "4px",
    marginLeft: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  menu: {
    boxShadow: "none",
    "& .MuiMenuItem-root": {
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "23px",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      boxShadow: "none",
      padding: "2px 20px",
    },
    "& .MuiPaper-root": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.dark.light
          : theme.palette.white.main,
    },
  },
  fixedWidth: {
    maxWidth: "100px",
  },
  rolePadding: {
    padding: "6px 12px",
    textTransform: "capitalize",
  },
  provisingAccess: {
    "& .MuiTableCell-root": {
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "23px",
      padding: "8px",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    },
  },
  userId: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "400",
    // fontSize: "12px",
    lineHeight: "20px",
    // color: "#282828",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.mode === "dark" ? theme.palette.white.main : "#4e4e4e",
  },
  menuStyle: {
    "& .MuiMenuItem-root": {
      backgroundColor: "#FBEDF2",
      color: theme.palette.dark.main,
      "&:hover": {
        backgroundColor: "#FBEDF2",
      },
    },
  },
  inputField: {
    flex: 1,
    "& .MuiInputBase-input": {
      width: "100% !important",
    },
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    width: "140px",
    height: "10px",
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "23px",
    borderRadius: "4px",
    color: "#282828",
  },
  "& .Mui-disabled": {
    cursor: "pointer",
  },
}));

export const paperStyle = {
  width: "100%",
  overflow: "hidden",
  borderRadius: "0px",
  boxShadow: "none",
  backgroundColor: "transparent",
};

export const menuStyle = {
  backgroundColor: "#FBEDF2",
  color: "#000000 !important",
  "&:hover": {
    backgroundColor: "#FBEDF2",
  },
  textTransform: "capitalize",
};
export const defaultStyle = {
  textTransform: "capitalize",
};
