import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import CreateLayout from "./CreateLayout";
import AppTemplate from "./AppTemplate";

import {
  updateReportTemplate,
  update_appData,
  update_statusData,
} from "../../../redux/actions";
import {
  getDefaultTempData,
  getTemplateNumberInString,
} from "../Components/DashboardTemplate/utils";
import { isEqualObject } from "../../../utils/utils";
import templateDataHardcoded from "../Components/TemplateSelector/TemplateData";

const LayoutSelection = (props) => {
  const {
    templates,
    selectedTemplate,
    setSelectedTemplate,
    register_template,
    template_no,
    isFieldsEditable,
    template_val,
    validationMsg,
    handleSaveTagsDatasets,
    draft_app,
    handleDraftSubmit,
    appFields,
    appDetails,
    visited,
    // setActiveStepper,
    categoryInfoLocal,
    templateData,
    setOpen = () => {},
    setAlertMsg = () => {},
    setDraftApp = () => {},
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!visited.category) {
      if (appFields.app_details?.defined_categories?.length) {
        dispatch(
          updateReportTemplate({
            templateData: appFields.app_details.defined_categories,
            template_no: appFields.app_details.chosen_template,
            temp_img_data: appDetails.data?.app_details?.temp_img_data || {},
          })
        );
      }
      // } else {
      //   const template = 3;
      //   dispatch(
      //     updateReportTemplate({
      //       template_no: template,
      //       templateData: getDefaultTempData(
      //         getTemplateNumberInString(template)
      //       ),
      //       temp_img_data: {},
      //     })
      // );
      if (template_val > 0 && template_val < 6) {
        dispatch(
          update_appData({
            visited: { ...visited, category: true },
          })
        );
      }
    }
  }, [template_val]);

  useEffect(() => {
    if (
      Object.keys(templateData).length !== 0 &&
      Object.keys(categoryInfoLocal).length !== 0 &&
      !isEqualObject(categoryInfoLocal, templateData)
    ) {
      dispatch(update_statusData({ dirty: true }));
    } else {
      dispatch(update_statusData({ dirty: false }));
    }
  }, [categoryInfoLocal, templateData]);

  useEffect(() => {
    if (template_no !== undefined) {
      setSelectedTemplate(template_no);
    }
  }, [template_no]);

  // Load the template images for layout selections
  templateDataHardcoded.forEach((template) => {
    const img = new Image();
    img.src = template.template_img.light;
  });

  templateDataHardcoded.forEach((template) => {
    const img = new Image();
    img.src = template.template_img.dark;
  });

  return !selectedTemplate ? (
    <CreateLayout
      templates={templates}
      setSelectedTemplate={setSelectedTemplate}
      selectedTemplate={selectedTemplate}
      // NEW
      isFieldsEditable={isFieldsEditable}
      validationMsg={validationMsg}
      handleSaveTagsDatasets={handleSaveTagsDatasets}
      draft_app={draft_app}
      handleDraftSubmit={handleDraftSubmit}
      register_template={register_template}
      // setActiveStepper={setActiveStepper}
    />
  ) : (
    <AppTemplate
      setSelectedTemplate={setSelectedTemplate}
      selectedTemplate={selectedTemplate}
      template_no={template_no}
      isFieldsEditable={isFieldsEditable}
      setOpen={setOpen}
      setAlertMsg={setAlertMsg}
      setDraftApp={setDraftApp}
    />
  );
};

const mapStateToProps = (state) => ({
  templateData: state.appTemplate.appTemplateState.templateData,
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  selectedWorkspace: state.registerNewApp.BIappDetails.selectedWorkspace,
  template_val:
    state.registerNewApp.appData.appFields.app_details?.chosen_template,
  appDetails: state.AppsData.appDetails,
  appFields: state.registerNewApp.appData.appFields,
  visited: state.registerNewApp.appData.visited,
});

export default connect(mapStateToProps)(LayoutSelection);
