import React, { useState } from "react";
import { Grid } from "@mui/material";
import _ from "lodash";
import InputFiled from "../InputField";
import "../../index.scss";
import { useDispatch, connect } from "react-redux";
import { update_BIappDetails } from "../../../../redux/actions";

const CategoryField = ({
  data,
  // setCategoryInfo,
  categoryInfo,
  id,
  defaultValue,
  isVibrating,
  isFieldCardRed,
  // setCategoryInfoLocal,
  categoryInfoLocal,
  catIndex,
  setCatData,
  SetisCatReportvibrating,
  SetisCatReportTextFieldRed,
  URLValid,
}) => {
  const dispatch = useDispatch();
  const [vibrateField, setVibrateField] = useState(false);
  const [isTextFieldRed, setTextFieldRed] = useState(false);
  const value = categoryInfoLocal[catIndex].sub_category_name || "";
  var CatgeoryReportUrl;
  if (categoryInfoLocal[catIndex]?.CatHyperlinks?.isHyperlinkActive === true) {
    CatgeoryReportUrl = categoryInfoLocal[catIndex]?.CatHyperlinks?.url || "";
  } else {
    CatgeoryReportUrl = "NA";
  }

  const handleChange = (key, val) => {
    if (val && val.length > 50) return;
    const reqData = {
      ...categoryInfoLocal[catIndex],
      sub_category_name: val,
    };
    // setCategoryInfoLocal(setCatData(categoryInfoLocal, catIndex, reqData));
    dispatch(
      update_BIappDetails({
        categoryInfoLocal: setCatData(categoryInfoLocal, catIndex, reqData),
      })
    );
  };

  const removeVibration = () => {
    setTimeout(() => {
      setVibrateField(false);
      setTextFieldRed(true);
    }, 1000);
  };
  const removeVibrationv2 = () => {
    setTimeout(() => {
      SetisCatReportTextFieldRed(false);
      SetisCatReportvibrating(false);
    }, 1000);
  };

  const handleDone = () => {
    if (!value) {
      setVibrateField(true);
      removeVibration();
      return;
    }
    if (!CatgeoryReportUrl || URLValid.status === "Invalid") {
      SetisCatReportTextFieldRed(true);
      SetisCatReportvibrating(true);
      removeVibrationv2();
      return;
    }
    const reqData = {
      ...categoryInfo[catIndex],
      sub_category_name: value,
      isCatEditable: false,
    };
    // setCategoryInfo(setCatData(categoryInfo, catIndex, reqData));
    dispatch(
      update_BIappDetails({
        categoryInfo: setCatData(categoryInfo, catIndex, reqData),
      })
    );
  };

  const handleClose = () => {
    if (!defaultValue) {
      // setCategoryInfo([
      //   ...categoryInfo.slice(0, catIndex),
      //   ...categoryInfo.slice(catIndex + 1),
      // ]);
      // setCategoryInfoLocal([
      //   ...categoryInfoLocal.slice(0, catIndex),
      //   ...categoryInfoLocal.slice(catIndex + 1),
      // ]);
      dispatch(
        update_BIappDetails({
          categoryInfo: [
            ...categoryInfo.slice(0, catIndex),
            ...categoryInfo.slice(catIndex + 1),
          ],
          categoryInfoLocal: [
            ...categoryInfoLocal.slice(0, catIndex),
            ...categoryInfoLocal.slice(catIndex + 1),
          ],
        })
      );
    } else {
      const reqData = {
        ...categoryInfo[catIndex],
        isCatEditable: false,
      };
      // setCategoryInfo(setCatData(categoryInfo, catIndex, reqData));
      // setCategoryInfoLocal(setCatData(categoryInfoLocal, catIndex, reqData));
      dispatch(
        update_BIappDetails({
          categoryInfo: setCatData(categoryInfo, catIndex, reqData),
          categoryInfoLocal: setCatData(categoryInfoLocal, catIndex, reqData),
        })
      );
    }
  };

  return (
    <Grid container alignItems="flex-end">
      <InputFiled
        data={data}
        handleChange={handleChange}
        value={value}
        onCatInputBlur={handleDone}
        isFieldVibrating={Boolean((vibrateField || isVibrating) && !value)}
        isFieldRed={Boolean((isTextFieldRed || isFieldCardRed) && !value)}
      />
      <Grid item alignItems="flex-end" sx={{ mx: "24px" }}>
        {/* cross button */}
        <button
          className={`btn-cancel ${isVibrating && "shake-category-field"}`}
          onClick={handleClose}
          style={{ width: "50px" }}
        >
          <span class="material-icons-outlined">close</span>
        </button>
        {/* Tick button */}
        <button
          className={`btn-done ${isVibrating && "shake-category-field"}`}
          onClick={handleDone}
          style={{ width: "50px" }}
        >
          <span class="material-icons-outlined">done</span>
        </button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
});

export default connect(mapStateToProps)(CategoryField);
