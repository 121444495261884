//Import required libraries
import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

// Import Custom Component
import { Loader } from "../../components/Loader/Loader";

//Import assets/images
import no_access from "../../../assets/images/no_access.svg";
import RequestAccessModal from "../../components/RequestAccessModal/RequestAccessModal";

const noAccessClass = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px",
};

const NoAccess = (props) => {
  const { user, allApps, appName = "", appId = "", status, appDetails } = props;
  const [reqState, setReqState] = useState("pending");
  const [openRA, setOpenRA] = useState(false);

  const handleCloseRA = () => {
    setOpenRA(false);
  };

  return (
    <div style={noAccessClass}>
      <img src={no_access} alt="Don't have access"></img>
      <h3 style={{ color: "#FC007F", textTransform: "capitalize" }}>
        {appDetails?.app_meta?.appName}
      </h3>
      <p style={{ color: user.ui_preferences.theme === "light" ? "#282828" :"#fff", margin: "0px" }}>
        You don’t have access for this application !
      </p>
      {reqState === "loading" && <Loader message="Requesting ..." />}
      {(reqState === "pending" || reqState === "error") && (
        <button
          style={{ background: "#FC007F", marginTop: "10px", width: "250px" }}
          onClick={() => {
            setOpenRA(true);
          }}
        >
          Request Access
        </button>
      )}
      {reqState === "success" && (
        <h4 style={{ color: "#FC007F" }}>Request sent to Owner. </h4>
      )}
      {openRA && (
        <RequestAccessModal
          openRA={openRA}
          setOpenRA={setOpenRA}
          handleClose={handleCloseRA}
          appDetails={appDetails}
          reqState={reqState}
          setReqState={setReqState}
        />
      )}
    </div>
  );
};

NoAccess.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allApps: state.AppsData.allApps,
});

export default connect(mapStateToProps)(NoAccess);
