import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { v4 } from "uuid";
import CategoryCard from "./CategoryCard";
import CategoryField from "./CategoryField";
import CategoryFieldReport from "./CategoryFieldReport";
import ReportField from "./ReportField";
import ReportCard from "./ReportCard";
import SubCategoryField from "./SubCategoryField";
import SubCategoryFieldReport from "./SubCategoryFieldReport";
import SubCategoryCard from "./SubCategoryCard";
import { DefaultStyleSwitch, StyleSwitch, useStyles } from "../../style";
import "../../index.scss";
import { set } from "lodash";
import { update_BIappDetails } from "../../../../redux/actions";
import {
  isUrlMatch,
  updateCategoryReportState,
  updateSubCategoryReportState,
  updateSubState,
} from "../../../../utils/utils";
import { is } from "immutable";
import { connect, useDispatch } from "react-redux";

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: "4px 16px",
});

const getSubcatListStyle = (isDraggingOver) => ({
  padding: "8px 0px",
  margin: "4px 0px 0px 14px",
});

const AddSubCategory = ({ handleAddMore, theme }) => {
  return (
    <Box my={1}>
      <button
        className={`add-category-btn ${theme}-color`}
        onClick={handleAddMore}
      >
        <span class="material-icons" style={{ marginRight: "10px" }}>
          library_add
        </span>
        Add Sub-Category
      </button>
    </Box>
  );
};

const AddReport = ({ handleAddMore, theme, color }) => {
  return (
    <Box ml="12px" my={1}>
      <button
        style={{
          color:
            color === "#219793" ? color : theme === "dark" ? "#fff" : color,
        }}
        className={`add-category-btn ${theme}-color`}
        onClick={handleAddMore}
      >
        <span class="material-icons" style={{ marginRight: "10px" }}>
          library_add
        </span>
        Add Report
      </button>
    </Box>
  );
};

const CategoryDetails = (props) => {
  const {
    data,
    fields,
    categoryInfo,
    initailDefaultValues,
    catIndex,
    isFieldsEditable,
    theme,
    isFieldContainerVibratable,
    isFieldCardRed,
    setIsFieldCardRed,
    categoryInfoLocal,
    getSubmitableData,
    setCatData,
    setSubCatData,
    removeField,
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { sub_category, isCatEditable, sub_category_id } = data;
  const [isSubCatReportvibrating, SetisSubCatReportvibrating] = useState(false);
  const [isSubCatReportTextFieldRed, SetisSubCatReportTextFieldRed] =
    useState(false);
  const [SubcatURLValid, setSubcatURLValid] = useState({
    status: "pending",
    msg: "",
  });
  sub_category.map((item, subCatIndex) => {
    if (
      categoryInfoLocal[catIndex].sub_category[subCatIndex]?.SubCatHyperlinks
        ?.isHyperlinkActive === undefined
    ) {
      const reqData = { isHyperlinkActive: false };
      // setCategoryInfoLocal(
      //   updateSubCategoryReportState(
      //     categoryInfoLocal,
      //     catIndex,
      //     subCatIndex,
      //     reqData
      //   )
      // );
      dispatch(
        update_BIappDetails({
          categoryInfoLocal: updateSubCategoryReportState(
            categoryInfoLocal,
            catIndex,
            subCatIndex,
            reqData
          ),
        })
      );
    }
  });

  const handleSubCatSwitchv2 = (value, subCatIndex) => {
    const reqData = {
      isHyperlinkActive: value,
    };
    // setCategoryInfoLocal(
    //   updateSubCategoryReportState(
    //     categoryInfoLocal,
    //     catIndex,
    //     subCatIndex,
    //     reqData
    //   )
    // );
    dispatch(
      update_BIappDetails({
        categoryInfoLocal: updateSubCategoryReportState(
          categoryInfoLocal,
          catIndex,
          subCatIndex,
          reqData
        ),
      })
    );
  };
  // ====================== SUBcat-MODIFICATION ======================

  // ====================== CAT-MODIFICATION ======================
  const [isCatReportvibrating, SetisCatReportvibrating] = useState(false);
  const [isCatReportTextFieldRed, SetisCatReportTextFieldRed] = useState(false);
  const [URLValid, setURLValid] = useState({ status: "pending", msg: "" });
  const [CatHyperLinkv2, setCatHyperlinkv2] = useState(() => {
    if (
      categoryInfoLocal[catIndex]?.CatHyperlinks?.isHyperlinkActive !==
      undefined
    ) {
      return categoryInfoLocal[catIndex]?.CatHyperlinks?.isHyperlinkActive;
    } else {
      const reqData = {
        isHyperlinkActive: false,
      };
      // setCategoryInfoLocal(
      //   updateCategoryReportState(categoryInfoLocal, catIndex, reqData)
      // );
      dispatch(
        update_BIappDetails({
          categoryInfoLocal: updateCategoryReportState(
            categoryInfoLocal,
            catIndex,
            reqData
          ),
        })
      );
      return false;
    }
  });

  const handleCatSwitchv2 = (value, myid) => {
    setCatHyperlinkv2(!CatHyperLinkv2);
    const reqData = {
      isHyperlinkActive: !CatHyperLinkv2,
    };
    // setCategoryInfoLocal(
    //   updateCategoryReportState(categoryInfoLocal, catIndex, reqData)
    // );
    dispatch(
      update_BIappDetails({
        categoryInfoLocal: updateCategoryReportState(
          categoryInfoLocal,
          catIndex,
          reqData
        ),
      })
    );
  };
  // ====================== CAT-MODIFICATION ======================

  const initialSubCategory = {
    sub_category_id: v4(),
    sub_category_name: "",
    isSubCatEditable: true,
    sub_category: [
      {
        sub_category_id: v4(),
        sub_category_name: "",
        sub_category_icon: "space_dashboard",
        bi_application: "Power BI",
        url: "",
        isSubCatEditable: true,
        blob_name: "",
        app_logo_type: "google_font",
        view_count: 0,
      },
    ],
  };
  const initialReport = {
    sub_category_id: v4(),
    sub_category_name: "",
    sub_category_icon: "space_dashboard",
    bi_application: "Power BI",
    url: "",
    isSubCatEditable: true,
    blob_name: "",
    app_logo_type: "google_font",
    view_count: 0,
  };

  const getFields = (value) => fields?.find((item) => item.key === value);

  const setNewData = (data, index, newData) => {
    return [
      ...data.slice(0, index),
      {
        ...data[index],
        sub_category: [...data[index].sub_category, newData],
      },
      ...data.slice(index + 1),
    ];
  };

  const newDataOnAddnewReport = (data, catIndex, subCatIndex, newData) => {
    return [
      ...data.slice(0, catIndex),
      {
        ...data[catIndex],
        sub_category: [
          ...data[catIndex].sub_category.slice(0, subCatIndex),
          {
            ...data[catIndex].sub_category[subCatIndex],
            sub_category: [
              ...data[catIndex].sub_category[subCatIndex].sub_category,
              newData,
            ],
          },
          ...data[catIndex].sub_category.slice(subCatIndex + 1),
        ],
      },
      ...data.slice(catIndex + 1),
    ];
  };

  const handleAddMoreSubcategory = () => {
    if (isFieldCardRed) setIsFieldCardRed(false);
    const { infoState, localState } = getSubmitableData(
      categoryInfo,
      categoryInfoLocal
    );
    const initialData = {
      ...initialSubCategory,
      sub_category: [
        {
          ...initialSubCategory.sub_category[0],
        },
      ],
    };
    const index = infoState.findIndex(
      (item) => item.sub_category_id === sub_category_id
    );
    // setCategoryInfo(setNewData(infoState, index, initialData));
    // setCategoryInfoLocal(setNewData(localState, index, initialData));
    dispatch(
      update_BIappDetails({
        categoryInfoLocal: setNewData(localState, index, initialData),
        categoryInfo: setNewData(infoState, index, initialData),
      })
    );
  };
  const handleAddMoreReport = () => {
    if (isFieldCardRed) setIsFieldCardRed(false);
    const newSubCatData = {
      ...initialReport,
      ...initailDefaultValues,
    };
    const { infoState, localState } = getSubmitableData(
      categoryInfo,
      categoryInfoLocal
    );
    const index = infoState.findIndex(
      (item) => item.sub_category_id === sub_category_id
    );
    // setCategoryInfo(setNewData(infoState, index, newSubCatData));
    // setCategoryInfoLocal(setNewData(localState, index, newSubCatData));
    dispatch(
      update_BIappDetails({
        categoryInfoLocal: setNewData(localState, index, newSubCatData),
        categoryInfo: setNewData(infoState, index, newSubCatData),
      })
    );
  };

  const handleAddReport = (subCatIndex) => {
    if (isFieldCardRed) setIsFieldCardRed(false);
    const newSubCatData = {
      ...initialReport,
      ...initailDefaultValues,
    };
    const { infoState, localState } = getSubmitableData(
      categoryInfo,
      categoryInfoLocal
    );
    const catIndex = infoState.findIndex(
      (item) => item.sub_category_id === sub_category_id
    );
    // setCategoryInfo(
    //   newDataOnAddnewReport(infoState, catIndex, subCatIndex, newSubCatData)
    // );
    // setCategoryInfoLocal(
    //   newDataOnAddnewReport(localState, catIndex, subCatIndex, newSubCatData)
    // );

    dispatch(
      update_BIappDetails({
        categoryInfoLocal: newDataOnAddnewReport(
          localState,
          catIndex,
          subCatIndex,
          newSubCatData
        ),
        categoryInfo: newDataOnAddnewReport(
          infoState,
          catIndex,
          subCatIndex,
          newSubCatData
        ),
      })
    );
  };

  return (
    <>
      <Draggable
        key={sub_category_id}
        draggableId={sub_category_id + "__cat"}
        index={catIndex}
        type="draggable_category"
        isDragDisabled={isFieldsEditable}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <>
              <div {...provided.dragHandleProps}>
                {isCatEditable ? (
                  <>
                    <CategoryField
                      data={getFields("category_name")}
                      catIndex={catIndex}
                      id={sub_category_id}
                      defaultValue={data.sub_category_name}
                      isVibrating={isFieldContainerVibratable && isCatEditable}
                      isFieldCardRed={isFieldCardRed && isCatEditable}
                      setCatData={setCatData}
                      SetisCatReportvibrating={SetisCatReportvibrating}
                      SetisCatReportTextFieldRed={SetisCatReportTextFieldRed}
                      URLValid={URLValid}
                    />
                    <div
                      style={{
                        marginTop: "4px",
                        // marginBottom: "8px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          textDecoration: "underline",
                          color: CatHyperLinkv2 ? "#8d9ca6" : "#fc007f",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          handleCatSwitchv2(e.target.checked, sub_category_id)
                        }
                      >
                        {CatHyperLinkv2
                          ? "Remove Hyperlink from Category"
                          : "Add Hyperlink to Category"}
                      </span>
                    </div>

                    <CategoryFieldReport
                      catIndex={catIndex}
                      isVibrating={isFieldContainerVibratable && isCatEditable}
                      isFieldCardRed={isFieldCardRed && isCatEditable}
                      CatHyperLink={CatHyperLinkv2}
                      isCatReportvibrating={isCatReportvibrating}
                      isCatReportTextFieldRed={isCatReportTextFieldRed}
                      URLValid={URLValid}
                      setURLValid={setURLValid}
                    />
                  </>
                ) : (
                  <CategoryCard
                    {...data}
                    isFieldsEditable={isFieldsEditable}
                    setIsFieldCardRed={setIsFieldCardRed}
                    catIndex={catIndex}
                    setCatData={setCatData}
                  />
                )}
              </div>
              <Droppable
                droppableId={sub_category_id + "__cat"}
                type={`droppable_sub_category`}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {sub_category.map((item, i) =>
                      item.hasOwnProperty("url") ? (
                        <>
                          <Draggable
                            key={item.sub_category_id}
                            draggableId={item.sub_category_id + "__rep"}
                            index={i}
                            type="draggable_report"
                            isDragDisabled={isFieldsEditable}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {item.isSubCatEditable ? (
                                  <ReportField
                                    catIndex={catIndex}
                                    reportIndex={i}
                                    templates={getFields("sub_category")}
                                    sub_category_id={
                                      data.sub_category[0].sub_category_id
                                    }
                                    id={item.sub_category_id}
                                    defaultValue={item}
                                    isVibrating={
                                      isFieldContainerVibratable &&
                                      item.isSubCatEditable
                                    }
                                    isFieldCardRed={
                                      isFieldCardRed && item.isSubCatEditable
                                    }
                                    setSubCatData={setSubCatData}
                                    removeField={removeField}
                                  />
                                ) : (
                                  <div {...provided.dragHandleProps}>
                                    <ReportCard
                                      data={item}
                                      CategoryId={sub_category_id}
                                      isFieldsEditable={isFieldsEditable}
                                      setIsFieldCardRed={setIsFieldCardRed}
                                      catIndex={catIndex}
                                      subCatIndex={-1}
                                      reportIndex={i}
                                      setSubCatData={setSubCatData}
                                      removeField={removeField}
                                      marginLeft="0px"
                                      borderColor="#282828"
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </Draggable>
                        </>
                      ) : (
                        <>
                          <Draggable
                            key={item.sub_category_id}
                            draggableId={item.sub_category_id + "__subcat"}
                            index={i}
                            type="draggable_sub_category"
                            isDragDisabled={isFieldsEditable}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <>
                                  <div {...provided.dragHandleProps}>
                                    {item.isSubCatEditable ? (
                                      <>
                                        <SubCategoryField
                                          catIndex={catIndex}
                                          subCatIndex={i}
                                          templates={getFields("sub_category")}
                                          sub_category_id={item.sub_category_id}
                                          id={item.sub_category_id}
                                          defaultValue={item}
                                          isVibrating={
                                            isFieldContainerVibratable &&
                                            item.isSubCatEditable
                                          }
                                          isFieldCardRed={
                                            isFieldCardRed &&
                                            item.isSubCatEditable
                                          }
                                          setSubCatData={setSubCatData}
                                          removeField={removeField}
                                          URLValid={SubcatURLValid}
                                          setURLValid={setSubcatURLValid}
                                          isSubCatReportvibrating={
                                            isSubCatReportvibrating
                                          }
                                          isSubCatReportTextFieldRed={
                                            isSubCatReportTextFieldRed
                                          }
                                          SetisSubCatReportTextFieldRed={
                                            SetisSubCatReportTextFieldRed
                                          }
                                          SetisSubCatReportvibrating={
                                            SetisSubCatReportvibrating
                                          }
                                        />

                                        <div
                                          style={{
                                            marginTop: "4px",
                                            // marginBottom: "8px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              textDecoration: "underline",
                                              color:
                                                categoryInfoLocal[catIndex]
                                                  .sub_category[i]
                                                  ?.SubCatHyperlinks
                                                  ?.isHyperlinkActive || false
                                                  ? "#8d9ca6"
                                                  : "#fc007f",
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) =>
                                              handleSubCatSwitchv2(
                                                !(
                                                  categoryInfoLocal[catIndex]
                                                    .sub_category[i]
                                                    ?.SubCatHyperlinks
                                                    ?.isHyperlinkActive || false
                                                ),
                                                i
                                              )
                                            }
                                          >
                                            {categoryInfoLocal[catIndex]
                                              .sub_category[i]?.SubCatHyperlinks
                                              ?.isHyperlinkActive || false
                                              ? "Remove Hyperlink from Subcategory"
                                              : "Add Hyperlink to Subcategory"}
                                          </span>
                                        </div>

                                        <SubCategoryFieldReport
                                          catIndex={catIndex}
                                          subCatIndex={i}
                                          isVibrating={
                                            isFieldContainerVibratable &&
                                            item.isSubCatEditable
                                          }
                                          isFieldCardRed={
                                            isFieldCardRed &&
                                            item.isSubCatEditable
                                          }
                                          SubCatHyperLink={
                                            categoryInfoLocal[catIndex]
                                              .sub_category[i]?.SubCatHyperlinks
                                              ?.isHyperlinkActive
                                          }
                                          URLValid={SubcatURLValid}
                                          setURLValid={setSubcatURLValid}
                                          isSubCatReportvibrating={
                                            isSubCatReportvibrating
                                          }
                                          isSubCatReportTextFieldRed={
                                            isSubCatReportTextFieldRed
                                          }
                                        />
                                      </>
                                    ) : (
                                      <SubCategoryCard
                                        data={item}
                                        isFieldsEditable={isFieldsEditable}
                                        setIsFieldCardRed={setIsFieldCardRed}
                                        catIndex={catIndex}
                                        subCatIndex={i}
                                        setCatData={setCatData}
                                      />
                                    )}
                                  </div>
                                  <Droppable
                                    droppableId={
                                      item.sub_category_id + "__subcat"
                                    }
                                    type={`droppableSubCategory`}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        style={getSubcatListStyle(
                                          snapshot.isDraggingOver
                                        )}
                                      >
                                        {item.sub_category.map(
                                          (report, index) => (
                                            <Draggable
                                              key={report.sub_category_id}
                                              draggableId={
                                                report.sub_category_id + "__rep"
                                              }
                                              type="draggable_report"
                                              index={index}
                                              isDragDisabled={isFieldsEditable}
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                      .style
                                                  )}
                                                >
                                                  <>
                                                    {report.isSubCatEditable ? (
                                                      <>
                                                        <ReportField
                                                          catIndex={catIndex}
                                                          reportIndex={index}
                                                          subCatIndex={i}
                                                          templates={getFields(
                                                            "sub_category"
                                                          )}
                                                          sub_category_id={
                                                            item.sub_category_id
                                                          }
                                                          id={
                                                            report.sub_category_id
                                                          }
                                                          defaultValue={report}
                                                          isVibrating={
                                                            isFieldContainerVibratable &&
                                                            report.isSubCatEditable
                                                          }
                                                          isFieldCardRed={
                                                            isFieldCardRed &&
                                                            report.isSubCatEditable
                                                          }
                                                          setSubCatData={
                                                            setSubCatData
                                                          }
                                                          removeField={
                                                            removeField
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      <div
                                                        {...provided.dragHandleProps}
                                                      >
                                                        <ReportCard
                                                          data={report}
                                                          CategoryId={
                                                            sub_category_id
                                                          }
                                                          isFieldsEditable={
                                                            isFieldsEditable
                                                          }
                                                          setIsFieldCardRed={
                                                            setIsFieldCardRed
                                                          }
                                                          reportIndex={index}
                                                          catIndex={catIndex}
                                                          subCatIndex={i}
                                                          setSubCatData={
                                                            setSubCatData
                                                          }
                                                          removeField={
                                                            removeField
                                                          }
                                                          marginLeft="10px"
                                                          borderColor="#219793"
                                                        />
                                                      </div>
                                                    )}
                                                  </>
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                  {
                                    // showSubCategoryBtn() &&
                                    !isFieldsEditable && (
                                      <>
                                        <AddReport
                                          handleAddMore={() => {
                                            handleAddReport(i);
                                          }}
                                          color="#219793"
                                          theme={theme}
                                        />
                                        {/* <Divider
                                              sx={{
                                                borderStyle: "dashed",
                                                borderBottomWidth: "1px",
                                                color: "#cccccc",
                                                mb: 2,
                                              }}
                                            /> */}
                                      </>
                                    )
                                  }
                                </>
                                {provided.placeholder}
                              </div>
                            )}
                          </Draggable>
                        </>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {
                // showSubCategoryBtn() &&
                !isFieldsEditable && (
                  <>
                    <div style={{ display: "flex" }}>
                      <AddSubCategory
                        handleAddMore={handleAddMoreSubcategory}
                        theme={theme}
                      />
                      <AddReport
                        handleAddMore={handleAddMoreReport}
                        color="#282828"
                        theme={theme}
                      />
                    </div>
                    <Divider
                      sx={{
                        borderStyle: "solid",
                        borderBottomWidth: "1px",
                        borderColor: "#3b4151",
                        mb: 2,
                      }}
                    />
                  </>
                )
              }
            </>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    </>
  );
};

const mapStateToProps = (state) => ({
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
});

export default connect(mapStateToProps)(CategoryDetails);
