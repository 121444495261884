import React from "react";
import { Radio, Grid } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "../index.scss";
import { v4 } from "uuid";

const RadioBtns = (props) => {
    const {
        data,
        value,
        handleChange,
        width,
        appInfo,
        isFieldsEditable,
        isAdminRegistering,
        theme = "light",
    } = props;
    const { label, options, key, required } = data;

    const getRadioData = (data) => {
        if (key === "app_type") {
            return (
                <div>
                    <div className="Radio-labels">{data.label}</div>
                    <div
                        className="desciption"
                        style={{ marginBottom: "18px" }}
                    >
                        {data.description}
                    </div>
                </div>
            );
        }
        return <div className="desciption">{data}</div>;
    };

    return (
        <Grid item xs={width || 12} key={key} sx={{ pb: "12px" }}>
            <FormControl component="fieldset">
                <FormLabel
                    component="legend"
                    className="heading"
                    sx={{ pt: "8px" }}
                >
                    {label}{" "}
                    <span style={{ display: required ? "" : "none" }}>*</span>:
                </FormLabel>
                <RadioGroup
                    value={value || null}
                    onChange={(e) => handleChange(key, e.target.value)}
                    row={key !== "app_type"}
                    // className={
                    //     key === "app_type" ? "btn-radio" : "btn-radio-default"
                    // }
                    className={`btn-radio-default ${theme}-btn-radio`}
                >
                    {options.map((item) => (
                        <FormControlLabel
                            key={v4()}
                            value={item.key || item}
                            disabled={
                                // disabled for other than these values
                                !(
                                    item.key === "ml_app" ||
                                    item.key === "bi_app" ||
                                    item === "Python - Flask" ||
                                    item === "Airflow" ||
                                    item === "Databricks" ||
                                    item === "H2O" ||
                                    item === "Firestore" ||
                                    item === "None" ||
                                    item === "Yes" ||
                                    item === "No"
                                ) ||
                                appInfo ||
                                isFieldsEditable ||
                                isAdminRegistering
                            }
                            control={<Radio size="small" color="default" />}
                            label={getRadioData(item)}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Grid>
    );
};

export default RadioBtns;
