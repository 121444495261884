import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
} from "@mui/material";
import { makeStyles, withStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import UsageMetricsTableHeader from "./UsageMetricsTableHeader";
import UsageMetricsTableBody from "./UsageMetricsTableBody";
import { AccessManagementSkeletons } from "../../screens/Register/components/Skeletons";
import {
  StyledAutoComplete,
  CssTextField,
  useStyles,
} from "../../screens/NewRegister/style";
import Loader from "../Loader/Loader";
import noApps from "../../../assets/images/noapps.svg";
import noAppsDark from "../../../assets/images/noapps-dark.svg";

import { useStylesfoUM } from "./style";

function fetchUsageMetricsAPI(filter, subfilter, signal) {
  const url = `/api/allusagemetrics?filter=${filter}&subfilter=${subfilter}`;
  return fetch(url, {
    signal,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function UsageMetricsComponent(props) {
  const { monthKeys, monthShort, registerTemplate, user } = props;
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("app_rank");
  const [order, setOrder] = useState("asc");
  const [rows, setRows] = useState([]);
  const [apiData, setApiData] = useState(null);
  const [filter, setFilter] = useState("initial");
  const [showFilter, setShowFilter] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const abortController = useRef(null);
  const [loading, setLoading] = useState(false);
  const [filterbasedon, setFilterbasedon] = useState("All Apps");
  const [subfilterbasedon, setSubfilterbasedon] = useState("all_apps");

  const dispatch = useDispatch();

  const options = [
    "All Apps",
    "App Type",
    "App Category",
    "Global Business Unit",
  ];

  let app_type_options = [];
  let app_category_options = [];
  let gbu_options = [];
  registerTemplate?.data?.search_template.fields.map((item) => {
    if (item.key === "app_type") {
      app_type_options = item.options;
    } else if (item.key === "app_category") {
      app_category_options = item.options;
    } else if (item.key === "line_of_business") {
      gbu_options = item.options;
    }
  });

  const columns = [
    {
      id: "app_rank",
      label: "App Rank",
      minWidth: 110,
      type: "number",
    },
    {
      id: "app_name",
      label: "App Name",
      minWidth: 150,
      type: "string",
    },
    {
      id: "total_views",
      label: "Total Views",
      minWidth: 85,
      type: "number",
    },

    {
      id: "total_views_last",
      label: "Views",
      minWidth: 120,
      type: "number",
    },
    {
      id: "total_views_current",
      label: "Views",
      minWidth: 85,
      type: "number",
    },
    {
      id: "total_users",
      label: "Total Users",
      minWidth: 85,
      type: "number",
    },
    {
      id: "avg_unique_users_year",
      label: "Avg Unique Users per day for year",
      minWidth: 165,
      type: "number",
    },
    {
      id: "avg_unique_users_month",
      label: "Avg Unique Users per day for month",
      minWidth: 165,
      type: "number",
    },
    {
      id: "owners",
      label: "Owners",
      minWidth: 100,
      type: "string",
    },
    {
      id: "published",
      label: "Published",
      minWidth: 100,
      type: "number",
    },
  ];

  function convertPercentageStringToNumber(percentageString) {
    const cleanedString = percentageString.replace("+", "").replace("%", "");
    const parsedNumber = parseFloat(cleanedString);
    if (isNaN(parsedNumber)) {
      return 0;
    }
    return parsedNumber;
  }

  function createData(tableData) {
    const result = tableData.map((app) => {
      const appData = {
        app_id: app.app_id,
        app_rank: app.rank,
        app_name: app.app_name,
        total_views: app.total_views,
        total_views_last: app.total_monthly_views.hasOwnProperty(monthKeys[1])
          ? [
              app.total_monthly_views[monthKeys[1]].views,
              convertPercentageStringToNumber(app.total_monthly_views.growth),
            ]
          : ["-"],
        total_views_current: app.total_monthly_views.hasOwnProperty(
          monthKeys[0]
        )
          ? app.total_monthly_views[monthKeys[0]].views
          : "-",
        total_users: app.kpi_values.total_visitors,
        avg_unique_users_year: app.kpi_values.unique_user_current_year,
        avg_unique_users_month: app.kpi_values.unique_user_current_month,
        owners: app.owners.length > 0 ? app.owners : ["-"],
        published: app.published_on,
      };
      return appData;
    });
    return result;
  }

  useEffect(() => {
    if (apiData === null) {
      return;
    }
    const initialRows = createData(apiData);
    setRows(initialRows);
  }, [apiData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleNavigate = (path) => {
    history.push(path);
  };

  const HandleOpenApp = (row) => {
    if (window.location.pathname.split("/")[1] === "myapps") {
      handleNavigate("/register/" + row.app_id + "_published/usage-report");
    }
  };

  const updatedColumns = () =>
    columns.map((column) => {
      let updatedLabel = "";
      if (column.id === "total_views_last") {
        updatedLabel = `${column.label} ${monthShort[1]}`;
      } else if (column.id === "total_views_current") {
        updatedLabel = `${column.label} ${monthShort[0]}`;
      } else if (column.id === "avg_unique_users_month") {
        updatedLabel = `${column.label}:${monthShort[0].slice(0, 3)}`;
      } else if (column.id === "avg_unique_users_year") {
        updatedLabel = `${column.label}:${monthShort[0].slice(-4)}`;
      } else {
        return { ...column };
      }
      return { ...column, label: updatedLabel };
    });

  const handleSort = (property) => {
    const isAsc = order === "asc" && orderBy === property;
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedRows = [...rows].sort((a, b) => {
      const asc =
        property === "app_name" || property === "app_rank" ? !isAsc : isAsc;
      const valueA =
        property === "total_views_last" ? a[property][0] : a[property];
      const valueB =
        property === "total_views_last" ? b[property][0] : b[property];
      if (typeof valueA === "number" && typeof valueB === "number") {
        if (valueA === valueB) {
          return asc ? b.app_rank - a.app_rank : a.app_rank - b.app_rank;
        } else {
          return asc ? valueA - valueB : valueB - valueA;
        }
      } else if (typeof valueA === "string" && typeof valueB === "string") {
        const stringA = valueA.toLowerCase();
        const stringB = valueB.toLowerCase();
        if (stringA === stringB) {
          return asc ? b.app_rank - a.app_rank : a.app_rank - b.app_rank;
        } else {
          return asc
            ? stringA.localeCompare(stringB)
            : stringB.localeCompare(stringA);
        }
      } else {
        if (asc) {
          return typeof valueA === "string" ? -1 : 1;
        } else {
          return typeof valueA === "string" ? 1 : -1;
        }
      }
    });
    setRows(sortedRows);
  };

  const handleChange = (e) => setFilter(e.target.value);

  const cancelSearch = () => {
    setFilter("");
  };
  const handleFilter = (e) => {
    setShowFilter(!showFilter);
    setFilter("");
  };

  useEffect(() => {
    if (filter !== "initial") {
      const filterRows = rows.filter((row) => {
        return row.app_name.toLowerCase().includes(filter.toLowerCase());
      });
      setFilteredRows(filterRows);
    }
    setPage(0);
  }, [filter, rows]);

  const getFormattedDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  const getAppTypePayload = (apptype) => {
    if (apptype === "Embedded Analytics") {
      return "bi_app";
    } else if (apptype === "ML App") {
      return "ml_app";
    } else {
      return "standalone_app";
    }
  };

  const handleDropdownChange = (newValue) => {
    setFilterbasedon(newValue);
  };

  const handleSubDropdownChange = (newValue) => {
    setSubfilterbasedon(newValue);
  };

  useEffect(() => {
    setSubfilterbasedon(
      filterbasedon === "Global Business Unit"
        ? gbu_options[0]
        : filterbasedon === "App Type"
        ? app_type_options[0]
        : filterbasedon === "App Category"
        ? app_category_options[0]
        : "all_apps"
    );
  }, [filterbasedon]);

  // sets Usage Metrics table data
  const fetchData = async () => {
    try {
      if (abortController.current) {
        abortController.current.abort();
      }
      setLoading(true);
      abortController.current = new AbortController();
      const res = await fetchUsageMetricsAPI(
        filterbasedon,
        filterbasedon === "App Type"
          ? getAppTypePayload(subfilterbasedon)
          : subfilterbasedon,
        abortController?.current?.signal
      );
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200") {
        setApiData(response.data);
      }
      setLoading(false);
    } catch (error) {
      if (error.name !== "AbortError") {
        toast.error("Oops! Something went wrong. Please try again later.");
        console.error("Error from API:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    setPage(0);
    setOrderBy("app_rank");
    setOrder("asc");
  }, [filterbasedon, subfilterbasedon]);

  const classes = useStylesfoUM();
  const classes1 = useStyles();

  return apiData !== null ? (
    <>
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {" "}
        <div
          style={{
            fontSize: "11px",
            paddingBottom: "1px",
            lineHeight: "18px",
            color: user.ui_preferences.theme === "dark" ? "#fff" : "#000",
          }}
        >
          FILTERS:
        </div>
        <div
          style={{
            width: "20%",
          }}
        >
          <div>
            <StyledAutoComplete
              className={classes1.typographyStyle}
              value={filterbasedon}
              options={options}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                handleDropdownChange(newValue);
              }}
              disableClearable
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  className={classes1.typographyStyle}
                  fullWidth
                  placeholder={`Choose Filter`}
                />
              )}
            />
          </div>
        </div>
        {filterbasedon !== "All Apps" && (
          <div
            style={{
              width: "20%",
            }}
          >
            <StyledAutoComplete
              className={classes1.typographyStyle}
              value={subfilterbasedon}
              // options={filterOptions.map((obj) => obj.label)}
              options={
                filterbasedon === "Global Business Unit"
                  ? gbu_options
                  : filterbasedon === "App Type"
                  ? app_type_options
                  : filterbasedon === "App Category"
                  ? app_category_options
                  : []
              }
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                handleSubDropdownChange(newValue);
              }}
              disableClearable
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  className={classes1.typographyStyle}
                  fullWidth
                  placeholder={`Choose ${filterbasedon}`}
                />
              )}
            />
          </div>
        )}
        {loading && <Loader loader_size={"14px"} margin={"0px"} />}
      </div>

      <Paper
        class={classes.tableContainer}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <TableContainer sx={{ height: `calc(100vh - 314px)` }}>
          <Table stickyHeader="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#E6E9EE" }}>
                {updatedColumns().map((column) => (
                  <UsageMetricsTableHeader
                    column={column}
                    showFilter={showFilter}
                    orderBy={orderBy}
                    filter={filter}
                    handleChange={handleChange}
                    handleFilter={handleFilter}
                    handleSort={handleSort}
                    cancelSearch={cancelSearch}
                    loading={loading}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(showFilter ? filteredRows : rows)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <UsageMetricsTableBody
                      row={row}
                      columns={columns}
                      HandleOpenApp={HandleOpenApp}
                      getFormattedDate={getFormattedDate}
                      monthShort={monthShort}
                    />
                  );
                })}
            </TableBody>
          </Table>
          {((showFilter && filteredRows.length === 0) ||
            (!showFilter && rows.length === 0)) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: `calc(100vh - 410px)`,
                position: "sticky",
                left: "0px",
              }}
            >
              <img
                src={user.ui_preferences.theme === "dark" ? noAppsDark : noApps}
                style={{ width: "500px" }}
              />
            </Box>
          )}
        </TableContainer>
        <TablePagination
          style={{
            fontFamily: "Energy !important ",
          }}
          sx={{
            fontFamily: "Energy !important ",
          }}
          className={classes.pagination}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={showFilter ? filteredRows.length : rows.length}
          labelRowsPerPage="Apps per page :"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  ) : (
    <AccessManagementSkeletons />
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  registerTemplate: state.register.registerTemplate,
});

export default connect(mapStateToProps)(UsageMetricsComponent);
