import React, { useState } from "react";
import {
    Fade,
    IconButton,
    Menu,
    MenuItem,
    TableCell,
    Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { defaultStyle, menuStyle, StyledInputBase, useStyles1 } from "./style";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const filtersOptions = ["All", "owner", "admin", "user"];

const TableHeaderCell = ({
    column,
    filtervalue,
    setFilterValue,
    filterRole,
    setFilterRole,
    app_id,
    filterListWithDate,
    sortingType,
    theme,
}) => {
    const classesTable = useStyles1();
    const eventTracker = useAnalyticsEventTracker("Access Management", app_id);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const open = Boolean(anchorEl);

    const handleCloseMenu = () => setAnchorEl(null);
    const handleChange = (e) => setFilterValue(e.target.value);
    const cancelSearch = () => setFilterValue("");

    const handleFilter = (e, type) => {
        if (type === "role") {
            setAnchorEl(e.currentTarget);
            eventTracker("Filter applied", "role");
            return;
        }
        // setup for GA and tracking only if user clicked on filter to show text field.
        if (showFilter) {
            eventTracker("Filter applied", "user_id");
        }
        setShowFilter(!showFilter);
        setFilterValue("");
    };

    const handleFilterRole = (val) => {
        setFilterRole(val);
        setAnchorEl(null);
    };

    return (
        <>
            <TableCell
                className={`${classesTable.tableHeadCell} ${
                    column.id === "user_id" ? classesTable.fixedWidth : ""
                }`}
                key={column.id}
                align={column.align}
                style={{
                    minWidth: column.minWidth,
                }}
            >
                <Box display="flex" alignItems="center">
                    <div style={{ whiteSpace: "nowrap" }}>{column.label}</div>
                    {(column.id === "role" || column.id === "user_id") && (
                        <span
                            class="material-icons-outlined GA-filter-btn"
                            id={column.id}
                            style={{
                                fontSize: "16px",
                                marginLeft: "10px",
                                color:
                                    column.id === "role" && filterRole !== "All"
                                        ? "#ea3592"
                                        : column.id === "user_id" && filtervalue
                                        ? "#ea3592"
                                        :theme==="dark"?"#fff"
                                        : "#000",
                            }}
                            onClick={(e) => handleFilter(e, column.id)}
                        >
                            {showFilter && column.id === "user_id"
                                ? "close"
                                : "filter_alt"}
                        </span>
                    )}
                    {showFilter && (
                        <>
                            <div className={classesTable.search}>
                                <StyledInputBase
                                    placeholder="Search User"
                                    inputProps={{
                                        "aria-label": "search",
                                    }}
                                    onChange={handleChange}
                                    value={filtervalue}
                                    className={classesTable.inputField}
                                />
                                {filtervalue ? (
                                    <IconButton
                                        onClick={(e) => cancelSearch(e)}
                                    >
                                        <ClearIcon
                                            sx={{
                                                color: "#282828",
                                            }}
                                        />
                                    </IconButton>
                                ) : (
                                    <IconButton>
                                        <SearchIcon
                                            sx={{
                                                color: "#282828",
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </div>
                        </>
                    )}
                    {/* {column.id === "access_granted_on" && (
                        <span
                            class="material-icons-outlined"
                            style={{
                                fontSize: "16px",
                                marginLeft: "10px",
                                color: sortingType ? "#ea3592" : "inherit",
                            }}
                            onClick={filterListWithDate}
                        >
                            {sortingType ? "arrow_downward" : "arrow_upward"}
                        </span>
                    )} */}
                    <Menu
                        MenuListProps={{
                            "aria-labelledby": "fade-button",
                        }}
                        className={classesTable.menu}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                        TransitionComponent={Fade}
                    >
                        {filtersOptions.map((item) => (
                            <MenuItem
                                onClick={() => handleFilterRole(item)}
                                sx={
                                    filterRole === item
                                        ? menuStyle
                                        : defaultStyle
                                }
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </TableCell>
        </>
    );
};

export default TableHeaderCell;
