import { combineReducers } from "redux";
import user from "./user";
import changeFlag from "./changeFlag";
import data from "./data";
import register from "./register";
import AppsData from "./AppsData";
import userAccessManagement from "./userAccessManagement";
import signup from "./signup";
import helpCenter from "./helpCenter";
import appDashboard from "./appDashboard";
import appTemplate from "./templateReducer";
import registerNewApp from "./registerNewApp";
import handleBiEmbedToken from "./handleBiEmbedToken";

export default combineReducers({
  user,
  data,
  changeFlag,
  register,
  AppsData,
  userAccessManagement,
  signup,
  helpCenter,
  appDashboard,
  appTemplate,
  registerNewApp,
  handleBiEmbedToken,
});
