import { makeStyles } from "@material-ui/core";

export const useStylesforTextEditor = makeStyles((theme) => ({
  container: {
    "& .rdw-editor-main": {
      // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
      backgroundColor:
        theme.palette.mode === "dark" ? "#282828" : "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      borderRadius: "5px",
      border: `1px solid ${
        theme.palette.mode === "dark"
          ? "#757575"
          : "rgba(66, 89, 108, 0.25)"
      }`,
      //   boxShadow: "2px 4px 4px rgba(0,0,0,.1)",
      padding: "6px 10px",
      height: "300px",
      boxSizing: "unset",
      overflow: "auto",
    },
    "& .rdw-editor-toolbar": {
      backgroundColor: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      padding: "6px 0px 0px",
      border: "none",
      fontSize: "12px",
      // border: `1px solid ${
      //   theme.palette.mode === "dark" ? "#000000" : "rgba(66, 89, 108, 0.25)"
      // }`,
      //   boxShadow: "2px 4px 4px rgba(0,0,0,.1)",
    },
    "& .rdw-option-wrapper": {
      backgroundColor:
        theme.palette.mode === "dark" ? "#282828" : "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
      border: `1px solid ${
        theme.palette.mode === "dark" ? "#757575" : "rgba(66, 89, 108, 0.25)"
      }`,
      "&:hover": {
        boxShadow: `1px 1px 0px ${
          theme.palette.mode === "dark" ? "transparent" : "#bfbdbd"
        }`,
      },
      "& img": {
        filter: theme.palette.mode === "dark" ? "invert(1)" : "",
      },
    },
    "& .rdw-dropdown-wrapper": {
      backgroundColor:
        theme.palette.mode === "dark" ? "#282828" : "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#282828",
      border: `1px solid ${
        theme.palette.mode === "dark" ? "#757575" : "rgba(66, 89, 108, 0.25)"
      }`,
      "&:hover": {
        boxShadow: `1px 1px 0px ${
          theme.palette.mode === "dark" ? "transparent" : "#bfbdbd"
        }`,
      },
    },
    "& .rdw-dropdown-optionwrapper": {
      backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
      color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    },
    "& .rdw-dropdownoption-highlighted": {
      color: "#4e4e4e",
    },
    "& .rdw-dropdownoption-active": {
      color: "#4e4e4e",
    },
    "& .rdw-colorpicker-modal": {
      backgroundColor: theme.palette.mode === "dark" ? "#282828" : "#fff",
    },
    "& .rdw-colorpicker-modal-style-label": {
      fontSize: "12px",
    },
    "& .public-DraftStyleDefault-block": {
      margin: 0,
    },
    "& .rdw-dropdown-carettoopen": {
      borderTop:
        theme.palette.mode === "dark" ? "6px solid #fff" : "6px solid #4e4e4e",
    },
    "& .rdw-dropdown-carettoclose": {
      borderTop:
        theme.palette.mode === "dark" ? "6px solid #fff" : "6px solid #4e4e4e",
    },
  },
}));
