import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import VerticalBarChart from "./ChartComponent/HorizontalBarChart";
import MonthSelector from "./MonthSelector";
import Chip from "@mui/material/Chip";
import moment from "moment";
import { connect } from "react-redux";
import noData from "../../../../assets/images/register-images/nographdata.svg";
import noDataDark from "../../../../assets/images/register-images/no_charts.svg";
import loadingMap from "../../../../assets/images/register-images/maploading.gif";
import noDataAvailable from "../../../../assets/images/noDataAvailable.svg";
import noDataAvailableDark from "../../../../assets/images/noDataAvailableDark.svg";

const TopUsers = (props) => {
  const { topTenUsers, appDetails, theme, loading } = props;
  const app_published_on = appDetails?.data?.app_meta?.published_on;
  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState([]);
  const [allTime, setAllTime] = useState(true);
  const selectedDate = moment(app_published_on);
  const [year, setYear] = useState(selectedDate.year());
  const [month, setMonth] = useState(selectedDate.month());
  const [monthKeys, setMonthKeys] = useState([]);
  const [monthValues, setMonthValues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (topTenUsers && topTenUsers?.all_time) {
        const allTimeData = topTenUsers.all_time;
        const allTimeKeys = allTimeData.map((user) => user.user_name);
        const allTimeValues = allTimeData.map((user) => user.view_count);
        setKeys(allTimeKeys);
        setValues(allTimeValues);
      }

      if (topTenUsers && topTenUsers?.monthly) {
        const monthlyData = topTenUsers.monthly;
        const selectedMonthKey = `${moment()
          .month(month)
          .format("MMMM")}_${year}`;

        const monthlyUserData = monthlyData[selectedMonthKey.toLowerCase()];
        if (monthlyUserData) {
          const monthlyKeys = monthlyUserData.map((user) => user.user_name);
          const monthlyValues = monthlyUserData.map((user) => user.view_count);

          setMonthKeys(monthlyKeys);
          setMonthValues(monthlyValues);
        } else {
          setMonthKeys([]);
          setMonthValues([]);
          console.log(`No data available for ${selectedMonthKey}`);
        }
      }
    };
    fetchData();
  }, [topTenUsers, year, month]);

  const isSelectedAllTime = allTime;
  const isSelectedMonthly = !allTime;

  const chipStyleAllTime = {
    width: "100px",
    fontFamily: "Energy",
    fontSize: "12px",
    lineHeight: "16px",
    height: "24px",
    letterSpacing: "0em",
    textAlign: "center",
    color: isSelectedAllTime
      ? "#FFFFFF"
      : theme == "light"
      ? "#647887"
      : "#FFFFFF",
    fontWeight: isSelectedAllTime ? "700" : "400",
    backgroundColor: isSelectedAllTime
      ? "#415A6C"
      : theme == "light"
      ? "#FFFFFF"
      : "#282828",
    border: "1px solid #CDD3D9",
  };

  const chipStyleMonthly = {
    width: "100px",
    height: "24px",
    fontFamily: "Energy",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "center",
    color: isSelectedMonthly
      ? "#FFFFFF"
      : theme == "light"
      ? "#647887"
      : "#FFFFFF",
    fontWeight: isSelectedMonthly ? "700" : "400",
    backgroundColor: isSelectedMonthly
      ? "#415A6C"
      : theme == "light"
      ? "#FFFFFF"
      : "#282828",
    border: "1px solid #CDD3D9",
  };
  const allTimeLength =
    topTenUsers && topTenUsers.all_time ? topTenUsers.all_time.length : 0;

  return (
    <Box
      marginTop="16px"
      border={1}
      p={2}
      paddingTop="8px"
      borderColor="#B3BDC4"
      sx={{
        "@media (min-width:1920px)": {
          height: "407px",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ alignItems: "center", height: "40px" }}
      >
        <div>
          <Typography
            fontFamily="Energy"
            fontWeight="600"
            fontSize="14px"
            color={theme === "dark" ? "#fff" : "#000"}
            lineHeight="24px"
          >
            {" "}
            Top 10 Users{" "}
          </Typography>
        </div>
        <div>
          {!allTime && (
            <MonthSelector
              year={year}
              setYear={setYear}
              month={month}
              setMonth={setMonth}
            />
          )}
        </div>
      </Box>
      {allTimeLength > 0 ? (
        <div>
          <div>
            <div style={{ display: "flex", gap: "8px", marginTop: "8px" }}>
              <Chip
                style={chipStyleAllTime}
                label="All Time"
                clickable
                onClick={() => {
                  setAllTime(true);
                }}
              />
              <Chip
                style={chipStyleMonthly}
                label="Monthly"
                clickable
                onClick={() => {
                  setAllTime(false);
                }}
              />
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#D9DEE2",
              border: "0px 0px 1px 0px",
              marginRight: "3px",
            }}
          >
            <Box display="flex" flexDirection="row" marginTop="12px">
              <Box width="50%">
                <Typography
                  fontFamily="Energy"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="24px"
                  color="#415A6C"
                  align="right"
                  paddingLeft="130px"
                  textAlign="left"
                >
                  USER
                </Typography>
              </Box>
              <Box width="50%">
                <Typography
                  fontFamily="Energy"
                  fontWeight="400"
                  fontSize="16px"
                  color="#415A6C"
                  align="right"
                  lineHeight="24px"
                  paddingRight="70px"
                >
                  VISITS
                </Typography>
              </Box>
            </Box>
          </div>

          {loading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <img
                src={loadingMap}
                alt=""
                style={{ height: "280px", marginTop: "12px" }}
              />
            </Box>
          ) : (
            allTime &&
            (keys.length > 0 && values.length > 0 ? (
              <VerticalBarChart keys={keys} values={values} theme={theme} />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ backgroundColor: theme === "light" ? "#fff" : "#282828" }}
              >
                <img
                  src={theme === "dark" ? noDataDark : noData}
                  alt=""
                  style={{ height: "250px" }}
                />
              </Box>
            ))
          )}

          {!loading &&
            !allTime &&
            (monthKeys.length > 0 && monthValues.length > 0 ? (
              <VerticalBarChart
                keys={monthKeys}
                values={monthValues}
                theme={theme}
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ backgroundColor: theme === "light" ? "#fff" : "#282828" }}
              >
                <img
                  src={theme === "dark" ? noDataDark : noData}
                  alt=""
                  style={{ height: "250px", marginTop: "16px" }}
                />
              </Box>
            ))}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: theme === "light" ? " #fff" : "#282828",
            display: "flex",
            alignItems: "center",
            padding: "20px",
            height: "80%",
          }}
        >
          <img
            src={theme === "light" ? noDataAvailableDark : noDataAvailable}
            alt="No Data"
            style={{ height: "250px", margin: "auto" }}
          />
        </div>
      )}
    </Box>
  );
};
const mapStateToProps = (state) => ({
  userInfo: state.user,
  appDetails: state.AppsData.appDetails,
  usage_report_users: state.register.usage_report_users,
  theme: state.user.ui_preferences?.theme,
});
export default connect(mapStateToProps)(TopUsers);
