import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { debounce } from "throttle-debounce";
// import AutoWithAsyncLoad from "../../../../components/AutoCompletes/AutoWithAsyncLoad";
import AutoWithAsyncLoad from "../../../../Register/components/AutoCompletesComponents/AutoWithAsyncLoad";
import Chips from "../../../../Register/components/Chips";
import { getUsersList } from "../../../../../redux/actions";

// Import Styles
import "../../../index.scss";

const MultipleEmailSelect = ({
  values = [],
  handleChange,
  usersList,
  handleDelete,
  userInfo,
  data,
}) => {
  const { label, key, required } = data;
  const dispatch = useDispatch();
  const [userListOptions, setUserListOptions] = useState([]);
  const [activeKey, setActiveKey] = useState("");

  const onFocusField = (k) => {
    setActiveKey(k);
  };

  // const handleGetUsers = (val) => {
  //     if (!val) return;
  //     dispatch(getUsersList({ term: val }));
  // };
  const debounceFunc = debounce(800, (val) => {
    dispatch(getUsersList({ term: val }));
  });

  const handleGetUsers = (val) => {
    if (!val) return;
    debounceFunc(val);
  };
  const handleSelect = (val) => {
    handleChange(key, val.user_id);
  };

  const handleDeleteChip = (chip) => {
    handleChange(key, chip, null, null, "delete");
  };
  useEffect(() => {
    if (usersList.status === "success") {
      setUserListOptions(usersList.data);
    }
  }, [usersList]);

  return (
    <div style={{ width: "100%", marginLeft: "8px" }}>
      <Box display="flex" alignItems="center" className="heading">
        <div>
          {label}{" "}
          <span
            style={{
              display: required ? "" : "none",
            }}
          >
            *
          </span>
          :
        </div>
      </Box>
      <div className="help-center-multiselect">
        {values?.length > 0 &&
          values?.map((item) => (
            <Chips
              key={item}
              handleDelete={handleDeleteChip}
              chip={item}
              // isFieldsEditable={userInfo.preferred_username === item}
            />
          ))}
        <Box width="300px">
          <AutoWithAsyncLoad
            handleSelect={handleSelect}
            list={usersList}
            options={userListOptions}
            handleChange={handleGetUsers}
            my="5px"
            placeholder="Add email"
            onFocusField={onFocusField}
            activeKey={activeKey}
            elementKey={key}
          />
        </Box>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userInfo: state.user,
  usersList: state.AppsData.usersList,
});
export default connect(mapStateToProps)(MultipleEmailSelect);
