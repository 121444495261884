import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import registerIcon from "../../../assets/images/register-images/registered_successfully.svg";
const useStyles = makeStyles((theme) => ({
    contained: {
        borderColor: "#4EAFB3",
        backgroundColor: "#4EAFB3",
        color: "white",
        marginRight: 4,
        fontSize: 11,
        padding: 10,
        height: 23,
        minWidth: 15,
        textTransform: "capitalize",
        fontFamily: "Energy",
        "&:hover": {
            borderColor: "#4EAFB3",
            backgroundColor: "#4EAFB3",
            color: "white",
            boxShadow: "none",
        },
    },
    alertContainer: {
        // minWidth: "400px",
        // maxWidth: "600px",
        padding: "20px",
    },
    title: {
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "15px",
        lineHeight: "25px",
        textAlign: "center",
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
    description: {
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "23px",
        textAlign: "center",
        color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    },
    paper: {
        minWidth: "500px",
    },
}));
export default function RegisterAlert({
    message,
    open = true,
    redirect,
    handleClose,
    draftId,
}) {
    const classes = useStyles();
    return (
        <div>
            <Dialog
                open={open}
                onClose={() => handleClose(redirect)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.paper }}
            >
                <div className={classes.alertContainer}>
                    <Box display="flex" justifyContent="flex-end">
                        <span
                            class="material-icons-outlined"
                            onClick={() => handleClose(redirect)}
                        >
                            close
                        </span>
                    </Box>
                    <DialogContent>
                        <DialogContentText style={{ display: "flex" }}>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                width="75%"
                            >
                                <img
                                    src={registerIcon}
                                    alt=""
                                    style={{ width: "200px" }}
                                />
                            </Box>
                            <Box
                                width="70%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                mx="auto"
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                    className={classes.title}
                                    textAlign="center"
                                >
                                    {message ||
                                        "Congratulations! Your application is successfully Registered!"}
                                </Box>
                                {!draftId && (
                                    <div className={classes.description}>
                                        You will receive an email from us about
                                        further steps once your application is
                                        approved.
                                    </div>
                                )}
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
}
RegisterAlert.propTypes = {
    message: PropTypes.any.isRequired,
    open: PropTypes.any.isRequired,
    redirect: PropTypes.any.isRequired,
    handleClose: PropTypes.any.isRequired,
    draftId: PropTypes.any.isRequired,
};
