import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { paperStyle, useStyles1 } from "./style";
import TableBodyComponent from "./TableBodyComponent";
import TableHeaderCell from "./TableHeaderCell";
import { formatDate } from "../../utils/utils";
import { connect, useDispatch } from "react-redux";
import { getCountryBrands, update_accessManagement } from "../../redux/actions";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const columns = [
  { id: "user_id", label: "User Id", minWidth: 100 },
  { id: "role", label: "User Role", minWidth: 100 },
  { id: "access_granted_by", label: "Access Granted By", minWidth: 100 },
  { id: "access_granted_on", label: "Access Granted On", minWidth: 100 },
];
const columns_for_mlApp = [
  { id: "user_id", label: "User Id", minWidth: 100 },
  { id: "role", label: "User Role", minWidth: 100 },
  { id: "access_granted_by", label: "Access Granted By", minWidth: 100 },
  { id: "access_granted_on", label: "Access Granted On", minWidth: 100 },
  { id: "security_filter", label: "Security Filter", minWidth: 120 },
];
function UserTable(props) {
  const {
    accessList = [],
    filtervalue,
    setFilterValue,
    accessListClone,
    filterRole,
    setFilterRole,
    appType,
    countryBrandsData,
    accessOptions,
    accessRole,
    appDetails,
    user,
  } = props;
  const classesTable = useStyles1();
  const eventTracker = useAnalyticsEventTracker(
    "Access Management",
    appDetails.data.app_id
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortingType, setSortingType] = useState(false);
  const dispatch = useDispatch();

  function createData(accessList) {
    const result = accessList?.map((user) => {
      const time = formatDate(user.access_granted_on, "MMMM Do YYYY");
      const appData = {
        user_id: user.user_id,
        role: user.role,
        access_granted_by: user.access_granted_by,
        access_granted_on: time,
      };
      return appData;
    });
    return result;
  }
  const rows = createData(accessList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const count = +event.target.value;
    setRowsPerPage(count);
    setPage(0);
    eventTracker("Users pr page btn", count);
  };

  const filterListWithDate = () => {
    let reqList = [];
    if (sortingType) {
      reqList = accessListClone.sort((a, b) => {
        return b.access_granted_on - a.access_granted_on;
      });
    } else {
      reqList = accessListClone.sort((a, b) => {
        return a.access_granted_on - b.access_granted_on;
      });
    }
    // setAccessList(reqList);
    dispatch(update_accessManagement({ accessList: reqList }));

    setSortingType((prev) => !prev);
  };

  const handleUpdateRole = (newValue, obj) => {
    const index = accessList.findIndex((el) => el.name === obj.name);
    const indexClone = accessListClone.findIndex((el) => el.name === obj.name);
    const notUpdatedRecordsLeft = accessList.slice(0, index);
    const notUpdatedRecordsRight = accessList.slice(index + 1);
    const notUpdatedRecordsLeftClone = accessListClone.slice(0, indexClone);
    const notUpdatedRecordsRightClone = accessListClone.slice(indexClone + 1);
    const updatedState = [
      ...notUpdatedRecordsLeft,
      {
        ...obj,
        role: newValue,
      },
      ...notUpdatedRecordsRight,
    ];
    const updatedStateClone = [
      ...notUpdatedRecordsLeftClone,
      {
        ...obj,
        role: newValue,
      },
      ...notUpdatedRecordsRightClone,
    ];
    // setAccessList(updatedState);
    // setAccessListClone(updatedStateClone);
    dispatch(
      update_accessManagement({
        accessList: updatedState,
        accessListClone: updatedStateClone,
      })
    );
  };

  const handleDelete = (row) => {
    const filterData = accessList.filter(
      (item) => item.user_id.toLowerCase() !== row.user_id.toLowerCase()
    );
    const filterDataClone = accessListClone.filter(
      (item) => item.user_id.toLowerCase() !== row.user_id.toLowerCase()
    );
    // setAccessList(filterData);
    // setAccessListClone(filterDataClone);
    dispatch(
      update_accessManagement({
        accessList: filterData,
        accessListClone: filterDataClone,
      })
    );
  };

  const handleFilterUpdate = useCallback(() => {
    if (page !== 0) setPage(0);
    if (!filtervalue && filterRole === "All") {
      //   setAccessList(accessListClone);
      dispatch(
        update_accessManagement({
          accessList: accessListClone,
        })
      );
      return;
    } else if (filtervalue && filterRole === "All") {
      const filteredData = accessListClone.filter(
        (item) =>
          item.user_id.toLowerCase().includes(filtervalue.toLowerCase()) ||
          item.name.toLowerCase().includes(filtervalue.toLowerCase()) ||
          item.name
            .split(",")
            .join("")
            .split(" (Contractor)")
            .join("")
            .toLowerCase()
            .includes(filtervalue.toLowerCase()) ||
          item.name
            .split(",")
            .join("")
            .split(" ")
            .reverse()
            .join(" ")
            .split("(Contractor) ")
            .join("")
            .toLowerCase()
            .includes(filtervalue.toLowerCase())
      );
      //   setAccessList(filteredData);
      dispatch(
        update_accessManagement({
          accessList: filteredData,
        })
      );
    } else if (!filtervalue && filterRole !== "All") {
      const filteredData = accessListClone.filter(
        (item) => item.role === filterRole
      );
      //   setAccessList(filteredData);
      dispatch(
        update_accessManagement({
          accessList: filteredData,
        })
      );
    } else if (filtervalue && filterRole !== "All") {
      const filteredData1 = accessListClone.filter(
        (item) =>
          item.user_id.toLowerCase().includes(filtervalue.toLowerCase()) ||
          item.name.toLowerCase().includes(filtervalue.toLowerCase()) ||
          item.name
            .split(",")
            .join("")
            .split(" (Contractor)")
            .join("")
            .toLowerCase()
            .includes(filtervalue.toLowerCase()) ||
          item.name
            .split(",")
            .join("")
            .split(" ")
            .reverse()
            .join(" ")
            .split("(Contractor) ")
            .join("")
            .toLowerCase()
            .includes(filtervalue.toLowerCase())
      );
      const filteredData2 = filteredData1.filter(
        (item) => item.role === filterRole
      );
      //   setAccessList(filteredData2);
      dispatch(
        update_accessManagement({
          accessList: filteredData2,
        })
      );
    }
  }, [
    accessListClone,
    filterRole,
    filtervalue,
    // setAccessList
  ]);

  useEffect(() => {
    handleFilterUpdate();
  }, [filtervalue, filterRole, handleFilterUpdate]);

  useEffect(() => {
    if (appType === "ml_app" && countryBrandsData.status === "pending") {
      dispatch(getCountryBrands());
    }
  }, [dispatch]);

  return (
    <Paper sx={paperStyle}>
      {(appType === "ml_app" ||
        appType === "bi_app" ||
        appType === "standalone_app") && (
        <TableContainer sx={{ maxHeight: "calc(100vh - 520px)" }}>
          <Table stickyHeader="sticky table">
            <TableHead>
              <TableRow>
                {(appType === "bi_app" || appType === "standalone_app") &&
                  columns.map((column, i) => (
                    <TableHeaderCell
                      key={i}
                      column={column}
                      filtervalue={filtervalue}
                      setFilterValue={setFilterValue}
                      filterRole={filterRole}
                      setFilterRole={setFilterRole}
                      app_id={appDetails.data.app_id}
                      filterListWithDate={filterListWithDate}
                      sortingType={sortingType}
                      theme={user.ui_preferences.theme}
                    />
                  ))}
                {appType === "ml_app" &&
                  columns_for_mlApp.map((column, i) => (
                    <TableHeaderCell
                      key={i}
                      column={column}
                      filtervalue={filtervalue}
                      setFilterValue={setFilterValue}
                      filterRole={filterRole}
                      setFilterRole={setFilterRole}
                      app_id={appDetails.data.app_id}
                      filterListWithDate={filterListWithDate}
                      sortingType={sortingType}
                      theme={user.ui_preferences.theme}
                    />
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {accessList.length
                ? accessList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const obj = row;
                      return (
                        <TableBodyComponent
                          key={i}
                          obj={obj}
                          row={row}
                          columns={
                            appType === "bi_app" || appType === "standalone_app"
                              ? columns
                              : columns_for_mlApp
                          }
                          handleUpdateRole={handleUpdateRole}
                          handleDelete={handleDelete}
                          securityFilterData={countryBrandsData.data}
                          appType={appType}
                          accessOptions={accessOptions}
                          accessRole={accessRole}
                          theme={user.ui_preferences.theme}
                        />
                      );
                    })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        className={classesTable.pagination}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        labelRowsPerPage="Users per page :"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* <Pagination
            count={~~((rows.length/rowsPerPage)+1)}
            page={page}
            onPageChange={handleChangePage}
            /> */}
      {!accessList.length && (
        <Box textAlign="center" mt={1}>
          No user found
        </Box>
      )}
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  accessListClone: state.registerNewApp.accessManagement.accessListClone,
  accessList: state.registerNewApp.accessManagement.accessList,
  countryBrandsData: state.AppsData.countryBrands,
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(UserTable);
