// Import required libraries
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

// Import custom components
import App from "./js/App";

// Import styles
import "./index.scss";

// Import store
import store from "./js/redux/storeFactory";
// import storeFactory from "./js/redux/storeFactory";

// const store = storeFactory();
// window.React = React;
// window.store = store;

// console.log("STORE -> ", store.getState());

//GA TRACKING IDs
// const TRACKING_ID = "UA-238067786-1"; // DEV/LOCAL
// const TRACKING_ID = "UA-238061532-1"; // UAT
const TRACKING_ID = "UA-238062548-1"; // PRD
ReactGA.initialize(TRACKING_ID);

// GTM

const tagManagerArgs = {
    // gtmId: "GTM-TQ68KTS",
    gtmId: "GTM-PJP5Z75", // Dev prod
    // gtmId: "GTM-N49HVR3", // Uat ID
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>,
    document.getElementById("root")
);
