import React, { useState, useEffect } from "react";

// Import mui components
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "./style.scss";
// Import Styles
import { useStylesforSF } from "../AppInfoCard/style";
import moment from "moment";

function fetchAPI(payload) {
  const url = "/api/requestaccess";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: "12px",
    // fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

function isAppOwner(person) {
  return (
    person.role.toLowerCase().includes("owner") ||
    person.role.toLowerCase().includes("admin")
  );
}

// Main component
const RequestAccessModal = ({
  openRA,
  handleClose,
  appDetails,
  user,
  setOpenRA,
  reqState,
  setReqState,
}) => {
  const classes = useStylesforSF();
  const [value, setValue] = useState(
    `Hi There,
Kindly grant me access for your application.`
  );

  const appOwnerArr = appDetails.access_details
    ? appDetails.access_details?.users_list?.filter(isAppOwner)
    : [];
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleRequest = async () => {
    setReqState("success");
    setOpenRA(false);
    const body = {
      request_id: v4(),
      requester_meta: {
        app_name: appDetails.app_meta?.app_name,
        app_type: appDetails.app_meta?.app_type,
        app_id: appDetails.app_id,
        requested_by: user.preferred_username,
        requested_on: moment().valueOf(),
        requestors_comment: value,
        access_level: "user",
        name: user.name,
      },
      approval_meta: {
        approval_status: "pending",
        approved_on: "",
        approved_by: "",
      },
    };

    try {
      const res = await fetchAPI(body);
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200") {
      } else {
        toast.error("Application Request Failed.");
      }
    } catch (error) {
      setReqState("error");
      toast.error("Application Request Failed.");
    }
  };

  return (
    <Dialog
      open={openRA}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.requestalertContainer}
    >
      <div>
        <div>
          <Box className={classes.requestAccessHeading}>
            <div
              style={{
                paddingBottom: "20px",
                borderBottom: "1px dotted #b1b1b1",
              }}
            >
              Request Access
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {appDetails.app_meta?.app_logo_type === "google_font" ? (
                <span
                  class={`material-icons-outlined app-icon ${user.ui_preferences.theme}-icon`}
                  style={{
                    fontSize:"18px",
                  }}
                >
                  analytics
                </span>
              ) : (
                <img
                  className="app-icon"
                  src={appDetails.app_meta?.app_logo}
                  style={{
                    width: "18px",
                  }}
                  alt="icon"
                />
              )}
              <div className={classes.requestAccessAppName}>
                {appDetails.app_meta.app_name || ""}
              </div>
              <div></div>
            </div>

            {appOwnerArr.length !== 0 && (
              <div style={{ color: "black", cursor: "default" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    class="material-icons-round"
                    style={{
                      color: `${
                        user.ui_preferences.theme === "light"
                          ? "#FC007F"
                          : "#fff"
                      }`,
                      fontSize: "20px",
                      padding:"0px 15px 0px 5px",
                    }}
                  >
                    person
                  </span>
                  <p
                    className={
                      user.ui_preferences.theme === "light"
                        ? "infoPara-light"
                        : "infoPara-dark"
                    }
                  >
                    App Owners:
                  </p>

                  {appOwnerArr.slice(0, 2).map((person, index) => (
                    <p
                      className={
                        user.ui_preferences.theme === "light"
                          ? "infoPara-light"
                          : "infoPara-dark"
                      }
                    >
                      {person?.name
                        ? person?.name
                            .replace("(Contractor)", "")
                            .replace(",", " ") + " "
                        : " "}
                      {index < appOwnerArr.length - 1 ? ", " : " "}
                    </p>
                  ))}

                  {appOwnerArr.length > 2 && (
                    <HtmlTooltip
                      placement="top-start"
                      title={
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {appOwnerArr
                            .slice(2, appOwnerArr.length)
                            .map((person, index) => (
                              <p>
                                {person?.name
                                  ? person?.name
                                      .replace("(Contractor)", "")
                                      .replace(",", " ") + " "
                                  : " "}
                                {index < appOwnerArr.length - 1 ? ", " : " "}
                              </p>
                            ))}
                        </div>
                      }
                    >
                      <p
                        className={
                          user.ui_preferences.theme === "light"
                            ? "infoPara-light"
                            : "infoPara-dark"
                        }
                      >
                        +{appOwnerArr.length - 2} more.
                      </p>
                    </HtmlTooltip>
                  )}
                </div>
              </div>
            )}
          </Box>
          <DialogContent style={{ paddingBottom: "10px" }}>
            <TextField
              disabled={
                reqState === "pending" || reqState === "error" ? false : true
              }
              className={classes.textFieldText}
              fullWidth
              id="outlined-multiline-static"
              multiline
              value={value}
              onChange={handleChange}
              rows={4}
              placeholder="Add a Note (Optional)"
            />
          </DialogContent>
        </div>
        <DialogActions sx={{ marginBottom: "8px" }}>
          {reqState === "pending" || reqState === "error" ? (
            <div style={{ display: "flex", marginRight: "14px" }}>
              <button
                className={`${classes.requestBtns} ${classes.transparent}`}
                onClick={() => {
                  setOpenRA(false);
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleRequest}
                className={`${classes.requestBtns} ${classes.pink}`}
              >
                Send Request
              </button>
            </div>
          ) : (
            <button
              className={`${classes.requestBtns} ${classes.grey}`}
              style={{ marginRight: "14px" }}
            >
              Access Requested
            </button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};
RequestAccessModal.propTypes = {
  openRA: PropTypes.any.isRequired,
  handleClose: PropTypes.any.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(RequestAccessModal);
