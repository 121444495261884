import { v4 } from "uuid";
import moment from "moment";
import orderBy from "lodash/orderBy";
import isEqual from "lodash/isEqual";
import filter from "lodash/filter";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import tempDefaultData from "../../assets/data/tempDefaultData.json";

// Import Hooks
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";
import { config } from "../config/config";
import {
  affricanCountries,
  APACCountries,
  europeCountriesList,
  LATAMCountries,
  middleEastList,
  northAmericanCountries,
} from "../screens/AccountSetup/data";

export function getElementContentWidth(element) {
  var styles = window.getComputedStyle(element);

  return (
    element.clientWidth -
    parseFloat(styles.paddingLeft) -
    parseFloat(styles.paddingRight)
  );
}

// To poopulate filters data based on elements available
export const populateFiltersData = (fitlersData, data) => {
  let updFiltersData = fitlersData.map((obj) => ({
    ...obj,
    key: v4(),
    data: data[obj.name] ? data[obj.name] : null,
  }));
  return updFiltersData;
};

// Add ALL from UI
export const addAllInData = (arr) => [{ id: "ALL", name: "ALL" }, ...arr];

// To flatten nested object
export const flattenObject = (obj) => {
  const flattened = {};
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && key === "metadata") {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });
  return flattened;
};

export const makeDefaultResponseJson = () => ({ data: [], error: "" });

// To convert a given date in to provided date format
export const formatDate = (date, format = "MMM-DD-YYYY H:mm:ss") =>
  moment(date).format(format);

// To sort data based on field
export const getSortedData = (data, orderField = "name", order = "asc") =>
  orderBy(data, [orderField], [order]);

// To check is 2 objects/maps are equal
export const isEqualObject = (objVal, otherVal) => {
  return isEqual(objVal, otherVal);
};

export const splitToChunks = (array, parts) => {
  let mainArr = [],
    tempArr = [];
  for (let i = 0; i < parts; i++) {
    tempArr = [];
    for (let j = i; j < array.length; j = j + parts) {
      tempArr.push(array[j]);
    }
    mainArr.push({ id: i, data: tempArr });
  }
  return mainArr;
};

// To get initials from email
export const getInitialsFromEmail = (nameString, separator) => {
  const emailName = nameString.split(separator);
  const fullName = emailName[0].split(".");
  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

//To get initials from name
export const getInitials = (nameString, separator) => {
  const fullName = nameString.split(separator);
  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  return initials.toUpperCase();
};

//filter records based on user selections
export const filterMyConfigs = (
  arr = [],
  searchKey,
  selections,
  configFlag,
  appType
) => {
  let filteredArr,
    filtersObj,
    config = {};
  let validFilters = ["country", "brand", "segment"];

  for (let key in selections) {
    if (selections[key] !== "ALL" && validFilters.includes(key)) {
      config[key] = selections[key];
    }
  }

  if (configFlag) {
    filtersObj = {
      selections: {
        config,
      },
    };
  } else {
    filtersObj = {};
  }

  filteredArr = filter(arr, filtersObj);

  if (selections.searchText !== "") {
    filteredArr = filteredArr.filter((obj) => {
      return obj[searchKey]
        .toLowerCase()
        .includes(selections.searchText.toLowerCase());
    });
  }
  if (appType) {
    filteredArr = filteredArr.filter((obj) => {
      return obj?.app_id?.includes(appType);
    });
  }
  return filteredArr;
};

export const imageSize = (url) => {
  const img = document.createElement("img");
  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      resolve({ width, height });
    };
    img.onerror = reject;
  });
  img.src = url;

  return promise;
};

export function getFilename(url) {
  var filename = url.split("/").pop();
  return filename.substr(0, filename.lastIndexOf("."));
}

export const ChangeViewByStructure = (
  data,
  filter,
  options,
  value_type = "multiple"
) => {
  let obj = {};
  if (filter === "app_type") {
    const arr = options.map((val) => {
      if (val === "Embedded Analytics") {
        val = "bi_app";
      } else {
        val = val.replaceAll(" ", "_").toLowerCase();
      }
      return val;
    });
    arr.forEach((val) => {
      Object.assign(obj, { [val]: [] });
    });
    data.forEach((app) => {
      const App_type = app.app_meta.app_type;
      obj[App_type].push(app);
    });
  } else if (filter === "geography") {
    const arr = options.map((val) => {
      return val.region;
    });
    arr.forEach((val) => {
      Object.assign(obj, { [val]: [] });
    });
    options.forEach((filterVal) => {
      data.forEach((app) => {
        const regionVals = app.app_details[filter].map((item) => item.region);
        if (regionVals.includes(filterVal.region)) {
          obj[filterVal.region].push(app);
        }
      });
    });
  } else {
    if (value_type === "single") {
      options.forEach((val) => {
        Object.assign(obj, { [val]: [] });
      });
      data?.forEach((app) => {
        const keyValue = app.app_details[filter];
        obj[keyValue].push(app);
      });
    } else if (value_type === "multiple") {
      options.forEach((val) => {
        Object.assign(obj, { [val]: [] });
      });

      options.forEach((filterVal) => {
        data.forEach((app) => {
          if (app.app_details[filter].includes(filterVal)) {
            obj[filterVal].push(app);
          }
        });
      });
    }
  }
  return Object.entries(obj);
};

export const getAppIds = (data = []) => {
  const reqData = [];
  data.forEach((item) => reqData.push(item.app_id));
  return reqData;
};

export const ownerList = (data = {}, role = "") => {
  let list = [];
  data.access_details?.users_list?.forEach((item) => {
    if (item.role === "owner" || item.role === role) {
      list.push(item.user_id);
    }
  });
  return list;
};

export const shortedData = (data) => {
  if (Array.isArray(data)) {
    return data.sort((a, b) =>
      a.localeCompare(b, undefined, {
        sensitivity: "base",
      })
    );
  } else return data;
};

export const UpdatePageView = (pageName) => {
  const gaEventTracker = useAnalyticsEventTracker("PageNavigation");
  gaEventTracker("Launch", `page = ${pageName}`);
};

export const isUrlMatch = (bi_application, val) => {
  if (
    bi_application === "Google DataStudio" &&
    (val.startsWith("https://datastudio.google.com/embed") ||
      val.startsWith("https://lookerstudio.google.com"))
  ) {
    return true;
  } else if (
    bi_application === "Sharepoint" &&
    val.includes("sharepoint.com") &&
    val.includes("action=embedview")
  ) {
    return true;
  } else if (
    bi_application === "Power BI" &&
    val.startsWith("https://app.powerbi.com/reportEmbed")
  ) {
    return true;
  } else if (
    bi_application === "Power Apps" &&
    val.startsWith("https://apps.powerapps.com")
  ) {
    return true;
  } else if (
    bi_application === "Other Applications" &&
    val.startsWith("https://")
  ) {
    return true;
  } else {
    return false;
  }
};

export const updateCatState = (oldData, catIndex, newData) => {
  const leftItem = oldData.slice(0, catIndex);
  const rightItem = oldData.slice(catIndex + 1);
  return [
    ...leftItem,
    {
      ...oldData[catIndex],
      ...newData,
    },
    ...rightItem,
  ];
};

export const updateMiddleSubcatState = (
  oldData,
  catIndex,
  subCatIndex,
  newData
) => {
  const leftItem = oldData.slice(0, catIndex);
  const rightItem = oldData.slice(catIndex + 1);
  return [
    ...leftItem,
    {
      ...oldData[catIndex],
      sub_category: [
        ...oldData[catIndex].sub_category.slice(0, subCatIndex),
        {
          ...oldData[catIndex].sub_category[subCatIndex],
          ...newData,
        },
        ...oldData[catIndex].sub_category.slice(subCatIndex + 1),
      ],
    },
    ...rightItem,
  ];
};

export const updateSubState = (
  oldData,
  catIndex,
  subCatIndex,
  newData,
  reportIndex
) => {
  const leftItem = oldData.slice(0, catIndex);
  const rightItem = oldData.slice(catIndex + 1);
  if (subCatIndex > -1) {
    return [
      ...leftItem,
      {
        ...oldData[catIndex],
        sub_category: [
          ...oldData[catIndex].sub_category.slice(0, subCatIndex),
          {
            ...oldData[catIndex].sub_category[subCatIndex],
            sub_category: [
              ...oldData[catIndex].sub_category[subCatIndex].sub_category.slice(
                0,
                reportIndex
              ),
              {
                ...oldData[catIndex].sub_category[subCatIndex].sub_category[
                  reportIndex
                ],
                ...newData,
              },
              ...oldData[catIndex].sub_category[subCatIndex].sub_category.slice(
                reportIndex + 1
              ),
            ],
          },
          ...oldData[catIndex].sub_category.slice(subCatIndex + 1),
        ],
      },
      ...rightItem,
    ];
  } else {
    return [
      ...leftItem,
      {
        ...oldData[catIndex],
        sub_category: [
          ...oldData[catIndex].sub_category.slice(0, reportIndex),
          {
            ...oldData[catIndex].sub_category[reportIndex],
            ...newData,
          },
          ...oldData[catIndex].sub_category.slice(reportIndex + 1),
        ],
      },
      ...rightItem,
    ];
  }
};
export const updateSubCategoryReportState = (
  oldData,
  catIndex,
  subCatIndex,
  newData
) => {
  const leftItem = oldData.slice(0, catIndex);
  const rightItem = oldData.slice(catIndex + 1);
  return [
    ...leftItem,
    {
      ...oldData[catIndex],
      sub_category: [
        ...oldData[catIndex].sub_category.slice(0, subCatIndex),
        {
          ...oldData[catIndex].sub_category[subCatIndex],
          SubCatHyperlinks: {
            ...oldData[catIndex].sub_category[subCatIndex].SubCatHyperlinks,
            ...newData,
            // isHyperlinkActive: true,
            view_count: 0,
          },
        },
        ...oldData[catIndex].sub_category.slice(subCatIndex + 1),
      ],
    },
    ...rightItem,
  ];
};

export const updateCategoryReportState = (oldData, catIndex, newData) => {
  const leftItem = oldData.slice(0, catIndex);
  const rightItem = oldData.slice(catIndex + 1);
  return [
    ...leftItem,
    {
      ...oldData[catIndex],
      CatHyperlinks: {
        ...oldData[catIndex].CatHyperlinks,
        ...newData,
        // isHyperlinkActive: true,
        view_count: 0,
      },
    },
    ...rightItem,
  ];
};

export const removeSubField = (oldData, catIndex, subCatIndex) => {
  const leftItem = oldData.slice(0, catIndex);
  const rightItem = oldData.slice(catIndex + 1);
  return [
    ...leftItem,
    {
      ...oldData[catIndex],
      sub_category: [
        ...oldData[catIndex].sub_category.slice(0, subCatIndex),
        ...oldData[catIndex].sub_category.slice(subCatIndex + 1),
      ],
    },
    ...rightItem,
  ];
};

export const removeReportField = (
  oldData,
  catIndex,
  subCatIndex,
  reportIndex
) => {
  const leftItem = oldData.slice(0, catIndex);
  const rightItem = oldData.slice(catIndex + 1);
  if (subCatIndex > -1) {
    return [
      ...leftItem,
      {
        ...oldData[catIndex],
        sub_category: [
          ...oldData[catIndex].sub_category.slice(0, subCatIndex),
          {
            ...oldData[catIndex].sub_category[subCatIndex],
            sub_category: [
              ...oldData[catIndex].sub_category[subCatIndex].sub_category.slice(
                0,
                reportIndex
              ),
              ...oldData[catIndex].sub_category[subCatIndex].sub_category.slice(
                reportIndex + 1
              ),
            ],
          },
          ...oldData[catIndex].sub_category.slice(subCatIndex + 1),
        ],
      },
      ...rightItem,
    ];
  } else {
    return [
      ...leftItem,
      {
        ...oldData[catIndex],
        sub_category: [
          ...oldData[catIndex].sub_category.slice(0, reportIndex),
          ...oldData[catIndex].sub_category.slice(reportIndex + 1),
        ],
      },
      ...rightItem,
    ];
  }
};

export const specialFeilds = [
  "report",
  "hybrid",
  "customHybrid",
  "sub_header",
  "textBox",
];

export const getNormalText = (text) => {
  if (text.startsWith('{"blocks"')) {
    let reqText = "";
    JSON.parse(text)?.blocks?.forEach((item) => {
      if (item.text) {
        reqText = reqText + item.text + "\n";
      }
    });
    return reqText;
  }
  return text;
};

export const getNormalTextFromRaw = (data = {}) => {
  if (!data?.sub_category) return "";
  const rowText = convertToRaw(data.sub_category_name.getCurrentContent());
  if (rowText.blocks[0]?.text) {
    return getNormalText(rowText);
  }
  return data;
};

export const getValidTextEditorData = (text) => ({
  blocks: [
    {
      key: "637gr",
      text: text || "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: { "text-align": "start" },
    },
  ],
  entityMap: {},
});

export const getDefaultEditorData = (text = tempDefaultData) => {
  const reqData = EditorState.createWithContent(convertFromRaw(text));
  return reqData;
};
// convert data from string to texteditor format.
export const getEditorState = (text) => {
  let editorState = null;
  if (!text.startsWith('{"blocks"')) {
    const reqText = getValidTextEditorData(text);
    editorState = getDefaultEditorData(reqText);
  } else {
    editorState = getDefaultEditorData(JSON.parse(text));
  }
  return editorState;
};

export const handleCopyToClipBoard = (text) => {
  navigator.clipboard.writeText(text);
};

export const checkNewApp = (app) => {
  if (
    app?.approval_meta?.approval_status === "published" &&
    app?.app_meta?.published_on
  ) {
    const currentEpoch = Date.now();
    const diff = currentEpoch - app.app_meta.published_on;
    if (diff > config.hardCoded.fourteen_days_val || Number.isNaN(diff)) {
      return false;
    } else return true;
  }
  return false;
};

export const addAppToHome = (user_id, appIdList, app_id) => {
  let data = { user_id };
  if (!appIdList.includes(app_id)) {
    data = {
      ...data,
      sequence_of_app: [app_id, ...appIdList],
    };
  } else {
    const reqList = appIdList.filter((item) => item !== app_id);
    data = { ...data, sequence_of_app: reqList };
  }
  return data;
};

export const req_regions = {
  "North America": northAmericanCountries,
  APAC: APACCountries,
  LATAM: LATAMCountries,
  Europe: europeCountriesList,
  AMET: [...affricanCountries, ...middleEastList],
};
export const getFilteredOptions = (options) => {
  if (!options?.length) return [];
  if (options[0].region) return options;
  return options.map((item) => {
    return {
      region: item,
      countries: ["All", ...(req_regions[item] || [])],
    };
  });
};
export const getReqRegion = (options) => {
  if (!options?.length) return [];
  if (options[0].region) return options;
  return options.map((item) => {
    return {
      region: item,
      countries: [],
    };
  });
};
