import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import delete_icon from "../../../../../../assets/images/delete_icon.svg";
import add_icon from "../../../../../../assets/images/add_azure.svg";
import AddIcon from "@mui/icons-material/Add";
import runSuccess from "../../../../../../assets/images/register-images/run-success.svg";
import { BootstrapInput } from "../../../style";
import { updateReportTemplate } from "../../../../../redux/actions";
import { v4 } from "uuid";
import { getInitialState } from "../utils";
import { config } from "../../../../../config/config";
import TemplateCard from "../../../../../components/TemplateCard/TemplateCard";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexWrap: "wrap",
    marginTop: "10px",
  },
  parentHeading: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "25px",
    // color: "#282828",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    // marginTop: "20px",
    cursor: "pointer",
    width: "fit-content",
  },
  descText: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
    whiteSpace: "pre-wrap",
    cursor: "pointer",
    width: "fit-content",
  },
  addReport: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
    width: "100px",
    height: "100px",
    borderRadius: "5px",
    // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
    border: "0.2px solid #fc007f",
    cursor: "pointer",
    color: "#fc007f",
    "&:hover": {
      backgroundColor: "#fc007f",
      color: "#fff",
      boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
    },
  },
  cardRoot: {
    width: "100px",
    height: "100px",
  },
  reportText: {
    fontSize: "12px",
    fontWeight: 400,
  },
  reportContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    marginTop: "10px",
  },
  cancelIcon: {
    height: "20px",
    cursor: "pointer",
  },
  doneIcon: {
    cursor: "pointer",
    height: "40px",
  },
  addDescBtn: {
    backgroundColor: "transparent",
    float:"left",
    color: "#fc007f",
    border: "none",
    fontSize: "12px",
    fontWeight: 600,
    padding: 0,
  },
  addParentReport: {
    fontSize: "12px",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
    background: "transparent",
    fontWeight: 400,
    borderRadius: "6px",
    border: theme.palette.mode === "dark" ? "1px solid #fff" : "1px solid #000",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 0px 10px",
    // "&:hover": {
    //     borderBottom: "1px solid #fc007f",
    // },
  },
}));

const InputField = ({
  setHideInput,
  defaultValue,
  multiline = false,
  width = "30%",
  fieldKey,
  handleUpdate,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("");

  const handleChange = (e) => setValue(e.target.value);
  const handleCancel = () => setHideInput(false);

  const handleDone = () => {
    if (!value && fieldKey === "sub_category_name") return;
    setHideInput(false);
    handleUpdate({ [fieldKey]: value });
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <BootstrapInput
        onChange={handleChange}
        value={value}
        multiline={multiline}
        style={{ width }}
      />
      <img
        src={runSuccess}
        className={classes.doneIcon}
        title="Done"
        alt="Done"
        onClick={handleDone}
      />
      <img
        src={delete_icon}
        alt="Cancel"
        title="Cancel"
        className={classes.cancelIcon}
        onClick={handleCancel}
      />
    </Box>
  );
};

const ParentRow = ({
  data,
  handleNavigation,
  handleDelete,
  handleEdit,
  breadcrumbArr,
  setRenderSubReportArr,
  setSelectedSubcat,
  setopenAddReportPopUp,
  setSelectedWidgetType,
  setParentID,
  isFieldsEditable,
  setParentReportType,
  editable_layout,
  template_no,
  
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { templateData } = useSelector(
    (state) => state.appTemplate.appTemplateState
  );
  const [showNameInput, setShowNameInput] = useState(false);
  const [showDescInput, setShowDescInput] = useState(false);

  const handleOpenNameField = () => {
    if (isFieldsEditable) return;
    else setShowNameInput(true);
  };
  const handleOpenDescInput = () => {
    if (isFieldsEditable) return;
    else setShowDescInput(true);
  };

  const findNUpdateSubCat = (oldData, breadID, newData, childId) => {
    let isFound = false;
    oldData.forEach((item) => {
      if (isFound) return;
      if (item.sub_category_id !== breadID) {
        if (item.sub_category.length > 0) {
          findNUpdateSubCat(item.sub_category, breadID, newData, childId);
        }
      } else {
        const reqIndex = item.sub_category.findIndex(
          (subCat) => subCat.sub_category_id === childId
        );
        item.sub_category[reqIndex] = {
          ...item.sub_category[reqIndex],
          ...newData,
        };
        setRenderSubReportArr(item.sub_category);
        isFound = true;
      }
    });
  };

  const findNUpdate = (oldData, newData, childId) => {
    if (breadcrumbArr.length === 1) {
      const reqIndex = oldData.findIndex(
        (item) => item.sub_category_id === childId
      );
      oldData[reqIndex] = { ...oldData[reqIndex], ...newData };
      setRenderSubReportArr(oldData);
    } else {
      findNUpdateSubCat(
        oldData,
        breadcrumbArr[breadcrumbArr.length - 1].textID,
        newData,
        childId
      );
    }
  };
  const handleUpdate = (newData) => {
    let newState = JSON.parse(JSON.stringify(templateData));
    findNUpdate(newState, newData, data.sub_category_id);
    dispatch(
      updateReportTemplate({
        templateData: newState,
      })
    );
  };

  const handleOpen_Add_pop_up = () => {
    if (isFieldsEditable) return;
    setSelectedSubcat({});
    setParentID(data.sub_category_id);
    setopenAddReportPopUp(true);
    setSelectedWidgetType("report");
    setParentReportType("parentReport");
  };
  const horizontalReports = templateData.filter(
    (item) => item.sub_category_type === "parentReport"
  );
  const handleDeleteParentReport = () => {
    // const horizontalReports = templateData.filter(
    //   (item) => item.sub_category_type === "parentReport"
    // );
  
    if (horizontalReports.length === 1) {
      return;
    }
    handleDelete(data);
  };

  return (
    <div key={data.sub_category_id}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>
          {showNameInput ? (
            <InputField
              defaultValue={data.sub_category_name}
              setHideInput={setShowNameInput}
              fieldKey="sub_category_name"
              handleUpdate={handleUpdate}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div
                className={classes.parentHeading}
                onClick={editable_layout?handleOpenNameField:""}
                title="Click to edit"
              >
                {data.sub_category_name}
              </div>
              {!isFieldsEditable && editable_layout && (template_no !== 4 || (template_no === 4 && horizontalReports.length > 1)) && (
                <span
                  onClick={handleDeleteParentReport}
                  style={{
                    marginRight: "10px",
                    color: "#FC007F",
                    fontSize: "20px",
                  }}
                  class="material-icons-outlined"
                  title="Delete Parent Report"
                >
                  delete_forever
                </span>
              )}
            </div>
          )}

          {!data.sub_category_description && editable_layout && !showDescInput && (
            <button
              className={classes.addDescBtn}
              onClick={handleOpenDescInput}
            >
              Add Description
            </button>
          )}
          {showDescInput ? (
            <InputField
              defaultValue={(data.sub_category_description || "").replaceAll(
                "\\n",
                "\n"
              )}
              setHideInput={setShowDescInput}
              width="60%"
              multiline
              fieldKey="sub_category_description"
              handleUpdate={handleUpdate}
            />
          ) : data.sub_category_description ? (
            <div
              className={classes.descText}
              onClick={editable_layout?handleOpenDescInput:""}
              title="Click to edit"
            >
              {(data.sub_category_description || "").replaceAll("\\n", "\n")}
            </div>
          ) : null}
        </div>
      </div>
      <div className={classes.cardContainer}>
        {editable_layout && (
          <div onClick={handleOpen_Add_pop_up} className={classes.addReport}>
            <AddCircleIcon sx={{ fontSize: "20px" }} />
            <div className={classes.reportText}>Report</div>
          </div>
        )}
        {(data.sub_category || []).map((childList) => {
          if (childList.sub_category_type === "header") return null;
          return (
            <div className={classes.cardRoot}>
              <TemplateCard
                key={childList.sub_category_id}
                data={childList}
                handleNavigation={handleNavigation}
                parentData={data}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                isFieldsEditable={isFieldsEditable}
                notEditable={isFieldsEditable}
                normalIcons
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ParentReportType = ({
  data,
  handleNavigation,
  handleDelete,
  handleEdit,
  breadcrumbArr,
  setRenderSubReportArr,
  setSelectedSubcat,
  setopenAddReportPopUp,
  setSelectedWidgetType,
  setParentID,
  isFieldsEditable,
  setParentReportType,
  editable_layout,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { templateData } = useSelector(
    (state) => state.appTemplate.appTemplateState
  );
  const template_no = useSelector(
    (state) => state.appTemplate.appTemplateState.template_no
  );
  const getHeaderData = useMemo(
    () => data.filter((item) => item.sub_category_type === "header"),
    [data]
  );

  const findNUpdateSubCat = (oldData, breadID, newData) => {
    let isFound = false;
    oldData.forEach((item) => {
      if (isFound) return;
      if (item.sub_category_id !== breadID) {
        if (item.sub_category.length > 0) {
          findNUpdateSubCat(item.sub_category, breadID, newData);
        }
      } else {
        item.sub_category.push(newData);
        setRenderSubReportArr(item.sub_category);
        isFound = true;
      }
    });
  };

  const findNUpdate = (oldData, newData) => {
    if (breadcrumbArr.length === 1) {
      oldData.push(newData);
      setRenderSubReportArr(oldData);
    } else {
      findNUpdateSubCat(
        oldData,
        breadcrumbArr[breadcrumbArr.length - 1].textID,
        newData
      );
    }
  };

  const handleParentReport = () => {
    const ID = v4();
    const initialData = getInitialState(
      ID,
      `${config.hardCoded.horizontal_report}Name`,
      config.hardCoded.your_discription,
      "",
      "parentReport"
    );
    let newState = JSON.parse(JSON.stringify(templateData));
    findNUpdate(newState, initialData);
    dispatch(
      updateReportTemplate({
        templateData: newState,
      })
    );
  };

  return (
    <Box
      className={classes.reportContainer}
      sx={{
        overflow: "auto",
        height: `calc(80vh - 20px - ${
          getHeaderData.length > 0 ? getHeaderData.length * 80 : 0
        }px)`,
        px: "10px",
      }}
    >
      {data.map((parentList) => {
        if (parentList.sub_category_type === "header") return null;
        return (
          <ParentRow
            editable_layout={editable_layout}
            key={parentList.sub_category_id}
            data={parentList}
            handleNavigation={handleNavigation}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            breadcrumbArr={breadcrumbArr}
            setRenderSubReportArr={setRenderSubReportArr}
            setSelectedSubcat={setSelectedSubcat}
            setopenAddReportPopUp={setopenAddReportPopUp}
            setSelectedWidgetType={setSelectedWidgetType}
            setParentID={setParentID}
            isFieldsEditable={isFieldsEditable}
            setParentReportType={setParentReportType}
            template_no={template_no}
          />
        );
      })}
      {!isFieldsEditable && editable_layout && (
        <div>
          <button
            className={classes.addParentReport}
            onClick={handleParentReport}
          >
            {/* <img src={add_icon}/> */}
            <AddIcon fontWeight="400" />
            <div style={{ marginLeft: "5px" }}>Horizontal Report</div>
          </button>
        </div>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  editable_layout: state.registerNewApp.statusData.editLayout,
  template_no: state.appTemplate.appTemplateState.template_no,
});

export default connect(mapStateToProps)(ParentReportType);
