import React from "react";
import { makeStyles } from "@material-ui/core";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
const useStyles = makeStyles((theme) => ({
  links: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "26px",
    textDecoration: "none",
    // color: "#282828",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
  },
}));
const getElipsesName = (name, arrayLength) => {
  if (arrayLength <= 3) {
    return name;
  } else if (arrayLength <= 4) {
    return name.length > 15 ? name.slice(0, 15) + ".." : name;
  } else if (arrayLength <= 15) {
    return name.length > 5 ? name.slice(0, 5) + ".." : name;
  } else {
    return name.length > 2 ? name.slice(0, 2) + ".." : name;
  }
};

const BreadcrumbComponent = ({ data = [] }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs
      separator="›"
      aria-label="breadcrumbs"
      sx={{
        // mb: "10px",
        px: "10px",
        flex: 1,
        width: "400px",
      }}
    >
      {data.slice(0, -1).map((item, index) => (
        <Link key={item.name} to={item.link} className={classes.links}>
          {index === 0 ? (
            item.name
          ) : (
            <Tooltip title={item.name}>
              {getElipsesName(item.name, data.length)}
            </Tooltip>
          )}

          {/* {item.name} */}
        </Link>
      ))}
      <div className={classes.links} style={{ color: "#FC007F" }}>
        {data.slice(-1)[0]?.name}
      </div>
    </Breadcrumbs>
  );
};

export default BreadcrumbComponent;
