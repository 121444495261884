import React from "react";
import { connect } from "react-redux";
import "../../index.scss";

const ReportCard1 = (props) => {
  const {
    user,
    card_width = "200px",
    card_height = "200px",
    reportDetails,
    handleEditSubCat,
    handleDelete,
    editable,
    handlePreviewReport,
  } = props;

  // console.log("reportDetails", reportDetails);
  const path_has_register =
    window.location.pathname.split("/")[1] === "register";
  return (
    <div
      onClick={() => {
        handlePreviewReport(reportDetails);
      }}
      style={{
        // width: card_width, // TODO: we have to convert the width to % and then use it.
        height: card_height,
        // fontSize: "0.94vw",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        borderRadius: "6px",
        // border: "1px solid #D7DADF",
        background: user.ui_preferences.theme === "light" ? "#FFF" : "#282828",
        color: user.ui_preferences.theme === "dark" ? "#FFF" : "#282828",
        boxShadow: "1px 1px 8px 0px rgba(0, 0, 0, 0.08)",
        borderLeft: "8px solid #CE2021",
        padding: "0px 10px 0 10px",
        position: "relative",
      }}
    >
      {/* bi-icon,link, edit and delete */}
      <div className="card-heading">
        <div
          className="link-icon"
          style={{
            width: "calc(100% - 45px)",
          }}
        >
          {reportDetails.app_logo_type === "google_font" ? (
            <span
              class="material-icons-round"
              style={{
                // lineHeight: "18px",
                padding: "4px",
                borderRadius: "20px",
                border: "1px solid #d7dadf",
              }}
            >
              {reportDetails?.sub_category_icon}
            </span>
          ) : (
            <img
              src={reportDetails?.sub_category_icon}
              className="app-icon"
              style={{
                width: "22px",
                height: "22px",
                padding: "4px",
              }}
              alt="upload icon"
            />
          )}
          <p
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {reportDetails.bi_application}
          </p>
        </div>

        <div className="link-icon" style={{ width: "45px" }}>
          {editable && path_has_register && (
            <>
              <span
                style={{
                  // color: "grey",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleEditSubCat(reportDetails);
                }}
                class="material-icons-round"
              >
                edit
              </span>
              <span
                style={{
                  // color: "grey",
                  marginRight: "0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleDelete(reportDetails);
                }}
                class="material-icons-round"
              >
                delete
              </span>
            </>
          )}
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <p
          style={{
            margin: "0px",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "18px",
            letterSpacing: "0.1px",
            padding: "10px 0 10px 0",
            textAlign: "left",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {reportDetails.sub_category_name}
        </p>
        {reportDetails.sub_category_description !== "" && (
          <div className="report-description">
            {reportDetails.sub_category_description}
          </div>
        )}
        <span
          class="material-symbols-outlined"
          style={{
            right: "11px",
            bottom: "9px",
            position: "absolute",
          }}
        >
          arrow_right_alt
        </span>
      </div>
    </div>
  );
};

const ReportCard2 = (props) => {
  const {
    user,
    card_width = "200px",
    card_height = "300px",
    handleEditSubCat,
    reportDetails = {},
    objectFitType = "fit",
    handleDelete,
    editable,
    handlePreviewReport,
  } = props;
  // console.log("reportDetails",reportDetails)
  const path_has_register =
    window.location.pathname.split("/")[1] === "register";
  return (
    <div
      onClick={() => {
        handlePreviewReport(reportDetails);
      }}
      style={{
        // width: card_width, // TODO: we have to convert the width to % and then use it.
        // height: card_height,
        height: "300px",
        // fontSize: "0.94vw",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        borderRadius: "6px",
        // border: "1px solid #D7DADF",
        background: user.ui_preferences.theme === "light" ? "#FFF" : "#282828",
        color: user.ui_preferences.theme === "dark" ? "#FFF" : "#282828",
        boxShadow: "1px 1px 8px 0px rgba(0, 0, 0, 0.08)",
        borderLeft: "8px solid #CE2021",
        padding: "0px 10px 0 10px",
        position: "relative",
      }}
    >
      {/* bi-icon,link, edit and delete */}
      <div className="card-heading">
        <div
          className="link-icon"
          style={{
            width: "calc(100% - 45px)",
          }}
        >
          {reportDetails.app_logo_type === "google_font" ? (
            <span
              class="material-icons-round"
              style={{
                // lineHeight: "18px",
                padding: "4px",
                borderRadius: "20px",
                border: "1px solid #d7dadf",
              }}
            >
              {reportDetails?.sub_category_icon}
            </span>
          ) : (
            <img
              src={reportDetails?.sub_category_icon}
              className="app-icon"
              style={{
                width: "22px",
                height: "22px",
                padding: "4px",
              }}
              alt="upload icon"
            />
          )}
          <p
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {reportDetails.bi_application}
          </p>
        </div>

        <div className="link-icon" style={{ width: "45px" }}>
          {editable && path_has_register && (
            <>
              <span
                style={{
                  // color: "grey",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleEditSubCat(reportDetails);
                }}
                class="material-icons-round"
              >
                edit
              </span>
              <span
                style={{
                  // color: "grey",
                  marginRight: "0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleDelete(reportDetails);
                }}
                class="material-icons-round"
              >
                delete
              </span>
            </>
          )}
        </div>
      </div>

      <div style={{ width: "100%" }}>
        {reportDetails?.sub_category_img_data?.img_url !== "" ? (
          <img
            style={{
              maxWidth: "175px",
              // maxHeight: "83px",
              width: "100%",
              height: "95px",
              objectFit:
                reportDetails.sub_category_img_data.selected_size === "fit"
                  ? "contain"
                  : "cover",
            }}
            alt=""
            src={reportDetails.sub_category_img_data.img_url}
          />
        ) : // <img
        //   style={{
        //     // margin: "10px 0px",
        //     // height: "100%",
        //     // width: "101px",
        //     maxHeight: "100px",
        //     width: "100%",
        //     objectFit: "contain",
        //   }}
        //   alt=""
        //   src={sample_image}
        // />
        null}

        <p
          style={{
            margin: "0px",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "18px",
            letterSpacing: "0.1px",
            padding: "10px 0 10px 0",
            textAlign: "left",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {reportDetails.sub_category_name}
        </p>
        {reportDetails.sub_category_description !== "" && (
          <div className="report-description">
            {reportDetails.sub_category_description}
          </div>
        )}
        <span
          class="material-symbols-outlined"
          style={{
            right: "11px",
            bottom: "9px",
            position: "absolute",
          }}
        >
          arrow_right_alt
        </span>
      </div>
    </div>
  );
};

const ReportCard3 = (props) => {
  const {
    user,
    reportDetails,
    card_width,
    card_height,
    handleEditSubCat,
    handleDelete,
    editable,
    handlePreviewReport,
  } = props;

  const path_has_register =
    window.location.pathname.split("/")[1] === "register";

  return (
    <div
      onClick={() => {
        handlePreviewReport(reportDetails);
      }}
      style={{
        // TODO: we have to convert the width to % and then use it.
        // width: "619px",
        height: "180px",
        fontSize: "0.94vw",
        // display: "flex",
        // alignItems: "center",
        borderRadius: "6px",
        // border: "1px solid #D7DADF",
        background: user.ui_preferences.theme === "light" ? "#FFF" : "#282828",
        color: user.ui_preferences.theme === "dark" ? "#FFF" : "#282828",
        boxShadow: "1px 1px 8px 0px rgba(0, 0, 0, 0.08)",
        padding: "0 10px 0 10px",
        borderLeft: "8px solid #CE2021",
        position: "relative",
      }}
    >
      {/* bi-icon,link, edit and delete */}
      <div className="card-heading">
        <div
          className="link-icon"
          style={{
            width: "calc(100% - 45px)",
          }}
        >
          {reportDetails.app_logo_type === "google_font" ? (
            <span
              class="material-icons-round"
              style={{
                // lineHeight: "18px",
                padding: "4px",
                borderRadius: "20px",
                border: "1px solid #d7dadf",
              }}
            >
              {reportDetails?.sub_category_icon}
            </span>
          ) : (
            <img
              src={reportDetails?.sub_category_icon}
              className="app-icon"
              style={{
                width: "22px",
                height: "22px",
                padding: "4px",
              }}
              alt="upload icon"
            />
          )}
          <p
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {reportDetails.bi_application}
          </p>
        </div>

        <div className="link-icon" style={{ width: "45px" }}>
          {editable && path_has_register && (
            <>
              <span
                style={{
                  // color: "grey",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleEditSubCat(reportDetails);
                }}
                class="material-icons-round"
              >
                edit
              </span>
              <span
                style={{
                  // color: "grey",
                  marginRight: "0px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleDelete(reportDetails);
                }}
                class="material-icons-round"
              >
                delete
              </span>
            </>
          )}
        </div>
      </div>
      {/* image */}
      <div style={{ display: "flex", width: "100%" }}>
        {/* reportDetails?.sub_category_img_data?.img_url !== undefined */}
        {reportDetails?.sub_category_img_data?.img_url !== "" ? (
          <img
            style={{
              // margin: "10px 0px",
              height: "110px",
              width: "30%",
              maxHeight: "110px",
              objectFit:
                reportDetails.sub_category_img_data.selected_size === "fit"
                  ? "contain"
                  : "cover",
            }}
            alt=""
            src={reportDetails.sub_category_img_data.img_url}
          />
        ) : // <img
        //   style={{
        //     // margin: "10px 0px",
        //     // height: "100%",
        //     // width: "101px",
        //     maxHeight: "100px",
        //     width: "100%",
        //     objectFit: "contain",
        //   }}
        //   alt=""
        //   src={sample_image}
        // />
        null}
        {/* app name and description and arrow icon*/}
        <div style={{ paddingLeft: "30px", width: "calc(100% - 131px)" }}>
          {/* report name */}
          <p
            style={{
              width: "calc(100% - 50px)",
              margin: "0px",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "18px",
              letterSpacing: "0.1px",
              paddingBottom: "5px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              textAlign: "left",
            }}
          >
            {reportDetails.sub_category_name}
          </p>
          {/* report description */}
          {reportDetails.sub_category_description !== "" && (
            <div className="report9-description">
              {reportDetails.sub_category_description}
            </div>
          )}
          <span
            class="material-symbols-outlined"
            style={{
              position: "absolute",
              bottom: "20px",
              right: "25px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            arrow_right_alt
          </span>
        </div>
      </div>
    </div>
  );
};

const LayoutReportCard = (props) => {
  const {
    user,
    template_no,
    handleEditSubCat,
    handleDeleteSubCat,
    card_width = "200px",
    card_height = "200px",
    reportDetails = {},
    editable,
    template_number,
    handlePreviewReport = () => {},
  } = props;
  // console.log("reportDetails", reportDetails);
  return (
    <>
      {(template_no === 6 || template_number === 6) && (
        <ReportCard1
          user={user}
          reportDetails={reportDetails}
          card_width={card_width}
          card_height={card_height}
          handleEditSubCat={handleEditSubCat}
          handleDelete={handleDeleteSubCat}
          editable={editable}
          handlePreviewReport={handlePreviewReport}
        />
      )}
      {(template_no === 7 || template_number === 7) && (
        <ReportCard2
          user={user}
          reportDetails={reportDetails}
          card_width={card_width}
          card_height={card_height}
          handleEditSubCat={handleEditSubCat}
          handleDelete={handleDeleteSubCat}
          editable={editable}
          handlePreviewReport={handlePreviewReport}
        />
      )}
      {(template_no === 8 || template_number === 8) && (
        <ReportCard2
          user={user}
          reportDetails={reportDetails}
          card_width={card_width}
          card_height={card_height}
          handleEditSubCat={handleEditSubCat}
          handleDelete={handleDeleteSubCat}
          editable={editable}
          handlePreviewReport={handlePreviewReport}
        />
      )}
      {(template_no === 9 || template_number === 9) && (
        <ReportCard3
          user={user}
          reportDetails={reportDetails}
          card_width={card_width}
          card_height={card_height}
          handleEditSubCat={handleEditSubCat}
          handleDelete={handleDeleteSubCat}
          editable={editable}
          handlePreviewReport={handlePreviewReport}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  template_no: state.appTemplate.appTemplateState.template_no,
  editable: state.registerNewApp.statusData.editLayout,
});

export default connect(mapStateToProps)(LayoutReportCard);
