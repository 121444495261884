import React,{ useState, useEffect, useCallback } from "react";
import { Grid, Box } from "@mui/material";
import runError from "../../../../../assets/images/register-images/run-error.svg";
import runSuccess from "../../../../../assets/images/register-images/run-success.svg";
import { BootstrapInput, statusIconStyle } from "../Styles/Styles";
import Loader from "../../../../components/Loader/Loader";
import {isUrlMatch} from "../../../../utils/utils";
// import styles
import "../../index.scss";
import { checkFieldsAreValid } from "../Utils/Utils";

const InputTextBox = (props) => {
  const {
    handleChange,
    value,
    data,
    setshowWarning,
    state,
    width,
    isFieldsEditable,
    waiting,
    isAppUnique,
    validationMsg,
    hasError,
    isFieldVibrating,
    isFieldRed,
    bi_application,
    URLValid,
    setURLValid,
    theme
  } = props;
  const { label, key, required } = data;
  // const handleBlur = useCallback(
  //   (value) => {
  //     const handleURLValidation = (val) => {
  //       if (!val.startsWith("https://")) {
  //         setURLValid({
  //           status: "Invalid",
  //           msg: "URL should start with (https://)",
  //         });
  //       } else if (!val.slice(8).trim().length) {
  //         setURLValid({
  //           status: "Invalid",
  //           msg: "Please Enter valid url",
  //         });
  //       } else if (!isUrlMatch(bi_application, val)) {
  //         setURLValid({
  //           status: "Invalid",
  //           msg: "Please Enter valid url",
  //         });
  //       } else {
  //         setURLValid({
  //           status: "Valid",
  //           msg: "",
  //         });
  //       }
  //     };
  //     if (key === "url" && value) {
  //       handleURLValidation(value);
  //     }
  //     return;
  //   },
  //   [key, setURLValid, bi_application]
  // );
  useEffect(()=>{
    setURLValid({ status: "pending", msg: "" })
  },[])
  const handleBlur = useCallback(() => {
    if (state !== null && key === "url") {
      if (!checkFieldsAreValid(state, setURLValid)) {
        setshowWarning(true);
      } else {
        setshowWarning(false);
      }
    } else {
      setshowWarning(false);
    }
  }, [state, key, setURLValid, checkFieldsAreValid]);

  useEffect(() => {
    if (!value) return;
    handleBlur();
  }, [state.bi_application]);


  return (
    <Grid item xs={width || 6}>
      <Box display="flex" alignItems="center" className={`heading-input ${theme}`}>
        <div>
          {key === "sub_category_name"?"Report Name":label}{" "}
          <span
            style={{
              display: required || key === "url" ? "" : "none",
            }}
          >
            *
          </span>
          :
        </div>
        {key === "description" && (
          <Box ml="auto">
            <span
              style={{
                color:
                  (value && value.length > 500) || !value ? "red" : "inherit",
              }}
            >
              {value ? value.length : 0}
            </span>{" "}
            / 500
          </Box>
        )}
      </Box>
      <Box position={"relative"}>
        <BootstrapInput
          onChange={(e) => handleChange(key, e.target.value.trimStart())}
          value={value}
          onBlur={() => handleBlur()}
          multiline={
            key === "description" || key === "sub_category_description"
          }
          placeholder={
            key === "sub_category_name" ? "Enter text here" : `Enter ${label}`
          }
          disabled={isFieldsEditable}
          className={`${isFieldVibrating && "shake-category-field"} ${
            hasError || isFieldRed ? "error" : ""
          }`}
          fullWidth
          maxRows={
            key === "description" || key === "sub_category_description" ? 4 : 1
          }
          style={{
            padding: "0px",
          }}
        />
        {hasError && (
          <div className="validation_msg" style={{ color: "red" }}>
            This field is required*
          </div>
        )}
        {key === "app_name" && (
          <>
            <Box position={"absolute"} right={0} top="2px" zIndex={10}>
              {waiting && <Loader />}
              {!waiting && isAppUnique ? (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              ) : null}
              {!waiting && !isAppUnique && validationMsg !== "" && (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Already exists"
                  alt="Failed"
                />
              )}
            </Box>
            {validationMsg && !isAppUnique && !waiting && (
              <div
                className="validation_msg"
                style={{ color: isAppUnique ? "green" : "red" }}
              >
                {validationMsg}
              </div>
            )}
          </>
        )}
        {key === "url" && (
          <>
            <Box position={"absolute"} right={0} top="2px" zIndex={10}>
              {URLValid.status === "Valid" && (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              )}
              {URLValid.status === "Invalid" && (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Invalid URL"
                  alt="Failed"
                />
              )}
            </Box>
            {URLValid.status === "Invalid" && (
              <div className="validation_msg" style={{ color: "red" }}>
                {URLValid.msg}
              </div>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default InputTextBox;
