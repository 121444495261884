import React from "react";
import "../index.scss";

const Card = ({
    icon,
    name,
    handleChange,
    data = [],
    keyField,
    defaultList,
    value,
    theme,
}) => {
    return (
        <div
            className={`GA-${keyField} setup-card ${theme}-setup-card ${
                data.includes(name) ||
                (name === "Others" &&
                    value &&
                    !defaultList.includes(value) &&
                    data.includes(value))
                    ? "setup-card-selected"
                    : ""
            }`}
            title={name}
            onClick={() => handleChange(keyField, name)}
            id={name}
        >
            <img
                className={`GA-${keyField} setup-card-icon`}
                id={name}
                src={icon}
                alt=""
            />
            <div className={`GA-${keyField} setup-card-heading`} id={name}>
                {name}
            </div>
            {name === "Others" &&
                value &&
                !defaultList.includes(value) &&
                data.includes(value) && (
                    <div
                        className="setup-card-subheading"
                        title={value.length > 18 ? value : ""}
                    >{`(${
                        value.length > 18 ? `${value.slice(0, 18)}...` : value
                    })`}</div>
                )}
        </div>
    );
};

export default Card;
