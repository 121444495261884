import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import equal from "deep-equal";
import { Box, Grid, Dialog } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import RegisterBodyContainer from "../components/RegisterBodyContainer";
import DashboardTemplate from "../components/DashboardTemplate";

import TemplateImg_1 from "../../../../assets/images/register-images/Template_1.svg";
import TemplateImg_2 from "../../../../assets/images/register-images/Template_2.svg";
import TemplateImg_3 from "../../../../assets/images/register-images/Template_3.svg";
import TemplateImg_4 from "../../../../assets/images/register-images/Template_4.svg";
import TemplateImg_5 from "../../../../assets/images/register-images/Template_5.svg";

import TemplateImg_1_dark from "../../../../assets/images/register-images/Template_1_dark.svg";
import TemplateImg_2_dark from "../../../../assets/images/register-images/Template_2_dark.svg";
import TemplateImg_3_dark from "../../../../assets/images/register-images/Template_3_dark.svg";
import TemplateImg_4_dark from "../../../../assets/images/register-images/Template_4_dark.svg";
import TemplateImg_5_dark from "../../../../assets/images/register-images/Template_5_dark.svg";

// Import Actions
import { updateReportTemplate } from "../../../redux/actions";

// Import UTILS
import {
  getDefaultTempData,
  getTemplateNumberInString,
} from "../components/DashboardTemplate/utils";
import Loader from "../../../components/Loader/Loader";
import { useParams } from "react-router";
import WorkspaceDrawer from "../components/DashboardTemplate/Components/WorkspaceDrawer";
import AppLayout from "../../NewRegister/pages/AppLayout";

const templatesImages = {
  Template_1: TemplateImg_1,
  Template_2: TemplateImg_2,
  Template_3: TemplateImg_3,
  Template_4: TemplateImg_4,
  Template_5: TemplateImg_5,
};
const templatesImagesDark = {
  Template_1: TemplateImg_1_dark,
  Template_2: TemplateImg_2_dark,
  Template_3: TemplateImg_3_dark,
  Template_4: TemplateImg_4_dark,
  Template_5: TemplateImg_5_dark,
};

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiDialog-paper": {
      overflowY: "hidden",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1100px !important",
      minWidth: "1100px !important",
      padding: "5px",
      background: theme.palette.mode === "dark" ? "#36424E" : "white",
    },
  },
  templateImg: {
    // height: "280px",
    border: "1px solid transparent",
    borderRadius: "5px",
    height: "-webkit-fill-available",
    width: "-webkit-fill-available",
  },
  templateSelected: {
    // height: "280px",
    border: "1px solid #FC007F",
    borderRadius: "5px",
    height: "-webkit-fill-available",
    width: "-webkit-fill-available",
  },
  configLanding: {
    display: "flex",
    flexDirection: "column",
    zIndex: 10,
    height: "100%",
    // position: "relative",
  },
  headerText: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "19px",
    color: theme.palette.mode === "light" ? "#FC007F" : "white",
    margin: "10px 0 5px 0",
    textAlign: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    // height: "calc(100vh - 291px)",
    // overflow: "auto",
    // position: "relative",
  },
  imgInnerContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    paddingTop: "10px",
  },
}));

const ChangeTemplatePopUp = (props) => {
  const {
    open = false,
    setOpenChangeTemplatePopUp,
    setCategoryInfo,
    setCategoryInfoLocal,
    register_template,
    theme,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { template_no } = useSelector(
    (state) => state.appTemplate.appTemplateState
  );
  const { fields } = register_template;

  const handleClose = () => setOpenChangeTemplatePopUp(false);

  //60//30
  const handleChangeTemplate = (template) => {
    const defaultValues = getDefaultTempData(template, fields);
    if (template === "Template_1") {
      dispatch(
        updateReportTemplate({
          template_no: template,
          templateData: [],
          temp_img_data: {},
        })
      );
      setCategoryInfo(defaultValues);
      setCategoryInfoLocal(defaultValues);
    } else {
      dispatch(
        updateReportTemplate({
          template_no: template,
          templateData: defaultValues,
          temp_img_data: {},
        })
      );
      setCategoryInfo([]);
      setCategoryInfoLocal([]);
    }
  };

  const handleCreateCustom = () => {
    handleChangeTemplate("Template_5");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialogContainer}
    >
      <div style={{ overflow: "auto" }}>
        <div className={classes.headerText}>
          Choose a template for your Application
        </div>
        <Grid container spacing={2} sx={{ p: "10px" }}>
          {["Template_1", "Template_2", "Template_3", "Template_4"].map(
            (item) => (
              <Grid item xs={6} key={item} sx={{ p: "10px" }}>
                <img
                  loading="lazy"
                  className={`GA-template ${
                    template_no === item
                      ? classes.templateSelected
                      : classes.templateImg
                  }`}
                  onClick={() => handleChangeTemplate(item)}
                  src={
                    theme === "light"
                      ? templatesImages[item]
                      : templatesImagesDark[item]
                  }
                  alt={item}
                  id={item}
                />
              </Grid>
            )
          )}
        </Grid>
      </div>
      <div className={classes.footer}>
        <button
          style={{ marginLeft: "12px" }}
          className="btn GA-template"
          id="Create your own"
          onClick={handleCreateCustom}
        >
          Create your own
        </button>
        <button
          style={{ marginRight: "8px" }}
          className="btn"
          onClick={handleClose}
        >
          Done
        </button>
      </div>
    </Dialog>
  );
};

const ApplicationTemplate = (props) => {
  const {
    templates,
    setCategoryInfo,
    categoryInfo,
    appFields,
    appTemplateState,
    setActiveStepper,
    appInfo,
    isAdminRegistering,
    waiting,
    handleSubmit,
    visited,
    setVisited,
    draft_app_status,
    isChangesSubmitable,
    isDetailsSubmitable,
    handleDraftSubmit,
    setCategoryInfoLocal,
    isFieldsEditable,
    register_template,
    appDetails,
    theme,
    setSelectedWorkspace,
    selectedWorkspace,
    prevWorkspace,
    setPrevWorkspace,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { draftId } = useParams();
  const { template_no, temp_img_data } = appTemplateState;
  const [openChangeTemplatePopUp, setOpenChangeTemplatePopUp] = useState(false);
  const [openCreateTemplatePopUp, setopenCreateTemplatePopUp] = useState(false);
  const [openWorkspaceDrawer, setOpenWorkspaceDrawer] = useState(false);

  const handleBack = () => setActiveStepper((prev) => prev - 1);
  const isSubmitBtnDisabled = useMemo(() => {
    return (
      categoryInfo.length === 0 ||
      (appInfo &&
        equal(categoryInfo, appDetails.data.app_details?.defined_categories))
    );
  }, [appDetails.data.app_details?.defined_categories, appInfo, categoryInfo]);

  const handleBegin = () => {
    if (template_no === "Template_1") {
      dispatch(
        updateReportTemplate({
          showSubCatPage: true,
        })
      );
    } else {
      setopenCreateTemplatePopUp(true);
    }
  };
  const handleChangeTemp = () => {
    if (appInfo || isAdminRegistering) return;
    setOpenChangeTemplatePopUp(true);
  };

  const handleDraftFunction = () => {
    handleDraftSubmit("Categories", categoryInfo);
  };

  const handleOpenImportWorkspace = () => {
    setOpenWorkspaceDrawer(true);
  };

  const handleCloseImportWorkspace = () => {
    setOpenWorkspaceDrawer(false);
  };

  useEffect(() => {
    if (!visited.category) {
      if (appFields.app_details?.defined_categories?.length) {
        dispatch(
          updateReportTemplate({
            templateData: appFields.app_details.defined_categories,
            template_no: getTemplateNumberInString(
              appFields.app_details.chosen_template
            ),
            temp_img_data: appDetails.data?.app_details?.temp_img_data || {},
          })
        );
      } else {
        const template = getTemplateNumberInString(3);
        dispatch(
          updateReportTemplate({
            template_no: template,
            templateData: getDefaultTempData(template),
            temp_img_data: {},
          })
        );
      }
      setVisited({ ...visited, category: true });
    }
  }, []);

  return (
    <>
      {openWorkspaceDrawer && (
        <WorkspaceDrawer
          open={openWorkspaceDrawer}
          handleClose={handleCloseImportWorkspace}
          handleBegin={handleBegin}
          setSelectedWorkspace={setSelectedWorkspace}
          selectedWorkspace={selectedWorkspace}
          appFields={appFields}
        />
      )}
      {openChangeTemplatePopUp && (
        <ChangeTemplatePopUp
          open={openChangeTemplatePopUp}
          setOpenChangeTemplatePopUp={setOpenChangeTemplatePopUp}
          setCategoryInfo={setCategoryInfo}
          setCategoryInfoLocal={setCategoryInfoLocal}
          register_template={register_template}
          theme={theme}
        />
      )}
      {openCreateTemplatePopUp && (
        <DashboardTemplate
          appFields={appFields}
          template_no={template_no}
          setopenCreateTemplatePopUp={setopenCreateTemplatePopUp}
          open={openCreateTemplatePopUp}
          setCategoryInfo={setCategoryInfo}
          categoryInfo={categoryInfo}
          setCategoryInfoLocal={setCategoryInfoLocal}
          isFieldsEditable={isFieldsEditable}
          selectedWorkspace={selectedWorkspace}
          setSelectedWorkspace={setSelectedWorkspace}
          prevWorkspace={prevWorkspace}
          setPrevWorkspace={setPrevWorkspace}
          setOpenWorkspaceDrawer={setOpenWorkspaceDrawer}
        />
      )}
      <RegisterBodyContainer height="245px">
        {openCreateTemplatePopUp && <AppLayout />}
        {!openCreateTemplatePopUp && (
          <div className={classes.configLanding}>
            <div className={classes.imgContainer}>
              {!(appInfo || isAdminRegistering) && (
                <Box position="absolute" right="5%" top="160px" zIndex={10}>
                  {
                    <button
                      className="btn GA-import-workspace"
                      style={{
                        marginRight: "10px",
                        background: "#282828",
                      }}
                      onClick={handleOpenImportWorkspace}
                    >
                      Import Workspace
                    </button>
                  }
                  <button
                    className="btn"
                    id="GA-change-temp-btn"
                    onClick={handleChangeTemp}
                  >
                    Change Template
                  </button>
                </Box>
              )}
              <img
                style={{ height: "400px", position: "absolute", top: "210px" }}
                src={
                  temp_img_data?.url
                    ? temp_img_data?.url
                    : theme === "light"
                    ? templatesImages[template_no]
                    : templatesImagesDark[template_no]
                }
                alt=""
              />
            </div>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={3}
              mt={2}
              position="relative"
            >
              {!(
                appInfo ||
                isAdminRegistering ||
                template_no === "Template_1"
              ) && (
                <button
                  className="btn-draft"
                  onClick={handleDraftFunction}
                  disabled={
                    draft_app_status.status === "loading" ||
                    waiting ||
                    (draftId && !(isChangesSubmitable || isDetailsSubmitable))
                  }
                >
                  {draft_app_status.status === "loading" ? (
                    <Loader color="#ffffff" />
                  ) : (
                    "Save as Draft"
                  )}
                </button>
              )}

              {!(appInfo || isAdminRegistering) && (
                <button onClick={handleBack} className="btn-back">
                  Back
                </button>
              )}
              <button className="btn btn-next" onClick={handleBegin}>
                {categoryInfo.length ? "Edit Report" : "Begin"}
              </button>
              {!(template_no === "Template_1" || isFieldsEditable) && (
                <button
                  className={`btn btn-next`}
                  id={(appInfo || isAdminRegistering) && "GA-update-btn"}
                  onClick={() => handleSubmit()}
                  disabled={waiting || isSubmitBtnDisabled}
                  style={{
                    position: "absolute",
                    right: 0,
                  }}
                  title={
                    categoryInfo.length === 0
                      ? "Complete your report and submit."
                      : ""
                  }
                >
                  {waiting ? (
                    <Loader color="#ffffff" margin="0px 8px" />
                  ) : appInfo || isAdminRegistering ? (
                    "Update Request"
                  ) : (
                    "Submit"
                  )}
                </button>
              )}
            </Box>
          </div>
        )}
      </RegisterBodyContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  allApps: state.AppsData.allApps,
  theme: state.user.ui_preferences?.theme,
  appTemplateState: state.appTemplate.appTemplateState,
  appDetails: state.AppsData.appDetails,
  draft_app_status: state.register.register_draft_app,
});

export default connect(mapStateToProps)(ApplicationTemplate);
