import React from "react";
import { Skeleton } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiSkeleton-root": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : "#e5e5e5",
        },
    },
}));
export const NewTicketSkeletonLoader = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.root}`}>
            <Skeleton animation="wave" variant="text" height={100} />
            <Skeleton animation="wave" variant="text" width="33%" height={60} />
            <Skeleton animation="wave" variant="text" width="33%" height={60} />
            <Skeleton
                animation="wave"
                variant="text"
                height={150}
                width="100%"
            />
            <Skeleton
                animation="wave"
                variant="text"
                height={150}
                width="100%"
            />
            <Skeleton animation="wave" variant="text" height={80} />
        </div>
    );
};
