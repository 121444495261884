import React from "react";

// Import MUI components
import { Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";

// Import Styles
import { useStylesForDashboardTemplate } from "../style";
import { StyleSwitch } from "../style";

// Import custom components

const SwitchField = (props) => {
  const { tooltip_text = "", handleChange, data, value } = props;
  const { label, key } = data;
  const classes = useStylesForDashboardTemplate();
  return (
    <Box
      display="flex"
      flexDirection={"row"}
      alignItems={"center"}
      width={"100%"}
      gap={0}
      paddingLeft={1}
      paddingTop={1}
    >
      <div style={{ marginTop: "0px" }} className={classes.heading}>{`${label} :`}</div>
      <Box display="flex" alignItems="center">
        <div
          style={{ marginTop: "0px" }}
          className={`${classes.heading} ${value && classes.lightText}`}
        >
          No
        </div>
        <StyleSwitch
          label="start"
          onChange={(e) => handleChange(key, e.target.checked)}
          checked={value || false}
          sx={{ mx: 1 }}
        />
        <div
          style={{ marginTop: "0px" }}
          className={`${classes.heading} ${!value && classes.lightText}`}
        >
          Yes
        </div>
        {tooltip_text !== "" && (
          <Tooltip placement="top" title={tooltip_text}>
            <InfoIcon
              sx={{
                fontSize: "16px",
                ml: "5px",
                color: "#8d9ca6",
              }}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default SwitchField;
