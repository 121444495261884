import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { makeStyles, withStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        padding: "8px 16px",
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.dark.light : "white",
        // width: "300px",
        height: "20px",
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "23px",
        borderRadius: "50px",
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#282828",
    },
    "& .Mui-disabled": {
        cursor: "pointer",
    },
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        fontFamily: "Energy",
        backgroundColor: "#f3f3f3",
        // backgroundColor: 'rgba(230 233 238)',
        borderRadius: "4px",
        borderColor: "transparent",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "23px",
        padding: "8px",
        border: "transparent",
    },
    "&:focus": {},
}));

export const CssTextField = withStyles((theme) => ({
    root: {
        // backgroundColor: "#fff",
        "& .MuiInputBase-root": {
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : theme.palette.dark.light,
        },
        backgroundColor:
            theme.palette.mode === "dark"
                ? theme.palette.dark.light
                : theme.palette.white.main,
        padding: "3px 10px 3px 0px",
        borderRadius: "5px",
        border: "1px solid #28282840",
        marginBottom: "5px",
        // boxShadow: "0px 5px 15px 0px #28282814",
        "& label.Mui-focused": {
            color: "green",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#dedede",
        },
        "& .MuiOutlinedInput-root": {
            // backgroundColor: "white",
            "& fieldset": {
                borderColor: "#dedede",
            },
            "&:hover fieldset": {
                borderColor: "#dedede",
            },
            "&.Mui-focused fieldset": {
                border: "0.5px solid #dedede",
            },
        },
    },
}))(TextField);

export const StyledAutoComplete = withStyles((theme) => ({
    tag: {
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : theme.palette.dark.light,
        backgroundColor:
            theme.palette.mode === "dark"
                ? theme.palette.dark.light
                : theme.palette.white.main,
        fontFamily: "Energy !important",
    },
    paper: {
        fontFamily: "Energy",
        fontSize: 13,
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#282828",
        backgroundColor:
            theme.palette.mode === "dark"
                ? theme.palette.dark.light
                : theme.palette.white.main,
        "& .MuiAutocomplete-option": {
            paddingLeft: "10px",
        },
    },
}))(Autocomplete);

export const useStyles = makeStyles((theme) => ({
    typographyStyle: {
        "& .MuiChip-root": {
            fontFamily: "Energy",
            fontSize: 12,
        },
        "& .MuiInput-underline:after": {
            borderBottom: 0,
        },
        "& .MuiInput-underline:before": {
            borderBottom: 0,
            transition: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "0px solid #dedede !important",
        },
        "& .Mui-focused .MuiInput-underline": {
            borderBottom: 0,
        },
        "& .MuiAutocomplete-option": {
            fontFamily: "Energy",
            fontSize: 12,
            paddingLeft: "10px",
        },
        "& .MuiAutocomplete-paper": {
            fontFamily: "Energy",
            fontSize: 6,
        },
        "& .MuiInputBase-input": {
            fontFamily: "Energy",
            fontSize: 12,
            padding:"0px !important"
            // color: "#282828",
        },
        "& .MuiOutlinedInput-root": {
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
        },
        "& .MuiAutocomplete-popupIndicator": {
            marginRight: "-7px",
            "& span": {
                "& svg": {
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
                    },
                },
            },
        },
        "& .MuiAutocomplete-clearIndicator": {
            marginRight: "-8px",
        },
        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: "14px",
        },
        "& .MuiIconButton-root": {
            fontSize: "14px",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "14px",
        },
        "& .MuiAutocomplete-inputRoot": {
            paddingRight: "18px !important",
            paddingLeft: "7px",
        },
    },
    root: {
        "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
            transform: "translate(34px, 20px) scale(1);",
            fontFamily: "Energy",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "5px",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
            opacity: 0.5,
        },
    },
    select: {
        fontFamily: "Energy !important",
        fontSize: "16px!important",
        fontStyle: "normal!important",
        fontWeight: "400!important",
    },
    comboOptions: {
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#282828",
    },
    popupIndicator: {
        "& span": {
            "& svg": {
                "& path": {
                    d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
                },
            },
        },
    },
    inputRoot: {
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#282828",
        background: "#ededed",
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
            {
                paddingLeft: 26,
                fontFamily: "Energy",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "23px",
                letterSpacing: "0em",
                textAlign: "left",
                color:
                    theme.palette.mode === "dark"
                        ? theme.palette.white.main
                        : "#282828",
            },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fafafa",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fafafa",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fafafa",
        },
    },
    checkbox: {
        "& .MuiCheckbox-root": {
            padding: 0,
        },
    },
    active: {
        "&.MuiFormControl-root": {
            backgroundColor: "#FBEDF2",
            // color: "#fff",
            color: "rgba(255,255,255,0.8) !important",
        },
        "& .MuiInputBase-root": {
            // color: "#fff",
            color: "#d8477e !important",
        },
        "& .MuiSvgIcon-root": {
            // color: "#fff",
            color: "#d8477e",
        },
    },
    activeAuto: {
        "&.MuiFormControl-root": {
            backgroundColor: "#FBEDF2",
            color: "#fff",
            // color: "rgba(255,255,255,0.5)",
        },
        "& .MuiInputBase-root": {
            color: "#d8477e",
            // color: "rgba(255,255,255,0.5)",
        },
        "& .MuiSvgIcon-root": {
            color: "#d8477e",
            // color: "rgba(255,255,255,0.5)",
        },
    },
    unActive: {
        "&.MuiFormControl-root": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : "white",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
        "& .MuiInputBase-root": {
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
        "& .MuiSvgIcon-root": {
            color: "#FC007F",
        },
    },
    SecFilUnActive: {
        "&.MuiFormControl-root": {
            // backgroundColor:
            //     theme.palette.mode === "dark" ? "#4e4e4e" : "#f6f6f6",
                color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
        },
        "& .MuiInputBase-root": {
            color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
        },
        "& .MuiSvgIcon-root": {
            color: theme.palette.mode === "dark" ? "#fff" : "#4e4e4e",
        },
    },
}));
