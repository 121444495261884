import React from "react";
import { FormControlLabel, Grid, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "../index.scss";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" htmlColor="#FF007F" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckBoxComponent = ({
  data,
  width,
  value = false,
  handleChange,
  isFieldsEditable,
  theme,
}) => {
  const { label, key } = data;
  return (
    <Grid item xs={width || 12} textAlign="start">
      <FormControlLabel
        sx={{
          "& .MuiFormControlLabel-label": {
            fontFamily: "Energy !important",
            fontStyle: "normal !important",
            fontWeight: "400 !important",
            fontSize: "12px !important",
            lineHeight: "24px !important",
            color: value
              ? theme === "dark"
                ? "#b1b1b1"
                : "#4e4e4e"
              : "#8D9CA7",
            marginLeft: "3px",
          },
        }}
        control={
          <Checkbox
            checked={Boolean(value)}
            icon={icon}
            checkedIcon={checkedIcon}
            sx={{
              width: "24px",
              height: "24px",
              "&.Mui-checked": {
                color: "#ea3592",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "1.2rem",
              },
            }}
            disabled={isFieldsEditable}
            onChange={(e) => handleChange(key, e.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={label}
      />
    </Grid>
  );
};

export default CheckBoxComponent;
