import React, { useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import RegisterBodyContainer from "../Components/RegisterBodyContainer";
import FooterContainer from "../Components/FooterContainer";

import runError from "../../../../assets/images/register-images/run-error.svg";
import runSuccess from "../../../../assets/images/register-images/run-success.svg";
//hooks
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
//styles
import { BootstrapInput, statusIconStyle } from "../style";
import "../index.scss";
import { update_activeStepper } from "../../../redux/actions";
export const InputField = (props) => {
  const {
    handleChange,
    value = "",
    data,
    isFieldsEditable,
    width,
    app_url_uniqueness,
    hasError,
    theme,
  } = props;
  const { label, key, required } = data;
  const { status, message } = app_url_uniqueness;
  return (
    <Grid item md={width || 12} xs={12}>
      <div className={`heading ${theme}`}>
        {label} <span style={{ display: required ? "" : "none" }}>*</span>
      </div>
      <Box display="flex" alignItems="center" position="relative">
        <BootstrapInput
          onChange={(e) => handleChange(key, e.target.value)}
          value={value}
          placeholder={`Enter ${label}`}
          disabled={isFieldsEditable || key === "app_name"}
          fullWidth
          className={hasError ? "error" : ""}
        />
        {key === "app_url" && (
          <>
            <Box position={"absolute"} right={0} top="3px" zIndex={10}>
              {status === "loading" && key === "app_url" && <Loader />}
              {status === "success" && value && (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              )}
              {status === "error" && value && (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Already exists"
                  alt="Failed"
                />
              )}
            </Box>
          </>
        )}
      </Box>
      {key === "app_url" && status === "error" && (
        <div className="validation_msg" style={{ color: "red", fontSize: 14 }}>
          {message}
        </div>
      )}
      {hasError && (
        <div className="validation_msg" style={{ color: "red" }}>
          This field is required*
        </div>
      )}
    </Grid>
  );
};

const StandaloneConfig = ({
  waiting,
  // setActiveStepper,
  templates,
  isFieldsEditable,
  appInfo,
  handleSubmit,
  isAdminRegistering,
  handleChange,
  appFields,
  app_url_uniqueness,
  isChangesSubmitable,
  appDetails,
  handleDraftSubmit,
  draft_app_status,
  draft_id,
  isDetailsSubmitable,
  activeStepper,
  theme,
}) => {
  const dispatch = useDispatch();
  const eventTracker = useAnalyticsEventTracker(
    "Standalone Configurations",
    appDetails.data.app_id
  );
  const [hasError, setHasError] = useState({ app_url: false });

  const isBtnDisabled = useMemo(() => {
    const result =
      appFields.app_name &&
      appFields.app_url?.trim() &&
      appFields?.app_details?.description &&
      !waiting &&
      appFields.app_details?.function.length &&
      appFields.app_details?.line_of_business.length &&
      //checking either app_url updated and unique or didn't updated and matching with the old one while updating stage.
      (app_url_uniqueness.status === "success" ||
        ((appInfo || isAdminRegistering || draft_id) &&
          appDetails.data.app_meta?.app_url === appFields.app_url)) &&
      // either app is in the register state or in the draft state or if in update state then made changes or not
      ((!appInfo && !isAdminRegistering) || draft_id || isChangesSubmitable);

    return !result;
  }, [
    appDetails.data.app_meta?.app_url,
    appFields.app_details?.description,
    appFields.app_details?.function.length,
    appFields.app_details?.line_of_business.length,
    appFields.app_name,
    appFields.app_url,
    appInfo,
    app_url_uniqueness.status,
    draft_id,
    isAdminRegistering,
    isChangesSubmitable,
    waiting,
  ]);

  const isDraftBtnDisabled = useMemo(() => {
    const rslt =
      !(
        (appFields.app_url?.trim() &&
          (app_url_uniqueness.status === "success" ||
            (draft_id &&
              appDetails.data.app_meta?.app_url === appFields.app_url))) ||
        !appFields.app_url?.trim()
      ) ||
      draft_app_status.status === "loading" ||
      waiting ||
      (draft_id && !(isChangesSubmitable || isDetailsSubmitable));
    return rslt;
  }, [
    appDetails.data.app_meta?.app_url,
    appFields.app_url,
    app_url_uniqueness.status,
    draft_app_status.status,
    draft_id,
    isChangesSubmitable,
    isDetailsSubmitable,
    waiting,
  ]);

  const removeErrorMsg = () => {
    setTimeout(() => {
      setHasError({ app_url: false });
    }, [1500]);
  };

  const submit = () => {
    if (!isBtnDisabled) {
      handleSubmit();
    } else if (!appFields.app_url) {
      setHasError({ app_url: true });
      removeErrorMsg();
    }
  };

  const handleBack = () => {
    eventTracker("Back btn clicked", "clicked");
    // setActiveStepper((prev) => prev - 1);
    dispatch(update_activeStepper(activeStepper - 1));
  };

  const getRequiredComponent = (data, theme) => {
    const { type, key } = data;
    switch (type) {
      case "input":
        return (
          <InputField
            data={data}
            handleChange={handleChange}
            value={appFields[key]?.trim()}
            isFieldsEditable={isFieldsEditable}
            width={6}
            app_url_uniqueness={app_url_uniqueness}
            hasError={hasError[key] || false}
            theme={theme}
          />
        );
      default:
        return "";
    }
  };

  return (
    <>
      <RegisterBodyContainer
      // height={appInfo || isAdminRegistering ? "315px" : ""}
      >
        {/* Main Content */}
        <div
          style={{
            height: "calc(100vh - 300px)",
          }}
        >
          {/* Heading */}
          <div
            className="accordion-heading"
            style={{
              marginBottom: "14px",
            }}
          >
            General
          </div>
          {/* contents */}
          <Grid container spacing={2}>
            {templates?.fields?.map((item) => {
              if (item.key === "app_name") {
                return (
                  <>
                    {getRequiredComponent(item, theme)}
                    <Grid item md={6} xs={12}></Grid>
                  </>
                );
              } else {
                return <>{getRequiredComponent(item, theme)}</>;
              }
            })}
          </Grid>
        </div>
      </RegisterBodyContainer>
      <FooterContainer>
        <div style={{ marginTop: "-20px" }}>
          {!(appInfo || isAdminRegistering) && (
            <>
              {/* Save As Draft button */}
              <button
                className="btn-draft"
                onClick={() => {
                  handleDraftSubmit("Standalone configurations");
                }}
                disabled={isDraftBtnDisabled}
              >
                {draft_app_status.status === "loading" ? (
                  <Loader color="#ffffff" />
                ) : (
                  "Save as Draft"
                )}
              </button>
              <button
                className="btn-back"
                onClick={handleBack}
                disabled={
                  draft_app_status.status === "loading" ||
                  app_url_uniqueness.status === "loading"
                }
              >
                Back
              </button>
            </>
          )}
          {!isFieldsEditable && (
            <button
              onClick={submit}
              className={`btn btn-next ${
                isBtnDisabled || draft_app_status.status === "loading"
                  ? "btn-disabled"
                  : ""
              }`}
              id={(appDetails?.data?.app_id || draft_id) && "GA-update-btn"}
              disabled={draft_app_status.status === "loading"}
            >
              {waiting ? (
                <Loader color="#ffffff" margin="0px 8px" />
              ) : appInfo || isAdminRegistering ? (
                "Update Request"
              ) : (
                "Submit"
              )}
            </button>
          )}
        </div>
      </FooterContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeStepper: state.registerNewApp.activeStepper,
  // statusData
  isChangesSubmitable: state.registerNewApp.statusData.isChangesSubmitable,
  isDetailsSubmitable: state.registerNewApp.statusData.isDetailsSubmitable,
  waiting: state.registerNewApp.statusData.waiting,
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  // appData
  appFields: state.registerNewApp.appData.appFields,
  appDetails: state.AppsData.appDetails,
  app_url_uniqueness: state.register.app_url_uniqueness,
  draft_app_status: state.register.register_draft_app,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(StandaloneConfig);
