import React, { useState, useCallback, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { v4 } from "uuid";
import { toast } from "react-toastify";
import _ from "lodash";
import { convertToRaw } from "draft-js";
import { CompactPicker } from "react-color";
// Import MUI components
import Dialog from "@material-ui/core/Dialog";
import { Box, Grid } from "@mui/material";

// Import Custom Components
import PreviewComponent from "./PreviewComponent";
import EditPreviewToggle from "../../EditPreviewToggle";

// Import Styles
import { useStylesForDashboardTemplate } from "../style";

import PowerBiDrawer from "./PowerBiDrawer";
// Import UTILS / Functions
import {
  getRequiredComponent,
  handleHideComponent,
  handleGetErrorMessage,
  getComponentsForReports,
} from "../utils";
import {
  updateReportTemplate,
  update_statusData,
} from "../../../../../redux/actions";
import {
  getDefaultEditorData,
  getEditorState,
  isUrlMatch,
  specialFeilds,
} from "../../../../../utils/utils";
import { config } from "../../../../../config/config";

const initialState1 = (y, x, widget_type, appFields, user) => {
  const initialID = v4();
  return {
    additional_images: [],
    app_logo_type: "google_font",
    bi_application: "Power BI",
    blob_name: "analytics",
    contains_sub_category: true,
    show_description: false,
    display_subtiles: false,
    embedded_report: false,
    function: appFields.app_details.function,
    line_of_business: appFields.app_details.line_of_business,
    business_owners: [user.preferred_username],
    technical_owners: [user.preferred_username],
    geography: appFields.app_details.geography,
    position_meta: {
      h: 2,
      i: initialID,
      w: 3,
      x: x || 0,
      y: y || 0,
    },
    sub_category: [],
    sub_category_description: "",
    sub_category_icon: "",
    sub_category_id: initialID,
    sub_category_name: "",
    sub_category_type: widget_type || "report",
    url: "",
    view_count: 0,
    card_bg_color: "",
  };
};

const AddSubcategoryPopUp = (props) => {
  const {
    open = false,
    setopenAddReportPopUp,
    registerTemplateFields,
    registerFields,
    appTemplateState,
    subcategoryState, // to be sent from parent in case of (update)
    breadcrumbArr,
    allSubcats = [],
    setRenderSubReportArr,
    widget_type,
    setSelectedWidgetType,
    parentID,
    setParentID,
    renderSubReportArr,
    parent_report_type,
    setParentReportType,
    appFields,
    user,
    theme,
    editPreviewToggleReport,
  } = props;

  const classes = useStylesForDashboardTemplate();
  const dispatch = useDispatch();
  const [localState, setlocalState] = useState({});
  const [URLValid, setURLValid] = useState({ status: "pending", msg: "" });
  const [showWarning, setshowWarning] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [previewDisable, setPreviewDisable] = useState(false);
  const [editorState, setEditorState] = useState({
    sub_category_name: getDefaultEditorData(),
    sub_category_description: getDefaultEditorData(),
  });
  const getMaxValue = (myData, k) => {
    const data = myData.reduce(function (prev, current) {
      if (
        +current.position_meta[k] > +prev.position_meta[k] &&
        current.sub_category_type !== "sub_header"
      ) {
        return current;
      } else {
        return prev;
      }
    });
    return data;
  };
  const getHightestY = useCallback(() => {
    if (!allSubcats.length) return { x: 0, y: 0 };
    const max_y = getMaxValue(allSubcats, "y");
    const x_in_y = allSubcats.filter(
      (item) => item.position_meta.y === max_y.position_meta.y
    );
    const max_x_in_y = getMaxValue(x_in_y, "x");
    if (
      max_x_in_y?.position_meta &&
      max_x_in_y.position_meta.x + max_x_in_y.position_meta.w > 21
    ) {
      return {
        x: 0,
        y: max_x_in_y.position_meta.y + max_x_in_y.position_meta.h,
      };
    } else if (max_x_in_y.position_meta) {
      return {
        x: max_x_in_y.position_meta.x + max_x_in_y.position_meta.w,
        y: max_x_in_y.position_meta.y,
      };
    } else return { x: 0, y: 0 };
  }, [allSubcats]);

  // To update the values for local fields
  const handleChangeFieldVal = (
    key,
    val,
    blob_name,
    app_logo_type,
    action = "add"
  ) => {
    let newVal = {};
    if (app_logo_type) {
      newVal = { ...localState, [key]: val, blob_name, app_logo_type };
    } else if (key === "sub_category_type") {
      newVal = {
        ...localState,
        sub_category_description: "",
        sub_category_icon: "",
        url: "",
        sub_category_name: "",
        app_logo_type: "google_font",
        blob_name: "",
        [key]: val,
      };
      if (Object.keys(newVal).includes("function")) {
        newVal = _.omit(
          newVal,
          "function",
          "line_of_business",
          "business_owners",
          "technical_owners",
          "geography"
        );
      }
      if (val === "report") {
        newVal = {
          ...newVal,
          function: appFields.app_details.function,
          line_of_business: appFields.app_details.line_of_business,
          business_owners: [user.preferred_username],
          technical_owners: [user.preferred_username],
          geography: appFields.app_details.geography,
          bi_application: "Power BI",
        };
      }
    } else if (key === "function") {
      if (!localState[key]) {
        newVal = { ...localState, [key]: [val] };
      } else if (localState[key].includes(val)) {
        const arr = localState[key].filter((item) => item !== val);
        newVal = { ...localState, [key]: arr };
      } else if (val === "All") {
        newVal = { ...localState, [key]: [val] };
      } else {
        if ((localState[key] || []).includes("All")) {
          const filteredData = localState[key].filter((item) => item !== "All");
          newVal = { ...localState, [key]: [...filteredData, val] };
        } else {
          newVal = {
            ...localState,
            [key]: [...(localState[key] || []), val],
          };
        }
      }
    } else if (key === "line_of_business") {
      if (!localState[key]) {
        newVal = { ...localState, [key]: [val] };
      } else if (localState[key].includes(val)) {
        const arr = localState[key].filter((item) => item !== val);
        newVal = { ...localState, [key]: arr };
      } else if (val === "Global") {
        newVal = { ...localState, [key]: [val] };
      } else {
        if ((localState[key] || []).includes("Global")) {
          const filteredData = localState[key].filter(
            (item) => item !== "Global"
          );
          newVal = { ...localState, [key]: [...filteredData, val] };
        } else {
          newVal = {
            ...localState,
            [key]: [...(localState[key] || []), val],
          };
        }
      }
    } else if (key === "business_owners" || key === "technical_owners") {
      if (!localState[key]) {
        newVal = { ...localState, [key]: [val] };
      } else if (localState[key].includes(val) && action === "delete") {
        const arr = localState[key].filter((item) => item !== val);
        newVal = { ...localState, [key]: arr };
      } else if (localState[key].includes(val) && action !== "delete") {
        return;
      } else {
        newVal = { ...localState, [key]: [...localState[key], val] };
      }
    } else {
      newVal = { ...localState, [key]: val };
    }
    setlocalState(newVal);
  };
  // To update the values for local fields
  const handleChangeColor = (color) => {
    let newVal;
    newVal = { ...localState, card_bg_color: color.hex };
    setlocalState(newVal);
  };
  const handleCloseDrawer = () => setDrawerState(false);
  const handleOpenDrawer = () => setDrawerState(true);

  const handleClose = () => {
    setopenAddReportPopUp(false);
    setParentReportType("");
  };

  const validateReportFields = () => {
    // if (localState.sub_category_name === "") {
    //     return false;
    // } else {
    if (
      localState.function.length === 0 ||
      localState.business_owners.length === 0 ||
      localState.technical_owners.length === 0 ||
      localState.line_of_business.length === 0 ||
      localState.geography.length === 0
    ) {
      return false;
    }
    if (!localState.url.startsWith("https://")) {
      setURLValid({
        status: "Invalid",
        msg: "URL should start with (https://)",
      });
      return false;
    } else if (!localState.url.slice(8).trim().length) {
      setURLValid({
        status: "Invalid",
        msg: "Please Enter valid url",
      });
      return false;
    } else if (!isUrlMatch(localState.bi_application, localState.url)) {
      setURLValid({
        status: "Invalid",
        msg: handleGetErrorMessage(localState.bi_application),
      });
      return false;
    } else {
      setURLValid({
        status: "Valid",
        msg: "",
      });
      return true;
    }
  };

  const checkAreFieldsFilled = () => {
    let val;
    const ReportType = localState.sub_category_type;
    switch (ReportType) {
      case "report":
        val = validateReportFields();
        break;
      case "header":
        val = localState.sub_category_name !== "";
        break;
      // case "sub_header":
      //     val = localState.sub_category_name !== "";
      //     break;
      // case "hybrid":
      //     // val = checkFieldsForReport();
      //     val = localState.sub_category_name !== "";
      //     break;
      case "parentReport":
        val = localState.sub_category_name !== "";
        break;
      // case "customHybrid":
      //     val = localState.sub_category_name !== "";
      //     break;
      //   case "textBox":
      //       val = localState.sub_category_name !== "";
      //       break;
      case "image":
        val = localState.sub_category_icon !== "";
        break;
      default:
        val = true;
    }
    return val;
  };

  const findCat = (data, modifiedLocalState) => {
    const ind = data.findIndex(
      (item) => item.sub_category_id === modifiedLocalState.sub_category_id
    );
    let newData;
    if (ind >= 0) {
      const leftItems = data.slice(0, ind);
      const rightItems = data.slice(ind + 1);
      newData = [...leftItems, modifiedLocalState, ...rightItems];
    } else {
      // const reqSubcategoryTypes = data.map((item) => item.sub_category_type);
      let headersArr = data.filter(
        (item) => item.sub_category_type === "header"
      );
      let subHeadersArr = data.filter(
        (item) => item.sub_category_type === "sub_header"
      );
      let otherArr = data.filter(
        (item) =>
          item.sub_category_type !== "header" &&
          item.sub_category_type !== "sub_header"
      );
      if (modifiedLocalState.sub_category_type === "header") {
        headersArr = [...headersArr, modifiedLocalState];
      } else if (modifiedLocalState.sub_category_type === "sub_header") {
        subHeadersArr = [...subHeadersArr, modifiedLocalState];
      } else {
        otherArr = [...otherArr, modifiedLocalState];
      }
      newData = [...headersArr, ...subHeadersArr, ...otherArr];
      // const LastInd = reqSubcategoryTypes.lastIndexOf(
      //   modifiedLocalState.sub_category_type
      // );
      // if (LastInd >= 0) {
      //   const leftItems = data.slice(0, LastInd + 1);
      //   const rightItems = data.slice(LastInd + 1);
      //   newData = [...leftItems, modifiedLocalState, ...rightItems];
      // } else {
      //   newData = [...data, modifiedLocalState];
      // }
    }
    return newData;
  };

  // Find and update the subcat
  const findSubCat = (id, data, modifiedLocalState) => {
    data.forEach((rep) => {
      if (rep.sub_category_id === id) {
        const ind = rep.sub_category.findIndex(
          (item) => item.sub_category_id === modifiedLocalState.sub_category_id
        );
        if (ind >= 0) {
          // update incase ID exists
          rep.sub_category[ind] = modifiedLocalState;
        } else {
          // Add incase ID doesn't exists
          const a2 = rep.sub_category.map((el) => el.sub_category_type);
          const LastInd = a2.lastIndexOf(modifiedLocalState.sub_category_type);
          if (LastInd >= 0) {
            rep.sub_category.splice(LastInd + 1, 0, modifiedLocalState);
          } else {
            const LastIndofHeader = a2.lastIndexOf("header");
            modifiedLocalState.sub_category_type === "header"
              ? rep.sub_category.unshift(modifiedLocalState)
              : LastIndofHeader >= 0
              ? rep.sub_category.splice(
                  LastIndofHeader + 1,
                  0,
                  modifiedLocalState
                )
              : rep.sub_category.unshift(modifiedLocalState);
          }
        }
        setRenderSubReportArr(rep.sub_category);
      } else {
        if (rep.sub_category && rep.sub_category.length > 0) {
          findSubCat(id, rep.sub_category, modifiedLocalState);
        }
      }
    });
  };

  const findParentSubCat = (id, data, parent_id, modifiedLocalState) => {
    data.forEach((rep) => {
      if (rep.sub_category_id === parent_id) {
        const ind = rep.sub_category.findIndex(
          (item) => item.sub_category_id === id
        );
        if (ind >= 0) {
          // update incase ID exists
          rep.sub_category[ind] = modifiedLocalState;
        } else {
          rep.sub_category.push(modifiedLocalState); // push incase id doesn't exists
        }
        setRenderSubReportArr(data);
      } else {
        if (rep.sub_category && rep.sub_category.length > 0) {
          findParentSubCat(id, rep.sub_category, parent_id, modifiedLocalState);
        }
      }
    });
  };

  const findParentID = (childID, arr) => {
    let reqId = null;
    arr.forEach((item) => {
      const reqIndx = item.sub_category.findIndex(
        (list) => list.sub_category_id === childID
      );
      if (reqIndx >= 0) {
        reqId = item.sub_category_id;
      }
      if (reqId) return;
    });
    return reqId;
  };

  const modifyPositionMeta = (data = localState) => {
    let newPMData = { ...data };
    const subHeadersArr = allSubcats.filter(
      (rep) => rep.sub_category_type === "sub_header"
    );
    if (subHeadersArr.length === 0) {
      newPMData = {
        ...localState,
        position_meta: {
          ...localState.position_meta,
          h: 2,
          w: 4,
          y: 0,
          x: 0,
        },
      };
    } else {
      const initialValue = 0;
      const AllWidths = subHeadersArr.map((rep) => rep.position_meta.w);
      const occupiedWidth = AllWidths.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      );
      newPMData = {
        ...localState,
        position_meta: {
          ...localState.position_meta,
          h: 2,
          w: 4, // 18-occupied width
          y: 0,
          x: occupiedWidth,
        },
      };
    }
    return newPMData;
  };

  const findNUpdateSubcats = (newState, modifiedLocalState) => {
    const parent_id =
      parentID || findParentID(modifiedLocalState.sub_category_id, allSubcats);
    // If report is being ADDED/UPDATED inside a parent-report
    if (parent_id) {
      findParentSubCat(
        modifiedLocalState.sub_category_id,
        newState,
        parent_id,
        modifiedLocalState
      );
    }
    // If ADDing/UPDATing - not inside a parent report & on first page
    else if (breadcrumbArr.length === 1) {
      newState = findCat(newState, modifiedLocalState);
      setRenderSubReportArr(newState);
      return newState;
    }
    // If ADDing/UPDATing - not inside a parent report & any other page but first
    else {
      findSubCat(
        breadcrumbArr[breadcrumbArr.length - 1].textID,
        newState,
        modifiedLocalState
      );
    }
  };

  const isSaveDisabled = useMemo(() => {
    if (specialFeilds.includes(localState.sub_category_type)) {
      return false;
    }
    const new_LocalState = _.omit(localState, "sub_category_id");
    const new_subcategoryState = _.omit(subcategoryState, "sub_category_id");
    if (
      JSON.stringify(new_LocalState) === JSON.stringify(new_subcategoryState)
    ) {
      return true;
    } else {
      return false;
    }
  }, [localState, subcategoryState]);

  const handleEditorState = (k, v) => {
    setEditorState({ ...editorState, [k]: v });
  };

  // Add / Update the subcategory
  const handleSaveSubcategory = () => {
    if (!checkAreFieldsFilled()) {
      setshowWarning(true);
      return;
    }
    setshowWarning(false);
    if (specialFeilds.includes(localState.sub_category_type)) {
      let modifiedData = JSON.parse(JSON.stringify(localState));
      if (localState.sub_category_type === "sub_header") {
        const subHeadersArr = allSubcats.filter(
          (rep) => rep.sub_category_type === "sub_header"
        );
        const existingRep = subHeadersArr.findIndex(
          (rep) => rep.sub_category_id === localState.sub_category_id
        );
        if (subHeadersArr.length >= 6 && existingRep < 0) {
          handleClose();
          toast.error("Maximum 6 sub-headers can be added!");
          return;
        }
        if (existingRep < 0) {
          // modify the position-meta only if its a new subheader
          modifiedData = modifyPositionMeta();
        }
      }
      const rowName = convertToRaw(
        editorState.sub_category_name.getCurrentContent()
      );
      const rowDesc = convertToRaw(
        editorState.sub_category_description.getCurrentContent()
      );
      if (!rowName.blocks[0]?.text?.trim()) {
        setshowWarning(true);
        return;
      }
      const sub_category_name = JSON.stringify(rowName);
      const sub_category_description = JSON.stringify(rowDesc);
      let newState = JSON.parse(JSON.stringify(appTemplateState));
      // Find and ADD/UPDATE
      const reqState = findNUpdateSubcats(newState, {
        ...modifiedData,
        sub_category_name,
        sub_category_description:
          localState.sub_category_type === "textBox"
            ? ""
            : sub_category_description,
      });
      // UPDATE the subcategory with new local state in the Actual State
      dispatch(
        updateReportTemplate({
          templateData: reqState || newState,
        })
      );
    } else {
      //create a function to remove unwanted values
      let modifiedData = JSON.parse(JSON.stringify(localState));
      let newState = JSON.parse(JSON.stringify(appTemplateState));
      // Find and ADD/UPDATE
      const reqState = findNUpdateSubcats(newState, modifiedData);
      // UPDATE the subcategory with new local state in the Actual State
      dispatch(
        updateReportTemplate({
          templateData: reqState || newState,
        })
      );
    }
    setParentID(null);
    setSelectedWidgetType(null);
    dispatch(update_statusData({ editPreviewToggleReport: true }));
    handleClose();
  };

  // Discard Subcategory Changes
  const handleDiscardSubcategory = () => {
    setParentID(null);
    setSelectedWidgetType(null);
    dispatch(update_statusData({ editPreviewToggleReport: true }));
    handleClose();
  };

  useEffect(() => {
    const { x, y } = getHightestY();
    const widgetInd = allSubcats.findIndex(
      (item) => item.sub_category_type === "customHybrid"
    );
    setlocalState(
      subcategoryState.sub_category_id
        ? subcategoryState
        : initialState1(
            y,
            x,
            widgetInd < 0 ? widget_type : "customHybrid",
            appFields,
            user
          )
    );
    if (specialFeilds.includes(subcategoryState.sub_category_type)) {
      const sub_category_name = getEditorState(
        subcategoryState.sub_category_name
      );
      const sub_category_description = getEditorState(
        subcategoryState.sub_category_description
      );
      setEditorState({ sub_category_name, sub_category_description });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcategoryState, widget_type]);

  useEffect(() => {
    setshowWarning(false);
  }, [localState]);

  useEffect(() => {
    if (Object.keys(localState).length !== 0) {
      if (
        localState.sub_category_type === "textBox" ||
        localState.sub_category_type === "image"
      ) {
        setPreviewDisable(true);
      } else {
        setPreviewDisable(false);
      }
    }
  }, [localState]);

  return (
    <Dialog
      open={open}
      disableEnforceFocus
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.alertContainer2}
    >
      <div>
        <EditPreviewToggle
          component="reports"
          previewDisableReport={previewDisable}
        />
      </div>
      <Box
        sx={{
          height: "610px",
          overflow: "auto",
          position: "relative",
        }}
      >
        {editPreviewToggleReport ? (
          <Grid container spacing={1}>
            {registerTemplateFields &&
              registerTemplateFields.fields[1].fields.map((item) => {
                return (
                  <React.Fragment key={item.key}>
                    {!handleHideComponent(
                      item.key,
                      localState.sub_category_type,
                      localState
                    ) &&
                      getRequiredComponent(
                        item,
                        localState,
                        handleChangeFieldVal,
                        URLValid,
                        setURLValid,
                        widget_type,
                        breadcrumbArr,
                        renderSubReportArr,
                        parent_report_type,
                        editorState,
                        handleEditorState,
                        handleOpenDrawer,
                        theme
                      )}
                  </React.Fragment>
                );
              })}
            {registerFields &&
              localState.sub_category_type === "report" &&
              registerFields.fields.map((item) => {
                return (
                  <React.Fragment key={item.key}>
                    {!handleHideComponent(
                      item.key,
                      localState.sub_category_type,
                      localState
                    ) &&
                      getRequiredComponent(
                        item,
                        localState,
                        handleChangeFieldVal,
                        URLValid,
                        setURLValid,
                        widget_type,
                        breadcrumbArr,
                        renderSubReportArr,
                        parent_report_type,
                        editorState,
                        handleEditorState,
                        handleOpenDrawer,
                        theme
                      )}
                  </React.Fragment>
                );
              })}
          </Grid>
        ) : (
          <div
            style={{
              width: "99%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <PreviewComponent
              previewData={localState}
              parent_report_type={parent_report_type}
              editorState={editorState}
            />
            {specialFeilds.includes(localState.sub_category_type) &&
              localState.sub_category_type !== "textBox" && (
                <CompactPicker
                  triangle="hide"
                  color={localState.card_bg_color}
                  colors={config.cssValues.colors}
                  onChangeComplete={handleChangeColor}
                />
              )}
          </div>
        )}
        {drawerState && (
          <PowerBiDrawer open={drawerState} handleClose={handleCloseDrawer} />
        )}
      </Box>
      <div className={classes.footerContainer}>
        <button
          className={`${classes.discardBtn} btn-next btn`}
          onClick={handleDiscardSubcategory}
        >
          {isSaveDisabled ? "Close" : "Discard Changes"}
        </button>
        <div>
          {showWarning && (
            <p style={{ fontSize: "11px", color: "red" }}>
              Please fill all Required Fields
            </p>
          )}
          <button
            disabled={isSaveDisabled}
            className="btn-next btn"
            onClick={handleSaveSubcategory}
          >
            Save Changes
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  allApps: state.AppsData.allApps,
  theme: state.user.ui_preferences?.theme,
  appTemplateState: state.appTemplate.appTemplateState.templateData,
  registerTemplateFields:
    state.register.registerTemplate.data.register_app_template.fields[0]
      .options[0].subform_templates[2],
  registerFields:
    state.register.registerTemplate.data.register_app_template.fields[0]
      .options[0].subform_templates[0],
  editPreviewToggleReport:
    state.registerNewApp.statusData.editPreviewToggleReport,
});

export default connect(mapStateToProps)(AddSubcategoryPopUp);
// if (subcategoryState.sub_category_name.startsWith('{"blocks"')) {
//     setEditorState(
//         getDefaultEditorData(
//             JSON.parse(subcategoryState.sub_category_name)
//         )
//     );
// } else {
//     const reqText = getValidTextEditorData(
//         subcategoryState.sub_category_name
//     );
//     setEditorState(getDefaultEditorData(reqText));
// }
