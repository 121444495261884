import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { useStylesforImageSelector } from "../ImageSelector/Styles";
import { connect, useDispatch } from "react-redux";
import { useStylesforElementSelector } from "./Styles";
import { convertToRaw } from "draft-js";

// Import Custom Components
import { TextEditor } from "../TextEditor/TextEditor";
import ImageSelector from "../ImageSelector/ImageSelector";
import ReportSelector from "../ReportSelector/ReportSelector";

// Import Images
import reportimage from "../../../../../assets/images/new-theme/register/light-theme/reportimage.svg";
import reportimagewhite from "../../../../../assets/images/new-theme/register/dark-theme/reportimagewhite.svg";
import image from "../../../../../assets/images/new-theme/register/light-theme/image.svg";
import imagewhite from "../../../../../assets/images/new-theme/register/dark-theme/imagewhite.svg";
import textimage from "../../../../../assets/images/new-theme/register/light-theme/textimage.svg";
import textimagewhite from "../../../../../assets/images/new-theme/register/dark-theme/textimagewhite.svg";
import Loader from "../../../../components/Loader/Loader";
// import PowerBiDrawer from "../../../Register/components/DashboardTemplate/Components/PowerBiDrawer";
import {
  updateReportTemplate,
  updateReportDesc,
} from "../../../../redux/actions";
import { checkFieldsAreValid } from "../Utils/Utils";

import {
  getDefaultEditorData,
  getEditorState,
  isEqualObject,
} from "../../../../utils/utils";
import PowerBiDrawer from "../DashboardTemplate/Components/PowerBiDrawer";

const AddElementComponent = (props) => {
  const { setselectedElement, user } = props;

  const handleSelectElement = (el) => {
    setselectedElement(el);
  };

  const popup_classes = useStylesforElementSelector();
  return (
    <div className={popup_classes.elementsContainer}>
      <div
        className={popup_classes.mainContainer}
        onClick={() => handleSelectElement("report")}
      >
        <img
          src={
            user.ui_preferences.theme === "light"
              ? reportimage
              : reportimagewhite
          }
          className={popup_classes.elementImage}
          alt="Add Report"
        />
        <p className={popup_classes.elementText}>
          Direct link to report or dashboard
        </p>
        <p className={popup_classes.elementDescription}>
          Links can be opened here using iframe or redirect user to any other
          web or sharepoint site
        </p>
      </div>
      <div
        className={popup_classes.mainContainer}
        onClick={() => handleSelectElement("text")}
      >
        <img
          src={
            user.ui_preferences.theme === "light" ? textimage : textimagewhite
          }
          className={popup_classes.elementImage}
          alt="Add Text"
        />
        <p className={popup_classes.elementText}>Plain Text</p>
        <p className={popup_classes.elementDescription}>
          Tiles with plain text can be used in addition to a basic description
        </p>
      </div>
      <div
        className={popup_classes.mainContainer}
        onClick={() => handleSelectElement("image")}
      >
        <img
          src={user.ui_preferences.theme === "light" ? image : imagewhite}
          alt="Add Img"
          className={popup_classes.elementImage}
        />
        <p className={popup_classes.elementText}>Image</p>
        <p className={popup_classes.elementDescription}>
          If you want to enrich project space with images select this type of
          tile
        </p>
      </div>
    </div>
  );
};

const AddNewElementPopUp = (props) => {
  const {
    handleClose = () => {},
    openImageSelector = true,
    user,
    selectedEl = null,
    reportDetails = {},
    setopenAddReportPopUp,
    handleSaveElement,
    template_no,
  } = props;
  // const [selectedElement, setselectedElement] = useState(selectedEl);
  const [reportState, setReportState] = useState(null);
  const [fileUploadStatus, setFileUploadStatus] = useState("pending");
  const [URLValid, setURLValid] = useState({ status: "pending", msg: "" });
  const [file, setFile] = useState(null);
  const [showWarning, setshowWarning] = useState(false);
  const [imageUploadWarning, setImageUploadWarning] = useState(false);
  const [selectedSize, setSelectedSize] = useState(null);
  const [drawerState, setDrawerState] = useState(false);
  const classes = useStylesforImageSelector();
  const popup_classes = useStylesforElementSelector();
  const [editorState, setEditorState] = useState({
    sub_category_description: getDefaultEditorData(),
  });

  useEffect(() => {
    if (reportDetails?.sub_category_img_data?.selected_size) {
      setSelectedSize(reportDetails?.sub_category_img_data?.selected_size);
    } else setSelectedSize("fit");
  }, [reportDetails]);

  const dispatch = useDispatch();

  const handleEditorState = (k, v) => {
    setEditorState({ ...editorState, [k]: v });
  };

  let sub_category_description = "";
  const rowDesc = convertToRaw(
    editorState.sub_category_description.getCurrentContent()
  );
  sub_category_description = JSON.stringify(rowDesc);

  useEffect(() => {
    if (reportDetails.sub_category_type === "text") {
      const sub_category_description = getEditorState(
        reportDetails.sub_category_description
      );
      setEditorState({ sub_category_description });
    }
  }, [reportDetails]);
  const handleSaveSubcategory = () => {
    dispatch(
      updateReportDesc({
        sub_category_description: sub_category_description,
      })
    );
    handleClose();
  };

  // useEffect(() => {
  //   // if (reportDetails.sub_category_type) {
  //   //   setselectedElement(reportDetails.sub_category_type);
  //   // } else

  //   setselectedElement(selectedEl);
  // }, [selectedEl]);

  const handleCancel = () => {
    setopenAddReportPopUp(false);
    setFile(null);
    setshowWarning(false);
    setURLValid({ status: "pending", msg: "" });
    // TODO: call the imageuploadv2 api and save the data to an object
  };
  // const handlePrevious = () => {
  //   setselectedElement(null);
  // };
  function fetchAPI(formData) {
    const url = "/api/imageuploadv2";
    return fetch(url, {
      method: "POST",
      headers: {},
      body: formData,
    });
  }

  const removeWarning = () => {
    setTimeout(() => {
      setshowWarning(false);
      setImageUploadWarning(false);
    }, 1000);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };

  const handleSaveReportDetails = (response, type = "report") => {
    let newState = {};
    if (response) {
      newState = {
        ...reportState,
        sub_category_type: reportDetails.sub_category_type,
        sub_category_id: reportDetails.sub_category_id,
        sub_category_img_data: {
          blob_name: response.data.blob_name,
          img_url: response.data.url,
          selected_size: selectedSize,
        },
      };
    } else {
      if (type === "report") {
        if (
          reportState.sub_category_img_data.img_url.startsWith(
            "/static/media/report_sample_image"
          )
        ) {
          newState = {
            ...reportState,
            sub_category_type: reportDetails.sub_category_type,
            sub_category_id: reportDetails.sub_category_id,
            sub_category_img_data: {
              selected_size: selectedSize,
              img_url: "",
              blob_name: "",
            },
          };
        } else {
          newState = {
            ...reportState,
            sub_category_type: reportDetails.sub_category_type,
            sub_category_id: reportDetails.sub_category_id,
            sub_category_img_data: {
              ...reportState.sub_category_img_data,
              selected_size: selectedSize,
            },
          };
        }
      } else {
        newState = {
          ...reportState,
          sub_category_type: reportDetails.sub_category_type,
          sub_category_id: reportDetails.sub_category_id,
          sub_category_img_data: {
            ...reportDetails.sub_category_img_data,
            selected_size: selectedSize,
          },
        };
      }
    }
    handleSaveElement(newState);
  };

  const uploadFile = async (file, selectedEl) => {
    setFileUploadStatus("loading");
    const formData = new FormData();
    formData.append("file", file);
    try {
      const req = await fetchAPI(formData);
      const response = await req.json();
      if (response.status.statusCode === "datareckitt-200") {
        setFileUploadStatus("success");
        // console.log("response", response);
        handleSaveReportDetails(response, selectedEl);
        setFile(null);
        setSelectedSize("fit");
      } else {
        setFileUploadStatus("error");
        console.log("Oops! something went wrong");
      }
    } catch (error) {
      setFileUploadStatus("error");
      console.log("error from image upload", error);
    }
  };
  const handleSave = () => {
    // debugger;
    if (selectedEl === "report") {
      if (
        (template_no === 7 || template_no === 9) &&
        file === null &&
        reportDetails?.sub_category_img_data?.blob_name === ""
      ) {
        setImageUploadWarning(true);
        removeWarning();
        return;
      }
      if (
        !checkFieldsAreValid(reportState, setURLValid) ||
        reportState.sub_category_icon === ""
      ) {
        setshowWarning(true);
        removeWarning();
        return;
      }
      setshowWarning(false);
      if (file) {
        uploadFile(file, selectedEl);
      } else {
        handleSaveReportDetails(null, selectedEl);
      }
    } else if (selectedEl === "image") {
      if (
        file === null &&
        reportDetails.sub_category_img_data?.blob_name?.length === 0 &&
        reportDetails.sub_category_img_data?.img_url.length === 0
      ) {
        setshowWarning(true);
        return;
      }
      setshowWarning(false);
      if (file) {
        uploadFile(file, selectedEl);
      } else {
        handleSaveReportDetails(null, selectedEl);
      }
    } else if (selectedEl === "text") {
      handleSaveSubcategory();
      // let sub_category_description = "";
      // const rowDesc = convertToRaw(
      //   editorState.sub_category_description.getCurrentContent()
      // );
      // sub_category_description = JSON.stringify(rowDesc);
      const new_data = {
        sub_category_description,
        sub_category_id: reportDetails.sub_category_id,
        sub_category_type: reportDetails.sub_category_type,
      };
      handleSaveElement(new_data);
    }
  };

  const checkSaveDisabled = () => {
    if (selectedEl === "report") {
      if (
        file !== null ||
        selectedSize !== reportDetails?.sub_category_img_data?.selected_size
      ) {
        return false;
      } else {
        return isEqualObject(reportState, reportDetails);
      }
    } else if (selectedEl === "image") {
      if (file !== null) {
        return false;
      } else if (reportDetails.sub_category_img_data.img_url === "") {
        return true;
      } else if (
        selectedSize === reportDetails?.sub_category_img_data?.selected_size
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return isEqualObject(
        editorState,
        reportDetails["sub_category_description"]
      );
    }
  };

  return (
    <Dialog
      open={openImageSelector}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.ImageSelectorContainer}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box className="" m="0px 25px" p="15px 0px">
          {"Add New Element" + (selectedEl ? " / " + selectedEl : "")}
        </Box>
        {drawerState && (
          <PowerBiDrawer open={drawerState} handleClose={handleCloseDrawer} />
        )}
        <DialogContent className={popup_classes.ContentForElementSelector}>
          {/* {selectedEl === null && (
            <AddElementComponent
              user={user}
              setselectedElement={setselectedElement}
            />
          )} */}
          {selectedEl === "text" && (
            <TextEditor
              editorState={editorState}
              handleEditorState={handleEditorState}
              theme={user.ui_preferences.theme}
            />
          )}
          {selectedEl === "report" && (
            <ReportSelector
              reportState={reportState}
              setReportState={setReportState}
              URLValid={URLValid}
              setshowWarning={setshowWarning}
              setURLValid={setURLValid}
              reportDetails={reportDetails}
              file={file}
              setFile={setFile}
              selectedSize={selectedSize}
              setSelectedSize={setSelectedSize}
              handleOpenDrawer={handleOpenDrawer}
            />
          )}
          {selectedEl === "image" && (
            <ImageSelector
              file={file}
              imageDetails={reportDetails}
              setFile={setFile}
              selectedSize={selectedSize}
              setSelectedSize={setSelectedSize}
            />
          )}
        </DialogContent>
        <DialogActions className="">
          {imageUploadWarning ? (
            <p style={{ fontSize: "11px", color: "red" }}>
              Please upload Report image
            </p>
          ) : showWarning ? (
            <p style={{ fontSize: "11px", color: "red" }}>
              Please fill all Required Fields
            </p>
          ) : null}
          {/* {showWarning &&
            ((template_no === 7 || template_no === 9) &&
            file === null &&
            reportDetails?.sub_category_img_data?.blob_name === "" ? (
              <p style={{ fontSize: "11px", color: "red" }}>
                Please upload Report image
              </p>
            ) : (
              <p style={{ fontSize: "11px", color: "red" }}>
                Please fill all Required Fields
              </p>
            ))} */}
          {selectedEl && (
            <div>
              {/* {selectedEl !== null && (
                <button
                  onClick={handlePrevious}
                  className={classes.SecondaryButtonStyles}
                >
                  Previous Step
                </button>
              )} */}

              <button
                onClick={handleCancel}
                className={classes.SecondaryButtonStyles}
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                disabled={checkSaveDisabled()}
                className={
                  checkSaveDisabled()
                    ? classes.PrimaryButtonStylesDisabled
                    : classes.PrimaryButtonStyles
                }
              >
                {fileUploadStatus === "loading" ? (
                  <Loader color="#fff" loader_size="0.5rem" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          )}
          {!selectedEl && (
            <button
              onClick={handleCancel}
              disabled={fileUploadStatus === "loading"}
              className={classes.SecondaryButtonStyles}
            >
              Cancel
            </button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  template_no: state.appTemplate.appTemplateState.template_no,
});

export default connect(mapStateToProps)(AddNewElementPopUp);
