import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { connect, useDispatch } from "react-redux";
import KpiCardComponent from "../Components/KpiCardComponent";
import BarChart from "../Components/ChartComponent/BarChart";
import UsageReportFilter from "../Components/UsageReportFilter";
import StackChart from "../Components/ChartComponent/StackChart";
import RegisterBodyContainer from "../Components/RegisterBodyContainer";
import RankingComponent from "../Components/RankingComponent";

import { formatDate, getNormalText } from "../../../utils/utils";
import format from "date-fns/format";
import noData from "../../../../assets/images/register-images/nographdata.svg";
import noDataDark from "../../../../assets/images/register-images/no_charts.svg";
import loadingMap from "../../../../assets/images/register-images/maploading.gif";

import {
  clean_usage_report_users,
  get_usage_report_users,
} from "../../../redux/actions";

import "../../NewRegister/index.scss";
import UsageChartComponent from "../Components/UsageChartComponent";
import TopUsers from "../Components/TopUsers";
import UsageReportCalender from "../Components/UsageReportCalender";

function fetchAPI(payload, signal) {
  const url = "/api/usagemetrics";
  return fetch(url, {
    signal,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const initailsValues = {
  chart_type: "Bar Chart",
  time_range: "Last 7 Days",
  subCategory: "All",
  usersList: ["All"],
};

const isTimestampToday = (timestamp) => {
  const dateFromTimestamp = new Date(timestamp);
  const currentDate = new Date();

  return (
    dateFromTimestamp.getFullYear() === currentDate.getFullYear() &&
    dateFromTimestamp.getMonth() === currentDate.getMonth() &&
    dateFromTimestamp.getDate() === currentDate.getDate()
  );
};

// Props Comments
// appDetails: contains the details of the app
// usage_report_users: contains user-list who accessed the app
// theme: light/dark theme
const UsageReport = (props) => {
  let { appDetails, usage_report_users, theme } = props;
  const app_published_on = appDetails?.data?.app_meta?.published_on;
  const published_timestamp = app_published_on;

  const refOne = useRef(null);
  const refTow = useRef(null);
  const [open, setOpen] = useState(false);

  // const [selectedOption, setSelectedOption] = useState("Last 7 Days");
  const [selectedChip, setSelectedChip] = useState("Last 7 Days");
  const [timeStampObject, setTimeStampObject] = useState({});
  const [disabledChip, setDisabledChip] = useState([]);
  const [timestampRange, SetTimestampRange] = useState(() => {
    const currentDate = new Date();

    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0);

    const sevenDaysAgo2 = new Date(currentDate);
    sevenDaysAgo2.setDate(currentDate.getDate() - 7);
    const ts_sevenDaysAgo = sevenDaysAgo2.setHours(23, 59, 59, 999);

    const oneDayAgo = new Date(currentDate);
    oneDayAgo.setDate(currentDate.getDate() - 1);
    oneDayAgo.setHours(23, 59, 59, 999);

    let startDateTimestamp = sevenDaysAgo.getTime();
    let endDateTimestamp = oneDayAgo.getTime();

    if (app_published_on > ts_sevenDaysAgo) {
      startDateTimestamp = app_published_on;
      endDateTimestamp = isTimestampToday(app_published_on)
        ? currentDate.getTime()
        : oneDayAgo.getTime();
      setSelectedChip("Time Range");
    }
    return {
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
    };
  });

  const [kpiValue, setKpiValue] = useState({});
  const [topTenUsers, setTopTenUsers] = useState({});
  const [monthStatus, setMonthStatus] = useState(false);
  const dispatch = useDispatch();
  const abortController = useRef(null);
  const abortController2 = useRef(null);
  const [usersData, setUsersData] = useState([]);
  const [filters, setFilters] = useState({ ...initailsValues });
  const [graphData, setGraphData] = useState({
    range: [timestampRange.startDate, timestampRange.endDate],
    yRange: [0, 100],
  });
  const [loading, setLoading] = useState(false);
  const [loadingTopUsers, setLoadingTopUsers] = useState(false);
  const [rankingdata, setRankingdata] = useState([]);

  // To get kpi value, top 10 user and app ranking
  function fetchUsageDataAPI(appPath, signal) {
    const url = `/api/fetchusagedata?app_path=${appPath}`;
    return fetch(url, {
      signal,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  // sets kpi value, top 10 user and app ranking
  const fetchData2 = async () => {
    try {
      if (abortController2.current) {
        abortController2.current.abort();
      }
      setLoadingTopUsers(true);
      abortController2.current = new AbortController();
      const res = await fetchUsageDataAPI(
        appDetails.data.app_id,
        abortController2?.current?.signal
      );
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200") {
        const kpi_values = response?.data.hasOwnProperty("kpi_values")
          ? response?.data?.kpi_values
          : {};
        setKpiValue(kpi_values);
        const top_ten_users = response?.data.hasOwnProperty("top_ten_users")
          ? response?.data?.top_ten_users
          : {};
        setTopTenUsers(top_ten_users);
        setRankingdata(
          response?.data.hasOwnProperty("app_ranking")
            ? response?.data?.app_ranking
            : []
        );
      }
      setLoadingTopUsers(false);
    } catch (error) {
      if (error.name !== "AbortError") {
        toast.error("Oops! Something went wrong. Please try again later.");
        console.error("Error from API:", error);
        setLoadingTopUsers(false);
      }
    }
  };

  // use to disable chips
  useEffect(() => {
    const currentDate = new Date();

    // 1 day ago timestamp (END TIME)
    const oneDayAgo = new Date(currentDate);
    oneDayAgo.setDate(currentDate.getDate() - 1);
    oneDayAgo.setHours(23, 59, 59, 999);
    const timestamp_1dayAgo = new Date(oneDayAgo).getTime();

    // 7 day ago timestimp
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0);
    const timestamp_7dayAgo = sevenDaysAgo.getTime();

    // 14 day ago timestamp
    const fourteenDaysAgo = new Date(currentDate);
    fourteenDaysAgo.setDate(currentDate.getDate() - 14);
    fourteenDaysAgo.setHours(0, 0, 0, 0);
    const timestamp_14dayAgo = new Date(fourteenDaysAgo).getTime();

    // 30 day ago timestamp
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    const timestamp_30days = thirtyDaysAgo.setHours(23, 59, 59, 999);

    // This month start timestamp
    const firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const timestamp_thismonth = new Date(firstDay).getTime();

    setTimeStampObject({
      last7_days: timestamp_7dayAgo,
      last14_days: timestamp_14dayAgo,
      this_month: timestamp_thismonth,
      onedayago: timestamp_1dayAgo,
    });

    let disabled_array = [];

    const timestamp_7days = sevenDaysAgo.setHours(23, 59, 59, 999);
    const timestamp_14days = fourteenDaysAgo.setHours(23, 59, 59, 999);

    if (published_timestamp > timestamp_7days) {
      disabled_array.push("Last 7 Days");
    }
    if (published_timestamp > timestamp_14days) {
      disabled_array.push("Last 14 Days");
    }
    if (published_timestamp > timestamp_30days) {
      disabled_array.push("Monthly");
    }
    if (currentDate.getDate() === 1) {
      disabled_array.push("This Month");
    }

    setDisabledChip(disabled_array);
    fetchData2();

    if (refOne.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      !(
        (refOne.current && refOne.current.contains(event.target)) ||
        (refTow.current && refTow.current.contains(event.target))
      )
    ) {
      setOpen(false);
    }
  };
  // checks if timerange is greater than 30 days
  useEffect(() => {
    const isMonthlyView = () => {
      const diffInDays =
        (timestampRange.endDate - timestampRange.startDate) /
        (1000 * 60 * 60 * 24);
      return diffInDays > 30;
    };
    const ismonth = isMonthlyView();
    if (ismonth) {
      setMonthStatus(true);
    } else {
      setMonthStatus(false);
    }
  }, [timestampRange]);

  // use to fetch data from /api/usagemetrics
  const fetchUsageReportData = async (reqFilters) => {
    if (abortController.current) {
      abortController.current.abort();
    }
    setLoading(true);
    // const access_on_first = getFirstDate(reqFilters.time_range);
    // data contains the filters to be applied on the graph
    const currentDate = new Date();
    const oneDayAgo = new Date(currentDate);
    oneDayAgo.setDate(currentDate.getDate() - 1);
    oneDayAgo.setHours(23, 59, 59, 999);

    const data = {
      search_columns: [
        {
          name: "app_id",
          type: "in",
          values: [appDetails.data.app_id],
        },
        {
          name: "accessed_by",
          type: "in",
          values: reqFilters.usersList?.length ? reqFilters.usersList : ["All"],
        },
        {
          name: "sub_category_id",
          type: "in",
          values: [reqFilters.subCategory?.val || "All"],
        },
        {
          name: "accessed_on",
          type: "greaterthan",
          values: timestampRange.startDate,
        },
        {
          name: "accessed_on",
          type: "lessthan",
          values: isTimestampToday(timestampRange.endDate)
            ? oneDayAgo.getTime()
            : timestampRange.endDate,
        },
      ],
      monthly: monthStatus,
    };

    try {
      abortController.current = new AbortController();
      // AbortController: used to cancel the HTTP-fetch requests if required
      const res = await fetchAPI(data, abortController?.current?.signal);
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200" && !monthStatus) {
        let labels = [];
        const daily_count = [];
        const stackData = [];
        const dates = [];
        const uniqueView = [];
        let count = 0;
        response.data[0].forEach((item) => {
          let uniqueCount = 0;
          labels.push(formatDate(item.date, "YYYY-MM-DD"));
          dates.push(item.date);
          daily_count.push(item.total_daily_views);
          count = count + item.total_daily_views;
          item.usage_meta.forEach((list) => {
            uniqueCount = uniqueCount + 1;
            const index = stackData.findIndex(
              (data) => data.name === list.email
            );
            if (index >= 0) {
              stackData[index].labels.push(formatDate(item.date, "YYYY-MM-DD"));
              stackData[index].date.push(item.date);
              stackData[index].daily_count.push(list.daily_count);
            } else {
              stackData.push({
                name: list.email,
                labels: [formatDate(item.date, "YYYY-MM-DD")],
                date: [item.date],
                daily_count: [list.daily_count],
              });
            }
          });
          uniqueView.push(uniqueCount);
        });
        const firstRange = new Date(Math.min(...dates)).setDate(
          new Date(Math.min(...dates)).getDate() - 1
        );
        const secondRange = new Date(Math.max(...dates)).setDate(
          new Date(Math.max(...dates)).getDate() + 1
        );

        if (isNaN(firstRange)) {
          setGraphData({
            labels,
            daily_count,
            uniqueView,
            count,
            range: [timestampRange.startDate, timestampRange.endDate],
            yRange: [0, 100],
            // ...initailsRange,
          });
        } else {
          let yRange =
            Math.max(...daily_count) + 0.25 * Math.max(...daily_count);
          // Math.ceil(0.25 * Math.max(...daily_count));
          setGraphData({
            labels,
            daily_count,
            uniqueView,
            range: [firstRange, secondRange],
            yRange: [0, yRange],
            count,
            stackData,
          });
        }
      } else if (
        response.status.statusCode === "datareckitt-200" &&
        monthStatus
      ) {
        let labels = [];
        const daily_count = [];
        const stackData = [];
        const months = [];
        const uniqueView = [];
        let count = 0;
        response.data[0].forEach((item) => {
          let uniqueCount = 0;
          labels.push(formatDate(item.month, "YYYY-MM-DD"));
          months.push(item.month);
          daily_count.push(item.total_monthly_views);
          count = count + item.total_monthly_views;
          item.usage_meta.forEach((list) => {
            uniqueCount = uniqueCount + 1;
            const index = stackData.findIndex(
              (data) => data.name === list.email
            );
            if (index >= 0) {
              stackData[index].labels.push(
                formatDate(item.month, "YYYY-MM-DD")
              );
              stackData[index].date.push(item.month);
              stackData[index].daily_count.push(list.monthly_count);
            } else {
              stackData.push({
                name: list.email,
                labels: [formatDate(item.month, "YYYY-MM-DD")],
                date: [item.month],
                daily_count: [list.monthly_count],
              });
            }
          });
          uniqueView.push(uniqueCount);
        });

        const minDate = new Date(Math.min(...months));
        minDate.setMonth(minDate.getMonth() - 1);
        const firstRange = minDate.getTime();

        const maxDate = new Date(Math.max(...months));
        maxDate.setMonth(maxDate.getMonth() + 1);
        const secondRange = maxDate.getTime();

        if (isNaN(firstRange)) {
          setGraphData({
            labels,
            daily_count,
            uniqueView,
            count,
            range: [timestampRange.startDate, timestampRange.endDate],
            yRange: [0, 100],
            // ...initailsRange,
          });
        } else {
          let yRange =
            Math.max(...daily_count) + 0.25 * Math.max(...daily_count);
          // Math.ceil(0.25 * Math.max(...daily_count));
          setGraphData({
            labels,
            daily_count,
            uniqueView,
            range: [firstRange, secondRange],
            yRange: [0, yRange],
            count,
            stackData,
          });
        }
      }
      setLoading(false);
    } catch (error) {
      if (error.name !== "AbortError") {
        toast.error("Oops Something went wrong. Please try later");
        console.log("error form api", error);
        setLoading(false);
      }
    }
  };

  //used to set the subcategory list in the options
  useEffect(() => {
    const reqList = [];
    // For covid data and Xplore return
    if (appDetails.data.app_id === "data-assets") return;
    else if (appDetails.data.app_id === "18a252bd-b59e-4") {
      appDetails.data.app_details?.defined_categories[0].sub_category.forEach(
        (report) => {
          if (report.bi_application === "Power BI") {
            reqList.push({
              title: report.sub_category_name,
              val: report.sub_category_id,
            });
          }
        }
      );
      setUsersData({
        subCategory: ["All", ...reqList],
      });
      return;
    }
    const getReport = (sub_category, list = []) => {
      sub_category.forEach((item) => {
        if (item.sub_category_type === "report") {
          list.push({
            title: getNormalText(item.sub_category_name),
            val: item.sub_category_id,
          });
        } else if (item.sub_category?.length) {
          getReport(item.sub_category, list);
        }
      });
    };

    if (appDetails.status === "success") {
      const defined_categories =
        appDetails.data.app_details?.defined_categories;
      if (
        defined_categories?.length &&
        appDetails.data.app_details?.chosen_template &&
        appDetails.data.app_details?.chosen_template !== 1
      ) {
        getReport(defined_categories, reqList);
      } else if (defined_categories?.length) {
        defined_categories.map((Category) => {
          if (
            Category?.CatHyperlinks &&
            Category?.CatHyperlinks?.isHyperlinkActive
          ) {
            reqList.push({
              title: Category.sub_category_name,
              val: Category.sub_category_id,
            });
          }

          if (Category?.sub_category) {
            Category?.sub_category.map((subCat) => {
              if (
                subCat?.SubCatHyperlinks &&
                subCat?.SubCatHyperlinks?.isHyperlinkActive
              ) {
                reqList.push({
                  title: subCat.sub_category_name,
                  val: subCat.sub_category_id,
                });
              }
            });
          }
        });

        defined_categories.forEach((item) => {
          item.sub_category.forEach((list) => {
            if (list.hasOwnProperty("url")) {
              reqList.push({
                title: list.sub_category_name,
                val: list.sub_category_id,
              });
            } else {
              list.sub_category.forEach((rep) => {
                reqList.push({
                  title: rep.sub_category_name,
                  val: rep.sub_category_id,
                });
              });
            }
          });
        });
      }
      setUsersData({
        subCategory: ["All", ...reqList],
      });
    }

    return () => dispatch(clean_usage_report_users());
  }, [
    appDetails.data.app_details?.chosen_template,
    appDetails.data.app_details?.defined_categories,
    appDetails.status,
    dispatch,
  ]);

  // runs first to get the usersList for a given app or subcategory from /appuserslist
  useEffect(() => {
    const fetchData = (val) => {
      let data = {};
      const currentDate = new Date();
      const oneDayAgo = new Date(currentDate);
      oneDayAgo.setDate(currentDate.getDate() - 1);
      oneDayAgo.setHours(23, 59, 59, 999);
      const start_date = timestampRange.startDate;
      const end_date = isTimestampToday(timestampRange.endDate)
        ? oneDayAgo.getTime()
        : timestampRange.endDate;
      let final_timestamp = {
        startDate: start_date,
        endDate: end_date,
      };
      if (appDetails.data.app_meta?.app_type === "bi_app" && val !== "All") {
        data = { sub_category_id: val, timestampRange: final_timestamp };
      } else {
        data = {
          app_id: appDetails.data.app_id,
          timestampRange: final_timestamp,
        };
      }
      dispatch(get_usage_report_users(data));
    };

    fetchData(filters.subCategory?.val || "All");
  }, [
    appDetails.data.app_id,
    appDetails.data.app_meta?.app_type,
    // dispatch,
    filters.subCategory.val,
    timestampRange,
  ]);

  useEffect(() => {
    if (usage_report_users.status === "success") {
      setUsersData((prevState) => ({
        ...prevState,
        usersList: ["All", ...(usage_report_users.data.list_of_users || [])],
      }));
      let reqData = { ...filters, usersList: ["All"] };
      setFilters(reqData);
      fetchUsageReportData(reqData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage_report_users]);

  // sets the timestamp range for for 12 months if available else from publish_date to current day
  const TimestampRange1 = (isMonthly) => {
    const currentDate = new Date();
    if (isMonthly) {
      const twelveMonthsAgo = new Date(currentDate);
      twelveMonthsAgo.setMonth(currentDate.getMonth() - 12);
      twelveMonthsAgo.setHours(0, 0, 0, 0);

      // // const currentDate = new Date();

      // // 1 day ago timestamp (END TIME)
      // const oneDayAgo = new Date(currentDate);
      // oneDayAgo.setDate(currentDate.getDate() - 1);
      // oneDayAgo.setHours(23, 59, 59, 999);

      let startDateTimestamp;
      if (app_published_on && twelveMonthsAgo < new Date(app_published_on)) {
        startDateTimestamp = new Date(app_published_on).getTime();
      } else {
        startDateTimestamp = twelveMonthsAgo.getTime();
      }
      const endDateTimestamp = timeStampObject.onedayago;

      SetTimestampRange({
        startDate: startDateTimestamp,
        endDate: endDateTimestamp,
      });
    }
  };

  // Chips styling (CSS)
  const chipStyles = {
    commonStyle: {
      width: "95px",
      fontFamily: "Energy",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0em",
      textAlign: "center",
      border: "1px solid #CDD3D9",
      height: "24px",
    },
    selected: {
      backgroundColor: "#415a6c",
      color: "#fff",
      fontWeight: 600,
    },
    defaultlight: {
      backgroundColor: "#fff",
      color: "#647887",
      fontWeight: 400,
    },
    defaultdark: {
      backgroundColor: "#282828",
      color: "#cdd3d9",
      fontWeight: 400,
    },
    active: {
      cursor: "pointer",
    },
    disabled: {
      cursor: "not-allowed",
    },
  };

  // selects 7 days ago, 14 days ago etc. chips
  const handleClick = (data) => {
    if (data === selectedChip && data !== "Time Range") {
      return;
    }

    setSelectedChip(data);
    if (data === "Last 7 Days") {
      setMonthStatus(false);
      SetTimestampRange({
        startDate: timeStampObject?.last7_days,
        endDate: timeStampObject?.onedayago,
      });
    } else if (data === "Last 14 Days") {
      setMonthStatus(false);
      SetTimestampRange({
        startDate: timeStampObject?.last14_days,
        endDate: timeStampObject?.onedayago,
      });
    } else if (data === "This Month") {
      const currentDate = new Date();
      setMonthStatus(false);

      SetTimestampRange({
        startDate:
          published_timestamp > timeStampObject?.this_month
            ? published_timestamp
            : timeStampObject?.this_month,
        endDate: isTimestampToday(published_timestamp)
          ? currentDate.getTime()
          : timeStampObject?.onedayago,
      });
    } else if (data === "Monthly") {
      setMonthStatus(true);
      TimestampRange1(true);
    } else if (data === "Time Range") {
      setMonthStatus(false);
      const currentDate = new Date();
      const oneDayAgo = new Date(currentDate);
      oneDayAgo.setDate(currentDate.getDate() - 1);
      oneDayAgo.setHours(0, 0, 0, 0);

      let startDateTimestamp = oneDayAgo.getTime();

      if (app_published_on > startDateTimestamp) {
        startDateTimestamp = app_published_on;
      }

      SetTimestampRange({
        startDate: startDateTimestamp,
        endDate: new Date().getTime(),
      });
    }
  };

  // list of chips available for usageReport chart
  const chipData = [
    "Last 7 Days",
    "Last 14 Days",
    "This Month",
    "Monthly",
    "Time Range",
  ];

  return (
    <RegisterBodyContainer height={`calc(100vh - 200px)`}>
      {appDetails.status === "success" && (
        <>
          {/* handles date-range selection and KPI card*/}
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              alignItems: "center",
            }}
          >
            <KpiCardComponent
              kpiValue={kpiValue}
              theme={theme}
              loading={loadingTopUsers}
            />

            <UsageReportFilter
              setSelectedChip={setSelectedChip}
              published_timestamp={published_timestamp}
              timestampRange={timestampRange}
              SetTimestampRange={SetTimestampRange}
              filters={filters}
              setFilters={setFilters}
              app_type={appDetails.data.app_meta?.app_typPe}
              fetchUsageReportData={fetchUsageReportData}
              theme={theme}
              open={open}
              refTow={refTow}
              timeStampObject={timeStampObject}
            />
          </Box>

          {/* Usage Report chart component contains select-report, select-users, daily,monthly button and bar chart */}
          <Box
            marginTop="16px"
            border={1}
            p={2}
            paddingTop="8px"
            borderColor="#B3BDC4"
          >
            <div
              style={{
                marginRight: "auto",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                fontFamily="Energy"
                fontWeight="600"
                lineHeight="24px"
                fontSize="14px"
                color={theme === "dark" ? "#fff" : "#000"}
              >
                {" "}
                Usage Report
              </Typography>

              <div className="filters">
                <div
                  style={{
                    width: "245px",
                  }}
                >
                  <div
                    ref={refOne}
                    className={`input-box-${theme}`}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    <input
                      value={`${format(
                        new Date(timestampRange.startDate),
                        "dd-MMM-yyyy"
                      ).toUpperCase()}  To  ${format(
                        new Date(timestampRange.endDate),
                        "dd-MMM-yyyy"
                      ).toUpperCase()}`}
                      readOnly
                      className={`input-${theme}`}
                    />
                    <span
                      class="material-symbols-outlined"
                      style={{
                        paddingRight: "5px",
                      }}
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      {open ? "expand_less" : "expand_more"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Box display="flex" justifyContent="space-between">
              <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    marginTop: "8px",
                    cursor: "not-allowed",
                  }}
                >
                  {chipData.map((chip) => (
                    <Chip
                      key={chip}
                      label={chip}
                      clickable
                      onClick={() => handleClick(chip)}
                      disabled={disabledChip.includes(chip)}
                      style={{
                        ...chipStyles.commonStyle,
                        ...(selectedChip === chip
                          ? chipStyles.selected
                          : theme === "light"
                          ? chipStyles.defaultlight
                          : chipStyles.defaultdark),
                        ...(disabledChip.includes(chip)
                          ? chipStyles.disabled
                          : chipStyles.active),
                      }}
                    />
                  ))}
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <UsageChartComponent
                  timestampRange={timestampRange}
                  SetTimestampRange={SetTimestampRange}
                  usersData={usersData}
                  filters={filters}
                  setFilters={setFilters}
                  app_type={appDetails.data.app_meta?.app_type}
                  fetchUsageReportData={fetchUsageReportData}
                  app_id={appDetails.data.app_id}
                  theme={theme}
                />
              </div>
            </Box>

            <Box
              display="flex"
              marginTop="16px"
              alignItems="center"
              justifyContent="center"
              position="relative"
              flex={1}
              sx={(theme) => ({
                bgcolor:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : "#fff",
                height: "280px",
              })}
            >
              {loading || usage_report_users.status === "loading" ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img src={loadingMap} alt="" style={{ height: "280px" }} />
                </Box>
              ) : graphData.labels?.length && !loading ? (
                <>
                  {filters.chart_type === "Stack Chart" ? (
                    <StackChart
                      graphData={graphData}
                      theme={theme}
                      monthStatus={monthStatus}
                    />
                  ) : (
                    <BarChart
                      graphData={graphData}
                      theme={theme}
                      monthStatus={monthStatus}
                    />
                  )}
                </>
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img
                    src={theme === "dark" ? noDataDark : noData}
                    alt=""
                    style={{ height: "250px" }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          {/* Top 10 users chart and App Ranking component*/}
          <div style={{ width: "100%" }}>
            <Box className="top10users_and_rank_container">
              {/* Top 10 users chart all-time,monthly button and monthly calender */}
              <div className="ranking_container">
                <TopUsers topTenUsers={topTenUsers} loading={loadingTopUsers} />
              </div>

              {/* App Ranking component */}
              <div className="ranking_container">
                {appDetails.status === "success" && (
                  <RankingComponent
                    apidata={rankingdata}
                    loading={loadingTopUsers}
                  />
                )}
              </div>
            </Box>
          </div>
        </>
      )}
    </RegisterBodyContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user,
  appDetails: state.AppsData.appDetails,
  usage_report_users: state.register.usage_report_users,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(UsageReport);
