// Import Required Libraries
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@mui/material/Skeleton";
import { v4 } from "uuid";

// Import utils & data
import { getElementContentWidth } from "../../utils/utils";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSkeleton-root": {
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.dark.light : "#e5e5e5",
    },
  },
  appsContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gridGap: "10px",
    gap: "10px",
    padding: "10px",
  },
}));

export function SkeletonForHelpCenterPage(props) {
  const { showSingleRow = false, showNavTab = false, theme } = props;
  const classes = useStyles();
  const skelRow = showSingleRow ? [1] : [1, 2, 3];
  const skelArr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  const containerId = useRef(`appRow_${v4()}`);
  const [sliceCount, setSliceCount] = useState();

  // Defining required side effects
  useEffect(() => {
    // Defining required function
    const handleWindowSize = () => {
      // Should come from config
      const appCardWidth = 300;
      const appRowGridGap = 15;

      // Getting slice count based on width
      const appRowEl = document.getElementById(containerId.current);
      const appRowContentWidth = getElementContentWidth(appRowEl);
      const newSliceCount = Math.floor(
        appRowContentWidth / (appCardWidth + appRowGridGap)
      );
      // Setting slice count
      setSliceCount(newSliceCount - 1);
    };

    // Do this only when singleRowFlag is true
    // Call the function when component mounts
    handleWindowSize();

    // Add event listener to window resize
    window.addEventListener("resize", handleWindowSize);

    return () => {
      // Remove event listener to window resize
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);

  return (
    <div className={classes.root} style={{ height: "100vh" }}>
      <div
        style={{ marginLeft: showNavTab ? "20px" : "0px" }}
        id={containerId.current}
      >
        {showNavTab && (
          <Skeleton
            sx={{ margin: "20px 0px" }}
            variant="text"
            animation="wave"
            width={"20%"}
            height={60}
          />
        )}
        {skelRow.map((el, i) => {
          return (
            <div style={{ marginBottom: "15px" }} key={i}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns:
                    "repeat( auto-fill, minmax(300px, 0.1fr) )",
                  gridGap: "15px",
                }}
              >
                {skelArr.slice(0, sliceCount + 1).map(() => {
                  return (
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width={300}
                      height={150}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function SkeletonForTicketPopUp() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ marginLeft: "50px" }}>
        <Skeleton
          sx={{ margin: "30px 0px" }}
          variant="text"
          animation="wave"
          width={"20%"}
          height={60}
        />
        <Skeleton
          sx={{ margin: "20px 0px" }}
          variant="text"
          animation="wave"
          width={"40%"}
          height={60}
        />
        <Skeleton
          sx={{ margin: "20px 0px" }}
          variant="text"
          animation="wave"
          width={"80%"}
          height={60}
        />
        <Skeleton
          sx={{ margin: "10px 0px" }}
          variant="text"
          animation="wave"
          width={"70%"}
          height={60}
        />
        <Skeleton
          sx={{ margin: "20px 0px" }}
          variant="text"
          animation="wave"
          width={"80%"}
          height={60}
        />
        <Skeleton
          sx={{ margin: "10px 0px" }}
          variant="text"
          animation="wave"
          width={"70%"}
          height={60}
        />
        <Skeleton
          sx={{ margin: "10px 0px" }}
          variant="text"
          animation="wave"
          width={"70%"}
          height={60}
        />
        <Skeleton
          sx={{ margin: "20px 0px" }}
          variant="text"
          animation="wave"
          width={"80%"}
          height={60}
        />
      </div>
    </div>
  );
}

export function SkeletonforManageProfile() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton
        sx={{ margin: "20px 50px" }}
        variant="rectangular"
        animation="wave"
        width={"90%"}
        height={60}
      />
      <Skeleton
        sx={{ margin: "20px 50px" }}
        variant="rectangular"
        animation="wave"
        width={"90%"}
        height={30}
      />
      <Skeleton
        sx={{ margin: "0px 50px" }}
        variant="rectangular"
        animation="wave"
        width={"90%"}
        height={"calc(100vh - 400px)"}
      />
    </div>
  );
}

export function SkeletonRecentApp() {
  const skelArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const classes = useStyles();
  return (
    <Box className={`${classes.root} ${classes.appsContainer}`}>
      {skelArr.map((ind) => {
        return (
          <Skeleton
            sx={{ borderRadius: "4px" }}
            variant="rounded"
            animation="wave"
            width={225}
            height={225}
          />
        );
      })}
    </Box>
  );
}

export function SkeletonLoadScreen(props) {
  const {
    showSearchBar = false,
    showSingleRow = false,
    showNavTab = false,
    showBiSkel = false,
  } = props;
  const classes = useStyles();
  const skelRow = showSingleRow ? [1] : [1, 2, 3, 4];
  const skelArr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  const containerId = useRef(`appRow_${v4()}`);
  const [sliceCount, setSliceCount] = useState();

  // Defining required side effects
  useEffect(() => {
    // Defining required function
    if (!showBiSkel) {
      const handleWindowSize = () => {
        // Should come from config
        const appCardWidth = 225;
        const appRowGridGap = 10;

        // Getting slice count based on width
        const appRowEl = document.getElementById(containerId.current);
        const appRowContentWidth = getElementContentWidth(appRowEl);
        const newSliceCount = Math.floor(
          appRowContentWidth / (appCardWidth + appRowGridGap)
        );
        // Setting slice count
        setSliceCount(newSliceCount - 1);
      };

      // Do this only when singleRowFlag is true
      // Call the function when component mounts
      handleWindowSize();

      // Add event listener to window resize
      window.addEventListener("resize", handleWindowSize);

      return () => {
        // Remove event listener to window resize
        window.removeEventListener("resize", handleWindowSize);
      };
    }
  }, []);

  const dropZoneHeight =
    skelArr.length > sliceCount ? skelArr.length / sliceCount : 1;
  const dropZoneHeightRounded = Math.ceil(dropZoneHeight) * 255;
  const textHeight = dropZoneHeightRounded.toString();
  const textHeightinPX = textHeight + "px";

  return (
    <div className={classes.root} style={{ height: "100vh" }}>
      {showBiSkel ? (
        <div style={{ display: "flex", height: "100%", marginLeft: "10px" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"20%"}
            height={"90%"}
          />
          <div style={{ width: "80%" }}>
            {skelRow.map(() => {
              return (
                <>
                  <Skeleton
                    sx={{ margin: "30px" }}
                    variant="text"
                    animation="wave"
                    width={"65%"}
                    height={"5%"}
                  />
                  <Skeleton
                    sx={{ margin: "30px" }}
                    variant="text"
                    animation="wave"
                    width={"50%"}
                    height={"2%"}
                  />
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div style={{ marginLeft: "50px" }} id={containerId.current}>
          {showSearchBar && (
            <Skeleton
              sx={{ margin: "20px 0px" }}
              variant="text"
              animation="wave"
              width={"90%"}
              height={60}
            />
          )}
          {showNavTab && (
            <Skeleton
              sx={{ margin: "20px 0px" }}
              variant="text"
              animation="wave"
              width={"30%"}
              height={60}
            />
          )}
          {skelRow.map(() => {
            return (
              <div style={{ marginBottom: "30px" }}>
                {!showSingleRow && (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width={200}
                    height={30}
                  />
                )}
                <div style={{ display: "flex", marginTop: "15px" }}>
                  {skelArr.slice(0, sliceCount).map(() => {
                    return (
                      <div style={{ marginRight: "10px" }}>
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={225}
                          height={225}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SkeletonLoadScreen;
