import React, { useState } from "react";
import { Grid } from "@mui/material";
import InputFiled from "../InputField";
import "../../index.scss";
import {
  removeSubField,
  updateMiddleSubcatState,
} from "../../../../utils/utils";
import { update_BIappDetails } from "../../../../redux/actions";
import { useDispatch, connect } from "react-redux";

const SubCategoryField = ({
  data,
  // setCategoryInfo,
  categoryInfo,
  id,
  defaultValue,
  isVibrating,
  isFieldCardRed,
  // setCategoryInfoLocal,
  categoryInfoLocal,
  catIndex,
  subCatIndex,
  URLValid,
  setURLValid,
  isSubCatReportvibrating,
  isSubCatReportTextFieldRed,
  SetisSubCatReportTextFieldRed,
  SetisSubCatReportvibrating,
}) => {
  const dispatch = useDispatch();
  const [vibrateField, setVibrateField] = useState(false);
  const [isTextFieldRed, setTextFieldRed] = useState(false);
  const value =
    categoryInfoLocal[catIndex].sub_category[subCatIndex].sub_category_name ||
    "";
  var SubCatgeoryReportUrl;
  if (
    categoryInfoLocal[catIndex].sub_category[subCatIndex]?.SubCatHyperlinks
      ?.isHyperlinkActive === true
  ) {
    SubCatgeoryReportUrl =
      categoryInfoLocal[catIndex].sub_category[subCatIndex]?.SubCatHyperlinks
        ?.url || "";
  } else {
    SubCatgeoryReportUrl = "NA";
  }

  const handleChange = (key, val) => {
    if (val && val.length > 50) return;
    const reqData = {
      ...categoryInfoLocal[catIndex].sub_category[subCatIndex],
      sub_category_name: val,
    };

    // setCategoryInfoLocal(
    //   updateMiddleSubcatState(categoryInfoLocal, catIndex, subCatIndex, reqData)
    // );
    dispatch(
      update_BIappDetails({
        categoryInfoLocal: updateMiddleSubcatState(
          categoryInfoLocal,
          catIndex,
          subCatIndex,
          reqData
        ),
      })
    );
  };

  const removeVibration = () => {
    setTimeout(() => {
      setVibrateField(false);
      setTextFieldRed(true);
    }, 1000);
  };

  const removeVibrationv2 = () => {
    setTimeout(() => {
      SetisSubCatReportTextFieldRed(false);
      SetisSubCatReportvibrating(false);
    }, 1000);
  };

  const handleDone = () => {
    if (!value) {
      setVibrateField(true);
      removeVibration();
      return;
    }

    if (!SubCatgeoryReportUrl || URLValid.status === "Invalid") {
      SetisSubCatReportTextFieldRed(true);
      SetisSubCatReportvibrating(true);
      removeVibrationv2();
      return;
    }
    const reqData = {
      ...categoryInfo[catIndex].sub_category[subCatIndex],
      sub_category_name: value,
      isSubCatEditable: false,
    };
    // setCategoryInfo(
    //   updateMiddleSubcatState(categoryInfo, catIndex, subCatIndex, reqData)
    // );
    dispatch(
      update_BIappDetails({
        categoryInfo: updateMiddleSubcatState(
          categoryInfo,
          catIndex,
          subCatIndex,
          reqData
        ),
      })
    );
  };

  const handleClose = () => {
    if (!defaultValue.sub_category_name) {
      // setCategoryInfo(removeSubField(categoryInfo, catIndex, subCatIndex));
      // setCategoryInfoLocal(
      //   removeSubField(categoryInfoLocal, catIndex, subCatIndex)
      // );
      dispatch(
        update_BIappDetails({
          categoryInfo: removeSubField(categoryInfo, catIndex, subCatIndex),
          categoryInfoLocal: removeSubField(
            categoryInfoLocal,
            catIndex,
            subCatIndex
          ),
        })
      );
    } else {
      const reqData = {
        ...categoryInfo[catIndex].sub_category[subCatIndex],
        isSubCatEditable: false,
      };
      // setCategoryInfo(
      //   updateMiddleSubcatState(categoryInfo, catIndex, subCatIndex, reqData)
      // );
      // setCategoryInfoLocal(
      //   updateMiddleSubcatState(
      //     categoryInfoLocal,
      //     catIndex,
      //     subCatIndex,
      //     reqData
      //   )
      // );
      dispatch(
        update_BIappDetails({
          categoryInfo: updateMiddleSubcatState(
            categoryInfo,
            catIndex,
            subCatIndex,
            reqData
          ),
          categoryInfoLocal: updateMiddleSubcatState(
            categoryInfoLocal,
            catIndex,
            subCatIndex,
            reqData
          ),
        })
      );
    }
  };

  return (
    <Grid container alignItems="flex-end">
      <InputFiled
        data={{ ...data, label: "Sub-category name", key: "sub_category_name" }}
        handleChange={handleChange}
        value={value}
        onCatInputBlur={handleDone}
        isFieldVibrating={Boolean((vibrateField || isVibrating) && !value)}
        isFieldRed={Boolean((isTextFieldRed || isFieldCardRed) && !value)}
      />
      <Grid item alignItems="flex-end" sx={{ mx: "24px" }}>
        <button
          className={`btn-cancel ${isVibrating && "shake-category-field"}`}
          onClick={handleClose}
          style={{ width: "50px" }}
        >
          <span class="material-icons-outlined">close</span>
        </button>
        <button
          className={`btn-done ${isVibrating && "shake-category-field"}`}
          onClick={handleDone}
          style={{ width: "50px" }}
        >
          <span class="material-icons-outlined">done</span>
        </button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
});

export default connect(mapStateToProps)(SubCategoryField);
