import React, { useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import "../../index.scss";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import { removeReportField, updateSubState } from "../../../../utils/utils";
import { update_BIappDetails } from "../../../../redux/actions";
const ReportCard = ({
  data,
  categoryInfo,
  // setCategoryInfo,
  CategoryId,
  isFieldsEditable,
  theme,
  appDetails,
  setIsFieldCardRed,
  catIndex,
  subCatIndex,
  // setCategoryInfoLocal,
  categoryInfoLocal,
  reportIndex,
  marginLeft,
  borderColor = "#282828",
}) => {
  const dispatch = useDispatch();
  const {
    sub_category_name = "",
    url = "",
    sub_category_icon = "",
    app_logo_type,
    app_logo_url,
  } = data;

  const eventTracker = useAnalyticsEventTracker(
    "Categories",
    appDetails.data.app_id
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event) => {
    if (isFieldsEditable) return;
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    // setCategoryInfo(
    //   removeReportField(categoryInfo, catIndex, subCatIndex, reportIndex)
    // );
    // setCategoryInfoLocal(
    //   removeReportField(categoryInfoLocal, catIndex, subCatIndex, reportIndex)
    // );
    dispatch(
      update_BIappDetails({
        categoryInfo: removeReportField(
          categoryInfo,
          catIndex,
          subCatIndex,
          reportIndex
        ),
        categoryInfoLocal: removeReportField(
          categoryInfoLocal,
          catIndex,
          subCatIndex,
          reportIndex
        ),
      })
    );
    eventTracker("Delete btn clicked", "subcategory deleted");
  };

  const handleEdit = () => {
    setIsFieldCardRed(false);
    const reqData =
      subCatIndex > -1
        ? {
            ...categoryInfo[catIndex].sub_category[subCatIndex].sub_category[
              reportIndex
            ],
            isSubCatEditable: true,
          }
        : {
            ...categoryInfo[catIndex].sub_category[reportIndex],
            isSubCatEditable: true,
          };
    // setCategoryInfo(
    //   updateSubState(categoryInfo, catIndex, subCatIndex, reqData, reportIndex)
    // );
    // setCategoryInfoLocal(
    //   updateSubState(
    //     categoryInfoLocal,
    //     catIndex,
    //     subCatIndex,
    //     reqData,
    //     reportIndex
    //   )
    // );
    dispatch(
      update_BIappDetails({
        categoryInfo: updateSubState(
          categoryInfo,
          catIndex,
          subCatIndex,
          reqData,
          reportIndex
        ),
        categoryInfoLocal: updateSubState(
          categoryInfoLocal,
          catIndex,
          subCatIndex,
          reqData,
          reportIndex
        ),
      })
    );

    eventTracker("Edit btn clicked", "edit subcategory");
  };

  return (
    <div
      className={`sub-category-card ${theme}-sub-category-card`}
      id={data.sub_category_id}
      style={{
        marginLeft: marginLeft,
        marginRight: marginLeft,
        borderLeft: `5px solid ${
          theme === "light"
            ? borderColor
            : marginLeft === "0px"
            ? "#fff"
            : borderColor
        }`,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        style={{
          width: "88%",
        }}
      >
        {app_logo_type === "uploaded_image" ? (
          <img
            src={app_logo_url || sub_category_icon}
            alt="icon"
            style={{ marginRight: "15px", width: "35px" }}
          />
        ) : (
          <span
            class="material-icons-outlined"
            style={{
              fontSize: "30px",
              color:
                theme === "light"
                  ? borderColor
                  : marginLeft === "0px"
                  ? "#fff"
                  : borderColor,
            }}
          >
            {sub_category_icon}
          </span>
        )}
        <Box
          ml={1}
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              color:
                theme === "light"
                  ? borderColor
                  : marginLeft === "0px"
                  ? "#fff"
                  : borderColor,
            }}
            className="sub-category-card-name"
          >
            {sub_category_name}
          </div>
          <div
            className={`sub-categroy-card-url ${theme}-sub-categroy-card-url`}
            style={{
              marginTop: "6px",
            }}
          >
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{
                whiteSpace: "nowrap",
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              }}
            >
              {url}
            </a>
          </div>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <span class="material-icons-outlined drag-icon">reorder</span>
        <span class="material-icons-outlined" onClick={handleOpen}>
          more_vert
        </span>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  theme: state.user.ui_preferences?.theme,
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(ReportCard);
