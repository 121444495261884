// Import required libraies
import React from "react";
import { connect } from "react-redux";

// Import Custom Component
import Loader from "../../../components/Loader/Loader";

// Import action creators
import {
    setActiveAssetCollection,
    toggleSidenav,
} from "../../../redux/actions";

// Import utils/data

// Import styles
import "./DataAssetsSideNav.scss";

function DataAssetsSideNav(props) {
    let {
        user,
        collections,
        activeCollection,
        setActiveCollection,
        toggleSidenav,
        setActiveAssetCollection,
        theme = "light",
    } = props;

    const handleMenuClose = () => {
        toggleSidenav();
    };

    return (
        <aside
            className={`${theme} ${
                user.screen.isSidenavBarExpanded
                    ? "sidenav-expanded"
                    : "sidenav-collapsed"
            }`}
        >
            <nav>
                {!collections.data ? (
                    <Loader />
                ) : (
                    <div>
                        {
                            <div>
                                {/* <div
                  className="nav-item-menu disabled-style"
                  onClick={handleMenuClose}
                >
                  <i className={`material-icons-outlined nav-icon-menu`}>
                    reorder
                  </i>
                  <p
                    className={`${
                      user.screen.isSidenavBarExpanded ? "visible" : "hidden"
                    } nav-text-menu`}
                  >
                    Menu
                  </p>
                </div> */}
                                <button
                                    className={
                                        user.screen.isSidenavBarExpanded
                                            ? "jira-button expanded-nav"
                                            : "jira-button collapsed-nav"
                                    }
                                    onClick={handleMenuClose}
                                >
                                    <i className="material-icons jira-icon">
                                        {user.screen.isSidenavBarExpanded
                                            ? "chevron_left"
                                            : "chevron_right"}
                                    </i>
                                </button>
                                {collections.data.map((obj) => (
                                    <div
                                        onClick={() => {
                                            setActiveCollection({
                                                id: obj.id,
                                                name: obj.name,
                                            });
                                            setActiveAssetCollection({
                                                id: obj.id,
                                                name: obj.name,
                                            });
                                        }}
                                        className={`nav-item disabled-style ${
                                            activeCollection.id === obj.id
                                                ? "active"
                                                : ""
                                        }`}
                                        key={obj.id}
                                        title={obj.name}
                                    >
                                        <i
                                            className={`${obj.materialUiClassName} nav-icon`}
                                        >
                                            {obj.materialUiIconName}
                                        </i>
                                        <p
                                            className={`${
                                                user.screen.isSidenavBarExpanded
                                                    ? "visible"
                                                    : "hidden"
                                            } nav-text`}
                                        >
                                            {obj.name}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                )}
            </nav>
        </aside>
    );
}

DataAssetsSideNav.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    toggleSidenav,
    setActiveAssetCollection,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataAssetsSideNav);
