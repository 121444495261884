import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
// import "./index.scss";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import FieldComponent from "./UsageReportFilter/FieldComponent";
const data1 = [
  {
    label: "Select Report :",
    key: "subCategory",
    placeholder: "Choose Report",
  },
  {
    label: "Select Specific User :",
    key: "usersList",
    placeholder: "Username",
  },
];

const UsageChartComponent = ({
  timestampRange,
  SetTimestampRange,
  usersData,
  filters,
  setFilters,
  app_type,
  fetchUsageReportData,
  app_id,
  theme,
}) => {
  const refOne = useRef(null);
  const refTow = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Daily");

  const eventTracker = useAnalyticsEventTracker("Usage report", app_id);
  const [addTooltips, setAddTooltip] = useState(false);
  const removeTooltip = () => {
    setTimeout(() => setAddTooltip(false), 1000);
  };

  const updateData = (k, v, label) => {
    const reqData = { ...filters, [k]: v };
    setFilters(reqData);
    fetchUsageReportData(reqData);
    eventTracker(label, v.includes("All") ? "All" : "Email selected");
  };
  const handleChange = (k, v, label) => {
    if (
      k === "chart_type" &&
      ((v === "Stack Chart" && filters.usersList?.length <= 1) ||
        (v !== "Stack Chart" && filters.usersList?.length > 1))
    )
      return;
    if (k === "usersList") {
      if (v === "All") {
        if (filters[k].includes("All")) {
          // updateData(k, []);
          return;
        } else {
          updateData(k, ["All"], label);
        }
      } else {
        if (filters[k].includes("All")) {
          updateData(k, [v], label);
        } else {
          if ((filters[k] || []).includes(v)) {
            const reqData = filters[k].filter((item) => item !== v);
            //checking if all users are removed than "All" is added automatically as a filter.
            if (!reqData.length) {
              updateData(k, ["All"], label);
            } else {
              updateData(k, reqData, label);
            }
          } else if (filters[k]?.length > 4) {
            setAddTooltip(true);
            removeTooltip();
          } else {
            updateData(k, [...(filters[k] || []), v], label);
          }
        }
      }
    } else {
      let reqData = { ...filters, [k]: v };
      setFilters(reqData);
      // for subCategory it is already running in useffect.
      if (k !== "subCategory") {
        fetchUsageReportData(reqData);
      }
      // setup for GA tracking
      eventTracker(label, v.title || v);
    }
  };

  useEffect(() => {
    if (filters.usersList.length > 1 && filters.chart_type !== "Stack Chart") {
      setFilters({ ...filters, chart_type: "Stack Chart" });
    } else if (
      filters.usersList.length <= 1 &&
      filters.chart_type === "Stack Chart"
    ) {
      setFilters({ ...filters, chart_type: "Bar Chart" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.usersList]);

  useEffect(() => {
    if (refOne.current) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      !(
        (refOne.current && refOne.current.contains(event.target)) ||
        (refTow.current && refTow.current.contains(event.target))
      )
    ) {
      setOpen(false);
    }
  };

  return (
    <>
      <div>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="end"
          sx={{
            marginBottom: "20px",
            marginTop: "4px",
          }}
        >
          {data1.map((item) => {
            if (app_type !== "bi_app" && item.key === "subCategory") {
              return null;
            }
            return (
              <FieldComponent
                key={item.label}
                value={filters[item.key]}
                handleChange={handleChange}
                data={item}
                autoCheck={item.key === "usersList"}
                dataList={usersData}
                addTooltips={addTooltips}
              />
            );
          })}
        </Box>
      </div>
    </>
  );
};

export default UsageChartComponent;
