import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Drawer, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "../../../../../components/Loader/Loader";
import loginImg from "../../../../../../assets/images/register-images/login-img.png";
import {
  getAllApps,
  updateReportTemplate,
  update_dublicate_workspace_list,
  update_power_bi_reports,
  update_power_bi_workspace,
  update_BIappDetails,
} from "../../../../../redux/actions";
import { StyledInputBase } from "../../../../../components/UserTable/style";
import { useStylesPowerBi } from "../../../style";

function fetchAPI(payload) {
  const url = "/api/powerbi_reports";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

function fetchImportAPI(obj) {
  const url = "/api/import-reports";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),
  });
}

const LoginPowerbiScreen = ({ handleClose }) => {
  const classes = useStylesPowerBi();

  const showToaster = () => {
    setTimeout(() => {
      toast.success(
        "You have successfully signed In. Now you can reopen your workspaces.",
        {
          style: {
            fontSize: "14px",
            color: "#4e4e4e",
          },
        }
      );
    }, 1500);
  };
  const handleAuth = () => {
    window.open(`${window.origin}/powerbi_auth/powerbi_login`, "_blank"); //need to be changed after powerbi access is fully set on backend
    handleClose();
    showToaster();
  };

  return (
    <div className={classes.loginContainer}>
      <img src={loginImg} alt="" />
      <div className={classes.loginText}>
        Please login to PowerBi to see your reports
      </div>
      <button className={classes.loginBtn} onClick={handleAuth}>
        Login
      </button>
    </div>
  );
};

const WorkspaceCard = ({
  workspace,
  reports,
  status,
  localWorkspaceID,
  setLocalWorkspaceID,
  dublicate_workspace_list = [],
}) => {
  const classes = useStylesPowerBi();
  const [showAppList, setShowAppList] = useState(false);

  const handleReports = () => {
    setShowAppList((prev) => !prev);
    setLocalWorkspaceID(workspace.id);
  };
  const handleNavigateApp = (path) => {
    const url = `${window.origin}${path}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div
        className={classes.workspaceCard}
        style={{
          border:
            localWorkspaceID === workspace.id
              ? "2px solid #FC007F"
              : "2px solid transparent",
        }}
        onClick={handleReports}
      >
        <div
          className={
            localWorkspaceID === workspace.id
              ? classes.workspaceText
              : classes.workspaceTextforImport
          }
        >
          {workspace.name}
        </div>
        {dublicate_workspace_list.length ? (
          <div
            className={classes.workspaceIconArrow}
            title="Workspace has been imported to Data@Reckitt
                        already."
          >
            <InfoIcon
              sx={(theme) => ({
                fontSize: "15px",
                color: theme.palette.mode === "dark" ? "#fff" : "#282828",
              })}
            />
          </div>
        ) : null}
      </div>
      {showAppList && dublicate_workspace_list.length > 0 && (
        <div className={classes.appLists}>
          <div className={classes.existListHeading}>
            The above workspace has been imported to Data@Reckitt already.
            Please review the application before importing again.
          </div>
          {dublicate_workspace_list.map((item) => {
            return (
              <div
                key={item.app_name}
                onClick={() => handleNavigateApp(item.app_path)}
                className={`${classes.appLink} GA-dublicate-url`}
              >
                {item.app_name}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const WorkspaceDrawer = ({
  open,
  handleClose,
  handleBegin,
  // setSelectedWorkspace,
  selectedWorkspace,
  user,
  appFields,
  allApps,
  dublicate_workspace_list,
}) => {
  const classes = useStylesPowerBi();
  const dispatch = useDispatch();
  const { powerBiWorkspace, powerBiReports } = useSelector(
    (state) => state.register
  );
  const { data, status, message } = powerBiWorkspace;
  const [searchedReport, setSearchedReport] = useState([]);
  const [searchedVal, setSearchedVal] = useState("");
  const [localWorkspaceID, setLocalWorkspaceID] = useState(
    selectedWorkspace || ""
  );
  const [searchWorkspace, setSearchWorkspace] = useState([]);
  const [layoutLoading, setLayoutLoading] = useState("pending");

  const allReports = useMemo(() => {
    let reqReports = [];
    (Object.keys(powerBiReports.data) || []).forEach((item) => {
      reqReports = [...reqReports, ...(powerBiReports.data[item]?.value || [])];
    });
    return reqReports;
  }, [powerBiReports.data]);

  const handleCreateImportedLayout = async () => {
    setLayoutLoading("loading");
    // setSelectedWorkspace(localWorkspaceID);
    dispatch(update_BIappDetails({ selectedWorkspace: localWorkspaceID }));
    const requestObject = {
      // workspace_id: appFields.workspace_id,
      workspace_id: localWorkspaceID,
      // workspace_id: "8a0013bc-5607-46d9-a1b3-fbafd3f4337b",
      function: appFields.app_details.function,
      line_of_business: appFields.app_details.line_of_business,
      business_owners: [user.preferred_username],
      technical_owners: [user.preferred_username],
      geography: appFields.app_details.geography,
    };
    try {
      const response = await fetchImportAPI(requestObject);
      const resJson = await response.json();
      if (resJson?.status.statusCode === "datareckitt-200") {
        setLayoutLoading("success");
        dispatch(
          updateReportTemplate({
            templateData: resJson.data,
            template_no: 5,
          })
        );
        handleClose();
        // handleBegin();
      } else {
        setLayoutLoading("error");
        toast.error("Opps! something went wrong.");
      }
    } catch (error) {
      setLayoutLoading("error");
      toast.error("Opps! something went wrong.");
      console.log("error", error);
    }
  };

  const requestSearch = (val) => {
    if (val.length > 0) {
      const reqSearchedReport = allReports.filter((item) =>
        item.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())
      );
      const reqSearchWorkspace = data.filter((item) =>
        item.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())
      );
      setSearchWorkspace(reqSearchWorkspace);
      setSearchedReport(reqSearchedReport);
      setSearchedVal(val);
    } else if (val.length === 0) {
      setSearchedReport([]);
      setSearchedVal(val);
    }
  };

  const cancelSearch = () => {
    setSearchedReport([]);
    setSearchedVal("");
  };

  const setDefaultData = useCallback(
    () =>
      dispatch(
        update_power_bi_workspace({
          status: "error",
          message: "login_required",
        })
      ),
    [dispatch]
  );
  const setDefaultReportData = useCallback(
    () =>
      dispatch(
        update_power_bi_workspace({
          status: "error",
          message: "login_required",
        })
      ),
    [dispatch]
  );

  const fetchReports = async (workspace) => {
    dispatch(
      update_power_bi_reports({
        status: "loading",
        message: "",
      })
    );
    const reqWorkspaceIds = [];
    workspace.forEach((item) => {
      reqWorkspaceIds.push(item.id);
    });
    try {
      const response = await fetchAPI({ group_ids: reqWorkspaceIds });
      const resJson = await response.json();
      if (resJson?.status.statusCode === "datareckitt-200") {
        dispatch(
          update_power_bi_reports({
            status: "success",
            message: "",
            data: resJson?.data,
          })
        );
      } else {
        setDefaultReportData();
        toast.error("Opps! something went wrong.");
      }
    } catch (error) {
      toast.error("Opps! something went wrong.");
      console.log("error", error);
      setDefaultReportData();
    }
  };

  useEffect(() => {
    if (allApps.status === "pending") {
      dispatch(getAllApps());
    }
  }, [allApps.status, dispatch]);

  useEffect(() => {
    if (
      dublicate_workspace_list.status !== "pending" ||
      allApps.status !== "success" ||
      !data.length
    )
      return;
    dispatch(update_dublicate_workspace_list({ status: "loading" }));
    let dublicateWorkSpaces = {};
    const workspacesIdList = data.map((item) => item.id);
    (allApps.data || []).forEach((item) => {
      if (
        item.app_meta.app_type === "bi_app" &&
        item.app_details.workspace_id &&
        workspacesIdList.includes(item.app_details.workspace_id)
      ) {
        const workspaceId = item.app_details.workspace_id;
        dublicateWorkSpaces = {
          ...dublicateWorkSpaces,
          [workspaceId]: [
            ...(dublicateWorkSpaces[workspaceId] || []),
            {
              workspace_id: item.app_details.workspace_id,
              app_name: item.app_meta.app_name,
              app_path: item.app_meta.app_path,
            },
          ],
        };
      }
    });
    dispatch(
      update_dublicate_workspace_list({
        status: "success",
        data: dublicateWorkSpaces,
      })
    );
  }, [
    allApps.data,
    allApps.status,
    data,
    dispatch,
    dublicate_workspace_list.status,
  ]);

  useEffect(() => {
    const fetchWorkSpace = async () => {
      dispatch(update_power_bi_workspace({ status: "loading", message: "" }));
      try {
        const response = await fetch("/api/powerbi_workspace");
        const data = await response.json();
        if (
          data?.status.statusCode === "datareckitt-200" &&
          data?.data?.value
        ) {
          dispatch(
            update_power_bi_workspace({
              status: "success",
              data: data?.data.value,
            })
          );
          fetchReports(data?.data.value);
        } else {
          setDefaultData();
        }
      } catch (error) {
        toast.error("Opps! something went wrong.");
        console.log("error", error);
        setDefaultData();
      }
    };
    if (status === "loading" || status === "success") return;
    fetchWorkSpace();
  }, []);

  return (
    <Drawer
      open={open}
      anchor="right"
      variant="persistent"
      className={classes.drawer}
    >
      <div className={classes.closeIcon}>
        <span class="material-icons-outlined" onClick={handleClose}>
          close
        </span>
      </div>
      <div className={classes.heading}>My PowerBI workspaces</div>

      {status === "loading" ? (
        <Loader />
      ) : message === "login_required" ? (
        <LoginPowerbiScreen handleClose={handleClose} />
      ) : (
        <>
          <div className={classes.search}>
            <StyledInputBase
              placeholder="Search Workspaces"
              inputProps={{
                "aria-label": "search",
              }}
              onChange={(e) => requestSearch(e.target.value)}
              value={searchedVal}
              className={classes.inputField}
            />
            {searchedVal ? (
              <IconButton onClick={(e) => cancelSearch(e)}>
                <ClearIcon
                  sx={{
                    color: "#282828",
                  }}
                />
              </IconButton>
            ) : (
              <IconButton>
                <SearchIcon
                  sx={{
                    color: "#282828",
                  }}
                />
              </IconButton>
            )}
          </div>
          <div className={classes.reportContainer}>
            {!searchedVal &&
              (data || []).map((item) => (
                <WorkspaceCard
                  workspace={item}
                  key={item.id}
                  reports={powerBiReports.data[item.id]}
                  status={powerBiReports.status}
                  localWorkspaceID={localWorkspaceID}
                  setLocalWorkspaceID={setLocalWorkspaceID}
                  dublicate_workspace_list={
                    dublicate_workspace_list.data[item.id]
                  }
                />
              ))}

            {searchedVal &&
            (searchedReport.length || searchWorkspace.length) ? (
              <>
                {/* {searchedReport.map((item) => (
                                    <ReportCard report={item} key={v4()} />
                                ))} */}
                {searchWorkspace.map((item) => (
                  <WorkspaceCard
                    workspace={item}
                    key={item.id}
                    reports={powerBiReports.data[item.id]}
                    status={powerBiReports.status}
                    localWorkspaceID={localWorkspaceID}
                    setLocalWorkspaceID={setLocalWorkspaceID}
                    dublicate_workspace_list={
                      dublicate_workspace_list.data[item.id]
                    }
                  />
                ))}
              </>
            ) : searchedVal ? (
              <div
                className={classes.workspaceText}
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                }}
              >
                No result found
              </div>
            ) : null}
          </div>
          <button
            className="btn GA-create-layout"
            style={{ width: "100%" }}
            onClick={handleCreateImportedLayout}
            disabled={localWorkspaceID === "" || layoutLoading === "loading"}
          >
            {layoutLoading === "pending" || layoutLoading === "error"
              ? "Create Layout"
              : "Creating ..."}
          </button>
        </>
      )}
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  selectedWorkspace: state.registerNewApp.BIappDetails.selectedWorkspace,
  user: state.user,
  allApps: state.AppsData.allApps,
  dublicate_workspace_list: state.register.dublicate_workspace_list,
});

export default connect(mapStateToProps)(WorkspaceDrawer);
