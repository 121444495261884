import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    copyOnClick: {
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#8d9ca6",
        fontSize: "12px",
    },
}));
const CopyToClipBoard = ({ text = "" }) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const removeItem = () => {
        setTimeout(() => setShow(false), 1000);
    };
    const copyText = () => {
        navigator.clipboard.writeText(text);
        setShow(true);
        removeItem();
    };
    return (
        <Box display="flex" alignItems="center" gap={1} fontSize={12}>
            <span
                onClick={copyText}
                title="copy"
                className={`${classes.copyOnClick} material-icons-outlined`}
            >
                content_copy
            </span>
            {show && <div>Copied</div>}
        </Box>
    );
};

export default CopyToClipBoard;
