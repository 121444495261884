import React from "react";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import "./style.scss";

const FormLabelComponent = ({ label, required, toolTipText = "" }) => (
    <div className="form-label">
        <div>{label}</div>
        {required && <div className="form-label-star">*</div>}
        {toolTipText && (
            <Tooltip
                title={
                    toolTipText.split(".").length > 1 ? (
                        <ul>
                            {toolTipText.split(".").map((item) => (
                                <li>{item}</li>
                            ))}
                        </ul>
                    ) : (
                        toolTipText
                    )
                }
                arrow
                placement="top"
            >
                <InfoIcon
                    sx={{
                        fontSize: "16px",
                        ml: "5px",
                        color: "#8d9ca6",
                    }}
                />
            </Tooltip>
        )}
    </div>
);

export default FormLabelComponent;
