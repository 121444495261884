import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
// Import MUI components
import { Box, Dialog } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import equal from "deep-equal";

import AddSubcategoryPopUp from "./Components/AddSubcategoryPopUp";
import BreadCrumb from "./Components/BreadCrumb";
// Import Actions
import { updateReportTemplate } from "../../../../redux/actions";
import DashboardView from "./DashboardView";
import { getDefaultTempData } from "./utils";
import addImg from "../../../../../assets/images/Dashboard-images/addData.svg";
import * as htmlToImage from "html-to-image";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader/Loader";
import ConfirmationModal from "./Components/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        "& .MuiDialog-paper": {
            overflowY: "hidden",
            backgroundColor:
                theme.palette.mode === "dark" ? "#000000" : "#f6f6f6",
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "90vw !important",
            minWidth: "90vw !important",
            maxHeight: "95vh !important",
            minHeight: "95vh !important",
            display: "flex",
            flexDirection: "column",
        },
    },
    container: {
        display: "flex !important",
        flexDirection: "column",
        // paddingBottom: "20px",
        flex: 1,
        paddingLeft: "25px",
        paddingRight: "25px",
        position: "relative",
    },
    content: {
        flex: 1,
    },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "10px",
        marginBottom: "20px",
    },
    discardBtn: {
        backgroundColor: "#E7E9EE !important",
        color: "#282828 !important",
    },
    addReportText: {
        fontSize: "14px",
        color: "#282828 !important",
    },
    addReportContainer: {
        height: "calc(95vh - 110px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    emptyScreenImg: {
        height: "250px",
    },
    iconForScreenshot: {
        "& .googleIcons": {
            color: "#fc007f !important",
            "-webkit-text-fill-color": "#fc007f !important",
            backgroundColor: "transparent",
        },
    },
    normalIcon: {
        "& .googleIcons": {
            background:
                "linear-gradient(45deg, #FC007F 0%,#FC007F 50% ,#FF4D48 100%)",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
        },
    },
}));

function fetchAPI(formData) {
  const url = "/api/imageuploadv2";
  return fetch(url, {
    method: "POST",
    headers: {},
    body: formData,
  });
}
function fetchImportAPI(obj) {
  const url = "/api/sync-reports";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),
  });
}
function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const DashboardTemplate = (props) => {
  const {
    open = false,
    setopenCreateTemplatePopUp,
    registerTemplateFields,
    appTemplateState = {},
    appFields,
    categoryInfo,
    setCategoryInfo,
    template_no,
    setCategoryInfoLocal,
    isFieldsEditable,
    user,
    appDetails,
    selectedWorkspace,
    setSelectedWorkspace,
    prevWorkspace,
    setPrevWorkspace,
    setOpenWorkspaceDrawer,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  //Define required states
  const [openAddReportPopUp, setopenAddReportPopUp] = useState(false);
  const [selectedWidgetType, setSelectedWidgetType] = useState(null);
  const [parentID, setParentID] = useState(null);
  const [selectedSubcat, setSelectedSubcat] = useState({});
  const [parent_report_type, setParentReportType] = useState("");
  const [breadcrumbArr, setBreadCrumbArr] = useState([
    { textName: appFields.app_name, textID: appFields.app_id },
  ]);
  const [renderSubReportArr, setRenderSubReportArr] =
    useState(appTemplateState);
  const [waiting, setWaiting] = useState(false);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [syncloading, setSyncLoading] = useState("pending");
  const [msg, setMsg] = useState("");

  const handleCancelSync = () => {
    setOpenSyncModal(false);
  };

  const handleClickSyncReports = () => {
    if (equal(categoryInfo, appTemplateState)) {
      setOpenSyncModal(true);
      handleSyncReports();
    } else {
      setMsg(
        "You have unsaved changes! Are you sure you want to discard them and sync reports ?"
      );
      setOpenSyncModal(true);
    }
  };

  const handleClose = () => {
    setopenCreateTemplatePopUp(false);
  };

  const handleSyncReports = async () => {
    setMsg("");
    setSyncLoading("loading");
    const requestObject = {
      workspace_id: appDetails.data.app_details.workspace_id,
      function: appFields.app_details.function,
      line_of_business: appFields.app_details.line_of_business,
      business_owners: [user.preferred_username],
      technical_owners: [user.preferred_username],
      geography: appFields.app_details.geography,
      app_id: appDetails.data.app_id,
    };
    try {
      const response = await fetchImportAPI(requestObject);
      const resJson = await response.json();
      if (resJson?.status.statusCode === "datareckitt-200") {
        dispatch(
          updateReportTemplate({
            templateData: resJson.data,
            template_no: 5,
          })
        );
        setRenderSubReportArr(resJson.data);
        setSyncLoading("success");
        setOpenSyncModal(false);
      } else if (resJson?.status.statusCode === "datareckitt-401") {
        setOpenWorkspaceDrawer(true);
        setSyncLoading("error");
        handleDiscardChanges();
      } else {
        toast.error("Opps! something went wrong.");
        setSyncLoading("error");
      }
    } catch (error) {
      toast.error("Opps! something went wrong.");
      console.log("error", error);
      setSyncLoading("error");
    }
  };

  // Opens popup to edit/add a new report
  const handleEditSubCat = (report, parentData) => {
    setSelectedWidgetType(report.sub_category_type);
    setSelectedSubcat(report);
    setopenAddReportPopUp(true);
    setParentReportType(parentData?.sub_category_type || "");
  };
  const handleDiscardChanges = () => {
    // Update the template Data state with OLD array (for update) / empty Array for (New)
    setSelectedWorkspace(prevWorkspace);
    dispatch(
      updateReportTemplate({
        templateData: categoryInfo.length
          ? categoryInfo
          : getDefaultTempData(template_no),
      })
    );
    handleClose();
  };

  const findSubCat = (id, data) => {
    let val;
    data.some((rep) => {
      if (rep.sub_category_id === id) {
        val = rep.sub_category;
        setRenderSubReportArr(val);
        return true;
      } else {
        if (rep.sub_category && rep.sub_category.length > 0) {
          findSubCat(id, rep.sub_category);
        }
        return false;
      }
    });
  };

  const handleNavigateInsideReport = (report) => {
    // update the breadcrumb - If 'Contains_subcat' key is true
    // if (report.contains_sub_category) {
    //   const newBreadcrumbArr = [
    //     ...breadcrumbArr,
    //     {
    //       textName: report.sub_category_name,
    //       textID: report.sub_category_id,
    //     },
    //   ];
    //   setBreadCrumbArr(newBreadcrumbArr);
    //   findSubCat(report.sub_category_id, appTemplateState);
    // }
    if (waiting) return;
    if (report.sub_category_type === "report") {
      window.open(report.url, "_blank");
    } else if (
      report.sub_category_type === "hybrid" ||
      report.sub_category_type === "customHybrid"
    ) {
      let newBreadcrumbArr = [];
      // converting text editor string to normal text
      if (report.sub_category_name?.startsWith('{"blocks"')) {
        newBreadcrumbArr = [
          ...breadcrumbArr,
          {
            textName: JSON.parse(report.sub_category_name).blocks[0]?.text,
            textID: report.sub_category_id,
          },
        ];
      } else {
        newBreadcrumbArr = [
          ...breadcrumbArr,
          {
            textName: report.sub_category_name,
            textID: report.sub_category_id,
          },
        ];
      }
      setBreadCrumbArr(newBreadcrumbArr);
      findSubCat(report.sub_category_id, appTemplateState);
    }
  };

  const handleClickBreadCrumb = (item, ind) => {
    const BCIndex = breadcrumbArr.findIndex((bc) => bc.textID === item.textID);
    const newBArr = breadcrumbArr.slice(0, BCIndex + 1);
    setBreadCrumbArr(newBArr);
    ind === 0
      ? setRenderSubReportArr(appTemplateState)
      : findSubCat(item.textID, appTemplateState);
  };

  const deleteSubcatInsideParentRep = (id, data, parent_id) => {
    data.forEach((rep) => {
      if (rep.sub_category_id === parent_id) {
        const ind = rep.sub_category.findIndex(
          (item) => item.sub_category_id === id
        );
        const leftItems = rep.sub_category.slice(0, ind);
        const rightItems = rep.sub_category.slice(ind + 1);
        const updatedData = [...leftItems, ...rightItems];
        rep.sub_category = updatedData;
        setRenderSubReportArr(data);
      } else {
        if (rep.sub_category && rep.sub_category.length > 0) {
          deleteSubcatInsideParentRep(id, rep.sub_category, parent_id);
        }
      }
    });
  };

  const handleDeleteNestedData = (subCat, data) => {
    data.forEach((rep) => {
      const ind = rep.sub_category.findIndex(
        (item) => item.sub_category_id === subCat.sub_category_id
      );
      if (ind >= 0) {
        // update incase ID exists
        const leftItems = rep.sub_category.slice(0, ind);
        const rightItems = rep.sub_category.slice(ind + 1);
        rep.sub_category = [...leftItems, ...rightItems];
        setRenderSubReportArr(rep.sub_category);
        return;
      } else {
        if (rep.sub_category && rep.sub_category.length > 0) {
          handleDeleteNestedData(subCat, rep.sub_category);
        }
      }
    });
  };

  const checkBlockingDelete = (subCat) => {
    if (
      breadcrumbArr.length === 1 &&
      (template_no === "Template_4" || template_no === "Template_3")
    ) {
      let val;
      if (template_no === "Template_4") {
        const totalHeaders = renderSubReportArr.filter(
          (el) => el.sub_category_type === "header"
        );
        const totalPRs = renderSubReportArr.filter(
          (el) => el.sub_category_type === "parentReport"
        );
        if (subCat.sub_category_type === "header") {
          val = totalHeaders.length > 1 ? false : true;
        } else if (subCat.sub_category_type === "parentReport") {
          val = totalPRs.length > 1 ? false : true;
        }
      } else if (template_no === "Template_3") {
        const totalSubheaders = renderSubReportArr.filter(
          (el) => el.sub_category_type === "sub_header"
        );
        if (subCat.sub_category_type === "sub_header") {
          val = totalSubheaders.length > 1 ? false : true;
        }
      }
      return val;
    } else {
      return false;
    }
  };

  // Delete a subcategory
  const handleDeleteSubCat = (subCat, data_1, parentData) => {
    const BlockDelete = checkBlockingDelete(subCat);
    if (BlockDelete) {
      return;
    }
    let data =
      data_1 && data_1?.length > 0
        ? JSON.parse(JSON.stringify(data_1))
        : JSON.parse(JSON.stringify(appTemplateState));
    let newData;
    if (parentData?.sub_category_id) {
      deleteSubcatInsideParentRep(
        subCat.sub_category_id,
        data,
        parentData?.sub_category_id
      );
      // Update the state here !!
      dispatch(
        updateReportTemplate({
          templateData: data,
        })
      );
      // setRenderSubReportArr(data);
    } else if (breadcrumbArr.length === 1) {
      // console.log(data);
      const ind = data.findIndex(
        (item) => item.sub_category_id === subCat.sub_category_id
      );
      if (ind >= 0) {
        const leftItems = data.slice(0, ind);
        const rightItems = data.slice(ind + 1);
        newData = [...leftItems, ...rightItems];
        // Update the state here !!
        dispatch(
          updateReportTemplate({
            templateData: newData,
          })
        );
        setRenderSubReportArr(newData);
      }
    } else {
      handleDeleteNestedData(subCat, data);
      dispatch(
        updateReportTemplate({
          templateData: data,
        })
      );
    }
  };

  const handleChooseLabel = () => {
    const contains_ParentReport = renderSubReportArr.find(
      (rep) => rep.sub_category_type === "parentReport"
    );
    if (contains_ParentReport) {
      return "Add a Header";
    } else {
      return "Add New Widget";
    }
  };

  // Opens popup to create new
  const handleAddReportPopUp = () => {
    if (isFieldsEditable) return;
    setSelectedSubcat({});
    if (handleChooseLabel() === "Add a Header") {
      setSelectedWidgetType("header");
    }
    setopenAddReportPopUp(true);
  };

  //   useEffect(() => {
  //     const contains_CustomHybrid = renderSubReportArr.find(
  //       (rep) => rep.sub_category_type === "customHybrid"
  //     );
  //     if (contains_CustomHybrid) {
  //       setSelectedWidgetType("customHybrid");
  //     }
  //   }, [renderSubReportArr]);

  const setReqStates = () => {
    setCategoryInfo(appTemplateState);
    setCategoryInfoLocal(appTemplateState);
    handleClose();
    setWaiting(false);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const req = await fetchAPI(formData);
      const response = await req.json();
      if (response.status.statusCode === "datareckitt-200") {
        dispatch(
          updateReportTemplate({
            temp_img_data: response.data,
          })
        );
      } else {
        toast.error("Oops! something went wrong");
      }
      setReqStates();
    } catch (error) {
      console.log("error from image upload", error);
      toast.error(error || "Oops! something went wrong");
      setReqStates();
    }
  };

  const urlToObject = async (image) => {
    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });
    return file;
  };

  const getScreenShot = async () => {
    setWaiting(true);
    setPrevWorkspace(selectedWorkspace);
    setBreadCrumbArr([
      { textName: appFields.app_name, textID: appFields.app_id },
    ]);
    setRenderSubReportArr(appTemplateState);
    await timeout(800);
    const node = document.getElementById("screenshot");
    htmlToImage
      .toPng(node)
      .then(function (img) {
        return urlToObject(img);
      })
      .then((file) => {
        uploadFile(file);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
        setReqStates();
      });
  };

  const handleSaveChanges = async () => {
    await getScreenShot();
    // Update the Category Info state with new template Data State
    // setCategoryInfo(appTemplateState);
    // setCategoryInfoLocal(appTemplateState);
    // handleClose();
  };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableEnforceFocus
                sx={{
                    "& .MuiDialog-paper": {
                        backgroundImage: "none",
                        position: "relative",
                    },
                }}
                className={classes.dialogContainer}
            >
                <ConfirmationModal
                    open={openSyncModal}
                    msg={msg}
                    // msg={
                    //     "You have unsaved changes! Are you sure you want to discard them and sync reports ?"
                    // }
                    handleClose={handleCancelSync}
                    handleDone={handleSyncReports}
                    loadStatus={syncloading === "loading"}
                />
                <div className={classes.container}>
                    {openAddReportPopUp && (
                        <AddSubcategoryPopUp
                            open={openAddReportPopUp}
                            registerTemplateFields={registerTemplateFields}
                            setopenAddReportPopUp={setopenAddReportPopUp}
                            appTemplateState={appTemplateState}
                            subcategoryState={selectedSubcat}
                            breadcrumbArr={breadcrumbArr}
                            allSubcats={renderSubReportArr}
                            setRenderSubReportArr={setRenderSubReportArr}
                            widget_type={selectedWidgetType}
                            setSelectedWidgetType={setSelectedWidgetType}
                            parentID={parentID}
                            setParentID={setParentID}
                            renderSubReportArr={renderSubReportArr}
                            parent_report_type={parent_report_type}
                            setParentReportType={setParentReportType}
                            appFields={appFields}
                        />
                    )}
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        my={2}
                        px="10px"
                    >
                        <BreadCrumb
                            breadcrumbArr={breadcrumbArr}
                            handleClickBreadCrumb={handleClickBreadCrumb}
                        />
                        <div style={{ display: "flex" }}>
                            {(appDetails.data?.approval_meta
                                ?.approval_status === "approved" ||
                                appDetails.data?.approval_meta
                                    ?.approval_status === "published") &&
                                breadcrumbArr.length === 1 &&
                                appDetails?.data?.app_details?.workspace_id !==
                                    undefined &&
                                appDetails?.data?.app_details?.workspace_id !==
                                    "" &&
                                !isFieldsEditable && (
                                    <button
                                        className="btn GA-Sync-reports"
                                        style={{
                                            marginRight: "10px",
                                            background: "#282828",
                                        }}
                                        onClick={handleClickSyncReports}
                                    >
                                        Sync Reports
                                    </button>
                                )}
                            {registerTemplateFields &&
                                renderSubReportArr.length > 0 &&
                                !isFieldsEditable && (
                                    <button
                                        className="btn"
                                        onClick={handleAddReportPopUp}
                                    >
                                        {handleChooseLabel()}
                                    </button>
                                )}
                        </div>
                    </Box>
                    <div id="screenshot">
                        {renderSubReportArr.length > 0 && (
                            <DashboardView
                                renderSubReportArr={renderSubReportArr}
                                handleNavigateInsideReport={
                                    handleNavigateInsideReport
                                }
                                handleEditSubCat={handleEditSubCat}
                                breadcrumbArr={breadcrumbArr}
                                handleDeleteSubCat={handleDeleteSubCat}
                                setRenderSubReportArr={setRenderSubReportArr}
                                setSelectedSubcat={setSelectedSubcat}
                                setopenAddReportPopUp={setopenAddReportPopUp}
                                setSelectedWidgetType={setSelectedWidgetType}
                                setParentID={setParentID}
                                isFieldsEditable={isFieldsEditable}
                                setParentReportType={setParentReportType}
                            />
                        )}
                    </div>
                    {renderSubReportArr.length === 0 && (
                        <div className={classes.addReportContainer}>
                            <img
                                alt=""
                                src={addImg}
                                className={classes.emptyScreenImg}
                            />
                            <p className={classes.addReportText}>
                                Start adding reports here.
                            </p>
                            <button
                                className="btn"
                                onClick={handleAddReportPopUp}
                            >
                                Add New Widget
                            </button>
                        </div>
                    )}
                    <div className={classes.footer}>
                        {isFieldsEditable ? (
                            <button
                                onClick={handleClose}
                                className="btn-next btn"
                            >
                                Done
                            </button>
                        ) : (
                            <>
                                <button
                                    onClick={handleDiscardChanges}
                                    disabled={waiting}
                                    className={`${classes.discardBtn} btn-next btn`}
                                >
                                    Discard Changes
                                </button>
                                <button
                                    onClick={handleSaveChanges}
                                    disabled={
                                        equal(categoryInfo, appTemplateState) ||
                                        appTemplateState.length === 0 ||
                                        waiting
                                    }
                                    className="btn-next btn"
                                >
                                    {waiting ? (
                                        <Loader color="#ffffff" />
                                    ) : (
                                        "Save Template"
                                    )}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
};
const mapStateToProps = (state) => ({
  user: state.user,
  allApps: state.AppsData.allApps,
  theme: state.user.ui_preferences?.theme,
  appTemplateState: state.appTemplate.appTemplateState.templateData,
  template_no: state.appTemplate.appTemplateState.template_no,
  registerTemplateFields:
    state.register.registerTemplate.data.register_app_template.fields[0]
      .options[0].subform_templates[2],
  appDetails: state.AppsData.appDetails,
});

export default connect(mapStateToProps)(DashboardTemplate);
