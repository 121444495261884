import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import Loader from "../../../components/Loader/Loader.js";
const KpiCardComponent = (props) => {
  const { kpiValue, theme, loading } = props;

  const getYesterdayYear = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.getFullYear();
  };

  const getYesterdayMonth = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayMonthIndex = yesterday.getMonth();

    return months[yesterdayMonthIndex];
  };

  return (
    <>
      <Grid container spacing={1} sx={{ width: "80%" }}>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            display="flex"
            border={1}
            borderColor="#B3BDC4"
            padding="8px"
            bgcolor={theme === "light" ? "#fff" : "#282828"}
            sx={{
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box flex={1}>
              <Typography
                fontFamily="Energy"
                fontWeight="400"
                lineHeight="20px"
                fontSize="14px"
                color={theme === "light" ? "#000" : "#fff"}
                align="center"
                letterSpacing="0.6px"
              >
                Total Visitors
              </Typography>
              <Typography
                fontFamily="Energy"
                fontWeight="400"
                lineHeight="16px"
                fontSize="12px"
                color={theme === "light" ? "#4e4e4e" : "#b1b1b1"}
                align="center"
                letterSpacing="0.6px"
              >
                since app creation
              </Typography>
            </Box>
            <Box>
              <Typography
                fontFamily="Energy"
                fontWeight="700"
                lineHeight="18px"
                fontSize="14px"
                color={theme === "light" ? "#000" : "#fff"}
                align="center"
                letterSpacing="0.6px"
              >
                {loading ? (
                  <Loader
                    color={theme === "light" ? "#fc007f" : "#fff"}
                    margin="4px 8px 1px"
                    loader_size="0.8rem"
                  />
                ) : Object.keys(kpiValue).length === 0 ? (
                  "No data"
                ) : (
                  kpiValue.total_visitors
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            display="flex"
            border={1}
            borderColor="#B3BDC4"
            padding="8px"
            bgcolor={theme === "light" ? "#FFFFFF" : "#282828"}
            sx={{
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box flex={1}>
              <Typography
                fontFamily="Energy"
                fontWeight="400"
                lineHeight="20px"
                fontSize="14px"
                color={theme === "light" ? "#000" : "#fff"}
                align="center"
                letterSpacing="0.6px"
              >
                Average Unique Users
              </Typography>
              <Typography
                fontFamily="Energy"
                fontWeight="400"
                lineHeight="16px"
                fontSize="12px"
                color={theme === "light" ? "#4e4e4e" : "#b1b1b1"}
                align="center"
                letterSpacing="0.6px"
              >
                per day for Year: {getYesterdayYear()}
              </Typography>
            </Box>
            <Box>
              <Typography
                fontFamily="Energy"
                fontWeight="700"
                lineHeight="18px"
                fontSize="14px"
                color={theme === "light" ? "#000" : "#fff"}
                align="center"
                letterSpacing="0.6px"
              >
                {loading ? (
                  <Loader
                    color={theme === "light" ? "#fc007f" : "#fff"}
                    margin="4px 8px 1px"
                    loader_size="0.8rem"
                  />
                ) : Object.keys(kpiValue).length === 0 ? (
                  "No data"
                ) : (
                  kpiValue.unique_user_current_year
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            display="flex"
            border={1}
            borderColor="#B3BDC4"
            padding="8px"
            bgcolor={theme === "light" ? "#FFFFFF" : "#282828"}
            sx={{
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box flex={1}>
              <Typography
                fontFamily="Energy"
                fontWeight="400"
                lineHeight="20px"
                fontSize="14px"
                color={theme === "light" ? "#000" : "#fff"}
                align="center"
                letterSpacing="0.6px"
              >
                Average Unique Users
              </Typography>
              <Typography
                fontFamily="Energy"
                fontWeight="400"
                lineHeight="16px"
                fontSize="12px"
                color={theme === "light" ? "#4e4e4e" : "#b1b1b1"}
                align="center"
                letterSpacing="0.6px"
              >
                per day for Month: {getYesterdayMonth()}
              </Typography>
            </Box>
            <Box>
              <Typography
                fontFamily="Energy"
                fontWeight="700"
                lineHeight="18px"
                fontSize="14px"
                color={theme === "light" ? "#000" : "#fff"}
                align="center"
                letterSpacing="0.6px"
              >
                {loading ? (
                  <Loader
                    color={theme === "light" ? "#fc007f" : "#fff"}
                    margin="4px 8px 1px"
                    loader_size="0.8rem"
                  />
                ) : Object.keys(kpiValue).length === 0 ? (
                  "No data"
                ) : (
                  kpiValue.unique_user_current_month
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default KpiCardComponent;
