import { Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getAllApps, getRegisterAppTemplate } from "../../redux/actions";
import { ChangeViewByStructure } from "../../utils/utils";

// Import Custom Components
import NewAppCard from "../../components/AppCard/NewAppCard";
import SkeletonLoadScreen from "../../components/ImageLoader/SkeletonLoadScreen";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";

// Import styles
import "./Explore.scss";
import { Typography } from "antd";
import { ChevronLeft } from "@material-ui/icons";
import NewAppTable from "../../components/AppTable/NewAppTable";

function CustomTabPanel(props) {
  const { value, index, renderAppData, tabValue, other, user } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ marginTop: "15px" }}
    >
      <div className="all-apps-container-above-grid">
        {value === index &&
          renderAppData[tabValue][1].length &&
          user.ui_preferences.grid_size !== "list" && (
            <div className="applications-row-grid-allapps">
              {renderAppData[tabValue][1].map((app, index) => (
                <NewAppCard row={app} key={app.app_id} appIndex={index} />
              ))}
            </div>
          )}
        {user.ui_preferences.grid_size === "list" && (
          <NewAppTable tableData={renderAppData[tabValue][1]} />
        )}
      </div>
    </div>
  );
}

const AllApps = (props) => {
  const { registerTemplate, user, AppsData } = props;
  const { type, value } = useParams();
  const [tabValue, setTabValue] = useState(0);
  // This state will show apps on UI
  const [renderAppData, setRenderAppData] = useState([]);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const tabListStyle = {
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      height: "2.5px",
      marginBottom: "5px",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#FC007F",
    },
    "& .MuiTabs-scrollButtons": {
      color:
        user.ui_preferences.theme === "dark"
          ? "#ffffff !important"
          : "#000000 !important",
    },
  };
  const tabStyle = {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "23px",
    textTransform: "capitalize",
    minHeight: "35px",
    color: user.ui_preferences.theme === "dark" ? "#ffffff" : "#000000",
    opacity: 0.6,
    "&.Mui-selected": {
      color: "#FC007F",
      fontWeight: 400,
      opacity: 1,
      minHeight: "35px",
      borderBottom: "2px solid",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  };

  const changeURL = (newUrl) => {
    history.push(newUrl);
  };

  const getChangedOption = (opt_name) => {
    if (type === "app_type") {
      if (opt_name === "bi_app") {
        return "embedded analytics";
      } else return opt_name.replace("_", " ");
    } else return opt_name;
  };

  const createOptionsAndData = () => {
    const fieldValue = registerTemplate.data.search_template.fields.filter(
      (field) => field.key === type
    );
    const data1 = AppsData.allApps.data;
    const newData = ChangeViewByStructure(
      data1,
      type,
      fieldValue[0].options,
      fieldValue[0].value_type
    );
    let filteredOptions = [];
    newData.forEach((el) => {
      if (el[1].length > 0) {
        filteredOptions.push(getChangedOption(el[0]));
      }
    });
    let filteredData = [];
    newData.forEach((el) => {
      if (el[1].length > 0) {
        filteredData.push(el);
      }
    });
    return { filteredOptions: filteredOptions, filteredData: filteredData };
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    const createdData = createOptionsAndData();
    const newValueName = createdData.filteredOptions[newValue].replace(
      " ",
      "%20"
    );
    const newUrl = "/explore/" + type + "/" + newValueName;
    changeURL(newUrl);
  };

  const handleClickBackButton = () => {
    history.push("/myapps");
  };

  useEffect(() => {
    if (AppsData.allApps.status === "pending") {
      dispatch(getAllApps());
    }
    if (registerTemplate.status === "pending") {
      dispatch(getRegisterAppTemplate());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      registerTemplate.status === "success" &&
      AppsData.allApps.status === "success"
    ) {
      const createdData = createOptionsAndData();
      setOptions(createdData.filteredOptions);
      const correctedValue =
        type === "app_type" ? value : value.replace("%20", " ");
      let ind = createdData.filteredOptions.findIndex(
        (el) => el === correctedValue
      );
      if (ind === -1) {
        ind = 0;
      }
      setTabValue(ind);
      setRenderAppData(createdData.filteredData);
    }
  }, [registerTemplate, AppsData.allApps, type, value]);

  return (
    <LayoutTopSideBottom>
      <div style={{ padding: "10px" }}>
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            cursor: "pointer",
            width: "150px",
          }}
          onClick={handleClickBackButton}
        >
          <ChevronLeft style={{ color: "#FC007F" }} />
          <Typography
            style={{
              color: "#FC007F",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Back
          </Typography>
        </div>

        {registerTemplate.status === "success" &&
          AppsData.allApps.status === "success" &&
          renderAppData.length > 0 && (
            <>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                sx={tabListStyle}
              >
                {options.map((optItem, ind) => {
                  return <Tab sx={tabStyle} label={optItem} />;
                })}
              </Tabs>
              <CustomTabPanel
                style={{
                  height: "calc(100vh - 210px)",
                  overflowY: "auto",
                }}
                value={tabValue}
                index={tabValue}
                renderAppData={renderAppData}
                tabValue={tabValue}
                user={user}
              >
                {/* {renderAppData.length && (
                <div className="applications-row-grid-mng">
                  {renderAppData[tabValue][1].map((app, index) => (
                    <NewAppCard row={app} key={app.app_id} appIndex={index} />
                  ))}
                </div>
              )} */}
              </CustomTabPanel>
            </>
          )}
        {(registerTemplate.status === "loading" ||
          AppsData.allApps.status === "loading") && (
          <SkeletonLoadScreen showSingleRow={true} showNavTab={true} />
        )}
      </div>
    </LayoutTopSideBottom>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  registerTemplate: state.register.registerTemplate,
  AppsData: state.AppsData,
});

export default connect(mapStateToProps)(AllApps);
