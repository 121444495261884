import iconList from "../../../assets/images/job-icon.svg";
import budgetIcon from "../../../assets/images/setup-images/budget.svg";
import businessmanIcon from "../../../assets/images/setup-images/businessman.svg";
import conveyorIcon from "../../../assets/images/setup-images/conveyor.svg";
import dataScientistIcon from "../../../assets/images/setup-images/data-scientist.svg";
import demandIcon from "../../../assets/images/setup-images/demand.svg";
import documentsIcon from "../../../assets/images/setup-images/documents.svg";
import hrIcon from "../../../assets/images/setup-images/hr.svg";
import profitsIcon from "../../../assets/images/setup-images/profits.svg";
import salesIcon from "../../../assets/images/setup-images/sales.svg";
import scientistIcon from "../../../assets/images/setup-images/scientist.svg";
import directorIcon from "../../../assets/images/setup-images/director.png";
import productOwnerIcon from "../../../assets/images/setup-images/product-owner.png";
import Software_architectIcon from "../../../assets/images/setup-images/Software_architect.png";
import software_engineerIcon from "../../../assets/images/setup-images/software_engineer.png";
import webDevelopmentIcon from "../../../assets/images/setup-images/web-development.svg";
import medicalHistoryIcon from "../../../assets/images/setup-images/medical-history.svg";
import otherFunctionLight from "../../../assets/images/setup-images/function_light.svg";
import otherFunction from "../../../assets/images/setup-images/others-job.svg";
import otherJob from "../../../assets/images/setup-images/others-func.svg";
import Administration from "../../../assets/images/setup-images/functionsIcons/blogger.png";
import eRb from "../../../assets/images/setup-images/functionsIcons/add-to-cart.png";
import Business_analytics from "../../../assets/images/setup-images/functionsIcons/analytics.png";
import ClinicalDevelopment from "../../../assets/images/setup-images/functionsIcons/pharmacy.png";
import Facilities from "../../../assets/images/setup-images/functionsIcons/factory.png";
import CorporatecommunicationsExternalAffairs from "../../../assets/images/setup-images/functionsIcons/handshake.png";
import Legal from "../../../assets/images/setup-images/functionsIcons/compliant.png";
import Manufacturing from "../../../assets/images/setup-images/functionsIcons/conveyor.png";
import Marketing from "../../../assets/images/setup-images/functionsIcons/social-media.png";
import Marketingexcellence from "../../../assets/images/setup-images/functionsIcons/capital.png";
import finance from "../../../assets/images/setup-images/functionsIcons/profits.png";
import GeneralManagement from "../../../assets/images/setup-images/functionsIcons/team-management.png";
import GlobalCategory from "../../../assets/images/setup-images/functionsIcons/worldwide.png";
import humanResources from "../../../assets/images/setup-images/functionsIcons/hr.png";
import medical from "../../../assets/images/setup-images/functionsIcons/medical-care.png";
import medicalaffairs from "../../../assets/images/setup-images/functionsIcons/medical-team.png";
import medicalchannel from "../../../assets/images/setup-images/functionsIcons/healthcare.png";
import procurement from "../../../assets/images/setup-images/functionsIcons/procurement.png";
import quality from "../../../assets/images/setup-images/functionsIcons/quality.png";
import Regulatory from "../../../assets/images/setup-images/functionsIcons/checklist.png";
import researchanddevelopment from "../../../assets/images/setup-images/functionsIcons/idea.png";
import safetyqualityregulatoryandcompliance from "../../../assets/images/setup-images/functionsIcons/shield.png";
import sales from "../../../assets/images/setup-images/functionsIcons/budget.png";
import Salesoutperformance from "../../../assets/images/setup-images/functionsIcons/market-analysis.png";
import supply from "../../../assets/images/setup-images/functionsIcons/supply-chain.png";
import transformation from "../../../assets/images/setup-images/functionsIcons/process.png";
import informationTechnology from "../../../assets/images/setup-images/functionsIcons/coder.png";
export const function_icons = (theme) => {
    return {
        Administration: Administration,
        "Business Analytics": Business_analytics,
        "Clinical Development": ClinicalDevelopment,
        "Corporate Communications & External Affairs":
            CorporatecommunicationsExternalAffairs,
        eRB: eRb,
        Facilities: Facilities,
        Finance: finance,
        "General Management": GeneralManagement,
        "Global Category": GlobalCategory,
        "Human Resources": humanResources,
        "Information Technology": informationTechnology,
        Legal: Legal,
        Manufacturing: Manufacturing,
        Marketing: Marketing,
        "Marketing Excellence": Marketingexcellence,
        Medical: medical,
        "Medical Affairs": medicalaffairs,
        "Medical Channel": medicalchannel,
        Procurement: procurement,
        Quality: quality,
        Regulatory: Regulatory,
        "Research & Development": researchanddevelopment,
        "Safety, Quality, Regulatory & Compliance":
            safetyqualityregulatoryandcompliance,
        Sales: sales,
        "Sales Outperformance": Salesoutperformance,
        Supply: supply,
        Transformation: transformation,
        Others: theme === "dark" ? otherFunctionLight : otherFunction,
    };
};
export const functionsList = [
    {
        name: "Marketing",
        icon: iconList,
    },
    {
        name: "Sales",
        icon: budgetIcon,
    },
    {
        name: "HR",
        icon: hrIcon,
    },
    {
        name: "Finance",
        icon: profitsIcon,
    },
    {
        name: "Manufacturing",
        icon: conveyorIcon,
    },
    {
        name: "Supply",
        icon: demandIcon,
    },
    {
        name: "Demand",
        icon: salesIcon,
    },
    {
        name: "Medical",
        icon: medicalHistoryIcon,
    },
    {
        name: "Research & Dev",
        icon: documentsIcon,
    },
    {
        name: "Others",
        icon: otherFunction,
    },
];
export const functions_Options_List = [
    "Marketing",
    "Sales",
    "HR",
    "Finance",
    "Manufacturing",
    "Supply",
    "Demand",
    "Medical",
    "Research & Dev",
];
export const JobRoleList = [
    {
        name: "Data Scientist",
        icon: scientistIcon,
    },
    {
        name: "Data Engineer",
        icon: webDevelopmentIcon,
    },
    {
        name: "Data Analyst",
        icon: dataScientistIcon,
    },
    {
        name: "Business User",
        icon: businessmanIcon,
    },
    {
        name: "Others",
        icon: otherJob,
    },
];

export const jobRole_icons = {
    data_scientist: scientistIcon,
    data_engineer: webDevelopmentIcon,
    business_analyst: dataScientistIcon,
    business_user: businessmanIcon,
    software_engineer: software_engineerIcon,
    director: directorIcon,
    software_architect: Software_architectIcon,
    others: otherJob,
    product_owner: productOwnerIcon,
};
// export const function_icons = (theme) => {
//     return {
//         Marketing: iconList,
//         Sales: budgetIcon,
//         HR: hrIcon,
//         Finance: profitsIcon,
//         Manufacturing: conveyorIcon,
//         Supply: demandIcon,
//         Demand: salesIcon,
//         Medical: medicalHistoryIcon,
//         "Research & Dev": documentsIcon,
//         Others: theme === "dark" ? otherFunctionLight : otherFunction,
//     };
// };

export const job_role_list = [
    "Data Scientist",
    "Data Engineer",
    "Data Analyst",
    "Business User",
];

export const affricanCountries = [
    "dz",
    "ao",
    "bj",
    "bw",
    "bf",
    "bi",
    "cm",
    "cv",
    "cf",
    "td",
    "km",
    "cd",
    "dj",
    "eg",
    "gq",
    "er",
    "et",
    "ga",
    "gm",
    "gh",
    "gn",
    "gw",
    "ci",
    "ke",
    "ls",
    "lr",
    "ly",
    "mg",
    "mw",
    "ml",
    "mr",
    "mu",
    "yt",
    "ma",
    "mz",
    "na",
    "ne",
    "ng",
    "cg",
    "re",
    "rw",
    "sh",
    "st",
    "sn",
    "sc",
    "sl",
    "so",
    "za",
    "ss",
    "sd",
    "sz",
    "tz",
    "tg",
    "tn",
    "ug",
    "eh",
    "zm",
    "zw",
];
export const affricanCountriesName = [
    "Algeria",
    "Angola",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cameroon",
    "Cabo Verde",
    "Central African Republic",
    "Chad",
    "Comoros",
    "Congo",
    "Djibouti",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Ethiopia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Guinea",
    "Guinea-Bissau",
    "Côte d'Ivoire",
    "Kenya",
    "Lesotho",
    "Liberia",
    "Libya",
    "Madagascar",
    "Malawi",
    "Mali",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Niger",
    "Nigeria",
    "Congo",
    "Réunion",
    "Rwanda",
    "Saint Helena",
    "Sao Tome and Principe",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Sudan",
    "Swaziland",
    "Tanzania",
    "Togo",
    "Tunisia",
    "Uganda",
    "Western Sahara",
    "Zambia",
    "Zimbabwe",
];
export const northAmericanCountriesName = [
    "Anguilla",
    "Antigua and Barbuda	",
    "Aruba",
    "Barbados",
    "Belize",
    "Bermuda",
    "Virgin Islands, British",
    "Canada",
    "Cayman Islands",
    "Curaçao",
    "Dominica",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Jamaica",
    "Martinique",
    "Montserrat",
    "Puerto Rico",
    "Saint Barthélemy",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Collectivity of Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Sint Maarten",
    "Bahamas",
    "Trinidad and Tobago	",
    "Turks and Caicos Islands",
    "United States of America",
    "Virgin Islands, U.S.",
];
export const europeCountriesListName = [
    "Austria",
    "Belgium	",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Albania",
    "Andorra",
    "Armenia",
    "Belarus",
    "Bosnia and Herzegovina",
    "Faroe Islands",
    "Georgia",
    "Gibralta",
    "Iceland",
    "Isle of Man",
    "Liechtenstein",
    "Macedonia",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Norway",
    "Russian Federation",
    "San Marino",
    "Serbia",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "United Kingdom of Great Britain and Northern Island",
    "Holy See",
];

export const LATAMCountriesName = [
    "Antigua and Barbuda",
    "Anguilla",
    "Argentina",
    "Aruba",
    "Barbados",
    "Bermuda",
    "Bolivia",
    "Brazil",
    "Bahamas",
    "Belize",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Falkland Islands",
    "Grenada",
    "South Georgia and the South Sandwich Islands",
    "Guatemala",
    "Guyana",
    "Honduras",
    "Haiti",
    "Jamaica",
    "Saint Kitts and Nevis",
    "Cayman Islands",
    "Saint Lucia",
    "Montserrat",
    "Mexico",
    "Nicaragua",
    "Panama",
    "Peru",
    "Puerto Rico",
    "Paraguay",
    "Suriname",
    "El Salvadorsv",
    "Turks and Caicos Islands",
    "Trinidad and Tobago",
    "Uruguay",
    "Saint Vincent and the Grenadines",
    "Venezuela",
    "Virgin Islands,, British",
    "Virgin Islands, U.S.",
];

export const middleEastListName = [
    "Bahrain",
    "Cyprus",
    "Egypt",
    "Iran",
    "Iraq",
    "Israel",
    "Jordan",
    "Kuwait",
    "Lebanon",
    "Oman",
    "Palestine",
    "Qatar",
    "Saudi Arabia",
    "Turkey",
    "United Arab Emirates",
    "Yemen",
    "Syrian Arab Republic",
];
export const APACCountriesName = [
    "American Samoa",
    "Australia",
    "Bangladesh",
    "Brunei Darussalam",
    "Bhutan",
    "Cocos (Keeling) Islands",
    "China",
    "Christmas Island",
    "Fiji",
    "Micronesia",
    "Guam",
    "Hong Kong",
    "Indonesia",
    "India",
    "British Indian Ocean Territory",
    "Japan",
    "Cambodia",
    "Kiribati",
    "Korea, Democratic People's Republic",
    "Korea, Republic",
    "Lao",
    "Sri Lanka",
    "Marshall Islands",
    "Myanmar",
    "Mongolia",
    "Macao",
    "Northern Mariana Islands",
    "Maldives",
    "Malaysia",
    "Norfolk Island",
    "Nepal",
    "Nauru",
    "Niue",
    "New Zealand",
    "Papua New Guinea",
    "Philippines",
    "Pakistan",
    "Pitcairn",
    "Palau",
    "Solomon Islands",
    "Singapore",
    "Thailand",
    "Tokelau",
    "Timor-Leste",
    "Tonga",
    "Tuvalu",
    "Taiwan",
    "United States Minor Outlying Islands",
    "Vietnam",
    "Vanuatu",
    "Samoa",
    "Kazakhstan",
    "Uzbekistan",
    "Turkmenistan",
    "Kyrgyzstan",
    "Afghanistan",
    "Tajikistan",
];
export const northAmericanCountries = [
    "ai",
    "ag",
    "aw",
    "bb",
    "bz",
    "bm",
    "bq",
    "vg",
    "ca",
    "ky",
    // "cr",
    // "cu",
    "cw",
    "dm",
    // "do",
    // "sv",
    "gl",
    "gd",
    "gp",
    // "gt",
    // "ht",
    // "hn",
    "jm",
    "mq",
    // "mx",
    "ms",
    "an",
    // "ni",
    // "pa",
    "pr",
    "bl",
    "kn",
    "lc",
    "mf",
    "pm",
    "vc",
    "sx",
    "bs",
    "tt",
    "tc",
    "us",
    "vi",
];

export const europeCountriesList = [
    "at",
    "be",
    "bg",
    "hr",
    "cy",
    "cz",
    "dk",
    "ee",
    "fi",
    "fr",
    "de",
    "gr",
    "hu",
    "ie",
    "it",
    "lv",
    "lt",
    "lu",
    "mt",
    "nl",
    "pl",
    "pt",
    "ro",
    "sk",
    "si",
    "es",
    "se",
    "al",
    "ad",
    "am",
    "by",
    "ba",
    "fo",
    "ge",
    "gi",
    "is",
    "im",
    "xk",
    "li",
    "mk",
    "md",
    "mc",
    "me",
    "no",
    "ru",
    "sm",
    "rs",
    "ch",
    "tr",
    "ua",
    "gb",
    "va",
];

export const LATAMCountries = [
    "ag",
    "ai",
    "ar",
    "aw",
    "bb",
    "bm",
    "bo",
    "br",
    "bs",
    "bz",
    "cl",
    "co",
    "cr",
    "cu",
    "dm",
    "do",
    "ec",
    "fk",
    "gd",
    "gs",
    "gt",
    "gy",
    "hn",
    "ht",
    "jm",
    "kn",
    "ky",
    "lc",
    "ms",
    "mx",
    "ni",
    "pa",
    "pe",
    "pr",
    "py",
    "sr",
    "sv",
    "tc",
    "tt",
    "uy",
    "vc",
    "ve",
    "vg",
    "vi",
];

export const APACCountries = [
    "as",
    "au",
    "bd",
    "bn",
    "bt",
    "cc",
    "cn",
    "cx",
    "fj",
    "fm",
    "gu",
    "hk",
    "id",
    "in",
    "io",
    "jp",
    "kh",
    "ki",
    "kp",
    "kr",
    "la",
    "lk",
    "mh",
    "mm",
    "mn",
    "mo",
    "mp",
    "mv",
    "my",
    "nf",
    "np",
    "nr",
    "nu",
    "nz",
    "pg",
    "ph",
    "pk",
    "pn",
    "pw",
    "sb",
    "sg",
    "th",
    "tk",
    "tl",
    "to",
    "tv",
    "tw",
    "um",
    "vn",
    "vu",
    "ws",
    "kz",
    "uz",
    "tm",
    "kg",
    "af",
    "tj",
];

export const middleEastList = [
    "bh",
    "cy",
    "eg",
    "ir",
    "iq",
    "il",
    "jo",
    "kw",
    "lb",
    "om",
    "ps",
    "qa",
    "sa",
    "tr",
    "ae",
    "ye",
    "sy",
];

// When reviewing your dashboard data by region, Adjust will automatically group countries into the following business
// regions: EMEA (Europe, the Middle East and Africa),
//  NA (North America), LATAM (Latin America) and
//  APAC (Asia-Pacific). To confirm the categorization of a specific country, simply refer to the list below.

// const rowData = [

//     ad	Andorra	EMEA
// ae	United Arab Emirates	EMEA
// af	Afghanistan	EMEA
// ag	Antigua and Barbuda	LATAM
// ai	Anguilla	LATAM
// al	Albania	EMEA
// am	Armenia	EMEA
// ao	Angola	EMEA
// aq	Antarctica	EMEA
// ar	Argentina	LATAM
// as	American Samoa	APAC
// at	Austria	EMEA
// au	Australia	APAC
// aw	Aruba	LATAM
// ax	Åland Islands	EMEA
// az	Azerbaijan	EMEA
// ba	Bosnia and Herzegovina	EMEA
// bb	Barbados	LATAM
// bd	Bangladesh	APAC
// be	Belgium	EMEA
// bf	Burkina Faso	EMEA
// bg	Bulgaria	EMEA
// bh	Bahrain	EMEA
// bi	Burundi	EMEA
// bj	Benin	EMEA
// bl	Saint Barthélemy	EMEA
// bm	Bermuda	LATAM
// bn	Brunei Darussalam	APAC
// bo	Bolivia, Plurinational State of	LATAM
// br	Brazil	LATAM
// bs	Bahamas	LATAM
// bt	Bhutan	APAC
// bv	Bouvet Island	EMEA
// bw	Botswana	EMEA
// by	Belarus	EMEA
// bz	Belize	LATAM
// ca	Canada	NA
// cc	Cocos (Keeling) Islands	APAC
// cd	Congo, the Democratic Republic of the	EMEA
// cf	Central African Republic	EMEA
// cg	Congo	EMEA
// ch	Switzerland	EMEA
// ci	Côte d'Ivoire	EMEA
// ck	Cook Islands	EMEA
// cl	Chile	LATAM
// cm	Cameroon	EMEA
// cn	China	APAC
// co	Colombia	LATAM
// cr	Costa Rica	LATAM
// cu	Cuba	LATAM
// cv	Cabo Verde	EMEA
// cw	Curaçao	EMEA
// cx	Christmas Island	APAC
// cy	Cyprus	EMEA
// cz	Czechia	EMEA
// de	Germany	EMEA
// dj	Djibouti	EMEA
// dk	Denmark	EMEA
// dm	Dominica	LATAM
// do	Dominican Republic	LATAM
// dz	Algeria	EMEA
// ec	Ecuador	LATAM
// ee	Estonia	EMEA
// eg	Egypt	EMEA
// eh	Western Sahara	EMEA
// er	Eritrea	EMEA
// es	Spain	EMEA
// et	Ethiopia	EMEA
// fi	Finland	EMEA
// fj	Fiji	APAC
// fk	Falkland Islands (Malvinas)	LATAM
// fm	Micronesia, Federated States of	APAC
// fo	Faroe Islands	EMEA
// fr	France	EMEA
// ga	Gabon	EMEA
// gb	United Kingdom of Great Britain and Northern Island	EMEA
// gd	Grenada	LATAM
// ge	Georgia	EMEA
// gf	French Guiana	EMEA
// gg	Guernsey	EMEA
// gh	Ghana	EMEA
// gi	Gibralta	EMEA
// gl	Greenland	EMEA
// gm	Gambia	EMEA
// gn	Guinea	EMEA
// gp	Guadeloupe	EMEA
// gq	Equatorial Guinea	EMEA
// gr	Greece	EMEA
// gs	South Georgia and the South Sandwich Islands	LATAM
// gt	Guatemala	LATAM
// gu	Guam	APAC
// gw	Guinea-Bissau	EMEA
// gy	Guyana	LATAM
// hk	Hong Kong	APAC
// hm	Heard Island and McDonald Islands	EMEA
// hn	Honduras	LATAM
// hr	Croatia	EMEA
// ht	Haiti	LATAM
// hu	Hungary	EMEA
// id	Indonesia	APAC
// ie	Ireland	EMEA
// il	Israel	EMEA
// im	Isle of Man	EMEA
// in	India	APAC
// io	British Indian Ocean Territory	APAC
// iq	Iraq	EMEA
// ir	Iran, Islamic Republic of	EMEA
// is	Iceland	EMEA
// it	Italy	EMEA
// je	Jersey	EMEA
// jm	Jamaica	LATAM
// jo	Jordan	EMEA
// jp	Japan	APAC
// ke	Kenya	EMEA
// kg	Kyrgyzstan	EMEA
// kh	Cambodia	APAC
// ki	Kiribati	APAC
// km	Comoros	EMEA
// kn	Saint Kitts and Nevis	LATAM
// kp	Korea, Democratic People's Republic of	APAC
// kr	Korea, Republic of	APAC
// kw	Kuwait	EMEA
// ky	Cayman Islands	LATAM
// kz	Kazakhstan	EMEA
// la	Lao People's Democratic Republic	APAC
// lb	Lebanon	EMEA
// lc	Saint Lucia	LATAM
// li	Liechtenstein	EMEA
// lk	Sri Lanka	APAC
// lr	Liberia	EMEA
// ls	Lesotho	EMEA
// lt	Lithuania	EMEA
// lu	Luxembourg	EMEA
// lv	Latvia	EMEA
// ly	Libya	EMEA
// ma	Morocco	EMEA
// mc	Monaco	EMEA
// md	Moldova, Republic of	EMEA
// me	Montenegro	EMEA
// mf	Collectivity of Saint Martin	EMEA
// mg	Madagascar	EMEA
// mh	Marshall Islands	APAC
// mk	Macedonia, the former Yugoslav Republic of	EMEA
// ml	Mali	EMEA
// mm	Myanmar	APAC
// mn	Mongolia	APAC
// mo	Macao	APAC
// mp	Northern Mariana Islands	APAC
// mq	Martinique	EMEA
// mr	Mauritania	EMEA
// ms	Montserrat	LATAM
// mt	Malta	EMEA
// mu	Mauritius	EMEA
// mv	Maldives	APAC
// mw	Malawi	EMEA
// mx	Mexico	LATAM
// my	Malaysia	APAC
// mz	Mozambique	EMEA
// na	Namibia	EMEA
// nc	New Caledonia	EMEA
// ne	Niger	EMEA
// nf	Norfolk Island	APAC
// ng	Nigeria	EMEA
// ni	Nicaragua	LATAM
// nl	Netherlands	EMEA
// no	Norway	EMEA
// np	Nepal	APAC
// nr	Nauru	APAC
// nu	Niue	APAC
// nz	New Zealand	APAC
// om	Oman	EMEA
// pa	Panama	LATAM
// pe	Peru	LATAM
// pf	French Polynesia	EMEA
// pg	Papua New Guinea	APAC
// ph	Philippines	APAC
// pk	Pakistan	APAC
// pl	Poland	EMEA
// pm	Saint Pierre and Miquelon	EMEA
// pn	Pitcairn	APAC
// pr	Puerto Rico	LATAM
// ps	Palestine, State of	EMEA
// pt	Portugal	EMEA
// pw	Palau	APAC
// py	Paraguay	LATAM
// qa	Qatar	EMEA
// re	Réunion	EMEA
// ro	Romania	EMEA
// rs	Serbia	EMEA
// ru	Russian Federation	EMEA
// rw	Rwanda	EMEA
// sa	Saudi Arabia	EMEA
// sb	Solomon Islands	APAC
// sc	Seychelles	EMEA
// sd	Sudan	EMEA
// se	Sweden	EMEA
// sg	Singapore	APAC
// sh	Saint Helena, Ascension and Tristan da Cunha	EMEA
// si	Slovenia	EMEA
// sk	Slovakia	EMEA
// sl	Sierra Leone	EMEA
// sm	San Marino	EMEA
// sn	Senegal	EMEA
// so	Somalia	EMEA
// sr	Suriname	LATAM
// ss	South Sudan	EMEA
// st	Sao Tome and Principe	EMEA
// sv	El Salvador	LATAM
// sx	Sint Maarten	EMEA
// sy	Syrian Arab Republic	EMEA
// sz	Swaziland	EMEA
// tc	Turks and Caicos Islands	LATAM
// td	Chad	EMEA
// tf	French Southern Territories	EMEA
// tg	Togo	EMEA
// th	Thailand	APAC
// tj	Tajikistan	EMEA
// tk	Tokelau	APAC
// tl	Timor-Leste	APAC
// tm	Turkmenistan	EMEA
// tn	Tunisia	EMEA
// to	Tonga	APAC
// tr	Turkey	EMEA
// tt	Trinidad and Tobago	LATAM
// tv	Tuvalu	APAC
// tw	Taiwan, Province of China	APAC
// tz	Tanzania, United Republic of	EMEA
// ua	Ukraine	EMEA
// ug	Uganda	EMEA
// um	United States Minor Outlying Islands	APAC
// us	United States of America	NA
// uy	Uruguay	LATAM
// uz	Uzbekistan	EMEA
// va	Holy See	EMEA
// vc	Saint Vincent and the Grenadines	LATAM
// ve	Venezuela, Bolivarian Republic of	LATAM
// vg	Virgin Islands, British	LATAM
// vi	Virgin Islands, U.S.	LATAM
// vn	Vietnam	APAC
// vu	Vanuatu	APAC
// wf	Wallis and Futuna	EMEA
// ws	Samoa	APAC
// ye	Yemen	EMEA
// yt	Mayotte	EMEA
// za	South Africa	EMEA
// zm	Zambia	EMEA
// zw	Zimbabwe	EMEA
// ]
