import React, { useState } from "react";
import SelectLogo from "../../../../Register/components/SelectLogo";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import addImgBackLogo from "../../../../../../assets/images/register-images/addIconLogo.svg";

// Import Styles
import "../../../index.scss";
import { Box, Grid } from "@mui/material";

export const useStyles = makeStyles((theme) => ({
  container: {
    // border:
    //     theme.palette.mode === "dark"
    //         ? "transparent"
    //         : "0.2px solid #e7e9ee",
    // boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  firstColumn: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flex: 1,
  },
  addImgBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
    width: "76px",
    height: "57px",
    padding: "5px",
    borderRadius: "5px",
    cursor: "pointer",
    position: "relative",
    color: "#282828",
  },
  addImgBackLogo: {
    position: "absolute",
  },
  addImgText: {
    fontFamily: "Energy",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "8px",
    lineHeight: "9px",
    textAlign: "center",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    zIndex: 10,
  },
  lastColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: "5px",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    "& span": {
      fontFamily: "Energy",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "19px",
    },
    "& svg": {
      height: "16px",
    },
  },
  textFieldContainer: {
    fontFamily: "Energy",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    flex: 1,
  },
  headerField: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#FC007F",
    background: "#F6F6F6",
    border: "1px solid #D9D9D9",
    borderRadius: "3px",
    width: "30%",
    padding: "2px 5px",
    input___webkit_input_placeholder: { color: "#282828", opacity: "1" },
  },
  descField: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    color: "#282828",
    lineHeight: "20px",
    background: "#F6F6F6",
    border: "1px solid #D9D9D9",
    borderRadius: "3px",
    width: "60%",
    padding: "2px 5px",
  },
  lastColumnContainer: {
    marginRight: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  uploadedImg: {
    maxHeight: "90%",
    maxWidth: "90%",
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: theme.palette.mode === "dark" ? "#fff" : "#282828",
    width: "50px",
  },
}));
const IconField = (props) => {
  const {
    data,
    sub_category_icon = "analytics",
    app_logo_type = "google_font",
    handleChange,
    required = false,
    user,
    state,
    handleOpenDrawer,
    theme,
    widgetType,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleAddLogo = () => setOpen(true);
  return (
    <Grid
      item
      xs={12}
      sx={{
        position: "relative",
        display: "flex",
      }}
    >
      <div
        style={{
          display: "flex",
          // padding: "8px 0px 0px 8px",
          // paddingLeft: "60px",
          justifyContent: "center",
          alignItems: "center",
          // flexDirection: "column",
        }}
      >
        <SelectLogo
          data={data}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleChange={handleChange}
          OneToOneRatio={
            user.role === "super_admin" || state?.sub_category_type === "image"
          }
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={`heading ${theme}`}
          marginRight="20px"
        >
          {"Select Image"}{" "}
          <span
            style={{
              display: required ? "" : "none",
              color: "red",
            }}
          >
            *
          </span>
          :
          {sub_category_icon && (
            <span
              class="material-icons-outlined"
              style={{
                fontSize: "20px",
                color: theme === "dark" ? "#b1b1b1" : "#4e4e4e",
                marginLeft: "10px",
              }}
              onClick={() => {
                handleChange(data.key, "", "", "");
              }}
            >
              delete
            </span>
          )}
        </Box>
        <div className={classes.imgContainer} onClick={handleAddLogo}>
          {sub_category_icon && app_logo_type === "google_font" ? (
            <span
              class="material-icons-outlined"
              style={{
                fontSize: "50px",
                color: theme === "dark" ? "#b1b1b1" : "#4e4e4e",
              }}
            >
              {sub_category_icon}
            </span>
          ) : sub_category_icon ? (
            <img
              src={sub_category_icon}
              alt=""
              className={classes.uploadedImg}
            />
          ) : (
            <div className={classes.addImgBtn}>
              <img
                src={addImgBackLogo}
                alt=""
                className={classes.addImgBackLogo}
              />
              <div className={classes.addImgText}>Click to Add Image</div>
              <AddCircleIcon sx={{ zIndex: 1, fontSize: "16px" }} />
            </div>
          )}
        </div>
      </div>
      {widgetType === "report" && (
        <button
          onClick={handleOpenDrawer}
          style={{
            position: "absolute",
            top: "19px",
            right: "10px",
            width: "180px",
          }}
          className="btn btn-next GA-open-powerbi-drawer"
        >
          My PowerBI workspaces
        </button>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  allApps: state.AppsData.allApps,
  theme: state.user.ui_preferences?.theme,
  templateData: state.appTemplate.appTemplateState.templateData,
  registerTemplateFields:
    state.register.registerTemplate.data.register_app_template.fields[0]
      .options[0].subform_templates[2],
});

export default connect(mapStateToProps)(IconField);
