import React from "react";
import { useHistory } from "react-router";

// Import Styles
import "./style.scss";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
// import { HtmlTooltip } from "../../../components/AppInfoCard/AppInfoCard";

// Import Hooks
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:
      theme.palette.mode === "light" ? "#f5f5f9" : theme.palette.dark.main,
    color: theme.palette.mode === "light" ? theme.palette.dark.main : "white",
    maxWidth: 500,
    fontSize: "12px",
    fontFamily: "Energy",
    // fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const ProfileMangement = (props) => {
  const { theme, user } = props;
  const history = useHistory();

  const EditPreferences = (tab, tabname) => {
    const gaEventTracker = useAnalyticsEventTracker("ProfileManagement");
    gaEventTracker("EditPreferences", `screen = ${tabname}`);
    history.push({
      pathname: "/account-setup",
      tab_name: tab,
    });
  };

  return (
    <div>
      <div className="profile-subtitle">
        Manage your profile and preferences to make us work better for you.
      </div>
      <div className={`my-profile-container ${theme}`}>
        <div className={`top-container`}>
          <p className={`profile-heading`}>
            Welcome {user.name.replace("(Contractor)", "")}{" "}
          </p>
          {/* <p className={`profile-text`}>
            Manage your profile and preferences to make us work better for you.
          </p> */}
        </div>
        <div className={`middle-container`}>
          <div className={`profile-image-container`}>
            {user.profile_meta.url !== "" ? (
              <img
                alt=""
                src={user.profile_meta.url}
                className={`alphabet-picture`}
              ></img>
            ) : (
              <div
                className={`alphabet-picture`}
                style={{
                  background: user.profile_meta.background_color,
                }}
              >
                <p className={`name-initials`}>{user.profile_meta.initials}</p>
              </div>
            )}
          </div>
          <div className={`profile-details-container`}>
            <div className={`profile-detail-item`}>
              <span className="material-icons-outlined profile-detail-icon">
                badge
              </span>
              <p className={`profile-detail-value`}>
                {user.name.replace("(Contractor)", "")}
              </p>
            </div>
            <div className={`profile-detail-item`}>
              <span className={`material-icons-outlined profile-detail-icon`}>
                person_pin_circle
              </span>
              <p className={`profile-detail-value`}>
                {user.profile_meta.user_location}
              </p>
            </div>
            <div className={`profile-detail-item`}>
              <span className={`material-icons-outlined profile-detail-icon`}>
                mail
              </span>
              <p className={`profile-detail-value`}>
                {user.preferred_username}
              </p>
            </div>
          </div>
        </div>
        <div className={`bottom-container`}>
          <p className={`profile-bottom-header`}>Preferences</p>
          {user.user_interests ? (
            <div className={`bottom-profile-details`}>
              <div>
                <div className={`preferences-item`}>
                  <div className={`preferences-label`}>
                    <span
                      className={`material-icons-outlined preferences-item-icon`}
                    >
                      engineering
                    </span>
                    <p className={`preferences-item-label profile-text`}>
                      Job Role
                    </p>
                  </div>
                  <span
                    className={`material-icons-outlined preferences-edit-icon GA-edit-preferences`}
                    id="job role"
                    onClick={() => {
                      EditPreferences(2, "job role");
                    }}
                  >
                    edit
                  </span>
                </div>
                <div className={`preferences-item`}>
                  <div className={`preferences-label`}>
                    <span
                      className={`material-icons-outlined preferences-item-icon`}
                    >
                      psychology
                    </span>
                    <p className={`preferences-item-label profile-text`}>
                      Functions
                    </p>
                  </div>
                  <span
                    className={`material-icons-outlined preferences-edit-icon GA-edit-preferences`}
                    id="functions"
                    onClick={() => {
                      EditPreferences(3, "functions");
                    }}
                  >
                    edit
                  </span>
                </div>
                <div className={`preferences-item`}>
                  <div className={`preferences-label`}>
                    <span
                      className={`material-icons-outlined preferences-item-icon`}
                    >
                      pin_drop
                    </span>
                    <p className={`preferences-item-label profile-text`}>
                      Geography
                    </p>
                  </div>
                  <span
                    className={`material-icons-outlined preferences-edit-icon GA-edit-preferences`}
                    id="geography"
                    onClick={() => {
                      EditPreferences(4, "geography");
                    }}
                  >
                    edit
                  </span>
                </div>
              </div>
              <div className={`preferences-values`}>
                <div className={`values-container`}>
                  {user.user_interests.job_role ? (
                    user.user_interests.job_role.length ? (
                      user.user_interests.job_role.map((job, index) => (
                        <p className={`preferences-item-value`}>
                          {" " + job}
                          {index < user.user_interests.job_role.length - 1
                            ? ", "
                            : " "}
                        </p>
                      ))
                    ) : (
                      <p className={`preferences-item-value`}>
                        Yet to be updated
                      </p>
                    )
                  ) : (
                    <p className={`preferences-item-value`}>
                      Yet to be updated
                    </p>
                  )}
                </div>
                <div className={`values-container`}>
                  {user.user_interests.function ? (
                    user.user_interests.function.length ? (
                      <div style={{ display: "flex" }}>
                        {user.user_interests.function.length < 4
                          ? user.user_interests.function.map((fun, index) => (
                              <p className={`preferences-item-value`}>
                                {" " + fun.length > 35
                                  ? fun.slice(0, 35) + "..."
                                  : fun}
                                {index < user.user_interests.function.length - 1
                                  ? ", "
                                  : " "}
                              </p>
                            ))
                          : user.user_interests.function
                              .slice(0, 3)
                              .map((fun, index) => (
                                <p className={`preferences-item-value`}>
                                  {" " + fun.length > 35
                                    ? fun.slice(0, 35) + "..."
                                    : fun}
                                  {index <
                                  user.user_interests.function.length - 1
                                    ? ", "
                                    : " "}
                                </p>
                              ))}
                        {user.user_interests.function.length > 3 && (
                          <HtmlTooltip
                            placement="top-start"
                            title={
                              <div
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {user.user_interests.function
                                  // .slice(3, user.user_interests.function.length)
                                  .map((fun, index) => (
                                    <p className={`preferences-item-value`}>
                                      {" " + fun}
                                      {index <
                                      user.user_interests.function.length - 3
                                        ? ", "
                                        : " "}
                                    </p>
                                  ))}
                              </div>
                            }
                          >
                            <p
                              className={`preferences-item-value hover-on-more`}
                            >
                              +{" " + user.user_interests.function.length - 3}{" "}
                              more.
                            </p>
                          </HtmlTooltip>
                        )}
                      </div>
                    ) : (
                      <p className={`preferences-item-value`}>
                        Yet to be updated
                      </p>
                    )
                  ) : (
                    <p className={`preferences-item-value`}>
                      Yet to be updated
                    </p>
                  )}
                </div>
                <div className={`values-container`}>
                  {user.user_interests.geography ? (
                    user.user_interests.geography.length ? (
                      user.user_interests.geography.map((geo, index) => (
                        <p className={`preferences-item-value`}>
                          {" " + geo}
                          {index < user.user_interests.geography.length - 1
                            ? ", "
                            : " "}
                        </p>
                      ))
                    ) : (
                      <p className={`preferences-item-value`}>
                        Yet to be updated
                      </p>
                    )
                  ) : (
                    <p className={`preferences-item-value`}>
                      Yet to be updated
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={`values-container`}>
              <p className={`preferences-item-value`}>
                Your profile is yet to be updated.
              </p>
              <p
                className={`preferences-item-value update-hyperlink-text GA-edit-preferences`}
                id="new update"
                onClick={() => {
                  EditPreferences(1, "signup");
                }}
              >
                Update Here
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileMangement;
