import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DrawerFilters from "../../../components/DrawerFilters";
import SearchBar from "../../../components/SearchBar";
import { config } from "../../../config/config";
import {
  getRegisterAppTemplate,
  updateAppDashboardState,
} from "../../../redux/actions";
import { getFilteredOptions } from "../../../utils/utils";

const requiredDropdowns = ["function", "geography", "line_of_business"];

function fetchAPI(payload) {
  const url = `/api/smartsearchapp`;
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const SearchFilters = ({ templates, appDashboardState }) => {
  const dispatch = useDispatch();
  const {
    appData,
    search_columns = [],
    searchVal = "",
    filterOptions = config.initailOptions,
    filterList = config.initailFilters,
  } = appDashboardState;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setOpen(false);
  const handleFilterDrawer = () => setOpen(true);

  const handlesetFilterOptions = (options) => {
    dispatch(updateAppDashboardState({ filterOptions: options }));
  };
  const handleSetFitlers = (filterList) => {
    dispatch(updateAppDashboardState({ filterList }));
  };

  const handleClear = () => {
    dispatch(updateAppDashboardState({ searchVal: "" }));
    if (search_columns.length) {
      handleSearchReports({
        search_columns: search_columns,
        search_string: "",
      });
    } else {
      // updateScreenWithFilteredData(AppsData.allApps.data);
    }
  };

  const handleChange = (key, value) => {
    const filterInfo = { ...filterList, [key]: value };
    handleSetFitlers(filterInfo);
    handleSubmit(filterInfo);
  };

  const handleSearchBarChange = (e) => {
    const value = e.target.value;
    if (!value) {
      handleClear();
    } else {
      dispatch(updateAppDashboardState({ searchVal: value }));
    }
  };
  const handleReset = () => {
    if (searchVal) {
      handleSearchReports({
        search_columns: [],
        search_string: searchVal,
      });
    } else {
      // updateScreenWithFilteredData(AppsData.allApps?.data || []);
    }
    dispatch(updateAppDashboardState({ search_columns: [] }));
    handleSetFitlers(config.initailFilters);
  };
  // this function is used to set smart search data for the api
  const handleSubmit = (filterInfo = {}) => {
    let search_columns = [];
    Object.keys(filterInfo).forEach((item) => {
      if (filterInfo[item] && filterInfo[item].length) {
        search_columns = [
          ...search_columns,
          {
            name: item,
            type:
              config.specialFields.includes(item) && item !== "geography"
                ? "multi"
                : item === "geography"
                ? "custom"
                : "single",
            values:
              item === "app_type" &&
              filterInfo[item] === config.hardCoded.embedded_analytics
                ? "bi_app"
                : item === "app_type"
                ? filterInfo[item].toLowerCase().replaceAll(" ", "_")
                : filterInfo[item],
          },
        ];
      }
    });
    if (!search_columns.length) {
      dispatch(
        updateAppDashboardState({
          search_columns: [],
          loadingFilter: "pending",
        })
      );
      handleReset();
      return;
    }
    dispatch(updateAppDashboardState({ search_columns }));
    handleSearchReports({
      search_columns,
      search_string: searchVal,
    });
  };

  const handleSearchReports = useCallback(
    async (payload) => {
      dispatch(updateAppDashboardState({ loadingFilter: "loading" }));
      const reqPayLoad = { ...payload, app_id: appData.app_id };
      try {
        const res = await fetchAPI(reqPayLoad);
        const response = await res.json();
        if (response.status?.status === "datareckitt-200") {
          // console.log("response", response);
          dispatch(
            updateAppDashboardState({
              filteredReportsList: response.data,
            })
          );
        }
        dispatch(updateAppDashboardState({ loadingFilter: "success" }));
      } catch (error) {
        toast.error("Oops! somthing went wrong. Please try later.");
        dispatch(updateAppDashboardState({ loadingFilter: "error" }));
      }
    },
    [appData.app_id, dispatch]
  );

  useEffect(() => {
    if (templates.status === "pending") {
      dispatch(getRegisterAppTemplate());
    }
  }, [dispatch, templates.status]);

  //used to set the options in filters && initialize the filters with the data stored in backend.
  useEffect(() => {
    if (templates.status !== "success" || search_columns.length) return;
    let options = {};
    templates.data.search_template.fields.forEach((item) => {
      if (config.specialFields.includes(item.key)) {
        if (item.key === "geography") {
          options = {
            ...options,
            [item.key]: getFilteredOptions(item.options),
          };
        } else {
          options = { ...options, [item.key]: item.options };
        }
      }
    });
    dispatch(updateAppDashboardState({ filterOptions: options }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates.data.search_template?.fields, templates.status]);

  useEffect(() => {
    let isCancelled = false;
    const handleSearch = async (value) => {
      if (!value) {
        return;
      }
      await timeout(1000);
      if (!isCancelled) {
        const payload = {
          search_columns: search_columns,
          search_string: value,
        };
        handleSearchReports(payload);
      }
    };
    handleSearch(searchVal);
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  return (
    <>
      <DrawerFilters
        open={open}
        handleClose={handleClose}
        handleCloseDrawer={handleClose}
        handleReset={handleReset}
        loading={loading}
        filterList={filterList}
        handleChange={handleChange}
        filterOptions={filterOptions}
        setFilterOptions={handlesetFilterOptions}
        handleSubmit={handleSubmit}
        setFilterList={handleSetFitlers}
        requiredDropdowns={requiredDropdowns}
      />
      <SearchBar
        handleClear={handleClear}
        handleFilterDrawer={handleFilterDrawer}
        searchVal={searchVal}
        search_columns={search_columns}
        handleChange={handleSearchBarChange}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  templates: state.register.registerTemplate,
  user: state.user,
  appDashboardState: state.appDashboard.appDashboardState,
});

export default connect(mapStateToProps)(SearchFilters);
