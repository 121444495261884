import React from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import Loader from "../../../../../components/Loader/Loader";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    backgroundColor: "transparent",
    color: "#fc007f",
    border: "1px solid #fc007f",
    fontWeight: "400",
    width: "135px",
  },
}));

const ConfirmationModal = ({
  open,
  handleClose,
  handleDone,
  msg = "",
  loadStatus = false,
  theme,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          backgroundImage: "none",
          boxShadow: "4px 4px 12px 4px #434343",
        },
      }}
    >
      <div
        style={{
          minWidth: "320px",
          maxWidth: "600px",
        }}
      >
        {loadStatus || !msg ? (
          <DialogContent>
            <Loader message={"Syncing your reports ..."} />
          </DialogContent>
        ) : (
          <>
            <DialogContent>
              <Box
                display="flex"
                alignItems="center"
                mb={1}
                mx={1}
                className={`heading ${theme}`}
              >
                {msg}
              </Box>
            </DialogContent>
            <Box display="flex" justifyContent="flex-end" px={2} pb={1} gap={2}>
              <button
                onClick={handleClose}
                className={`${classes.cancelBtn} GA-exit-btn`}
                id="Cancel_exit"
              >
                Cancel
              </button>
              <button
                className="btn GA-exit-btn"
                onClick={handleDone}
                id="Exit_without_save"
              >
                Continue
              </button>
            </Box>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
